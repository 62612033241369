import { Card, CardContent, Grid } from '@material-ui/core';
import { GroupedAutoComplete, TextField } from '@nutrien/cxp-components';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { BorerStateType } from '@/rxdb/BorerStateTypeFeed/queryBuilder';

import { DelayMultiEditInput } from '../DelaysMultiEditDrawer';

interface Props {
  control: Control<DelayMultiEditInput>;
  delayTypes: BorerStateType[];
  errors: FieldErrors;
}

export const DelaysMultiEditFormCard = ({ control, delayTypes, errors }: Props): JSX.Element => {
  return (
    <Card data-testid={`delay-multi-edit-form-card`}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <GroupedAutoComplete
                  {...field}
                  label="Type"
                  data-testid={`delay-multi-edit-form-card-type-autocomplete`}
                  list={delayTypes}
                  groupByProperty="categoryName"
                  primaryProperty="description"
                  secondaryProperty="categoryName"
                  autoSelect={false}
                  autoHighlight={false}
                  showCaret
                  getOptionLabel={item => {
                    return item?.description || '';
                  }}
                  onChange={(event, value: BorerStateType) => field.onChange(value)}
                  error={!!errors.type}
                  errorText={errors.type?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Comment (optional)"
                  rows={3}
                  multiline
                  css={undefined}
                  noMinHeight
                  error={!!errors.comment}
                  errorText={errors.comment?.message}
                  data-testid={`delay-multi-edit-form-card-comment`}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
