import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import type { RxDocument } from 'rxdb';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import {
  DATE_STORAGE_FORMAT,
  getCurrentShift,
  SHIFT_LENGTH_HOURS,
} from '../../mobx-models/ShiftPicker/ShiftPicker';
import { ShiftType } from '../../utilities/enums';
import {
  AWS_DATE_TIME_FORMAT,
  getStartOfDateString,
} from '../../utilities/hooks/useDateFormatters';
import { sortDescendingByDay } from '../../utilities/sortHelper';
import { BorerShiftCollection } from '../BorerShift/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Shift, ShiftCollection } from './queryBuilder';

export const useShifts = () => {
  const { shiftPicker } = useMst();

  const shiftCollection: ShiftCollection = useRxCollection(RxdbCollectionName.SHIFT);
  const borerShiftCollection: BorerShiftCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT,
  );

  const [shiftsInitialized, setShiftsInitialized] = useState(false);
  const [currentShiftSelected, setCurrentShiftSelected] = useState(false);
  const [dayShiftSelected, setDayShiftSelected] = useState(false);
  const [currentActiveShiftInDB, setCurrentActiveShiftInDB] = useState<string | null>(null);
  const [selectedShiftInDB, setSelectedShiftInDB] = useState<string | null>(null);

  const [mostRecentAvailableShift, setMostRecentAvailableShift] = useState<RxDocument<
    Shift,
    unknown
  > | null>(null);

  const [selectedBorerShiftInDB, setSelectedBorerShiftInDB] = useState<string | null>(null);
  const [mostRecentShiftSelected, setMostRecentShiftSelected] = useState(false);
  const [viewingMostRecentAvailableShift, setViewingMostRecentAvailableShift] = useState(false);

  useEffect(() => {
    setCurrentShiftSelected(shiftPicker.isCurrentShiftSelected());
    setDayShiftSelected(shiftPicker.isDayShift());
  }, [shiftPicker.currentShiftId, shiftPicker.currentBorerShiftId]);

  useEffect(() => {
    if (shiftCollection) setShiftsInitialized(true);
  }, [shiftCollection]);

  const queryForMostRecentShift = async () => {
    // Queries for most recent available shift/borerShift pair
    let { Type: currentType } = getCurrentShift();
    const { datejs } = getCurrentShift();
    setMostRecentShiftSelected(false);
    setMostRecentAvailableShift(null);

    if (!shiftCollection || !borerShiftCollection) return;

    const maxShiftsBack = 14 * 2; // 14 days * 2 shifts per day
    let currentShiftsBack = 0;

    let currentUtcDate = datejs.startOf('day');
    while (currentShiftsBack <= maxShiftsBack) {
      const shiftQuery = await shiftCollection
        ?.findOne({
          selector: {
            name: { $eq: currentType },
            day: { $eq: getStartOfDateString(currentUtcDate) },
          },
        })
        .exec();

      // Ensure borerShift is available for recent shift
      const borerShiftForRecentShiftId = shiftQuery?.id
        ? await borerShiftCollection?.findOne().where('shiftId').eq(shiftQuery?.id).exec()
        : null;

      if (!shiftQuery || !borerShiftForRecentShiftId) {
        if (currentType === ShiftType.DAY_SHIFT) {
          currentType = ShiftType.NIGHT_SHIFT;
          currentUtcDate = currentUtcDate.subtract(1, 'day');
        } else if (currentType === ShiftType.NIGHT_SHIFT) {
          currentType = ShiftType.DAY_SHIFT;
        }
        currentShiftsBack++;
      } else {
        setMostRecentAvailableShift(shiftQuery);
        setMostRecentShiftSelected(currentShiftsBack === 0);
        currentShiftsBack = maxShiftsBack + 1;
      }
    }
  };
  useEffect(() => {
    const shiftSub = shiftCollection?.find().$.subscribe(() => queryForMostRecentShift());
    const bShiftSub = borerShiftCollection?.find().$.subscribe(() => queryForMostRecentShift());

    return () => {
      if (shiftSub?.unsubscribe) shiftSub.unsubscribe();
      if (bShiftSub?.unsubscribe) bShiftSub.unsubscribe();
    };
  }, [shiftCollection, borerShiftCollection]);

  const getShiftForDateAndType = async (
    date: Dayjs,
    type: ShiftType,
  ): Promise<RxDocument<Shift> | undefined> => {
    if (!shiftCollection) {
      return undefined;
    }

    const utcDate = `${date.utc().startOf('day').format(AWS_DATE_TIME_FORMAT)}Z`;

    const result = await shiftCollection
      ?.findOne({
        selector: {
          name: { $eq: type },
          day: { $eq: utcDate },
        },
      })
      .exec();

    return result !== null ? result : undefined;
  };

  const getPreviousShift = async () => {
    const selectedShiftStartTime = dayjs.unix(shiftPicker.shiftStartDateUnix);

    const newShiftStartTime = selectedShiftStartTime.clone().subtract(SHIFT_LENGTH_HOURS, 'hours');

    const date = dayjs(newShiftStartTime.format(DATE_STORAGE_FORMAT));
    const newType =
      shiftPicker.Type === ShiftType.DAY_SHIFT ? ShiftType.NIGHT_SHIFT : ShiftType.DAY_SHIFT;

    const prev = getShiftForDateAndType(date, newType);
    return prev;
  };

  const getShiftById = async (id: string): Promise<RxDocument<Shift> | undefined> => {
    if (!shiftCollection) {
      return undefined;
    }

    const result = await shiftCollection
      ?.findOne({
        selector: {
          id: { $eq: id },
        },
      })
      .exec();

    return result !== null ? result : undefined;
  };

  const getPreviousShiftsBefore = async (
    currentShift: Shift,
  ): Promise<RxDocument<Shift[] | []>> => {
    if (!shiftCollection || !currentShift) {
      return [];
    }

    const allShifts = await shiftCollection?.find().exec();
    const result = allShifts
      .filter(shift => dayjs(shift.start).unix() < dayjs(currentShift.start).unix())
      .sort(sortDescendingByDay);

    return result;
  };

  const checkForActiveShiftInDB = () => {
    if (!shiftCollection) {
      return false;
    }
    // Do we have the current active shift downloaded
    const { Date, Type } = shiftPicker;

    const query = shiftCollection?.findOne({
      selector: {
        name: { $eq: Type },
        day: { $eq: getStartOfDateString(Date) },
      },
    });

    return query.$.subscribe(data => {
      setCurrentActiveShiftInDB(data?.id ?? null);
    });

    // Do we have the current active borer shift downloaded
  };

  const checkForSelectedShiftInDB = () => {
    if (!shiftCollection) {
      return false;
    }
    const utcDate = dayjs(shiftPicker.Date).startOf('day').format(AWS_DATE_TIME_FORMAT) + 'Z';

    const query = shiftCollection?.findOne({
      selector: {
        name: { $eq: shiftPicker.Type },
        day: { $eq: utcDate },
      },
    });

    return query.$.subscribe(data => {
      setSelectedShiftInDB(data?.id ?? null);
    });
  };

  const checkForSelectedBorerShiftInDB = () => {
    if (!borerShiftCollection) {
      return false;
    }
    const query = borerShiftCollection?.findOne({
      selector: {
        shiftId: { $eq: shiftPicker.currentShiftId },
      },
    });

    return query.$.subscribe(data => {
      setSelectedBorerShiftInDB(data?.id ?? null);
    });
  };

  useEffect(() => {
    let activeShiftSub: Subscription | undefined | false;
    let selectedShiftSub: Subscription | undefined | false;
    let selectedBorerShiftSub: Subscription | undefined | false;

    if (shiftCollection) {
      activeShiftSub = checkForActiveShiftInDB();
      selectedShiftSub = checkForSelectedShiftInDB();
    }
    if (borerShiftCollection) selectedBorerShiftSub = checkForSelectedBorerShiftInDB();

    return () => {
      if (activeShiftSub) activeShiftSub.unsubscribe();
      if (selectedShiftSub) selectedShiftSub.unsubscribe();
      if (selectedBorerShiftSub) selectedBorerShiftSub.unsubscribe();
    };
  }, [
    shiftCollection,
    borerShiftCollection,
    shiftPicker.Type,
    shiftPicker.Date,
    shiftPicker.currentShiftId,
  ]);

  useEffect(() => {
    setViewingMostRecentAvailableShift(
      Boolean(
        shiftPicker.currentShiftId && mostRecentAvailableShift?.id === shiftPicker.currentShiftId,
      ),
    );
  }, [shiftPicker.currentShiftId, mostRecentAvailableShift]);

  return {
    shiftsInitialized,
    getPreviousShift,
    getShiftForDateAndType,
    getShiftById,
    getPreviousShiftsBefore,
    shiftCollection,
    currentShiftSelected,
    dayShiftSelected,
    currentActiveShiftInDB,
    mostRecentAvailableShift,
    mostRecentShiftSelected,
    selectedShiftInDB,
    selectedBorerShiftInDB,
    viewingMostRecentAvailableShift,
  };
};

export default useShifts;
