import { RxCollection } from 'rxdb';
import { useRxData } from 'rxdb-hooks';

import { InspectionType } from '@/utilities/constants';

import { Inspection } from '../Inspections/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import { InspectionResult } from './queryBuilder';

const useLastInspectionResult = (inspectionType: InspectionType, miningMethod?: string) => {
  const { result: matchingInspectionTypes } = useRxData(
    RxdbCollectionName.INSPECTIONS,
    (collection: RxCollection<Inspection>) =>
      collection.find({
        selector: {
          $and: [
            { inspectionType: { $eq: inspectionType } },
            { miningMethod: { $eq: miningMethod } },
          ],
        },
        sort: [{ updatedAt: 'asc' }],
      }),
  );

  const matchingInspectionType = matchingInspectionTypes[0];

  const { result: recentMatchingInspections } = useRxData(
    RxdbCollectionName.INSPECTION_RESULTS,
    (collection: RxCollection<InspectionResult>) =>
      collection.find({
        selector: {
          inspectionId: matchingInspectionType?.id || null,
        },

        sort: [{ createdOn: 'desc' }],
      }),
  );

  return {
    lastInspection: recentMatchingInspections[0],
    recentMatchingInspections,
  };
};

export default useLastInspectionResult;
