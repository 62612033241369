import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    document: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    page: {
      maxWidth: 'calc(100% - 1em)',
      margin: '1em',
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

export default useStyles;
export { useStyles };
