import dayjs from 'dayjs';
import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { LiveInterval } from '../constants';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface PanelDrawing extends BaseEntity {
  readonly borerShiftId: string;
  readonly drawingData: string;
  readonly miningMethod: string;
}
export type PanelDrawingCollection = RxCollection<PanelDrawing> | null;
export type PanelDrawingDocument = RxDocument<PanelDrawing>;

export const PANEL_DRAWING_SYNC_INTERVAL = LiveInterval.SECONDS_60;
export const PANEL_DRAWING_BATCH_SIZE = 34; //https://nutrien.atlassian.net/browse/MDP-7729

export const panelDrawingSchema: RxJsonSchema<PanelDrawing> = {
  type: 'object',
  version: 0,
  description: 'describes a PanelDrawing object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    borerShiftId: { type: 'string' },
    drawingData: { type: 'string' },
    miningMethod: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

const panelDrawingFeedQuery = print(gql`
  query panelDrawingFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    panelDrawingFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      isDeleted
      updatedAt
      version
      borerShiftId
      drawingData
      miningMethod
    }
  }
`);

export const panelDrawingPullQueryBuilder = generatePullQuery(
  panelDrawingFeedQuery,
  PullQueryContext.Borer,
);

const setPanelDrawingMutation = print(gql`
  mutation setPanelDrawing(
    $id: ID!
    $borerShiftId: ID!
    $drawingData: String!
    $isDeleted: Boolean!
    $miningMethod: String!
    $version: Int!
    $modifiedOn: AWSDateTime
  ) {
    setPanelDrawing(
      input: {
        id: $id
        version: $version
        isDeleted: $isDeleted
        miningMethod: $miningMethod
        drawingData: $drawingData
        borerShiftId: $borerShiftId
        modifiedOn: $modifiedOn
      }
    )
  }
`);

type SetPanelDrawingInput = {
  id: string;
  borerShiftId: string;
  drawingData: string;
  miningMethod: string;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapPanelDrawingDocumentToInput = (doc: PanelDrawingDocument): SetPanelDrawingInput => {
  return {
    id: doc.id,
    version: doc.version,
    isDeleted: doc.isDeleted,
    miningMethod: doc.miningMethod,
    drawingData: doc.drawingData,
    borerShiftId: doc.borerShiftId,
    modifiedOn: dayjs().toISOString(),
    createdOn: null,
  };
};

export const panelDrawingPushQueryBuilder = generatePushQuery(
  setPanelDrawingMutation,
  (doc: PanelDrawingDocument) => mapPanelDrawingDocumentToInput(doc),
);
