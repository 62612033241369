/* eslint-disable import/first */
import { useFlags } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Workbox } from 'workbox-window';

import RxdbManager from '@/rxdb/RxdbManager';
import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';

import { useMst } from '../../mobx-models/Root';
import { useConstructor, useNotification, useOnlineStatus } from '../../utilities';
import useDebug from '../../utilities/hooks/useDebug';
import { useWindowVisibility } from '../../utilities/hooks/useWindowVisibility';
import UpdateModal from '../UpdateModal';
import {
  checkIfFlagsAllowUpdate,
  getPWAVersion,
  installNewServiceWorkerIfFound,
  installPendingUpdate,
} from './updateFunctions';

const DEFAULT_POLLING_INTERVAL = 30; // seconds

interface Props {
  serviceWorkerFile: string;
  serviceWorkerScope?: string; // default to "/" for scope
  serviceWorkerPollingInterval?: number; // ms
  updateTimeoutSeconds?: number;
}

const PWAUpdate: React.FC<Props> = ({
  serviceWorkerFile,
  serviceWorkerPollingInterval = DEFAULT_POLLING_INTERVAL * 1000,
  serviceWorkerScope = '/',
  updateTimeoutSeconds = 10,
}: Props) => {
  const DEBUG = useDebug('DEBUG_PWA');
  const { maxUpdateVersion, versionBlacklist, allowAutoUpdate, borerBlockUpdate } =
    useFlags().flags;
  const { appVersion, shiftPicker } = useMst();
  const isOnline = useOnlineStatus();
  const { defaultNotification, errorNotification } = useNotification();
  const windowVisible = useWindowVisibility();

  // App version
  const newAppVersion = useRef<string | undefined>(undefined);

  // Auto-update state
  const [autoUpdateProcessTriggered, setAutoUpdateProcessTriggered] = useState(false);
  const [showAutoUpdateModal, setShowAutoUpdateModal] = useState(false);
  const autoUpdateCheckedForSession = useRef(false);

  // Polling Ref
  const pollingRef = useRef<NodeJS.Timeout | undefined>(undefined);

  // Workbox & Service Worker
  const [workbox, setWorkbox] = useState<Workbox | undefined>();

  // Configure workbox, register service worker, and check for new updates
  useConstructor(async () => {
    if ('serviceWorker' in navigator) {
      try {
        // Initialize workbox
        if (DEBUG)
          console.log(
            `<PWAUpdate> Initializing new workbox ${serviceWorkerFile} scope: ${serviceWorkerScope}`,
          );

        const wb = new Workbox(serviceWorkerFile, { scope: serviceWorkerScope });
        setWorkbox(wb);

        wb.addEventListener('waiting', event => {
          if (DEBUG) console.log('<PWAUpdate> Service worker waiting:', event);
          if (newAppVersion.current) {
            appVersion.setNewVersionAvailable(newAppVersion.current);

            Sentry.addBreadcrumb({
              category: 'PWAUpdate',
              message: `New version waiting: ${newAppVersion.current}`,
              level: 'info',
            });
          }
        });
        wb.addEventListener('activated', event => {
          if (DEBUG) console.log('<PWAUpdate> Service worker activated:', event);
        });
        wb.addEventListener('controlling', event => {
          if (DEBUG) console.log('<PWAUpdate> Service worker controlling:', event);
        });

        // Register a service worker
        if (DEBUG) console.log('<PWAUpdate> Registering service worker...');
        await wb.register({ immediate: true });
      } catch (error) {
        console.log('🚀 ~ file: PWAUpdate.tsx ~ line 30 ~ useConstructor ~ error', error);
        captureInSentryWithDetails(error, { serviceWorkerError: true });
      }
    } else {
      console.error('<PWAUpdate> Service workers are not supported in this browser.');
    }
  });

  /**
   * Check for a new potential update to the app on the server and if found install it
   * @return {*}
   */
  const checkForNewUpdate = useCallback(async () => {
    if (DEBUG) console.log(`\n<PWAUpdate> Checking for new service worker...`);

    // Get version from server
    let version;
    try {
      ({ version } = await getPWAVersion());
    } catch (error) {
      console.error('🚀 ~ file: PWAUpdate.tsx ~ line 76 ~ serviceWorkerUpdateCheck ~ error', error);
      return;
    }

    // Check if we should install the update
    const { shouldInstallPWAUpdate, hasNewVersion } = checkIfFlagsAllowUpdate(
      version,
      borerBlockUpdate,
      versionBlacklist,
      maxUpdateVersion,
      isOnline,
    );

    if (hasNewVersion) {
      Sentry.addBreadcrumb({
        category: 'PWAUpdate',
        message: `New version found: ${version}, should install: ${shouldInstallPWAUpdate}.`,
        level: 'info',
      });
    }

    // If we should not install the update, set not new version, return
    if (!shouldInstallPWAUpdate) {
      if (DEBUG) console.log(`<PWAUpdate> Do not install update...`);
      appVersion.setNewVersionNotAvailable();
      newAppVersion.current = undefined;
      return;
    }

    try {
      Sentry.addBreadcrumb({
        category: 'PWAUpdate',
        message: `Installing new version: ${newAppVersion.current}.`,
        level: 'info',
      });

      // Display update available indicator
      appVersion.setNewVersionAvailable(version);

      // Attempt to install the update
      newAppVersion.current = version;

      await installNewServiceWorkerIfFound(workbox);

      // don't do anything here, instead wait until the service worker is in a waiting state
    } catch (error) {
      if (error instanceof DOMException) {
        // If we get a DOMException, we have a waiting update, this is a issue we have noted in safari
        if (DEBUG) console.log(`<PWAUpdate> Waiting update found.....`);
        return;
      }

      // If we get any other error, log it and return
      console.error('🚀 ~ file: PWAUpdate.tsx ~ line 98 ~ serviceWorkerUpdateCheck ~ error', error);
      appVersion.setNewVersionNotAvailable();
      newAppVersion.current = undefined;
      captureInSentryWithDetails(error, { serviceWorkerError: true });
    }
  }, [maxUpdateVersion, versionBlacklist, borerBlockUpdate, isOnline, appVersion, DEBUG]);

  // Effects
  useEffect(() => {
    if (workbox) {
      // Poll for new service worker
      pollingRef.current = setInterval(() => {
        try {
          checkForNewUpdate();
        } catch (error) {
          console.log(
            '🚀 ~ file: PWAUpdate.tsx:142 ~ pollingRef.current=setInterval ~ error:',
            error,
          );
          captureInSentryWithDetails(error, { updateCheckError: true });
        }
      }, serviceWorkerPollingInterval);
    }

    return () => {
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
  }, [workbox, checkForNewUpdate, serviceWorkerPollingInterval]);

  // Auto-update effect
  useEffect(() => {
    if (DEBUG)
      console.table({
        'autoUpdateCheckedForSession.current': autoUpdateCheckedForSession.current,
        allowAutoUpdate,
        borerBlockUpdate,
        isOnline,
        'appVersion.hasNewUpdate': appVersion.hasNewUpdate,
      });

    if (windowVisible === false) {
      autoUpdateCheckedForSession.current = false;
      return;
    }
    if (!allowAutoUpdate || !isOnline || !borerBlockUpdate) {
      autoUpdateCheckedForSession.current = true;
      return;
    }
    if (autoUpdateCheckedForSession.current === true) {
      return;
    }
    if (autoUpdateCheckedForSession.current === false) autoUpdateCheckedForSession.current = true;

    if (appVersion.hasNewUpdate) {
      setAutoUpdateProcessTriggered(true);

      // Trigger notification
      defaultNotification(
        `There is a newer version of the borer app available, your app will automatically update in ${updateTimeoutSeconds} seconds if network quality is sufficient.`,
        {
          autoHideDuration: updateTimeoutSeconds * 1000 + 500,
        },
      );

      setTimeout(() => {
        setShowAutoUpdateModal(true);
      }, updateTimeoutSeconds * 1000);
    }
  }, [
    appVersion.hasNewUpdate,
    appVersion.newAppVersion,
    DEBUG,
    defaultNotification,
    appVersion.currentAppVersion,
    allowAutoUpdate,
    borerBlockUpdate,
    updateTimeoutSeconds,
    windowVisible,
    isOnline,
  ]);

  // Show manual update modal effect
  // (on shift change, will verify time since update last deferred)
  useEffect(() => {
    if (isOnline) appVersion.checkForUpdate();
  }, [shiftPicker.currentBorerShiftId, isOnline]);

  // Event Handlers
  const onDeferUpdate = (updateFailed = false) => {
    appVersion.deferUpdate(updateFailed);
    setShowAutoUpdateModal(false);
    setAutoUpdateProcessTriggered(false);
  };

  const onTriggerUpdate = async () => {
    if (DEBUG) console.log(`<PWAUpdate> onTriggerUpdate started`);
    await RxdbManager.instance.runSingleReplication();
    if (DEBUG) console.log(`<PWAUpdate> runSingleReplication completed`);
    // Trigger update
    if (workbox) {
      if (DEBUG) console.log(`<PWAUpdate> onTriggerUpdate within workbox `);
      installPendingUpdate(workbox);
      if (DEBUG) console.log(`<PWAUpdate> installPendingUpdate completed`);
    } else {
      console.error('🚀 ~ file: PWAUpdate.tsx ~ line 228 ~ onTriggerUpdate ~ error');
      onDeferUpdate(true);
      errorNotification('Failed to update app, please try again later.');
    }
  };

  return (
    <UpdateModal
      open={(appVersion.showUpdateModal && !autoUpdateProcessTriggered) || showAutoUpdateModal}
      onDeferUpdate={onDeferUpdate}
      isOnline={isOnline}
      autoUpdateModal={showAutoUpdateModal}
      onTriggerUpdate={onTriggerUpdate}
    />
  );
};

export default observer(PWAUpdate);
