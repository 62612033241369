import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: '12px 24px !important',
    },
    content: {
      marginTop: 0,
    },
    icon: {
      textAlign: 'center',
      marginBottom: 24,
    },
  }),
);
