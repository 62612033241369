import { Grid } from '@material-ui/core';
import {
  Checkbox,
  ExpansionPanel,
  FormControlLabel,
  GroupedAutoComplete,
  i18n,
  TextField,
} from '@nutrien/cxp-components';
import React, { useEffect, useMemo } from 'react';

import { EquipmentWithType } from '@/rxdb/EquipmentType/useEquipmentListByTypeName';
import { generateAssignmentString } from '@/utilities/generateAssignmentString';
import { sortAlphabeticallyByProperty } from '@/utilities/sortHelper';

import { rootStore } from '../../mobx-models/Root';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { EquipmentDocument } from '../../rxdb/Equipment/queryBuilder';
import { EquipmentType } from '../../rxdb/EquipmentType/queryBuilder';
import { DeficiencyPanelTypes } from '../../utilities/enums';
import { useStyles } from './DeficiencyDetailsExpansionPanel.styles';

export interface Props {
  onExpanded: (expanded: boolean, expansionPanelId: DeficiencyPanelTypes) => void;
  expanded: boolean;
  employees: Employee[];
  onEmployeeSelected: (employee: Employee) => void;
  selectedEmployee?: Employee;
  onDeficiencyTypeChanged?: (value: string) => void;
  deficiencyType?: string;
  deficiencyTypeErrorText?: string;
  onCommentChanged: (value: string) => void;
  comment: string;
  onWorkOrderRequired: (checked: boolean) => void;
  workOrderRequired: boolean;
  equipmentTypes?: EquipmentType[];
  equipmentType?: EquipmentType;
  onEquipmentTypeChanged?: (value: EquipmentType) => void;
  editingExistingDeficiency?: boolean;
  disableAllInputs: boolean;
  disableEquipmentField?: boolean;
  equipments: EquipmentDocument[];
  onEquipmentChanged?: (value: EquipmentDocument) => void;
  equipment?: EquipmentDocument;
  equipmentTypeId?: string;
  employeesLoading: boolean;
}

const expansionPanelId = DeficiencyPanelTypes.DETAILS;

const DeficiencyDetailsExpansionPanel: React.FC<Props> = (props: Props) => {
  const {
    onExpanded,
    expanded,
    employees,
    onEmployeeSelected,
    selectedEmployee,
    onDeficiencyTypeChanged,
    deficiencyType,
    deficiencyTypeErrorText,
    comment,
    onCommentChanged,
    onWorkOrderRequired,
    workOrderRequired,
    equipmentTypes,
    editingExistingDeficiency = false,
    disableAllInputs,
    disableEquipmentField = false,
    equipments,
    onEquipmentChanged,
    equipment,
    equipmentTypeId,
    employeesLoading,
  } = props;
  const classes = useStyles();

  const equipmentTypesById = useMemo(() => {
    const obj = {};
    equipmentTypes?.forEach(type => {
      obj[type.id] = type;
    });

    return obj;
  }, [equipmentTypes]);

  const onExpandPanel = (event: React.ChangeEvent<unknown>, expand: boolean) => {
    onExpanded(expand, expansionPanelId);
  };

  const onNameChange = (event: any, value: any) => {
    onEmployeeSelected(value);
  };

  const getNameFromEmployee = (employee: Employee) => {
    return employee.firstName ? `${employee.firstName} ${employee.lastName}` : '';
  };

  const onDeficiencyTypeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => onDeficiencyTypeChanged(event.target.value);

  const onCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    onCommentChanged(event.target.value);

  const onWorkOrderRequiredChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => onWorkOrderRequired(checked);

  const getEquipmentLabel = (equipmentItem?: EquipmentWithType) => {
    return equipmentItem ? `${equipmentItem.typeName} ${equipmentItem.name}` : '';
  };

  const onEquipmentChange = (event: any, value: EquipmentWithType) => {
    if (onEquipmentChanged) onEquipmentChanged(value);
  };

  const fillEquipmentTypes = useMemo((): EquipmentWithType[] => {
    if (equipments?.length) {
      return equipments
        .map(equipmentDoc => {
          const type = equipmentTypesById[equipmentDoc.equipmentTypeId];

          return {
            equipment: equipmentDoc,
            type,
            name: equipmentDoc.longName,
            typeName: type?.description,
            assignmentString: generateAssignmentString(
              equipmentDoc.assignedBorerEquipmentId === rootStore.equipment.selectedBorerId ||
                equipmentDoc.id === rootStore.equipment.selectedBorerId,
              !equipmentTypeId,
              type?.description,
            ),
          };
        })
        .sort((a, b) => {
          if (a.assignmentString === b.assignmentString) {
            return a.name.localeCompare(b.name);
          }
          return a.assignmentString.localeCompare(b.assignmentString);
        });
    }
    return [];
  }, [equipmentTypesById, equipments, equipmentTypeId]);

  useEffect(() => {
    // If pre-op deficiency type is Borer, self-assign.
    if (
      disableEquipmentField &&
      fillEquipmentTypes.length === 1 &&
      fillEquipmentTypes[0]?.equipment?.isBorer
    ) {
      onEquipmentChange(null, fillEquipmentTypes[0]);
    }
  }, [fillEquipmentTypes, disableEquipmentField]);

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Details')}
      key="deficiency-details"
      data-cy="deficiency-details"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={onExpandPanel}
      expansionPanelDetailProps={{ className: classes.panelDetails }}
      panelContent={
        <Grid container>
          <Grid item xs={12}>
            <GroupedAutoComplete
              id="deficiency-details-panel-input-employee-name"
              list={sortAlphabeticallyByProperty(employees, 'fullName')}
              label={i18n.t('Your name')}
              required
              getOptionLabel={getNameFromEmployee}
              onChange={onNameChange}
              value={selectedEmployee}
              showCaret
              disabled={disableAllInputs}
              loading={employeesLoading}
              groupByProperty="header"
              primaryProperty="fullName"
            />
          </Grid>
          {!editingExistingDeficiency && (
            <>
              <Grid item xs={12}>
                <GroupedAutoComplete
                  list={fillEquipmentTypes}
                  label={i18n.t('Equipment')}
                  required
                  getOptionLabel={getEquipmentLabel}
                  onChange={onEquipmentChange}
                  value={equipment}
                  showCaret
                  disabled={disableAllInputs || disableEquipmentField}
                  groupByProperty="assignmentString"
                  primaryProperty="name"
                  secondaryProperty="typeName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={i18n.t('Deficiency')}
                  required
                  onChange={onDeficiencyTypeChange}
                  value={deficiencyType}
                  data-cy="deficiency-type"
                  error={deficiencyTypeErrorText !== ''}
                  errorText={deficiencyTypeErrorText}
                  disabled={disableAllInputs}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              label="Comment (optional)"
              required
              onChange={onCommentChange}
              value={comment}
              data-cy="deficiency-comment"
              rows={3}
              multiline
              disabled={disableAllInputs}
            />
          </Grid>
          <Grid item xs={12} className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={onWorkOrderRequiredChanged}
                  name="work-request-required-checkbox"
                  checked={workOrderRequired}
                  disabled={disableAllInputs || editingExistingDeficiency}
                  role="work-request-required-checkbox"
                />
              }
              label={i18n.t('Work request required')}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default DeficiencyDetailsExpansionPanel;
