import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import { EquipmentDeficiencyLog, EquipmentDeficiencyLogCollection } from './queryBuilder';

const useEquipmentDeficiencyLog = () => {
  const equipmentDeficiencyLogCollection: EquipmentDeficiencyLogCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
  );

  const [deficiencyLogInitialized, setCollectionsInitialized] = useState(false);

  useEffect(() => {
    if (equipmentDeficiencyLogCollection) setCollectionsInitialized(true);
  }, [equipmentDeficiencyLogCollection]);

  const createEquipmentDeficiencyLog = (
    comment: string,
    siteEmployeeId: string,
    equipmentDeficiencyId: string,
  ) => {
    const doc: EquipmentDeficiencyLog = {
      ...generateBaseEntityWithCreatedOn(),
      comment,
      siteEmployeeId,
      equipmentDeficiencyId,
    };

    return equipmentDeficiencyLogCollection?.insert(doc);
  };

  return {
    createEquipmentDeficiencyLog,
    deficiencyLogInitialized,
    equipmentDeficiencyLogCollection,
  };
};

export { useEquipmentDeficiencyLog };
export default useEquipmentDeficiencyLog;
