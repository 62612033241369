import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { useCallback, useMemo } from 'react';
import type { RxDocument } from 'rxdb';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '@/mobx-models/Root';
import { INITIAL_COMMENT_HTML } from '@/utilities/constants';

import { BorerShiftCommentTypeDocument } from '../BorerShiftCommentType/queryBuilder';
import { useBorerShiftCommentType } from '../BorerShiftCommentType/useBorerShiftCommentType';
import RxdbCollectionName from '../rxdbCollectionName';
import {
  BorerShiftComment,
  BorerShiftCommentCollection,
  ExtendedBorerShiftComment,
} from './queryBuilder';

const createBorerShiftCommentMap = (
  commentDocs: RxDocument<BorerShiftComment>[],
  commentTypes: BorerShiftCommentTypeDocument[],
): ExtendedBorerShiftCommentMap => {
  const bsCommentMap: ExtendedBorerShiftCommentMap = new Map();

  // For each borer shift comment type, find the corresponding comment doc
  commentTypes.forEach(commentType => {
    const commentDocForCommentType = commentDocs.find(
      doc => doc.borerShiftCommentTypeId === commentType.id,
    );
    if (commentDocForCommentType) {
      const parsedComment = commentDocForCommentType?.comment
        ? HtmlReactParser(
            DOMPurify.sanitize(commentDocForCommentType?.comment.replace(/\u00a0/g, ' '), {
              ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'u', 'a', 'p', 'br', 'ul', 'li', 'ol'],
              ALLOWED_ATTR: [],
            }),
          )
        : INITIAL_COMMENT_HTML;

      bsCommentMap.set(commentType.description, {
        comment: commentDocForCommentType?.comment || '',
        borerShiftId: commentDocForCommentType?.borerShiftId || '',
        borerShiftCommentTypeId: commentDocForCommentType?.borerShiftCommentTypeId || '',
        isDeleted: commentDocForCommentType?.isDeleted || false,
        id: commentDocForCommentType?.id || '',
        updatedAt: commentDocForCommentType?.updatedAt || 0,
        version: commentDocForCommentType?.version || 1,
        parsedComment,
        displayInBorer: commentType.displayInBorer,
      });
    } else {
      // If no comment doc is found, create a new one
      bsCommentMap.set(commentType.description, {
        comment: undefined,
        borerShiftCommentTypeId: commentType.id,
        displayInBorer: commentType.displayInBorer,
      });
    }
  });
  return bsCommentMap;
};

interface BorerShiftCommentHook {
  upsertBorerShiftComment: (
    borerShiftCommentInput: ExtendedBorerShiftComment,
    // eslint-disable-next-line @typescript-eslint/ban-types
  ) => Promise<RxDocument<ExtendedBorerShiftComment, {}> | undefined>;
  borerShiftCommentsLoaded: boolean;
  borerShiftComments: ExtendedBorerShiftCommentMap;
  automationComments: ExtendedBorerShiftCommentMap;
}

export type ExtendedBorerShiftCommentMap = Map<string, Partial<ExtendedBorerShiftComment>>;

export const useBorerShiftComment = (): BorerShiftCommentHook => {
  const { shiftPicker } = useMst();
  const { borerShiftCommentTypesArray, automationCommentTypesArray } = useBorerShiftCommentType();
  const borerShiftCommentCollection: BorerShiftCommentCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_COMMENT,
  );

  const borerShiftCommentQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerShiftId: shiftPicker.currentBorerShiftId,
        },
        sort: [{ updatedAt: 'desc' }],
      }),
    [shiftPicker.currentBorerShiftId],
  );

  const { result: borerShiftCommentDocs, isFetching: borerShiftCommentDocsFetching } =
    useRxData<BorerShiftComment>(
      RxdbCollectionName.BORER_SHIFT_COMMENT,
      borerShiftCommentQueryConstructor,
    );

  const borerShiftComments = useMemo((): ExtendedBorerShiftCommentMap => {
    return createBorerShiftCommentMap(borerShiftCommentDocs, borerShiftCommentTypesArray);
  }, [borerShiftCommentDocs, borerShiftCommentTypesArray]);

  const automationComments = useMemo((): ExtendedBorerShiftCommentMap => {
    return createBorerShiftCommentMap(borerShiftCommentDocs, automationCommentTypesArray);
  }, [borerShiftCommentDocs, automationCommentTypesArray]);

  const upsertBorerShiftComment = async (borerShiftCommentInput: BorerShiftComment) => {
    return borerShiftCommentCollection?.upsert(borerShiftCommentInput);
  };

  return {
    upsertBorerShiftComment,
    borerShiftCommentsLoaded: !borerShiftCommentDocsFetching,
    borerShiftComments,
    automationComments,
  };
};

export default useBorerShiftComment;
