import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, i18n, Typography } from '@nutrien/cxp-components';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
    },
    messageContainer: {
      color: theme.palette.text.disabled,
      padding: '10px 0 15px 0',
    },
    okButton: {
      width: '100px !important',
      maxWidth: '100px !important',
      margin: '0px auto !important',
    },
  }),
);

interface Props {
  open: boolean;
  onOk: () => void;
  titleText: string;
  okButtonText?: string;
  message: string;
  maxHeight?: number;
  flipColors?: boolean;
  disableEnforceFocus?: boolean;
}

const GenericMessageModal: React.FC<Props> = ({
  open,
  onOk,
  titleText,
  okButtonText,
  message,
  maxHeight,
  flipColors = false,
  disableEnforceFocus = false,
}) => {
  const classes = useStyles();
  const defaultOkText = i18n.t('Ok');

  return (
    <Dialog
      open={open}
      onClose={onOk}
      titleText={titleText}
      backButton={false}
      disableEnforceFocus={disableEnforceFocus}
      disableBackdropClick
      disableEscapeKeyDown
      dialogActions={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={onOk}
              className={classes.okButton}
              variant="outlined"
              color={flipColors ? 'error' : 'primary'}
              noMinHeight
              id="ok-button"
            >
              {okButtonText || defaultOkText}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight={maxHeight ? `${maxHeight}px` : '250px'}
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Typography variant="body2" className={classes.messageContainer}>
        {message}
      </Typography>
    </Dialog>
  );
};

export default GenericMessageModal;
