import type { Theme } from '@material-ui/core';
import { createStyles, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CustomPalette, i18n, SidePanel } from '@nutrien/cxp-components';
import React, { useMemo, useState } from 'react';

import { useNotification } from '../../utilities';
import { capitalizeFirstLetter, toCamelCase, toKebabCase } from '../../utilities/utilityFunctions';
import DiscardDraftModal from '../DiscardDraftModal';
import GenericSidePanelHeader from '../GenericSidePanelHeader';
import Spinner from '../Spinner';

export interface DeleteModalConfig {
  title: string;
  bodyText: string;
  cancelButtonText: string;
  deleteButtonText: string;
  maxHeight?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '16px',
      width: '400px',
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    deleteButton: {
      margin: 'auto auto auto 15px !important',
    },
  }),
);

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  hasEdits: boolean;
  canSave: boolean;
  isSaving: boolean;
  setUndo: (value: boolean) => void;
  onSave: () => void;
  onDelete?: () => void;
  deleteButtonText?: string;
  title: string;
  titleForTestAttributes?: string;
  onCancel?: () => void;
  discardNotificationText?: string;
  deleteModalConfig?: DeleteModalConfig;
  loading?: boolean;
}

const GenericSidePanel: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  onCancel,
  hasEdits,
  canSave,
  isSaving,
  setUndo,
  discardNotificationText = 'Draft discarded',
  onSave,
  onDelete,
  deleteButtonText,
  title,
  children,
  deleteModalConfig,
  loading = false,
}: Props) => {
  const classes = useStyles();

  const dataTestId = useMemo(() => `${toKebabCase(title)}-side-panel`, [title]);
  const dataCy = useMemo(() => `${toCamelCase(title)}SidePanel`, [title]);

  const { closeSnackbar, errorNotification } = useNotification();

  // Side Panel Controls
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

  // Controls
  const openDiscardEditsModel = () => {
    if (hasEdits) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  };

  const onCancelDiscard = () => {
    setDiscardOpen(false);
    setUndo(false);
  };

  const onDiscard = () => {
    onClose();
    setDiscardOpen(false);
    if (onCancel) onCancel();
    errorNotification(discardNotificationText, {
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            setUndo(true);
            closeSnackbar(key);
            onOpen();
          }}
          id="undo-button"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  const onCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteClicked = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    if (onDelete) {
      setDeleteConfirmationOpen(false);
      onDelete();
    }
  };

  return (
    <div data-testid={dataTestId}>
      <SidePanel
        open={open}
        onClose={openDiscardEditsModel}
        transitionDuration={200}
        disableBackdropClick
        disableEnforceFocus
        ModalProps={{
          BackdropProps: {
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          },
        }}
      >
        <GenericSidePanelHeader title={title} onClose={openDiscardEditsModel} />
        <DialogContent className={classes.dialogContent} id="sidePanel">
          {loading ? <Spinner /> : children}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={openDiscardEditsModel}
                data-cy={`cancel${capitalizeFirstLetter(dataCy)}`}
                data-testid={`${dataTestId}-cancel-button`}
                noMinHeight
                id="cancel-button"
              >
                {i18n.t('Cancel')}
              </Button>
            </Grid>
            {onDelete && (
              <Grid item className={classes.deleteButton}>
                <Button
                  variant="text"
                  color="error"
                  onClick={handleDeleteClicked}
                  data-cy={`delete${capitalizeFirstLetter(dataCy)}`}
                  data-testid={`${dataTestId}-delete-button`}
                  noMinHeight
                  id="delete-button"
                >
                  {deleteButtonText || i18n.t('Delete')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                data-cy={`save${capitalizeFirstLetter(dataCy)}`}
                data-testid={`${dataTestId}-save-button`}
                noMinHeight
                onClick={onSave}
                disabled={!canSave || isSaving}
                isLoading={isSaving}
                id="save-button"
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </SidePanel>
      <DiscardDraftModal open={discardOpen} onCancel={onCancelDiscard} onDiscard={onDiscard} />
      <DiscardDraftModal
        open={deleteConfirmationOpen}
        onCancel={onCancelDelete}
        onDiscard={handleConfirmDelete}
        titleText={deleteModalConfig?.title}
        cancelText={deleteModalConfig?.bodyText}
        continueEditingButtonText={deleteModalConfig?.cancelButtonText}
        discardDraftButtonText={deleteModalConfig?.deleteButtonText}
        maxHeight={deleteModalConfig?.maxHeight}
      />
    </div>
  );
};

export default GenericSidePanel;
