import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, i18n, TextField } from '@nutrien/cxp-components';
import React, { FocusEvent, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100vh',
    },
  }),
);

interface Props {
  saveNewPassword: (password: string) => Promise<void>;
}

const NewPasswordArea = ({ saveNewPassword }: Props) => {
  const classes = useStyles();

  const [password, setPasssword] = useState('');

  const handlePasswordChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPasssword(value);
  };

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          color="primary"
          label={i18n.t('new password')}
          onBlur={handlePasswordChange}
          type="password"
        />
        <Button
          color="primary"
          variant="contained"
          disabled={password === ''}
          onClick={() => {
            saveNewPassword(password);
          }}
          id="save-button"
        >
          Save New Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewPasswordArea;
