import { SupplyItemDocument } from '../rxdb/SupplyItem/queryBuilder';
import { EnrichedSupply, SupplyDocument } from './../rxdb/Supply/queryBuilder';

// eslint-disable-next-line import/prefer-default-export
export class SupplyMapper {
  public static MapFromSupplyDocument(
    supplyDocument: SupplyDocument,
    supplyItemDocument: SupplyItemDocument,
  ): EnrichedSupply {
    return {
      id: supplyDocument.id,
      borerEquipmentId: supplyDocument.borerEquipmentId,
      quantity: supplyDocument.quantity,
      supplyItemId: supplyDocument.supplyItemId,
      supplyItemDescription: supplyItemDocument.description,
      otherDescription: supplyDocument.otherDescription,
      supplyItemUnit: supplyItemDocument.unit,
      isDeleted: supplyDocument.isDeleted,
      version: supplyDocument.version,
      updatedAt: supplyDocument.updatedAt,
      isNew: false,
    };
  }
}
