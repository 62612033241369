const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

const format = (val: number) => {
  return val.toLocaleString('en-US');
};

const stringifyNumber = (val: number) => {
  if (val < 20) return special[val];
  if (val % 10 === 0) return deca[Math.floor(val / 10) - 2] + 'ieth';
  return deca[Math.floor(val / 10) - 2] + 'y-' + special[val % 10];
};

export const useNumberFormatter = () => {
  return {
    format,
    stringifyNumber,
  };
};

export const numberWithSuffix = (i: number): string => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};
