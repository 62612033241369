export const scrollToCard = (appointmentId?: string) => {
  if (!appointmentId) return;
  const sidePanelContainer = document.querySelector('#sidePanel');
  const delayElement = document.querySelector(`[data-testid='delay-edit-card-${appointmentId}']`);

  if (delayElement && sidePanelContainer?.scrollTo) {
    sidePanelContainer.scrollTo({
      top:
        sidePanelContainer.scrollTop +
        delayElement.getBoundingClientRect().top -
        (!!window.scrollY ? 20 : 74),
      behavior: 'smooth',
    });
  }
};
