import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import {
  RxGraphQLReplicationPushQueryBuilder,
  RxReplicationWriteToMasterRow,
  WithDeleted,
} from 'rxdb/dist/types/types';
import { v4 as uuidv4 } from 'uuid';

import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';

import { BaseEntity } from '../models/BaseEntity';
import { CheckpointType } from './types';

export interface BaseEntityWithCreatedOn extends BaseEntity {
  createdOn: string;
}

export const getUnixMillisecondTimestamp = () => dayjs().valueOf();

export const generateBaseEntity = (isDeleted = false, initialVersion = 1): BaseEntity => {
  const baseValues = {
    id: uuidv4(),
    updatedAt: getUnixMillisecondTimestamp(),
    isDeleted,
    version: initialVersion,
  };

  return baseValues;
};

export const generateBaseEntityWithCreatedOn = (
  createdOn = dayjs().toISOString(),
  isDeleted = false,
  initialVersion = 1,
): BaseEntityWithCreatedOn => {
  const baseValues: BaseEntityWithCreatedOn = {
    ...generateBaseEntity(isDeleted, initialVersion),
    createdOn,
  };

  return baseValues;
};

/**
 * Creates a push query function that can be used by RxDB to push data to the server.
 *
 * @param {string} mutation
 * @param {(doc: RxDocument<unknown>) => Record<string, unknown>} variableMappingFunction
 * @param {Record<string, unknown>} [customVariables={}]
 * @return {*}  {RxGraphQLReplicationPushQueryBuilder}
 */
export const generatePushQuery = (
  mutation: string,
  variableMappingFunction: (doc: WithDeleted<RxDocType>) => Record<string, unknown>,
  customVariables: Record<string, unknown> = {},
  debug = false,
): RxGraphQLReplicationPushQueryBuilder => {
  return (rows: RxReplicationWriteToMasterRow<unknown>[]) => {
    const { newDocumentState } = rows[0]; // We assume on the first doc since our batch size is 1

    const variables = variableMappingFunction(newDocumentState);

    if (debug)
      console.log('🚀 ~ file: rxdbUtilityFunctions.ts:118 ~ variables:', {
        query: mutation,
        variables: {
          ...variables,
          ...customVariables,
        },
      });

    return {
      query: mutation,
      variables: {
        ...variables,
        ...customVariables,
      },
    };
  };
};

export default {
  generateBaseEntity,
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
};

export const refreshTokenOnSyncState = async (
  replicationState: RxGraphQLReplicationState<any, CheckpointType>,
  maxTimeToExpiry = 0, // seconds
  DEBUG = false,
) => {
  let tokenExpired = false;
  let tokenExpiringSoon = false;
  let currentIdTokenExpiration = -1;
  const currentTimestamp = dayjs().unix();
  const collectionName = replicationState.collection.name;

  try {
    // 1. Get the id token expiration on the token in storage
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const currentIdToken = currentSession.getIdToken().getJwtToken();
    currentIdTokenExpiration = currentSession.getIdToken().getExpiration(); // seconds

    const diff = currentIdTokenExpiration - currentTimestamp; // if this is positive its still a valid token
    if (DEBUG) console.log(`Token will expire in ${diff} seconds`);

    tokenExpired = diff <= 0;
    tokenExpiringSoon = diff <= maxTimeToExpiry;

    if (!tokenExpiringSoon && DEBUG)
      console.log(
        `Token newer than max time to expire... no need to refresh (${diff}s > ${maxTimeToExpiry}s)`,
      );

    // 2. If the id token is expired, refresh the token
    if (tokenExpired || tokenExpiringSoon) {
      const refreshToken = currentSession.getRefreshToken();
      if (DEBUG) console.log(`Refresh token: ${refreshToken}`);

      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (err) {
          if (DEBUG) console.log(`Token refresh failed:`, err);

          if (
            err.message === 'Request timed out.' ||
            err.message?.toLowerCase().includes('network') ||
            err.message?.toLowerCase().includes('offline')
          ) {
            return;
          }

          captureInSentryWithDetails(err, {
            replicationError: true,
            rxDBError: true,
            collectionName,
            tokenExpired,
            tokenExpiringSoon,
            refreshTokenError: true,
            currentIdTokenExpiration,
            currentTimestamp,
          });
          return;
        }

        const newIdToken = session.idToken.getJwtToken();
        if (DEBUG) console.log(`New ID token: ${newIdToken}`);

        replicationState.setHeaders({ Authorization: newIdToken });
      });
    } else {
      // 3. Token not expired but set the token on the collection headers regardless just in case the collection has a stale token
      replicationState.setHeaders({ Authorization: currentIdToken });
    }
  } catch (error) {
    console.error('Error refreshing token on collection:', replicationState.collection.name, error);

    if (
      error.message === 'Request timed out.' ||
      error.message?.toLowerCase().includes('network') ||
      error.message?.toLowerCase().includes('offline')
    ) {
      return;
    }
    captureInSentryWithDetails(error, {
      replicationError: true,
      rxDBError: true,
      collectionName,
      tokenExpired,
      tokenExpiringSoon,
      refreshTokenError: true,
      currentIdTokenExpiration,
      currentTimestamp,
    });
  }
};
