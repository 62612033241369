import { BorerShiftCrewMemberWithRole } from '@/rxdb/BorerShiftCrew/queryBuilder';

export const duplicateEmployeeAndRole = (
  crewMemberBeingValidated: BorerShiftCrewMemberWithRole,
  crewMemberList: BorerShiftCrewMemberWithRole[],
) => {
  if (!crewMemberBeingValidated?.employee?.id) return false;
  const membersWithMatchingEmployeeIdAndRoleId = crewMemberList.filter(
    crewMember =>
      crewMember.employee?.id === crewMemberBeingValidated.employee?.id &&
      crewMember.borerShiftCrewMemberRole?.id ===
        crewMemberBeingValidated.borerShiftCrewMemberRole?.id,
  );
  const employeeRoleCombination = membersWithMatchingEmployeeIdAndRoleId.map(
    crewMember => `${crewMember.employee?.id}-${crewMember.borerShiftCrewMemberRole?.id}`,
  );
  const hasDuplicates = new Set(employeeRoleCombination).size !== employeeRoleCombination.length;
  return hasDuplicates;
};
