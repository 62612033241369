import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from '@nutrien/cxp-components';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    zIndex: {
      zIndex: '1200 !important',
    },
  }),
);

const SnackbarWrapper = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ containerRoot: classes.zIndex }}
        bottom="76px"
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

export default SnackbarWrapper;
