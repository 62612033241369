import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Icons, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useMemo, useState } from 'react';

import AspectRatio from '../AspectRatio';
import ImagePreviewModal from '../ImagePreviewModal';
import PDFPreviewModal from '../PDFPreviewModal';
import { useStyles } from './Thumbnail.styles';

interface Props {
  url?: string;
  file?: File;
  onDelete?: (file: File) => void;
  name?: string;
  uniqueId?: string;
  attachmentId?: string;
  isRecentUpload?: boolean;
}

export interface ImageMetadata {
  fileName: string;
  isRecentUpload?: boolean;
}

const Thumbnail: React.FC<Props> = ({
  url,
  file,
  onDelete,
  name,
  uniqueId,
  attachmentId,
  isRecentUpload,
}: Props) => {
  const classes = useStyles();

  const [imageName, setImageName] = useState(file ? file.name : name);
  const isImage =
    imageName?.toLowerCase().endsWith('.jpg') ||
    imageName?.toLowerCase().endsWith('.jpeg') ||
    imageName?.toLowerCase().endsWith('.png');

  const [previewOpen, setPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(url === 'LOADING');
  const id = `${imageName}-${file?.lastModified}-${uniqueId}`;

  const [pdfPreviewOpen, setPDFPreviewOpen] = useState(false);
  const [pdfWindow, setPdfWindow] = useState<WindowProxy | undefined>();
  const [attachmentUrl, setAttachmentUrl] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);

  useEffect(() => {
    setImageName(file ? file.name : imageName);
    setIsLoading(url === 'LOADING');
  }, [url, file, name, imageName]);

  const handleDelete = (fileToDelete: File = file) => {
    setPreviewOpen(false);
    if (onDelete && fileToDelete) onDelete(fileToDelete);
  };

  const getAttachmentToShow = async () => {
    try {
      if (url) {
        setAttachmentUrl(url);
      } else if (file) {
        // If raw Blob (upload preview)

        const objectURL = URL.createObjectURL(file);
        if (objectURL) setAttachmentUrl(objectURL);
      }
    } catch (error) {
      console.log('🚀 ~ file: Thumbnail.tsx ~ line 138 ~ getAttachmentToShow ~ error', error);
    }
  };

  const onImgLoad = () => {
    setImageLoaded(true);
  };

  const imgStyle = useMemo(
    () => ({
      display: imageLoaded ? 'block' : 'none',
      objectFit: 'cover',
    }),
    [imageLoaded],
  );

  const onImgError = async () => {
    setImageLoadError(true);
    if (isRecentUpload) {
      setTimeout(async () => {
        await getAttachmentToShow();
        setImageLoadError(false);
      }, 2500);
    }
  };

  useEffect(() => {
    getAttachmentToShow();
  }, [attachmentId, url]);

  return (
    <>
      {!imageLoadError && (
        <Grid item xs={6}>
          {isLoading ? (
            <Grid item container direction="column" justify="center" alignItems="center" xs={12}>
              <div className={classes.photoCardAdd}>
                <AspectRatio ratio={1 / 1}>
                  <div className={classes.centerContent}>
                    <CircularProgress />
                  </div>
                </AspectRatio>
              </div>
            </Grid>
          ) : (
            <>
              {isImage && (
                <AspectRatio ratio={1 / 1}>
                  <img
                    className={classes.thumbnail}
                    onLoad={onImgLoad}
                    onError={onImgError}
                    id={id}
                    onClick={() => {
                      setPreviewOpen(true);
                    }}
                    src={attachmentUrl}
                    alt="Thumbnail"
                    style={imgStyle}
                  />
                  {!imageLoaded && (
                    <div className={classes.photoCardAdd}>
                      <AspectRatio ratio={1 / 1}>
                        <div className={classes.centerContent}>
                          <CircularProgress />
                        </div>
                      </AspectRatio>
                    </div>
                  )}
                </AspectRatio>
              )}
              {!isImage && (
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  xs={12}
                >
                  <div className={classes.photoCardAdd}>
                    <AspectRatio ratio={1 / 1}>
                      <Box
                        className={classes.centerContent}
                        style={{
                          textAlign: 'center',
                          lineBreak: 'anywhere',
                          padding: 5,
                        }}
                        onClick={() => {
                          const windowProxy = window.open();
                          if (windowProxy) setPdfWindow(windowProxy);
                          setPDFPreviewOpen(true);
                        }}
                      >
                        <Icons.FileTextFeather
                          color="primary"
                          className={classes.icon}
                          size="small"
                        />
                        <Typography
                          variant="caption"
                          className={classes.thumbnailText}
                          style={{ fontSize: 10 }}
                        >
                          {imageName}
                        </Typography>
                      </Box>
                    </AspectRatio>
                  </div>
                </Grid>
              )}
              {previewOpen && (
                <ImagePreviewModal
                  open={previewOpen}
                  onClose={() => {
                    setPreviewOpen(false);
                  }}
                  url={attachmentUrl}
                  onDelete={onDelete ? handleDelete : undefined}
                  imageTitle={imageName || ''}
                />
              )}
              {pdfPreviewOpen && (
                <PDFPreviewModal
                  url={attachmentUrl}
                  open={pdfPreviewOpen}
                  onClose={() => {
                    setPDFPreviewOpen(false);
                  }}
                  imageTitle={imageName || ''}
                  pdfWindow={pdfWindow}
                />
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default Thumbnail;
