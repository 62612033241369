import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type {
  MigrationStrategies,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  WithDeleted,
} from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import MigrationHelper from '../MigrationHelper';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { CheckpointType } from '../types';

export interface BorerShiftCrewMember extends BaseEntity {
  readonly borerShiftCrewId: string;
  readonly siteEmployeeId: string | null;
  readonly employeeOrder: number;
  readonly borerShiftCrewMemberRoleId: string;
}
export type BorerShiftCrewMemberCollection = RxCollection<BorerShiftCrewMember> | null;
export type BorerShiftCrewMemberDocument = RxDocument<BorerShiftCrewMember>;

export const borerShiftCrewMemberSchema: RxJsonSchema<BorerShiftCrewMember> = {
  type: 'object',
  version: 4,
  description: 'describes an borer shift crew member object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    borerShiftCrewId: {
      type: 'string',
      ref: RxdbCollectionName.BORER_SHIFT_CREW,
    },
    siteEmployeeId: { type: ['string', 'null'], ref: RxdbCollectionName.EMPLOYEES },
    employeeOrder: { type: ['number', 'null'] },
    borerShiftCrewMemberRoleId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.BORER_SHIFT_CREW_MEMBER_ROLE,
    },
  },
};

export const borerShiftCrewMemberMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCrewMemberDocument) => {
    return { ...oldDoc, employeeOrder: null };
  },
  2: (oldDoc: BorerShiftCrewMemberDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
  3: async (oldDoc: BorerShiftCrewMemberDocument) => {
    // Note that this schema changed introduced `borerShiftCrewMemberRoleId` as a nullable column.
    // This data is prepopulated on the back-end.
    // Before we can synchronize with the BE, we must migrate the existing data to the new schema version.
    // We don't know what data exists on the BE before synchronization,
    // so we cannot know what the correct value of this property is,
    // and thus we cannot set it here.
    // That's OK as we don't need it at this point in time anyway.
    // The documents migrated from V2 to V3 will not be valid V3 documents for this migration,
    // but they will be by the time we've completed synchronization.
    // Upon synchronization, we'll pull all documents from the BE.
    // These documents will have the latest version of borerShiftCrewMemberRoleId.
    // The RxDB Conflict Handler will detect that they're different than the existing local documents and replace them.
    // The only documents that don't get updated in this way are ones that do not yet exist on the BE.
    // Those will need to have their `borerShiftCrewMemberRoleId` set;
    // See `borerShiftCrewMemberPushModifier` below for details.
    //
    // Note that the `BorerShiftCrewMember` type is neither nullable nor optional.
    // Not only does this not align with the documents returned by this migration,
    // but it also does not align with the schema.
    // TODO: resolve this if/when we start applying strict type checking. https://nutrien.atlassian.net/browse/MDP-7927
    return oldDoc;
  },
  // This migration is a NoOp because of current development policy
  // of creating new migrations whenever someone starts work on a new branch.
  // No schema changes were introduced between V3 and V4.
  4: (oldDoc: BorerShiftCrewMemberDocument) => oldDoc,
};

const borerShiftCrewMemberFeedQuery = print(gql`
  query borerShiftCrewMemberFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftCrewMemberFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftCrewId
      siteEmployeeId
      id
      isDeleted
      updatedAt
      employeeOrder
      version
      borerShiftCrewMemberRoleId
    }
  }
`);

const basePullQueryBuilder = generatePullQuery(
  borerShiftCrewMemberFeedQuery,
  PullQueryContext.Borer,
);
let initialPullQuery = true;

export const borerShiftCrewMemberPullQueryBuilder = (
  lastPulledCheckpoint: CheckpointType | null,
  limit: number,
) => {
  // HACK: While testing MDP-7876 for the v1.45 release, QA discovered a bug in the RC environment.
  // The BE had been previously updated to v1.45 code & data.
  // However, the bug occurred during the FE v1.44->v1.45 upgrade process.
  // To diagnose and retest this, we needed to downgrade the FE back to v1.44, reset the data,
  // re-upgrade to v1.45, and then ensure that the upgrade process updated all data correctly.
  // However, after downgrading, the reset pulled data from the v1.45 BE, but the FE only knew about v1.44 properties & logic.
  // Specifically, it was missing the `borerShiftCrewMemberRoleId` property.
  // So it synchronized all BorerShiftCrewMember documents without this property.
  // When it came time to synchronize post-upgrade, RxDB requested updated documents, of which there were none;
  // all documents (without `borerShiftCrewMemberRoleId`) were already synchronized from the BE.
  // Thus RxDB got into a state where it would not re-pull existing document, and those documents were incomplete.
  // This blocked retesting of the upgrade process against v1.45-upgraded BEs (including RC).
  //
  // To work around this, we introduced `initialPullQuery`.
  // When Borer is refreshed (such as at the start of a v1.44->v1.45 upgrade),
  // this module-local variable is set to `false`.
  // When that happens, we ignore the existing `lastPulledCheckpoint`,
  // and thus force RxDB to pull all of the existing BorerShiftCrewMember documents
  // (for the assigned Borer, and within the usual 2-week update window).
  // These documents contain `borerShiftCrewMemberRoleId`, as expected from a v1.45 BE.
  // RxDB then detects a conflict with its own internal copy of these documents, and replaces them normally.
  // We only need to bypass `lastPulledCheckpoint` once to kick off this process;
  // hence why we set `initialPullQuery = false` on the first runthrough.
  // After that, `lastPulledCheckpoint` correctly follows the synchronization time of the last pulled document in the batch as usual.
  // In effect, this is doing a mini data reset, except that:
  //   * It's localized to BorerShiftCrewMember
  //   * It doesn't drop records that are modified prior to the v1.45 upgrade.
  // It does introduce some inefficiency, as it will trigger a refresh whenever the app reloads.
  // TODO: remove this once all clients are updated to v1.45 https://nutrien.atlassian.net/browse/MDP-7927
  if (initialPullQuery) {
    initialPullQuery = false;
    return basePullQueryBuilder(null, limit);
  }
  return basePullQueryBuilder(lastPulledCheckpoint, limit);
};

const setBorerShiftCrewMemberMutation = print(gql`
  mutation setBorerShiftCrewMember($input: SetBorerShiftCrewMemberInput) {
    setBorerShiftCrewMember(input: $input) {
      id
      siteId
      shiftId
      borerEquipmentId
      borerShiftCrewMemberRoleId
    }
  }
`);

type SetBorerShiftCrewMemberInput = {
  id: string;
  version: number;
  borerShiftCrewId: string;
  siteEmployeeId: string;
  employeeOrder: number;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  borerShiftCrewMemberRoleId: string;
};

const mapBorerShiftCrewMemberDocToInput = (
  doc: BorerShiftCrewMemberDocument,
): SetBorerShiftCrewMemberInput => {
  return {
    id: doc.id,
    borerShiftCrewId: doc.borerShiftCrewId,
    siteEmployeeId: doc.siteEmployeeId,
    employeeOrder: doc.employeeOrder,
    createdOn: null,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted,
    version: doc.version,
    borerShiftCrewMemberRoleId: doc.borerShiftCrewMemberRoleId,
  };
};

export const borerShiftCrewMemberPushQueryBuilder = generatePushQuery(
  setBorerShiftCrewMemberMutation,
  (doc: BorerShiftCrewMemberDocument) => ({
    input: mapBorerShiftCrewMemberDocToInput(doc),
  }),
  undefined,
);

// As mentioned above, most documents should be resynchronized
// and thus load their `borerShiftCrewMemberRoleId` from the database.
// Any documents that are created locally/offline while on v1.45
// are getting their `borerShiftCrewMemberRoleId` set from the UI.
// That still leaves some cases where `borerShiftCrewMemberRoleId` may be absent:
// 1. When a BorerShiftCrewMember record is created on v1.44,
//   and then the app upgrades before synchronization can happen.
// 2. When a BorerShiftCrewMember record is updated on v1.44,
//   and then the app upgrades before synchronization can happen.
// 3. When a BorerShiftCrewMember record is deleted on v1.44,
//   and then the app upgrades before synchronization can happen.
//   (Note that this is just a special case of #2, since deletes are soft; we just update a property).
// In these cases, we must supply a `borerShiftCrewMemberRoleId` before we push the record;
// otherwise the BE will reject the record.
// (This is true even when we're deleting a record; the BE does not allow any mutation of a record without a valid role ID.)
// This logic sets the `borerShiftCrewMemberRoleId` if it does not exist already before the document is pushed to the BE.
// The logic is, roughly speaking, the same that the BE used when it upgraded all BorerShiftCrewMembers from v1.44 to v1.45,
// based on the prior semantics attached to `employeeOrder`.
// Note that it assumes that the correct roles appear first and second in the returned array.
// TODO: remove this once all apps have upgraded to v1.45. https://nutrien.atlassian.net/browse/MDP-7927
export const borerShiftCrewMemberPushModifier = async (
  docData: WithDeleted<BorerShiftCrewMemberDocument>,
): Promise<WithDeleted<BorerShiftCrewMemberDocument> | null> => {
  if (!docData.borerShiftCrewMemberRoleId) {
    const newDoc = { ...docData };

    const roles = await MigrationHelper.instance.getBorerShiftCrewMemberRoleIds();
    if (!roles || roles.length === 0) {
      throw new Error('No default borer shift crew member roles found');
    }
    if (newDoc.employeeOrder === null) {
      throw new Error('Employee order is null, cannot set default roles based on employee order');
    }

    if (newDoc.employeeOrder === 0) {
      newDoc.borerShiftCrewMemberRoleId = roles[0].id;
    } else if (newDoc.employeeOrder >= 1) {
      newDoc.borerShiftCrewMemberRoleId = roles[1].id;
    }
    return newDoc;
  }
  return docData;
};
