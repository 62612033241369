import { useRxData } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { DelayActivityTypeCategory } from './queryBuilder';

export const useDelayActivityTypeCategory = () => {
  const { result: allDelayTypeCategories, isFetching: allDelayTypeCategoriesFetching } =
    useRxData<DelayActivityTypeCategory>(
      RxdbCollectionName.DELAY_ACTIVITY_TYPE_CATEGORY,
      collection => collection.find(),
    );

  return {
    allDelayTypeCategories,
    allDelayTypeCategoriesFetching,
  };
};

export default useDelayActivityTypeCategory;
