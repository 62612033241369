import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { EmployeeCollection, EmployeeDocument } from '../Employees/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity } from '../rxdbUtilityFunctions';
import { BorerShiftActivityEmployeeCollection } from './queryBuilder';

export const useBorerShiftActivityEmployees = () => {
  const borerShiftActivityEmployeeCollection: BorerShiftActivityEmployeeCollection =
    useRxCollection(RxdbCollectionName.BORER_SHIFT_ACTIVITY_EMPLOYEES);
  const employeeCollection: EmployeeCollection = useRxCollection(RxdbCollectionName.EMPLOYEES);

  const [borerShiftActivityEmployeesInitialized, setBorerShiftActivityEmployeesInitialized] =
    useState(false);

  useEffect(() => {
    if (borerShiftActivityEmployeeCollection) setBorerShiftActivityEmployeesInitialized(true);
  }, [borerShiftActivityEmployeeCollection]);

  useEffect(() => {
    if (employeeCollection && borerShiftActivityEmployeeCollection) {
      setBorerShiftActivityEmployeesInitialized(true);
    }
  }, [employeeCollection, borerShiftActivityEmployeeCollection]);

  const listBorerShiftActivityEmployees = async (
    activityId: string | undefined,
  ): Promise<EmployeeDocument[]> => {
    if (!activityId || !borerShiftActivityEmployeeCollection || !employeeCollection) {
      return [];
    }
    try {
      const borerShiftEmployees = await borerShiftActivityEmployeeCollection
        .find({
          selector: {
            $and: [{ borerActivityId: activityId }],
          },
        })
        .exec();

      const result: EmployeeDocument[] = await Promise.all(
        borerShiftEmployees.map(borerShiftEmployee =>
          borerShiftEmployee.populate('siteEmployeeId'),
        ),
      );

      return result;
    } catch (error) {
      console.log(
        '🚀 ~ file: useBorerShiftActivityEmployees.ts ~ line 53 ~ listBorerShiftActivityEmployees ~ error',
        error,
      );
      throw error;
    }
  };

  const createBorerShiftActivityEmployee = (borerActivityId: string, siteEmployeeId: string) => {
    const doc = {
      ...generateBaseEntity(),
      borerActivityId,
      siteEmployeeId,
      isDeleted: false,
    };

    return borerShiftActivityEmployeeCollection?.insert(doc);
  };

  const deleteBorerShiftActivityEmployee = async (
    borerActivityId: string,
    siteEmployeeId: string,
  ) => {
    const doc = await borerShiftActivityEmployeeCollection
      ?.findOne({
        selector: {
          borerActivityId,
          siteEmployeeId,
        },
      })
      .exec();

    await doc?.remove();
  };

  const setEmployeesOnBorerShiftActivity = async (
    borerActivityId: string,
    siteEmployeeIds: string[],
  ) => {
    // Get the current employees on that activity
    const existingEmployeesOnActivity = await listBorerShiftActivityEmployees(borerActivityId);
    const existingEmployeeIds = existingEmployeesOnActivity.map(employee => employee.id);

    // If none create all of the employee ids for the activity
    if (existingEmployeesOnActivity.length === 0) {
      return Promise.all(
        siteEmployeeIds.map(siteEmployeeId =>
          createBorerShiftActivityEmployee(borerActivityId, siteEmployeeId),
        ),
      );
    }

    // If there are some determine which ones need to be added and which are deleted
    const siteEmployeeIdsToAdd = siteEmployeeIds.filter(id => !existingEmployeeIds.includes(id));

    await Promise.all(
      siteEmployeeIdsToAdd.map(siteEmployeeId =>
        createBorerShiftActivityEmployee(borerActivityId, siteEmployeeId),
      ),
    );

    const siteEmployeeIdsToDelete = existingEmployeeIds.filter(id => !siteEmployeeIds.includes(id));

    await Promise.all(
      siteEmployeeIdsToDelete.map(siteEmployeeId =>
        deleteBorerShiftActivityEmployee(borerActivityId, siteEmployeeId),
      ),
    );
  };

  return {
    listBorerShiftActivityEmployees,
    borerShiftActivityEmployeesInitialized,
    borerShiftActivityEmployeeCollection,
    setEmployeesOnBorerShiftActivity,
  };
};

export default useBorerShiftActivityEmployees;
