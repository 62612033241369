import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useMst } from '@/mobx-models/Root';
import useTargetBorerRunningTimes from '@/rxdb/TargetBorerRunningTimes/useTargetBorerRunningTimes';
import useActualCuttingTimes from '@/utilities/hooks/useActualCuttingTimes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
    },
  }),
);

const ShiftEndRunningTimesCard: React.FC = () => {
  const classes = useStyles();

  const { shiftPicker } = useMst();

  const { dayShiftStart, dayShiftEnd, nightShiftStart, nightShiftEnd } =
    useTargetBorerRunningTimes();

  const { actualCuttingStartTime, actualCuttingEndTime } = useActualCuttingTimes();

  return (
    <>
      <Card className={classes.cardRoot} onClick={() => {}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{i18n.t('Running time')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Target Start')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {shiftPicker.isDayShift() ? dayShiftStart : nightShiftStart}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Actual Start')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {actualCuttingStartTime}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Target end')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              {shiftPicker.isDayShift() ? dayShiftEnd : nightShiftEnd}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Actual end')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{actualCuttingEndTime}</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default observer(ShiftEndRunningTimesCard);
