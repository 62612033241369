import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface HazardLog extends BaseEntity {
  readonly groundHazard: string;
  readonly comment: string;
  readonly reportedBy: string;
  readonly createdOn?: string;
  readonly groundControlSets: string[];
  readonly updateLastViewed: boolean | null;
}
export type HazardLogDocument = RxDocument<HazardLog>;
export type HazardLogCollection = RxCollection<HazardLog> | null;

export const hazardLogSchema: RxJsonSchema<HazardLog> = {
  type: 'object',
  version: 1,
  description: 'describes a hazard log object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    groundHazard: { type: 'string' },
    comment: { type: 'string' },
    groundControlSets: {
      type: 'array',
      ref: 'ground_control_sets',
      items: {
        type: 'string',
      },
    },
    reportedBy: {
      ref: 'employees',
      type: 'string',
    },
    createdOn: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updateLastViewed: { type: ['boolean', 'null'] },
  },
};

export const groundHazarLogdMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: HazardLogDocument) => {
    return {
      ...oldDoc,
    };
  },
};

const groundHazardLogFeedQuery = print(gql`
  query groundHazardLogFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    groundHazardLogFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      comment
      groundHazard
      groundControlSets
      reportedBy
      updatedAt
      isDeleted
      version
      createdOn
    }
  }
`);

export const hazardLogsPullQueryBuilder = generatePullQuery(
  groundHazardLogFeedQuery,
  PullQueryContext.Borer,
  defaultDoc90Day,
);

const createGroundHazardLogMutation = print(gql`
  mutation CreateGroundHazardLog($hazardLog: CreateGroundHazardLogInput) {
    createGroundHazardLog(input: $hazardLog) {
      id
      siteId
      borerEquipmentId
      groundHazardId
    }
  }
`);

type CreateGroundHazardLogInput = {
  id: string;
  createdOn: string | null;
  reportedById: string;
  comment: string;
  groundHazardId: string;
  groundControlSets: string[];
  updateLastViewed: boolean | null;
};

const mapGroundHazardLogDocToInput = (doc: HazardLogDocument): CreateGroundHazardLogInput => {
  return {
    id: doc.id,
    createdOn: doc.createdOn || null,
    reportedById: doc.reportedBy,
    comment: doc.comment,
    groundHazardId: doc.groundHazard,
    groundControlSets: doc.groundControlSets,
    updateLastViewed: false,
  };
};

export const hazardLogPushQueryBuilder = generatePushQuery(
  createGroundHazardLogMutation,
  (doc: HazardLogDocument) => ({
    hazardLog: mapGroundHazardLogDocToInput(doc),
  }),
);
