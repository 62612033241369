import { useEffect, useState } from 'react';
import { RxCollection } from 'rxdb';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { InspectionType } from '../../utilities/constants';
import { Inspection, InspectionDocument } from '../Inspections/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import { InspectionResult, InspectionResultDocument } from './queryBuilder';

export interface InspectionResultItem {
  inspectionDetails: InspectionDocument;
  inspectionResult: InspectionResultDocument;
}

const useInspectionResults = (inspectionId?: string) => {
  const inspectionsCollection = useRxCollection(
    RxdbCollectionName.INSPECTIONS,
  ) as RxCollection<Inspection>;
  const inspectionResultsCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  ) as RxCollection<InspectionResult>;
  const { shiftPicker } = useMst();

  const [inspectionsInitialized, setInspectionsInitialized] = useState(false);
  const [inspectionResultForId, setInspectionResultForId] = useState<InspectionResultDocument>();
  const [shiftCardInspectionsList, setShiftCardInspectionsList] = useState<InspectionResultItem[]>(
    [],
  );
  const [inspectionCount, setInspectionCount] = useState<number>(0);

  useEffect(() => {
    if (inspectionsCollection && inspectionResultsCollection) {
      setInspectionsInitialized(true);
    }
  }, [inspectionsCollection, inspectionResultsCollection]);

  const listInspections = async (borerShiftId: string): Promise<InspectionResultItem[]> => {
    if (!inspectionsCollection) return;
    if (!inspectionResultsCollection) return;

    let inspectionResults: InspectionResultDocument[];
    try {
      inspectionResults = await inspectionResultsCollection
        .find({
          selector: {
            borerShiftId,
          },
        })
        .exec();
      if (!inspectionResults) return [];
    } catch (error) {
      console.log('🚀 ~ file: useInspectionResults.tsx ~ line 58 ~ listInspections ~ error', error);
      return;
    }

    let inspections: Map<string, InspectionDocument>;
    try {
      inspections = await inspectionsCollection
        .findByIds(inspectionResults.map(result => result.inspectionId))
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useInspectionResults.tsx ~ line 58 ~ listInspections ~ error', error);
    }

    return inspectionResults.map(result => {
      return {
        inspectionResult: result,
        inspectionDetails: inspections.get(result.inspectionId),
      };
    });
  };

  /**
   * List the PreOp Checks in this shift based on the borerShiftId
   * Also includes Borer / Post-cutting / Borer Flexiveyor checks
   *
   * @return {InspectionResultDocument[]} List of inspection results that are pre op checks
   */
  const listPreOpChecksThisShift = async (): Promise<InspectionResultItem[]> => {
    if (!inspectionResultsCollection || !shiftPicker.currentBorerShiftId || !inspectionsCollection)
      return [];

    const inspectionResults: InspectionResultDocument[] = await inspectionResultsCollection
      .find({
        selector: {
          borerShiftId: {
            $eq: shiftPicker.currentBorerShiftId,
          },
        },
        sort: [
          {
            updatedAt: 'desc',
          },
        ],
      })
      .exec();

    const inspections: Map<string, InspectionDocument> = await inspectionsCollection
      .findByIds(inspectionResults.map(result => result.inspectionId))
      .exec();

    const inspectionResultsWithInspection = inspectionResults
      .map(result => {
        return {
          inspectionResult: result,
          inspectionDetails: inspections.get(result.inspectionId),
        };
      })
      .filter(
        inspection =>
          inspection.inspectionDetails?.inspectionType === InspectionType.PRE_OP ||
          inspection.inspectionDetails?.inspectionType ===
            InspectionType.BORER_FLEXIVEYOR_PREOP_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BORER_CUTTING_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.POST_CUTTING_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINER_PRE_OP ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINER_RUNNING ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BRIDGES_PRE_OP ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BRIDGES_RUNNING ||
          inspection.inspectionDetails?.inspectionType === InspectionType.EQUIPMENT_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BORER_INSPECTION ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINEVEYOR_INSPECTION ||
          inspection.inspectionDetails?.inspectionType ===
            InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING ||
          inspection.inspectionDetails?.inspectionType ===
            InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING,
      );

    return inspectionResultsWithInspection;
  };

  useEffect(() => {
    let subscription: Subscription | undefined;

    if (!!inspectionResultsCollection && inspectionId) {
      subscription = inspectionResultsCollection
        .findOne({ selector: { id: { $eq: inspectionId } } })
        .$.subscribe(item => {
          setInspectionResultForId(item);
        });
    } else {
      setInspectionResultForId(undefined);
    }
    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [inspectionId, inspectionResultsCollection]);

  const getCurrrentshiftCardInspectionsList = async () => {
    if (shiftPicker.currentBorerShiftId) {
      const results = await listInspections(shiftPicker.currentBorerShiftId);
      setShiftCardInspectionsList(results);
      getInspectionCount(results);
    } else {
      setShiftCardInspectionsList([]);
      getInspectionCount([]);
    }
  };

  const getInspectionCount = (list: InspectionResultItem[]) => {
    let count = 0;
    list.forEach(inspection => {
      const { inspectionDetails } = inspection;
      const inspectionType = inspectionDetails?.inspectionType;
      if (
        inspectionType === InspectionType.AREA_CHECK ||
        inspectionType === InspectionType.CORY_AREA_CHECK ||
        inspectionType === InspectionType.CORY_LUNCH_AREA_CHECK ||
        inspectionType === InspectionType.CORY_REFUGE_CHECK ||
        inspectionType === InspectionType.CORY_MONTHLY_PANEL_INSPECTION ||
        inspectionType === InspectionType.VANSCOY_AREA_PREOP_CUTTING_CHECK ||
        inspectionType === InspectionType.VANSCOY_PANEL_INSPECTION ||
        inspectionType === InspectionType.AREA_CHECK_LUNCH_TABLE ||
        inspectionType === InspectionType.AREA_CHECK_EXTENSIBLE_DRIVE ||
        inspectionType === InspectionType.AREA_CHECK_MINING_FACE ||
        inspectionType === InspectionType.AREA_CHECK_BREAKTHROUGH_ROOM ||
        inspectionType === InspectionType.AREA_CHECK_REFUGE ||
        inspectionType === InspectionType.CUTTING_PERMIT ||
        inspectionType === InspectionType.REHAB_CUTTING_PERMIT ||
        inspectionType === InspectionType.BIT_RACK_CHECK
      ) {
        count++;
      }
    });
    setInspectionCount(count);
  };

  useEffect(() => {
    if (inspectionsInitialized && inspectionResultsCollection) {
      getCurrrentshiftCardInspectionsList();
      inspectionResultsCollection.find().$.subscribe(() => {
        getCurrrentshiftCardInspectionsList();
      });
    }
  }, [shiftPicker.currentBorerShiftId, inspectionsInitialized, inspectionResultsCollection]);

  return {
    listInspections,
    inspectionsInitialized,
    inspectionResultsCollection,
    listPreOpChecksThisShift,
    inspectionResultForId,
    shiftCardInspectionsList,
    inspectionCount,
  };
};

export default useInspectionResults;
