import { Box, createStyles, Grid, makeStyles } from '@material-ui/core';
import { Card, i18n, TextField, Typography } from '@nutrien/cxp-components';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

import { PanelDrawingCollection } from '../../rxdb/PanelDrawing/queryBuilder';
import { PanelDrawingCommentDocument } from '../../rxdb/PanelDrawingComment/queryBuilder';
import usePanelDrawingComment, {
  JSONComment,
  PartialPanelDrawingComment,
} from '../../rxdb/PanelDrawingComment/usePanelDrawingComment';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import { useNotification } from '../../utilities';
import { PANEL_DRAWING_COLOURS, PANEL_DRAWING_COLOURS_WITH_KEYS } from '../../utilities/constants';
import GenericSidePanel from '../GenericSidePanel';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    colourBox: {
      height: 44,
      width: 44,
      borderRadius: '8px',
      marginRight: '32px',
    },
    selectedColourBox: {
      border: '4px solid #2AAB3F',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  panelDrawingId: string;
  existingComments: PartialPanelDrawingComment[];
}

const EditPanelDrawingCommentPanel = ({
  open,
  onClose,
  onOpen,
  onCancel,
  panelDrawingId,
  existingComments,
}: Props) => {
  const classes = useStyles();
  const panelDrawingCollection: PanelDrawingCollection = useRxCollection(
    RxdbCollectionName.PANEL_DRAWINGS,
  );
  const { panelDrawingCommentCollectionInitialized, updatePanelDrawingComment } =
    usePanelDrawingComment();

  const { successNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    comment: '',
  });

  const [selectedColourHex, setSelectedColourHex] = useState(PANEL_DRAWING_COLOURS[0]);
  const [commentText, setCommentText] = useState<string>('');
  const [existingCommentDoc, setExistingCommentDoc] = useState<PanelDrawingCommentDocument>();

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setErrors({
        comment: '',
      });
      setHasEdits(false);
      setCanSave(false);
      setIsSaving(false);
      setSelectedColourHex(PANEL_DRAWING_COLOURS[0]);
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open, panelDrawingCommentCollectionInitialized, panelDrawingId]);

  // Input Validation
  const validateSave = () => {
    let validSave = true;

    if (Object.values(errors).find(error => error !== '')) {
      validSave = false;
    }

    setCanSave(validSave && hasEdits && panelDrawingCommentCollectionInitialized);
  };

  useEffect(() => {
    validateSave();
  }, [errors, panelDrawingCommentCollectionInitialized, hasEdits]);

  const onCommentChanged = (value: string) => {
    setCommentText(value);
    setHasEdits(true);
  };

  const getCommentJSON = () => {
    const comment: JSONComment = { commentText, colourHex: selectedColourHex };
    return JSON.stringify(comment);
  };

  // Save
  const onSave = async () => {
    setIsSaving(true);

    try {
      const existingCommentId = existingCommentDoc ? existingCommentDoc.id : undefined;

      const newComment: PartialPanelDrawingComment = {
        id: existingCommentId || uuidv4(),
        comment: getCommentJSON(),
        panelDrawingId,
      };

      const existingPanel = await panelDrawingCollection?.findOne(panelDrawingId).exec();

      if (existingPanel) {
        // Save to existing panel if exists
        await updatePanelDrawingComment({
          ...newComment,
          panelDrawingId: existingPanel.id,
        });
      }

      successNotification('Legend updated');
      onClose();
    } catch (error) {
      console.log('🚀 ~ file: EditMeterHoursSidePanel.tsx ~ line 337 ~ onSave ~ error', error);
      setIsSaving(false);
    }
  };

  const prefillCommentTextForColour = (colourHex: string) => {
    const commentDoc = existingComments.find(doc => doc.comment.includes(colourHex));
    if (!commentDoc) {
      setExistingCommentDoc(undefined);
      setCommentText('');
      return;
    }

    setExistingCommentDoc(commentDoc);
    const parsedDocData: JSONComment = JSON.parse(commentDoc.comment);

    setCommentText(parsedDocData.commentText);
    setSelectedColourHex(parsedDocData.colourHex);
  };

  const onColourSelected = (colourHex: string) => {
    setSelectedColourHex(colourHex);

    prefillCommentTextForColour(colourHex);
  };

  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={i18n.t('Add to legend')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Legend draft discarded"
      >
        <Card className={classes.cardRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">{i18n.t('Legend')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-start">
                {PANEL_DRAWING_COLOURS_WITH_KEYS.map(colourHexObj => (
                  <Grid item key={colourHexObj.key}>
                    <Box
                      style={{
                        background: colourHexObj.color,
                      }}
                      className={clsx(
                        classes.colourBox,
                        colourHexObj.color === selectedColourHex && classes.selectedColourBox,
                      )}
                      onClick={() => onColourSelected(colourHexObj.color)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={i18n.t('Comment')}
                onChange={event => onCommentChanged(event.target.value)}
                value={commentText}
                data-cy="PanelDrawingCommentInput"
                inputProps={{ inputMode: 'text' }}
                error={errors.comment !== ''}
                errorText={errors.comment}
              />
            </Grid>
          </Grid>
        </Card>
      </GenericSidePanel>
    </>
  );
};

export default observer(EditPanelDrawingCommentPanel);
