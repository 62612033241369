import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import { DailySafetyTopicDocument } from '../../rxdb/DailySafetyTopic/queryBuilder';
import useDailySafetyTopic from '../../rxdb/DailySafetyTopic/useDailySafetyTopic';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },
    spacer: {
      marginLeft: 15,
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
  }),
);

const ShiftDailySafetyTopicCard: FC = () => {
  const classes = useStyles();

  const [initialTopicLoad, setInitialTopicLoad] = useState(true);
  const [topic, setTopic] = useState<DailySafetyTopicDocument>();

  const { getDailySafetyTopic, dailySafetyTopicInitialized } = useDailySafetyTopic();
  const { shiftPicker } = useMst();

  const setup = async (shiftId: string) => {
    setInitialTopicLoad(true);
    try {
      const result = await getDailySafetyTopic(shiftId);

      setTopic(result);
    } catch (error) {
      console.log('🚀 ~ file: ShiftDailySafetyTopicCard.tsx ~ line 96 ~ setup ~ error', error);
    }
    setInitialTopicLoad(false);
  };

  useEffect(() => {
    if (dailySafetyTopicInitialized) setup(shiftPicker.currentShiftId);
  }, [dailySafetyTopicInitialized, shiftPicker.currentShiftId]);

  return (
    <>
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Safety')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {!initialTopicLoad && (
            <>
              <Grid container className={classes.verticalSpacer}>
                <Grid item xs={4}>
                  <Typography variant="body1" color="textSecondary">
                    {i18n.t('Topic')}
                  </Typography>
                </Grid>
                {topic && (
                  <Grid item xs={8}>
                    <Typography variant="body1">{topic?.comment}</Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {initialTopicLoad && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>
    </>
  );
};

export default observer(ShiftDailySafetyTopicCard);
