import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, i18n, Icons } from '@nutrien/cxp-components';
import React, { useCallback, useRef, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

import { useNotification } from '../../utilities';
import DiscardDraftModal from '../DiscardDraftModal';

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      marginLeft: '8px',
    },
    trashIcon: {
      marginRight: '8px',
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  url: string;
  onDelete?: () => void;
  imageTitle: string;
  fullScreen?: boolean;
}

const ImagePreviewModal: React.FC<Props> = ({
  open,
  onClose,
  onDelete,
  url,
  imageTitle,
  fullScreen = false,
}: Props) => {
  const classes = useStyles();
  const { errorNotification } = useNotification();
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);

  // Adding zoom on images
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        titleText={imageTitle}
        fullScreen={fullScreen}
        backButton={false}
        noMinHeight
        customLeftHeaderButton={
          onDelete ? (
            <div className={classes.deleteButton}>
              <Button
                color="error"
                noMinHeight
                startAdornment={<Icons.Trash2Feather color="error" className={classes.trashIcon} />}
                onClick={() => {
                  setDiscardOpen(true);
                }}
                id="delete-image-button"
              >
                {i18n.t('Delete image')}
              </Button>
            </div>
          ) : undefined
        }
        BackdropProps={{
          onClick: event => {
            event.preventDefault();
          },
          onTouchStart: event => {
            event.preventDefault();
          },
          style: { touchAction: 'none' },
        }}
        classes={{ container: classes.backdrop }}
      >
        <Grid container justify="center">
          <Grid item>
            <QuickPinchZoom onUpdate={onUpdate} tapZoomFactor={0.5}>
              <img
                src={url}
                alt="selected upload"
                style={{ maxWidth: '100%', maxHeight: '80vh' }}
                ref={imgRef}
              />
            </QuickPinchZoom>
          </Grid>
        </Grid>
      </Dialog>
      <DiscardDraftModal
        open={discardOpen}
        onCancel={() => {
          setDiscardOpen(false);
        }}
        onDiscard={() => {
          if (onDelete) onDelete();
          errorNotification('Image deleted');
        }}
        titleText="Delete Image"
        cancelText="This image will no longer be available."
        continueEditingButtonText="Cancel"
        discardDraftButtonText="Delete Image"
      />
    </>
  );
};

export default ImagePreviewModal;
