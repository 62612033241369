import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';

import { isJestOrStorybook } from '../../test-helpers/isJestOrStorybook';
import useWindowVisibility from './useWindowVisibility';

export const PING_RESOURCE = '/ping.txt';
const TIMEOUT_TIME_MS = 2000;
const onlinePollingInterval = 10000;

const timeout = (time: number, promise: Promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;
  // If the browser has no network connection return offline
  if (isJestOrStorybook()) return true;
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      }),
    );
    const timeNow = dayjs().unix();
    localStorage.setItem('lastOnline', timeNow.toString());
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);
  const windowVisible = useWindowVisibility();

  useEffect(() => {
    Sentry.setContext('onlineStatus', { onlineStatus });
  }, [onlineStatus]);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    checkStatus();

    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (windowVisible) {
      checkStatus();
    }
  }, [windowVisible]);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export default useOnlineStatus;
