import { Grid } from '@material-ui/core';
import { ExpansionPanel, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { DeficiencyPanelTypes } from '../../utilities/enums';
import {
  convertListOfFilesAndFileListsToArray,
  formatFileNamesToBeUnique,
} from '../../utilities/utilityFunctions';
import AspectRatio from '../AspectRatio';
import Thumbnail from '../Thumbnail';
import useStyles from './DeficiencyAddPhotosPanel.styles';

interface Props {
  onExpanded: (expanded: boolean, expansionPanelId: DeficiencyPanelTypes) => void;
  expanded: boolean;
  selectedImages: File[];
  onFilesSelected: (selectedImages: File[]) => void;
  disableAllInputs: boolean;
}

const expansionPanelId = DeficiencyPanelTypes.PHOTOS;

const DeficiencyAddPhotosPanel: React.FC<Props> = ({
  onExpanded,
  expanded,
  selectedImages,
  onFilesSelected,
  disableAllInputs,
}: Props) => {
  const classes = useStyles();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: filesInput } = e.target;

    if (!filesInput) return;

    const files = convertListOfFilesAndFileListsToArray(filesInput);
    const formattedFiles = formatFileNamesToBeUnique(files, 'deficiency');

    onFilesSelected([...selectedImages, ...formattedFiles]);
  };

  const onOpenUpload = () => {
    document.getElementById('selectImage')?.click();
  };

  const onDelete = (file: File) => {
    onFilesSelected(selectedImages.filter(image => image.name !== file.name));
  };

  const getIcon = () => {
    if (!expanded && selectedImages.length === 0) return <Icons.PlusFeather color="primary" />;
    return <Icons.ChevronDownFeather color="primary" />;
  };

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Photos')}
      key="deficiency-photos-panel"
      data-cy="deficiency-photos-panel"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(event: React.ChangeEvent<unknown>, expandedValue: boolean) => {
        if (expandedValue && selectedImages.length === 0)
          setTimeout(() => {
            onOpenUpload();
          }, 1);

        onExpanded(expandedValue, expansionPanelId);
      }}
      expansionPanelSummaryProps={{
        expandIcon: getIcon(),
        IconButtonProps: {
          color: 'primary',
          style: {
            border: expanded || selectedImages.length !== 0 ? '0px' : '1px solid green',
            borderRadius: '4px',
            height: '44px',
          },
        },
      }}
      panelContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/jpeg"
              onChange={evt => onChange(evt)}
              multiple
              hidden
              id="selectImage"
              onClick={event => {
                event.target.value = null;
              }}
              disabled={disableAllInputs}
            />
          </Grid>
          {selectedImages.map(file => (
            <Thumbnail
              file={file}
              onDelete={onDelete}
              key={`deficiency-thumbnail-${file.name}-${file.lastModified}`}
            />
          ))}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            onClick={onOpenUpload}
            xs={6}
            id="upload-button"
          >
            <div className={classes.photoCardAdd}>
              <AspectRatio ratio={1 / 1}>
                <div className={classes.photoDiv}>
                  <Icons.PlusCircleFeather color="primary" />
                  <Typography className={classes.photoCardAddText} variant="body1">
                    {i18n.t('Add Photo')}
                  </Typography>
                </div>
              </AspectRatio>
            </div>
          </Grid>
        </Grid>
      }
    />
  );
};

export default observer(DeficiencyAddPhotosPanel);
