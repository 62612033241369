import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, MaterialPalette, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';

import { useMst } from '../../mobx-models/Root';
import BorerShiftDocumentsCard from '../BorerShiftDocumentsCard';
import BorerShiftInfoCard from '../BorerShiftInfoCard';
import RoomConditionCommentsCard from '../pages/ShiftStart/RoomConditionCommentsCard';
import ParentCrewCard from '../ParentCrewCard';
import ShiftDailySafetyTopicCard from '../ShiftDailySafetyTopicCard';
import SupervisorsCard from '../SupervisorsCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
    },
    iconSpacer: {
      marginRight: 8,
    },
    spacer: {
      marginLeft: 15,
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
  }),
);

const ShiftStartCard: FC = () => {
  const classes = useStyles();

  const { shiftPicker } = useMst();

  const currentShiftSelected = useMemo(() => {
    return shiftPicker.isCurrentShiftSelected();
  }, [shiftPicker.currentBorerShiftId]);

  return (
    <Card elevation={1} className={classes.card}>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            <Typography variant="h3">{i18n.t('Shift start')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ParentCrewCard />
          <RoomConditionCommentsCard />
          <SupervisorsCard />
          {currentShiftSelected && <BorerShiftDocumentsCard />}
          <BorerShiftInfoCard />
          <ShiftDailySafetyTopicCard />
        </Grid>
      </Grid>
    </Card>
  );
};

export default observer(ShiftStartCard);
