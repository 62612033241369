import { useCallback, useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';

import RxdbCollectionName from '../rxdbCollectionName';
import { CuttingMethod } from './queryBuilder';

export const useCuttingMethod = () => {
  const cuttingMethodQueryConstructor = useCallback(collection => collection.find(), []);

  const { result: cuttingMethods } = useRxData<CuttingMethod>(
    RxdbCollectionName.CUTTING_METHOD,
    cuttingMethodQueryConstructor,
  );

  const normalCuttingMethod = useMemo(
    () =>
      cuttingMethods.find(cuttingMethod => cuttingMethod.description?.toLowerCase() === 'normal'),
    [cuttingMethods],
  );

  return {
    cuttingMethods: cuttingMethods.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)),
    normalCuttingMethod,
  };
};

export default useCuttingMethod;
