import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      display: 'flex',
      height: '100%',
      marginTop: theme.spacing(2),
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
    },
  }),
);

const Spinner: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      justify="center"
      alignContent="flex-start"
      className={classes.progress}
    >
      <CircularProgress className={classes.spinner} />
    </Grid>
  );
};

export default Spinner;
