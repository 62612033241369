import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles(() =>
  createStyles({
    comment: {
      marginTop: '30px',
    },
    groundControlView: {
      paddingBottom: '10px',
    },
    bullet: {
      color: MaterialPalette.primary.main,
      padding: '0 10px',
    },
    bold: {
      fontWeight: 'bold',
    },
    circularProgress: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '15px',
      color: `${MaterialPalette.primary.main}`,
      '& h6': {
        marginLeft: '20px',
      },
    },
    groundControlSet: {
      marginTop: '15px',
    },
    hazardPanelContent: {
      display: 'flex',
      flex: 0.8,
    },
    flexSmall: {
      flex: 0.2,
    },
    flexMid: {
      flex: 0.23,
    },
    flexLarge: {
      flex: 0.56,
    },
  }),
);
