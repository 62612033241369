import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Crew extends BaseEntity {
  readonly departmentId: string;
  readonly description: string;
  readonly number: string;
  readonly isActive: boolean;
}

export type CrewCollection = RxCollection<Crew> | null;
export type CrewDocument = RxDocument<Crew>;

export const crewSchema: RxJsonSchema<Crew> = {
  type: 'object',
  version: 0,
  description: 'describes a crew object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    departmentId: { type: 'string' },
    description: { type: 'string' },
    number: { type: 'string' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

const crewFeedQuery = print(gql`
  query crewFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
    crewFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      departmentId
      description
      id
      isActive
      isDeleted
      number
      updatedAt
      version
    }
  }
`);

export const crewPullQueryBuilder = generatePullQuery(
  crewFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
