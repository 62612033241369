import { useCallback, useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';

import { DocumentUpload, EnrichedDocument } from '../../../../../rxdb/Documents/queryBuilder';
import useDocsForLocation from '../../../../../rxdb/Documents/useDocsForLocation';
import { useCurrentBorer } from '../../../../../rxdb/Equipment/useCurrentBorer';
import { RxdbCollectionName } from '../../../../../rxdb/rxdbCollectionName';

export const useSafeWorkPlanDocument = () => {
  const [safeWorkPlanDocument, setSafeWorkPlanDocument] = useState<EnrichedDocument>();

  const { currentRoom, currentSurveyPoint, miningMethod } = useCurrentBorer();

  const { docsForLocation: currentLocationDocs, docsForLocationLoaded } = useDocsForLocation(
    currentRoom?.id,
    currentSurveyPoint?.description,
  );
  const sWPDocQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          description: 'Safe Work Plan',
          miningMethod,
        },
      }),
    [miningMethod],
  );

  const { result: sWPDocTypeArray, isFetching: isDocTypeFetching } = useRxData<DocumentUpload>(
    RxdbCollectionName.DOCUMENT_TYPES,
    sWPDocQueryConstructor,
  );

  useEffect(() => {
    const findCorrectSwpDoc = async () => {
      // Only 1 per site/miningMethod
      const sWPDocType = sWPDocTypeArray[0];

      const matchingDoc = currentLocationDocs?.find(locDoc => {
        return locDoc.documentTypeDescription === sWPDocType?.description;
      });

      if (matchingDoc) {
        setSafeWorkPlanDocument(matchingDoc);
      } else {
        setSafeWorkPlanDocument(undefined);
      }
    };
    if (sWPDocTypeArray.length) {
      findCorrectSwpDoc();
    } else {
      setSafeWorkPlanDocument(undefined);
    }
  }, [currentLocationDocs, sWPDocTypeArray]);

  return {
    safeWorkPlanDocument,
    safeWorkPlanDocumentAvailable: Boolean(safeWorkPlanDocument),
    safeWorkPlanDocumentFetching: isDocTypeFetching || !docsForLocationLoaded,
  };
};

export default useSafeWorkPlanDocument;
