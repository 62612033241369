import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useMemo } from 'react';

import { InspectionResultDocument } from '../../rxdb/InspectionResults/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { WorkFaceAreaInspectionType } from '../../utilities/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px !important',
      padding: '16px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
  }),
);

interface Props {
  inspectionResult: InspectionResultDocument;
  onWorkFaceAreaInspectionSelected: (inspectionResultId: string) => void;
}

const WorkFaceAreaInspectionSummary: React.FC<Props> = ({
  inspectionResult,
  onWorkFaceAreaInspectionSelected,
}: Props) => {
  const classes = useStyles();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { isRocanville } = useSiteFeatures();

  const parsedInspectionDetails = useMemo(
    () => JSON.parse(inspectionResult.inspectionDetail),
    [inspectionResult],
  );

  const hazardMessage = useMemo(() => {
    if (
      parsedInspectionDetails?.wfaiDetails?.newGroundHazardIds?.length ||
      parsedInspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds?.length
    )
      return i18n.t('Hazards added');

    return i18n.t('No new hazards added');
  }, [parsedInspectionDetails]);

  const inspectionType = parsedInspectionDetails?.wfaiDetails?.inspectionType;
  const isProduction = inspectionType === WorkFaceAreaInspectionType.PRODUCTION;

  const title = isRocanville
    ? i18n.t('Mining Face Inspection')
    : isProduction
    ? i18n.t('Work face area inspection (Production)')
    : i18n.t('Work face area inspection (other)');

  return (
    <Card
      className={classes.cardRoot}
      onClick={() => {
        onWorkFaceAreaInspectionSelected(inspectionResult.id);
      }}
      id={`open-area-check-card-${inspectionResult.id}`}
    >
      <Grid container>
        <Grid item container xs={6}>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <span className={classes.dot2}>&#183;</span>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textSecondary">
              {formatDateWithDisappearingDate(inspectionResult.createdOn)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} justify="flex-end" alignContent="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{hazardMessage}</Typography>
          </Grid>
          <Grid item>
            <Icons.ChevronRightFeather color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WorkFaceAreaInspectionSummary;
