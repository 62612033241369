import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
import { useEffect, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import { useSite } from '@/rxdb/Site/useSite';

import { useTimedInterval } from './useTimedInterval';
import { useWindowVisibility } from './useWindowVisibility';

export const useViewingShiftOpenForEdit = (): boolean => {
  const { shiftPicker } = useMst();
  const { editableAfterShiftEndMinutes } = useSite();
  const windowVisible = useWindowVisibility();

  const [userViewingShiftOpenForEdit, setUserViewingShiftOpenForEdit] = useState(false);

  const isShiftOpenToEdit = () => {
    if (shiftPicker.isCurrentShiftSelected()) return true;

    const shiftStart = shiftPicker.selectedShift?.shiftStart;
    const shiftEnd = shiftPicker.selectedShift?.shiftEnd;

    if (editableAfterShiftEndMinutes === undefined) return false;
    const shiftEditEnd = shiftEnd.add(editableAfterShiftEndMinutes, 'minutes');

    return dayjs().utc().isBetween(shiftStart, shiftEditEnd, null, '[]');
  };

  // Trigger shift open for edit check whenever shift changes.
  useEffect(() => {
    setUserViewingShiftOpenForEdit(isShiftOpenToEdit());
  }, [shiftPicker.selectedShift, shiftPicker, windowVisible, editableAfterShiftEndMinutes]);

  // Check for shift open for edit every hour.
  useTimedInterval({
    callback: () => {
      setUserViewingShiftOpenForEdit(isShiftOpenToEdit());
    },
    minutes: 2,
  });

  return userViewingShiftOpenForEdit;
};

export default useViewingShiftOpenForEdit;
