import { useCallback } from 'react';
import { useRxData } from 'rxdb-hooks';

import { useMst } from '@/mobx-models/Root';

import RxdbCollectionName from '../rxdbCollectionName';
import { BorerShiftTarget } from './queryBuilder';

export const useBorerShiftTarget = () => {
  const { shiftPicker } = useMst();

  const queryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          $or: [
            { borerShiftId: shiftPicker.currentBorerShiftId },
            {
              shiftDate: `${shiftPicker.Date}:00:00.000Z`,
              shiftType: shiftPicker.Type,
            },
          ],
        },
      }),
    [shiftPicker.currentBorerShiftId],
  );

  const { result: borerShiftTargets, isFetching: isBorerShiftTargetFetching } =
    useRxData<BorerShiftTarget>(RxdbCollectionName.BORER_SHIFT_TARGET, queryConstructor);

  return {
    borerShiftTargetDoc: borerShiftTargets[0] || null,
    isBorerShiftTargetFetching,
  };
};
