import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { SequenceCollection, SequenceDocument } from './queryBuilder';

export const useSequences = () => {
  const sequencesCollection: SequenceCollection = useRxCollection(RxdbCollectionName.SEQUENCES);

  const getSequencesForMiningMethod = async (miningMethod: string): Promise<SequenceDocument[]> => {
    if (!sequencesCollection) return [];

    let sequences: SequenceDocument[];

    try {
      sequences = await sequencesCollection
        ?.find({
          selector: {
            $or: [{ miningMethod: { $eq: miningMethod } }, { miningMethod: { $eq: 'Universal' } }],
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }

    return sequences;
  };

  return { getSequencesForMiningMethod };
};

export default useSequences;
