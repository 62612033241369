import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      backgroundColor: CustomPalette.elevation.dp4Solid,
      minWidth: '274px',
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    headerContainer: {
      margin: '0',
      height: '48px',
    },
    rightButtonContainer: {
      position: 'absolute',
      top: '8px',
      right: 0,
      height: '48px',
    },
    dialogContent: {
      padding: '16px',
      width: '400px',
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);
