import { Grid, Typography } from '@material-ui/core';
import { Button, Card, Dialog, i18n, ToggleButton } from '@nutrien/cxp-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { GroundHazardCollection, GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import {
  InspectionDetails,
  InspectionOptionsWithDetails,
} from '../../rxdb/Inspections/useInspections';
import useLocations from '../../rxdb/Locations/useLocations';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import { useDateFormatters } from '../../utilities';
import { formatAsStartCase } from '../../utilities/utilityFunctions';
import { AreaCheck } from '../AddAreaCheck/AddAreaCheck';
import ChecksThisShiftSignatureCard from '../ChecksThisShiftSignatureCard';
import HazardPanelContent from '../HazardPanelContent';
import LocationDetailsComponent from '../LocationDetailsComponent';
import SmartToggleButtonGroup from '../SmartToggleButtonGroup';
import useStyles from './AreaCheckReadOnlyModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
  inspectionDetailsSource: InspectionDetails;
  selectedOptionsSource: Record<string, InspectionOptionsWithDetails>;
  areaChecks: AreaCheck[];
  signatureId: string;
  createdOn: string;
}

const AreaCheckReadOnlyModal: React.FC<Props> = ({
  open,
  onClose,
  inspectionDetailsSource,
  selectedOptionsSource = {},
  areaChecks,
  signatureId,
  createdOn,
}: Props) => {
  const classes = useStyles();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { getLocations, locationsInitialized } = useLocations();

  const [enrichedHazards, setEnrichedHazards] = useState({});
  const [enrichedLocations, setEnrichedLocations] = useState({});

  const [hazardsLoaded, setHazardsLoaded] = useState<boolean>(false);
  const areaCheckName = useMemo(
    () => formatAsStartCase(inspectionDetailsSource?.inspection?.description),
    [inspectionDetailsSource],
  );

  // collections
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );

  const fetchGroundHazard = async (id: string) => {
    const gc = await groundHazardCollection.findOne().where('id').eq(id).exec();
    return gc;
  };

  // Configure
  const setup = async () => {
    const locations = await getLocations();

    try {
      if (areaChecks) {
        const tempEnrichedHazards = {};
        const tempEnrichedLocations = {};
        for (let k = 0; k < areaChecks?.length || 0; k += 1) {
          const hazardDocuments: GroundHazardDocument[] = [];
          for (let j = 0; j < areaChecks[k].hazards.length; j += 1) {
            hazardDocuments.push(await fetchGroundHazard(areaChecks[k].hazards[j]));
          }

          tempEnrichedHazards[areaChecks[k].id] = hazardDocuments;
          if (hazardDocuments.length > 0) {
            enrichedLocations[areaChecks[k].id] = locations.find(
              x => x.id === hazardDocuments[0].startLocation,
            );
          }
          setEnrichedHazards(tempEnrichedHazards);
          setEnrichedLocations(tempEnrichedLocations);
        }
        setHazardsLoaded(true);
      }
    } catch (error) {
      console.log('🚀 ~ file: AreaCheckReadOnlyModal.tsx ~ line 129 ~ setup ~ error', error);
    }
  };

  useEffect(() => {
    if (open === true && groundHazardCollection && locationsInitialized) {
      setup();
    }
  }, [open, groundHazardCollection, locationsInitialized, areaChecks]);

  const listHazards = (areaCheckId: string) => {
    if (!enrichedHazards[areaCheckId] || Object.keys(enrichedHazards[areaCheckId]).length === 0) {
      return (
        <Grid item container xs={12}>
          &nbsp;
        </Grid>
      );
    }

    return enrichedHazards[areaCheckId].map(hazard => {
      return (
        <Grid item xs={12} className={classes.commentsContainer} key={`${hazard.id}-GridItem`}>
          <HazardPanelContent
            groundHazard={hazard}
            key={`${hazard.id}-HazardPanelContent`}
            hideAddPhotoAction
          />
        </Grid>
      );
    });
  };

  // Generate the complicated set of options based on hazard details
  const generateOptions = () => {
    if (!areaChecks || !hazardsLoaded) return [];

    return areaChecks
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(areaCheck => {
        return (
          <Grid
            container
            key={`${areaCheck.id}-${areaCheck.categoryId}-${areaCheck.displayOrder}-root`}
          >
            <Grid item xs={12} key={`${areaCheck.id}-${areaCheck.displayOrder}-parent-Grid`}>
              <Card className={classes.card}>
                <Grid container>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography variant="h3" className={classes.categoryTitle}>
                        {inspectionDetailsSource?.categories.find(
                          x => x.category.id === areaCheck.categoryId,
                        )?.category.description || ''}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.locationDetails}>
                      <LocationDetailsComponent location={enrichedLocations[areaCheck.id]} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonGroupContainer}>
                    <SmartToggleButtonGroup
                      id={`${areaCheck.id}-${areaCheck.categoryId}-ToggleButtonGroup-id`}
                      key={`${areaCheck.id}-${areaCheck.categoryId}-ToggleButtonGroup-key`}
                      value={selectedOptionsSource[areaCheck.id].id || null}
                      onChange={() => {}}
                      aria-label=""
                      exclusive
                      viewOnly
                      list={inspectionDetailsSource.categories
                        .find(x => x.category.id === areaCheck.categoryId)
                        ?.options.sort((a, b) => a.option.displayOrder - b.option.displayOrder)}
                      dropdownClassName={classes.dropdown}
                      dropdownGetValue={(val: any) => {
                        const option = inspectionDetailsSource.categories
                          .find(x => x.category.id === areaCheck.categoryId)
                          ?.options.find(y => y.option.id === val);
                        return option;
                      }}
                      dropDownOptionLabel={(item: any) => {
                        if (item === '') {
                          return '';
                        }
                        return item.option.description;
                      }}
                    >
                      {inspectionDetailsSource.categories
                        .find(x => x.category.id === areaCheck.categoryId)
                        ?.options.sort((a, b) => a.option.displayOrder - b.option.displayOrder)
                        .map(val => {
                          const { option } = val;
                          return (
                            <ToggleButton
                              key={`${areaCheck.id}+${areaCheck.categoryId}+${option.id}-${areaCheck.displayOrder}`}
                              value={option.id}
                              aria-label={`${option.id}-toggle`}
                              color={
                                option.hazardConditionTypeId ||
                                option?.description?.toUpperCase() === 'DEFICIENCY'
                                  ? 'error'
                                  : 'success'
                              }
                              disabled
                              showColorOnDisabled
                              disableRipple
                            >
                              <Typography variant="button">{option.description}</Typography>
                            </ToggleButton>
                          );
                        })}
                    </SmartToggleButtonGroup>
                  </Grid>
                  {areaCheck.hazards.length > 0 && hazardsLoaded && (
                    <>{listHazards(areaCheck.id)}</>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        );
      });
  };

  return (
    <>
      {open && (
        <Dialog
          data-cy="AreaCheckReadOnlyModal"
          open={open}
          onClose={onClose}
          titleText={areaCheckName}
          backButton={false}
          customLeftHeaderButton={
            <div className={classes.createdDate}>
              <Typography variant="h5" color="primary">
                {formatDateWithDisappearingDate(createdOn)}
              </Typography>
            </div>
          }
          fullScreen
          disableEnforceFocus
          dialogActions={
            <Grid container spacing={2} justify="space-between">
              <Grid item />
              <Grid item>
                <Button
                  onClick={onClose}
                  variant="contained"
                  color="primary"
                  noMinHeight
                  id="area-check-read-only-modal-close-button"
                >
                  {i18n.t('Close')}
                </Button>
              </Grid>
            </Grid>
          }
          BackdropProps={{
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          }}
        >
          <Grid container>{generateOptions()}</Grid>
          <Grid container>
            <ChecksThisShiftSignatureCard signatureId={signatureId} />
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default AreaCheckReadOnlyModal;
