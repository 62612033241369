import { usePermissions, UserPermissionTypes } from '@nutrien/minesight-utility-module';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

import { useMst } from '@/mobx-models/Root';
import useBorerStateType from '@/rxdb/BorerStateTypeFeed/useBorerStateType';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

export const useDelaySchema = () => {
  const adminPermissions = usePermissions(UserPermissionTypes.ADMIN_SITE_CONFIGURATION_WRITE);
  const { shiftPicker } = useMst();
  const { isRehabBorer } = useCurrentBorer();
  const { runningStateTypes } = useBorerStateType(adminPermissions, isRehabBorer);

  const delaySchema = yup
    .object({
      delays: yup.array(
        yup.object({
          startTime: yup
            .object()
            .required()
            .test('isBefore', 'Must be before end', (startTime: Dayjs, context) => {
              const { parent } = context;
              const { endTime } = parent;
              return startTime?.isBefore(endTime);
            })
            .test(
              'isWithinShiftBoundaries',
              'Must be within shift boundaries',
              (startTime: Dayjs) => {
                return (
                  dayjs.unix(shiftPicker.shiftStartDateUnix).diff(startTime, 'seconds') <= 0 &&
                  dayjs.unix(shiftPicker.shiftEndDateUnix).diff(startTime, 'seconds') >= 0
                );
              },
            ),
          endTime: yup
            .object()
            .required()
            .test('isAfter', 'Must be after start', (endDateTime: Dayjs, context) => {
              const { parent } = context;
              const { startTime } = parent;
              return endDateTime?.isAfter(startTime);
            })
            .test(
              'isWithinShiftBoundaries',
              'Must be within shift boundaries',
              (endTime: Dayjs) => {
                if (endTime) {
                  return (
                    dayjs.unix(shiftPicker.shiftStartDateUnix).diff(endTime, 'seconds') <= 0 &&
                    dayjs.unix(shiftPicker.shiftEndDateUnix).diff(endTime, 'seconds') >= 0
                  );
                }
                return true;
              },
            ),
          borerStateTypeId: yup.string().nullable().required('Required'),
          borerShiftAdvanceId: yup.string().optional(),
          cuttingTypeId: yup
            .string()
            .nullable()
            .optional()
            .test('cuttingTypeId', 'Required', (value, context) => {
              // If the borer state type is running, then cutting type is required
              if (runningStateTypes.map(val => val.id).includes(context.parent.borerStateTypeId)) {
                return value !== null && value !== undefined && value !== '';
              }
              return true;
            }),
          cuttingMethodId: yup
            .string()
            .nullable()
            .optional()
            .test('cuttingMethodId', 'Required', (value, context) => {
              // If the borer state type is running, then cutting type is required
              if (runningStateTypes.map(val => val.id).includes(context.parent.borerStateTypeId)) {
                return value !== null && value !== undefined && value !== '';
              }
              return true;
            }),
          advanceId: yup.string().optional(),
          comment: yup.string().optional(),
          id: yup.string().optional(),
          originalId: yup.string().optional(),
        }),
      ),
    })
    .required();

  return delaySchema;
};
