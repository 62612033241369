import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    tableRoot: {
      width: '100%',
      padding: '10px 0px',
    },
    tableContainer: {
      marginTop: 10,
    },
    paginationContainer: {
      padding: '15px 0px',
    },
    tableRow: {
      height: '40px',
      verticalAlign: 'baseline',
    },
    noDataMessage: {
      padding: '0px 0px 5px 0px',
    },
  }),
);
