import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    createdDate: {
      padding: '10px 0 0 35px',
    },
    content: {
      overflow: 'scroll',
      height: 'calc(100vh - 123px)',
      alignContent: 'flex-start',
    },
    panelWithoutLeftPadding: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      margin: '8px 8px 0px 8px',
      borderRadius: '8px',
      padding: '16px 16px 16px 0px',
    },
    panelWithoutTopMargin: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      margin: '0px 8px 0px 8px',
      borderRadius: '8px',
      padding: '16px 16px 16px 16px',
    },
    noTextTransform: {
      textTransform: 'none !important',
    },
    buttonGroupContainer: {
      marginTop: '8px',
    },
    wfaiCommentContainer: {
      marginTop: '16px',
    },
  }),
);

export default useStyles;
