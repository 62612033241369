import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface TargetBorerRunningTime extends BaseEntity {
  readonly borerEquipmentId: string;
  readonly dayShiftStart: string;
  readonly dayShiftEnd: string | null;
  readonly nightShiftStart: string | null;
  readonly nightShiftEnd: string | null;
  readonly dayShiftEndOffsetMinutes: number;
  readonly dayShiftStartOffsetMinutes: number;
  readonly nightShiftEndOffsetMinutes: number;
  readonly nightShiftStartOffsetMinutes: number;
}
export type TargetBorerRunningTimeCollection = RxCollection<TargetBorerRunningTime> | null;
export type TargetBorerRunningTimeDocument = RxDocument<TargetBorerRunningTime>;

export const targetBorerRunningTimeSchema: RxJsonSchema<TargetBorerRunningTime> = {
  type: 'object',
  version: 1,
  description: 'describes a TargetBorerRunningTime object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerEquipmentId: { type: 'string' },
    dayShiftStart: { type: 'string' },
    dayShiftEnd: { type: ['string', 'null'] },
    nightShiftStart: { type: ['string', 'null'] },
    nightShiftEnd: { type: ['string', 'null'] },
    dayShiftEndOffsetMinutes: { type: 'number' },
    dayShiftStartOffsetMinutes: { type: 'number' },
    nightShiftEndOffsetMinutes: { type: 'number' },
    nightShiftStartOffsetMinutes: { type: 'number' },
  },
};

const targetBorerRunningTimesFeedQuery = print(gql`
  query targetBorerRunningTimesFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    targetBorerRunningTimesFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      isDeleted
      updatedAt
      version
      borerEquipmentId
      dayShiftEndOffsetMinutes
      dayShiftStartOffsetMinutes
      nightShiftEndOffsetMinutes
      nightShiftStartOffsetMinutes
    }
  }
`);

export const targetBorerRunningTimesPullQueryBuilder = generatePullQuery(
  targetBorerRunningTimesFeedQuery,
  PullQueryContext.Borer,
  defaultDocAllTime,
);

export const targetRunningTimesMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: TargetBorerRunningTimeDocument) => {
    return {
      ...oldDoc,
      dayShiftEndOffsetMinutes: 0,
      dayShiftStartOffsetMinutes: 0,
      nightShiftEndOffsetMinutes: 0,
      nightShiftStartOffsetMinutes: 0,
    };
  },
};

export default {};
