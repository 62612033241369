import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { closeBorerShift } from '../../graphql/BorerShift';
import { useMst } from '../../mobx-models/Root';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useShifts from '../Shifts/useShifts';
import { useRxDB } from '../useRxDB';
import { BorerShiftCollection, BorerShiftDocument } from './queryBuilder';

export const useBorerShift = () => {
  const borerShiftCollection: BorerShiftCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT,
  );
  const { shiftPicker } = useMst();
  const { getPreviousShift, getPreviousShiftsBefore, getShiftById, currentActiveShiftInDB } =
    useShifts();
  const { db } = useRxDB();

  const [hasCurrentActiveBorerShiftInDB, setHasCurrentActiveBorerShiftInDB] = useState(false);

  useEffect(() => {
    const func = async () => {
      try {
        const borerShift = await getCurrentBorerShift(currentActiveShiftInDB);
        setHasCurrentActiveBorerShiftInDB(!!borerShift);
      } catch (error) {
        console.log('🚀 ~ file: useBorerShift.ts ~ line 29 ~ func ~ error', error);
      }
    };
    func();
  }, [currentActiveShiftInDB]);

  const getCurrentBorerShift = async (
    shiftId?: string | undefined | null,
  ): Promise<BorerShiftDocument | null> => {
    const { currentShiftId } = shiftPicker;

    if ((!currentShiftId && !shiftId) || !borerShiftCollection) {
      console.log('No current shift has been set => Cannot get borer shift.');
      return null;
    }

    try {
      const borerShift = await borerShiftCollection
        .findOne()
        .where('shiftId')
        .eq(shiftId || currentShiftId)
        .exec();

      return borerShift;
    } catch (error) {
      console.log('🚀 ~ file: useBorerShift.ts ~ line 18 ~ getBorerShift ~ error', error);
      throw error;
    }
  };

  const getCurrentBorerShiftId = async (
    shiftId?: string | undefined,
  ): Promise<string | undefined> => {
    const { currentShiftId } = shiftPicker;

    if ((!currentShiftId && !shiftId) || !borerShiftCollection) {
      console.log('No current shift has been set => Cannot get borer shift.');
      return undefined;
    }

    try {
      const borerShift = await borerShiftCollection
        .findOne()
        .where('shiftId')
        .eq(shiftId || currentShiftId)
        .exec();
      if (borerShift) return borerShift.id;
      return undefined;
    } catch (error) {
      console.log('🚀 ~ file: useBorerShift.ts ~ line 18 ~ getBorerShift ~ error', error);
      throw error;
    }
  };

  const getPreviousBorerShift = async () => {
    const previousShift = await getPreviousShift();
    if (previousShift?.id && borerShiftCollection) {
      try {
        const prevBorerShift = await borerShiftCollection
          .findOne()
          .where('shiftId')
          .eq(previousShift?.id)
          .exec();

        if (prevBorerShift) return prevBorerShift;
        return undefined;
      } catch (error) {
        console.log('🚀 ~ file: useBorerShift.ts ~ line 82 ~ getPreviousBorerShift ~ error', error);
        throw error;
      }
    }
    return undefined;
  };

  const getCurrentBorerShiftById = async (
    borerShiftId?: string | undefined,
  ): Promise<BorerShiftDocument | null> => {
    if (!borerShiftCollection) throw Error('No borer shift collection');

    try {
      const borerShift = await borerShiftCollection.findOne().where('id').eq(borerShiftId).exec();
      return borerShift;
    } catch (error) {
      console.log('🚀 ~ file: useBorerShift.ts ~ line 18 ~ getBorerShift ~ error', error);
      throw error;
    }
  };

  const closeShift = async (borerShiftId: string) => {
    const closedOn = dayjs().toISOString();

    try {
      await closeBorerShift({
        id: borerShiftId,
        closedOn,
      });
      await db?.runSingleReplication();
    } catch (error) {
      console.log('🚀 ~ file: useBorerShift.ts ~ line 96 ~ closeShift ~ error', error);

      Sentry.captureException(error, {
        contexts: {
          borerShiftId,
          closedOn,
        },
      });

      throw error;
    }
  };

  const getLastKnownOpenShift = async () => {
    const currentBorerShift = await getCurrentBorerShift(shiftPicker.currentShiftId);
    const currentShift = await getShiftById(currentBorerShift?.shiftId);

    const previousShifts = await getPreviousShiftsBefore(currentShift);
    const previousShiftIds = previousShifts.map(x => x.id);

    if (!borerShiftCollection) throw Error('No borer shift collection');

    try {
      const borerShifts = await borerShiftCollection
        .find({
          selector: {
            isOpen: true,
            shiftId: { $in: previousShiftIds },
          },
        })
        .exec();

      if (borerShifts.length > 0) {
        const correspondingShift = previousShifts.find(x => x.id === borerShifts[0].shiftId);

        return {
          borerShift: borerShifts[0],
          shift: correspondingShift,
        };
      }
      return undefined;
    } catch (error) {
      console.log('🚀 ~ file: useBorerShift.ts ~ line 18 ~ getBorerShift ~ error', error);
      throw error;
    }
  };

  return {
    getCurrentBorerShift,
    getCurrentBorerShiftId,
    getPreviousBorerShift,
    closeShift,
    getCurrentBorerShiftById,
    borerShiftCollection,
    getLastKnownOpenShift,
    hasCurrentActiveBorerShiftInDB,
  };
};

export default useBorerShift;
