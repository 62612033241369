import { apiConfig } from '../config/api.config';
import { callAPI } from './apiUtilities';

export const getAllEmployeesFromAPI = (siteId: string) =>
  callAPI(`${apiConfig.hazardsRootUrl}/employees?siteId=${siteId}`, {
    method: 'get',
  });

export const getAllImpersonatedUsersFromAPI = () =>
  callAPI(`${apiConfig.hazardsRootUrl}/impersonationUsers`, {
    method: 'get',
  });
