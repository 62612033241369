import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: '0px 8px !important',
      width: '100%',
    },
    label: {
      color: '#2AAB3F',
      fontSize: '12px',
      fontWeight: 600,
    },
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    panelContent: {
      padding: '4px 8px 16px 8px !important',
      width: '94%',
      margin: '4px auto',
    },
    panelExpanded: {
      margin: '4px 0px !important',
    },
    panelSummary: {
      padding: '0px 20px !important',
    },
  }),
);

export default useStyles;
