const env = import.meta.env.VITE_REACT_APP_ENV;
interface URLs {
  hazardsRootUrl: string;
  graphUrl: string;
  webSocketGraphUrl: string;
}

// Real Non Dev URLS
const devAPIRootURL = `https://devapi.minesight.nutrien.com`;
const stagingAPIRootURL = `https://stageapi.minesight.nutrien.com`;
const rcAPIRootURL = `https://rcapi.minesight.nutrien.com`;
const uatAPIRootURL = `https://uatapi.minesight.nutrien.com`;
const trainingAPIRootURL = `https://trainingapi.minesight.nutrien.com`;
const prodAPIRootURL = `https://api.minesight.nutrien.com`;

const devGraphUrl = `https://devapi-graph.minesight.nutrien.com/graphql`;
const stageGraphUrl = `https://stageapi.minesight.nutrien.com/graphql`;
const rcGraphUrl = `https://rcapi.minesight.nutrien.com/graphql`;
const uatGraphUrl = `https://uatapi.minesight.nutrien.com/graphql`;
const trainingGraphUrl = `https://trainingapi.minesight.nutrien.com/graphql`;
const prodGraphUrl = `https://api.minesight.nutrien.com/graphql`;

const devWebSocketGraphUrl = `wss://devapi-graph.minesight.nutrien.com/graphql/realtime`;
const stageWebSocketGraphUrl = `wss://stageapi-graph.minesight.nutrien.com/graphql/realtime`;
const rcWebSocketGraphUrl = `wss://rcapi-graph.minesight.nutrien.com/graphql/realtime`;
const uatWebSocketGraphUrl = `wss://uatapi-graph.minesight.nutrien.com/graphql/realtime`;
const trainingWebSocketGraphUrl = `wss://trainingapi-graph.minesight.nutrien.com/graphql/realtime`;
const prodWebSocketGraphUrl = `wss://api-graph.minesight.nutrien.com/graphql/realtime`;

let envUrl;
let graphUrl;
let webSocketGraphUrl;
switch (env) {
  case 'dev':
    envUrl = devAPIRootURL;
    graphUrl = devGraphUrl;
    webSocketGraphUrl = devWebSocketGraphUrl;
    break;
  case 'staging':
    envUrl = stagingAPIRootURL;
    graphUrl = stageGraphUrl;
    webSocketGraphUrl = stageWebSocketGraphUrl;
    break;
  case 'rc':
    envUrl = rcAPIRootURL;
    graphUrl = rcGraphUrl;
    webSocketGraphUrl = rcWebSocketGraphUrl;
    break;
  case 'uat':
    envUrl = uatAPIRootURL;
    graphUrl = uatGraphUrl;
    webSocketGraphUrl = uatWebSocketGraphUrl;
    break;
  case 'training':
    envUrl = trainingAPIRootURL;
    graphUrl = trainingGraphUrl;
    webSocketGraphUrl = trainingWebSocketGraphUrl;
    break;
  case 'prod':
    envUrl = prodAPIRootURL;
    graphUrl = prodGraphUrl;
    webSocketGraphUrl = prodWebSocketGraphUrl;
    break;
  default:
    envUrl = devAPIRootURL;
    graphUrl = devGraphUrl;
    webSocketGraphUrl = devWebSocketGraphUrl;
    break;
}

const apiConfig: URLs = {
  hazardsRootUrl: `${envUrl}`,
  graphUrl: `${graphUrl}`,
  webSocketGraphUrl: `${webSocketGraphUrl}`,
};

export { apiConfig };
