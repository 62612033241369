import React, { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AppBar from '../AppBar';
import AppLoadingDialog from '../AppLoadingDialog';
import TabBarConfig from '../TabBar/TabBarConfig.json';
const whiteListedPaths = TabBarConfig.items.map(route => route.path);

interface Props {
  children?: ReactNode;
  loadingModalOpen: boolean;
}

const AppBarController = ({ children, loadingModalOpen }: Props) => {
  const location = useLocation();

  const showAppbar = useMemo(() => {
    if (whiteListedPaths.includes(location.pathname)) return true;
    return false;
  }, [location]);

  return (
    <>
      {showAppbar && <AppBar />}
      {loadingModalOpen && <AppLoadingDialog />}
      {children}
    </>
  );
};

export default AppBarController;
