import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface EquipmentDeficiencyAttachment extends BaseEntity {
  readonly equipmentDeficiencyId: string;
  readonly fileName: string;
  readonly uploadedById: string;
  readonly createdOn: string | null;
}
export type EquipmentDeficiencyAttachmentCollection =
  RxCollection<EquipmentDeficiencyAttachment> | null;
export type EquipmentDeficiencyAttachmentDocument = RxDocument<EquipmentDeficiencyAttachment>;

export const equipmentDeficiencyAttachmentSchema: RxJsonSchema<EquipmentDeficiencyAttachment> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentDeficiencyAttachment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    equipmentDeficiencyId: { type: 'string' },
    fileName: { type: 'string' },
    uploadedById: { type: 'string' },
    createdOn: { type: 'string' },
  },
};

const equipmentDeficiencyAttachmentFeedQuery = print(gql`
  query equipmentDeficiencyAttachmentFeed(
    $lastUpdateAt: Float!
    $borerEquipmentId: ID!
    $limit: Int!
  ) {
    equipmentDeficiencyAttachmentFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      fileName
      equipmentDeficiencyId
      isDeleted
      updatedAt
      version
    }
  }
`);

export const equipmentDeficiencyAttachmentPullQueryBuilder = generatePullQuery(
  equipmentDeficiencyAttachmentFeedQuery,
  PullQueryContext.Borer,
);

const createEquipmentDeficiencyAttachmentMutation = print(gql`
  mutation createEquipmentDeficiencyAttachment(
    $attachmentId: ID!
    $createdOn: AWSDateTime
    $equipmentDeficiencyId: ID!
    $fileName: String!
    $uploadedById: ID!
  ) {
    createEquipmentDeficiencyAttachment(
      input: {
        uploadedById: $uploadedById
        fileName: $fileName
        equipmentDeficiencyId: $equipmentDeficiencyId
        createdOn: $createdOn
        attachmentId: $attachmentId
      }
    ) {
      id
      isWorkOrderRequired
      siteId
    }
  }
`);

type CreateEquipmentDeficiencyAttachmentInput = {
  attachmentId: string;
  equipmentDeficiencyId: string;
  fileName: string;
  uploadedById: string;
  createdOn: string | null;
};

const mapEquipmentDeficiencyAttachmentDocToInput = (
  doc: EquipmentDeficiencyAttachmentDocument,
): CreateEquipmentDeficiencyAttachmentInput => {
  return {
    attachmentId: doc.id,
    equipmentDeficiencyId: doc.equipmentDeficiencyId,
    fileName: doc.fileName,
    uploadedById: doc.uploadedById,
    createdOn: doc.createdOn,
  };
};

export const equipmentDeficiencyAttachmentPushQueryBuilder = generatePushQuery(
  createEquipmentDeficiencyAttachmentMutation,
  (doc: EquipmentDeficiencyAttachmentDocument) => mapEquipmentDeficiencyAttachmentDocToInput(doc),
);
