import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useMst } from '../../mobx-models/Root';
import { SignatureType } from '../../utilities/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderRadius: '8px !important',
    },
    div: { backgroundColor: theme.palette.background.default },
    container: {
      width: '145px',
      padding: '16px',
      backgroundColor: 'rgba(56,56,56,1)',
    },
    title: {
      paddingBottom: '24px',
      color: theme.palette.text.secondary,
    },
    link: {
      padding: '10px 0px',
    },
  }),
);

interface Props {
  open: boolean;
  onOpen: (signatureType: SignatureType) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

const AddSignaturePopover = ({ open, anchorEl, onClose, onOpen }: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.popover}
      >
        <div className={classes.div}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              {shiftPicker.isCurrentShiftSelected() && (
                <Grid
                  item
                  xs={12}
                  className={classes.link}
                  onClick={() => onOpen(SignatureType.VISITOR)}
                  id="add-visitor-signature"
                >
                  <Typography variant="body1">{i18n.t('Visitors')}</Typography>
                </Grid>
              )}
              {!shiftPicker.isCurrentShiftSelected() && (
                <Grid
                  item
                  xs={12}
                  className={classes.link}
                  onClick={() => onOpen(SignatureType.OPERATOR)}
                  id="add-operator-signature"
                >
                  <Typography variant="body1">{i18n.t('Operator')}</Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                className={classes.link}
                onClick={() => onOpen(SignatureType.SUPERVISOR)}
                id="add-supervisor-signature"
              >
                <Typography variant="body1">{i18n.t('Supervisors')}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.link}
                onClick={() => onOpen(SignatureType.MAINTENANCE)}
                id="add-maintenance-signature"
              >
                <Typography variant="body1">{i18n.t('Maintenance')}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.link}
                onClick={() => onOpen(SignatureType.MINE_STAFF)}
                id="add-mine-staff-signature"
              >
                <Typography variant="body1">{i18n.t('Mine staff')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
};

export default observer(AddSignaturePopover);
