import { types } from 'mobx-state-tree';

export const AppVersion = types
  .model({
    currentAppVersion: types.string,
    newAppVersion: types.maybe(types.string),
    hasNewUpdate: types.boolean,
    showUpdateModal: types.boolean,
    deferredAt: types.maybeNull(types.number),
  })
  .views(() => ({}))
  .actions(self => ({
    setNewVersionAvailable: (newAppVersion: string) => {
      self.newAppVersion = newAppVersion;
      self.hasNewUpdate = true;
    },
    setNewVersionNotAvailable: () => {
      self.newAppVersion = undefined;
      self.hasNewUpdate = false;
    },
    openUpdateModal: () => {
      self.showUpdateModal = true;
    },
    closeUpdateModal: () => {
      self.showUpdateModal = false;
    },
    deferUpdate: (updateFailed: boolean) => {
      if (!updateFailed) {
        self.deferredAt = Date.now();
        localStorage.setItem('updateDeferralTime', Date.now().toString());
      }
      self.showUpdateModal = false;
    },
    checkForUpdate: () => {
      if (!self.hasNewUpdate) return;
      if (!self.deferredAt) {
        self.showUpdateModal = true;
        return;
      }
      const now = Date.now();
      const timeSinceDeferred = now - self.deferredAt;

      const period = 60 * 60 * 1000;
      if (localStorage.getItem('DEBUG') === 'true') {
        console.log(
          'timeSinceDeferred update seconds (show modal at 3600)',
          timeSinceDeferred / 1000,
        );
      }
      if (timeSinceDeferred > period) self.showUpdateModal = true;
    },
  }));

export default AppVersion;
