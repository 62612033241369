import { useMemo, useState } from 'react';

import useInterval from './useInterval';
import useTimeout from './useTimeout';

type UseTimedIntervalProps = {
  callback: () => void;
  active?: boolean;
  minutes?: number;
};

export function useTimedInterval({ callback, active = true, minutes = 15 }: UseTimedIntervalProps) {
  const [isRunning, setIsRunning] = useState(false);
  const [delayStart] = useState(60 * minutes * 1000);

  const firstCall = useMemo(() => {
    const now = new Date();

    return (
      delayStart -
      ((now.getMinutes() % minutes) * 60 + now.getSeconds()) * 1000 -
      now.getMilliseconds()
    );
  }, [delayStart]);

  useTimeout(
    () => {
      callback();
      setIsRunning(true);
    },
    active ? firstCall + 1000 : null,
  );

  useInterval(
    () => {
      callback();
    },
    isRunning && active ? delayStart + 1000 : null,
  );
}
