import { useEffect, useState } from 'react';

const useDebug = (debugKey = 'DEBUG'): boolean => {
  const [DEBUG, setDebug] = useState(false);

  const storageEventHandler = () => {
    setDebug(localStorage.getItem(debugKey) === 'true');
  };

  useEffect(() => {
    storageEventHandler();
    window.addEventListener('storage', storageEventHandler, false);
    return () => window.removeEventListener('storage', storageEventHandler, false);
  }, []);

  return DEBUG;
};

export default useDebug;
