import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
export interface Signature extends BaseEntity {
  readonly siteEmployeeId?: string;
  readonly personnelName?: string;
  readonly signature: string;
  readonly signatureType: string;
  readonly createdOn: string;
}
export type SignatureDocument = RxDocument<Signature>;
export type SignatureCollection = RxCollection<Signature> | null;

export const signatureSchema: RxJsonSchema<Signature> = {
  type: 'object',
  version: 1,
  description: 'describes a Signature object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    signatureType: { type: 'string' },
    siteEmployeeId: { type: ['string', 'null'] },
    personnelName: { type: ['string', 'null'] },
    signature: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
  },
};

export const signatureMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SignatureDocument) => {
    const newDoc = {
      ...oldDoc,
      siteEmployeeId: oldDoc.employeeId,
    };

    delete newDoc.employeeId;
    return newDoc;
  },
};

const signatureFeedQuery = print(gql`
  query signatureFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    signatureFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, borerEquipmentId: $borerEquipmentId) {
      id
      version
      updatedAt
      signatureType
      signature
      personnelName
      isDeleted
      siteEmployeeId
      createdOn
    }
  }
`);

export const signaturePullQueryBuilder = generatePullQuery(
  signatureFeedQuery,
  PullQueryContext.Borer,
);

const setSignatureMutation = print(gql`
  mutation setSignature($input: SetSignatureInput) {
    setSignature(input: $input)
  }
`);

type SetSignatureInput = {
  id: string;
  signatureType: string;
  personnelName: string | null;
  siteEmployeeId: string | null;
  signature: string;
  createdOn: string | null;
  version: number;
  isDeleted: boolean;
};

const mapSignatureDocumentToInput = (doc: SignatureDocument): SetSignatureInput => {
  return {
    id: doc.id,
    signatureType: doc.signatureType,
    personnelName: doc.personnelName || null,
    siteEmployeeId: doc.siteEmployeeId || null,
    signature: doc.signature,
    createdOn: doc.createdOn,
    version: doc.version,
    isDeleted: doc.isDeleted,
  };
};

export const signaturePushQueryBuilder = generatePushQuery(
  setSignatureMutation,
  (doc: SignatureDocument) => ({
    input: mapSignatureDocumentToInput(doc),
  }),
);
