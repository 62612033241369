import { Grid } from '@material-ui/core';
import { ExpansionPanel, Typography } from '@nutrien/cxp-components';
import React from 'react';

import DeficiencyPhotos from '@/components/DeficiencyPhotos';
import { EmployeeDocument } from '@/rxdb/Employees/queryBuilder';
import { EquipmentDocument } from '@/rxdb/Equipment/queryBuilder';
import { EquipmentDeficiencyDocument } from '@/rxdb/EquipmentDeficiency/queryBuilder';
import { EquipmentDeficiencyLogDocument } from '@/rxdb/EquipmentDeficiencyLog/queryBuilder';
import { EquipmentTypeDocument } from '@/rxdb/EquipmentType/queryBuilder';
import { useDateFormatters } from '@/utilities/index';

import useStyles from './DeficiencyExpansionPanel.styles';

export interface Props {
  deficiency: EquipmentDeficiencyDocument;
  equipment: EquipmentDocument;
  equipmentType: EquipmentTypeDocument;
  logs: EquipmentDeficiencyLogDocument[];
  employeeList: EmployeeDocument[];
  index: number;
  expandedDeficiencyId?: string;
  onExpanded: (expanded: boolean, deficiencyId: string) => void;
}

const DeficiencyExpansionPanel: React.FC<Props> = ({
  deficiency,
  equipment,
  equipmentType,
  logs,
  employeeList,
  index,
  expandedDeficiencyId,
  onExpanded,
}: Props) => {
  const classes = useStyles();

  const { formatDateWithDisappearingDate } = useDateFormatters();

  const getLogComponents = () => {
    if (!logs) return [];

    return logs.map(log => {
      const employee = employeeList.filter(emp => emp.id === log.siteEmployeeId)[0];

      return (
        <Grid container item xs={12} key={`log-${log.id}`} className={classes.commentItemContainer}>
          <Grid item xs={3}>
            {employee && (
              <Typography variant="caption" color="textSecondary">
                {employee.firstName} {employee.lastName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3} data-testid="condition-panel-employee-full-name">
            <Typography variant="subtitle2" color="textSecondary">
              {formatDateWithDisappearingDate(log.createdOn)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{log.comment}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const getDeficiencyStatusComponent = () => {
    if (deficiency.fullyAddressedDate !== null) {
      return (
        <>
          <Typography variant="caption" className={classes.secondaryText}>
            No WR required
          </Typography>
          <span className={classes.dot2}>&#183;</span>
          <Typography color="primary" variant="caption">
            Fully Addressed
          </Typography>
        </>
      );
    }
    if (deficiency.approvalDate !== null) {
      return (
        <>
          <Typography variant="caption" className={classes.secondaryText}>
            WR Approved
          </Typography>
        </>
      );
    }
    if (deficiency.denialDate !== null) {
      return (
        <>
          <Typography variant="caption" className={classes.secondaryText}>
            WR Denied
          </Typography>
        </>
      );
    }
    return <div />;
  };

  const expansionPanelChanged = (event: React.ChangeEvent<unknown>, expanded: boolean) => {
    onExpanded(expanded, deficiency.id);
  };

  return (
    <ExpansionPanel
      className={classes.panel}
      key={deficiency.id}
      id={deficiency.id}
      index={index}
      data-cy={`condition-panel-${deficiency.id}`}
      onChange={expansionPanelChanged}
      TransitionProps={{ unmountOnExit: true }}
      expanded={deficiency.id === expandedDeficiencyId}
      expansionPanelSummaryProps={{
        children: (
          <Grid container data-testid={`condition-panel-summary-${deficiency.id}`}>
            <Grid item container xs={8}>
              <Grid item container xs={12} spacing={1} className={classes.titleLine}>
                <Typography variant="h5">{deficiency.description}</Typography>
                <span className={classes.dot}>&#183;</span>
                <Typography className={classes.secondaryText} variant="h5">
                  {equipmentType?.description} {equipment.longName}
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                {getDeficiencyStatusComponent()}
              </Grid>
            </Grid>
          </Grid>
        ),
      }}
      panelContent={
        <Grid container className={classes.commentsContainer}>
          <Grid item container xs={8} data-testid="condition-panel-content-log-comments">
            {getLogComponents()}
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <DeficiencyPhotos hideAddPhotoAction deficiencyId={deficiency.id} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default DeficiencyExpansionPanel;
