import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface BorerStateTypeCategory extends BaseEntity {
  readonly description: string;
}
export type BorerStateTypeCategoryCollection = RxCollection<BorerStateTypeCategory> | null;
export type BorerStateTypeCategoryDocument = RxDocument<BorerStateTypeCategory>;

export const borerStateTypeCategorySchema: RxJsonSchema<BorerStateTypeCategory> = {
  type: 'object',
  version: 0,
  description: 'describes a BorerStateTypeCategory object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const borerStateTypeCategoryFeedQuery = print(gql`
  query borerStateTypeCategoryFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
    borerStateTypeCategoryFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      version
      updatedAt
      isDeleted
      id
      description
    }
  }
`);

export const borerStateTypeCategoryPullQueryBuilder = generatePullQuery(
  borerStateTypeCategoryFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export const borerStateTypeCategoryMigrationStrategies: MigrationStrategies = {
  // Placeholder for future
  // 1: (oldDoc: BorerStateTypeCategoryDocument) => {
  //   return {
  //     ...oldDoc,
  //   };
  // },
};
