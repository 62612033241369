import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface CuttingPermitSupervisorComment extends BaseEntity {
  signatureId: string;
  comment: string;
  inspectionResultId: string;
  modifiedOn: string;
  borerEquipmentId: string;

  readonly createdOn: string;
}
export type CuttingPermitSupervisorCommentCollection =
  RxCollection<CuttingPermitSupervisorComment> | null;
export type CuttingPermitSupervisorCommentDocument = RxDocument<CuttingPermitSupervisorComment>;

export const cuttingPermitSupervisorCommentSchema: RxJsonSchema<CuttingPermitSupervisorComment> = {
  type: 'object',
  version: 2,
  description: 'describes an CuttingPermitSupervisorComment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    signatureId: { ref: RxdbCollectionName.SIGNATURES, type: 'string' },
    inspectionResultId: { ref: RxdbCollectionName.INSPECTION_RESULTS, type: 'string' },
    comment: { type: 'string' },
    createdOn: { type: 'string' },
    modifiedOn: { type: 'string' },
    borerEquipmentId: { type: 'string' },

    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
  indexes: ['updatedAt'],
};

export const cuttingPermitSupervisorCommentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: CuttingPermitSupervisorCommentDocument) => {
    return {
      ...oldDoc,
      inspectionResultId: '',
    };
  },
  2: (oldDoc: CuttingPermitSupervisorCommentDocument) => {
    const newDoc = {
      ...oldDoc,
      updatedAt: 1,
    };
    delete newDoc.synchronizedOn;
    return newDoc;
  },
};

const cuttingPermitSupervisorCommentPullQuery = print(gql`
  query cuttingPermitSupervisorCommentFeed(
    $borerEquipmentId: ID!
    $lastUpdateAt: Float!
    $limit: Int!
  ) {
    cuttingPermitSupervisorCommentFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      comment
      id
      createdOn
      isDeleted
      inspectionResultId
      signatureId
      updatedAt
      version
    }
  }
`);

export const cuttingPermitSupervisorCommentPullQueryBuilder = generatePullQuery(
  cuttingPermitSupervisorCommentPullQuery,
  PullQueryContext.Borer,
);

type SetCuttingPermitSupervisorCommentInput = {
  id: string;
  signatureId: string;
  inspectionResultId: string;
  comment: string;
  createdOn: string | null;
  modifiedOn: string | null;
  version: number;
  isDeleted: boolean;
};

const setCuttingPermitSupervisorCommentMutation = print(gql`
  mutation setCuttingPermitSupervisorComment($input: SetCuttingPermitSupervisorCommentInput) {
    setCuttingPermitSupervisorComment(input: $input)
  }
`);

const mapSignatureCommentDocumentToInput = (
  doc: CuttingPermitSupervisorCommentDocument,
): SetCuttingPermitSupervisorCommentInput => {
  return {
    ...doc,
  };
};

export const cuttingPermitSupervisorCommentPushQueryBuilder = generatePushQuery(
  setCuttingPermitSupervisorCommentMutation,
  (doc: CuttingPermitSupervisorCommentDocument) => ({
    input: mapSignatureCommentDocumentToInput(doc),
  }),
);

export const cuttingPermitSupervisorCommentPushModifier = (
  doc: CuttingPermitSupervisorCommentDocument,
) => {
  const obj = {
    ...doc,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
