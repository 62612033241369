import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, i18n, Icons, TextField } from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import React, { FocusEvent, useState } from 'react';

import {
  GenericLoginError,
  MinesightError,
  MissingSiteIdError,
  MultipleSiteIdError,
  NoUserPermissionsError,
} from '@/utilities/minesightErrors';

import { useOnlineStatus } from '../../../utilities';
import { NOT_AUTHORIZED_CODE, USER_NOT_FOUND_CODE } from '../../../utilities/constants';
import OfflineStatusDialog from '../../OfflineStatusDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100vh',
    },
    card: {
      padding: '70px 40px',
      borderRadius: '10px',
      backgroundColor: `${theme.palette.background.paper}`,
    },
    imageContainer: {
      marginBottom: '50px',
      textAlign: 'center',
    },
    icon: {
      '& svg': {
        marginTop: '2px',
      },
    },
  }),
);

interface Props {
  loginUser: (email: string, password: string) => Promise<void>;
  isLoggingIn: boolean;
  error?: MinesightError;
  clearError: () => void;
}

const LoginArea = ({ loginUser, isLoggingIn, error, clearError }: Props) => {
  const classes = useStyles();

  const { msBorerDataSync } = useFlags().flags;

  const [email, setEmail] = useState('');
  const [password, setPasssword] = useState('');
  const online = useOnlineStatus();
  const handleEmailChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value.toLowerCase());
    if (error && error.code) {
      clearError();
    }
  };

  const handlePasswordChange = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPasssword(value);
    if (error && error.code) {
      clearError();
    }
  };

  const handleLogin = async () => {
    loginUser(email, password);
  };

  const getErrorText = (): string => {
    if (error?.code) {
      switch (error.code) {
        case USER_NOT_FOUND_CODE:
        case NOT_AUTHORIZED_CODE:
          return i18n.t('The email or password is incorrect. Try again.');
        case MissingSiteIdError.code:
          return MissingSiteIdError.message;
        case MultipleSiteIdError.code:
          return MultipleSiteIdError.message;
        case NoUserPermissionsError.code:
          return NoUserPermissionsError.message;
        case GenericLoginError.code:
          return GenericLoginError.message;
        default:
          return i18n.t('An error occurred logging in. Try again.');
      }
    }
    return i18n.t('An error occurred logging in. Try again.');
  };

  return online ? (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xs={8} md={6} className={classes.card}>
        <div className={classes.imageContainer}>
          <img src="../../minesight_borer.svg" alt="MineSight Borer" />
        </div>
        <TextField
          variant="outlined"
          color="primary"
          label={i18n.t('Email')}
          onChange={handleEmailChange}
          type="email"
          css={undefined}
          inputProps={{
            id: 'email-input',
            'data-testid': 'email-input',
          }}
        />
        <TextField
          variant="outlined"
          color="primary"
          label={i18n.t('Password')}
          onChange={handlePasswordChange}
          type="password"
          css={undefined}
          inputProps={{
            id: 'password-input',
            'data-testid': 'password-input',
          }}
        />
        {error?.code && (
          <Grid container alignItems="center">
            <Grid item xs={1} className={classes.icon}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" color="error" align="left">
                {getErrorText()}
              </Typography>
            </Grid>
            <br />
          </Grid>
        )}
        {msBorerDataSync?.allowSync === false && (
          <Grid container alignItems="flex-start">
            <Grid item xs={1} className={classes.icon}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body2" color="error" align="left">
                {msBorerDataSync?.message || ''}
              </Typography>
            </Grid>
            <br />
          </Grid>
        )}
        {msBorerDataSync?.allowSync !== false && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleLogin}
            disabled={isLoggingIn || email === '' || password === ''}
            isLoading={isLoggingIn}
            id="login-button"
            data-testid="login-button"
            css={undefined}
          >
            Login
          </Button>
        )}{' '}
      </Grid>
    </Grid>
  ) : (
    <OfflineStatusDialog open={!online} />
  );
};

export default LoginArea;
