import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';
import { ENABLE_DEBUGGER } from '@/utilities/constants';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface GroundControlSet extends BaseEntity {
  readonly type: string;
  readonly quantity: number;
  readonly optionValues: string[];
  readonly groundHazardId: string;
}

export type GroundControlSetDocument = RxDocument<GroundControlSet>;
export type GroundControlSetCollection = RxCollection<GroundControlSet> | null;

export const groundControlSetSchema: RxJsonSchema<GroundControlSet> = {
  type: 'object',
  version: 0,
  description: 'describes a Ground Control Set object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    type: { type: 'string' },
    quantity: { type: ['number', 'null'] },
    optionValues: { type: 'array' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    groundHazardId: { type: 'string' },
  },
};

const groundControlSetFeedQuery = print(gql`
  query groundControlSetFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    groundControlSetFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      isDeleted
      optionValues
      quantity
      type
      updatedAt
      version
    }
  }
`);

export const groundControlSetsPullQueryBuilder = generatePullQuery(
  groundControlSetFeedQuery,
  PullQueryContext.Site,
  defaultDoc90Day,
);

const createGroundControlSetMutation = print(gql`
  mutation CreateGroundControlSet($groundControlSet: CreateGroundControlSetInput) {
    createGroundControlSet(input: $groundControlSet)
  }
`);

type CreateGroundControlSetInput = {
  id: string;
  createdOn: string | null;
  quantity: number;
  optionValues: string | null;
  type: string;
  groundHazardId: string;
};

const mapGroundControlSetDocToInput = (
  doc: GroundControlSetDocument,
): CreateGroundControlSetInput => {
  return {
    id: doc.id,
    type: doc.type,
    quantity: doc.quantity,
    optionValues: JSON.stringify(doc.optionValues),
    createdOn: dayjs(doc.updatedAt).toISOString(),
    groundHazardId: doc.groundHazardId,
  };
};

export const groundControlSetPushQueryBuilder = generatePushQuery(
  createGroundControlSetMutation,
  (doc: GroundControlSetDocument) => ({
    groundControlSet: mapGroundControlSetDocToInput(doc),
  }),
);

export const groundControlSetsPullModifier = (doc: GroundControlSetDocument) => {
  return {
    ...doc,
    optionValues: JSON.parse(JSON.parse(doc.optionValues)),
  };
};

export const groundControlSetsPushModifier = (doc: GroundControlSetDocument) => {
  if (Boolean(doc.groundHazardId) === true) {
    return doc;
  } else {
    if (ENABLE_DEBUGGER) debugger;
    captureInSentryWithDetails(
      new Error('groundControlSetsPushModifier: groundHazardId is missing.'),
      { documentDetails: JSON.stringify(doc), rxdbResyncBug: true },
    );
    return null;
  }
};
