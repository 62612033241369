import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface Advance extends BaseEntity {
  readonly borerShiftProductionId: string;
  distance: number | null;
  readonly createdOn: string;
  startDistance: number | null;
  endDistance: number | null;
  numberOfBuckets: number | null;
}
export type AdvanceCollection = RxCollection<Advance> | null;
export type AdvanceDocument = RxDocument<Advance>;

export const advanceSchema: RxJsonSchema<Advance> = {
  type: 'object',
  version: 6,
  description: 'describes an Advance object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftProductionId: { type: 'string' },
    distance: { type: ['number', 'null'] },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    createdOn: { type: 'string' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    startDistance: { type: ['number', 'null'] },
    endDistance: { type: ['number', 'null'] },
    numberOfBuckets: { type: ['number', 'null'] },
  },
  indexes: ['updatedAt'],
};

const borerShiftAdvanceFeedQuery = print(gql`
  query borerShiftAdvanceFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftAdvanceFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftProductionId
      distance
      id
      isDeleted
      updatedAt
      version
      createdOn
      startDistance
      endDistance
      numberOfBuckets
    }
  }
`);

export const advancesPullQueryBuilder = generatePullQuery(
  borerShiftAdvanceFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftProductionAdvanceMutation = print(gql`
  mutation setBorerShiftProductionAdvance($advance: SetBorerShiftAdvanceInput) {
    setBorerShiftProductionAdvance(input: $advance) {
      id
      borerEquipmentId
      siteId
    }
  }
`);

type SetBorerShiftAdvanceInput = {
  id: string;
  borerShiftProductionId: string;
  startDistance: number | null;
  endDistance: number | null;
  distance: number | null;
  numberOfBuckets: number | null;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapAdvanceDocToInput = (doc: AdvanceDocument): SetBorerShiftAdvanceInput => {
  return {
    id: doc.id,
    borerShiftProductionId: doc.borerShiftProductionId,
    startDistance: doc.startDistance,
    endDistance: doc.endDistance,
    distance: doc.distance,
    numberOfBuckets: doc.numberOfBuckets,
    createdOn: doc.createdOn,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const advancesPushQueryBuilder = generatePushQuery(
  setBorerShiftProductionAdvanceMutation,
  (doc: AdvanceDocument) => ({
    advance: mapAdvanceDocToInput(doc),
  }),
);

export const advanceMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    startFootage: oldDoc.startFootage || '',
    endFootage: oldDoc.endFootage || '',
  }),
  2: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    startFootage: oldDoc.startFootage || '',
    endFootage: oldDoc.endFootage || '',
  }),
  3: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
  }),
  4: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    distance: oldDoc.footage,
    startDistance: oldDoc.startFootage,
    endDistance: oldDoc.endFootage,
  }),
  5: (oldDoc: AdvanceDocument) => {
    // for each doc check if start startDistance or end startDistance are strings or undefined
    let startDistance = oldDoc.startDistance;
    let endDistance = oldDoc.endDistance;

    // if a string, convert to number
    if (typeof startDistance === 'string') {
      startDistance = parseFloat(startDistance);
    }
    if (typeof endDistance === 'string') {
      endDistance = parseFloat(endDistance);
    }

    // if undefined, set to null
    if (typeof startDistance === 'undefined') {
      startDistance = null;
    }
    if (typeof endDistance === 'undefined') {
      endDistance = null;
    }

    // if number, do nothing

    return {
      ...oldDoc,
      startDistance,
      endDistance,
    };
  },
  6: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    numberOfBuckets: null,
  }),
};
