import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Typography } from '@nutrien/cxp-components';
import React from 'react';

import { getDurationByType } from '@/utilities/delayHelpers';
import { formatDurationAsString } from '@/utilities/formatters';
import { toLowerCaseWithDashes } from '@/utilities/utilityFunctions';

import { useStyles } from './DelaysMultiEditSummaryCard.styles';

interface Props {
  selectedDelays: AppointmentModel[];
}

export const DelaysMultiEditSummaryCard = ({ selectedDelays }: Props): JSX.Element => {
  const classes = useStyles();

  const delaysByType = getDurationByType(selectedDelays);

  return (
    <Card data-testid={`delay-multi-edit-summary-card`}>
      <CardHeader
        title={`Delays selected (${selectedDelays.length})`}
        titleTypographyProps={{
          variant: 'subtitle2',
          component: 'h2',
        }}
        classes={{
          root: classes.cardTitle,
        }}
        data-testid={`delay-multi-edit-summary-card-title`}
      ></CardHeader>
      <CardContent>
        {Object.entries(delaysByType).map(([delayType, duration]) => (
          <Grid item container key={delayType}>
            <Grid item xs={9}>
              <Typography variant="subtitle2">{delayType}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                color="textSecondary"
                data-testid={`delay-multi-edit-summary-card-${toLowerCaseWithDashes(
                  delayType,
                )}-duration`}
              >
                {formatDurationAsString(duration)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};
