import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import type { GridSize } from '@material-ui/core';
import { Box, createStyles, Grid, makeStyles } from '@material-ui/core';
import {
  Checkbox,
  darkTheme,
  Icons,
  MaterialPalette,
  Tooltip,
  Typography,
} from '@nutrien/cxp-components';
import { getDurationText } from '@nutrien/minesight-utility-module';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import useDimensions from 'react-use-dimensions';

import useCuttingType from '@/rxdb/CuttingType/useCuttingType';

import { isJest } from '../../../../test-helpers/isJestOrStorybook';
import { SCHEDULER_TITLE_CELL_CLASS } from '../../../../utilities/constants';
import { AppointmentType, DelayActivitiesBlockType } from '../../../../utilities/enums';
import { getScheduleItemTitle, IAppointment } from './ActivitiesSchedulerHelpers';

export const APPT_BLOCK_TYPES: {
  [key: string]: {
    text: string;
    id: number;
    color: string;
    border: string;
    background: string;
    indicatorBorder?: string;
  };
} = {
  DELAY: {
    text: 'delay',
    id: 1,
    color: '#FFD966',
    background: '#121212',
    border: '2px solid #121212',
  },
  RUNNING: {
    text: 'running',
    id: 2,
    color: '#2AAB3F',
    background: '#121212',
    border: '2px solid #121212',
  },
  ACTIVITY: {
    text: 'activity',
    id: 3,
    color: '#216EA5',
    background: '#121212',
    border: '2px solid #121212',
  },
  NOTRUNNING: {
    text: 'not-running',
    id: 4,
    color: '#383838',
    background: '#383838',
    border: '2px solid #383838',
  },
  MANUAL_DELAY: {
    text: 'manual_delay',
    id: 5,
    color: 'rgba(0,0,0,0.5)',
    indicatorBorder: '1px solid #FFD966',
    border: '2px solid rgba(0,0,0,0.5)',
    background: 'rgba(0,0,0,0.5)',
  },
  ERROR: {
    text: 'manual_delay',
    id: 5,
    color: darkTheme.palette.error.main,
    border: '2px solid rgba(0,0,0,0.5)',
    background: 'rgba(0,0,0,0.5)',
  },
};

const diffString = (data: any) => {
  const { startDate, endDate } = data;

  return getDurationText(dayjs(startDate), dayjs(endDate));
};

const getTitleCellWidth = () => {
  let titleCellWidth = 0;
  const elm = document.getElementsByClassName(SCHEDULER_TITLE_CELL_CLASS);

  if (elm && elm.length > 0) {
    titleCellWidth = elm[0].clientWidth;
  }

  if (isJest()) return 500;
  return titleCellWidth;
};

const timeFormat = 'h:mm a';
const timesString = (data: any) => {
  const { startDate, endDate } = data;

  return `${dayjs(startDate).format(timeFormat)} - ${dayjs(endDate).format(timeFormat)}`;
};

const STANDARD_FONT_SIZE = '14px';

const useStyles = makeStyles(() =>
  createStyles({
    titleContainer: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
    },
    title: {
      overflow: 'hidden',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#FFFFFF99',
      textAlign: 'left',
      fontSize: `${STANDARD_FONT_SIZE} !important`,
      fontWeight: `400 !important`,
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: 1.16,
      marginTop: '-2px !important',
    },
    advanceText: {
      overflow: 'hidden',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#FFFFFF99',
      textAlign: 'left',
      fontSize: `${STANDARD_FONT_SIZE} !important`,
      fontWeight: 400,
      fontFamily: "'Open Sans', sans-serif",
      lineHeight: 1.16,
      marginTop: '-2px !important',
    },
    commentTextClamp: {
      color: '#FFFFFF99',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineHeight: 1.16,
      marginTop: '-2px !important',
    },
    otherText: {
      color: '#FFFFFF99',
      textAlign: 'right',
      fontSize: `${STANDARD_FONT_SIZE} !important`,
      lineHeight: 'initial !important',
      fontWeight: 400,
      marginTop: '-1px !important',
    },
  }),
);

const getBlockType = (appointment: IAppointment) => {
  switch (appointment.blockType) {
    case DelayActivitiesBlockType.RUNNING:
      return APPT_BLOCK_TYPES.RUNNING;
    case DelayActivitiesBlockType.NOT_RUNNING:
      return APPT_BLOCK_TYPES.NOTRUNNING;
    case DelayActivitiesBlockType.DELAY:
      return APPT_BLOCK_TYPES.DELAY;
    case DelayActivitiesBlockType.MANUAL_DELAY:
      return APPT_BLOCK_TYPES.MANUAL_DELAY;
    case DelayActivitiesBlockType.ACTIVITY:
      return APPT_BLOCK_TYPES.ACTIVITY;
    case DelayActivitiesBlockType.ERROR:
      return APPT_BLOCK_TYPES.ERROR;
    default:
      return APPT_BLOCK_TYPES.DELAY;
  }
};

type DelaysSchedulerAppointmentProps = Appointments.AppointmentProps & {
  style: any;
  data: IAppointment;
  borerDelaysMultiEdit: boolean;
  selectedDelays: AppointmentModel[];
  addSelectedDelay: (delay: AppointmentModel) => void;
  removeSelectedDelay: (delay: AppointmentModel) => void;
};

const DelaysSchedulerAppointment = ({
  children,
  style,
  onClick,
  data: eventData,
  borerDelaysMultiEdit,
  selectedDelays,
  addSelectedDelay,
  removeSelectedDelay,
  ...restProps
}: DelaysSchedulerAppointmentProps) => {
  const advanceString = (eventData.borerStateType?.isRunning && eventData.advanceString) || '';
  const { selectedCuttingType } = useCuttingType(eventData.cuttingTypeId);

  const classes = useStyles();

  const [ref, size] = useDimensions();

  const [isChecked, setIsChecked] = useState(false);

  // Determine the max line clamp
  const lineHeight = 22;
  const elem = `appointment-${eventData.id}`;
  const elemHeight = document.getElementById(elem)?.clientHeight;
  const maxLineClamp = useMemo(() => {
    return elemHeight ? Math.round(elemHeight / lineHeight) : 1;
  }, [elemHeight]);

  // Handler for displaying the columns in the correct width
  const schedulerColumnWidth = getTitleCellWidth();
  const percentOfSchedulerColumn =
    size.width && schedulerColumnWidth ? (size.width / schedulerColumnWidth) * 100 : 0;
  const showComment = percentOfSchedulerColumn > 95;

  const showSyncIcon = useMemo(() => {
    return eventData.isTempState && eventData.blockType !== DelayActivitiesBlockType.ERROR;
  }, [eventData.isTempState, eventData.blockType]);

  const showErrorIcon = useMemo(() => {
    return eventData.blockType === DelayActivitiesBlockType.ERROR;
  }, [eventData.blockType]);

  // 8:00 am - 9:00 am | Advance string below title
  const showHoursString = useMemo(() => {
    if (isJest()) return true;
    return (elemHeight as number) > 50 && percentOfSchedulerColumn > 48.7 && size.width > 297;
  }, [percentOfSchedulerColumn, elemHeight, size]);

  const showCuttingType = useMemo(() => {
    if (isJest()) return true;
    if (!selectedCuttingType?.description) return false;
    return (elemHeight as number) > 40;
  }, [selectedCuttingType, elemHeight]);

  const showAdvanceString = useMemo(() => {
    if (isJest()) return true;
    if (!advanceString) return false;
    if (showCuttingType) return (elemHeight as number) > 49;
    return (elemHeight as number) > 40;
  }, [advanceString, showCuttingType, elemHeight]);

  // 1 h 0 m
  const showDuration = useMemo(() => {
    if (showSyncIcon || showErrorIcon) return size.width > 230;
    return size.width > 105;
  }, [size, showSyncIcon, showErrorIcon]);

  useEffect(() => {
    const delays = selectedDelays ?? [];
    if (delays.find(delay => delay.id === eventData.id)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [eventData.id, selectedDelays]);

  const handleMultiRowClick = clickEvent => {
    if (!isChecked) {
      eventData.onClick(eventData, false, clickEvent, true);
      addSelectedDelay(eventData);
    } else {
      removeSelectedDelay(eventData);
    }
  };

  const isFiveMinuteDiff = diffString(eventData) === '5m';
  const restrictMultiEdit =
    eventData?.cuttingTypeId ||
    eventData?.typeId === AppointmentType.ACTIVITY ||
    (eventData.isGeneratedState !== undefined && eventData.isGeneratedState !== false)
      ? true
      : false;

  // GENERAL STYLING:
  // 1. Always show title and sync icon (if applicable)
  // 2. Order to remove as block size shrinks: 1) hide comment, 2) hide times, 3) hide duration & advance string
  const colSize: { titleBlock: GridSize; timeBlock: GridSize; commentBlock: GridSize } =
    useMemo(() => {
      // Everything
      if (showComment && showHoursString && showDuration)
        return {
          titleBlock: 4,
          commentBlock: 3,
          timeBlock: 4,
        };
      // No comment
      if (!showComment && showHoursString && showDuration && !showSyncIcon && !showErrorIcon) {
        return {
          titleBlock: 4,
          commentBlock: 1,
          timeBlock: 8,
        };
      }
      // Hide hours due to small height
      if (showComment && !showHoursString && showDuration)
        return {
          titleBlock: 4,
          commentBlock: 3,
          timeBlock: 5,
        };

      // Duration and syncIcon
      if (!showComment && showDuration && (showSyncIcon || showErrorIcon)) {
        return {
          titleBlock: 4,
          commentBlock: 1,
          timeBlock: 8,
        };
      }
      // Only sync icon
      if (!showDuration && (showSyncIcon || showErrorIcon)) {
        return {
          titleBlock: 10,
          commentBlock: 1,
          timeBlock: 1,
        };
      }
      // Only duration
      if (showDuration && !showSyncIcon && !showErrorIcon) {
        return {
          titleBlock: 6,
          commentBlock: 1,
          timeBlock: 6,
        };
      }
      // Only title
      if (!showDuration && !showSyncIcon && !showErrorIcon) {
        return {
          titleBlock: 11,
          commentBlock: 1,
          timeBlock: 1,
        };
      }
      // Shouldn't reach this block
      return {
        titleBlock: 4,
        commentBlock: 3,
        timeBlock: 4,
      };
    }, [showComment, showHoursString, showDuration, showSyncIcon, showErrorIcon]);

  return (
    <div
      className="rootBoundaryContainer"
      ref={ref}
      data-testid={`appointment-${eventData.isTempState ? 'temp' : 'coded'}`}
      onClick={clickEvent =>
        !borerDelaysMultiEdit
          ? eventData.onClick(eventData, false, clickEvent, false)
          : borerDelaysMultiEdit && !restrictMultiEdit
          ? handleMultiRowClick()
          : null
      }
    >
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: getBlockType(eventData).background,
          border:
            borerDelaysMultiEdit && !restrictMultiEdit && isChecked
              ? `2px solid ${MaterialPalette.primary.main}`
              : getBlockType(eventData).border,
          borderRadius: '8px',
          color: 'black !important',
        }}
        data-testid={`appointment-container-${eventData.id}`}
        id={`appointment-${eventData.id}`}
      >
        <Box height="100%" paddingRight="8px" display="flex">
          <Box
            height="100%"
            style={{
              backgroundColor: getBlockType(eventData).color,
              border: getBlockType(eventData).indicatorBorder,
              borderRadius: '16px 0px 0px 16px',
            }}
            paddingLeft="8px"
            data-testid={`appointment-indicator-${eventData.id}`}
          />
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            style={{
              height: '100%',
              paddingLeft: '8px',
            }}
          >
            <Grid
              item
              container
              alignItems="flex-start"
              xs={colSize.titleBlock}
              className={classes.titleContainer}
            >
              {borerDelaysMultiEdit && !restrictMultiEdit && (
                <Grid item>
                  <Checkbox
                    checked={isChecked}
                    onChange={() => {}}
                    css={undefined}
                    style={{
                      padding: '0 5px 0 0',
                      transform: isFiveMinuteDiff ? 'scale(0.6)' : 'scale(0.7)',
                      marginTop: isFiveMinuteDiff ? '-7px' : '-4px',
                    }}
                    size="small"
                  />
                </Grid>
              )}
              <Grid item className={classes.titleContainer}>
                <Typography variant="h6" className={classes.title}>
                  {getScheduleItemTitle(eventData.title)}
                </Typography>
                {showCuttingType && (
                  <Typography variant="h6" className={classes.advanceText}>
                    {selectedCuttingType?.description}
                  </Typography>
                )}
                {showAdvanceString && (
                  <Typography variant="h6" className={classes.advanceText}>
                    {advanceString}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {showComment && (
              <Grid
                item
                container
                xs={colSize.commentBlock}
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classes.commentTextClamp}
                    style={{
                      WebkitLineClamp: maxLineClamp,
                      fontSize: STANDARD_FONT_SIZE,
                    }}
                  >
                    {eventData.comment}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {(showHoursString || showDuration || showSyncIcon || showErrorIcon) && (
              <Grid
                item
                container
                xs={colSize.timeBlock}
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid
                  item
                  container
                  xs={showSyncIcon || showErrorIcon ? 10 : 12}
                  justify="flex-end"
                  style={{
                    textAlign: 'end',
                    paddingRight: 5,
                  }}
                >
                  {showHoursString && (
                    <Grid item container xs={12} justify="flex-end">
                      <Typography variant="caption" className={classes.otherText}>
                        {timesString(eventData)}
                      </Typography>
                    </Grid>
                  )}
                  {showDuration && (
                    <Grid item container xs={12} justify="flex-end">
                      <Typography variant="caption" className={classes.otherText}>
                        {diffString(eventData)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {showSyncIcon && (
                  <Grid item>
                    <Icons.RefreshCcwFeather
                      color="disabled"
                      style={{ height: '13.75px', marginTop: 4 }}
                      data-testid="appointment-sync-icon"
                    />
                  </Grid>
                )}
                {showErrorIcon && (
                  <Tooltip title="Sync failed">
                    <Grid item>
                      <Icons.AlertCircleIcon
                        color="error"
                        style={{ height: '13.75px', marginTop: 2 }}
                        data-testid="appointment-error-icon"
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Appointments.Appointment>
    </div>
  );
};

export default DelaysSchedulerAppointment;
