import { v4 as uuidv4 } from 'uuid';

export const FEET_PER_METERS = 3.28084;
export const OVERRIDE_SITE_ID_KEY = 'OVERRIDE_SITE_ID';
export const USER_SITE_ID_LIST_KEY = 'USER_SITE_ID_LIST';
export const LANIGAN_FIXED_SITE_ID = '9d900b61-195c-433c-94ee-155a2570d653';
export const USER_SITE_ID = localStorage.getItem(OVERRIDE_SITE_ID_KEY) || null;
export const CORY_FIXED_SITE_ID = '0fd37374-4add-b21e-fa35-20dab7f8e600';
export const VANSCOY_FIXED_SITE_ID = '6599161d-ab54-289a-0e17-c4c8551540ec';
export const ROCANVILLE_FIXED_SITE_ID = '8b3aa642-48a3-43a6-a346-bab516508700';
export const ALLAN_FIXED_SITE_ID = '843de355-7db7-4692-8d13-d9a7edb7ed2b';

export const BORER_PAGINATION_LIMIT = 10;
export const PAGINATION_LIMIT_1000 = 1000;
export const DEFAULT_SHIFT_ID = '7719b5fa-8139-4c90-95e4-584f69fbade4';
export const AREA_CHECK_STORAGE_KEY = 'AreaCheck';
export const WFAI_CHECK_STORAGE_KEY = 'WfaiCheck';
export const AREA_CHECK_TAB_INDEX = 'AreaCheckTabIndex';
export const USER_NOT_FOUND_CODE = 'UserNotFoundException';
export const NOT_AUTHORIZED_CODE = 'NotAuthorizedException';
export const unknown = 'Unknown';
export enum SiteId {
  'Lanigan' = '9d900b61-195c-433c-94ee-155a2570d653',
  'Cory' = '0fd37374-4add-b21e-fa35-20dab7f8e600',
  'Vanscoy' = '6599161d-ab54-289a-0e17-c4c8551540ec',
  'Rocanville' = '8b3aa642-48a3-43a6-a346-bab516508700',
  'Allan' = '843de355-7db7-4692-8d13-d9a7edb7ed2b',
}

export enum InspectionType {
  // Note: WorkFaceAreaInspections use the AreaCheck type
  AREA_CHECK = 'AreaCheck',
  CUTTING_PERMIT = 'CuttingPermit',
  REHAB_CUTTING_PERMIT = 'RehabCuttingPermit',
  PRE_OP = 'PreOp',
  EQUIPMENT_CHECK = 'EquipmentCheck',
  // Vanscoy-only equipment inspections
  BORER_FLEXIVEYOR_PREOP_CHECK = 'BorerFlexiveyorPreopCheck',
  BORER_CUTTING_CHECK = 'BorerCuttingCheck',
  POST_CUTTING_CHECK = 'PostCuttingCheck',
  // Vanscoy-only area inspections
  VANSCOY_PANEL_INSPECTION = 'v_PanelInspection',
  VANSCOY_AREA_PREOP_CUTTING_CHECK = 'AreaPreOpCuttingCheck',
  VANSCOY_SAFETY_CHECK_BEFORE_CUTTING = 'EquipmentCheckBeforeCutting',
  VANSCOY_SAFETY_CHECK_DURING_CUTTING = 'EquipmentCheck',
  // Cory-only equipment inspections
  MINER_PRE_OP = 'MinerPreopInspection',
  MINER_RUNNING = 'MinerRunningInspection',
  BRIDGES_RUNNING = 'BridgesRunningInspection',
  BRIDGES_PRE_OP = 'BridgesPreopInspection',
  // Cory-only area inspections
  CORY_AREA_CHECK = 'c_AreaCheck',
  CORY_LUNCH_AREA_CHECK = 'c_LunchAreaCheck',
  CORY_REFUGE_CHECK = 'c_RefugeCheck',
  CORY_MONTHLY_PANEL_INSPECTION = 'c_MonthlyPanelInspection',
  CORY_EQUIPMENT_CHECK = 'EquipmentCheck',
  // Rocanville-only inspections
  AREA_CHECK_LUNCH_TABLE = 'AreaCheckLunchTable',
  AREA_CHECK_EXTENSIBLE_DRIVE = 'AreaCheckExtensibleDrive',
  AREA_CHECK_MINING_FACE = 'AreaCheckMiningFace',
  AREA_CHECK_BREAKTHROUGH_ROOM = 'AreaCheckBreakthroughRoom',
  BIT_RACK_CHECK = 'BitRackCheck',
  // Allan-only inspections
  AREA_CHECK_REFUGE = 'RefugeCheck',
  BORER_INSPECTION = 'BorerInspection',
  MINEVEYOR_INSPECTION = 'MineveyorInspection',
}

export enum MiningMethod {
  LONG_ROOM = 'Long Room',
  CHEVRON = 'Chevron',
  UNIVERSAL = 'Universal',
}

export const PANEL_DRAWING_COLOURS = ['#e8e8e8', '#cd4f5f', '#ffd966', '#226ea6'];
export const PANEL_DRAWING_COLOURS_WITH_KEYS = PANEL_DRAWING_COLOURS.map(color => ({
  color,
  key: uuidv4(),
}));
export const USER_PERMISSION_KEY = 'userPermissions';
export const SIGNATURE_WRITE_PERMISSION = 'Signature-Signature-Write';
export const SHIFT_WRITE_PERMISSION = 'Shift-Shifts-Write';
export const GROUND_HAZARD_WRITE_PERMISSION = 'Ground Hazard-Ground Hazards-Write';
export const EQUIPMENT_WRITE_PERMISSION = 'Equipment-Equipment-Write';
export const EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION =
  'Equipment Deficiencies-Equipment Deficiencies-Write';
export const ACTIVITIES_WRITE_PERMISSION = 'BorerActivities-BorerActivities-Write';
export const BORER_SHIFT_WRITE_PERMISSION = 'BorerShift-BorerShifts-Write';
export const XML_HEADER = 'application/xml';
export const BORER_DESCRIPTION = 'Borer';
export const OTHER_SUPPLY_ITEM_DESC = 'Other';
export const CONSTRUCTION_PRODUCTION_DEPARTMENTS = ['construction', 'production'];
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const OPERATOR_COMMENT_TYPE = "Operator's comment";
export const SCHEDULER_TITLE_CELL_CLASS = 'schedulerTitleCell';
export const INITIAL_COMMENT_HTML = '<p><br></p>';
export const EMPTY_HTML_COMMENT = '<p><br></p>';
export const APP_LOADING_BUFFER = 5000;
export const SYNC_DURATION_KEY = 'lastSync';
export const SYNC_DURATION_OVERRIDE = 'syncInterval';
export const OPERATORS_COMMENT_REGEX = /^Operator's [a-zA-Z]+$/i;
export const SYNC_TIMOUT = 30000;
export const UPLOAD_RETRY = 15000;
export const OVERRIDE_SITE_ID = 'OVERRIDE_SITE_ID';
export const SHOW_REFRESH_COMPLETE = 'SHOW_REFRESH_COMPLETE';
export const SYNC_LIMIT_50 = 50;
export const SYNC_LIMIT_LOW = 200;
export const SYNC_LIMIT_HIGH = 2000;
export const COLLECTION_SYNC_TIMES = 'collectionSyncTimes';
export const SHIFT_SESSION_KEY = 'shiftDetails';
export const SYNC_COMPLETE_KEY = 'syncComplete';
export const LOGIN_TIME_KEY = 'loginTime';
export const TWO_DECIMALS_OR_LESS_REGEX = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/i;
export const USER_EMAIL_ID = 'userEmailId';

export const enum CommentType {
  AUTOMATION_NEWS = 'News from Automation Group',
  AUTOMATION = 'Automation Notes (from Operator)',
}

export const RocanvilleAutomationCommentTypes = [
  CommentType.AUTOMATION_NEWS,
  CommentType.AUTOMATION,
];

export const UNKNOWN_TEXT = 'UNKNOWN';
export const STAMLER_EQUIPMENT_IDS = [
  '943aedb3-af07-4008-bc29-58539b64d18d', // SEED DATA GUID
  '08b6c85c-59fe-4a01-9640-72f9abdcdb35', // PROD DATA GUID
];
export const HAULAGE_EQUIPMENT_IDS = [
  '941bd583-2c67-4d8c-a2e8-3cfcffed396c', // Scoop
  '041f5a4c-c7e8-4774-a350-826643a878e3', // Scoop - Detachable
  'eee36b8b-58a8-409c-87ee-a17560bb4cfb', // Ore Truck
  '722e7043-d6f0-41d9-a2a8-0728460f7a22', // Telehandler
  '9990e3dc-8c78-3572-ffa3-60d581cddcc8', // Bobcat Skidsteer/Attachment
  'f6d56826-a2fc-4407-9e55-24ebb08a878e', // Loader
];

export const APP_BACKGROUND_KEY = 'appBackgroundTime';
export const ENABLE_DEBUGGER = localStorage.getItem('ENABLE_DEBUGGER') === 'true';
