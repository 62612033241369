import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import {
  CurrentTimeIndicator,
  GroupingState,
  IntegratedGrouping,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  DayView,
  GroupingPanel,
  Resources,
  Scheduler,
} from '@devexpress/dx-react-scheduler-material-ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { useMst } from '@/mobx-models/Root';
import { DATE_STORAGE_FORMAT } from '@/mobx-models/ShiftPicker/ShiftPicker';
import { ShiftType } from '@/utilities/enums';
import useAppointmentUtilities from '@/utilities/hooks/useAppointmentUtilities';
import { sortAlphabeticallyByProperty } from '@/utilities/sortHelper';

import Spinner from '../../../Spinner';
import {
  DayScaleCell,
  GroupingPanelCell,
  GroupingPanelCellNightShift,
  GroupingPanelRow,
  TimeScaleLabel,
  TimeScaleLayout,
  TimeTableCell,
} from './ActivitiesSchedulerHelpers';
import DelaysSchedulerAppointment from './DelaysSchedulerAppointment';
import useStyles from './DelaysSchedulerViewMultiEdit.styles';

dayjs.extend(utc);

interface Props {
  schedulerData?: AppointmentModel[];
  isNightShift?: boolean;
  saving?: boolean;
  borerDelaysMultiEdit: boolean;
  selectedDelays: AppointmentModel[];
  addSelectedDelay: (delay: AppointmentModel) => void;
  removeSelectedDelay: (delay: AppointmentModel) => void;
}

const DelaysSchedulerViewMultiEdit: React.FC<Props> = ({
  schedulerData,
  isNightShift = false,
  saving = false,
  borerDelaysMultiEdit,
  selectedDelays,
  addSelectedDelay,
  removeSelectedDelay,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const { APPT_RESOURCES, APPT_GROUPING } = useAppointmentUtilities();

  const startHour = useMemo((): number => {
    if (shiftPicker.Type === ShiftType.DAY_SHIFT) {
      return shiftPicker.dayShiftStartHour - 0.25;
    }
    return shiftPicker.nightShiftStartHour - 0.25;
  }, [shiftPicker.Type, shiftPicker.dayShiftStartHour, shiftPicker.nightShiftStartHour]);

  const endHour = useMemo((): number => {
    if (shiftPicker.Type === ShiftType.DAY_SHIFT) {
      return shiftPicker.nightShiftStartHour + 0.75;
    }
    return 24;
  }, [shiftPicker.Type, shiftPicker.nightShiftStartHour]);

  // eslint-disable-next-line react/prop-types
  const TimeIndicator = ({ top, ...restProps }) => {
    return (
      <div {...restProps}>
        <div className={`${classes.nowIndicator} ${classes.circle}`} />
        <div className={`${classes.nowIndicator} ${classes.line}`} />
      </div>
    );
  };

  const nightShiftDate = useMemo(
    () => shiftPicker.selectedShiftDate.add(1, 'day').format(DATE_STORAGE_FORMAT),
    [shiftPicker.selectedShiftDate],
  );

  const sortedSchedulerData = !schedulerData
    ? []
    : sortAlphabeticallyByProperty(schedulerData, 'startDate');

  const AppointmentLayout = useMemo(() => {
    return ({ data, ...restProps }: Appointments.AppointmentProps) => (
      <DelaysSchedulerAppointment
        data={data as AppointmentModel}
        borerDelaysMultiEdit={borerDelaysMultiEdit}
        selectedDelays={selectedDelays}
        addSelectedDelay={addSelectedDelay}
        removeSelectedDelay={removeSelectedDelay}
        {...restProps}
      />
    );
  }, [addSelectedDelay, borerDelaysMultiEdit, removeSelectedDelay, selectedDelays]);

  return saving ? (
    <div style={{ paddingBottom: '40px' }}>
      <Spinner />
    </div>
  ) : (
    <>
      <Scheduler data={sortedSchedulerData}>
        <ViewState currentDate={shiftPicker.Date} />
        <GroupingState grouping={APPT_GROUPING} />
        <DayView
          startDayHour={startHour}
          endDayHour={endHour}
          cellDuration={15}
          timeScaleLayoutComponent={TimeScaleLayout}
          dayScaleCellComponent={DayScaleCell}
          timeScaleLabelComponent={TimeScaleLabel}
          timeTableCellComponent={TimeTableCell}
        />
        <Appointments
          appointmentComponent={AppointmentLayout}
          placeAppointmentsNextToEachOther // Private property noted by dev extremes support team
        />
        <Resources data={APPT_RESOURCES} mainResourceName="typeId" />
        <IntegratedGrouping />
        <GroupingPanel cellComponent={GroupingPanelCell} rowComponent={GroupingPanelRow} />
        <CurrentTimeIndicator
          shadePreviousCells={false}
          shadePreviousAppointments={false}
          updateInterval={1000}
          indicatorComponent={TimeIndicator}
        />
      </Scheduler>
      {isNightShift && (
        <Scheduler data={sortedSchedulerData}>
          <ViewState currentDate={nightShiftDate} />
          <GroupingState grouping={APPT_GROUPING} />
          <DayView
            startDayHour={0}
            endDayHour={8.25}
            cellDuration={15}
            timeScaleLayoutComponent={TimeScaleLayout}
            dayScaleCellComponent={DayScaleCell}
            timeScaleLabelComponent={TimeScaleLabel}
            timeTableCellComponent={TimeTableCell}
          />
          <Appointments
            appointmentComponent={AppointmentLayout}
            placeAppointmentsNextToEachOther // Private property noted by dev extremes support team
          />
          <Resources data={APPT_RESOURCES} mainResourceName="typeId" />
          <IntegratedGrouping />
          <GroupingPanel
            cellComponent={GroupingPanelCellNightShift}
            rowComponent={GroupingPanelRow}
          />
          <CurrentTimeIndicator
            shadePreviousCells={false}
            shadePreviousAppointments={false}
            updateInterval={1000}
            indicatorComponent={TimeIndicator}
          />
        </Scheduler>
      )}
    </>
  );
};

export default observer(DelaysSchedulerViewMultiEdit);
