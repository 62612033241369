import { Grid } from '@material-ui/core';
import { i18n } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import React from 'react';

import { useMst } from '../../mobx-models/Root';
import RemediatedConditionsCard from '../RemediatedConditionsCard';

const AreaCheckHistoricalTab: React.FC = () => {
  const { shiftPicker } = useMst();

  return (
    <Grid container data-cy="AreaCheckHistoricalTab">
      <Grid item xs={12}>
        <RemediatedConditionsCard
          useHistorical
          shiftDateStart={shiftPicker.selectedShiftStartUTC.unix()}
          shiftDateEnd={dayjs().unix()}
          conditionsAlwaysExpanded={false}
          hideAddPhotoAction
          title={i18n.t('Historical hazards')}
        />
      </Grid>
    </Grid>
  );
};

export default AreaCheckHistoricalTab;
