import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import {
  BorerDocumentUploadCollection,
  BorerDocumentUploadDocument,
} from '@/rxdb/BorerDocuments/queryBuilder';
import RxdbCollectionName from '@/rxdb/rxdbCollectionName';
import { useOnlineStatus } from '@/utilities';

export const useBorerDocs = (borerEquipmentId: string) => {
  const [borerDocs, setBorerDocs] = useState<BorerDocumentUploadDocument[]>([]);
  const [borerDocsLoaded, setBorerDocsLoaded] = useState(false);
  const borerDocumentUploadCollection: BorerDocumentUploadCollection = useRxCollection(
    RxdbCollectionName.BORER_DOCUMENT_UPLOAD,
  );

  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (borerDocumentUploadCollection) setBorerDocsLoaded(true);
  }, [borerDocumentUploadCollection]);

  const augmentAndSortDocuments = async (documents: BorerDocumentUploadDocument[]) => {
    await Promise.all(
      documents.map(async document => {
        const documentType = await document.populate('borerDocumentTypeId');
        // Documents with reference documentType won't have description / display order
        if ((!document.description || !document.displayOrder) && documentType) {
          // If stale or missing document decsription / displayOrder - add/update it
          if (document.documentTypeDescription !== documentType.description)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              documentTypeDescription: documentType.description,
            }));
          if (document.displayOrder !== documentType.displayOrder)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              displayOrder: documentType.displayOrder,
            }));
        }
        return {
          ...document,
          isCached: true,
        };
      }),
    );

    return documents
      .filter(doc => doc !== null)
      .sort(
        (a, b) =>
          (a?.displayOrder === undefined ? 1 : 0) - (b?.displayOrder === undefined ? 1 : 0) ||
          +(a?.displayOrder > b?.displayOrder) ||
          -(a?.displayOrder < b?.displayOrder),
      );
  };

  useEffect(() => {
    let sub: Subscription | undefined;
    let timeout: NodeJS.Timeout | undefined;

    const getDocuments = async () => {
      if (!borerDocumentUploadCollection) {
        setBorerDocs([]);
        setBorerDocsLoaded(true);
        return;
      }

      try {
        const querySelector = {
          selector: {
            borerEquipmentId,
          },
        };

        sub = borerDocumentUploadCollection?.find(querySelector).$.subscribe(async docs => {
          const augmentedDocs = await augmentAndSortDocuments(docs);
          if (augmentedDocs.length) {
            setBorerDocs(augmentedDocs);
          } else {
            setBorerDocs([]);
          }
          setBorerDocsLoaded(true);
        });
      } catch (error) {
        setBorerDocs([]);
        console.log(error);
      }
    };
    getDocuments();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
      if (timeout) clearTimeout(timeout);
    };
  }, [borerDocumentUploadCollection, borerDocsLoaded, borerEquipmentId, isOnline]);

  return {
    borerDocs,
    borerDocsLoaded,
  };
};

export default useBorerDocs;
