import { Auth } from 'aws-amplify';
import postal from 'postal';
import type { RxDatabase } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';

import { BorerDatabaseCollections } from '@/models/BorerDatabaseCollections';
import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';
import { removeIndexedDbPromise } from '@/utilities/removeIndexedDbPromise';

import { isJestOrStorybook } from '../test-helpers/isJestOrStorybook';
import { setupMockRxdbInstance } from '../test-helpers/setupMockRxdbInstance';
import initializeDB, { rxdb14DatabaseName } from './dbInitializer';
import RxdbCollectionName from './rxdbCollectionName';
import { refreshTokenOnSyncState } from './rxdbUtilityFunctions';
import syncReplicationStatesWithGraphQL from './syncReplicationStatesWithGraphQL';
import { CheckpointType } from './types';

const getIdToken = async (): Promise<string> => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();
  return idToken;
};

const DEBUG_RXDB = localStorage.getItem('DEBUG_RXDB') === 'true';
const DEBUG_PWA = localStorage.getItem('DEBUG_PWA') === 'true';

export default class RxdbManager {
  private static _instance: RxdbManager;

  db: RxDatabase<BorerDatabaseCollections> | undefined;

  isInitialized: boolean;

  syncStates: RxGraphQLReplicationState<any, CheckpointType>[];

  reSyncTimeouts: NodeJS.Timeout[];

  syncActive: boolean;

  DEBUG: boolean;

  syncCancelled: boolean;

  constructor(DEBUG = true, providedDb?: RxDatabase) {
    this.db = undefined;
    this.syncStates = [];
    this.reSyncTimeouts = [];
    this.isInitialized = false;
    this.syncActive = false;
    this.DEBUG = DEBUG;
    this.syncCancelled = false;
    RxdbManager._instance = this;
    if (providedDb) this.db = providedDb;
    this.initialize();
  }

  public static get instance(): RxdbManager {
    return RxdbManager._instance;
  }

  public static get syncStates(): RxGraphQLReplicationState<any>[] {
    return this.syncStates;
  }

  public initialize = async () => {
    // No need to run during tests which have import trail back to RxdbManager;
    if (isJestOrStorybook()) {
      if (!this.db) {
        this.db = await setupMockRxdbInstance();
      }
      this.isInitialized = true;
      return;
    }

    if (DEBUG_RXDB) console.log('initializing db...');
    const rxdbInfo = await initializeDB();
    this.db = rxdbInfo.db;
    this.isInitialized = true;
    if (DEBUG_RXDB) console.log('Publishing db.initialized.');
    postal.publish({
      channel: 'db',
      topic: 'db.initialized',
    });
  };

  startReplication = async () => {
    if (DEBUG_RXDB) console.log('Start Rxdb replication');
    if (this.syncCancelled) {
      if (DEBUG_RXDB) console.log('Sync cancelled, cannot start Replication');
      return;
    }
    if (isJestOrStorybook()) return;

    // if the db is not initialized, we can't start replication
    if (!this.isInitialized) return;

    const borerOperatorStatesSyncing =
      this.syncStates.find(
        state => state.collection.name === RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
      ) !== undefined;

    const allSyncStatesRunning =
      this.syncStates.length > 0 &&
      this.syncStates.every(state => {
        return state.isStopped() === false;
      }) &&
      this.reSyncTimeouts.length === this.syncStates.length;

    if (this.DEBUG)
      console.table({
        borerOperatorStatesSyncing,
        allSyncStatesRunning,
      });

    await this.stopReplication();

    try {
      const idToken = await getIdToken();
      const { syncStates, reSyncTimeouts } = await syncReplicationStatesWithGraphQL(
        idToken,
        this.db,
        true,
      );

      this.syncStates = syncStates;
      this.reSyncTimeouts = reSyncTimeouts;

      const allRunning = this.syncStates.every(state => !state.isStopped());
      console.log(`Start replication: ${allRunning} (${this.syncStates.length} sync states)`);

      this.syncActive = true;

      postal.publish({
        channel: 'sync',
        topic: 'sync.online',
      });
    } catch (err) {
      console.log('🚀 ~ file: RxdbManager.ts:130 ~ RxdbManager ~ startReplication= ~ err:', err);
      captureInSentryWithDetails(err, {
        rxDBError: true,
        startReplicationFailure: true,
      });
    }
  };

  stopReplication = async (preventRestart = false) => {
    // preventRestart will never allow sync to start again

    if (DEBUG_RXDB) console.log('Stop Rxdb replication');
    if (!this.isInitialized || isJestOrStorybook()) return;
    if (this.DEBUG) console.log('stopReplication called...');

    if (this.DEBUG) console.log('clearing timeouts', this.reSyncTimeouts.length);
    this.reSyncTimeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
    this.reSyncTimeouts = [];
    if (this.DEBUG) console.log('timeouts cleared');

    if (this.syncStates) {
      await Promise.all(this.syncStates.map(state => state.cancel()));

      const allStopped = this.syncStates.every(state => state.isStopped());
      console.log('Stop replication completed:', allStopped);
    }
    this.syncActive = false;
    if (preventRestart) this.syncCancelled = true;
  };

  runSingleReplication = () => {
    if (DEBUG_PWA) console.log(`<PWAUpdate> runSingleReplication started`);
    // reSync is not an async process
    if (this.DEBUG) console.log('Running single replication');
    try {
      if (this.syncStates) {
        if (DEBUG_PWA) console.log(`<PWAUpdate> runSingleReplication syncStates`);
        this.syncStates.map(state => {
          return state.reSync(); // Not a promise
        });
        if (DEBUG_PWA) console.log(`<PWAUpdate> runSingleReplication syncStates complete`);
      }
    } catch (error) {
      if (DEBUG_PWA) console.log(`<PWAUpdate> runSingleReplication caught error`);
      console.error('🚀 ~ file: usetsx ~ line 136 ~ runSingleReplication ~ error', error);
      throw error;
    }
  };

  refreshTokensOnAllCollections = async () => {
    if (this.syncStates) {
      console.log('Calling refresh token on all collections');

      for (const state of this.syncStates) {
        await refreshTokenOnSyncState(state, 6 * 60 * 60, this.DEBUG);
      }
    }
  };

  destroyDB = async () => {
    console.log('Destroying DB...');
    const tenSecondTimeout = new Promise(resolve =>
      setTimeout(() => {
        console.log('DB not idle within 5 seconds');
        return resolve('DB not idle within 5 seconds');
      }, 5000),
    );

    await Promise.race([this.db?.requestIdlePromise, tenSecondTimeout]);
    const destroyResult = await this.db?.destroy();
    await this.db?.remove();
    if (this.DEBUG)
      console.log(
        '🚀 ~ file: RxdbManager.ts:211 ~ RxdbManager ~ destroyDB= ~ destroyResult:',
        destroyResult,
      );
    console.log('DB destroyed');
  };

  rebuildDb = async () => {
    await this.destroyDB();
    await removeIndexedDbPromise(rxdb14DatabaseName);
    await this.initialize();
  };

  waitForIdle = async () => {
    if (this.DEBUG) console.log('Waiting for idle...');
    const tenSecondTimeout = new Promise(resolve =>
      setTimeout(() => {
        console.log('DB not idle within 5 seconds');
        return resolve('DB not idle within 5 seconds');
      }, 5000),
    );
    try {
      await Promise.race([this.db?.requestIdlePromise, tenSecondTimeout]);
      if (this.DEBUG) console.log('DB idle');
      return true;
    } catch (error) {
      console.error('🚀 ~ file: usetsx ~ line 200 ~ waitForIdle ~ error', error);
      if (this.DEBUG) console.log('DB timeout while waiting for idle');
      return false;
    }
  };
}
