import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface SupplyItem extends BaseEntity {
  readonly description: string;
  readonly unit: string;
  readonly isActive: boolean;
  readonly siteId: string;
}
export type SupplyItemCollection = RxCollection<SupplyItem> | null;
export type SupplyItemDocument = RxDocument<SupplyItem>;

export const supplyItemSchema: RxJsonSchema<SupplyItem> = {
  type: 'object',
  version: 0,
  description: 'describes a SupplyItem object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    siteId: { type: 'string' },
    unit: { type: ['string', 'null'] },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    isActive: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const supplyItemsFeedQuery = print(gql`
  query supplyItemsFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
    supplyItemsFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      description
      id
      isActive
      isDeleted
      siteId
      unit
      updatedAt
      version
    }
  }
`);

export const supplyItemPullQueryBuilder = generatePullQuery(
  supplyItemsFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
