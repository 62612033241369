import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    topMargin: {
      marginTop: 20,
    },
    nowIndicator: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: ({ top }) => top,
    },
    line: {
      height: '2px',
      borderTop: `2px ${theme.palette.primary.main} dotted`,
      width: '100%',
      transform: 'translate(0, -1px)',
    },
    circle: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
