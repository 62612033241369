import { CircularProgress, Grid } from '@material-ui/core';
import { Button, Dialog, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useMemo, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useBorerShift from '../../rxdb/BorerShift/useBorerShift';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import useInspectionResults from '../../rxdb/InspectionResults/useInspectionResults';
import { useProduction } from '../../rxdb/Productions/useProduction';
import { useNotification, useOnlineStatus } from '../../utilities';
import { InspectionType } from '../../utilities/constants';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface CloseBorerShiftChecks {
  hasCuttingPermit: boolean;
  hasAreaCheck: boolean;
  hasAtLeastOneAdvance: boolean;
  hasBeltAndCableValues: boolean;
  hasNullServiceStatus: boolean;
  numberOfOperators: number;
  has12HoursOfRtDuetRecords: boolean;
  hasMeterHours: boolean;
}

const CloseShiftModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const { shiftPicker, appVersion } = useMst();
  const isOnline = useOnlineStatus();
  const { inspectionsInitialized, listInspections } = useInspectionResults();
  const { productionInitialized, getMostRecentProductionForShiftId } = useProduction();
  const { miningMethod } = useCurrentBorer();
  const { belt, cable, serviceStatus, startMeterHours, endMeterHours } = useBorerShiftInfo();
  const { borerShiftCrewInitialized, getBorerShiftCrews } = useBorerShiftCrew();
  const { closeShift } = useBorerShift();
  const { successNotification, errorNotification } = useNotification();

  const [isPerformingCheck, setIsPerformingCheck] = useState(true);
  const [shiftCloseCheck, setShiftCloseCheck] = useState<CloseBorerShiftChecks>({
    hasAreaCheck: false,
    hasCuttingPermit: false,
    hasAtLeastOneAdvance: false,
    hasBeltAndCableValues: false,
    hasNullServiceStatus: false,
    numberOfOperators: 0,
    has12HoursOfRtDuetRecords: false,
    hasMeterHours: false,
  });
  const [isClosingShift, setIsClosingShift] = useState(false);

  const totalHours = useMemo(() => {
    if (startMeterHours !== undefined && endMeterHours !== undefined)
      return endMeterHours - startMeterHours;
  }, [startMeterHours, endMeterHours]);

  const allChecksPass = useMemo(() => {
    return Object.values(shiftCloseCheck).find(check => check === false);
  }, [shiftCloseCheck]);

  const checkShiftValidClose = async () => {
    setIsPerformingCheck(true);
    const tempShiftCloseCheck = { ...shiftCloseCheck };

    // Operator
    if (borerShiftCrewInitialized) {
      const { borerShiftCrews } = await getBorerShiftCrews(shiftPicker.currentBorerShiftId);

      let operators = 0;
      borerShiftCrews.forEach(crew => {
        operators += crew.borerShiftCrewMemberInput.length;
      });

      tempShiftCloseCheck.numberOfOperators = operators;
    }

    // Area Check & Cutting Permit
    if (inspectionsInitialized && shiftPicker.currentBorerShiftId) {
      const inspections = await listInspections(shiftPicker.currentBorerShiftId);

      tempShiftCloseCheck.hasAreaCheck =
        inspections.find(
          inspection => inspection.inspectionDetails.inspectionType === InspectionType.AREA_CHECK,
        ) !== undefined;

      tempShiftCloseCheck.hasCuttingPermit =
        inspections.find(
          inspection =>
            inspection.inspectionDetails.inspectionType === InspectionType.CUTTING_PERMIT,
        ) !== undefined;
    }

    // Check for Advance
    if (productionInitialized && shiftPicker.currentShiftId) {
      const production = await getMostRecentProductionForShiftId(shiftPicker.currentBorerShiftId);

      if (production) tempShiftCloseCheck.hasAtLeastOneAdvance = true;
    }

    // Check for Belt & Cable

    if (belt && cable) tempShiftCloseCheck.hasBeltAndCableValues = true;

    // Service Status
    tempShiftCloseCheck.hasNullServiceStatus = serviceStatus === null;

    // RtDuet Data
    // TODO: Need to fix this in a future story
    tempShiftCloseCheck.has12HoursOfRtDuetRecords = true;

    // Meter Hours
    if (totalHours !== undefined && totalHours > 0) tempShiftCloseCheck.hasMeterHours = true;

    setShiftCloseCheck(tempShiftCloseCheck);
    setIsPerformingCheck(false);
  };

  useEffect(() => {
    if (open) {
      checkShiftValidClose();
    }
  }, [open, inspectionsInitialized, productionInitialized, totalHours]);

  const onCloseDialog = () => {
    onClose();
  };

  const onCloseShift = async () => {
    setIsClosingShift(true);
    try {
      if (shiftPicker.currentBorerShiftId) await closeShift(shiftPicker.currentBorerShiftId);

      if (appVersion.hasNewUpdate) {
        appVersion.openUpdateModal();
      }

      onCloseDialog();
      successNotification('Shift closed');
    } catch (error) {
      console.log('🚀 ~ file: CloseShiftModal.tsx ~ line 199 ~ onCloseShift ~ error', error);
      errorNotification('Shift could not be closed. Please try again with a better connection.');
    }
    setIsClosingShift(false);
  };

  return (
    <Dialog
      titleText="Close shift"
      data-cy="CloseShiftModal"
      open={open}
      onClose={isClosingShift ? onClose : undefined}
      backButton={false}
      maxWidth="sm"
      maxHeight="430px"
      dialogActions={
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Button
              onClick={onCloseDialog}
              variant="outlined"
              color="primary"
              noMinHeight
              id="close-shift-modal-cancel-button"
              disabled={isClosingShift}
            >
              Keep shift open
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onCloseShift}
              variant="contained"
              color="primary"
              noMinHeight
              disabled={!isOnline || isClosingShift}
              isLoading={isClosingShift}
              id="close-shift-modal-close-button"
            >
              Close shift
            </Button>
          </Grid>
        </Grid>
      }
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      {isPerformingCheck ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              By closing the shift you are confirming that you have reviewed the face, finished the
              shift, and confirmed that it is accurate and complete
            </Typography>
          </Grid>
          {!allChecksPass && (
            <>
              <Grid item xs={12}>
                <Typography variant="h4">Missing data:</Typography>
              </Grid>
              <Grid item xs={12}>
                <ul>
                  {shiftCloseCheck.numberOfOperators < 1 && (
                    <li>
                      <Typography>{shiftCloseCheck.numberOfOperators}/1 Operator</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.hasAreaCheck && (
                    <li>
                      <Typography>0/1 Area check</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.hasCuttingPermit && (
                    <li>
                      <Typography>0/1 Cutting permit</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.has12HoursOfRtDuetRecords && (
                    <li>
                      <Typography>12 hours of Borer delays</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.hasAtLeastOneAdvance && (
                    <li>
                      <Typography>0/1 Advance recorded</Typography>
                    </li>
                  )}
                  {miningMethod === 'Long Room' && !shiftCloseCheck.hasBeltAndCableValues && (
                    <li>
                      <Typography>Belt and cable</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.hasNullServiceStatus && (
                    <li>
                      <Typography>Maintenance: {serviceStatus}</Typography>
                    </li>
                  )}
                  {!shiftCloseCheck.hasMeterHours && (
                    <li>
                      <Typography>
                        Meter hours at shift end must be greater than shift start
                      </Typography>
                    </li>
                  )}
                </ul>
              </Grid>
            </>
          )}
          {!isOnline && (
            <Grid item xs={12}>
              <Typography>
                You appear to be offline. Please connect the iPad to WiFi, so the shift can be
                closed.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Dialog>
  );
};

export default CloseShiftModal;
