import { CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import {
  Card,
  Chip,
  CustomPalette,
  Icons,
  MaterialPalette,
  Typography,
} from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { useDateFormatters, useOnlineStatus } from '../../utilities';

interface Props {
  document: DocumentUploadDocument;
  selectDocument: (document: DocumentUploadDocument) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
      height: '100%',
      minHeight: 130,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
    },
    text: {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    icon: {
      marginTop: 10,
      marginBottom: 10,
    },
    chip: {
      marginTop: 'auto',
      marginBottom: 'auto',
      backgroundColor: `${MaterialPalette.secondary.dark} !important`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadIcon: {
      maxHeight: 17,
      color: MaterialPalette.common.black,
    },
    chipText: {
      color: MaterialPalette.common.black,
      fontSize: '12px',
    },
    chipContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    photoCardAdd: {
      width: '100%',
      height: '100%',
      border: `1px dashed ${CustomPalette.elevation.dp8}`,
      borderRadius: '4px',
      backgroundColor: CustomPalette.elevation.dp1,
      color: theme.palette.text.secondary,
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 12,
      paddingBottom: 12,
    },
  }),
);

const DocumentThumbnail: FC<Props> = ({ document, selectDocument }: Props) => {
  const classes = useStyles();
  const [docToDisplay, setDocToDisplay] = useState(document);
  const dateFormater = useDateFormatters();
  const handleSelectDocument = () => {
    selectDocument(docToDisplay);
  };
  const online = useOnlineStatus();

  useEffect(() => {
    const sub = document.$.subscribe(newDoc => {
      setDocToDisplay(newDoc);
    });

    return () => sub.unsubscribe();
  }, [document, docToDisplay]);

  return (
    <Grid item xs={2} onClick={handleSelectDocument} id={`select-document-thumbnail${document.id}`}>
      <Card className={classes.card}>
        {docToDisplay.isLoading && online ? (
          <Grid item container direction="column" justify="center" alignItems="center" xs={12}>
            <div className={classes.centerContent}>
              <CircularProgress size="25px" />
            </div>
          </Grid>
        ) : (
          <Icons.FileTextFeather
            color={docToDisplay.isCached ? 'primary' : 'error'}
            className={classes.icon}
          />
        )}
        <Typography variant="body1" className={classes.text}>
          {docToDisplay.description || docToDisplay.documentTypeDescription}
        </Typography>
        <Chip
          label={
            <div className={classes.chipContainer}>
              <Icons.UploadFeather className={classes.uploadIcon} color="inherit" size="small" />
              <Typography variant="caption" className={classes.chipText}>
                {dateFormater.formatDateWithDisappearingDate(docToDisplay.createdOn, false)}
              </Typography>
            </div>
          }
          size="small"
          classes={{ root: classes.chip }}
          textColor={MaterialPalette.common.black}
        />
      </Card>
    </Grid>
  );
};

export default observer(DocumentThumbnail);
