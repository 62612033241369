export const isJestOrStorybook = () => {
  return (
    process.env.JEST_WORKER_ID !== undefined ||
    process.env.STORYBOOK_MODE !== undefined ||
    import.meta.env.JEST_WORKER_ID !== undefined ||
    import.meta.env.STORYBOOK_MODE !== undefined ||
    process.env.VITEST_POOL_ID !== undefined
  );
};

export const isJest = () => {
  return process.env.JEST_WORKER_ID !== undefined || process.env.VITEST_POOL_ID !== undefined;
};
