import { client, gql } from './Client';

export interface BorerShiftCrewMemberRole {
  description: string;
  displayOrder: number;
  id: string;
  version: number;
}

// List borer shift crew member roles
const listBorerShiftCrewMemberRolesQuery = gql`
  query listBorerShiftCrewMemberRoles($siteId: ID!) {
    listBorerShiftCrewMemberRoles(siteId: $siteId) {
      description
      id
      displayOrder
      version
    }
  }
`;

export interface ListBorerShiftCrewMemberRolesResult {
  data: {
    listBorerShiftCrewMemberRoles: BorerShiftCrewMemberRole[];
  };
}

const listBorerShiftCrewMemberRoles = (
  siteId: string,
): Promise<ListBorerShiftCrewMemberRolesResult> =>
  client.query({
    query: listBorerShiftCrewMemberRolesQuery,
    variables: { siteId },
    fetchPolicy: 'no-cache',
  });

export { listBorerShiftCrewMemberRoles };
