import { ApolloClient, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

import { apiConfig } from '../config/api.config';

const cache = new InMemoryCache();

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}?opname=${operationName}`, options);
};

const httpLink = createHttpLink({
  uri: apiConfig.graphUrl,
  fetch: customFetch,
});

const authLink = setContext(async (_, { headers }) => {
  const credentials = await Auth.currentSession();
  const token = credentials.getIdToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache,
  link: authLink.concat(httpLink),
  // Provide some optional constructor fields
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export { client, gql };

export default { client, gql };
