import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { LiveInterval } from '../constants';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerShiftSignature extends BaseEntity {
  readonly borerShiftId: string;
  readonly signatureId: string;
  readonly reviewedCuttingPermits: boolean | null;
  readonly createdOn: string;
  readonly purposeOfVisit: string | null;
  readonly reviewedSafeWorkPlan: boolean | null;
}
export type BorerShiftSignatureDocument = RxDocument<BorerShiftSignature>;
export type BorerShiftSignatureCollection = RxCollection<BorerShiftSignature> | null;

export const SIGNATURE_SYNC_INTERVAL = LiveInterval.SECONDS_60;
export const SIGNATURE_BATCH_SIZE = 49; //https://nutrien.atlassian.net/browse/MDP-7729

export const borerShiftSignatureSchema: RxJsonSchema<BorerShiftSignature> = {
  type: 'object',
  version: 1,
  description: 'describes a BorerShiftSignature object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    signatureId: { ref: RxdbCollectionName.SIGNATURES, type: 'string' },
    reviewedCuttingPermits: { type: ['boolean', 'null'] },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
    purposeOfVisit: { type: ['string', 'null'] },
    reviewedSafeWorkPlan: { type: ['boolean', 'null'] },
  },
};

export const borerShiftSignatureMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftSignatureDocument) => {
    const newDoc = { ...oldDoc, purposeOfVisit: null, reviewedSafeWorkPlan: null };
    return newDoc;
  },
};

const borerShiftSignatureFeedQuery = print(gql`
  query borerShiftSignatureFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftSignatureFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      isDeleted
      updatedAt
      version
      borerShiftId
      signatureId
      reviewedCuttingPermits
      createdOn
      purposeOfVisit
      reviewedSafeWorkPlan
    }
  }
`);

export const borerShiftSignaturePullQueryBuilder = generatePullQuery(
  borerShiftSignatureFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftSignatureMutation = print(gql`
  mutation setBorerShiftSignature($input: SetBorerShiftSignatureInput) {
    setBorerShiftSignature(input: $input)
  }
`);

type SetBorerShiftSignatureInput = {
  id: string;
  borerShiftId: string;
  reviewedCuttingPermits: boolean | null;
  reviewedSafeWorkPlan: boolean | null;
  purposeOfVisit: string | null;
  signatureId: string;
  createdOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapBorerShiftSignatureDocumentToInput = (
  doc: BorerShiftSignatureDocument,
): SetBorerShiftSignatureInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    createdOn: doc.createdOn,
    reviewedCuttingPermits: doc.reviewedCuttingPermits,
    signatureId: doc.signatureId,
    purposeOfVisit: doc.purposeOfVisit,
    reviewedSafeWorkPlan: doc.reviewedSafeWorkPlan,
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const borerShiftSignaturePushQueryBuilder = generatePushQuery(
  setBorerShiftSignatureMutation,
  (doc: BorerShiftSignatureDocument) => ({
    input: mapBorerShiftSignatureDocumentToInput(doc),
  }),
);
