import * as markerjs2 from 'markerjs2';

// The /n and empty space characters prevent markerjs2 render
export const sanitizeMarkerJsData = (
  data: markerjs2.MarkerAreaState,
): markerjs2.MarkerAreaState => {
  const string = JSON.stringify(data);
  const sanitized = string.replace(/\\n/g, ' ').replace(/\u00A0/, ' ');
  return JSON.parse(sanitized);
};

export default sanitizeMarkerJsData;
