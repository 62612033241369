import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette, TextField } from '@nutrien/cxp-components';
import React, { useState } from 'react';
import { ZodTypeAny } from 'zod';

interface Props {
  option: any;
  handleValueChanged: (optionId: string, valueId: string) => void;
  setValidationError: (index: number, value: boolean) => void;
  index: number;
  validationSchema: ZodTypeAny | undefined;
  currentValue: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: MaterialPalette.background.paper2,
      '& h6': {
        fontSize: '20px',
        color: MaterialPalette.tertiary.main,
      },
    },
    checkboxLabel: {
      width: '100%',
    },
    optionsContainer: {
      paddingLeft: '30px',
      '& > div > span': {
        color: MaterialPalette.primary.main,
      },
    },
    nested: {
      paddingLeft: '30px',
    },
  }),
);

const GroundControlNumberOption = ({
  option,
  handleValueChanged,
  setValidationError,
  index,
  validationSchema,
  currentValue,
}: Props) => {
  const classes = useStyles();

  const [errorText, setErrorText] = useState('');

  const onChanged = (optionValue: any) => {
    const result = validationSchema?.safeParse(optionValue);
    if (!result?.success) {
      setValidationError(index, true);
      const errors = result?.error?.format();
      setErrorText(errors?._errors[0] || '');
    } else {
      setValidationError(index, false);
      setErrorText('');
    }

    handleValueChanged(option.id, optionValue);
  };

  return (
    <>
      <Grid item container xs={12} key={option.id} className={classes.nested}>
        <TextField
          label={`${option.description} (Optional)`}
          onChange={e => onChanged(e.target.value)}
          value={currentValue}
          fullWidth
          variant="outlined"
          error={errorText !== ''}
          errorText={errorText}
          unitText={option.unitOfMeasure || undefined}
        />
      </Grid>
    </>
  );
};

export default GroundControlNumberOption;
