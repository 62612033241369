import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { v4 as uuidv4, v4 } from 'uuid';

import { useMst } from '../../mobx-models/Root';
import {
  AWS_DATE_TIME_FORMAT,
  useDateFormatters,
  USER_TIMEZONE,
} from '../../utilities/hooks/useDateFormatters';
import { BorerShiftCrewType } from '../BorerShift/queryBuilder';
import {
  BorerShiftCrewMember,
  BorerShiftCrewMemberCollection,
  BorerShiftCrewMemberDocument,
} from '../BorerShiftCrewMember/queryBuilder';
import { useBorerShiftCrewMemberRole } from '../BorerShiftCrewMemberRole/useBorerShiftCrewMemberRole';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import {
  BorerShiftCrewCollection,
  BorerShiftCrewDocument,
  BorerShiftCrewMemberWithRole,
  ExtendedBorerShiftCrewDocument,
} from './queryBuilder';

export interface SetBorerShiftCrewInput {
  id: string;
  borerShiftId: string;
  crewNumber: number;
  startDateTime: string;
  endDateTime: string;
  createdOn?: string;
  borerShiftCrewMemberInput?: BorerShiftCrewMemberWithRole[];
  modifiedOn?: string;
  isDeleted: boolean;
  version: number;
}

export const useBorerShiftCrew = () => {
  const { formatAsAWSDateTime } = useDateFormatters();
  const { borerShiftCrewMemberRolesById } = useBorerShiftCrewMemberRole();
  const { shiftPicker } = useMst();

  const borerShiftCrewCollection: BorerShiftCrewCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_CREW,
  );
  const borerShiftCrewMemberCollection: BorerShiftCrewMemberCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
  );

  const [borerShiftCrewInitialized, setBorerShiftCrewInitialized] = useState(false);
  const [borerShiftCrewMemberInitialized, setBorerShiftCrewMemberInitialized] = useState(false);

  const [firstBorerShiftCrewPopulated, setFirstBorerShiftCrewPopulated] =
    useState<ExtendedBorerShiftCrewDocument | null>(null);

  const [secondBorerShiftCrewPopulated, setSecondBorerShiftCrewPopulated] =
    useState<ExtendedBorerShiftCrewDocument | null>(null);

  useEffect(() => {
    if (borerShiftCrewCollection) setBorerShiftCrewInitialized(true);
  }, [borerShiftCrewCollection]);

  useEffect(() => {
    if (borerShiftCrewMemberCollection) setBorerShiftCrewMemberInitialized(true);
  }, [borerShiftCrewMemberCollection]);

  const getBorerShiftCrews = async (
    borerShiftId?: string | undefined,
  ): Promise<{
    borerShiftCrews: ExtendedBorerShiftCrewDocument[] | null;
  }> => {
    const borerShiftCrews: ExtendedBorerShiftCrewDocument[] | null = [];
    if (!borerShiftCrewCollection) return { borerShiftCrews };

    try {
      if (borerShiftCrewCollection && borerShiftCrewMemberCollection) {
        const firstCrew = await borerShiftCrewCollection
          ?.findOne({
            selector: {
              crewNumber: 1,
              borerShiftId,
              isDeleted: false,
            },
            sort: [{ updatedAt: 'desc' }],
          })
          .exec();

        const secondCrew = await borerShiftCrewCollection
          ?.findOne({
            selector: {
              crewNumber: 2,
              borerShiftId,
              isDeleted: false,
            },
            sort: [{ updatedAt: 'desc' }],
          })
          .exec();

        if (firstCrew) {
          borerShiftCrews.push(firstCrew);
        }

        if (secondCrew && secondCrew.hidden !== true) {
          borerShiftCrews.push(secondCrew);
        }

        await Promise.all(
          borerShiftCrews?.map(async (borerShiftCrew, index) => {
            const borerShiftCrewMembers = await borerShiftCrewMemberCollection
              .find({
                selector: {
                  borerShiftCrewId: borerShiftCrew.id,
                },
              })
              .exec();

            const employees = await Promise.all(
              borerShiftCrewMembers
                .sort((a, b) => a.employeeOrder - b.employeeOrder)
                .map(async crewMember => {
                  const employeeInformation = await crewMember.populate('siteEmployeeId');

                  if (employeeInformation?.isActive) {
                    return {
                      firstName: employeeInformation.firstName,
                      lastName: employeeInformation.lastName,
                      id: employeeInformation.id,
                      borerShiftCrewMemberId: crewMember.id,
                      borerShiftCrewMemberVersion: crewMember.version,
                      reactKey: uuidv4(),
                      employeeOrder: crewMember.employeeOrder,
                      fullName: `${employeeInformation.firstName} ${employeeInformation.lastName}`,
                      borerShiftCrewMemberRoleId: crewMember.borerShiftCrewMemberRoleId,
                    };
                  } else {
                    return {
                      // As of MDP-7272, Roles can be missing employee, we need to keep the ID to ensure we delete the role later
                      borerShiftCrewMemberId: crewMember.id,
                      reactKey: uuidv4(),
                      borerShiftCrewMemberRoleId: crewMember.borerShiftCrewMemberRoleId,
                    };
                  }
                }),
            );

            if (employees) {
              borerShiftCrews[index] = {
                ...borerShiftCrews[index],
                id: borerShiftCrew.id,
                crewNumber: borerShiftCrew.crewNumber,
                start: dayjs(borerShiftCrew.start).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
                end: dayjs(borerShiftCrew.end).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
                version: borerShiftCrew.version,
                hidden: borerShiftCrew.hidden,
                borerShiftCrewMemberInput: employees.map(employee => ({
                  employee,
                  borerShiftCrewMemberRole:
                    borerShiftCrewMemberRolesById[employee?.borerShiftCrewMemberRoleId],
                })),
              };
            }
          }),
        );
      }

      return { borerShiftCrews };
    } catch (error) {
      console.log('🚀 ~ file: useBorerShiftCrew.ts ~ line 71 ~ error', error);
      throw error;
    }
  };

  // useRxData hook works better after the migration runs
  const firstCrewQueryConstructor = useCallback(
    (collection: any) => {
      return collection.findOne({
        selector: {
          crewNumber: 1,
          borerShiftId: shiftPicker.currentBorerShiftId,
          isDeleted: false,
        },
        sort: [{ updatedAt: 'desc' }],
      });
    },
    [shiftPicker.currentBorerShiftId],
  );

  const { result: firstBorerShiftCrew }: { result: BorerShiftCrewDocument[] } = useRxData(
    RxdbCollectionName.BORER_SHIFT_CREW,
    firstCrewQueryConstructor,
  );

  const firstCrewMembersQueryConstructor = useCallback(
    (collection: any) => {
      return collection.find({
        selector: {
          borerShiftCrewId: firstBorerShiftCrew?.[0]?.id,
        },
      });
    },
    [firstBorerShiftCrew],
  );

  const { result: firstBorerShiftCrewMembers }: { result: BorerShiftCrewMemberDocument[] } =
    useRxData(RxdbCollectionName.BORER_SHIFT_CREW_MEMBER, firstCrewMembersQueryConstructor);

  // useRxData hook works better after the migration runs
  const secondCrewQueryConstructor = useCallback(
    (collection: any) => {
      return collection.findOne({
        selector: {
          crewNumber: 2,
          borerShiftId: shiftPicker.currentBorerShiftId,
          isDeleted: false,
        },
        sort: [{ updatedAt: 'desc' }],
      });
    },
    [shiftPicker.currentBorerShiftId],
  );

  const { result: secondBorerShiftCrew }: { result: BorerShiftCrewDocument[] } = useRxData(
    RxdbCollectionName.BORER_SHIFT_CREW,
    secondCrewQueryConstructor,
  );

  const secondCrewMembersQueryConstructor = useCallback(
    (collection: any) => {
      return collection.find({
        selector: {
          borerShiftCrewId: secondBorerShiftCrew?.[0]?.id,
        },
      });
    },
    [secondBorerShiftCrew],
  );

  const { result: secondBorerShiftCrewMembers }: { result: BorerShiftCrewMemberDocument[] } =
    useRxData(RxdbCollectionName.BORER_SHIFT_CREW_MEMBER, secondCrewMembersQueryConstructor);

  const populateCrew = useCallback(
    async (
      crew: BorerShiftCrewDocument,
      crewMembers: BorerShiftCrewMemberDocument[],
      crewMemberRolesById,
    ) => {
      let populatedCrew: ExtendedBorerShiftCrewDocument | null = null;

      if (crewMembers && crew && borerShiftCrewMemberRolesById) {
        const employees = await Promise.all(
          crewMembers
            .sort((a, b) => a.employeeOrder - b.employeeOrder)
            .map(async crewMember => {
              const employeeInformation = crewMember?.siteEmployeeId
                ? await crewMember.populate('siteEmployeeId')
                : null;

              if (employeeInformation?.isActive) {
                return {
                  firstName: employeeInformation.firstName,
                  lastName: employeeInformation.lastName,
                  id: employeeInformation.id,
                  borerShiftCrewMemberId: crewMember.id,
                  borerShiftCrewMemberVersion: crewMember.version,
                  reactKey: uuidv4(),
                  employeeOrder: crewMember.employeeOrder,
                  fullName: `${employeeInformation.firstName} ${employeeInformation.lastName}`,
                  borerShiftCrewMemberRoleId: crewMember.borerShiftCrewMemberRoleId,
                };
              } else {
                return {
                  // As of MDP-7272, Roles can be missing employee, we need to keep the ID to ensure we delete the role later
                  borerShiftCrewMemberId: crewMember.id,
                  reactKey: uuidv4(),
                  borerShiftCrewMemberRoleId: crewMember.borerShiftCrewMemberRoleId,
                };
              }
            }),
        );

        if (employees && crew) {
          populatedCrew = {
            ...crew,
            id: crew?.id,
            crewNumber: crew?.crewNumber,
            start: dayjs(crew?.start).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
            end: dayjs(crew?.end).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
            version: crew?.version,
            hidden: crew?.hidden,
            borerShiftCrewMemberInput: employees.map(employee => ({
              employee,
              borerShiftCrewMemberRole: crewMemberRolesById[employee?.borerShiftCrewMemberRoleId],
            })),
          };
        }
      }

      return populatedCrew;
    },
    [],
  );

  useEffect(() => {
    let initial = true;
    const populateFirstCrew = async () => {
      const populatedCrew = await populateCrew(
        firstBorerShiftCrew?.[0],
        firstBorerShiftCrewMembers,
        borerShiftCrewMemberRolesById,
      );
      if (initial) setFirstBorerShiftCrewPopulated(populatedCrew);
    };

    populateFirstCrew();

    return () => {
      initial = false;
    };
  }, [
    firstBorerShiftCrew,
    firstBorerShiftCrewMembers,
    borerShiftCrewMemberRolesById,
    populateCrew,
  ]);

  useEffect(() => {
    let initial = true;
    const populateSecondCrew = async () => {
      const populatedCrew = await populateCrew(
        secondBorerShiftCrew?.[0],
        secondBorerShiftCrewMembers,
        borerShiftCrewMemberRolesById,
      );
      if (initial) setSecondBorerShiftCrewPopulated(populatedCrew);
    };

    populateSecondCrew();

    return () => {
      initial = false;
    };
  }, [
    secondBorerShiftCrew,
    secondBorerShiftCrewMembers,
    borerShiftCrewMemberRolesById,
    populateCrew,
  ]);

  const updateBorerShiftCrew = async (
    borerShiftCrewDetails: BorerShiftCrewType,
    originalBorerShiftCrewDetails: BorerShiftCrewType,
  ) => {
    if (!shiftPicker.currentBorerShiftId) throw new Error('Missing current borer shift id');

    try {
      const deletedRoles = originalBorerShiftCrewDetails.borerShiftCrewMemberInput
        // See if original crew positions are present in our new member array
        // Need to have same borerShiftCrewMemberId, otherwise add to delete array
        .filter(
          originalCrewMember =>
            !borerShiftCrewDetails.borerShiftCrewMemberInput.find(crewMember => {
              return (
                crewMember?.employee?.borerShiftCrewMemberId &&
                crewMember?.employee?.borerShiftCrewMemberId ===
                  originalCrewMember?.employee?.borerShiftCrewMemberId
              );
            }),
        );

      const employeesToUpsert = borerShiftCrewDetails.borerShiftCrewMemberInput.filter(
        crewMember =>
          !deletedRoles.find(
            deletedCrewMember =>
              deletedCrewMember.employee?.borerShiftCrewMemberId ===
              crewMember.employee?.borerShiftCrewMemberId,
          ),
      );

      // Update or insert the borer shift crew
      const existingDoc = await borerShiftCrewCollection
        ?.findOne({
          selector: {
            crewNumber: borerShiftCrewDetails.crewNumber,
            borerShiftId: shiftPicker.currentBorerShiftId,
          },
          sort: [{ updatedAt: 'desc' }],
        })
        .exec();

      let borerShiftCrewId = borerShiftCrewDetails.id;
      if (existingDoc) {
        borerShiftCrewId = existingDoc.id;
        await existingDoc.update({
          $set: {
            updatedAt: getUnixMillisecondTimestamp(),
            isDeleted: false,
            version: existingDoc.version,
            hidden: false,
            end: formatAsAWSDateTime(dayjs(borerShiftCrewDetails.endDateTime), true),
            start: formatAsAWSDateTime(dayjs(borerShiftCrewDetails.startDateTime), true),
          },
        });
      } else {
        const doc: SetBorerShiftCrewInput = {
          id: borerShiftCrewDetails.id,
          isDeleted: false,
          version: borerShiftCrewDetails.version ? borerShiftCrewDetails.version : 1,
          end: formatAsAWSDateTime(dayjs(borerShiftCrewDetails.endDateTime), true),
          start: formatAsAWSDateTime(dayjs(borerShiftCrewDetails.startDateTime), true),
          borerShiftId: shiftPicker.currentBorerShiftId,
          crewNumber: borerShiftCrewDetails.crewNumber || 1,
          updatedAt: getUnixMillisecondTimestamp(),
          hidden: false,
        };

        await borerShiftCrewCollection?.upsert(doc);
      }

      // Update or insert the borer shift crew members
      await Promise.all([
        ...employeesToUpsert.map(async (crewMemberWithRole, index) => {
          const existingCrewMemberDoc = await borerShiftCrewMemberCollection
            ?.findOne({
              selector: {
                borerShiftCrewId,
                id: crewMemberWithRole.employee.borerShiftCrewMemberId,
              },
            })
            .exec();

          if (existingCrewMemberDoc) {
            await existingCrewMemberDoc.incrementalModify(prev => ({
              ...prev,
              updatedAt: getUnixMillisecondTimestamp(),
              employeeOrder: index,
              isDeleted: false,
              borerShiftCrewMemberRoleId: crewMemberWithRole.borerShiftCrewMemberRole?.id,
            }));
          } else {
            if (!crewMemberWithRole.borerShiftCrewMemberRole?.id)
              throw new Error('Missing borerShiftCrewMemberRole id');
            if (!crewMemberWithRole.employee?.id) throw new Error('Missing employee id');
            const borerShiftCrewMemberDoc: BorerShiftCrewMember = {
              id: v4(),
              borerShiftCrewId,
              siteEmployeeId: crewMemberWithRole.employee.id,
              employeeOrder: borerShiftCrewDetails.borerShiftCrewMemberInput.findIndex(elem =>
                Boolean(
                  elem.employee?.id === crewMemberWithRole.employee?.id &&
                    elem.borerShiftCrewMemberRole?.id ===
                      crewMemberWithRole.borerShiftCrewMemberRole?.id,
                ),
              ),
              isDeleted: false,
              version: crewMemberWithRole.employee?.borerShiftCrewMemberVersion || 1,
              borerShiftCrewMemberRoleId: crewMemberWithRole.borerShiftCrewMemberRole?.id,
              updatedAt: getUnixMillisecondTimestamp(),
            };
            await borerShiftCrewMemberCollection?.upsert(borerShiftCrewMemberDoc);
          }
        }),
        ...deletedRoles.map(async crewMemberWithRole => {
          const borerShiftCrewMemberToDeleteDoc = await borerShiftCrewMemberCollection
            ?.findOne()
            .where('id')
            .eq(crewMemberWithRole.employee.borerShiftCrewMemberId)
            .exec();

          if (borerShiftCrewMemberToDeleteDoc)
            await borerShiftCrewMemberToDeleteDoc.incrementalRemove();
        }),
      ]);
    } catch (err) {
      console.log('🚀 ~ file: useBorerShiftCrew.ts ~ line 109 ~ err', err);
      throw new Error(err);
    }
  };

  const deleteBorerShiftCrew = async (originalBorerShiftCrewDetails: BorerShiftCrewType) => {
    const borerShiftCrewMembers = await borerShiftCrewMemberCollection
      ?.find({
        selector: {
          borerShiftCrewId: originalBorerShiftCrewDetails.id,
        },
      })
      .exec();

    if (borerShiftCrewMembers)
      await Promise.all(borerShiftCrewMembers.map(async crewMemberDoc => crewMemberDoc.remove()));
  };

  return {
    borerShiftCrewCollection,
    getBorerShiftCrews,
    borerShiftCrewInitialized,
    updateBorerShiftCrew,
    borerShiftCrewMemberCollection,
    borerShiftCrewMemberInitialized,
    deleteBorerShiftCrew,
    firstBorerShiftCrewPopulated,
    secondBorerShiftCrewPopulated,
  };
};

export default useBorerShiftCrew;
