import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Dialog, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    offlineText: {
      marginLeft: '10px',
    },
    infoTextContainer: {
      display: 'flex',
      marginTop: '20px',
    },
  }),
);

interface Props {
  open: boolean;
}

const OfflineStatusDialog: React.FC<Props> = ({ open }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      titleText="Offline"
      backButton={false}
      disableEnforceFocus
      maxWidth="xs"
      maxHeight="280px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Grid item xs={11} className={classes.infoTextContainer}>
        <Grid item xs={1}>
          <Icons.InfoFeather color="error" />
        </Grid>
        <Typography variant="h6" align="left" className={classes.offlineText}>
          {i18n.t(
            'You appear to be offline. Please connect the iPad to WiFi. If you are online, please wait a moment while the issue resolves.',
          )}
        </Typography>
      </Grid>
    </Dialog>
  );
};

export default OfflineStatusDialog;
