import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useNotification from '@/utilities/hooks/useNotification';

import { useMst } from '../../mobx-models/Root';
import usePanelDrawings from '../../rxdb/PanelDrawing/usePanelDrawings';
import { GROUND_HAZARD_WRITE_PERMISSION } from '../../utilities/constants';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
  }),
);

const NoPanelDrawingCard: React.FC = () => {
  const classes = useStyles();
  const { user, shiftPicker } = useMst();
  const { createNewPanelDrawing } = usePanelDrawings();
  const { successNotification, errorNotification } = useNotification();

  const onConfirmNewPanelDrawing = async () => {
    try {
      await createNewPanelDrawing();
      successNotification('Panel drawing created.');
    } catch (e) {
      errorNotification('Unable to create panel drawing. Ensure Borer is assigned to a location.');
      console.log('🚀 ~ file: NoPanelDrawingCard.tsx:29 ~ onConfirmNewPanelDrawing ~ e:', e);
    }
  };

  return (
    <>
      <div data-cy="NoPanelDrawingCard">
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h3">{i18n.t('Active panel drawing')}</Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end" spacing={1}>
              {user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION) &&
                shiftPicker.isCurrentShiftSelected() && (
                  <Grid item>
                    <Button
                      variant="contained"
                      startAdornment={
                        <Icons.Edit2Feather color="inherit" style={{ marginRight: 2 }} />
                      }
                      color="primary"
                      noMinHeight
                      onClick={onConfirmNewPanelDrawing}
                      id="edit-panel-drawing-button"
                    >
                      Create new panel drawing
                    </Button>
                  </Grid>
                )}
            </Grid>
            <Typography variant="h5">{i18n.t('No panel drawing exists')}</Typography>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default observer(NoPanelDrawingCard);
