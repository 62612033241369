import { useState } from 'react';

const useConstructor = (callBack: () => any) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

export { useConstructor };
