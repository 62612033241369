import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../Locations/queryBuilder';
import useLocations, { PopulatedLocation } from '../Locations/useLocations';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import { Prediction, PredictionCollection, PredictionDocument } from './queryBuilder';

export const usePrediction = () => {
  const { populateLocation } = useLocations();
  const predictionCollection: PredictionCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_PREDICTION,
  );

  const { shiftPicker } = useMst();
  const borerShiftId = shiftPicker.currentBorerShiftId || '';
  const predictionDocQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerShiftId,
        },
      }),
    [borerShiftId],
  );

  const { result: predictionDocArray, isFetching: isPredictionFetching } = useRxData<Prediction>(
    RxdbCollectionName.BORER_SHIFT_PREDICTION,
    predictionDocQueryConstructor,
  );

  const [predictionInitialized, setPredictionInitialized] = useState(false);
  const [predictedEndLocation, setPredictedEndLocation] = useState<PopulatedLocation | undefined>();

  useEffect(() => {
    if (predictionCollection) setPredictionInitialized(true);
  }, [predictionCollection]);

  const getPredictionForShift = async (
    currentBorerShiftId: string,
  ): Promise<PredictionDocument | undefined> => {
    if (!predictionCollection) {
      return undefined;
    }

    const prediction = await predictionCollection
      .findOne({
        selector: {
          borerShiftId: currentBorerShiftId,
        },
      })
      .exec();

    return prediction;
  };

  const getPredictionById = async (id: string) => {
    if (!predictionCollection) {
      return undefined;
    }

    const prediction = await predictionCollection
      .findOne({
        selector: {
          id,
        },
      })
      .exec();
    return prediction;
  };

  const setPrediction = async (
    predictionToEdit: PredictionDocument,
    endFootage: number,
    endLocationId: string,
  ) => {
    const doc = {
      id: predictionToEdit.id,
      borerShiftId: predictionToEdit.borerShiftId,
      endDistance: endFootage,
      endLocation: endLocationId,
      updatedAt: getUnixMillisecondTimestamp(),
      version: predictionToEdit.version,
      isDeleted: predictionToEdit.isDeleted || false,
    };
    return predictionCollection?.upsert(doc);
  };

  const createPrediction = async (predictionToEdit: PredictionDocument) => {
    return predictionCollection?.insert(predictionToEdit);
  };

  useEffect(() => {
    let sub: Subscription | undefined;

    const getCurrentPredictions = async () => {
      setPredictedEndLocation(undefined);

      if (!shiftPicker.currentBorerShiftId || !predictionCollection) {
        return;
      }
      try {
        sub = predictionCollection
          ?.findOne({
            selector: {
              borerShiftId: shiftPicker.currentBorerShiftId,
            },
          })
          .$.subscribe(async prediction => {
            if (prediction) {
              const endLocation: LocationDocument = await prediction?.populate('endLocation');
              const endLocationDetails = await populateLocation(endLocation);
              setPredictedEndLocation(endLocationDetails);
            } else {
              setPredictedEndLocation(undefined);
            }
          });
      } catch (error) {
        setPredictedEndLocation(undefined);
        console.log(error);
      }
    };

    getCurrentPredictions();
    return () => {
      sub?.unsubscribe();
    };
  }, [predictionCollection, shiftPicker.currentBorerShiftId]);

  return {
    predictionInitialized,
    getPredictionForShift,
    getPredictionById,
    setPrediction,
    createPrediction,
    predictionCollection,
    predictedEndLocation,
    predictionForShift: predictionDocArray[0],
    isPredictionFetching,
  };
};

export default usePrediction;
