import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette, MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      backgroundColor: CustomPalette.elevation.dp4,
    },
    chevronDown: {
      marginLeft: '8px',
    },
    datePickerButton: {
      fontWeight: '300 !important',
    },
    envTitle: {
      marginLeft: '16px !important',
    },
    offlineText: {
      margin: '10px',
    },
    infoTextContainer: {
      display: 'flex',
      maxWidth: '400px',
      marginLeft: '5px',
      padding: '10px',
    },
    paper: {
      maxWidth: '300px',
      minWidth: '250px',
      padding: '8px',
      backgroundColor: CustomPalette.elevation.dp24Solid,
    },
    infoIcon: {
      marginRight: '10px',
    },
    userMenuIcon: {
      zIndex: '2100 !important',
    },
    alertContainer: {
      width: '100%',
      zIndex: `1401 !important`,
    },
    error: {
      color: MaterialPalette.common.white,
      backgroundColor: MaterialPalette.error.dark,
    },
    info: {
      color: MaterialPalette.common.white,
      backgroundColor: MaterialPalette.info.dark,
    },
    dialog: {
      zIndex: '10000 !important',
      '& #customized-dialog-title': {
        padding: '0px',
        background: MaterialPalette.error.dark,
      },
      '& #customized-dialog-title button': {
        display: 'none',
      },
    },
  }),
);
