import type { PopoverProps, Theme } from '@material-ui/core';
import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { i18n, Typography } from '@nutrien/cxp-components';
import React from 'react';

import { InspectionType } from '../../utilities/constants';
import { WorkFaceAreaInspectionType } from '../../utilities/enums';
import { HazardMenuOption, useHazardMenuOptions } from './useHazardMenuOptions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderRadius: '8px !important',
    },
    div: { backgroundColor: theme.palette.background.default },
    container: {
      padding: '16px',
      backgroundColor: 'rgba(56,56,56,1)',
    },
    title: {
      paddingBottom: '24px',
      color: theme.palette.text.secondary,
    },
    link: {
      padding: '10px 0px',
    },
  }),
);

interface Props extends PopoverProps {
  openAddHazard: () => void;
  onAddCuttingPermit: () => void;
  onAddAreaCheck: (inspectionType?: InspectionType) => void;
  onAddWorkAreaInspection: (workFaceAreaInspectionType: WorkFaceAreaInspectionType) => void;
  isCurrentShiftSelected: boolean;
}

const HazardMenu = ({
  open,
  anchorEl,
  onClose,
  openAddHazard,
  onAddCuttingPermit,
  onAddAreaCheck,
  isCurrentShiftSelected,
  onAddWorkAreaInspection,
}: Props) => {
  const classes = useStyles();
  const menuOptions = useHazardMenuOptions();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.popover}
    >
      <div className={classes.div}>
        <Grid container className={classes.container} style={{ width: 450 }}>
          {isCurrentShiftSelected && (
            <Grid item container xs={8}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h6">{i18n.t('Checklist')}</Typography>
              </Grid>
              {menuOptions?.map((menuOption: HazardMenuOption) => (
                <Grid
                  item
                  xs={12}
                  key={menuOption.label}
                  className={classes.link}
                  onClick={() => {
                    if (menuOption.isCuttingPermit) {
                      onAddCuttingPermit();
                    } else if (menuOption.isWfai && menuOption.inspectionType) {
                      onAddWorkAreaInspection(
                        menuOption.inspectionType as WorkFaceAreaInspectionType,
                      );
                    } else if (menuOption.inspectionType) {
                      onAddAreaCheck(menuOption.inspectionType as InspectionType);
                    }
                  }}
                  id={`add-${menuOption.label}`}
                  data-testid={`${menuOption.inspectionType}`}
                >
                  <Typography variant="body1">{menuOption.label}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item xs={4}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">{i18n.t('Single entry')}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.link}
              data-testid="add-hazard-option"
              onClick={openAddHazard}
              id="add-hazard"
            >
              <Typography variant="body1">{i18n.t('Hazard')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default HazardMenu;
