import type { RxDatabase } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';

import { BorerDatabaseCollections } from '../../models/BorerDatabaseCollections';
import { isJestOrStorybook } from '../../test-helpers/isJestOrStorybook';
import { BorerOperatorChangeCollection } from '../BorerOperatorChangeFeed/queryBuilder';
import { checkForExpiredToken } from '../handleReplicationErrors';
import { CheckpointType } from '../types';
import { BorerOperatorStateCollection } from './queryBuilder';

const recordIdsFailedToSyncCount: Record<string, number> = {};

const increaseFailedToSyncCount = (recordId: string) => {
  if (!recordIdsFailedToSyncCount[recordId]) {
    recordIdsFailedToSyncCount[recordId] = 1;
  }
  recordIdsFailedToSyncCount[recordId] += 1;
};

const recordIdHasFailedToSyncMoreThanXTimes = (recordId: string, xTimes = 10) => {
  return recordIdsFailedToSyncCount[recordId] >= xTimes;
};

export const handleOperatorStateFeedEvents = async (
  stateFeedSyncState: RxGraphQLReplicationState<BorerOperatorStateCollection, CheckpointType>,
  borerOperatorChangeState: RxGraphQLReplicationState<
    BorerOperatorChangeCollection,
    CheckpointType
  >,
  db: RxDatabase<BorerDatabaseCollections>,
) => {
  // Successfully coded states, hide temp states from scheduler view
  stateFeedSyncState.received$.subscribe(async doc => {
    if (doc.isDeleted || doc._deleted) return;
    try {
      const borerStateId = doc.borerStateId;
      if (!borerStateId)
        throw new Error(
          'handleOperatorStateFeedEvents.ts:37 Doc missing borerStateId, cannot query for temp states',
        );

      const matchingTempDocs = await db.collections.borer_operator_change_feed
        ?.find({
          selector: {
            borerStateId,
            showInSchedulerView: true,
          },
        })
        .exec();

      for (const tempDoc of matchingTempDocs) {
        await tempDoc.incrementalPatch({
          showInSchedulerView: false,
          failedSync: false,
        });
      }
    } catch (err) {
      console.log('🚀 ~ file: handleOperatorStateFeedEvents.ts:54 ~ err:', err);
    }
  });

  // Failed to code states, show temp states with error, and original states in scheduler view
  if (borerOperatorChangeState)
    borerOperatorChangeState.error$.subscribe(async (err: RxError) => {
      console.error('🚀 ~ file:  borerOperatorChangeState.error$.subscribe ~ err:', err);
      const hasExpiredToken = checkForExpiredToken(err);
      if (hasExpiredToken) return;

      const documentsData = err.parameters.pushRows?.[0]?.newDocumentState;
      const borerStateId = documentsData.borerStateId as string;
      const docId = documentsData.id as string;

      if (!borerStateId) {
        console.log(
          'Failed to code state, and no borerStateId found in error. Cannot mark temp state as failedSync',
        );
        return;
      }

      if (!recordIdHasFailedToSyncMoreThanXTimes(docId) && !isJestOrStorybook()) {
        // Allow Rxdb to retry (won't retry if failedSync is true)
        increaseFailedToSyncCount(docId);
        return;
      } else {
        // Toggle failedSync to true for temp items
        const tempStateDocsForBorerStateId = await borerOperatorChangeState.collection
          .find({
            selector: {
              borerStateId,
            },
          })
          .exec();

        tempStateDocsForBorerStateId.forEach(doc => doc.incrementalPatch({ failedSync: true }));

        delete recordIdsFailedToSyncCount[docId];

        // Unhide original delay in scheduler view (now with error)
        const syncStatesForBorerStateId = await stateFeedSyncState.collection
          .find({
            selector: {
              borerStateId,
            },
          })
          .exec();

        syncStatesForBorerStateId.forEach(doc =>
          doc.incrementalPatch({ showInSchedulerView: true }),
        );
      }
    });
};
