import { createStyles, makeStyles } from '@material-ui/core';
import { Dialog, Typography } from '@nutrien/cxp-components';
import { default as React, useEffect, useState } from 'react';

import DiscardDraftModal from '../DiscardDraftModal';
import Spinner from '../Spinner';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
      textAlign: 'center',
    },
    body: {
      marginTop: '40px !important',
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);
interface Props {
  open: boolean;
  onDeferUpdate: (updateFailed?: boolean) => void;
  isOnline: boolean;
  autoUpdateModal?: boolean;
  checkingForUpdate?: boolean;
  onTriggerUpdate: () => void;
}

export const PING_RESOURCE_BIG = '/ping_big.txt';
const TIMEOUT_TIME_MS = 2000;

const timeout = (time: number, promise: Promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const UpdateModal: React.FC<Props> = ({
  open,
  onDeferUpdate,
  isOnline,
  onTriggerUpdate,
  autoUpdateModal = false,
}: Props) => {
  const [checkingForUpdate, setCheckingForUpdate] = useState(false);
  const [autoUpdating, setAutoUpdating] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (autoUpdateModal && isOnline && open) {
      (async () => {
        try {
          setCheckingForUpdate(true);
          const controller = new AbortController();
          const { signal } = controller;
          await timeout(
            TIMEOUT_TIME_MS,
            fetch(PING_RESOURCE_BIG, {
              method: 'GET',
              signal,
            }),
          );
          setCheckingForUpdate(false);
          setAutoUpdating(true);
          onTriggerUpdate();
        } catch (e) {
          console.log('🚀 ~ Error while checking network or updating:', e);
          onDeferUpdate(true);
        }
      })();
    } else {
      setCheckingForUpdate(false);
    }
  }, [isOnline, autoUpdateModal, open]);

  if (!open) return null;
  return checkingForUpdate || autoUpdating ? (
    <Dialog
      open
      titleText="Software update"
      backButton={false}
      disableEnforceFocus
      maxWidth="xs"
      maxHeight={'250px'}
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      classes={{ container: classes.backdrop }}
    >
      <Typography variant="h6" className={classes.title}>
        {checkingForUpdate && 'Checking connection...'}
        {autoUpdating && 'Updating app...'}
      </Typography>
      <div className={classes.body}>
        <Spinner />
      </div>
    </Dialog>
  ) : (
    <DiscardDraftModal
      open={open}
      onCancel={() => onDeferUpdate(false)}
      hideCloseButton={autoUpdateModal}
      onDiscard={onTriggerUpdate}
      disableActionButton={!isOnline}
      bothPrimaryColors
      titleText="Software update"
      areYouSureText="Software update required."
      cancelText={
        isOnline
          ? 'Would you like to download and install this update now?'
          : 'You must be online to install the update, please connect to WiFi or move to an area with better connectivity.'
      }
      continueEditingButtonText={'Remind me in 1 hr'}
      discardDraftButtonText="Install"
      disableEnforceFocus
    />
  );
};

export default UpdateModal;
