import { getSurveyPointInt } from '@/utilities/utilityFunctions';

import { DocumentUploadDocument } from './queryBuilder';

export const getDocsForSurveyPointWithCarryForwards = (
  surveyPointDesc: string,
  docsBySurveyPointInts: Record<string, DocumentUploadDocument[]>,
  surveyPointIdsToIntsMap: Record<string, number>,
): DocumentUploadDocument[] => {
  const docsAtLocation: DocumentUploadDocument[] = [];

  const allSurveyPointsInRoom = Object.values(surveyPointIdsToIntsMap).sort((a, b) => {
    return a - b;
  });

  const smallestSurveyPointInRoom = allSurveyPointsInRoom[0];
  const surveyPointInt = getSurveyPointInt(surveyPointDesc);

  const surveyPointKeysWithDocs = Object.keys(docsBySurveyPointInts);

  // iterate from the surveyPointDesc number to the smallest survey point in the room
  // for each point we are looking at iterate over the docs in that point
  for (let index = surveyPointInt; index >= smallestSurveyPointInRoom; index--) {
    const docsAtSurveyPoint = surveyPointKeysWithDocs.includes(index.toString())
      ? docsBySurveyPointInts[index.toString()]
      : [];

    // for each doc in that point
    docsAtSurveyPoint.forEach(doc => {
      // if it does not have a stop carry forward, and you don't already have a doc of the same type in the docsAtLocation array, add it to the array
      if (doc.stopcarryforwardSurveyPointId === null) {
        const docOfSameTypeAlreadyExists = docsAtLocation.findIndex(
          docInSurveyPoint => docInSurveyPoint.borerDocumentTypeId === doc.borerDocumentTypeId,
        );

        if (
          docOfSameTypeAlreadyExists === -1 ||
          doc.documentTypeDescription?.toLowerCase() === 'other'
        )
          docsAtLocation.push(doc);
      }
      // if it does have a stop carry forward, check if that stop carry forward is the same as or before the surveyPointDesc number
      else {
        const stopCarryForwardSurveyPointInt =
          surveyPointIdsToIntsMap[doc.stopcarryforwardSurveyPointId];

        if (stopCarryForwardSurveyPointInt >= surveyPointInt) {
          // if it is, and you don't already have a doc of the same type in the docsAtLocation array, add it to the array
          const docOfSameTypeAlreadyExists = docsAtLocation.findIndex(
            docInSurveyPoint => docInSurveyPoint.borerDocumentTypeId === doc.borerDocumentTypeId,
          );

          if (
            docOfSameTypeAlreadyExists === -1 ||
            doc.documentTypeDescription?.toLowerCase() === 'other'
          )
            docsAtLocation.push(doc);
        }
      }
    });
  }

  return docsAtLocation;
};
