import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { SignatureType } from '../../utilities/enums';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
} from '../BorerShiftSignature/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import { Signature, SignatureCollection, SignatureDocument } from './rxdbSignatureDefinition';

export const useSignatures = () => {
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const [signaturesInitialized, setSignaturesInitialized] = useState(false);
  const { shiftPicker } = useMst();

  useEffect(() => {
    if (signatureCollection) setSignaturesInitialized(true);
  }, [signatureCollection]);

  const listSignatures = async (): Promise<SignatureDocument[]> => {
    if (!signatureCollection) {
      return [];
    }
    try {
      const signatures = await signatureCollection.find().exec();
      return signatures;
    } catch (error) {
      console.log('🚀 ~ file: useSignatures.ts ~ line 28 ~ listSignatures ~ error', error);
      throw error;
    }
  };

  const getSignature = (id: string): Promise<SignatureDocument | null> | undefined => {
    return signatureCollection
      ?.findOne({
        selector: {
          id,
        },
      })
      .exec();
  };

  const createSignature = (
    signatureType: SignatureType,
    signatureData: string,
    siteEmployeeId?: string,
    personnelName?: string,
    reviewedCuttingPermits = false,
    reviewedSafeWorkPlan = null,
    purposeOfVisit = null,
  ) => {
    const { currentBorerShiftId } = shiftPicker;
    if (!currentBorerShiftId) throw new Error('Missing Borer Shift Id');

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntityWithCreatedOn(),
      signatureType,
      siteEmployeeId: siteEmployeeId || undefined,
      personnelName: personnelName || undefined,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: currentBorerShiftId,
      signatureId: signature.id,
      reviewedCuttingPermits,
      reviewedSafeWorkPlan,
      purposeOfVisit,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  return {
    listSignatures,
    getSignature,
    signaturesInitialized,
    createSignature,
  };
};

export default useSignatures;
