import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    card: {
      padding: '16px',
    },
    topMargin: {
      marginTop: 20,
    },
    bannerEnabled: {
      height: 'calc(100vh - 214px)',
      overflow: 'scroll',
    },
    nightShiftScheduleContainer: {
      '& > div': {
        height: 'auto !important',
      },
      '& > div:first-child > div': {
        marginBottom: -8,
        overflowY: 'hidden',
      },
      marginTop: 20,
    },
    fab: {
      position: 'absolute',
      right: '25px',
      zIndex: '1300',
    },
    buttonRightPad: {
      paddingRight: '8px',
    },
    textRightPad: {
      paddingRight: '16px',
    },
    buttonLight: {
      backgroundColor: 'rgba(42, 171, 63, 0.16)',
      color: MaterialPalette.primary.main,
      padding: '0 5px',
    },
    buttonPadding: {
      padding: '0 5px',
    },
    verticalRule: {
      borderLeft: `1px solid ${MaterialPalette.grey[700]}`,
      paddingLeft: '8px',
      marginLeft: '8px',
    },
    titleContainer: {
      padding: '16px 16px 8px 16px',
    },
  }),
);

export default useStyles;
