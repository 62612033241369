import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { rootStore } from '../../mobx-models/Root';
import { BORER_DESCRIPTION } from '../../utilities/constants';
import { EquipmentStatus } from '../EquipmentStatus/queryBuilder';
import { EquipmentType, EquipmentTypeCollection } from '../EquipmentType/queryBuilder';
import { EquipmentWithType } from '../EquipmentType/useEquipmentListByTypeName';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Equipment, EquipmentCollection, EquipmentDocument } from './queryBuilder';

export interface PopulatedEquipment extends Equipment {
  equipmentStatus: EquipmentStatus;
  equipmentType: EquipmentType;
}
export const useEquipment = () => {
  const equipmentCollection: EquipmentCollection = useRxCollection(RxdbCollectionName.EQUIPMENT);
  const equipmentTypeCollection: EquipmentTypeCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_TYPE,
  );

  const [equipmentInitialized, setCollectionsInitialized] = useState(false);
  const [assignedEquipmentList, setAssignedEquipmentList] = useState<PopulatedEquipment[]>([]);

  const equipmentQuery = useCallback(collection => collection.find(), []);

  const { result: equipmentList } = useRxData<Equipment>(
    RxdbCollectionName.EQUIPMENT,
    equipmentQuery,
  );

  useEffect(() => {
    if (equipmentCollection) setCollectionsInitialized(true);
  }, [equipmentCollection]);

  const getAssignedEquipment = useCallback(async () => {
    const assignedEquipment = equipmentList.filter(
      equipment =>
        equipment.assignedBorerEquipmentId === rootStore.equipment.selectedBorerId &&
        equipment.isActive === true,
    );

    const populatedEquipment = await Promise.all(
      assignedEquipment.map(async equipmentDoc => {
        const [equipmentStatusDoc, equipmentTypeDoc] = await Promise.all([
          equipmentDoc.populate('equipmentStatusId'),
          equipmentDoc.populate('equipmentTypeId'),
        ]);
        return {
          ...equipmentDoc.toJSON(),
          equipmentStatus: equipmentStatusDoc?.toJSON(),
          equipmentType: equipmentTypeDoc?.toJSON(),
        };
      }),
    );

    setAssignedEquipmentList(populatedEquipment);
  }, [equipmentList]);

  useEffect(() => {
    getAssignedEquipment();
  }, [equipmentList, getAssignedEquipment]);

  const listEquipment = async (): Promise<EquipmentDocument[]> => {
    if (!equipmentCollection) {
      return [];
    }

    // Pull Equipment
    let allEquipment;
    try {
      allEquipment = await equipmentCollection
        .find({
          selector: {
            isActive: true,
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useEquipment.ts ~ line 28 ~ listEquipment ~ error', error);
      throw error;
    }

    return allEquipment;
  };

  const listEquipmentOfType = async (equipmentTypeId: string): Promise<EquipmentDocument[]> => {
    if (!equipmentCollection) {
      return [];
    }

    // Pull Equipment
    let allEquipmentOfType;
    try {
      allEquipmentOfType = await equipmentCollection
        .find({ selector: { equipmentTypeId, isActive: true } })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useEquipment.ts ~ line 28 ~ listEquipment ~ error', error);
      throw error;
    }

    return allEquipmentOfType;
  };

  const listAssignedEquipment = async (equipmentTypeId: string): Promise<EquipmentWithType[]> => {
    if (!equipmentCollection || !equipmentTypeCollection) {
      return [];
    }

    const equipmentTypeDocument = await equipmentTypeCollection
      .findOne({
        selector: {
          id: equipmentTypeId,
        },
      })
      .exec();

    const isBorer = equipmentTypeDocument?.description === BORER_DESCRIPTION;

    let assignedEquipment;
    // Only return assigned borer
    if (isBorer) {
      try {
        assignedEquipment = await equipmentCollection
          .findOne({ selector: { id: rootStore.equipment.selectedBorerId } })
          .exec();

        assignedEquipment = [
          {
            ...assignedEquipment,
            assignedBorerEquipmentId: rootStore.equipment.selectedBorerId,
            equipmentTypeId,
            longName: assignedEquipment?.longName,
            isBorer: true,
            id: assignedEquipment?.id,
          },
        ];
      } catch (error) {
        console.log('🚀 ~ useEquipment.ts ~ line 107 ~ error', error);
        throw error;
      }
    } else {
      // Pull Equipment
      try {
        assignedEquipment = await equipmentCollection
          .find({ selector: { equipmentTypeId, isActive: true } })
          .exec();
      } catch (error) {
        console.log('🚀 ~ useEquipment.ts ~ line 113 ~ error', error);
        throw error;
      }
    }

    return assignedEquipment;
  };

  return {
    equipmentInitialized,
    equipmentCollection,
    assignedEquipmentList,
    listEquipment,
    listEquipmentOfType,
    listAssignedEquipment,
    getAssignedEquipment,
  };
};

export default useEquipment;
