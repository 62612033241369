import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
      margin: '0px 8px 8px 8px !important',
    },
    titleLine: {
      marginBottom: '2px',
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '24px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    secondaryText: {
      color: theme.palette.text.disabled,
    },
    commentsContainer: {
      marginTop: '16px',
      marginBottom: '16px',
      marginLeft: '-4px',
      alignItems: 'flex-start',
    },
    commentItemContainer: {
      marginBottom: '24px',
    },
  }),
);

export default useStyles;
