import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useMemo } from 'react';

import allanBg from '../../sharedAssets/allanPanelBg.png';
import chevronCoryBg from '../../sharedAssets/chevronCoryPanelBg-1024w.png';
import chevronLaniganBg from '../../sharedAssets/chevronLaniganPanelBg-1024w.png';
import chevronVanscoyBg from '../../sharedAssets/chevronVanscoyPanelBg-1024w.png';
import longRoomLaniganBg from '../../sharedAssets/longroomLaniganPanelBg-1024w.png';
import longRoomVanscoyBg from '../../sharedAssets/longRoomVanscoyPanelBg-1024w.png';
import rocanvilleBg from '../../sharedAssets/rocanvillePanelBg.png';
import { MiningMethod } from '../constants';
import { Size } from './useWindowSize';

export interface PanelDrawingBackground {
  miningMethod: MiningMethod;
  src: string;
  imageHeight: number;
  imageWidth: number;
}

export const laniganChevronPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.CHEVRON,
  src: chevronLaniganBg,
  imageWidth: 1024,
  imageHeight: 1085,
};

export const laniganLongroomPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.CHEVRON,
  src: longRoomLaniganBg,
  imageWidth: 1024,
  imageHeight: 150,
};

export const coryChevronPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.CHEVRON,
  src: chevronCoryBg,
  imageWidth: 1024,
  imageHeight: 1388,
};

export const vanscoyChevronPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.CHEVRON,
  src: chevronVanscoyBg,
  imageWidth: 1024,
  imageHeight: 1391,
};

export const vanscoyLongroomPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.LONG_ROOM,
  src: longRoomVanscoyBg,
  imageWidth: 1024,
  imageHeight: 356,
};

export const rocanvillePanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.UNIVERSAL,
  src: rocanvilleBg,
  imageWidth: 1024,
  imageHeight: 496,
};
export const allanPanelDrawing: PanelDrawingBackground = {
  miningMethod: MiningMethod.UNIVERSAL,
  src: allanBg,
  imageWidth: 1246,
  imageHeight: 1023,
};

interface UsePanelDrawingBackgroundsArgs {
  size: Size;
  miningMethod: MiningMethod;
  totalMarginPx?: number;
}

const usePanelDrawingBackgrounds = ({
  size,
  miningMethod,
  totalMarginPx = 64,
}: UsePanelDrawingBackgroundsArgs) => {
  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();

  const backgroundImage = useMemo(() => {
    if (isLanigan && miningMethod === MiningMethod.CHEVRON) return laniganChevronPanelDrawing;
    if (isLanigan && miningMethod === MiningMethod.LONG_ROOM) return laniganLongroomPanelDrawing;
    if (isCory) return coryChevronPanelDrawing;
    if (isVanscoy && miningMethod === MiningMethod.CHEVRON) return vanscoyChevronPanelDrawing;
    if (isVanscoy && miningMethod === MiningMethod.LONG_ROOM) return vanscoyLongroomPanelDrawing;
    if (isRocanville) return rocanvillePanelDrawing;
    if (isAllan) return allanPanelDrawing;
  }, [isLanigan, isCory, isVanscoy, isRocanville, isAllan, miningMethod]);

  const { imageHeight, imageWidth, imageWidthString, imageHeightString } = useMemo(() => {
    if (!backgroundImage || !size?.width || !size?.height) return { imageHeight: 0, imageWidth: 0 };

    const idealScalingFactor = (size.width - totalMarginPx) / backgroundImage.imageWidth;
    let imageHeightCalc = backgroundImage.imageHeight * idealScalingFactor;
    let imageWidthCalc = backgroundImage.imageWidth * idealScalingFactor;

    if (imageHeightCalc > 0.7 * size.height) {
      imageHeightCalc = 0.7 * size.height;
      imageWidthCalc = imageHeightCalc * (backgroundImage.imageWidth / backgroundImage.imageHeight);
    }
    return {
      imageHeight: imageHeightCalc,
      imageWidth: imageWidthCalc,
      imageWidthString: miningMethod === MiningMethod.CHEVRON ? imageWidthCalc : '100%',
      imageHeightString: miningMethod === MiningMethod.CHEVRON ? imageHeightCalc : 'unset',
    };
  }, [backgroundImage, size?.width, size?.height, totalMarginPx, miningMethod]);

  return { imageHeight, imageWidth, backgroundImage, imageWidthString, imageHeightString };
};

export default usePanelDrawingBackgrounds;
