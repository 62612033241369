import { FetchResult } from '@apollo/client';

import { client, gql } from './Client';

const createTrackingEventMutation = gql`
  mutation createTrackingEvent($input: CreateTrackingEventInput) {
    createTrackingEvent(input: $input)
  }
`;

export interface CreateTrackingEventInput {
  siteId: string;
  eventType: string;
  eventTimeOnDevice: string;
  eventData: string;
}

export const createTrackingEvent = (
  input: CreateTrackingEventInput,
): Promise<FetchResult<string>> => {
  return client.mutate({
    mutation: createTrackingEventMutation,
    variables: {
      input,
    },
  });
};
