import { SnackbarContent } from '@material-ui/core';
import React, { forwardRef } from 'react';

interface Props {
  id: string;
  message: string;
  bgColor: string;
}

const CustomBGSnackbar = forwardRef(({ message, bgColor }: Props, ref) => {
  return (
    <SnackbarContent
      ref={ref}
      message={message}
      style={{
        backgroundColor: bgColor,
      }}
    />
  );
});

CustomBGSnackbar.displayName = 'CustomBGSnackbar';
export default CustomBGSnackbar;
