import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import useEmployees from '@/rxdb/Employees/useEmployees';
import {
  EquipmentDeficiencyWithChildren,
  useEquipmentDeficiency,
} from '@/rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import { useEquipmentDeficiencyLog } from '@/rxdb/EquipmentDeficiencyLog/useEquipmentDeficiencyLog';

import DeficiencyExpansionPanel from './DeficiencyExpansionPanel';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    cardContainer: {
      padding: '16px 0px 0px 0px !important',
    },
  }),
);

const OtherDeficienciesCard: React.FC = () => {
  const classes = useStyles();

  const { shiftPicker } = useMst();

  const { employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: false,
    populatedPosition: false,
    onlyActiveCrew: false,
  });

  const {
    deficiencyCollectionsInitialized,
    equipmentDeficiencyCollection,
    listNonActiveEquipmentDeficienciesThisShift,
  } = useEquipmentDeficiency();

  const { deficiencyLogInitialized, equipmentDeficiencyLogCollection } =
    useEquipmentDeficiencyLog();

  // Active Deficiencies to Display
  const [activeDeficiencies, setActiveDeficiencies] = useState<EquipmentDeficiencyWithChildren[]>(
    [],
  );

  const getActiveDeficiencies = async () => {
    if (shiftPicker.currentShiftId === null) setActiveDeficiencies([]);

    try {
      setActiveDeficiencies([...(await listNonActiveEquipmentDeficienciesThisShift())]);
    } catch (error) {
      console.log(
        '🚀 ~ file: OtherDeficienciesCard.tsx ~ line 88 ~ getActiveDeficiencies ~ error',
        error,
      );
    }
  };

  const subscribeToDeficiencyChanges = async () => {
    if (equipmentDeficiencyCollection) {
      equipmentDeficiencyCollection.$.subscribe(() => {
        getActiveDeficiencies();
      });
    }
  };

  const subscribeToDeficiencyLogChanges = async () => {
    if (equipmentDeficiencyLogCollection) {
      equipmentDeficiencyLogCollection.$.subscribe(() => {
        getActiveDeficiencies();
      });
    }
  };

  useEffect(() => {
    if (deficiencyCollectionsInitialized) {
      getActiveDeficiencies();
    }

    subscribeToDeficiencyChanges();
    subscribeToDeficiencyLogChanges();
  }, [
    deficiencyCollectionsInitialized,
    equipmentDeficiencyCollection,
    deficiencyLogInitialized,
    shiftPicker.currentShiftId,
  ]);

  const [expandedId, setExpandedId] = useState<string>();

  const onExpandedPanel = (expanded: boolean, deficiencyId: string) => {
    if (expanded) setExpandedId(deficiencyId);
    else setExpandedId(undefined);
  };

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <Typography variant="h3">{i18n.t('Other deficiencies this shift')}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.cardContainer}>
            {activeDeficiencies.map((doc, index) => {
              return (
                <Grid item xs={12} key={`grid-${doc.deficiency.id}`}>
                  <DeficiencyExpansionPanel
                    deficiency={doc.deficiency}
                    equipment={doc.equipment}
                    equipmentType={doc.equipmentType}
                    logs={doc.logs}
                    employeeList={employeesList}
                    index={index}
                    expandedDeficiencyId={expandedId}
                    onExpanded={onExpandedPanel}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default observer(OtherDeficienciesCard);
