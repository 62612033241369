import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { MMM_D_YYYY_2_TIME_FORMAT } from '@/utilities/hooks/useDateFormatters';

// Define your row shape
export interface PanelLogRow {
  name: string;
  log: string;
  panelDescription: string;
  createdOn: string;
  modifiedOn: string;
  updatedAt: number;
  panelId: string;
}

const columnHelper = createColumnHelper<PanelLogRow>();

export const defaultColumns = [
  columnHelper.accessor('panelDescription', {
    id: 'panelDescription',
    cell: info => info?.getValue() || null,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    cell: info => info?.getValue() || null,
  }),
  columnHelper.accessor('updatedAt', {
    id: 'updatedAt',
    cell: info => dayjs(info?.getValue()).format(MMM_D_YYYY_2_TIME_FORMAT) || null,
  }),
  columnHelper.accessor('log', {
    id: 'log',
    cell: info => info?.getValue() || null,
  }),
];
