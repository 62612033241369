import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface MiningPattern extends BaseEntity {
  readonly description: string;
  readonly miningMethod: string;
}
export type MiningPatternCollection = RxCollection<MiningPattern> | null;
export type MiningPatternDocument = RxDocument<MiningPattern>;

export const miningPatternSchema: RxJsonSchema<MiningPattern> = {
  type: 'object',
  version: 1,
  description: 'describes a MiningPattern object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    miningMethod: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const miningPatternMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: MiningPatternDocument) => {
    return {
      ...oldDoc,
      miningMethod: null,
    };
  },
};

const miningPatternFeedQuery = print(gql`
  query miningPatternFeed($siteId: ID!, $limit: Int!, $lastUpdateAt: Float!) {
    miningPatternFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      description
      miningMethod
      isDeleted
      updatedAt
      version
    }
  }
`);

export const miningPatternPullQueryBuilder = generatePullQuery(
  miningPatternFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export default {};
