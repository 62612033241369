import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import {
  translate,
  usePermissions,
  UserPermissionTypes,
  useSiteFeatures,
} from '@nutrien/minesight-utility-module';
import React, { useMemo } from 'react';

import { useAdvanceIdArray } from '@/rxdb/Advance/useAdvanceIdArray';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { useEquipmentById } from '@/rxdb/Equipment/useEquipmentById';
import { AugmentedProduction } from '@/rxdb/Productions/useProduction';
import { useSite } from '@/rxdb/Site/useSite';
import { MiningMethodAllCap } from '@/utilities';
import { unknown } from '@/utilities/constants';
import { useViewingShiftOpenForEdit } from '@/utilities/hooks/useViewingShiftOpenForEdit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
      width: '100%',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
    chevron: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: {
      margin: '4px',
    },
    editBtnContainer: {
      '& > div': {
        minHeight: 'auto !important',
        width: '15vw',
        marginLeft: 'auto',
      },
      '& > div > span': {
        display: 'none',
      },
    },
    divClass: {
      width: '100%',
    },
  }),
);

interface Props {
  productionDocument: AugmentedProduction;
  onEdit: (production: AugmentedProduction) => void;
}

const ProductionCard: React.FC<Props> = ({ productionDocument, onEdit }: Props) => {
  const classes = useStyles();

  const shiftWritePermissions = usePermissions(UserPermissionTypes.SHIFT_WRITE);

  const userViewingShiftOpenForEdit = useViewingShiftOpenForEdit();

  const { isVanscoy, isLanigan, isCory, isAllan, isRocanville } = useSiteFeatures();
  const { distanceUnitAbbreviation, useMeters } = useSite();
  const { showAllanRehabFields } = useCurrentBorer();
  const { equipment: stamler } = useEquipmentById(
    productionDocument?.production?.stamlerEquipmentId,
  );
  const { equipment: scoop } = useEquipmentById(productionDocument?.production?.scoopEquipmentId);

  const { calculatedFootage, firstStartDistance, lastEndDistance, calculatedNumberOfBuckets } =
    useAdvanceIdArray(productionDocument.advances.map(advance => advance.id));

  const renderTitle = () => {
    return productionDocument.sequence
      ? `${productionDocument.sequence?.description} ${productionDocument.pass?.description}`
      : `${productionDocument.pass?.description}`;
  };

  const miningMethod = useMemo(
    () => productionDocument.miningCut?.miningMethod?.toUpperCase(),
    [productionDocument],
  );

  const panelLabel = useMemo(() => {
    if (miningMethod === MiningMethodAllCap.CHEVRON || isCory || isAllan) {
      return productionDocument.panel?.description || '';
    }
    if (productionDocument.panel?.description && productionDocument.room?.description)
      return `${productionDocument.panel?.description}-${productionDocument.room?.description}`;
    if (productionDocument.room?.description) return productionDocument.room?.description;
    return '';
  }, [isCory, productionDocument, miningMethod, isAllan]);

  const calculatedScoopTonnage = () => {
    if (scoop?.tonnesPerScoop) return Math.round(calculatedNumberOfBuckets * scoop?.tonnesPerScoop);
    return '';
  };

  const totalTonnesString = useMemo(() => {
    if (!productionDocument.miningCut?.density && productionDocument.miningCut?.density !== 0)
      return '';
    const calc = productionDocument.miningCut.density * calculatedFootage;
    if (calc || calc === 0) return `${parseFloat(calc.toString()).toFixed(1)} t`;
    return '';
  }, [productionDocument.miningCut?.density, calculatedFootage]);

  return (
    <Card className={classes.cardRoot} onClick={() => {}} divClass={classes.divClass}>
      {location && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{renderTitle()}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.editBtnContainer}>
            {userViewingShiftOpenForEdit && shiftWritePermissions && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => onEdit(productionDocument)}
                id="edit-advance-btn"
              >
                <Icons.EditFeather color="primary" />
                {`${i18n.t('Edit advance')}`}
              </Button>
            )}
          </Grid>
          {(isVanscoy || isCory || isRocanville || isAllan) && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Panel')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{panelLabel}</Typography>
              </Grid>
            </>
          )}
          {isLanigan && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Room')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {productionDocument.room?.description.includes('PE')
                    ? `${productionDocument.room?.description || ''}${
                        productionDocument.block?.description || ''
                      }${productionDocument.panel?.description || ''}`
                    : `${productionDocument.block?.description || ''}${
                        productionDocument.panel?.description || ''
                      }${productionDocument.room?.description || ''}`}
                </Typography>
              </Grid>
            </>
          )}
          {(miningMethod === MiningMethodAllCap.CHEVRON || isCory || isAllan) && (
            <>
              {productionDocument.surveyPoint?.description && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="body2">{translate('Sequence')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {productionDocument.surveyPoint?.description || unknown}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="body2">
                  {isVanscoy || isCory || isAllan ? translate('Pass') : i18n.t('Step')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{renderTitle()}</Typography>
              </Grid>
            </>
          )}
          {miningMethod === MiningMethodAllCap.LONG_ROOM && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Pass')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{renderTitle()}</Typography>
              </Grid>
            </>
          )}
          {miningMethod === MiningMethodAllCap.LONG_ROOM && !showAllanRehabFields && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Start distance')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {firstStartDistance} {distanceUnitAbbreviation}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('End distance')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {lastEndDistance} {distanceUnitAbbreviation}
                </Typography>
              </Grid>
            </>
          )}
          {miningMethod !== MiningMethodAllCap.LONG_ROOM && !showAllanRehabFields && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {useMeters ? i18n.t('Total meters') : translate('Total footage')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {`${calculatedFootage}`} {distanceUnitAbbreviation}
                </Typography>
              </Grid>
            </>
          )}
          {!showAllanRehabFields && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Tonnes')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{totalTonnesString}</Typography>
              </Grid>
            </>
          )}
          {showAllanRehabFields && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Stamler')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {stamler?.shortName || stamler?.longName || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Scoop/Hauler')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{scoop?.shortName || scoop?.longName || ''}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('No. Buckets')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{calculatedNumberOfBuckets}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Total tonnes')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{calculatedScoopTonnage()}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default ProductionCard;
