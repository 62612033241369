import { Card, i18n, Tab, Tabs, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import useInspectionResults, {
  InspectionResultItem,
} from '@/rxdb/InspectionResults/useInspectionResults';
import { useNotification } from '@/utilities';

import AssignedEquipment from './AssignedEquipment';
import CompleteWOTab from './CompleteWOTab';
import DeficienciesChecksTab from './DeficienciesChecksTab';
import DeficienciesTab from './DeficienciesTab';
import useStyles from './Equipment.styles';

const tabProperties = [
  { label: i18n.t('Deficiencies'), key: 'deficienciesTab' },
  { label: i18n.t('Checks this shift'), key: 'checksTab' },
  { label: i18n.t('Complete WOs'), key: 'woTab' },
  { label: i18n.t('Assigned equipment'), key: 'assignedEq' },
];

const Equipment: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  const [tabIndex, setTabIndex] = useState(0);
  const [equipmentPreOpChecks, setEquipmentPreOpChecks] = useState<InspectionResultItem[]>([]);
  const [equipmentIsLoading, setEquipmentIsLoading] = useState(true);

  const { listPreOpChecksThisShift, inspectionsInitialized, inspectionResultsCollection } =
    useInspectionResults();
  const { errorNotification } = useNotification();

  const onTabChanged = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabIndex(newValue);
  };

  const getPropChecks = async () => {
    setEquipmentIsLoading(true);
    try {
      setEquipmentPreOpChecks(await listPreOpChecksThisShift());
    } catch (error) {
      console.log('🚀 ~ file: PreOpCheckCard.tsx ~ line 65 ~ getPropChecks ~ error', error);
      errorNotification('Unable to load checks this shift.');
    }
    setTimeout(() => {
      setEquipmentIsLoading(false);
    }, 200);
  };

  const subscribeToPreOpChecks = () => {
    if (inspectionResultsCollection) {
      inspectionResultsCollection.$.subscribe(() => {
        getPropChecks();
      });
    }
  };

  useEffect(() => {
    if (inspectionsInitialized) {
      getPropChecks();
      subscribeToPreOpChecks();
    }
  }, [inspectionsInitialized, shiftPicker.Date, shiftPicker.Type, shiftPicker.currentBorerShiftId]);

  const getUIForTab = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <DeficienciesTab />;
      case 1:
        return (
          <DeficienciesChecksTab
            equipmentPreOpChecks={equipmentPreOpChecks}
            equipmentIsLoading={equipmentIsLoading}
          />
        );
      case 2:
        return <CompleteWOTab />;
      case 3:
        return <AssignedEquipment />;
      default:
        return (
          <div>
            <Typography>Unknown Tab</Typography>
          </div>
        );
    }
  }, [tabIndex, equipmentPreOpChecks, equipmentIsLoading]);

  return (
    <div className={classes.root}>
      <Card className={classes.tabCard}>
        <Tabs value={tabIndex} variant="standard" onChange={onTabChanged}>
          {tabProperties.map(tab => (
            <Tab
              label={
                tab.key === 'checksTab'
                  ? `${tab.label} (${equipmentPreOpChecks.length})`
                  : tab.label
              }
              key={tab.key}
            />
          ))}
        </Tabs>
      </Card>
      <div>{getUIForTab}</div>
    </div>
  );
};

export default observer(Equipment);
