import { CircularProgress, Grid } from '@material-ui/core';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useFlags } from '@nutrien/minesight-utility-module';
import { MiningMethod } from '@nutrien/minesight-utility-module/dist/constants/enums';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo, useState } from 'react';

import useBorerDocs from '@/rxdb/BorerDocuments/useBorerDocs';
import { currentDateIsWithin } from '@/utilities/dateHelpers';

import { DocumentUploadDocument, EnrichedDocument } from '../../rxdb/Documents/queryBuilder';
import useDocsForLocation from '../../rxdb/Documents/useDocsForLocation';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import usePrediction from '../../rxdb/Prediction/usePrediction';
import { useNotification } from '../../utilities';
import { getAttachmentFromIndexDB } from '../CacheUpdateHandler/documentUtilities';
import DocumentThumbnail from '../DocumentThumbnail';
import ImagePreviewModal from '../ImagePreviewModal';
import PDFPreviewModal from '../PDFPreviewModal';
import { useStyles } from './BorerShiftDocumentsCard.styles';
import BorerShiftGlobalDocumentsCard from './BorerShiftGlobalDocumentsCard';

const BorerShiftDocumentsCard: FC = () => {
  const classes = useStyles();

  const [pdfWindow, setPdfWindow] = useState<WindowProxy | undefined>();

  const { currentRoom, currentSurveyPoint, currentBorer } = useCurrentBorer();

  const { predictedEndLocation } = usePrediction();

  const { documentsV2, documentsV2Borer, documentsV2Location, documentsV2Global } =
    useFlags().flags;
  const { isCory, isVanscoy, isAllan } = useSiteFeatures();
  const {
    docsForLocation: currentLocationDocs,
    docsForLocationLoaded: docsForCurrentLocationLoaded,
  } = useDocsForLocation(currentRoom?.id, currentSurveyPoint?.description);

  const {
    docsForLocation: predictionEndLocationDocs,
    docsForLocationLoaded: docsForPredictionLoaded,
  } = useDocsForLocation(
    predictedEndLocation?.room?.id,
    predictedEndLocation?.surveyPoint?.description,
  );

  const { borerDocs } = useBorerDocs(currentBorer?.id);

  const { errorNotification } = useNotification();

  const [imagePreviewModalOpen, setImagePreviewModalOpen] = useState(false);
  const [PDFPreviewModalOpen, setPDFPreviewModalOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<EnrichedDocument | undefined>();
  const [selectedDocumentData, setSelectedDocumentData] = useState<string | undefined>();
  const [activeBorerDocs, setActiveBorerDocs] = useState([]);

  const endPredictionString = useMemo(() => {
    let str = '';

    const showRoomDescription = !(
      (isVanscoy &&
        predictedEndLocation?.panel?.miningMethod.toUpperCase() === MiningMethod.CHEVRON) ||
      isCory ||
      isAllan
    );
    if (predictedEndLocation?.block?.description) {
      str += predictedEndLocation.block.description;
    }
    if (predictedEndLocation?.panel?.description) {
      str += predictedEndLocation.panel.description;
    }
    if (predictedEndLocation?.room?.description && showRoomDescription) {
      str += predictedEndLocation?.room?.description;
    }
    if (predictedEndLocation?.surveyPoint?.description) {
      str += predictedEndLocation?.surveyPoint?.description;
    }

    return str;
  }, [predictedEndLocation]);

  const selectDocument = async (document: DocumentUploadDocument, windowProxy?: WindowProxy) => {
    let url = null;
    if (document.isCached && document.fileName) {
      // If we have the doc cached, get it from the cache
      url = await getAttachmentFromIndexDB(document.fileName);
    }

    if (url) setSelectedDocumentData(url);
    else {
      errorNotification(
        'Error opening document. Please try again or check your network connection.',
      );
      return;
    }

    setImagePreviewModalOpen(true);
    setSelectedDocument(document);

    if (windowProxy) {
      setPdfWindow(windowProxy);
      setPDFPreviewModalOpen(true);
    } else {
      setImagePreviewModalOpen(true);
    }
  };

  const unselectDocument = () => {
    setImagePreviewModalOpen(false);
    setPDFPreviewModalOpen(false);
    setSelectedDocumentData(undefined);
    setPdfWindow(undefined);
  };

  const prepareToSelectDocument = (doc: DocumentUploadDocument) => {
    if (doc.fileName?.toLowerCase().includes('.pdf')) {
      const windowProxy = window.open();
      selectDocument(doc, windowProxy || undefined);
    } else {
      selectDocument(doc);
    }
  };

  useEffect(() => {
    setActiveBorerDocs(borerDocs?.filter(x => currentDateIsWithin(x.startDate, x.endDate)));
  }, [borerDocs]);

  const showNoDocumentsText = useMemo(() => {
    const showNoLocationDocuments =
      (documentsV2 && !documentsV2Location) || currentLocationDocs?.length === 0;
    const showNoBorerDocuments = !documentsV2 || !documentsV2Borer || activeBorerDocs?.length === 0;

    return showNoLocationDocuments && showNoBorerDocuments;
  }, [currentLocationDocs, activeBorerDocs, documentsV2, documentsV2Location, documentsV2Borer]);

  const getDocumentCards = () => {
    const cards: JSX.Element[] = [];

    cards.push(
      <Card
        elevation={2}
        className={classes.subCard}
        data-testid={`documents-borer-${currentBorer?.shortName}`}
        key={`documents-borer-${currentBorer?.shortName}`}
      >
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Documents')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {showNoDocumentsText ? (
            <Grid container className={classes.verticalSpacer}>
              <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
            </Grid>
          ) : (
            <>
              {(!documentsV2 || documentsV2Location) && (
                <>
                  {docsForCurrentLocationLoaded && (
                    <>
                      <Grid container className={classes.verticalSpacer}>
                        {currentLocationDocs?.map(document => {
                          return (
                            <DocumentThumbnail
                              document={document}
                              selectDocument={prepareToSelectDocument}
                              key={document.id}
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </>
              )}
              {documentsV2 && documentsV2Borer && (
                <Grid container className={classes.verticalSpacer}>
                  {activeBorerDocs.map(document => {
                    return (
                      <DocumentThumbnail
                        document={document}
                        selectDocument={prepareToSelectDocument}
                        key={document.id}
                      />
                    );
                  })}
                </Grid>
              )}
            </>
          )}
          {!docsForCurrentLocationLoaded && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>,
    );

    // If end prediction has docs & location is different than current borer, show them
    if (
      endPredictionString &&
      (predictedEndLocation?.room?.id !== currentRoom?.id ||
        predictedEndLocation?.surveyPoint?.id !== currentSurveyPoint?.id)
    ) {
      cards.push(
        <Card
          elevation={2}
          className={classes.subCard}
          key="endPredictionDocs"
          data-testid="endPredictionDocs"
        >
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.type}>
                {i18n.t('Documents - {{endPredictionString}}', {
                  endPredictionString,
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-start">
            {docsForPredictionLoaded && (
              <>
                <Grid container className={classes.verticalSpacer}>
                  {predictionEndLocationDocs?.map(document => {
                    return (
                      <DocumentThumbnail
                        document={document}
                        selectDocument={prepareToSelectDocument}
                        key={document.id}
                      />
                    );
                  })}
                  {predictionEndLocationDocs?.length === 0 && (
                    <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
                  )}
                </Grid>
              </>
            )}
            {!docsForPredictionLoaded && <CircularProgress className={classes.spinner} />}
          </Grid>
        </Card>,
      );
    }

    return cards;
  };

  return (
    <>
      {(!documentsV2 || documentsV2Borer || documentsV2Location) && <>{getDocumentCards()}</>}
      {(!documentsV2 || documentsV2Global) && <BorerShiftGlobalDocumentsCard />}
      <ImagePreviewModal
        open={imagePreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
      />
      <PDFPreviewModal
        open={PDFPreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
        pdfWindow={pdfWindow}
      />
    </>
  );
};

export default observer(BorerShiftDocumentsCard);
