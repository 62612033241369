import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { PanelLogRow } from '../../components/pages/ShiftStart/RoomConditionCommentsTable/ColumnDefs';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import { PanelLog, PanelLogCollection } from './queryBuilder';

export const usePanelLog = (borerEquipmentId: string) => {
  const { useDefaultBlock } = useSiteFeatures();

  const panelLogCollection: PanelLogCollection = useRxCollection(RxdbCollectionName.PANEL_LOGS);

  const [panelLogsForBorerEquipmentId, setPanelLogsForBorerEquipmentId] = useState<PanelLogRow[]>(
    [],
  );

  const panelLogsQueryConstructor = useCallback(
    collection =>
      collection?.find({
        selector: {
          borerEquipmentId,
        },
      }),
    [borerEquipmentId],
  );

  const { result: panelLogs, isFetching: panelLogsFetching } = useRxData<PanelLog>(
    RxdbCollectionName.PANEL_LOGS,
    panelLogsQueryConstructor,
  );

  useEffect(() => {
    const augmentPanelLogsWithPanelAndEmployeeName = async () => {
      if (panelLogsFetching) {
        setPanelLogsForBorerEquipmentId([]);
      } else {
        const panelLogDocsPopulated = await Promise.all(
          panelLogs.map(async doc => {
            const employee = await doc.populate('reportedBySiteEmployeeId');
            const panel = await doc.populate('panelId');
            const block = await panel.populate('block');

            return {
              id: doc.id,
              panelDescription: useDefaultBlock
                ? `${panel.description}`
                : `${block.description}${panel.description}`,
              name: `${employee.firstName} ${employee.lastName}`,
              log: doc.log,
              createdOn: doc.createdOn,
              modifiedOn: doc.modifiedOn,
              updatedAt: doc.updatedAt,
              panelId: panel?.id,
            };
          }),
        );
        setPanelLogsForBorerEquipmentId(panelLogDocsPopulated.sort());
      }
    };
    augmentPanelLogsWithPanelAndEmployeeName();
  }, [panelLogs, panelLogsFetching]);

  const createPanelLog = async (log: string, reportedBySiteEmployeeId: string, panelId: string) => {
    const baseEntity = generateBaseEntityWithCreatedOn();

    const doc = {
      ...baseEntity,
      log,
      reportedBySiteEmployeeId,
      panelId,
      borerEquipmentId,
      modifiedOn: baseEntity.createdOn,
    };
    return panelLogCollection?.insert(doc);
  };

  return {
    createPanelLog,
    panelLogsForBorerEquipmentId,
    panelLogsFetching,
  };
};

export default usePanelLog;
