import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    deficiencyContentContainer: {
      marginTop: '16px',
    },
  }),
);

export { useStyles };
export default useStyles;
