import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface CuttingType extends BaseEntity {
  readonly description: string;
  readonly displayOrder: number | null;
}
export type CuttingTypeCollection = RxCollection<CuttingType> | null;
export type CuttingTypeDocument = RxDocument<CuttingType>;

export const cuttingTypeSchema: RxJsonSchema<CuttingType> = {
  type: 'object',
  version: 0,
  description: 'describes a CuttingType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    displayOrder: { type: 'number' },
  },
};

export const cuttingTypeMigrationStrategy: MigrationStrategies = {};

const cuttingTypeFeedQuery = print(gql`
  query cuttingTypeFeed($limit: Int!, $lastUpdateAt: Float!, $siteId: ID!) {
    cuttingTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      description
      displayOrder
      id
      isDeleted
      updatedAt
      version
    }
  }
`);

export const cuttingTypePullQueryBuilder = generatePullQuery(
  cuttingTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export default {};
