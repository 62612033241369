import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { DailySafetyTopicCollection } from './queryBuilder';

export const useDailySafetyTopic = () => {
  const dailySafetyTopicCollection: DailySafetyTopicCollection = useRxCollection(
    RxdbCollectionName.DAILY_SAFETY_TOPIC,
  );

  const [dailySafetyTopicInitialized, setDailySafetyTopicInitialized] = useState(false);

  useEffect(() => {
    if (dailySafetyTopicCollection) setDailySafetyTopicInitialized(true);
  }, [dailySafetyTopicCollection]);

  /**
   * Returns a DailySafetyTopicDocument matching the current shift
   *
   * @param {string} shiftId
   * @return {*}
   */
  const getDailySafetyTopic = async (shiftId: string) => {
    if (!dailySafetyTopicCollection) throw new Error('DailySafetyTopicCollection not initialized');

    try {
      return await dailySafetyTopicCollection
        .findOne({
          selector: {
            shiftId,
          },
        })
        .exec();
    } catch (error) {
      console.log(
        '🚀 ~ file: useDailySafetyTopic.ts ~ line 33 ~ getDailySafetyTopic ~ error',
        error,
      );
      throw error;
    }
  };

  return {
    dailySafetyTopicCollection,
    dailySafetyTopicInitialized,
    getDailySafetyTopic,
  };
};

export default useDailySafetyTopic;
