import { useEffect, useState } from 'react';
import type { RxQuery } from 'rxdb';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { GroundHazardAttachment, GroundHazardAttachmentCollection } from './queryBuilder';

export const useGroundHazardAttachments = () => {
  const groundHazardAttachmentCollection: GroundHazardAttachmentCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
  );

  // Boolean to notify if the collection is not null
  const [groundHazardAttachmentsInitialized, setCollectionsInitialized] = useState(false);

  useEffect(() => {
    if (groundHazardAttachmentCollection) setCollectionsInitialized(true);
  }, [groundHazardAttachmentCollection]);

  /**
   * Subscribe to newly inserted attachments
   *
   * @param {(item: RxChangeEventInsert<GroundHazardAttachment>) => void} next
   * @return {*}
   */
  const subscribeToGroundHazardAttachments = (
    handleAttachments: (attachments: GroundHazardAttachment[]) => void,
    queryObj?: RxQuery<GroundHazardAttachment>,
  ) => {
    if (!groundHazardAttachmentCollection) return;

    const query = groundHazardAttachmentCollection.find(queryObj);

    query.$.subscribe(handleAttachments);
  };

  /**
   * Get a ground hazard attachment document by its id
   *
   * @param {string} groundHazardAttachmentId
   * @return {*}
   */
  const getGroundHazardAttachment = (groundHazardAttachmentId: string) => {
    if (!groundHazardAttachmentCollection) return undefined;

    return groundHazardAttachmentCollection
      .findOne({
        selector: {
          id: groundHazardAttachmentId,
        },
      })
      .exec();
  };

  return {
    groundHazardAttachmentsInitialized,
    groundHazardAttachmentCollection,
    subscribeToGroundHazardAttachments,
    getGroundHazardAttachment,
  };
};

export default useGroundHazardAttachments;
