import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { Button, Grid, useTheme, Zoom } from '@material-ui/core';
import { Card, i18n, Icons, Menu, Typography } from '@nutrien/cxp-components';
import { useFlags, usePermissions, UserPermissionTypes } from '@nutrien/minesight-utility-module';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import { useBorerActivity } from '@/rxdb/BorerActivity/useBorerActivity';
import { markTempStatesAsHiddenForBorerStateId } from '@/rxdb/BorerOperatorChangeFeed/borerOperatorChangeQueries';
import { TempBorerOperatorChangeState } from '@/rxdb/BorerOperatorChangeFeed/queryBuilder';
import { useBorerOperatorChange } from '@/rxdb/BorerOperatorChangeFeed/useBorerOperatorChange';
import { useBorerOperatorState } from '@/rxdb/BorerOperatorStateFeed/useBorerOperatorState';
import { useGeneratedOfflineBlock } from '@/rxdb/BorerOperatorStateFeed/useGeneratedOfflineBlock';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { useOnlineStatus } from '@/utilities';
import { ActivityDelayMapper } from '@/utilities/activityDelayMapper';
import { AppointmentType } from '@/utilities/enums';
import { createDayJsUnix } from '@/utilities/hooks/useDateFormatters';
import { useShiftHours } from '@/utilities/hooks/useShiftHours';
import { useViewingShiftOpenForEdit } from '@/utilities/hooks/useViewingShiftOpenForEdit';
import { translate } from '@/utilities/siteLanguageTranslation/siteLanguageTranslation';

import AddBorerActivitySidePanel from '../../AddBorerActivitySidePanel';
import DelaysOfflineBar from '../../DelaysOfflineBar';
import DelaysEditDrawer from './DelaysEditDrawer';
import { DelayMultiEditInput, DelaysMultiEditDrawer } from './DelaysMultiEditDrawer';
import DelaysSchedulerView from './DelaysSchedulerView';
import { IAppointment } from './DelaysSchedulerView/ActivitiesSchedulerHelpers';
import DelaysSchedulerViewMultiEdit from './DelaysSchedulerViewMultiEdit';
import useStyles from './PiSightPage.styles';

interface Props {
  lastSyncTime?: number;
}

const PiSightPage: React.FC<Props> = ({ lastSyncTime }: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const {
    borerOutageWarningBanner,
    msBorerDataSync,
    borerOutageBanner,
    displayrxdbsyncindicator,
    borerDelaysMultiEditMode,
  } = useFlags().flags;

  const isBorerDelaysMultiEditMode = useMemo(() => {
    return borerDelaysMultiEditMode;
  }, [borerDelaysMultiEditMode]);

  const isBannerShowing = useMemo(() => {
    return (
      borerOutageWarningBanner?.display ||
      msBorerDataSync?.display ||
      borerOutageBanner?.display ||
      displayrxdbsyncindicator?.display
    );
  }, [borerOutageWarningBanner, msBorerDataSync, borerOutageBanner, displayrxdbsyncindicator]);

  const theme = useTheme();
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentModel | null>(null);
  const [editingOngoingAppointment, setEditingOngoingAppointment] = useState(false);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const { currentShiftBorerOperatorStates, getStatesForParentBorerStateId } =
    useBorerOperatorState();
  const { tempAugmentedStates, getTempStatesForParentBorerStateId } = useBorerOperatorChange();

  const isOnline = useOnlineStatus();
  const fabPosition =
    borerOutageWarningBanner?.display && !isOnline
      ? '182px'
      : borerOutageWarningBanner?.display || !isOnline
      ? '122px'
      : '75px';
  const adminPermissions = usePermissions(UserPermissionTypes.ADMIN_SITE_CONFIGURATION_WRITE);

  const { generatedBlock } = useGeneratedOfflineBlock(
    isOnline,
    shiftPicker.isCurrentShiftSelected(),
    [...tempAugmentedStates, ...currentShiftBorerOperatorStates],
    lastSyncTime,
  );

  const { augmentedActivitiesForShift } = useBorerActivity();
  const userViewingShiftOpenForEdit = useViewingShiftOpenForEdit();
  const { shiftId } = useShiftHours();

  const [loading, setLoading] = useState(false);
  const [failedToSyncMenuOpen, setFailedSyncMenuOpen] = useState(false);

  const [addActivityOpen, setAddActivityOpen] = useState<boolean>(false);

  const defaultMultiEditFormValues = {
    type: null,
    comment: '',
  };

  const [editDelayDrawerOpen, setEditDelayDrawerOpen] = useState(false);
  const [multiEditDrawerOpen, setMultiEditDrawerOpen] = useState(false);
  const [initialMultiEditFormValues, setInitialMultiEditFormValues] = useState<DelayMultiEditInput>(
    defaultMultiEditFormValues,
  );
  const [initalBorerStates, setInitialBorerStates] = useState<TempBorerOperatorChangeState[]>();

  const { isRehabBorer } = useCurrentBorer();

  const [borerDelaysMultiEdit, setBorerDelaysMultiEdit] = useState(false);
  const [selectedDelays, setSelectedDelays] = useState<AppointmentModel[]>([]);
  const [isSavingMultiEdit, setIsSavingMultiEdit] = useState(false);

  const addSelectedDelay = useCallback(
    (delay: AppointmentModel) => {
      setSelectedDelays([...selectedDelays, delay]);
    },
    [selectedDelays],
  );

  const removeSelectedDelay = useCallback(
    (delay: AppointmentModel) => {
      setSelectedDelays(selectedDelays.filter(d => d.id !== delay.id));
    },
    [selectedDelays],
  );

  const editSeletedDelays = useCallback(() => {
    setMultiEditDrawerOpen(true);
  }, []);

  const clearSelectedDelays = useCallback(() => {
    setSelectedDelays([]);
    setBorerDelaysMultiEdit(false);
  }, []);

  const refreshMultiSelectView = useCallback(() => {
    clearSelectedDelays();
    setMultiEditDrawerOpen(false);
    setInitialMultiEditFormValues(defaultMultiEditFormValues);
  }, []);

  const toggleBorerDelaysMultiEdit = useCallback(() => {
    setSelectedDelays([]);
    setBorerDelaysMultiEdit(prev => !prev);
  }, []);

  const handleMultiEditSaving = useCallback((isSaving: boolean) => {
    setIsSavingMultiEdit(isSaving);
  }, []);

  const onCloseBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(false);
  }, []);

  const onCloseMultiEditDrawer = useCallback(() => {
    setMultiEditDrawerOpen(false);
  }, []);

  const onOpenMultiEditDrawer = useCallback(() => {
    setMultiEditDrawerOpen(true);
  }, []);

  const onCancelBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(false);
  }, []);

  const onOpenBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(true);
  }, []);

  const onOpenActivitySidePanel = useCallback(() => {
    setAddActivityOpen(true);
  }, []);

  const onCloseOrCancelActivitySidePanel = useCallback(() => {
    setAddActivityOpen(false);
  }, []);

  const handleCloseFailedToSyncMenu = useCallback(() => {
    setFailedSyncMenuOpen(false);
    setSelectedAppointment(null);
  }, []);

  const onScheduleItemClicked = useCallback(
    async (
      appointment?: IAppointment | AppointmentModel | null,
      forceEditDelay = false,
      clickEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
      isMultiEdit = false,
    ) => {
      if (!appointment) return;
      // Only admins can code past shifts
      setSelectedAppointment({ ...appointment });

      if (appointment.failedSync && !forceEditDelay) {
        setCursorX(clickEvent?.clientX || 0);
        setCursorY(clickEvent?.clientY || 0);
        setFailedSyncMenuOpen(true);
        return;
      }

      setEditingOngoingAppointment(false);

      if (appointment.typeId === AppointmentType.DELAY) {
        // Borer State
        const [existingStates, existingTempStates] = await Promise.all([
          getStatesForParentBorerStateId(appointment?.borerOperatorStateId),
          getTempStatesForParentBorerStateId(appointment?.borerOperatorStateId),
        ]);

        let initialStates = [...existingStates, ...existingTempStates];

        if (appointment.isGeneratedState && appointment.borerStateTypeId) {
          // When selecting generated block (or temp block that was previously generated)
          initialStates.push(appointment);
        } else if (
          generatedBlock?.[0]?.borerOperatorStateId === appointment?.borerOperatorStateId
        ) {
          // When selecting item with same borerStateId as generated block
          initialStates = [...initialStates, ...generatedBlock];
        }

        const augmentedStates = await Promise.all(
          initialStates
            ?.sort((a, b) => a.startTimeUnix - b.startTimeUnix)
            .map(async (state, index) => {
              let endTime = state.endTimeUnix;
              if (!endTime) setEditingOngoingAppointment(true);

              if (!endTime && state.isRunning === true) {
                if (index === initialStates.length - 1) {
                  // Last running block - Temp state
                  endTime = dayjs(lastSyncTime).unix();
                } else {
                  // Use generated block end time
                  endTime = initialStates[index + 1].startTimeUnix;
                }
              } else if (!endTime && state.populate) {
                // Last running block - borerOperatorState
                const stateType = await state.populate('borerStateTypeId');
                if (stateType?.isRunning === true) endTime = dayjs(lastSyncTime).unix();
              }
              if (!endTime) {
                // For most recent delay (generated block included)
                endTime = dayjs().unix();
              }

              return {
                startTime: createDayJsUnix(state.startTimeUnix),
                endTime: createDayJsUnix(endTime),
                borerStateTypeId: state.borerStateTypeId,
                comment: state.comment || '',
                existingTempStateId: state.existingTempStateId,
                borerShiftAdvanceId: state.borerShiftAdvanceId || '',
                failedSync: state.failedSync,
                isGeneratedState: state.isGeneratedState,
                isTempState: state.isTempState,
                cuttingTypeId: state.cuttingTypeId,
                cuttingMethodId: state.cuttingMethodId,
                // Since RHF over-writes id
                originalId: state.id,
                id: state.id,
              };
            }) || [],
        );

        setInitialBorerStates(augmentedStates);

        if (!isMultiEdit) {
          setEditDelayDrawerOpen(true);
        }
      } else if (appointment.typeId === AppointmentType.ACTIVITY) {
        // Activity
        setAddActivityOpen(true);
      }
    },
    [
      userViewingShiftOpenForEdit,
      adminPermissions,
      getTempStatesForParentBorerStateId,
      getStatesForParentBorerStateId,
      lastSyncTime,
      generatedBlock,
    ],
  );

  const matchingBorerState = useMemo(() => {
    return tempAugmentedStates.find(bState =>
      currentShiftBorerOperatorStates.find(
        tempState =>
          !tempState.failedSync && tempState.borerOperatorStateId === bState.borerOperatorStateId,
      ),
    );
  }, [currentShiftBorerOperatorStates, tempAugmentedStates]);

  useEffect(() => {
    // Escape hatch for when handleOperatorStateFeedEvents misses marking temp state as hidden
    let timeout;
    if (matchingBorerState) {
      timeout = setTimeout(() => {
        markTempStatesAsHiddenForBorerStateId(matchingBorerState.borerOperatorStateId);
      }, 2000);
      // Added clearSelectedDelays to prevent issue where selected delays count is not reset when syncing happens
      clearSelectedDelays();
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [matchingBorerState]);

  useEffect(() => {
    clearSelectedDelays();
  }, [shiftId]);

  const schedulerData = useMemo((): AppointmentModel[] => {
    // If both operator state and temp states have the same borerStateId
    // We are waiting for the temp states to be marked as hidden
    // (This prevents render glitch where comments won't show)

    if (matchingBorerState) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // Attach on-click listener to delay and activity data
    const mappedDelays = [
      ...tempAugmentedStates,
      ...currentShiftBorerOperatorStates,
      ...generatedBlock,
    ].map(state => {
      return {
        ...state,
        onClick: onScheduleItemClicked,
      };
    });

    const mappedActivities = augmentedActivitiesForShift.map(x =>
      ActivityDelayMapper.MapActivityDocumentToAppointment(x, onScheduleItemClicked),
    );
    return [...mappedDelays, ...mappedActivities] as AppointmentModel[];
  }, [
    currentShiftBorerOperatorStates,
    augmentedActivitiesForShift,
    tempAugmentedStates,
    onScheduleItemClicked,
    generatedBlock,
    matchingBorerState,
  ]);

  const schedulerDataMultiEditMode = useMemo((): AppointmentModel[] => {
    // Filter out items from currentShiftBorerOperatorStates that match tempAugmentedStates by borerOperatorStateId, startTimeUnix and endTimeUnix.
    // This prevents render glitch where there may be the old and the new state showing at the same time
    const filteredShiftBorerOperatorStates = currentShiftBorerOperatorStates.filter(shiftState => {
      return !tempAugmentedStates.some(
        tempState => tempState.borerOperatorStateId === shiftState.borerOperatorStateId,
      );
    });

    // Attach on-click listener to delay and activity data
    const mappedDelays = [
      ...tempAugmentedStates,
      ...filteredShiftBorerOperatorStates,
      ...generatedBlock,
    ].map(state => {
      return {
        ...state,
        onClick: onScheduleItemClicked,
      };
    });

    const mappedActivities = augmentedActivitiesForShift.map(x =>
      ActivityDelayMapper.MapActivityDocumentToAppointment(x, onScheduleItemClicked),
    );
    return [...mappedDelays, ...mappedActivities] as AppointmentModel[];
  }, [
    currentShiftBorerOperatorStates,
    augmentedActivitiesForShift,
    tempAugmentedStates,
    onScheduleItemClicked,
    generatedBlock,
  ]);

  // should be read only if the shift being viewed is not open for edit
  const readOnly = !userViewingShiftOpenForEdit;

  const addShiftActivityClicked = () => {
    setSelectedAppointment(null);
    onOpenActivitySidePanel();
  };

  const showDelayActionButtons = useMemo(() => {
    return userViewingShiftOpenForEdit || adminPermissions;
  }, [userViewingShiftOpenForEdit, adminPermissions]);

  const multiEditSchedulerData = schedulerDataMultiEditMode.filter(
    x =>
      x.typeId === AppointmentType.DELAY &&
      (x.isGeneratedState === false || x.isGeneratedState === undefined),
  );

  return (
    <Grid container className={isBannerShowing ? classes.bannerEnabled : classes.root}>
      {!isOnline && userViewingShiftOpenForEdit && (
        <Grid item xs={12}>
          <DelaysOfflineBar lastSyncTime={lastSyncTime} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          item
          container
          xs={12}
          className={classes.titleContainer}
          alignItems="center"
          justify="space-between"
        >
          {isBorerDelaysMultiEditMode && showDelayActionButtons && (
            <>
              <Grid item>
                <Typography variant="h3" color="textSecondary" className={classes.title}>
                  <>{i18n.t('Delays')}</>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end" alignItems="center" direction="row">
                  {borerDelaysMultiEdit && selectedDelays.length !== 0 && (
                    <>
                      <Grid item className={classes.textRightPad}>
                        <Typography data-testid="selected-delays-count">
                          {selectedDelays.length} Selected
                        </Typography>
                      </Grid>
                      <Grid item className={classes.buttonRightPad}>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.buttonPadding}
                          startIcon={<Icons.EditFeather />}
                          onClick={() => editSeletedDelays()}
                          noMinHeight
                          data-testid="edit-selected-button"
                        >
                          Edit Selected
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Button
                      color="primary"
                      variant="text"
                      className={borerDelaysMultiEdit ? classes.buttonLight : classes.buttonPadding}
                      startIcon={<Icons.ListFeather color="primary" />}
                      onClick={() => toggleBorerDelaysMultiEdit()}
                      data-testid="select-multiple-button"
                    >
                      Select Multiple
                    </Button>
                  </Grid>
                  <Grid item className={classes.verticalRule}>
                    <Zoom
                      key="primary"
                      in={!addActivityOpen}
                      timeout={{
                        enter: theme.transitions.duration.enteringScreen,
                        exit: 100,
                      }}
                      style={{
                        transitionDelay: `${200}ms`,
                      }}
                      unmountOnExit
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.buttonPadding}
                        startIcon={<Icons.PlusFeather />}
                        onClick={addShiftActivityClicked}
                        data-testid="addShiftActivityFab"
                        data-testcy="addShiftActivityFab"
                      >
                        Add {translate('shift activity')}
                      </Button>
                    </Zoom>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {isBorerDelaysMultiEditMode ? (
          <Card elevation={1} className={classes.card}>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                {shiftPicker.isDayShift() ? (
                  <Grid item xs={12} className={classes.topMargin}>
                    <DelaysSchedulerViewMultiEdit
                      schedulerData={schedulerDataMultiEditMode}
                      saving={isSavingMultiEdit}
                      borerDelaysMultiEdit={borerDelaysMultiEdit}
                      selectedDelays={selectedDelays}
                      addSelectedDelay={addSelectedDelay}
                      removeSelectedDelay={removeSelectedDelay}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.nightShiftScheduleContainer}>
                    <DelaysSchedulerViewMultiEdit
                      schedulerData={schedulerDataMultiEditMode}
                      isNightShift
                      saving={isSavingMultiEdit}
                      borerDelaysMultiEdit={borerDelaysMultiEdit}
                      selectedDelays={selectedDelays}
                      addSelectedDelay={addSelectedDelay}
                      removeSelectedDelay={removeSelectedDelay}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Card elevation={1} className={classes.card}>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                {shiftPicker.isDayShift() ? (
                  <Grid item xs={12} className={classes.topMargin}>
                    <DelaysSchedulerView schedulerData={schedulerData} loading={loading} />
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.nightShiftScheduleContainer}>
                    <DelaysSchedulerView
                      schedulerData={schedulerData}
                      isNightShift
                      loading={loading}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
      {!isBorerDelaysMultiEditMode && showDelayActionButtons && (
        <Zoom
          key="primary"
          in={!addActivityOpen}
          timeout={{
            enter: theme.transitions.duration.enteringScreen,
            exit: 100,
          }}
          style={{
            transitionDelay: `${200}ms`,
          }}
          unmountOnExit
        >
          <Button
            aria-label="add-activity"
            data-testid="add-activity-button"
            className={classes.fab}
            variant="contained"
            color="primary"
            style={{ top: `${fabPosition}` }}
            onClick={addShiftActivityClicked}
            id="add-delay"
            startIcon={<Icons.PlusFeather />}
          >
            Add {translate('shift activity')}
          </Button>
        </Zoom>
      )}
      <DelaysEditDrawer
        selectedAppointment={selectedAppointment}
        open={editDelayDrawerOpen}
        onClose={onCloseBorerDelaySidePanel}
        onCancel={onCancelBorerDelaySidePanel}
        onOpen={onOpenBorerDelaySidePanel}
        initialValues={initalBorerStates}
        isOnlineWhenDrawerOpened={isOnline}
        lastSyncTime={lastSyncTime}
        editingOngoingAppointment={editingOngoingAppointment}
        shiftEndDateUnix={shiftPicker.shiftEndDateUnix}
        allowCodingAsRunning={isRehabBorer || adminPermissions}
      />
      <DelaysMultiEditDrawer
        open={multiEditDrawerOpen}
        onClose={onCloseMultiEditDrawer}
        onOpen={onOpenMultiEditDrawer}
        onSuccess={refreshMultiSelectView}
        onSaving={handleMultiEditSaving}
        initialValues={initialMultiEditFormValues}
        selectedDelays={selectedDelays}
        allDelays={multiEditSchedulerData}
        editingOngoingAppointment={editingOngoingAppointment}
      />
      <AddBorerActivitySidePanel
        open={addActivityOpen}
        onClose={onCloseOrCancelActivitySidePanel}
        onCancel={onCloseOrCancelActivitySidePanel}
        onOpen={onOpenActivitySidePanel}
        prevAppointment={selectedAppointment}
        readOnly={readOnly}
      />
      <Menu
        id="failed-to-sync-menu"
        open={failedToSyncMenuOpen}
        onClose={handleCloseFailedToSyncMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: cursorY, left: cursorX }}
        data={[
          {
            Rows: [
              {
                Title: i18n.t('Edit delay'),
                MenuHandler: () => {
                  setFailedSyncMenuOpen(false);
                  onScheduleItemClicked(selectedAppointment, true, undefined, false);
                },
              },
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default observer(PiSightPage);
