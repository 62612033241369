import { ApolloQueryResult } from '@apollo/client';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { client } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import MigrationHelper from '../MigrationHelper';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface BorerStateType extends BaseEntity {
  readonly description: string;
  readonly isRunning: boolean;
  readonly isDefault: boolean;
  readonly isActive: boolean;
  readonly borerStateTypeCategoryId: string | null;
  readonly timeUsageTypeId: string | null;
  readonly isPlanned: boolean | null;
  readonly equipmentTypeId: string | null;
  readonly siteId: string;
}
export type BorerStateTypeCollection = RxCollection<BorerStateType> | null;
export type BorerStateTypeDocument = RxDocument<BorerStateType>;

export const borerStateTypeSchema: RxJsonSchema<BorerStateType> = {
  type: 'object',
  version: 8,
  description: 'describes a BorerStateType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    isRunning: { type: 'boolean' },
    isDefault: { type: ['boolean', 'null'] },
    isActive: { type: ['boolean', 'null'] },
    borerStateTypeCategoryId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.BORER_STATE_TYPE_CATEGORY,
    },
    timeUsageTypeId: {
      type: ['string', 'null'],
    },
    isPlanned: { type: ['boolean', 'null'] },
    equipmentTypeId: { type: 'string' },
    siteId: { type: 'string' },
  },
};

export const borerStateTypeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerStateTypeDocument) => ({
    ...oldDoc,
    rtDuetReasonCodeId: '',
  }),
  2: (oldDoc: BorerStateTypeDocument) => ({
    ...oldDoc,
    isDefault: oldDoc.isDefault || null,
  }),
  3: (oldDoc: BorerStateTypeDocument) => {
    const newDoc = {
      ...oldDoc,
    };
    delete newDoc.rtDuetReasonCodeId;
    return newDoc;
  },
  4: (oldDoc: BorerStateTypeDocument) => oldDoc,
  5: (oldDoc: BorerStateTypeDocument) => oldDoc,
  6: (oldDoc: BorerStateTypeDocument) => ({ ...oldDoc, isDefault: oldDoc.isDefaultNonRunning }),
  7: (oldDoc: BorerStateTypeDocument) => {
    return MigrationHelper.instance.timeUsageTypeIdForStateTypeId(oldDoc.id).then(response => {
      const { timeUsageTypeId, isPlanned } = response;
      return { ...oldDoc, timeUsageTypeId, isPlanned };
    });
  },
  8: (oldDoc: BorerStateTypeDocument) => {
    return MigrationHelper.instance.equipmentTypeIdForStateTypeId(oldDoc.id).then(response => {
      const { equipmentTypeId, siteId } = response;
      if (equipmentTypeId && siteId) {
        return { ...oldDoc, equipmentTypeId, siteId };
      }
      // If the equipmentTypeId or siteId is null, that means the oldDoc.id is not in new
      // listBorerStateTypes query that includes equipmentTypeId as input param.
      // If we don't have the equipmentTypeId or siteId, we don't need to migrate this document
      return null;
    });
  },
};

const borerStateTypeFeedQuery = print(gql`
  query borerStateTypeFeed($limit: Int!, $lastUpdateAt: Float!, $siteId: ID!) {
    borerStateTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      updatedAt
      version
      isDeleted
      description
      isRunning
      isDefault
      borerStateTypeCategoryId
      isActive
      timeUsageTypeId
      isPlanned
      equipmentTypeId
      siteId
    }
  }
`);

export const borerStateTypePullQueryBuilder = generatePullQuery(
  borerStateTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

const listBorerStateTypesQuery = gql`
  query listBorerStateTypes($siteId: ID!, $equipmentTypeId: ID!) {
    listBorerStateTypes(siteId: $siteId, equipmentTypeId: $equipmentTypeId, includeInactive: true) {
      id
      description
      isActive
      isRunning
      borerStateTypeCategory {
        id
        description
      }
      timeUsageType {
        description
        id
      }
      isPlanned
      siteId
      equipmentType {
        id
        description
      }
    }
  }
`;

export interface BstFromListBorerStateTypeQuery extends Omit<BorerStateType, 'equipmentTypeId'> {
  equipmentType: {
    id: string;
    description: string;
  };
}

interface ListBorerStateTypesResponse {
  listBorerStateTypes: BstFromListBorerStateTypeQuery[];
}

export const listBorerStateTypes = (
  siteId: string,
  equipmentTypeId: string,
): Promise<ApolloQueryResult<ListBorerStateTypesResponse>> =>
  client.query({
    query: listBorerStateTypesQuery,
    variables: { siteId, equipmentTypeId },
    fetchPolicy: 'cache-first',
  });
