import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface AssignmentRole extends BaseEntity {
  readonly description: string;
}
export type AssignmentRoleCollection = RxCollection<AssignmentRole> | null;
export type AssignmentRoleDocument = RxDocument<AssignmentRole>;

export const assignmentRoleSchema: RxJsonSchema<AssignmentRole> = {
  type: 'object',
  version: 0,
  description: 'describes an assignment role object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    description: { type: 'string' },
  },
};

const assignmentRoleFeedQuery = print(gql`
  query assignmentRoleFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    assignmentRoleFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      description
      id
      isDeleted
      updatedAt
      version
    }
  }
`);

export const assignmentRolePullQueryBuilder = generatePullQuery(
  assignmentRoleFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
