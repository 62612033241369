import { Dayjs } from 'dayjs';

import { ShiftType } from './enums';

export const correctDateForShift = (
  dateTime: Dayjs,
  shiftStartDate: Dayjs,
  shiftEndDateTime: Dayjs,
  shiftType: ShiftType, // DS or NS
): Dayjs => {
  // if its NS
  if (shiftType === ShiftType.NIGHT_SHIFT) {
    // if before between shiftStart time and 12AM
    if (
      dateTime.format('HH:mm') >= shiftStartDate.format('HH:mm') &&
      dateTime.format('HH:mm') <= '23:59'
    ) {
      return dateTime
        .clone()
        .set('year', shiftStartDate.year())
        .set('month', shiftStartDate.month())
        .set('date', shiftStartDate.date());
    }

    // if after 12AM and before shiftEnd time
    if (
      dateTime.format('HH:mm') >= '00:00' &&
      dateTime.format('HH:mm') <= shiftEndDateTime.format('HH:mm')
    ) {
      return dateTime
        .clone()
        .set('year', shiftEndDateTime.year())
        .set('month', shiftEndDateTime.month())
        .set('date', shiftEndDateTime.date());
    }
  }

  // if its DS
  return dateTime
    .clone()
    .set('year', shiftStartDate.year())
    .set('month', shiftStartDate.month())
    .set('date', shiftStartDate.date());
};
