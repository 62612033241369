import { useCallback, useEffect, useState } from 'react';
import { RxCollection } from 'rxdb';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { MiningMethodAllCap } from '../../utilities';
import { sortAscendingByCreatedOn } from '../../utilities/sortHelper';
import { Advance, AdvanceCollection, AdvanceDocument } from '../Advance/queryBuilder';
import { useAdvancesForShift } from '../Advance/useAdvancesForShift';
import { BlockCollection, BlockDocument } from '../Blocks/queryBuilder';
import { LocationDocument } from '../Locations/queryBuilder';
import { MiningCutDocument, MiningCutSequencePassObj } from '../MiningCut/queryBuilder';
import useMiningCuts from '../MiningCut/useMiningCuts';
import { PanelDocument } from '../Panels/queryBuilder';
import { PassDocument } from '../Passes/queryBuilder';
import { Room, RoomDocument } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../rxdbUtilityFunctions';
import { SequenceDocument } from '../Sequences/queryBuilder';
import { SurveyPointDocument } from '../SurveyPoints/queryBuilder';
import { ProductionCollection, ProductionDocument } from './queryBuilder';

export interface AugmentedProduction {
  block?: BlockDocument;
  panel?: PanelDocument;
  room?: RoomDocument;
  surveyPoint?: SurveyPointDocument;
  sequence?: SequenceDocument;
  pass?: PassDocument;
  production: ProductionDocument;
  advances: AdvanceDocument[];
  miningCut?: MiningCutDocument;
  location: LocationDocument;
}

export interface ProductionLocationInfo {
  surveyPoint: SurveyPointDocument | undefined;
  miningCutSequencePassObj: MiningCutSequencePassObj | undefined;
  room: RoomDocument | undefined;
  panel: PanelDocument | undefined;
  block: BlockDocument | undefined | null;
  sequence: SequenceDocument | undefined;
  pass: PassDocument | undefined;
  mostRecentFootage: string | number | undefined | null;
}

export const useProduction = (borerShiftId?: string | null) => {
  const { shiftPicker } = useMst();
  const { advancesForBorer } = useAdvancesForShift();

  const [advancesInitialized, setAdvancesInitialized] = useState(false);
  const [productionInitialized, setProductionInitialized] = useState(false);
  const [productionForShift, setProductionForShift] = useState<AugmentedProduction[]>([]);
  const productionCollection: ProductionCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_PRODUCTION,
  );

  const productionAllDocsQueryConstructor = useCallback(
    (collection: RxCollection) => collection.find().sort({ updatedAt: 'desc' }),
    [],
  );

  const { result: productionDocsAll } = useRxData<ProductionDocument>(
    RxdbCollectionName.BORER_SHIFT_PRODUCTION,
    productionAllDocsQueryConstructor,
  );

  const advancesCollection: AdvanceCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
  );
  const blockCollection: BlockCollection = useRxCollection(RxdbCollectionName.BLOCKS);

  const [mostRecentProductionLocationInformation, setMostRecentProductionLocationInformation] =
    useState<ProductionLocationInfo>({
      surveyPoint: undefined,
      miningCutSequencePassObj: undefined,
      room: undefined,
      panel: undefined,
      block: undefined,
      sequence: undefined,
      pass: undefined,
      mostRecentFootage: undefined,
    });

  const { getMiningCutById } = useMiningCuts();

  const productionQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerShiftId,
        },
      }),
    [borerShiftId],
  );
  const { result: productionDocsForShift } = useRxData<ProductionDocument>(
    RxdbCollectionName.BORER_SHIFT_PRODUCTION,
    productionQueryConstructor,
  );

  // Ensures production augmentation process runs when location collection is updated
  const locationsQueryConstructor = useCallback(
    (collection: RxCollection) =>
      collection.find({
        selector: {
          id: {
            $in: productionDocsForShift.map(production => production.locationId),
          },
        },
      }),
    [productionDocsForShift],
  );

  const { result: locationsForProduction } = useRxData<LocationDocument>(
    RxdbCollectionName.LOCATIONS,
    locationsQueryConstructor,
  );

  useEffect(() => {
    if (productionCollection) setProductionInitialized(true);
  }, [productionCollection]);

  useEffect(() => {
    if (advancesCollection) setAdvancesInitialized(true);
  }, [advancesCollection]);

  useEffect(() => {
    (async function () {
      if (productionDocsForShift?.length) {
        const augmentedProduction: AugmentedProduction[] = await Promise.all(
          productionDocsForShift.map(async production => {
            const advances =
              (await RxdbManager?.instance.db?.collections[RxdbCollectionName.BORER_SHIFT_ADVANCE]
                ?.find({
                  selector: {
                    borerShiftProductionId: production.id,
                  },
                })
                .exec()) || [];

            const [location, miningCut] = await Promise.all([
              production.populate('locationId'),
              production.populate('miningCutId'),
            ]);

            // populate location, sequence, pass, room, survey point, panel, block
            let sequence: SequenceDocument | undefined;
            let pass: PassDocument | undefined;
            if (miningCut) {
              [sequence, pass] = await Promise.all([
                miningCut.populate('sequence'),
                miningCut.populate('pass'),
              ]);
            }

            let room: RoomDocument | undefined;
            let surveyPoint: SurveyPointDocument | undefined;
            let panel: PanelDocument | undefined;
            let block: BlockDocument | undefined;

            if (location) {
              [room, surveyPoint, panel] = await Promise.all([
                location.populate('room'),
                location.populate('surveyPoint'),
                location.populate('panel'),
              ]);
            }
            if (panel) {
              block = await panel.populate('block');
            }

            return {
              miningCut,
              production,
              advances,
              sequence,
              location,
              room,
              surveyPoint,
              block,
              panel,
              pass,
            };
          }),
        );

        augmentedProduction.sort((a, b) => (a.miningCut?.order || 0) - (b.miningCut?.order || 0));
        setProductionForShift(augmentedProduction);
      } else {
        setProductionForShift([]);
      }
    })();
  }, [productionDocsForShift, locationsForProduction, advancesForBorer]);

  const getMostRecentProductionForShiftId = async (
    bShiftId: string,
  ): Promise<ProductionDocument | undefined> => {
    if (!productionCollection) {
      return undefined;
    }

    const production = await productionCollection
      .find({
        selector: {
          borerShiftId: bShiftId,
        },
      })
      .sort({ updatedAt: 'desc' })
      .exec();

    return production.length > 0 ? production[0] : undefined;
  };

  useEffect(() => {
    const mostRecentProduction = productionDocsAll[0];
    const populateData = async () => {
      const location = await mostRecentProduction?.populate('locationId');

      const [miningCutSequencePassObj, surveyPoint, room, panel, sequence, pass, advance] =
        await Promise.all([
          getMiningCutById(mostRecentProduction?.miningCutId),
          location?.populate('surveyPoint'),
          location?.populate('room'),
          location?.populate('panel'),
          location?.populate('sequence'),
          location?.populate('pass'),
          advancesCollection
            ?.findOne({ selector: { borerShiftProductionId: mostRecentProduction?.id } })
            .sort({ updatedAt: 'desc' })
            .exec(),
        ]);

      const block = await blockCollection
        ?.findOne({
          selector: { id: panel?.block },
        })
        .exec();

      const mostRecentFootage =
        advance?.endDistance || Number(advance?.endDistance) === 0 ? advance?.endDistance : '';

      setMostRecentProductionLocationInformation({
        surveyPoint,
        miningCutSequencePassObj,
        room,
        panel,
        block,
        sequence,
        pass,
        mostRecentFootage,
      });
    };
    if (mostRecentProduction) {
      populateData();
    }
  }, [productionDocsAll, advancesCollection, blockCollection, getMiningCutById]);

  const createProduction = async (
    locationId: string,
    miningCutSequencePass: MiningCutSequencePassObj,
    scoopEquipmentId: string | null,
    stamlerEquipmentId: string | null,
  ) => {
    if (!shiftPicker.currentBorerShiftId) throw new Error('Missing current borer shift id');

    const prodBaseEntity = generateBaseEntityWithCreatedOn();

    const doc = {
      ...prodBaseEntity,
      borerShiftId: shiftPicker.currentBorerShiftId,
      locationId,
      miningCutId: miningCutSequencePass.miningCut.id,
      scoopEquipmentId,
      stamlerEquipmentId,
    };
    return productionCollection?.insert(doc);
  };

  const updateProduction = async (
    productionToEdit: ProductionDocument,
    locationId: string,
    miningCutId: string,
    scoopEquipmentId: string | null,
    stamlerEquipmentId: string | null,
  ) => {
    if (!shiftPicker.currentBorerShiftId) throw new Error('Missing current borer shift id');

    const doc = {
      id: productionToEdit.id,
      borerShiftId: productionToEdit.borerShiftId,
      locationId,
      miningCutId,
      updatedAt: getUnixMillisecondTimestamp(),
      version: productionToEdit.version,
      isDeleted: productionToEdit.isDeleted || false,
      scoopEquipmentId,
      stamlerEquipmentId,
    };
    return productionCollection?.upsert(doc);
  };

  const listProductionForShiftId = async (bShiftId: string): Promise<ProductionDocument[]> => {
    if (!productionCollection) {
      return [];
    }

    const production = await productionCollection
      .find({
        selector: {
          borerShiftId: bShiftId,
        },
      })
      .exec();

    const augmentedOrderData = await Promise.all(
      production.map(async (prod, index) => ({
        miningCut: await prod.populate('miningCutId'),
        originalIndex: index,
        id: prod.id,
      })),
    );

    const sortArray = augmentedOrderData.sort(
      (a, b) => a.miningCut?.order || 0 - b.miningCut?.order || 0,
    );

    const sortedProduction = sortArray.map(sortInfo => production[sortInfo.originalIndex]);

    return sortedProduction;
  };

  const findExistingProduction = async (
    room?: Room,
    surveyPoint?: SurveyPointDocument,
    miningCutSequencePassObj?: MiningCutSequencePassObj,
    scoopEquipmentId?: string | null,
    stamlerEquipmentId?: string | null,
    compareRoom = true,
    compareAssignedStamlerAndScoop = false,
  ) => {
    if (!miningCutSequencePassObj) return;
    const productionsForShift = await listProductionForShiftId(shiftPicker?.currentBorerShiftId);

    let existing: ProductionDocument;
    for (let index = 0; index < productionsForShift.length; index += 1) {
      // eslint-disable-next-line no-await-in-loop
      const productionDoc = productionsForShift[index];
      const loc: LocationDocument = await productionDoc.populate('locationId');

      if (
        (!compareRoom || (room && loc.room === room.id)) &&
        (!surveyPoint || (surveyPoint && loc.surveyPoint === surveyPoint.id)) &&
        loc.pass === miningCutSequencePassObj.pass?.id &&
        (loc.sequence === null || loc.sequence === miningCutSequencePassObj.sequence?.id) &&
        (!compareAssignedStamlerAndScoop ||
          (scoopEquipmentId === productionDoc.scoopEquipmentId &&
            stamlerEquipmentId === productionDoc.stamlerEquipmentId))
      ) {
        existing = productionsForShift[index];
      }
    }

    return existing || undefined;
  };

  const listAdvancesForProductionId = async (productionId?: string): Promise<Advance[]> => {
    if (!advancesCollection || !productionId) {
      return [];
    }

    const advances = await advancesCollection
      .find({
        selector: {
          borerShiftProductionId: productionId,
        },
      })
      .exec();

    return advances.sort(sortAscendingByCreatedOn).map(x => {
      return {
        id: x.id,
        borerShiftProductionId: x.borerShiftProductionId,
        createdOn: x.createdOn,
        distance: x.distance,
        updatedAt: x.updatedAt,
        isDeleted: x.isDeleted || false,
        version: x.version,
        startDistance: x.startDistance,
        endDistance: x.endDistance,
        numberOfBuckets: x.numberOfBuckets,
      };
    });
  };

  const getProductionTotalsForShift = async (useRehabBorerLogic = false) => {
    // foreach Production get the sequence, then the associated miningCut tons/ft.
    // then use that with the advances and sum up
    let totalTonnes = 0;
    let totalAdvance = 0;
    let totalBuckets = 0;
    const productionsForShift = await listProductionForShiftId(shiftPicker?.currentBorerShiftId);

    for (let k = 0; k < productionsForShift.length; k += 1) {
      // eslint-disable-next-line no-await-in-loop
      const [cut, assignedScoop] = await Promise.all([
        productionsForShift[k].populate('miningCutId'),
        productionsForShift[k].populate('scoopEquipmentId'),
      ]);
      // eslint-disable-next-line no-await-in-loop
      const advances = await listAdvancesForProductionId(productionsForShift[k].id);

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      advances.forEach((advance: Advance) => {
        totalAdvance += parseFloat(advance.distance);

        if (useRehabBorerLogic) {
          totalTonnes += (advance.numberOfBuckets || 0) * (assignedScoop?.tonnesPerScoop || 0);
          totalBuckets += advance.numberOfBuckets || 0;
        } else {
          totalTonnes += cut && cut?.density ? parseFloat(advance.distance) * cut?.density : 0;
        }
      });
    }

    return {
      actualTotalTonnes: totalTonnes,
      actualTotalAdvance: totalAdvance,
      actualTotalBuckets: totalBuckets,
    };
  };

  const getEndLocationForShift = async (bShiftId: string) => {
    // List the productions on the shift
    const productionsForShift = await listProductionForShiftId(bShiftId);
    const productionsIds = productionsForShift.map(productionDoc => productionDoc.id);

    // Get all advances that are associated with that production
    const advances: AdvanceDocument[] =
      (await advancesCollection
        ?.find({
          selector: { borerShiftProductionId: { $in: productionsIds } },
          sort: [{ createdOn: 'desc' }],
        })
        .exec()) || [];

    // Determine the most recent advances, sorting on the query helps with this
    const mostRecentAdvance = advances.length > 0 ? advances[0] : null;
    // Get all the adavces with similar borerShiftProducitonId
    const filteredAdv = advances.filter(
      advance => advance.borerShiftProductionId === mostRecentAdvance.borerShiftProductionId,
    );
    const production =
      productionsForShift.find(prod => prod.id === mostRecentAdvance?.borerShiftProductionId) ||
      null;

    if (production && advances && mostRecentAdvance) {
      let calculatedFootage = 0;
      let totalBuckets = 0;

      filteredAdv.forEach(advance => {
        calculatedFootage += advance.distance ? advance.distance : 0;
        totalBuckets += advance.numberOfBuckets || 0;
      });

      const cut = (await production.populate('miningCutId')) as MiningCutDocument | null;
      const location = (await production.populate('locationId')) as LocationDocument | null;
      const panel = (await location?.populate('panel')) as PanelDocument | null;

      if (cut && location && panel) {
        const block = await panel.populate('block');
        const room = await location.populate('room');
        const surveyPoint = await location.populate('surveyPoint');
        const sequence = await cut.populate('sequence');
        const pass = await cut.populate('pass');

        const miningMethod = panel.miningMethod.toLowerCase().includes('chevron')
          ? MiningMethodAllCap.CHEVRON
          : MiningMethodAllCap.LONG_ROOM;

        return {
          location,
          block,
          panel,
          room,
          sequence,
          pass,
          surveyPoint,
          miningMethod,
          endLocationDistance:
            miningMethod === MiningMethodAllCap.LONG_ROOM
              ? mostRecentAdvance.endDistance
              : calculatedFootage,
          totalBuckets,
        };
      }
    }
  };

  return {
    listAdvancesForProductionId,
    getProductionTotalsForShift,
    getEndLocationForShift,
    productionInitialized,
    advancesInitialized,
    findExistingProduction,
    listProductionForShiftId,
    createProduction,
    updateProduction,
    productionCollection,
    advancesCollection,
    getMostRecentProductionForShiftId,
    productionForShift,
    mostRecentProductionLocationInformation,
  };
};

export default useProduction;
