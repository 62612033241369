import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { translate } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },

    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
  }),
);

const BorerShiftInfoCard: FC = () => {
  const classes = useStyles();
  const { serviceStatus } = useBorerShiftInfo();
  const { isRehabBorer } = useCurrentBorer();

  return (
    <>
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Info')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          <>
            <Grid container className={classes.verticalSpacer}>
              <Grid item xs={4}>
                <Typography variant="body1" color="textSecondary">
                  {!isRehabBorer ? translate('Borer Status') : translate('Rehab status')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid item xs={6}>
                  <Typography variant="body1">{serviceStatus}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Card>
    </>
  );
};

export default observer(BorerShiftInfoCard);
