import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useEffect, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import { BlockDocument } from '@/rxdb/Blocks/queryBuilder';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { LocationDocument } from '@/rxdb/Locations/queryBuilder';
import { PanelDocument } from '@/rxdb/Panels/queryBuilder';
import { PassDocument } from '@/rxdb/Passes/queryBuilder';
import useProduction from '@/rxdb/Productions/useProduction';
import { RoomDocument } from '@/rxdb/Rooms/queryBuilder';
import { SequenceDocument } from '@/rxdb/Sequences/queryBuilder';
import useSite from '@/rxdb/Site/useSite';
import { SurveyPointDocument } from '@/rxdb/SurveyPoints/queryBuilder';
import { MiningMethodAllCap } from '@/utilities';
import { unknown } from '@/utilities/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
    },
    value: {
      color: theme.palette.text.primary,
    },
  }),
);

const ShiftEndLocationCard: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const { productionInitialized, advancesInitialized, advancesCollection, getEndLocationForShift } =
    useProduction();
  const { showAllanRehabFields } = useCurrentBorer();
  const { isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation, useMeters } = useSite();

  const [location, setLocation] = useState<LocationDocument | undefined>();
  const [endLocationDistance, setEndLocationDistance] = useState<number | null>(null);
  const [totalBuckets, setTotalBuckets] = useState<number>();
  const [block, setBlock] = useState<BlockDocument>();
  const [room, setRoom] = useState<RoomDocument>();
  const [panel, setPanel] = useState<PanelDocument>();

  const [sequence, setSequence] = useState<SequenceDocument>();
  const [surveyPoint, setSurveyPoint] = useState<SurveyPointDocument>();
  const [pass, setPass] = useState<PassDocument>();
  const [miningMethod, setMiningMethod] = useState<MiningMethodAllCap>();

  const getLastAdvance = async () => {
    if (!shiftPicker?.currentBorerShiftId) return;

    const result = await getEndLocationForShift(shiftPicker?.currentBorerShiftId);

    if (result) {
      setLocation(result.location);
      setEndLocationDistance(result.endLocationDistance);
      setTotalBuckets(result.totalBuckets);
      setBlock(result.block);
      setRoom(result.room);
      setPanel(result.panel);
      setSequence(result.sequence);
      setSurveyPoint(result.surveyPoint);
      setPass(result.pass);
      setMiningMethod(result.miningMethod);
    } else {
      setLocation(undefined);
    }
  };

  const subscribeToAdvances = async () => {
    if (advancesInitialized && advancesCollection) {
      advancesCollection.$.subscribe(async () => {
        await getLastAdvance();
      });
    }
  };

  const setup = async () => {
    await getLastAdvance();
    subscribeToAdvances();
  };

  const renderStep = () => {
    return sequence ? `${sequence?.description} ${pass?.description}` : `${pass?.description}`;
  };

  useEffect(() => {
    if (productionInitialized && advancesInitialized && !!shiftPicker.currentBorerShiftId) {
      setup();
    }
  }, [productionInitialized, advancesInitialized, shiftPicker.currentBorerShiftId]);

  const locationDescription = () => {
    if (panel?.miningMethod === 'Long Room') {
      if (isVanscoy || isRocanville) {
        return `${panel?.description}-${room?.description ?? ''}`;
      }
    }
    if (isCory || isVanscoy || isAllan) {
      return panel?.description;
    } else {
      return room?.description.includes('PE')
        ? room?.description + (block?.description ?? '') + (panel?.description ?? '')
        : (block?.description ?? '') + (panel?.description ?? '') + room?.description;
    }
  };

  return (
    <>
      {location && (
        <Card className={classes.cardRoot} onClick={() => {}}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h5">{i18n.t('End location')}</Typography>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={6}>
              <Typography className={classes.label} variant="body2">
                {isAllan ? i18n.t('Panel') : i18n.t('Room')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{locationDescription()}</Typography>
            </Grid>

            {miningMethod === MiningMethodAllCap.CHEVRON && (
              <>
                {surveyPoint && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body2" className={classes.label}>
                        {isAllan ? i18n.t('Survey point') : i18n.t('Sequence')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{surveyPoint?.description || unknown}</Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    {i18n.t('Step')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{renderStep()}</Typography>
                </Grid>
              </>
            )}

            {miningMethod === MiningMethodAllCap.LONG_ROOM && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    {i18n.t('Pass')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{pass?.description || unknown}</Typography>
                </Grid>
              </>
            )}
            {!showAllanRehabFields && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    {useMeters ? i18n.t('End meters') : i18n.t('End footage')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{`${
                    endLocationDistance || ''
                  } ${distanceUnitAbbreviation}`}</Typography>
                </Grid>
              </>
            )}
            {showAllanRehabFields && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.label}>
                    {i18n.t('No. Buckets')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{totalBuckets}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      )}
    </>
  );
};

export default ShiftEndLocationCard;
