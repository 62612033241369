import { Grid } from '@material-ui/core';
import {
  Button,
  Checkbox,
  Dialog,
  i18n,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';

import useInspectionResults from '@/rxdb/InspectionResults/useInspectionResults';
import { useDateFormatters } from '@/utilities';

import ActiveConditionsCard from '../ActiveConditionsCard';
import { addNewHazardArray } from '../AddWorkFaceAreaInspection/GCHAVerbage';
import RemediatedConditionsCard from '../RemediatedConditionsCard';
import WorkFaceAreaInspectionSignatureCard from '../WorkFaceAreaInspectionSignatureCard';
import useStyles from './WorkFaceAreaInspectionReadOnlyModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
  inspectionId: string;
}

const WorkFaceAreaInspectionReadOnlyModal: React.FC<Props> = ({
  open,
  onClose,
  inspectionId,
}: Props) => {
  const classes = useStyles();
  const { isRocanville } = useSiteFeatures();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { inspectionResultForId } = useInspectionResults(inspectionId);

  const parsedInspectionDetails = useMemo(
    () =>
      inspectionResultForId?.inspectionDetail &&
      JSON.parse(inspectionResultForId?.inspectionDetail),
    [inspectionResultForId],
  );

  const hideCommentsBeforeDate: Dayjs | undefined = inspectionResultForId?.createdOn
    ? dayjs(inspectionResultForId.createdOn)
    : undefined;

  const inspectionType = parsedInspectionDetails?.wfaiDetails?.inspectionType || '';

  const title = i18n.t(
    isRocanville ? 'Mining Face Inspection' : `Work face area inspection (${inspectionType})`,
  );

  if (!open) return null;
  return (
    <Dialog
      data-cy="WorkFaceAreaInspectionReadOnlyModal"
      open={open}
      onClose={onClose}
      titleText={title}
      backButton={false}
      customLeftHeaderButton={
        <div className={classes.createdDate}>
          <Typography variant="h5" color="primary">
            {formatDateWithDisappearingDate(inspectionResultForId?.createdOn)}
          </Typography>
        </div>
      }
      fullScreen
      disableEnforceFocus
      dialogActions={
        <Grid container spacing={2} justify="space-between">
          <Grid item />
          <Grid item>
            <Button
              onClick={onClose}
              variant="contained"
              color="primary"
              noMinHeight
              id="wfai-read-only-modal-close-button"
            >
              {i18n.t('Close')}
            </Button>
          </Grid>
        </Grid>
      }
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Grid container>
        <Grid container className={classes.content}>
          <Grid container className={classes.panelWithoutLeftPadding}>
            <>
              <Grid item container xs={1} justify="center">
                <Grid item>
                  <Checkbox checked disabled />
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">
                  {parsedInspectionDetails?.wfaiDetails?.verbage?.header || ''}
                </Typography>
                <Typography variant="h4" style={{ marginTop: 20 }}>
                  {parsedInspectionDetails?.wfaiDetails?.verbage?.subHeader || ''}
                </Typography>
                <Typography variant="body1">
                  {parsedInspectionDetails?.wfaiDetails?.verbage?.bulletPoints.map(point => (
                    <li key={point}>{point}</li>
                  ))}
                </Typography>
                <Typography variant="h4" style={{ marginTop: 10 }}>
                  {parsedInspectionDetails?.wfaiDetails?.verbage?.footer || ''}
                </Typography>
              </Grid>
            </>
            <Grid item xs={12} container justify="center" className={classes.wfaiCommentContainer}>
              <Grid item xs={11}>
                <Typography variant="h3">{i18n.t('Comment (optional)')}</Typography>
                <TextField
                  multiline
                  rows={undefined}
                  value={
                    parsedInspectionDetails?.wfaiDetails?.wfaiComment?.replace(
                      /&nbsp;/g,
                      '\u00a0\u200b',
                    ) || ''
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <ActiveConditionsCard
            showAddButton={false}
            alternateHeaderText={i18n.t('Active hazards')}
            newGroundHazardIds={parsedInspectionDetails?.wfaiDetails?.newGroundHazardIds}
            overrideHazardsToShowArray={parsedInspectionDetails?.wfaiDetails?.activeGroundHazardIds}
            hideAddPhotoAction
            hideAddComment
            hideCommentsBeforeDate={hideCommentsBeforeDate}
          />
          <Grid container className={classes.panelWithoutTopMargin}>
            <Typography variant="h3" style={{ marginLeft: 6 }}>
              {i18n.t('Hazard status')}
            </Typography>
            <Grid item xs={12} className={classes.buttonGroupContainer}>
              <ToggleButtonGroup
                value={parsedInspectionDetails?.wfaiDetails?.newHazardStatus || ''}
                exclusive
              >
                {addNewHazardArray.map(haz => (
                  <ToggleButton
                    key={`toggle-button${haz.value}`}
                    value={haz.value}
                    aria-label={haz.value}
                    showColorOnDisabled
                    disabled
                  >
                    <Typography variant="button" className={classes.noTextTransform}>
                      {haz.value}
                    </Typography>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <RemediatedConditionsCard
            newGroundHazardIds={
              parsedInspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds || []
            }
            conditionsAlwaysExpanded
            hideAddPhotoAction
            title={i18n.t('Remediated this check')}
            hideCommentsBeforeDate={hideCommentsBeforeDate}
          />
          <WorkFaceAreaInspectionSignatureCard
            signatureId={parsedInspectionDetails?.wfaiDetails?.signatureId}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default WorkFaceAreaInspectionReadOnlyModal;
