import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import usePanelDrawings from '../../rxdb/PanelDrawing/usePanelDrawings';
import useShifts from '../../rxdb/Shifts/useShifts';
import { GROUND_HAZARD_WRITE_PERMISSION } from '../../utilities/constants';
import { useViewingCurrentShift } from '../../utilities/hooks/useViewingCurrentShift';
import ActiveConditionsCard from '../ActiveConditionsCard';
import NoPanelDrawingCard from '../NoPanelDrawingCard';
import PanelDrawingViewEditCard from '../PanelDrawingViewEditCard';
import RemediatedConditionsCard from '../RemediatedConditionsCard';
import Spinner from '../Spinner';

const AreaCheckConditionsTab: React.FC = () => {
  const { shiftPicker, user } = useMst();
  const { currentPanelDrawing, recentPanelDrawing, panelDrawingsLoaded } = usePanelDrawings();
  const { viewingMostRecentAvailableShift } = useShifts();

  const [shiftDateStart, setShiftDateStart] = useState<number>();
  const [shiftDateEnd, setShiftDateEnd] = useState<number>();

  useEffect(() => {
    const shiftStart = shiftPicker.selectedShiftDate.unix();
    const shiftEnd = shiftPicker.selectedShiftEndDate.unix();

    setShiftDateStart(shiftStart);
    setShiftDateEnd(shiftEnd);
  }, [
    shiftPicker.Date,
    shiftPicker.Type,
    shiftPicker.currentShiftId,
    shiftPicker.currentBorerShiftId,
  ]);

  const viewingCurrentShift = useViewingCurrentShift();
  const showAddHazardButton = useMemo(() => {
    return viewingCurrentShift || viewingMostRecentAvailableShift;
  }, [viewingCurrentShift, viewingMostRecentAvailableShift]);

  return (
    <Grid container data-cy="AreaCheckConditionsTab">
      <Grid item xs={12}>
        <ActiveConditionsCard
          conditionsAlwaysExpanded
          hideAddPhotoAction={!user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION)}
          showAddButton={showAddHazardButton}
        />
      </Grid>
      <Grid item xs={12}>
        {!panelDrawingsLoaded && <Spinner />}
        {panelDrawingsLoaded && !currentPanelDrawing && <NoPanelDrawingCard />}
        {currentPanelDrawing && (
          <PanelDrawingViewEditCard
            panelDrawing={currentPanelDrawing}
            editMode={viewingCurrentShift}
            activeDrawing
          />
        )}
        {recentPanelDrawing && (
          <PanelDrawingViewEditCard panelDrawing={recentPanelDrawing} editMode={false} />
        )}
      </Grid>
      <Grid item xs={12}>
        <RemediatedConditionsCard
          useShiftDate
          shiftDateStart={shiftDateStart}
          shiftDateEnd={shiftDateEnd}
          conditionsAlwaysExpanded={false}
          hideAddPhotoAction
        />
      </Grid>
    </Grid>
  );
};

export default observer(AreaCheckConditionsTab);
