import {
  applyCuttingTypeIdToBorerOperatorState,
  getRunningBorerOperatorStatesAfterTimestamp,
} from '../BorerOperatorStateFeed/borerOperatorStateQueries';
import {
  applyCuttingTypeIdToChangeState,
  getRunningOperatorChangeStatesAfterTimestamp,
} from './borerOperatorChangeQueries';

export const applyCuttingTypeIdToAllFutureStates = async (
  startTimestamp: number,
  endTimestamp: number,
  newCuttingTypeId: string,
) => {
  const tempStatesToUpdate = await getRunningOperatorChangeStatesAfterTimestamp(
    startTimestamp,
    endTimestamp,
  );

  await applyCuttingTypeIdToChangeState(tempStatesToUpdate, newCuttingTypeId);

  const borerStateDocsToUpdate = await getRunningBorerOperatorStatesAfterTimestamp(
    startTimestamp,
    endTimestamp,
  );

  await applyCuttingTypeIdToBorerOperatorState(borerStateDocsToUpdate, newCuttingTypeId);
};
