import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  outerWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: props => `${(1 / props.ratio) * 100}%`,
  },
  innerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
});

interface Props {
  children?: any;
  ratio: number;
}

const AspectRatio: React.FC<Props> = ({ children, ratio }: Props) => {
  const classes = useStyles({ ratio });

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>{children}</div>
    </div>
  );
};

export default AspectRatio;
