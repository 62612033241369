import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { EquipmentTypeCollection, EquipmentTypeDocument } from './queryBuilder';

export const useEquipmentType = () => {
  const equipmentTypeCollection: EquipmentTypeCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_TYPE,
  );

  const [equipmentTypesInitialized, setCollectionsInitialized] = useState(false);

  const equipmentTypeQuery = useCallback(collection => collection.find(), []);

  const { result: equipmentTypeResult } = useRxData<EquipmentTypeDocument>(
    RxdbCollectionName.EQUIPMENT_TYPE,
    equipmentTypeQuery,
  );

  useEffect(() => {
    if (equipmentTypeCollection) setCollectionsInitialized(true);
  }, [equipmentTypeCollection]);

  const listEquipmentType = async (): Promise<EquipmentTypeDocument[]> => {
    if (!equipmentTypeCollection) {
      return [];
    }
    try {
      const types = await equipmentTypeCollection.find().exec();
      return types;
    } catch (error) {
      console.log('🚀 ~ file: useEquipmentType.ts ~ line 28 ~ listEquipmentType ~ error', error);
      throw error;
    }
  };

  return { listEquipmentType, equipmentTypesInitialized, equipmentTypeResult };
};

export default useEquipmentType;
