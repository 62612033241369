import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    type: {
      color: theme.palette.common.white,
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
    },
    iconSpacer: {
      marginRight: 8,
    },
    spacer: {
      marginLeft: 15,
    },
    flexItem: {
      height: 'auto',
      display: 'flex',
      flexGrow: 1,
    },
    crewCardContent: { marginTop: '10px' },
    crewTime: { marginRight: '12px', marginBottom: '10px' },
    operatorLabel: { minWidth: '104px', marginRight: '12px' },
    operatorValue: {
      [theme.breakpoints.down('lg')]: {
        maxWidth: '222px',
      },
    },
  }),
);
