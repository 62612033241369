import { useCallback } from 'react';
import { useRxData } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { Equipment } from './queryBuilder';

export const useEquipmentById = (id?: string | null) => {
  const equipmentQuery = useCallback(collection => collection.find({ selector: { id } }), [id]);

  const { result: equipment } = useRxData<Equipment>(RxdbCollectionName.EQUIPMENT, equipmentQuery);

  return {
    equipment: equipment[0],
  };
};
