import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { AppointmentType, DelayActivitiesBlockType } from '../../utilities/enums';
import { formatDateFromUnixInReginaTzForScheduler } from '../../utilities/hooks/useDateFormatters';
import useLastSyncTime from '../../utilities/hooks/useLastSyncTime';
import { getTimeUntilNextMinute } from '../../utilities/utilityFunctions';
import { useAdvancesForShift } from '../Advance/useAdvancesForShift';
import { BorerStateType, BorerStateTypeDocument } from '../BorerStateTypeFeed/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { BorerOperatorState, BorerOperatorStateDocument } from './queryBuilder';

export interface PopulatedBorerOperatorState extends AppointmentModel {
  borerOperatorState?: BorerOperatorState | null;
  borerOperatorStateId?: string | null;
  borerStateType?: BorerStateTypeDocument | null;
  isTempState?: boolean;
  updatedAt: number;
  failedSync: boolean | null | undefined;
  isGeneratedState: boolean | null;
  isRunning: boolean;
}
export const codeBlockType = (
  stateType: BorerStateType | null | undefined,
  hasError?: boolean | null,
) => {
  if (hasError) return DelayActivitiesBlockType.ERROR;
  if (stateType?.isRunning) return DelayActivitiesBlockType.RUNNING;
  if (stateType?.isDefault) return DelayActivitiesBlockType.NOT_RUNNING;
  if (!stateType) return DelayActivitiesBlockType.DELAY;
  return DelayActivitiesBlockType.DELAY;
};

export const useBorerOperatorState = (borerStateId?: string) => {
  const { shiftPicker } = useMst();
  const stateTypeCollection = useRxCollection(RxdbCollectionName.BORER_STATE_TYPE);
  const { advancesForShiftById } = useAdvancesForShift();

  const borerOperatorStateCollection = useRxCollection(
    RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
  );
  const lastOperatorStateSyncTime = useLastSyncTime(RxdbCollectionName.BORER_OPERATOR_STATE_FEED);
  const [currentShiftBorerOperatorStates, setCurrentShiftBorerOperatorStates] = useState<
    PopulatedBorerOperatorState[]
  >([]);

  const borerStateQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          $and: [
            {
              startTimeUnix: {
                $gte: shiftPicker.shiftStartDateUnix,
              },
            },
            {
              startTimeUnix: {
                $lt: shiftPicker.shiftEndDateUnix,
              },
            },
            { showInSchedulerView: true },
            {
              $or: [
                {
                  endTimeUnix: {
                    $lte: shiftPicker.shiftEndDateUnix,
                  },
                },
                {
                  endTimeUnix: {
                    $eq: null,
                  },
                },
              ],
            },
          ],
        },
      }),
    [shiftPicker.shiftStartDateUnix, shiftPicker.shiftEndDateUnix],
  );

  const { result: statesForShift, isFetching: statesFetching } = useRxData<BorerOperatorState>(
    RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
    borerStateQueryConstructor,
  );

  const removeStates = async (bStateId?: string) => {
    if (!bStateId) return;
    const matchingsDocs = await borerOperatorStateCollection
      ?.find({
        selector: {
          borerStateId: bStateId,
        },
      })
      .exec();

    if (matchingsDocs?.length) {
      matchingsDocs.forEach(async doc => {
        await doc.remove();
      });
    }
  };

  const getStateByBorerStateId = (bStateId: string) => {
    if (!borerOperatorStateCollection) {
      return undefined;
    }
    return borerOperatorStateCollection.findOne({ selector: { borerStateId: bStateId } }).exec();
  };

  useEffect(() => {
    const augmentStatesWithDocType = async () => {
      if (statesFetching) {
        setCurrentShiftBorerOperatorStates([]);
      } else {
        const stateTypes = await Promise.all(
          statesForShift.map(state => state.populate('borerStateTypeId')),
        );

        const augmentedStates = statesForShift.map((state, index) => {
          let endTimeUnix = state.endTimeUnix;
          if (!state.endTimeUnix) {
            // No end time for current block.
            const stateType = stateTypes[index];
            if (stateType.isRunning === true) {
              // If running, generate mock delay which starts at last sync time
              endTimeUnix = dayjs(lastOperatorStateSyncTime).unix();
            } else {
              // If not running, delay continues to current time
              endTimeUnix = dayjs().unix();
            }
          }

          return {
            borerOperatorState: state,
            borerOperatorStateId: state.borerStateId,
            borerStateType: stateTypes[index],
            startDate: formatDateFromUnixInReginaTzForScheduler(state.startTimeUnix),
            endDate: formatDateFromUnixInReginaTzForScheduler(endTimeUnix),
            borerShiftAdvanceId: state.borerShiftAdvanceId,
            advanceString: state?.borerShiftAdvanceId
              ? advancesForShiftById[state.borerShiftAdvanceId]?.locationString || ''
              : '',
            startTime: state.startTime || null,
            startTimeUnix: state.startTimeUnix,
            endTime: state.endTime || null,
            endTimeUnix,
            title: stateTypes[index]?.description || 'Unknown',
            comment: state?.comment,
            typeId: AppointmentType.DELAY, // Delays are 1, activities are 2
            blockType: codeBlockType(stateTypes[index]),
            id: state.id,
            isTempState: false,
            updatedAt: state.updatedAt,
            cuttingTypeId: state.cuttingTypeId,
            cuttingMethodId: state.cuttingMethodId,
          };
        });

        setCurrentShiftBorerOperatorStates([...augmentedStates]);
      }
    };
    augmentStatesWithDocType();

    const interval = setInterval(() => {
      // Re-run on on the minute to make sure last appointment is accurate
      augmentStatesWithDocType();
    }, getTimeUntilNextMinute());

    return () => clearInterval(interval);
  }, [
    stateTypeCollection,
    statesForShift,
    statesFetching,
    advancesForShiftById,
    lastOperatorStateSyncTime,
  ]);

  // States for given borerStateId (hook arg)
  const borerStateConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerStateId,
        },
      }),
    [borerStateId],
  );

  const getStatesForParentBorerStateId = useCallback(
    async (bStateId?: string): Promise<BorerOperatorStateDocument[] | undefined> =>
      borerOperatorStateCollection
        ?.find({
          selector: {
            borerStateId: bStateId,
            showInSchedulerView: true,
          },
        })
        .exec(),
    [borerOperatorStateCollection],
  );

  const { result: statesForParentBorerStateId, isFetching: stateTypesFetching } =
    useRxData<BorerOperatorState>(
      RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
      borerStateConstructor,
    );

  return {
    currentShiftBorerOperatorStates,
    statesFetching,
    statesForParentBorerStateId,
    stateTypesFetching,
    getStateByBorerStateId,
    removeStates,
    getStatesForParentBorerStateId,
  };
};

export default useBorerOperatorState;
