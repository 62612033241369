import { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';

export const useCrewAssignedForShift = (): boolean => {
  const { shiftPicker } = useMst();

  const [crewAssigned, setCrewAssigned] = useState(true);
  const { getBorerShiftCrews, borerShiftCrewInitialized } = useBorerShiftCrew();

  useEffect(() => {
    const checkIfCrewMissing = async () => {
      if (shiftPicker.currentBorerShiftId && borerShiftCrewInitialized) {
        const { borerShiftCrews } = await getBorerShiftCrews(shiftPicker.currentBorerShiftId);
        const isCrewMemberNotAassigned =
          borerShiftCrews !== null
            ? borerShiftCrews.every(item =>
                item.borerShiftCrewMemberInput.every(
                  crewMember => !(crewMember.employee?.id && crewMember.borerShiftCrewMemberRole),
                ),
              )
            : true;
        if (shiftPicker.isCurrentShiftSelected() && isCrewMemberNotAassigned) {
          setCrewAssigned(false);
        } else {
          setCrewAssigned(true);
        }
      }
    };
    checkIfCrewMissing();
  }, [shiftPicker.currentBorerShiftId, borerShiftCrewInitialized]);

  return crewAssigned;
};

export default useCrewAssignedForShift;
