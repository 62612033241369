import { RxGraphQLReplicationPullQueryBuilder } from 'rxdb';
import { RxGraphQLReplicationQueryBuilderResponse } from 'rxdb/dist/types/types';

import { rootStore } from '@/mobx-models/Root';

import defaultDoc from '../Shared/defaultDoc';
import { CheckpointType } from '../types';

// Pull Query Generator

export const enum PullQueryContext {
  Site = 'Site',
  Borer = 'Borer',
}
type PullQueryVariables = {
  lastUpdateAt: number;
  limit: number;
  siteId?: string;
  borerEquipmentId?: string;
};
/**
 * Creates a pull query function that can be used by RxDB to pull data from the server.
 *
 * @param {string} query
 * @param {PullQueryContext} [context]
 * @param {CheckpointType} [initialCheckpoint=defaultDoc]
 * @return {*}  {RxGraphQLReplicationPullQueryBuilder<CheckpointType>}
 */

export const generatePullQuery = (
  query: string,
  context?: PullQueryContext,
  initialCheckpoint: CheckpointType = defaultDoc,
  customVariables: Record<string, unknown> = {},
  debug = false,
): RxGraphQLReplicationPullQueryBuilder<CheckpointType> => {
  return (
    latestPulledCheckpoint: CheckpointType | null,
    limit: number,
  ): RxGraphQLReplicationQueryBuilderResponse => {
    if (!latestPulledCheckpoint) {
      latestPulledCheckpoint = { ...initialCheckpoint };
    }

    const variables: PullQueryVariables = {
      lastUpdateAt: latestPulledCheckpoint.updatedAt,
      limit,
    };

    if (context === PullQueryContext.Site && rootStore.user.siteId) {
      variables.siteId = rootStore.user.siteId;
    } else if (context === PullQueryContext.Borer) {
      variables.borerEquipmentId = rootStore.equipment.selectedBorerId;
    }

    if (debug) {
      console.log('🚀 ~ file: rxdbUtilityFunctions.ts:78 ~ query:', query);
      console.log('Pull Query Variables: ', variables);
    }

    return {
      query,
      variables: {
        ...variables,
        ...customVariables,
      },
    };
  };
};
