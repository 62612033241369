import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, i18n, Typography } from '@nutrien/cxp-components';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  shiftDate: string;
  disableEnforceFocus?: boolean;
}

const UnableToCloseShiftModal: React.FC<Props> = ({
  open,
  onClose,
  shiftDate,
  disableEnforceFocus = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleText={i18n.t('Close shift')}
      backButton={false}
      disableEnforceFocus={disableEnforceFocus}
      maxWidth="xs"
      maxHeight="250px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      classes={{ container: classes.backdrop }}
    >
      <Typography variant="h6" className={classes.title}>
        {i18n.t('Unable to close shift')}
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        {i18n.t(
          'Unable to close shift: You are unable to close your current shift since a previous shift, ',
        )}
        {shiftDate}
        {i18n.t(
          ', remains open. Please call your supervisor to close the previous shift prior to closing your current shift. ',
        )}
      </Typography>
    </Dialog>
  );
};

export default UnableToCloseShiftModal;
