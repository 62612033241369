import type { Event } from '@sentry/react';

const blackListErrorMessages = ['No current user'];

const DEBUG = localStorage.getItem('DEBUG') === 'true';

export const beforeSend = (event: Event): PromiseLike<Event | null> | Event | null => {
  if (DEBUG) {
    console.info('🚀 ~ file: sentryFunctions.ts ~ line 7 ~ event', event);
  }

  if (event.message && blackListErrorMessages.includes(event.message)) {
    return null;
  }

  return event;
};

export default { beforeSend };
