import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    margin: '4px 4px 10px 4px !important',
    padding: '16px',
    boxShadow: 'none !important',
  },
  label: {
    color: theme.palette.text.secondary,
    wordBreak: 'break-word',
    fontWeight: 'bold',
  },
  value: {
    color: theme.palette.text.primary,
  },
  editBtnContainer: {
    alignItems: 'flex-end !important',
    '& > div': {
      minHeight: 'auto !important',
      alignItems: 'flex-end',
    },
    '& > div > span': {
      display: 'none',
    },
  },
  addOrEditButton: {
    maxWidth: '175px',
  },
  commentRow: {
    '&:not(:last-child)': {
      paddingBottom: '16px',
    },
  },
  commentText: {
    ...theme.typography.body2,
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
