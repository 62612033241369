import { DialogTitle, Grid, IconButton } from '@material-ui/core';
import { i18n, Icons, Typography } from '@nutrien/cxp-components';
import React from 'react';

import useStyles from './GenericSidePanelHeader.styles';

interface Props {
  onClose: () => void;
  title: string;
}

const GenericSidePanelHeader: React.FC<Props> = ({ onClose, title }: Props) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.root}>
      <div className={classes.headerContainer}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item style={{ width: '36px', height: '36px' }}>
            <div />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label={i18n.t('close')} onClick={onClose} id="close-button">
              <Icons.XFeather strokeWidth={1} color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <div className={`${classes.rightButtonContainer} `} />
      </div>
    </DialogTitle>
  );
};

export default GenericSidePanelHeader;
