import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface InspectionResult extends BaseEntity {
  readonly inspectionDetail: string;
  readonly borerShiftId: string;
  readonly inspectionId: string;
  readonly signatureId: string | null;
  readonly createdOn: string;
  readonly locationId: string | null;
}
export type InspectionResultCollection = RxCollection<InspectionResult> | null;
export type InspectionResultDocument = RxDocument<InspectionResult>;

export const inspectionResultSchema: RxJsonSchema<InspectionResult> = {
  type: 'object',
  version: 1,
  description: 'describes a InspectionCategory object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    inspectionDetail: { type: 'string' },
    borerShiftId: { ref: RxdbCollectionName.BORER_SHIFT, type: 'string' },
    inspectionId: { ref: RxdbCollectionName.INSPECTIONS, type: 'string' },
    signatureId: {
      ref: RxdbCollectionName.SIGNATURES,
      type: ['string', 'null'],
    },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    createdOn: { type: 'string' },
    locationId: { ref: RxdbCollectionName.LOCATIONS, type: ['string', 'null'] },
  },
  indexes: ['updatedAt'],
};

export const inspectionResultMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: InspectionResult) => ({ ...oldDoc, locationId: null }),
};

const inspectionResultFeedQuery = print(gql`
  query inspectionResultFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    inspectionResultFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      inspectionDetail
      borerShiftId
      inspectionId
      isDeleted
      signatureId
      updatedAt
      version
      createdOn
      locationId
    }
  }
`);

export const inspectionResultPullQueryBuilder = generatePullQuery(
  inspectionResultFeedQuery,
  PullQueryContext.Borer,
);

const setInspectionResultMutation = print(gql`
  mutation setInspectionResult(
    $id: ID!
    $createdOn: AWSDateTime
    $borerShiftId: ID!
    $inspectionDetail: String!
    $inspectionId: ID!
    $signatureId: ID
    $locationId: ID
    $isDeleted: Boolean!
    $version: Int!
  ) {
    setInspectionResult(
      input: {
        id: $id
        borerShiftId: $borerShiftId
        createdOn: $createdOn
        inspectionId: $inspectionId
        signatureId: $signatureId
        inspectionDetail: $inspectionDetail
        locationId: $locationId
        isDeleted: $isDeleted
        version: $version
      }
    )
  }
`);

export const inspectionResultPushQueryBuilder = generatePushQuery(
  setInspectionResultMutation,
  (doc: InspectionResultDocument) => ({
    ...doc,
  }),
);

export const inspectionResultPushModifier = (doc: InspectionResultDocument) => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    createdOn: doc.createdOn,
    inspectionId: doc.inspectionId,
    signatureId: doc.signatureId,
    inspectionDetail: doc.inspectionDetail,
    locationId: doc.locationId,
    isDeleted: doc.isDeleted || false,
    version: doc.version || 1,
  };
};

export default {};
