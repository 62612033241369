import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { BorerShiftCrewMemberRole } from '../BorerShiftCrewMemberRole/queryBuilder';
import { Employee } from '../Employees/queryBuilder';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerShiftCrew extends BaseEntity {
  readonly borerShiftId: string;
  readonly crewNumber: number;
  readonly start: string;
  readonly end: string;
  readonly notUsed: boolean;
  readonly hidden: boolean;
}
export type BorerShiftCrewCollection = RxCollection<BorerShiftCrew> | null;
export type BorerShiftCrewDocument = RxDocument<BorerShiftCrew>;

export interface BorerShiftCrewMemberEmployee extends Employee {
  readonly borerShiftCrewMemberId?: string;
  readonly borerShiftCrewMemberRoleId?: string;
  readonly borerShiftCrewMemberVersion?: number;
  readonly reactKey?: string;
  readonly employeeOrder: number;
}

export interface EmptyEmployeePlaceholder {
  borerShiftCrewMemberId: string;
  borerShiftCrewMemberRoleId: string;
  reactKey?: string;
}
export interface BorerShiftCrewMemberWithRole {
  employee: BorerShiftCrewMemberEmployee | EmptyEmployeePlaceholder;
  borerShiftCrewMemberRole: BorerShiftCrewMemberRole | null;
}

export const borerShiftCrewSchema: RxJsonSchema<BorerShiftCrew> = {
  type: 'object',
  version: 4,
  description: 'describes an borer shift crew object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    borerShiftId: { type: 'string' },
    crewNumber: { type: 'number' },
    start: { type: 'string' },
    end: { type: 'string' },
    hidden: { type: 'boolean' },
    notUsed: { type: 'boolean' },
  },
  indexes: ['updatedAt'],
};

export const borerShiftCrewMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc, borerShiftCrewMemberInput: null };
  },
  2: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
  3: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
  4: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
};

const borerShiftCrewFeedQuery = print(gql`
  query borerShiftCrewFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftCrewFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftId
      crewNumber
      end
      id
      isDeleted
      start
      updatedAt
      version
    }
  }
`);

export const borerShiftCrewPullQueryBuilder = generatePullQuery(
  borerShiftCrewFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftCrewMutation = print(gql`
  mutation setBorerShiftCrew($input: SetBorerShiftCrewInput) {
    setBorerShiftCrew(input: $input) {
      id
      siteId
      shiftId
      borerEquipmentId
      borerShiftId
    }
  }
`);

type SetBorerShiftCrewInput = {
  id: string;
  borerShiftId: string;
  crewNumber: number;
  startDateTime: string;
  endDateTime: string;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapBorerShiftCrewDocToInput = (doc: BorerShiftCrewDocument): SetBorerShiftCrewInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    crewNumber: doc.crewNumber,
    startDateTime: doc.start,
    endDateTime: doc.end,
    modifiedOn: dayjs().toISOString(),
    version: doc.version,
    isDeleted: doc.notUsed,
  };
};

export const borerShiftCrewPushQueryBuilder = generatePushQuery(
  setBorerShiftCrewMutation,
  (doc: BorerShiftCrewDocument) => ({
    input: mapBorerShiftCrewDocToInput(doc),
  }),
);

export const borerShiftCrewPullModifier = (doc: any) => {
  const result = {
    ...doc,
    hidden: false,
  };
  return result;
};

export interface ExtendedBorerShiftCrewDocument extends BorerShiftCrewDocument {
  borerShiftCrewMemberInput: BorerShiftCrewMemberWithRole[];
}
