const defaultFlagValues = {
  borerBlockUpdate: true,
  allowAutoUpdate: false,
  borerWfaiDate: {
    '0fd37374-4add-b21e-fa35-20dab7f8e600': '',
    '6599161d-ab54-289a-0e17-c4c8551540ec': '',
    '843de355-7db7-4692-8d13-d9a7edb7ed2b': '',
    '8b3aa642-48a3-43a6-a346-bab516508700': '',
    '9d900b61-195c-433c-94ee-155a2570d653': '',
  },
  borerDisplayCuttingPermitSeverityValue: false,
  msBorerDataSync: {
    allowSync: true,
    message: '',
  },
  borerRefreshButtonEnabled: true,
  borerOutageWarningBanner: {
    message: '',
    display: false,
  },
  borerOutageBanner: {
    message: '',
    display: false,
  },
  displayrxdbsyncindicator: false,
  maxUpdateVersion: '1.0.0',
  versionBlacklist: [],
  coryZoneBCuttingPermit: false,
  rehabBorersVisible: false,
  showCloseShiftButton: false,
  borerDelaysMultiEditMode: false,
  documentsV2: false,
  documentsV2Borer: false,
  documentsV2Location: false,
  documentsV2Global: false,
};

export const FLAG_CACHE_KEY = 'flagValues';

export const getDefaultFlagValues = () => {
  // Check localStorage for cached flag values
  const cachedFlagValues = localStorage.getItem(FLAG_CACHE_KEY);
  if (cachedFlagValues) {
    try {
      const parsedFlagValues = JSON.parse(cachedFlagValues);
      return { ...parsedFlagValues, borerBlockUpdate: true, allowAutoUpdate: false };
    } catch (e) {
      console.error('Error parsing cached flag values', e);
    }
  }
  // If no cached values, return default values
  return defaultFlagValues;
};
