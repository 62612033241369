import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { RoomCollection, RoomDocument } from './queryBuilder';

export const useRooms = (panelId?: string) => {
  const roomsCollection: RoomCollection = useRxCollection(RxdbCollectionName.ROOMS);

  const [roomsInitialized, setRoomsInitialized] = useState<boolean>(false);
  const [defaultRoomForPanel, setDefaultRoomForPanel] = useState<RoomDocument | undefined>();

  useEffect(() => {
    if (roomsCollection) setRoomsInitialized(true);
  }, [roomsCollection]);

  const getRoomForPanel = async (panelIdentifier: string): Promise<RoomDocument | null> => {
    if (!roomsCollection) return null;

    try {
      return await roomsCollection
        ?.findOne({
          selector: {
            panel: { $eq: panelIdentifier },
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useRooms.ts ~ line 34 ~ getRoomForPanel ~ error', error);
      return null;
    }
  };

  useEffect(() => {
    const getDefaultRoomForPanel = async () => {
      if (roomsCollection && panelId) {
        const room = await getRoomForPanel(panelId);
        if (room) {
          setDefaultRoomForPanel(room);
        } else {
          setDefaultRoomForPanel(undefined);
        }
      } else {
        setDefaultRoomForPanel(undefined);
      }
    };

    getDefaultRoomForPanel();
  }, [roomsCollection, panelId]);

  return {
    roomsCollection,
    roomsInitialized,
    getRoomForPanel,
    defaultRoomForPanel,
  };
};

export default useRooms;
