import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Icons, Typography } from '@nutrien/cxp-components';
import React from 'react';

import { InspectionResultDocument } from '../../rxdb/InspectionResults/queryBuilder';
import { CuttingPermitResult } from '../../rxdb/InspectionResults/types';
import { InspectionDocument } from '../../rxdb/Inspections/queryBuilder';
import { useDateFormatters } from '../../utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px !important',
      padding: '16px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
  }),
);

interface Props {
  inspectionDetails: InspectionDocument;
  inspectionResult: InspectionResultDocument;
  onCuttingPermitSelected: (inspectionResult: InspectionResultDocument) => void;
}

const getSeverityLevel = (inspectionResult: InspectionResultDocument) => {
  let inspectionDetail: CuttingPermitResult | string | null;

  try {
    inspectionDetail = JSON.parse(inspectionResult.inspectionDetail);
  } catch (error) {
    inspectionDetail = inspectionResult.inspectionDetail;
  }

  if (typeof inspectionDetail === 'string' || inspectionDetail === null) return null;

  const { cuttingPermitSeverity } = inspectionDetail;
  if (typeof cuttingPermitSeverity === 'string') return cuttingPermitSeverity;

  return null;
};

const CuttingPermitCard: React.FC<Props> = ({
  inspectionDetails,
  inspectionResult,
  onCuttingPermitSelected,
}: Props) => {
  const classes = useStyles();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const severityLevel = getSeverityLevel(inspectionResult);

  return (
    <Card
      className={classes.cardRoot}
      onClick={() => {
        onCuttingPermitSelected(inspectionResult);
      }}
      id={`cutting-permit-card-${inspectionResult.id}`}
    >
      <Grid container>
        <Grid item container xs={6}>
          <Grid item>
            <Typography variant="h5">{inspectionDetails.description}</Typography>
          </Grid>
          <Grid item>
            <span className={classes.dot2}>&#183;</span>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textSecondary">
              {formatDateWithDisappearingDate(inspectionResult.createdOn, true)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} justify="flex-end" alignContent="center" spacing={2}>
          <Grid item xs={10}>
            <Typography variant="h5" style={{ textAlign: 'end' }}>
              {severityLevel}
            </Typography>
          </Grid>
          <Grid item>
            <Icons.ChevronRightFeather color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CuttingPermitCard;
