import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface GroundControlType extends BaseEntity {
  readonly options: string;
  readonly description: string;
  readonly displayOrder: number;
  readonly requiresQuantity: boolean;
}
export type GroundControlTypeCollection = RxCollection<GroundControlType> | null;
export type GroundControlTypeDocument = RxDocument<GroundControlType>;

export const groundControlTypesSchema: RxJsonSchema<GroundControlType> = {
  type: 'object',
  version: 0,
  description: 'describes a Ground Control Type object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayOrder: { type: 'number' },
    options: { type: 'string' },
    requiresQuantity: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const groundControlTypeFeedQuery = print(gql`
  query groundControlTypeFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    groundControlTypeFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      description
      displayOrder
      options
      requiresQuantity
      updatedAt
      isDeleted
      version
    }
  }
`);

export const groundControlTypesPullQueryBuilder = generatePullQuery(
  groundControlTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export default {};
