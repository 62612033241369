import { isNumber } from 'radash';

import { BorerDocumentUploadDocument } from '@/rxdb/BorerDocuments/queryBuilder';
import { RxdbCollectionName } from '@/rxdb/rxdbCollectionName';

import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { cacheDocumentInS3 } from './cacheDocumentInS3';
import { attachmentInStorage, retryCountExceeded } from './documentUtilities';
import {
  getDocumentFromBorerDocumentUploadCollection,
  getDocumentFromDocumentUploadCollection,
} from './getDocumentFromDocumentUploadCollection';

const DEBUG = sessionStorage.getItem('DEBUG') === 'true';
export const defaultProtectionLevel = 'public';

export const DOCUMENT_RETRY_COUNT = 3;

// Used for deficiency and hazard attachments
const isLoading: { [key: string]: boolean } = {};

/**
 * Using a filename the attachment will be attempted ot pull down, has a 5 second retry in case of race condition on upload
 *
 * @param {string} filename s3 filepath
 * @param {number} [retryTimeoutms=5000]
 */

const isHazardOrDeficiencyAttachment = (document: DocumentUploadDocument) =>
  document.collection.name === RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS ||
  document.collection.name === RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT;

export const getAttachmentWithRetry = async (
  document: DocumentUploadDocument | BorerDocumentUploadDocument,
  retryTimeoutms = 5000,
  numberOfRetries = DOCUMENT_RETRY_COUNT,
  isBorerDocument = false,
) => {
  if (!document.fileName) return;
  // Required to do incrementalModify on rxdbDoc. Only works on DocumentUpload collection.
  const rxdbDoc = isBorerDocument
    ? await getDocumentFromBorerDocumentUploadCollection(document)
    : await getDocumentFromDocumentUploadCollection(document);

  if (isLoading[document.id] === undefined || isLoading[document.id] === false) {
    isLoading[document.id] = true;
  } else {
    // Download / retry process already started for this document.
    // RxdbDoc isLoading property is async so this prevents duplicate downloads.
    return;
  }
  if (rxdbDoc?.isCached || rxdbDoc?.isLoading) return;

  // Doc is already cached
  const alreadyInAttachmentStore = await attachmentInStorage(document.fileName);

  if (alreadyInAttachmentStore) {
    await rxdbDoc?.incrementalModify(doc => {
      return { ...doc, isCached: true };
    });

    if (isBorerDocument) {
      await document?.incrementalModify(doc => {
        return { ...doc, isCached: true };
      });
    }

    return true;
  }

  let timeout: NodeJS.Timeout;
  if (
    (rxdbDoc || isHazardOrDeficiencyAttachment(document)) &&
    !retryCountExceeded(rxdbDoc, numberOfRetries)
  ) {
    if (DEBUG)
      console.info(
        'Attempting to download with retry',
        document.fileName,
        rxdbDoc?.retryCount,
        rxdbDoc,
      );
    try {
      await rxdbDoc?.incrementalModify(doc => ({ ...doc, isLoading: true }));
      if (isBorerDocument) {
        await document?.incrementalModify(doc => ({ ...doc, isLoading: true }));
      }
      const successfulCache = await cacheDocumentInS3(document);
      if (successfulCache) {
        await rxdbDoc?.incrementalModify(doc => {
          return { ...doc, retryCount: DOCUMENT_RETRY_COUNT, isCached: true, isLoading: false };
        });
        if (isBorerDocument) {
          await document?.incrementalModify(doc => {
            return { ...doc, retryCount: DOCUMENT_RETRY_COUNT, isCached: true, isLoading: false };
          });
        }
      }
    } catch (err) {
      await rxdbDoc?.incrementalModify(doc => {
        return {
          ...doc,
          retryCount: isNumber(doc.retryCount) ? (doc.retryCount += 1) : 1,
          isLoading: false,
        };
      });
      if (isBorerDocument) {
        await document?.incrementalModify(doc => {
          return {
            ...doc,
            retryCount: isNumber(doc.retryCount) ? (doc.retryCount += 1) : 1,
            isLoading: false,
          };
        });
      }
      isLoading[document.id] = false;
      if (retryTimeoutms) {
        timeout = setTimeout(async () => {
          await getAttachmentWithRetry(document, retryTimeoutms, numberOfRetries, isBorerDocument);
        }, retryTimeoutms);
      } else {
        await getAttachmentWithRetry(document, retryTimeoutms, numberOfRetries, isBorerDocument);
      }
    }
  } else {
    if (DEBUG) {
      console.info('Retry count exceeded on ', document.fileName, rxdbDoc);
      console.log(document);
    }
    if (rxdbDoc?.isLoading) {
      await rxdbDoc?.incrementalModify(doc => {
        return {
          ...doc,
          isLoading: false,
        };
      });
      if (isBorerDocument) {
        await document?.incrementalModify(doc => {
          return {
            ...doc,
            isLoading: false,
          };
        });
      }
    }
    if (timeout) clearTimeout(timeout);
  }
};
