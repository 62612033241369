import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface InspectionSeverity extends BaseEntity {
  readonly inspectionId: string;
  readonly description: string;
  readonly lowSeverity: number;
  readonly highSeverity: number;
}
export type InspectionSeverityCollection = RxCollection<InspectionSeverity> | null;
export type InspectionSeverityDocument = RxDocument<InspectionSeverity>;

export const inspectionSeveritySchema: RxJsonSchema<InspectionSeverity> = {
  type: 'object',
  version: 0,
  description: 'describes a InspectionSeverity object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    inspectionId: {
      ref: RxdbCollectionName.INSPECTIONS,
      type: 'string',
    },
    description: { type: 'string' },
    lowSeverity: { type: 'number' },
    highSeverity: { type: 'number' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

const inspectionSeverityFeedQuery = print(gql`
  query inspectionSeverityFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    inspectionSeverityFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      inspectionId
      description
      lowSeverity
      highSeverity
      isDeleted
      updatedAt
      version
    }
  }
`);

export const inspectionSeverityPullQueryBuilder = generatePullQuery(
  inspectionSeverityFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
