import dayjs from 'dayjs';

import { WorkOrderWithChildren } from './useWorkOrders';

export enum WorkOrderStatus {
  'On Work_Order' = 'On Work Order',
  'Awaiting Work Order' = 'Awaiting Work Order',
  'Additional Information' = 'Additional Information',
  'Open' = 'Open',
  'RESCHED' = 'RESCHED',
  'WCLOSE' = 'WCLOSE',
  'WENG' = 'WENG',
  'Released' = 'Released',
  'STAGED' = 'STAGED',
  'WMOC' = 'WMOC',
  'Draft' = 'Draft',
  'WPARTDC' = 'WPARTDC',
  'Pending Close' = 'Pending Close',
  'WPARTSINV' = 'WPARTSINV',
  'COMP-WARR' = 'COMP-WARR',
  'WBUDGET' = 'WBUDGET',
  'Unreleased' = 'Unreleased',
  'WPCOND' = 'WPCOND',
  'CPLAN' = 'CPLAN',
  'PLANNING' = 'PLANNING',
  'COMP-CI' = 'COMP-CI',
  'SCHED' = 'SCHED',
  'WPLAN' = 'WPLAN',
  'INPROG' = 'INPROG',
  'WTOOLS' = 'WTOOLS',
  'WSCHED' = 'WSCHED',
  'On Hold' = 'On Hold',
  'WLABOUR' = 'WLABOUR',
  'Active' = 'Active',
  'Cancelled By Approver (COMPLETED)' = 'Cancelled By Approver (COMPLETED)',
  'Complete - No Charges (COMPLETED)' = 'Complete - No Charges (COMPLETED)',
  'Cancelled By PM (COMPLETED)' = 'Cancelled By PM (COMPLETED)',
  'Complete (COMPLETED)' = 'Complete (COMPLETED)',
  'Cancelled (COMPLETED)' = 'Cancelled (COMPLETED)',
  'Closed (COMPLETED)' = 'Closed (COMPLETED)',
  'Rejected (COMPLETED)' = 'Rejected (COMPLETED)',
  'Backlog' = 'Backlog',
  'Complete' = 'Complete',
  'Cancelled' = 'Cancelled',
  'Closed' = 'Closed',
  'Rejected' = 'Rejected',
}

export const sortActiveWorkOrders = (a: WorkOrderWithChildren, b: WorkOrderWithChildren) => {
  if (a.workOrderDoc.status === b.workOrderDoc.status) {
    return a.workOrderDoc.updatedAt > b.workOrderDoc.updatedAt ? -1 : 1;
  }
  if (a.workOrderDoc.status.toLocaleLowerCase() === WorkOrderStatus.Open.toLocaleLowerCase()) {
    return -1;
  }
  if (b.workOrderDoc.status.toLocaleLowerCase() === WorkOrderStatus.Open.toLocaleLowerCase()) {
    return 1;
  }
  if (a.workOrderDoc.status.toLocaleLowerCase() === WorkOrderStatus.INPROG.toLocaleLowerCase()) {
    return -1;
  }
  if (b.workOrderDoc.status.toLocaleLowerCase() === WorkOrderStatus.INPROG.toLocaleLowerCase()) {
    return 1;
  }

  return a.workOrderDoc.updatedAt > b.workOrderDoc.updatedAt ? -1 : 1;
};

export const sortCompletedWorkOrders = (a: WorkOrderWithChildren, b: WorkOrderWithChildren) => {
  if (!a.workOrderDoc.completedDate) return 1;
  if (!b.workOrderDoc.completedDate) return -1;

  return dayjs(b.workOrderDoc.completedDate).diff(a.workOrderDoc.completedDate);
};
