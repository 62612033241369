import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface GroundHazardConditionType extends BaseEntity {
  readonly description: string;
  readonly displayName: string;
  readonly site: string;
  readonly isActive: boolean;
  readonly isSingleEntry: boolean;
  readonly severity: string;
}
export type GroundHazardConditionTypeCollection = RxCollection<GroundHazardConditionType> | null;
export type GroundHazardConditionTypeDocument = RxDocument<GroundHazardConditionType>;

export const groundHazardConditionTypeSchema: RxJsonSchema<GroundHazardConditionType> = {
  type: 'object',
  version: 1,
  description: 'describes a GroundHazardConditionType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayName: { type: 'string' },
    site: {
      ref: 'sites',
      type: 'string',
    },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    isActive: { type: 'boolean' },
    isSingleEntry: { type: 'boolean' },
    severity: { type: ['string', 'null'] },
    version: { type: 'number' },
  },
};

export const groundHazardConditionTypeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardConditionTypeDocument) => {
    return {
      ...oldDoc,
      displayName: oldDoc.description,
    };
  },
};

const hazardConditionTypeFeedQuery = print(gql`
  query hazardConditionTypeFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    hazardConditionTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      description
      displayName
      site
      updatedAt
      isActive
      isSingleEntry
      isDeleted
      severity
      version
    }
  }
`);

export const groundHazardConditionTypePullQueryBuilder = generatePullQuery(
  hazardConditionTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
