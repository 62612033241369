import { rootStore } from '../../mobx-models/Root';
import { SiteId } from '../constants';
import coryTerms from './siteTerms/cory.json';
import laniganTerms from './siteTerms/lanigan.json';
import vanscoyTerms from './siteTerms/vanscoy.json';

const getTermSetBySiteId = (siteId: string): Record<string, string> => {
  switch (siteId) {
    case SiteId.Lanigan:
      return laniganTerms;
    case SiteId.Cory:
      return coryTerms;
    case SiteId.Vanscoy:
      return vanscoyTerms;
    default:
      return laniganTerms;
  }
};

const getTermFromSiteId = (siteId: string, term: string) => {
  const termSet = getTermSetBySiteId(siteId);
  const termValue = termSet[term];
  return termValue;
};

const translate = (term: string, siteId = rootStore.user.siteId): string => {
  // 1. Check if term exists in the requested site and return if it does
  const resultingTerm = getTermFromSiteId(siteId, term);
  if (resultingTerm) return resultingTerm;

  // 2. Pass through term if not found.
  return term;
};

export { translate };
