import dayjs from 'dayjs';

import { BaseEntity } from '../../models/BaseEntity';

export const defaultDoc: BaseEntity = {
  id: '',
  updatedAt: dayjs().startOf('day').subtract(14, 'days').valueOf(),
  isDeleted: false,
  version: 1,
};

export const defaultDoc90Day: BaseEntity = {
  id: '',
  updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
  isDeleted: false,
  version: 1,
};

export const defaultDocAllTime: BaseEntity = {
  id: '',
  updatedAt: 0,
  isDeleted: false,
  version: 1,
};

export default defaultDoc;
