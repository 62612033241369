import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useMst } from '../../mobx-models/Root';
import ShiftStartCard from '../ShiftStartCard';

dayjs.extend(utc);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      touchAction: 'none',
    },
    container: {
      overflowY: 'scroll',
      height: 'calc(100vh - 136px)',
    },
  }),
);

const ShiftStart: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {shiftPicker.currentBorerShiftId !== null && <ShiftStartCard />}
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(ShiftStart);
