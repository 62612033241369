import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface InspectionCategory extends BaseEntity {
  readonly description: string;
  readonly displayOrder: number;
  readonly inspectionId: string;
  readonly detail: string | null;
}
export type InspectionCategoryCollection = RxCollection<InspectionCategory> | null;
export type InspectionCategoryDocument = RxDocument<InspectionCategory>;

export const inspectionCategoriesSchema: RxJsonSchema<InspectionCategory> = {
  type: 'object',
  version: 1,
  description: 'describes a InspectionCategory object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayOrder: { type: 'number' },
    inspectionId: { ref: RxdbCollectionName.INSPECTIONS, type: 'string' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
    detail: { type: ['string', 'null'] },
  },
};

const inspectionCategoryFeedQuery = print(gql`
  query inspectionCategoryFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    inspectionCategoryFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      description
      displayOrder
      inspectionId
      isDeleted
      updatedAt
      version
      detail
    }
  }
`);

export const inspectionCategoriesPullQueryBuilder = generatePullQuery(
  inspectionCategoryFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export const inspectionCategoriesMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: InspectionCategoryDocument) => {
    return { ...oldDoc, detail: null };
  },
};

export default {};
