import { Dayjs } from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { BorerShiftCrewMemberWithRole } from '../BorerShiftCrew/queryBuilder';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';

export interface BorerShift extends BaseEntity {
  readonly isOpen: boolean;
  readonly shiftId: string;
  readonly closedOn: string | null;
}

export type BorerShiftCollection = RxCollection<BorerShift> | null;
export type BorerShiftDocument = RxDocument<BorerShift>;

export interface BorerShiftCrewType {
  id: string;
  borerShiftId: string;
  startDateTime: string | Dayjs;
  endDateTime: string | Dayjs;
  borerShiftCrewMemberInput: BorerShiftCrewMemberWithRole[];
  version: number;
  crewNumber: number;
  index?: number;
}

export interface UpdateBorerShiftCrewInput {
  id: string;
  borerShiftId: string;
  startDateTime: string;
  endDateTime: string;
  borerShiftCrewMemberInput: BorerShiftCrewMemberWithRole[];
  version: number;
}

export const borerShiftSchema: RxJsonSchema<BorerShift> = {
  type: 'object',
  version: 1,
  description: 'describes an borer shift object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    shiftId: { type: 'string' },
    isOpen: { type: 'boolean' },
    closedOn: { type: ['string', 'null'] },
  },
};

export const borerShiftMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftDocument) => {
    return {
      ...oldDoc,
      closedOn: null,
    };
  },
};

const borerShiftFeedQuery = print(gql`
  query borerShiftFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      id
      isDeleted
      version
      updatedAt
      isOpen
      shiftId
      closedOn
    }
  }
`);

export const borerShiftPullQueryBuilder = generatePullQuery(
  borerShiftFeedQuery,
  PullQueryContext.Borer,
);
