import { ApolloQueryResult } from '@apollo/client';

import { client, gql } from './Client';

// Only being used for Inspection Options Migration 0 -> 1
const getInspectionOptionQuery = gql`
  query getInspectionOption($id: ID!) {
    getInspectionOption(id: $id) {
      id
      severityValue
    }
  }
`;

export interface InspectionOption {
  id: string;
  severityValue: number;
}

export interface GetInspectionOptionResult {
  getInspectionOption: InspectionOption;
}

const getInspectionOption = (id: string): Promise<ApolloQueryResult<GetInspectionOptionResult>> =>
  client.query({
    query: getInspectionOptionQuery,
    variables: { id },
    fetchPolicy: 'no-cache',
  });

export { getInspectionOption };
