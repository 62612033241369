import { useCallback, useMemo } from 'react';
import { RxCollection } from 'rxdb';
import { useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useShifts from '../Shifts/useShifts';
import { GroundHazard, GroundHazardDocument } from './queryBuilder';

interface UseGroundHazardOptions {
  startTimeUnix?: number;
  endTimeUnix?: number;
  overrideHazardsToShowArray?: string[];
}

// Document Scenarios
// 1. OverrideHazardsToShowArray array of ids to grab (for use with viewing WFAIs)
// 2. Has no current shift available = show all non remediated where createdOn >= startTimeUnix
//    (This is in case borer is offline without downloading latest shift)
// 3. Has current shift available = show non remediated with an createdOn < shift endTimeUnix (default case)

export const useGroundHazards = ({
  startTimeUnix,
  endTimeUnix,
  overrideHazardsToShowArray,
}: UseGroundHazardOptions) => {
  const { equipment } = useMst();
  const { currentActiveShiftInDB, viewingMostRecentAvailableShift } = useShifts();

  const groundHazardQueryConstructor = useCallback(
    (collection: RxCollection<GroundHazard>) => {
      if (overrideHazardsToShowArray)
        return collection.find({
          selector: {
            id: {
              $in: overrideHazardsToShowArray,
            },
          },
        });

      if (!currentActiveShiftInDB && startTimeUnix)
        return collection.find({
          selector: {
            remediatedOn: { $eq: null },
            borerEquipmentId: { $eq: equipment.selectedBorerId },
            createdOnUnix: { $gte: startTimeUnix },
          },
        });

      if (endTimeUnix)
        return collection.find({
          selector: {
            remediatedOn: { $eq: null },
            borerEquipmentId: { $eq: equipment.selectedBorerId },
          },
        });
    },
    [
      overrideHazardsToShowArray,
      currentActiveShiftInDB,
      equipment.selectedBorerId,
      startTimeUnix,
      endTimeUnix,
    ],
  );

  const { result: groundHazardsForShift }: { result: GroundHazardDocument[] } = useRxData(
    RxdbCollectionName.GROUND_HAZARDS,
    groundHazardQueryConstructor,
  );

  const activeGroundHazards = useMemo(() => {
    if (!overrideHazardsToShowArray && !(!currentActiveShiftInDB && startTimeUnix) && endTimeUnix) {
      return groundHazardsForShift.filter(
        h =>
          h.createdOnUnix < endTimeUnix ||
          (viewingMostRecentAvailableShift && h.remediatedOn === null),
      );
    }

    return groundHazardsForShift.filter(
      h => !!overrideHazardsToShowArray || h.remediatedOn === null,
    );
  }, [
    groundHazardsForShift,
    overrideHazardsToShowArray,
    currentActiveShiftInDB,
    startTimeUnix,
    viewingMostRecentAvailableShift,
    endTimeUnix,
  ]);

  return {
    activeGroundHazards,
  };
};

export default useGroundHazards;
