import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface EquipmentType extends BaseEntity {
  readonly description: string;
}
export type EquipmentTypeCollection = RxCollection<EquipmentType> | null;
export type EquipmentTypeDocument = RxDocument<EquipmentType>;

export const equipmentTypeSchema: RxJsonSchema<EquipmentType> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const equipmentTypeFeedQuery = print(gql`
  query equipmentTypeFeed($lastUpdateAt: Float!, $limit: Int!) {
    equipmentTypeFeed(lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      description
      isDeleted
      updatedAt
      version
    }
  }
`);

export const equipmentTypePullQueryBuilder = generatePullQuery(
  equipmentTypeFeedQuery,
  undefined,
  defaultDocAllTime,
);
