/* eslint-disable react/display-name */
import { DayView, GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@nutrien/cxp-components';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import { SCHEDULER_TITLE_CELL_CLASS } from '../../../../utilities/constants';
import { AddDelayActivityType, DelayActivitiesBlockType } from '../../../../utilities/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      color: theme.palette.common.black,
      border: 'none',
    },
    headerCellHidden: {
      display: 'none',
    },
    cell: {
      border: '0px !important',
    },
    headerCellTitle: {
      color: theme.palette.text.primary,
    },
  }),
);

export interface IAppointment {
  id: string;
  title: string;
  comment: string;
  delayOrActivityTypeId: string;
  typeId: number;
  blockType: DelayActivitiesBlockType;
  type: AddDelayActivityType;
  categoryId?: string;
  startDate: string;
  endDate: string;
  faceChangeCrew: boolean;
  onClick: (
    appt: IAppointment,
    forceEdit: boolean,
    clickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  version: number;
  updatedAt: number;
  status?: string;
  errorMessage?: string;
  associatedDelayIds?: string;
  isCurrent: boolean;
  failedSync?: boolean;
}

export const TimeScaleLayout = ({ ...restProps }: DayView.TimeScaleLayoutProps) => (
  <DayView.TimeScaleLayout {...restProps} height={10} />
);

export const DayScaleCell = () => <td />;

export const formatTime = (nextDate: Date) => {
  if (nextDate.getMinutes() === 0) return dayjs(nextDate).format('h A');
  return '';
};

export const TimeScaleLabel = React.memo(({ ...restProps }: DayView.TimeScaleLabelProps) => {
  return <DayView.TimeScaleLabel {...restProps} formatDate={formatTime} />;
});

export const TimeTableCell = React.memo(({ ...restProps }: DayView.TimeTableCellProps) => {
  const classes = useStyles();

  return <DayView.TimeTableCell {...restProps} className={classes.cell} />;
});

export const GroupingPanelCell = React.memo(
  ({ group, children, ...restProps }: GroupingPanel.CellProps) => {
    const classes = useStyles(group);
    return (
      <GroupingPanel.Cell
        group={{ ...group, text: '' }}
        className={clsx([classes.headerCell, SCHEDULER_TITLE_CELL_CLASS])}
        {...restProps}
      >
        <Typography variant="h4" className={classes.headerCellTitle}>
          {group.text}
        </Typography>
      </GroupingPanel.Cell>
    );
  },
);

export const isScheduleItemAShortEvent = (eventTitle?: string): boolean => {
  return eventTitle?.toLowerCase() === 'short event';
};

export const getScheduleItemTitle = (eventTitle?: string) => {
  if (isScheduleItemAShortEvent(eventTitle)) {
    return 'Not running';
  }
  return eventTitle;
};

export const GroupingPanelCellNightShift = React.memo(
  ({ group, children, ...restProps }: GroupingPanel.CellProps) => {
    const classes = useStyles(group);
    return (
      <GroupingPanel.Cell
        group={{ ...group, text: '' }}
        className={classes.headerCellHidden}
        {...restProps}
      >
        <></>
      </GroupingPanel.Cell>
    );
  },
);

export const GroupingPanelRow = React.memo(({ ...restProps }: GroupingPanel.RowProps) => {
  return <GroupingPanel.Row style={{ color: 'red !important' }} {...restProps} />;
});
