import { Grid } from '@material-ui/core';
import { i18n, Icons, Typography } from '@nutrien/cxp-components';
import dayjs, { Dayjs, extend as dayJsExtend } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { GroundHazardCollection } from '../../rxdb/GroundHazard/queryBuilder';
import {
  GroundHazardAttachment,
  GroundHazardAttachmentCollection,
} from '../../rxdb/GroundHazardAttachment/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../../rxdb/rxdbUtilityFunctions';
import { useFileStorage, useOnlineStatus } from '../../utilities';
import { useNotification } from '../../utilities/hooks/useNotification';
import { getFormattedImages } from '../../utilities/utilityFunctions';
import AspectRatio from '../AspectRatio';
import { getFileUrl, ImageMetadataWithUrl } from '../CacheUpdateHandler/documentUtilities';
import Thumbnail from '../Thumbnail';
import { ImageMetadata } from '../Thumbnail/Thumbnail';
import { useStyles } from './HazardPanelPhotos.styles';

dayJsExtend(isSameOrBefore);

interface Props {
  groundHazardId: string;
  hideAddPhotoAction?: boolean;
  hidePhotosBeforeDate?: Dayjs;
  attachmentPrefix?: string;
}

// This displays on the list of hazards
const HazardPanelPhotos: React.FC<Props> = ({
  groundHazardId,
  hidePhotosBeforeDate,
  hideAddPhotoAction,
  attachmentPrefix,
}: Props) => {
  const classes = useStyles();
  const { uploadImage } = useFileStorage();
  const { errorNotification } = useNotification();
  const online = useOnlineStatus();

  const [imageUrls, setImageUrls] = useState<ImageMetadataWithUrl[]>([]);

  const inputKeyName = `selectImageHazardPhotoPanel-${groundHazardId}`;

  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const attachmentCollection: GroundHazardAttachmentCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
  );

  const getHazardImagesUrls = async () => {
    // Get image attachment names from data store
    let imagesToShow: ImageMetadata[] = [];
    try {
      if (attachmentCollection) {
        const response = await attachmentCollection
          .find({
            selector: {
              fileName: { $regex: `.*${groundHazardId}*.` },
            },
          })
          .sort({ updatedAt: 'asc' })
          .exec();

        imagesToShow = await Promise.all(
          response
            .filter(doc => {
              if (!hidePhotosBeforeDate) return true;
              if (!doc.createdOn) return true;
              return dayjs(doc.createdOn).isSameOrBefore(hidePhotosBeforeDate);
            })
            .map(doc => getFileUrl(doc, online)),
        );

        setImageUrls(imagesToShow);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: HazardPanelPhotos.tsx ~ line 99 ~ getHazardImagesUrls ~ error',
        error,
      );
      throw error;
    }
  };

  const onOpenUpload = () => {
    document.getElementById(inputKeyName)?.click();
  };

  const createAttachment = async (hazardId: string, fileName: string) => {
    const attachment: GroundHazardAttachment = {
      ...generateBaseEntityWithCreatedOn(),
      fileName,
      groundHazardId: hazardId,
      createdBy: null,
    };
    if (attachmentCollection && hazardId) await attachmentCollection.insert(attachment);
    return attachment.id;
  };

  const updateGroundHazard = async (attachmentIds: string[]) => {
    try {
      if (groundHazardCollection) {
        await groundHazardCollection
          .findOne()
          .where('id')
          .eq(groundHazardId)
          .update({
            $set: {
              updatedAt: getUnixMillisecondTimestamp(),
              attachments: attachmentIds,
            },
          });
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: HazardPanelPhotos.tsx ~ line 179 ~ updateGroundHazard ~ error',
        error,
      );
    }
  };

  const handleImageUploads = async (fileList: FileList) => {
    const files = attachmentPrefix
      ? getFormattedImages(attachmentPrefix, [...fileList], imageUrls)
      : [...fileList];

    const currentImageUrls = imageUrls;
    setImageUrls([
      ...currentImageUrls,
      ...files.map(file => {
        return {
          url: 'LOADING',
          fileName: `${groundHazardId}/${file.name}`,
          isRecentUpload: true,
        };
      }),
    ]);

    try {
      const imageResults = await Promise.all(files.map(file => uploadImage(groundHazardId, file)));

      const attachmentIds: string[] = [];
      await Promise.all(
        imageResults.map(async imageResult => {
          const id = await createAttachment(groundHazardId, imageResult.key);
          attachmentIds.push(id);
        }),
      );

      await updateGroundHazard(attachmentIds);

      await getHazardImagesUrls();
    } catch (error) {
      console.log(
        '🚀 ~ file: HazardPanelPhotos.tsx ~ line 218 ~ handleImageUploads ~ error',
        error,
      );
      errorNotification(i18n.t('Unable to upload some images.'));
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) handleImageUploads(files);
  };

  useEffect(() => {
    let subscription: Subscription | undefined;
    if (attachmentCollection) {
      subscription = attachmentCollection
        .find({
          selector: {
            fileName: { $regex: `.*${groundHazardId}*.` },
          },
        })
        .$.subscribe(() => {
          getHazardImagesUrls();
        });
    }
    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [attachmentCollection, groundHazardId]);

  return (
    <>
      {groundHazardId && (
        <Grid container item xs={12} data-cy="HazardPanelPhotos" spacing={2} justify="flex-end">
          {imageUrls.length > 0 ? (
            imageUrls.map(data => (
              <Thumbnail
                url={data.url}
                key={`hazard-panel-thumbnail-${data.fileName}`}
                name={data.fileName.split('/')[1]}
                attachmentId={data.fileName}
                isRecentUpload={data.isRecentUpload}
              />
            ))
          ) : (
            <Grid item xs={6} />
          )}
          {!hideAddPhotoAction && (
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              onClick={onOpenUpload}
              xs={6}
              id="add-photo-button"
            >
              <div className={classes.photoCardAdd}>
                <AspectRatio ratio={1 / 1}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Icons.PlusCircleFeather className={classes.plusIcon} color="primary" />
                    <Typography
                      style={{ fontSize: '10px' }}
                      className={classes.photoCardAddText}
                      variant="body1"
                      data-testid="addPhoto"
                    >
                      {i18n.t('Add Photo')}
                    </Typography>
                  </div>
                </AspectRatio>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/jpeg"
              onChange={evt => onChange(evt)}
              multiple
              hidden
              id={inputKeyName}
              onClick={event => {
                event.target.value = null;
              }}
              data-testid="hazardPanelPhotos"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HazardPanelPhotos;
