import { Grid } from '@material-ui/core';
import { Button, ExpansionPanel, i18n, Icons, TimePicker2 } from '@nutrien/cxp-components';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { BorerShiftCrewMemberEmployee } from '@/rxdb/BorerShiftCrew/queryBuilder';
import { BorerShiftCrewMemberRole } from '@/rxdb/BorerShiftCrewMemberRole/queryBuilder';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import { USER_TIMEZONE } from '../../utilities/hooks/useDateFormatters';
import { getShiftCorrectedTime } from '../../utilities/shiftTimeUtilities';
import CrewMember from '../CrewMember';
import { useStyles } from './EditCrewDetailsPanel.styles';

dayjs.extend(utc);
export enum CrewEmployeePanelOperation {
  AddEmployee,
  DeleteEmployee,
}

export enum ValidationOperation {
  Add,
  Remove,
}

export interface AugmentedEmployee {
  id: string;
  firstName?: string;
  lastName?: string;
  positionName?: string;
  crewName?: string;
}
interface Props {
  crewDetails: BorerShiftCrewType;
  onCrewEmployeeChange: (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => void;
  onCrewDetailsChange: (
    key: keyof BorerShiftCrewType,
    value: BorerShiftCrewMemberEmployee | Dayjs | string,
  ) => void;
  onCrewEmployeeRoleChange: (index: number, value: BorerShiftCrewMemberRole | null) => void;
  setValidationError: Dispatch<SetStateAction<boolean>>;
  validationIndex: number;
  employeesList: AugmentedEmployee[];
}

const EditCrewDetailsPanel: React.FC<Props> = ({
  crewDetails,
  onCrewEmployeeChange,
  onCrewDetailsChange,
  onCrewEmployeeRoleChange,
  setValidationError,
  validationIndex,
  employeesList,
}: Props) => {
  const [crewValidationErrors, setCrewValidationErrors] = useState(
    new Array(crewDetails?.borerShiftCrewMemberInput?.length),
  );

  const classes = useStyles();
  const { shiftPicker } = useMst();

  const [errors, setErrors] = useState({
    startTime: '',
    endTime: '',
  });
  useEffect(() => {
    setValidationError(
      crewValidationErrors.find(e => !!e) || !!errors.startTime || !!errors.endTime,
    );
  }, [errors, crewValidationErrors]);

  useEffect(() => {
    // Start time validation
    if (validationIndex >= 1) {
      if (
        dayjs
          .tz(crewDetails.startDateTime, USER_TIMEZONE)
          .diff(shiftPicker.selectedShift?.shiftStart) < 0 ||
        dayjs
          .tz(crewDetails.startDateTime, USER_TIMEZONE)
          .diff(shiftPicker.selectedShift?.shiftEnd) > 0
      ) {
        setErrors(prev => {
          return { ...prev, startTime: 'Must be within shift hours' };
        });
      } else {
        setErrors(prev => {
          return { ...prev, startTime: '' };
        });
      }
    }
  }, [validationIndex, crewDetails.startDateTime]);

  useEffect(() => {
    // End time validation
    if (validationIndex >= 1) {
      if (dayjs(crewDetails.endDateTime).diff(crewDetails.startDateTime) <= 0) {
        setErrors(prev => {
          return { ...prev, endTime: 'Must be after start time' };
        });
      } else if (
        dayjs(crewDetails.endDateTime).diff(shiftPicker.selectedShift?.shiftStart) < 0 ||
        dayjs(crewDetails.endDateTime).diff(shiftPicker.selectedShift?.shiftEnd) > 0
      ) {
        setErrors(prev => {
          return { ...prev, endTime: 'Must be within shift hours' };
        });
      } else {
        setErrors(prev => {
          return { ...prev, endTime: '' };
        });
      }
    }
  }, [validationIndex, crewDetails.endDateTime, crewDetails.startDateTime]);

  const setCrewValidationError = (index: number, error: boolean | ValidationOperation) => {
    if (error === ValidationOperation.Add) {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy.push(true);
        return copy;
      });
    } else if (error === ValidationOperation.Remove) {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy.splice(index, 1);
        return copy;
      });
    } else {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy[index] = error;
        return copy;
      });
    }
  };

  const onAddCrew = () => {
    setCrewValidationError(-1, ValidationOperation.Add);
    onCrewEmployeeChange(-1, CrewEmployeePanelOperation.AddEmployee);
  };

  const onStartTimeChanged = useCallback(
    (date: Dayjs) => {
      const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
      onCrewDetailsChange('startDateTime', adjustedDate);
    },
    [shiftPicker.Date, shiftPicker.Type],
  );

  const onEndTimeChanged = useCallback(
    (date: Dayjs) => {
      const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
      onCrewDetailsChange('endDateTime', adjustedDate);
    },
    [shiftPicker.Date, shiftPicker.Type],
  );

  return (
    <ExpansionPanel
      className={classes.panel}
      title={`${i18n.t('Operating crew')}${crewDetails.crewNumber === 2 ? ' 2' : ''}`}
      key="crew-details"
      data-cy="crew-details"
      TransitionProps={{ unmountOnExit: true }}
      expansionPanelSummaryProps={{
        expandIcon: undefined,
      }}
      expanded
      panelContent={
        <Grid container>
          <Grid item container direction="column" xs={12}>
            <Grid container item spacing={1}>
              <Grid item xs={8}>
                <TimePicker2
                  value={dayjs(crewDetails.startDateTime).tz(USER_TIMEZONE)}
                  label={i18n.t('Start time')}
                  required
                  onTimeChanged={onStartTimeChanged}
                  error={!!errors.startTime}
                  errorText={errors.startTime}
                  useBrowserTimePicker
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={8}>
                <TimePicker2
                  value={dayjs(crewDetails.endDateTime).tz(USER_TIMEZONE)}
                  label={i18n.t('End time')}
                  required
                  onTimeChanged={onEndTimeChanged}
                  error={!!errors.endTime}
                  errorText={errors.endTime}
                  useBrowserTimePicker
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {crewDetails.borerShiftCrewMemberInput.map((crewMemberDetails, crewMemberIndex) => {
                return (
                  <CrewMember
                    crewMemberDetails={crewMemberDetails}
                    crewMemberIndex={crewMemberIndex}
                    crewMemberList={crewDetails.borerShiftCrewMemberInput}
                    employeeList={employeesList}
                    onCrewEmployeeChange={onCrewEmployeeChange}
                    onCrewEmployeeRoleChange={onCrewEmployeeRoleChange}
                    validationIndex={validationIndex}
                    setValidationError={setCrewValidationError}
                    key={crewMemberDetails?.employee?.reactKey || crewMemberDetails?.employee?.id}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item className={classes.buttonSpacer}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              noMinHeight
              startAdornment={<Icons.PlusFeather className={classes.iconSpacer} />}
              onClick={onAddCrew}
              data-cy="add-another-crew-button"
              id="add-another-crew-button"
            >
              {i18n.t('Add another operator')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default EditCrewDetailsPanel;
