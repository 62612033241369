export enum ApiStatus {
  NOT_CALLED = 'NOT_CALLED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum Operation {
  READ = 'Read',
  WRITE = 'Write',
}

export default { ApiStatus, Operation };
