import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete, ToggleButtonGroup } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  popper: {
    zIndex: 999999,
  },
});

interface Props {
  value: any;
  onToggleChange: (event: any, value: any) => void;
  onDropdownChange: (event: any, value: any) => void;
  exclusive: boolean;
  list: any[];
  label?: string;
  dropDownOptionLabel: (item: any) => string;
  dropdownClassName: any;
  dropdownGetValue?: (val: any) => void;
  viewOnly?: boolean;
  showToggle?: (list: any[]) => boolean;
}

// eslint-disable-next-line import/prefer-default-export
const SmartToggleButtonGroup: React.FC<Props> = ({
  value,
  onToggleChange,
  onDropdownChange,
  dropdownGetValue,
  exclusive,
  list,
  label,
  dropDownOptionLabel,
  dropdownClassName,
  viewOnly = false,
  showToggle,
  ...rest
}: Props) => {
  const classes = useStyles();

  const [renderAsToggle, setRenderAsToggle] = useState<boolean>(true);

  useEffect(() => {
    if (list) {
      if (showToggle) {
        setRenderAsToggle(showToggle(list));
      } else if (list.length > 5) {
        setRenderAsToggle(false);
      }
    }
  }, [list]);

  const getValue = () => {
    if (dropdownGetValue) {
      return dropdownGetValue(value);
    }
    return value;
  };

  return (
    <>
      {renderAsToggle && (
        <ToggleButtonGroup
          id={rest.id}
          name={rest.id}
          value={value}
          onChange={onToggleChange}
          aria-label=""
          exclusive={exclusive}
        >
          {rest.children}
        </ToggleButtonGroup>
      )}
      {!renderAsToggle && (
        <div className={dropdownClassName}>
          <AutoComplete
            id={rest.id}
            name={rest.id}
            list={list}
            label={label}
            required
            getOptionLabel={dropDownOptionLabel}
            onChange={onDropdownChange}
            value={getValue()}
            showCaret
            classes={{ popper: classes.popper }}
            autoSelect={false}
            disabled={viewOnly}
          />
        </div>
      )}
    </>
  );
};

export default SmartToggleButtonGroup;
