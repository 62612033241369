function versionCompare(v1: string, v2: string): number {
  const v1parts = v1.split('.').map(Number);
  const v2parts = v2.split('.').map(Number);
  const partsLength = Math.max(v1parts.length, v2parts.length);

  for (let i = 0; i < partsLength; i++) {
    const v1Part = v1parts[i] || 0;
    const v2Part = v2parts[i] || 0;

    if (v1Part !== v2Part) {
      return v1Part > v2Part ? 1 : -1; // 1 means v1 is greater than v2
    }
  }
  return 0; // equal
}

export { versionCompare };
