import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Room extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly length: number;
  readonly panel: string;
  readonly miningPattern: string;
}
export type RoomCollection = RxCollection<Room> | null;
export type RoomDocument = RxDocument<Room>;

export const roomSchema: RxJsonSchema<Room> = {
  type: 'object',
  version: 1,
  description: 'describes a Room object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    length: { type: 'number' },
    panel: { ref: RxdbCollectionName.PANELS, type: ['string', 'null'] },
    version: { type: 'number' },
    miningPattern: {
      ref: RxdbCollectionName.MINING_PATTERN,
      type: ['string', 'null'],
    },
  },
};

export const roomMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: RoomDocument) => ({ ...oldDoc, miningPattern: null }),
};

const roomFeedQuery = print(gql`
  query roomFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    roomFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      description
      id
      isDeleted
      isActive
      length
      panel
      updatedAt
      version
      miningPattern
    }
  }
`);

export const roomsPullQueryBuilder = generatePullQuery(
  roomFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
