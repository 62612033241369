import dayjs from 'dayjs';
import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface PanelDrawingComment extends BaseEntity {
  readonly comment: string;
  readonly panelDrawingId: string;
}
export type PanelDrawingCommentCollection = RxCollection<PanelDrawingComment> | null;
export type PanelDrawingCommentDocument = RxDocument<PanelDrawingComment>;

export const panelDrawingCommentSchema: RxJsonSchema<PanelDrawingComment> = {
  type: 'object',
  version: 0,
  description: 'describes a PanelDrawingComment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    comment: { type: 'string' },
    panelDrawingId: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

const panelDrawingCommentFeedQuery = print(gql`
  query panelDrawingCommentFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    panelDrawingCommentFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      isDeleted
      updatedAt
      version
      panelDrawingId
      comment
    }
  }
`);

export const panelDrawingCommentPullQueryBuilder = generatePullQuery(
  panelDrawingCommentFeedQuery,
  PullQueryContext.Borer,
);

const setPanelDrawingCommentMutation = print(gql`
  mutation setPanelDrawingComment(
    $id: ID!
    $panelDrawingId: ID!
    $comment: String!
    $isDeleted: Boolean!
    $version: Int!
  ) {
    setPanelDrawingComment(
      input: {
        id: $id
        version: $version
        isDeleted: $isDeleted
        panelDrawingId: $panelDrawingId
        comment: $comment
      }
    )
  }
`);

type SetPanelDrawingCommentInput = {
  id: string;
  panelDrawingId: string;
  comment: string;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapBorerShiftCrewDocToInput = (
  doc: PanelDrawingCommentDocument,
): SetPanelDrawingCommentInput => {
  return {
    id: doc.id,
    panelDrawingId: doc.panelDrawingId,
    comment: doc.comment,
    isDeleted: doc.isDeleted,
    version: doc.version,
    createdOn: null,
    modifiedOn: dayjs().toISOString(),
  };
};

export const panelDrawingCommentPushQueryBuilder = generatePushQuery(
  setPanelDrawingCommentMutation,
  (doc: PanelDrawingCommentDocument) => mapBorerShiftCrewDocToInput(doc),
);
