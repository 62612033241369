import { createStyles, makeStyles } from '@material-ui/core';
import { darkTheme } from '@nutrien/cxp-components';
import React from 'react';

interface Props {
  horizontalMargin?: number;
  verticalMargin?: number;
}

const PanelBreak: React.FC<Props> = ({ horizontalMargin = 8, verticalMargin = 16 }: Props) => {
  const useStyles = makeStyles(() =>
    createStyles({
      panelBreak: {
        width: '100%',
        margin: `${verticalMargin}px ${horizontalMargin}px ${verticalMargin}px ${horizontalMargin}px`,
        border: 'none',
        borderTop: `1px solid ${darkTheme.palette.action.disabledBackground}`,
      },
    }),
  );
  const classes = useStyles();

  return <hr className={classes.panelBreak} />;
};

export default PanelBreak;
