import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, MaterialPalette } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

interface Props {
  selectedValueId?: string;
  option: any;
  handleValueChanged: (optionId: string, valueId: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: MaterialPalette.background.paper2,
      '& h6': {
        fontSize: '20px',
        color: MaterialPalette.tertiary.main,
      },
    },
    checkboxLabel: {
      width: '100%',
    },
    optionsContainer: {
      paddingLeft: '30px',
      '& > div > span': {
        color: MaterialPalette.primary.main,
      },
    },
    nested: {
      paddingLeft: '30px',
    },
  }),
);

const GroundControlCheckboxesOption = ({ option, selectedValueId, handleValueChanged }: Props) => {
  const classes = useStyles();

  const [checkedValue, setCheckedValue] = useState<string>(option.values[0].value);

  useEffect(() => {
    const val = option.values.find(x => x.id === selectedValueId);
    if (val) {
      setCheckedValue(val.value);
    }
  }, [selectedValueId]);

  const handleGroundControlChecked = (val: string) => {
    setCheckedValue(val);
    handleValueChanged(option.id, option.values.find(x => x.value === val).id);
  };

  return (
    <>
      <Grid item container xs={12} key={option.id} className={classes.nested}>
        {option.values.map((val, index) => {
          return (
            <Grid item xs={12} key={`${val.id}-${index}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      handleGroundControlChecked(val.value);
                    }}
                    name="remediation-complete-checkbox"
                    checked={checkedValue === val.value}
                  />
                }
                label={val.value}
                className={classes.checkboxLabel}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default GroundControlCheckboxesOption;
