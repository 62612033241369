import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';

export const shiftSchema: RxJsonSchema<Shift> = {
  type: 'object',
  version: 0,
  description: 'describes an shift object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    name: { type: 'string' },
    day: { type: 'string' },
    start: { type: 'string' },
    end: { type: 'string' },
  },
};

export interface Shift extends BaseEntity {
  readonly name: string;
  readonly day: string;
  readonly start: string;
  readonly end: string;
}

export type ShiftCollection = RxCollection<Shift> | null;
export type ShiftDocument = RxDocument<Shift>;

const shiftFeedQuery = print(gql`
  query shiftFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    shiftFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      isDeleted
      version
      updatedAt
      name
      day
      start
      end
    }
  }
`);

export const shiftPullQueryBuilder = generatePullQuery(shiftFeedQuery, PullQueryContext.Site);
