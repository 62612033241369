import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  GroupedAutoComplete,
  i18n,
  IconButton,
  Icons,
  MaterialPalette,
  TextField,
  TimePicker2,
  Typography,
} from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import { EmployeeSummary } from '@/rxdb/Employees/useEmployees';

import { BorerActivityType } from '../../rxdb/BorerActivityType/queryBuilder';
import { DelayActivityTypeCategory } from '../../rxdb/DelayActivityTypeCategory/queryBuilder';
import { FormField } from '../../rxdb/Employees/formHelper';
import { EmployeeDocument } from '../../rxdb/Employees/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { USER_TIMEZONE } from '../../utilities/hooks/useDateFormatters';
import { translate } from '../../utilities/siteLanguageTranslation/siteLanguageTranslation';
import { sortByFirstName } from '../../utilities/sortHelper';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
      margin: '0 !important',
    },
    comment: {
      marginBottom: '10px',
    },
  }),
);

interface Props {
  startTime: Dayjs;
  onStartTimeChanged: (time: Dayjs) => void;
  startErrorText: string;
  endErrorText: string;
  endTime: Dayjs;
  onEndTimeChanged: (time: Dayjs) => void;
  category?: DelayActivityTypeCategory;
  onCategorySelected: (category: DelayActivityTypeCategory) => void;
  activityType?: BorerActivityType;
  onActivityTypeSelected: (activityType?: BorerActivityType) => void;
  comment?: string;
  onCommentChanged: (comment: string) => void;
  completedBy: EmployeeDocument[];
  completedByFields: FormField<EmployeeDocument | undefined>[];
  handleAddCompletedByField: () => void;
  handleUpdateSelectedEmployeeField: (index: number, operator: EmployeeDocument | null) => void;
  onRemoveCompletedByField: (index: number) => void;
  categories: DelayActivityTypeCategory[];
  activityTypes: BorerActivityType[];
  allEmployees: EmployeeSummary[];
  readOnly: boolean;
}

interface SelectedCompletedById {
  [key: string]: string | undefined;
}

const AddBorerActivityCard: React.FC<Props> = ({
  startTime,
  onStartTimeChanged,
  startErrorText,
  endTime,
  onEndTimeChanged,
  onCategorySelected,
  activityType,
  onActivityTypeSelected,
  comment,
  onCommentChanged,
  completedByFields,
  handleAddCompletedByField,
  handleUpdateSelectedEmployeeField,
  onRemoveCompletedByField,
  categories,
  activityTypes,
  allEmployees,
  endErrorText,
  readOnly,
}: Props) => {
  const classes = useStyles();
  const { convertMinutesToShortenedTimeString } = useDateFormatters();

  const [duration, setDuration] = useState<string>('0 min');
  const [selectedCompletedByIndex, setSelectedCompletedByIndex] = useState<SelectedCompletedById>(
    {},
  );

  useEffect(() => {
    const newSelectedCompletedByIndex = { ...selectedCompletedByIndex };
    completedByFields.forEach((operator, idx) => {
      if (operator) {
        newSelectedCompletedByIndex[idx] = operator.value?.id;
      } else {
        delete newSelectedCompletedByIndex[idx];
      }
    });
    setSelectedCompletedByIndex(newSelectedCompletedByIndex);
  }, [completedByFields]);

  const onTypeChanged = (event: any, value: any) => {
    const delayCategory = categories.find(
      cat => cat.id !== undefined && cat.id === value?.delayActivityTypeCategory,
    );
    onCategorySelected(delayCategory);
    onActivityTypeSelected(value);
  };

  const calculateDuration = useCallback(() => {
    const diff = endTime.diff(startTime, 'minute');
    const dur = convertMinutesToShortenedTimeString(diff);
    setDuration(dur);
  }, [startTime, endTime, convertMinutesToShortenedTimeString]);

  useEffect(() => {
    calculateDuration();
  }, [startTime, endTime, calculateDuration]);

  const onUpdateSelectedEmployee = (index: number, employee: EmployeeDocument | null) => {
    handleUpdateSelectedEmployeeField(index, employee);
  };

  const handleRemoveCompletedByField = (index: number) => {
    onRemoveCompletedByField(index);
  };

  const getCompletedByList = () => {
    const result = allEmployees.filter(x => {
      return !completedByFields.map(y => y.value?.id).includes(x.id);
    });
    return result.sort(sortByFirstName);
  };

  const renderCompletedByFields = () => {
    return completedByFields.map((formField: FormField<EmployeeDocument | undefined>, idx) => {
      return (
        <Grid container item key={`completed-by-${idx}`}>
          <Grid item xs={idx > 0 ? 10 : 12}>
            <GroupedAutoComplete
              autoSelect={false}
              autoHighlight={false}
              list={getCompletedByList()}
              label={idx === 0 ? i18n.t('Completed by (optional)') : undefined}
              getOptionLabel={emp => {
                if (emp) {
                  return `${emp.firstName} ${emp.lastName}`;
                }
                return '';
              }}
              onChange={(event, emp: EmployeeDocument | null) => {
                onUpdateSelectedEmployee(idx, emp);
              }}
              value={formField.value}
              showCaret={!formField.value}
              disabled={readOnly}
              groupByProperty="header"
              primaryProperty="fullName"
            />
          </Grid>
          {idx > 0 && (
            <Grid container item xs={2} justify="center" alignContent="center">
              <IconButton
                style={{ color: MaterialPalette.error.main }}
                onClick={() => handleRemoveCompletedByField(idx)}
                id={`remove-completed-by-${idx}`}
                disabled={readOnly}
              >
                <Icons.Trash2Feather color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">{translate('Shift activity')}</Typography>
        </Grid>
        <br />
        <br />
        <Grid item xs={12}>
          <TimePicker2
            value={startTime.tz(USER_TIMEZONE)}
            label={i18n.t('Start time')}
            onTimeChanged={(date: Dayjs) => {
              onStartTimeChanged(date);
            }}
            error={startErrorText !== ''}
            errorText={startErrorText}
            useBrowserTimePicker
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TimePicker2
            value={endTime.tz(USER_TIMEZONE)}
            label={i18n.t('End time')}
            onTimeChanged={(date: Dayjs) => {
              onEndTimeChanged(date);
            }}
            error={endErrorText !== ''}
            errorText={endErrorText}
            useBrowserTimePicker
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            {i18n.t('Calculated duration')}: {duration}
          </Typography>
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <GroupedAutoComplete
            id="activity-selector"
            autoSelect={false}
            list={activityTypes || []}
            label="Type"
            getOptionLabel={(option: BorerActivityType) => option.name}
            onChange={onTypeChanged}
            value={activityType}
            showCaret
            groupByProperty="categoryName"
            primaryProperty="name"
            secondaryProperty="categoryName"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} className={classes.comment}>
          <TextField
            label={i18n.t('Comment (optional)')}
            multiline
            rows={2}
            onChange={event => {
              onCommentChanged(event.target.value);
            }}
            value={comment}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          {renderCompletedByFields()}
        </Grid>
        <Grid item container xs={12}>
          <Button
            color="primary"
            style={{ width: '90%' }}
            variant="outlined"
            startAdornment={<Icons.PlusFeather color="primary" />}
            onClick={() => handleAddCompletedByField()}
            id="add-completed-by"
            disabled={readOnly}
          >
            {i18n.t('Add another person')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AddBorerActivityCard;
