import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import { HazardLogCollection, HazardLogDocument } from './queryBuilder';

export const useHazardLog = () => {
  const hazardLogCollection: HazardLogCollection = useRxCollection(RxdbCollectionName.HAZARD_LOGS);

  const [hazardLogsInitialized, setHazardLogsInitialized] = useState(false);

  useEffect(() => {
    if (hazardLogCollection) setHazardLogsInitialized(true);
  }, [hazardLogCollection]);

  const listHazardLogsFor = async (groundHazardId: string): Promise<HazardLogDocument[]> => {
    if (!RxdbManager.instance?.db?.collections[RxdbCollectionName.HAZARD_LOGS]) {
      return [];
    }
    try {
      const types = await RxdbManager.instance?.db?.collections[RxdbCollectionName.HAZARD_LOGS]
        .find()
        .where('groundHazard')
        .eq(groundHazardId)
        .exec();
      return types;
    } catch (error) {
      console.log('🚀 ~ file: useHazardLog.ts ~ line 34 ~ listHazardLogsFor ~ error', error);
      throw error;
    }
  };

  const saveHazardLog = async (hazardLog: HazardLogDocument) => {
    if (hazardLogCollection !== null) {
      await hazardLogCollection.insert(hazardLog);
    }
    if (!hazardLog?.groundHazard) {
      throw new Error('Missing Hazard Id');
    }
  };

  return {
    listHazardLogsFor,
    saveHazardLog,
    hazardLogsInitialized,
    hazardLogCollection,
  };
};

export default useHazardLog;
