import dayjs from 'dayjs';

import { Employee } from '@/rxdb/Employees/queryBuilder';
import { PopulatedLocation } from '@/rxdb/Locations/useLocations';

import { MiningCutSequencePassObj } from '../rxdb/MiningCut/queryBuilder';
import { EnrichedSupply } from '../rxdb/Supply/queryBuilder';
import { MiningMethod } from './constants';

export function uniq<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function uniqBy<T>(a: T[], key: string): T[] {
  // returns last occurence of the object that has a duplicate key
  return [...new Map(a.map(item => [item[key], item])).values()];
}

export const sortAscendingByDisplayOrder = (a, b) => {
  return a.displayOrder - b.displayOrder;
};

export const sortDescendingByCreatedOn = (a: any, b: any) => {
  return dayjs(b.createdOn).unix() - dayjs(a.createdOn).unix();
};

export const sortAscendingByCreatedOn = (a: any, b: any) => {
  return dayjs(a.createdOn).unix() - dayjs(b.createdOn).unix();
};

export const sortDescendingByDay = (a: any, b: any) => {
  return dayjs(b.day).unix() - dayjs(a.day).unix();
};

export const sortDescendingByInspectionResultCreatedOn = (a: any, b: any) => {
  return dayjs(b.inspectionResult.createdOn).unix() - dayjs(a.inspectionResult.createdOn).unix();
};

export const sortDescendingByUpdatedAt = (a: any, b: any) => {
  return b.updatedAt - a.updatedAt;
};

export const sortByFirstName = (a: Employee, b: Employee) => {
  if (a?.firstName?.toLowerCase() < b?.firstName?.toLowerCase()) return -1;
  if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase()) return 1;
  return 0;
};

export const sortBySupplyItemDescription = (a: any, b: any) => {
  if (a.supplyItemDescription.toLowerCase() < b.supplyItemDescription.toLowerCase()) return -1;
  if (a.supplyItemDescription.toLowerCase() > b.supplyItemDescription.toLowerCase()) return 1;
  return 0;
};

export const sortAlphabeticallyByProperty = (
  array: Record<string, any>[] | undefined,
  propertyName: string,
) => {
  if (!array) return array || [];
  return array.sort((a, b) => {
    if (a[propertyName]?.toLowerCase() < b[propertyName]?.toLowerCase()) return -1;
    if (a[propertyName]?.toLowerCase() > b[propertyName]?.toLowerCase()) return 1;
    return 0;
  });
};

export const customStepSort = (
  a: MiningCutSequencePassObj,
  b: MiningCutSequencePassObj,
): number => {
  // Move Universal cuts to bottom
  if (a.miningPatternName !== b.miningPatternName) {
    if (a.miningPatternName === MiningMethod.UNIVERSAL) return 1;
    if (b.miningPatternName === MiningMethod.UNIVERSAL) return -1;
  }

  // Sort based on miningCut order
  if (
    typeof a.miningCut?.order === 'number' &&
    typeof b.miningCut?.order === 'number' &&
    a.miningCut.order !== b.miningCut.order
  ) {
    return a.miningCut.order - b.miningCut.order;
  }

  const aSplit = a.sequencePassString?.split(' ');
  const bSplit = b.sequencePassString?.split(' ');
  const aPrefix = aSplit?.[0]?.toLowerCase();
  const bPrefix = bSplit?.[0]?.toLowerCase();

  // Sort based on prefix
  if (!!aPrefix && !!bPrefix && aPrefix !== bPrefix) {
    return aPrefix.localeCompare(bPrefix);
  }
  const aStepNumber = Number(aSplit?.[1]?.toLowerCase());
  const bStepNumber = Number(bSplit?.[1]?.toLowerCase());

  // If prefix is the same, sort based on step number
  if (aStepNumber && bStepNumber && aStepNumber !== bStepNumber) {
    return aStepNumber - bStepNumber;
  }
  const aPass = aSplit?.[2]?.toLowerCase();
  const bPass = bSplit?.[2]?.toLowerCase();
  const aPassNumber = Number(aPass?.match(/\d+/g)?.[0]);
  const bPassNumber = Number(bPass?.match(/\d+/g)?.[0]);

  // If step number is the same, sort based on pass number
  if (aPassNumber && bPassNumber && aPassNumber !== bPassNumber) {
    return aPassNumber - bPassNumber;
  }

  // If all else fails, sort based on sequence name
  return (a.sequencePassString?.toLocaleLowerCase() || '').localeCompare(
    b.sequencePassString?.toLocaleLowerCase() || '',
  );
};

export const sortAscendingByUpdatedAt = (a: any, b: any) => {
  return a.updatedAt - b.updatedAt;
};

export const sortRoomOptions = (options: PopulatedLocation[]) => {
  return options.sort((a, b) => {
    const aLabel = a.block?.description || '' + a.panel.description + a.room.description;
    const bLabel = b.block?.description || '' + b.panel.description + b.room.description;

    if (aLabel.toLowerCase() < bLabel.toLowerCase()) return -1;
    if (aLabel.toLowerCase() > bLabel.toLowerCase()) return 1;
    return 0;
  });
};

export const sortDescriptionWithNumberStrings = (
  a: { description: string | number },
  b: { description: string | number },
) => {
  if (Number(a.description) && Number(b.description)) {
    return Number(a.description) - Number(b.description);
  }
  if (Number(a.description)) {
    return 1;
  }
  if (Number(b.description)) {
    return -1;
  }
  return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
};

export const sortWithNumberStringsByProperty = (
  array: Record<string, any>[],
  propertyName: string,
) => {
  if (!array) return array;
  return array.sort((a, b) => {
    if (Number(a[propertyName]) && Number(b[propertyName])) {
      return Number(a[propertyName]) - Number(b[propertyName]);
    }
    if (Number(a[propertyName])) {
      return 1;
    }
    if (Number(b[propertyName])) {
      return -1;
    }
    return a[propertyName].toLowerCase().localeCompare(b[propertyName].toLowerCase());
  });
};

export const sortSupplies = (a: EnrichedSupply, b: EnrichedSupply) => {
  const aDesc =
    a.otherDescription?.trim().length === 0 ? a.supplyItemDescription : a.otherDescription || '';
  const bDesc =
    b.otherDescription?.trim().length === 0 ? b.supplyItemDescription : b.otherDescription || '';

  return aDesc.toLowerCase() > bDesc.toLowerCase() ? 1 : -1;
};

export const sortAlphabeticallyByMultipleProperties = (
  array: Record<string, any>[] | undefined,
  properties: string[],
) => {
  if (!array) return array || [];

  return array.sort((a, b) => {
    for (let i = 0; i < properties.length; i += 1) {
      const property = properties[i];
      if (a[property]?.toLowerCase() < b[property]?.toLowerCase()) return -1;
      if (a[property]?.toLowerCase() > b[property]?.toLowerCase()) return 1;
    }
    return 0;
  });
};
