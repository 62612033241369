import type { Theme } from '@material-ui/core';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import { generateBaseEntity } from '@/rxdb/rxdbUtilityFunctions';
import { EnrichedSupply } from '@/rxdb/Supply/queryBuilder';
import { useSupply } from '@/rxdb/Supply/useSupply';
import { SupplyItemDocument } from '@/rxdb/SupplyItem/queryBuilder';
import { useNotification } from '@/utilities';
import { OTHER_SUPPLY_ITEM_DESC } from '@/utilities/constants';
import { sortSupplies } from '@/utilities/sortHelper';

import GenericSidePanel from '../../../GenericSidePanel';
import SupplyItemSection from './SupplyItemSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    divider: {
      borderBottom: `solid 1px ${theme.palette.text.disabled}`,
      margin: '10px 0',
    },
    deleteButtonContainer: {
      marginTop: '25px',
    },
  }),
);

interface SupplyErrors {
  [key: string]: string;
}

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
}

const EditSuppliesSidePanel = ({ open, onClose, onOpen, onCancel }: Props) => {
  const classes = useStyles();
  const { equipment } = useMst();
  const { listSupplies, listSupplyItems, updateSupplies } = useSupply();

  const { successNotification, errorNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);

  // Supplies state
  const [suppliesToEdit, setSuppliesToEdit] = useState<EnrichedSupply[]>([]);
  const [suppliesToDelete, setSuppliesToDelete] = useState<EnrichedSupply[]>([]);
  const [supplyQuantityErrors, setSupplyQuantityErrors] = useState<SupplyErrors>({});

  const [supplyOtherDescriptionErrors, setSupplyOtherDescriptionErrors] = useState<SupplyErrors>(
    {},
  );

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  // options
  const [supplyItems, setSupplyItems] = useState<SupplyItemDocument[]>([]);

  const otherSupplyItemId = useMemo(
    () =>
      supplyItems.find(
        item => item.description.toLowerCase() === OTHER_SUPPLY_ITEM_DESC.toLowerCase(),
      )?.id,
    [supplyItems],
  );

  const getSupplyData = async () => {
    const supplies = await listSupplies();
    setSuppliesToEdit(supplies.sort(sortSupplies));

    let items = await listSupplyItems();
    items = items.filter(item => item.isActive);

    setSupplyItems(items);
  };

  // Input Validation
  const validateSave = useCallback(() => {
    let validSave = true;
    if (Object.keys(supplyQuantityErrors).length > 0) validSave = false;
    if (Object.keys(supplyOtherDescriptionErrors).length > 0) validSave = false;

    if (suppliesToEdit.filter(x => x.quantity === '' || (!x.supplyItemId && x.quantity)).length > 0)
      validSave = false;
    if (
      suppliesToEdit
        .filter(x => x.supplyItemId === otherSupplyItemId)
        .map(x => {
          if (x?.otherDescription === '') return true;
          if (x.otherDescription && x.otherDescription.length > 25) return true;
          return false;
        })
        .some(val => val)
    )
      validSave = false;

    // Form Logic
    setCanSave(validSave && hasEdits);
  }, [suppliesToEdit, suppliesToDelete, hasEdits, supplyQuantityErrors]);

  useEffect(() => {
    validateSave();
  }, [validateSave]);

  // Save
  const onSave = useCallback(async () => {
    try {
      await updateSupplies(suppliesToEdit, suppliesToDelete);
      successNotification(i18n.t('Supplies updated'));
      onClose();
    } catch (error) {
      setIsSaving(false);
      console.error(`ERROR updating supplies: ${error}`);
      errorNotification(`Could not update the supplies.`);
    }
  }, [suppliesToEdit, suppliesToDelete]);

  const onDeleteSupply = useCallback(
    (supplyToDelete: EnrichedSupply) => {
      setSuppliesToEdit(prev => {
        const newSupplies: EnrichedSupply[] = [];
        for (const sup of prev) {
          const newSupply = { ...sup };
          if (newSupply.id !== supplyToDelete.id) {
            newSupplies.push(newSupply);
          }
        }
        return newSupplies;
      });

      setSupplyQuantityErrors(prev => {
        const newSupplyQuantityErrors = { ...prev };
        delete newSupplyQuantityErrors[supplyToDelete.id];
        return newSupplyQuantityErrors;
      });
      if (!supplyToDelete.isNew) {
        setSuppliesToDelete(prev => {
          const newSuppliesToDelete: EnrichedSupply[] = [];
          for (const sup of prev) {
            const newSupply = { ...sup };
            newSuppliesToDelete.push(newSupply);
          }
          newSuppliesToDelete.push({ ...supplyToDelete });
          return newSuppliesToDelete;
        });
      }

      setHasEdits(true);
    },
    [setSuppliesToEdit, setSuppliesToDelete, setHasEdits],
  );

  const onItemChanged = useCallback(
    (supply: EnrichedSupply, supplyItem: SupplyItemDocument) => {
      setSuppliesToEdit(prev => {
        const newSupplies: EnrichedSupply[] = [];

        for (const sup of prev) {
          const newSupply = { ...sup };
          if (newSupply?.id === supply?.id) {
            if (supplyItem?.id !== otherSupplyItemId) {
              newSupply.otherDescription = '';
            }
            newSupply.supplyItemId = supplyItem?.id;
            newSupply.supplyItemDescription = supplyItem?.description;
            newSupply.supplyItemUnit = supplyItem?.unit;
          }
          newSupplies.push(newSupply);
        }
        return newSupplies;
      });
      if (!!otherSupplyItemId && supplyItem?.id !== otherSupplyItemId) {
        setSupplyOtherDescriptionErrors(prev => {
          const newSupplyOtherDescriptionErrors = {
            ...prev,
          };
          newSupplyOtherDescriptionErrors[supply.id] = '';
          return newSupplyOtherDescriptionErrors;
        });
      }
      setHasEdits(true);
    },
    [setSuppliesToEdit, setHasEdits],
  );

  const onQuantityChanged = useCallback(
    (supply: EnrichedSupply, newItemValue: string) => {
      setHasEdits(true);
      setSupplyQuantityErrors(prev => {
        const newSupplyQuantityErrors = { ...prev };
        if (RegExp('^[0-9]+$').test(newItemValue)) {
          delete newSupplyQuantityErrors[supply.id];
        } else {
          newSupplyQuantityErrors[supply.id] = 'Invalid quantity';
        }
        return newSupplyQuantityErrors;
      });
      setSuppliesToEdit(prev => {
        const newSupplies: EnrichedSupply[] = [];
        for (const sup of prev) {
          const newSupply = { ...sup };
          if (newSupply.id === supply.id) {
            newSupply.quantity = newItemValue;
          }
          newSupplies.push(newSupply);
        }
        return newSupplies;
      });
    },
    [setSuppliesToEdit, setHasEdits, setSupplyQuantityErrors],
  );

  const onOtherDescriptionChanged = useCallback(
    (supply: EnrichedSupply, newOtherDescription: string) => {
      setSupplyOtherDescriptionErrors(prev => {
        const matchingOtherDescription =
          suppliesToEdit.find(
            supplyToEdit =>
              supplyToEdit.otherDescription?.toLowerCase() === newOtherDescription.toLowerCase(),
          ) !== undefined;

        const newSupplyOtherDescriptionErrors = {
          ...prev,
        };
        if (newOtherDescription.trim() === '') {
          newSupplyOtherDescriptionErrors[supply.id] = i18n.t('Item description is required');
        } else if (matchingOtherDescription) {
          newSupplyOtherDescriptionErrors[supply.id] = i18n.t(
            'Another supply already exists with this type',
          );
        } else if (newOtherDescription.trim() !== '' && newOtherDescription.length <= 25) {
          delete newSupplyOtherDescriptionErrors[supply.id];
        } else if (newOtherDescription.length > 25) {
          newSupplyOtherDescriptionErrors[supply.id] = i18n.t(
            'Item description cannot be longer than 25 characters',
          );
        }

        return newSupplyOtherDescriptionErrors;
      });
      setSuppliesToEdit(prev => {
        const newSupplies: EnrichedSupply[] = [];

        for (const sup of prev) {
          const newSupply = { ...sup };
          if (newSupply.id === supply.id) {
            newSupply.otherDescription = newOtherDescription;
          }
          newSupplies.push(newSupply);
        }
        return newSupplies;
      });
      setHasEdits(true);
    },
    [setHasEdits, setSupplyOtherDescriptionErrors, setSuppliesToEdit, suppliesToEdit],
  );

  const onAddAnotherSupply = useCallback(() => {
    setSuppliesToEdit(prev => {
      const newSupplies: EnrichedSupply[] = [];

      for (const sup of prev) {
        const existingSupply = { ...sup };
        newSupplies.push(existingSupply);
      }
      const newSupply = {
        ...generateBaseEntity(),
        quantity: '',
        borerEquipmentId: equipment.selectedBorerId as string,
        supplyItemId: '',
        supplyItemDescription: '',
        otherDescription: '',
        supplyItemUnit: '',
        isNew: true,
      };
      newSupplies.push(newSupply);
      return newSupplies;
    });
    setHasEdits(true);
  }, [setSuppliesToEdit, setHasEdits]);

  useEffect(() => {
    if (open === true && undo === false) {
      setHasEdits(false);
      setCanSave(false);
      setIsSaving(false);
      setSuppliesToDelete([]);
      setSuppliesToEdit([]);
      setSupplyOtherDescriptionErrors({});
      setSupplyQuantityErrors({});
      getSupplyData();
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  return (
    <>
      <GenericSidePanel
        key="edit-supplies-panel"
        open={open}
        onClose={onClose}
        title={i18n.t('Edit supplies')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Supplies draft discarded"
      >
        <Card className={classes.cardRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">{i18n.t('Supplies')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <>
                {suppliesToEdit.map((supply, index) => {
                  return (
                    <SupplyItemSection
                      key={supply.id}
                      divider={index < suppliesToEdit.length - 1}
                      supply={supply}
                      supplyItems={supplyItems.filter(
                        supplyItem =>
                          supply.supplyItemId === supplyItem.id ||
                          supplyItem.description.toLowerCase().indexOf('other') !== -1 ||
                          !suppliesToEdit.find(toEdit => toEdit.supplyItemId === supplyItem.id),
                      )}
                      onItemChanged={onItemChanged}
                      onQuantityChanged={onQuantityChanged}
                      onOtherDescriptionChanged={onOtherDescriptionChanged}
                      onDeleteSupply={onDeleteSupply}
                      otherSupplyItemId={otherSupplyItemId}
                      quantityErrorMessage={supplyQuantityErrors[supply.id]}
                      otherDescriptionErrorMessage={supplyOtherDescriptionErrors[supply.id]}
                    />
                  );
                })}
              </>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Button
                  variant="outlined"
                  color="primary"
                  data-cy="add-another-advance"
                  noMinHeight
                  onClick={onAddAnotherSupply}
                  startAdornment={<Icons.PlusFeather color="primary" />}
                  id="add-another-supply"
                >
                  {i18n.t('Add another supply')}
                </Button>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Grid>
        </Card>
      </GenericSidePanel>
    </>
  );
};

export default EditSuppliesSidePanel;
