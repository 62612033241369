import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0 !important',
      padding: '16px',
    },
    buttonContainer: {
      '& > div': {
        alignItems: 'flex-end',
      },
    },
    closeShiftButton: {
      maxWidth: '160px',
    },
  }),
);

export default useStyles;
