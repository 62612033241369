import { DialogActions, DialogContent, Grid, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../../../mobx-models/Root';
import useBorerShiftSignatures from '../../../../rxdb/BorerShiftSignature/useBorerShiftSignatures';
import { Employee } from '../../../../rxdb/Employees/queryBuilder';
import useEmployees from '../../../../rxdb/Employees/useEmployees';
import { useCurrentBorer } from '../../../../rxdb/Equipment/useCurrentBorer';
import useEquipmentDeficiency, {
  EquipmentDeficiencyWithChildren,
} from '../../../../rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import {
  InspectionResult,
  InspectionResultCollection,
} from '../../../../rxdb/InspectionResults/queryBuilder';
import { PreOpResult } from '../../../../rxdb/InspectionResults/types';
import useInspections, {
  InspectionDetails,
  InspectionOptionsWithDetails,
} from '../../../../rxdb/Inspections/useInspections';
import RxdbCollectionName from '../../../../rxdb/rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../../../../rxdb/rxdbUtilityFunctions';
import useSignatures from '../../../../rxdb/Signature/useSignatures';
import { useNotification } from '../../../../utilities';
import { InspectionType } from '../../../../utilities/constants';
import { SignatureType } from '../../../../utilities/enums';
import useModalStyles from '../../../../utilities/modalStyles';
import { jsonMapReplacer } from '../../../../utilities/utilityFunctions';
import AddSignatureModal from '../../../AddSignatureModal';
import ChecksThisShiftSignatureCard from '../../../ChecksThisShiftSignatureCard';
import DiscardDraftModal from '../../../DiscardDraftModal';
import AddDeficiencySidePanel from '../AddDeficiencySidePanel';
import PreOpCheckCategory from './PreOpCheckCategory';

const UNDO_SNACKBAR_KEY = 'PreOpUndo';

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: (inspectionType?: InspectionType) => void;
  viewOnly?: boolean;
  inspectionDetailsSource?: InspectionDetails;
  selectedOptionsSource?: Record<string, InspectionOptionsWithDetails>;
  createdEquipmentDeficiencies?: Map<string, EquipmentDeficiencyWithChildren>;
  signatureId?: string;
  inspectionType?: InspectionType;
}

export const getCheckTitle = (
  inspectionType = InspectionType.PRE_OP,
  isVanscoy = false,
  isCory = false,
) => {
  if (inspectionType === InspectionType.BORER_FLEXIVEYOR_PREOP_CHECK)
    return 'Borer and flexiveyor pre-op check';
  if (inspectionType === InspectionType.BORER_CUTTING_CHECK) return 'Borer cutting check';
  if (inspectionType === InspectionType.POST_CUTTING_CHECK) return 'Post-cutting check';
  if (inspectionType === InspectionType.MINER_PRE_OP) return 'Miner pre-op inspection';
  if (inspectionType === InspectionType.MINER_RUNNING) return 'Miner running inspection';
  if (inspectionType === InspectionType.BRIDGES_PRE_OP) return 'Bridges pre-op inspection';
  if (inspectionType === InspectionType.BRIDGES_RUNNING) return 'Bridges running inspection';
  if (inspectionType === InspectionType.BORER_INSPECTION) return 'Borer inspection';
  if (inspectionType === InspectionType.MINEVEYOR_INSPECTION) return 'Mineveyor inspection';
  if (inspectionType === InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING)
    return 'Safety checks before cutting';
  if (
    [InspectionType.EQUIPMENT_CHECK, InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING].includes(
      inspectionType,
    )
  ) {
    if (isCory) return 'TR equipment check';
    if (isVanscoy) return 'Safety checks during cutting';
    return 'Equipment check';
  }
  return 'Pre-op check';
};

const isDeficiency = (deficiency?: string) =>
  ['Substandard', 'Deficiency', 'WO Required'].includes(deficiency || '');

// Note: Used to add all InspectionTypes, not just Pre-op checks
const AddPreOpCheck: React.FC<Props> = ({
  open,
  onClose,
  onOpen,
  viewOnly = false,
  inspectionDetailsSource,
  selectedOptionsSource = {},
  createdEquipmentDeficiencies = new Map(),
  signatureId,
  inspectionType = InspectionType.PRE_OP,
}: Props) => {
  const classes = useModalStyles();
  const { errorNotification, successNotification, closeSnackbar } = useNotification();
  const { createSignature } = useSignatures();
  const { isVanscoy, isCory } = useSiteFeatures();

  // Controls
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [loadingInitialDetails, setLoadingInitialDetails] = useState(true);

  // Inspection Details
  const [inspectionDetails, setInspectionDetails] = useState<InspectionDetails | undefined>(
    inspectionDetailsSource,
  );
  const [selectedOptions, setSelectedOptions] =
    useState<Record<string, InspectionOptionsWithDetails>>(selectedOptionsSource);
  const [recentlyChangedCategoryId, setRecentlyChangedCategoryId] = useState('');

  const [relatedEquipmentDeficiencies, setRelatedEquipmentDeficiencies] = useState<
    Map<string, EquipmentDeficiencyWithChildren>
  >(createdEquipmentDeficiencies);

  // Signature
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttingPermits] = useState<boolean>(false);
  const [eligibleEmployees, setEligibleEmployees] = useState<Employee[]>([]);

  const [addDeficiencyOpen, setAddDeficiencyOpen] = useState(false);

  // collections
  const inspectionResultCollection: InspectionResultCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  );

  // Configure Inspection Details
  const { shiftPicker } = useMst();
  const { miningMethod } = useCurrentBorer();
  const { getInspectionFor } = useInspections();
  const { deficiencyCollectionsInitialized, listEquipmentDeficienciesForIds } =
    useEquipmentDeficiency();
  const { listEligibleSignees } = useBorerShiftSignatures();
  const { employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: false,
    populatedPosition: false,
    onlyActiveCrew: false,
  });

  const getEquipmentDeficiencies = async () => {
    const ids = Object.values(selectedOptions)
      .map(option => option.deficiencyId)
      .filter(id => id !== undefined);

    const deficiencies = await listEquipmentDeficienciesForIds(ids);
    setRelatedEquipmentDeficiencies(deficiencies);
  };

  const checkTitle = useMemo(
    () => getCheckTitle(inspectionType, isVanscoy, isCory),
    [inspectionType, isVanscoy, isCory],
  );

  useEffect(() => {
    if (deficiencyCollectionsInitialized) getEquipmentDeficiencies();
  }, [selectedOptions, deficiencyCollectionsInitialized]);

  const setup = async () => {
    setLoadingInitialDetails(true);

    if (viewOnly) {
      setLoadingInitialDetails(false);
      return;
    }

    if (!miningMethod) {
      errorNotification('Unable to find mining method - please check borer assignment');
      onClose();
      setLoadingInitialDetails(false);
      return;
    }

    try {
      const result = await getInspectionFor(inspectionType, miningMethod);

      if (!result) {
        errorNotification('Unable to find inspection template - please contact an administrator');
        onClose();
        setLoadingInitialDetails(false);
        return;
      }
      setInspectionDetails(result);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 25 ~ setup ~ error', error);
    }

    try {
      const result = await listEligibleSignees(false);
      setEligibleEmployees(result);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 172 ~ setup ~ error', error);
    }
    setLoadingInitialDetails(false);
  };

  const resetModalDetails = () => {
    setCanSave(false);
    setIsSaving(false);
    setInspectionDetails(undefined);
    setSelectedOptions({});
    setRecentlyChangedCategoryId('');
    setRelatedEquipmentDeficiencies(new Map());
  };

  useEffect(() => {
    if (open === true && undo === false) {
      resetModalDetails();
      setInspectionDetails(inspectionDetailsSource);
      setSelectedOptions(selectedOptionsSource);
      setRelatedEquipmentDeficiencies(createdEquipmentDeficiencies);
      setup();
    }
    if (open === true) {
      setUndo(false);
      closeSnackbar(UNDO_SNACKBAR_KEY);
    }
  }, [open, miningMethod]);

  const onOpenAddDeficiency = () => setAddDeficiencyOpen(true);

  const handleToggleChange = (event: any, value: InspectionOptionsWithDetails) => {
    if (value) {
      const { inspectionCategoryId } = value.option;
      setRecentlyChangedCategoryId(inspectionCategoryId);
      setSelectedOptions(prev => ({
        ...prev,
        [inspectionCategoryId]: value,
      }));
      if (isDeficiency(value.option.description)) {
        // Trigger New Deficiency Panel
        onOpenAddDeficiency();
      }
    }
  };

  // Generate the complicated set of options based on hazard details
  const generateOptions = () => {
    if (!inspectionDetails) return [];

    return inspectionDetails.categories
      .sort((a, b) => a.category.displayOrder - b.category.displayOrder)
      .map(category => {
        let selectedOption = selectedOptions[category.category.id];

        // This is need to allow for the buttons to correctly highlight when viewing a past preop check
        if (selectedOption) {
          selectedOption = category.options.find(
            item => item.option.id === selectedOption.option.id,
          );
        }

        let deficiencyDetails: EquipmentDeficiencyWithChildren | undefined;
        if (
          selectedOption?.deficiencyId &&
          relatedEquipmentDeficiencies.has(selectedOption.deficiencyId)
        ) {
          deficiencyDetails = relatedEquipmentDeficiencies.get(selectedOption.deficiencyId);
        }

        return (
          <PreOpCheckCategory
            key={category.category.id}
            deficiencyDetails={deficiencyDetails}
            selectedOptionIsDeficiency={isDeficiency(selectedOption?.option?.description)}
            handleToggleChange={handleToggleChange}
            selectedOption={selectedOption}
            viewOnly={viewOnly}
            category={category}
            employeeList={employeesList}
          />
        );
      });
  };

  // Deficiency Side Panel
  const onCloseAddDeficiency = () => {
    setAddDeficiencyOpen(false);

    if (
      selectedOptions[recentlyChangedCategoryId] &&
      selectedOptions[recentlyChangedCategoryId].deficiencyId === undefined
    ) {
      const temp = { ...selectedOptions };
      delete temp[recentlyChangedCategoryId];
      setSelectedOptions(temp);
    }
  };

  const onPreopDeficiencyUndo = () => {
    const temp = { ...selectedOptions };
    setSelectedOptions(temp);
  };

  const onDeficiencyAdded = (deficiencyId: string) => {
    const temp = { ...selectedOptions };
    temp[recentlyChangedCategoryId].deficiencyId = deficiencyId;
    setSelectedOptions(temp);
  };

  const onCancelPreOpCheck = () => {
    if (viewOnly || Object.keys(selectedOptions).length === 0) {
      onClose();
    } else if (Object.keys(selectedOptions).length > 0) {
      setDiscardOpen(true);
    }
  };

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
    setUndo(false);
  };

  const onDiscardDraft = () => {
    onClose();
    setDiscardOpen(false);
    errorNotification(`${checkTitle} draft discarded`, {
      key: UNDO_SNACKBAR_KEY,
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            setUndo(true);
            closeSnackbar(key);
            onOpen(inspectionType);
          }}
          id="undo-discard-pre-op-draft"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  // Validates the form is filled in
  const validateCanSave = useCallback(() => {
    let validSave = true;

    if (inspectionDetails) {
      const numberOfCategories = inspectionDetails?.categories.length;
      const numberOfSelectedOptions = Object.keys(selectedOptions).length;

      if (numberOfCategories !== numberOfSelectedOptions) validSave = false;
    }

    setCanSave(validSave);
  }, [inspectionDetails, selectedOptions]);

  useEffect(() => {
    validateCanSave();
  }, [selectedOptions, validateCanSave]);

  const onOpenSignatureModal = () => {
    setSignatureModalOpen(true);
  };

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttingPermits(false);
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    reviewedCuttingPermitsValue: boolean,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttingPermits(reviewedCuttingPermitsValue);
  };

  const onCancelSignature = () => {
    setSignatureModalOpen(false);
    clearSignatureData();
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    reviewedCuttingPermitsValue: boolean,
    signatureData: string,
  ) => {
    return createSignature(
      SignatureType.PRE_OP,
      signatureData,
      customName ? undefined : employee?.id,
      undefined,
      reviewedCuttingPermitsValue,
    );
  };

  const errorSaving = () => {
    errorNotification(i18n.t('Pre Op check could not save. Please try again.'));
    setSignatureModalOpen(false);

    setIsSaving(false);
  };

  const onSaveInspection = async (
    employee: Employee,
    customName: string,
    cuttingPermitsReviewed: boolean,
    signatureData: string,
  ) => {
    setIsSaving(true);

    // Save the Signature
    let signature;
    try {
      signature = await onSaveSignature(
        employee,
        customName,
        cuttingPermitsReviewed,
        signatureData,
      );
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 430 ~ onSaveInspection ~ error', error);

      return errorSaving();
    }

    if (!signature || !inspectionDetails || !shiftPicker.currentBorerShiftId) {
      return errorSaving();
    }

    // Generate Inspection Object
    const inspectionResult: PreOpResult = {
      preOpConfig: inspectionDetails,
      preOpSelections: selectedOptions,
      preOpDeficiencies: relatedEquipmentDeficiencies,
    };

    const doc: InspectionResult = {
      ...generateBaseEntityWithCreatedOn(),
      inspectionDetail: JSON.stringify(inspectionResult, jsonMapReplacer),
      signatureId: signature.signatureId,
      inspectionId: inspectionDetails.inspection.id,
      borerShiftId: shiftPicker.currentBorerShiftId,
    };

    // Save Inspection
    try {
      await inspectionResultCollection?.insert(doc);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 415 ~ error', error);
      return errorSaving();
    }

    // Close Modal
    onClose();
    successNotification(`${checkTitle} added`);
  };

  return (
    <>
      {open && (
        <div className={classes.root}>
          <MuiDialogTitle disableTypography className={classes.dialogTitleContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.headerTitleContainer}>
                <Typography variant="h6">{checkTitle}</Typography>
              </div>
              <div className={`${classes.rightButtonContainer} `}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={onCancelPreOpCheck}
                  data-cy="closeDialogButton"
                  id="close-add-pre-op-check-dialog"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </div>
            </div>
          </MuiDialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container>{generateOptions()}</Grid>
            {viewOnly && signatureId && (
              <Grid container>
                <ChecksThisShiftSignatureCard signatureId={signatureId} />
              </Grid>
            )}
          </DialogContent>
          {!viewOnly && (
            <DialogActions className={classes.dialogActionsContainer}>
              <Grid container justify="space-between">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancelPreOpCheck}
                    data-cy="cancelAddPreOpCheck"
                    noMinHeight
                    id="cancel-add-pre-op-check"
                  >
                    {i18n.t('Cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    data-cy="saveAddPreOpCheck"
                    noMinHeight
                    onClick={onOpenSignatureModal}
                    disabled={!canSave || loadingInitialDetails}
                    isLoading={isSaving}
                    id="save-add-pre-op-check"
                  >
                    {i18n.t('Save')}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          )}
          <DiscardDraftModal
            open={discardOpen}
            onCancel={onCancelDiscardDraft}
            onDiscard={onDiscardDraft}
            disableEnforceFocus
          />
          <AddDeficiencySidePanel
            open={addDeficiencyOpen}
            onClose={onCloseAddDeficiency}
            onOpen={onOpenAddDeficiency}
            onDeficiencyAdded={onDeficiencyAdded}
            equipmentTypeId={
              recentlyChangedCategoryId && selectedOptions[recentlyChangedCategoryId]
                ? selectedOptions[recentlyChangedCategoryId].option.equipmentTypeId
                : undefined
            }
            preopDeficiencyUndo={onPreopDeficiencyUndo}
          />
          <AddSignatureModal
            open={signatureModalOpen}
            type={SignatureType.PRE_OP}
            onCancel={onCancelSignature}
            onSave={onSaveInspection}
            onUpdate={onUpdateSignature}
            employees={eligibleEmployees}
            selectedEmployee={selectedEmployee}
            selectedCustomName={selectedCustomName}
            nameNotInList={nameNotInList}
            reviewedCuttingPermits={reviewedCuttingPermits}
            disableEnforceFocus
            backButtonText="Back"
            cardText="Add your signature to verify that the deficiency report is complete."
          />
        </div>
      )}
    </>
  );
};

export default AddPreOpCheck;
