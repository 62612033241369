import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
export interface DailySafetyTopic extends BaseEntity {
  comment: string;
  shiftCommentTypeId: string;
  shiftId: string;
}
export type DailySafetyTopicCollection = RxCollection<DailySafetyTopic> | null;
export type DailySafetyTopicDocument = RxDocument<DailySafetyTopic>;

export const dailySafetyTopicSchema: RxJsonSchema<DailySafetyTopic> = {
  type: 'object',
  version: 0,
  description: 'describes a dailySafetyTopic object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    comment: { type: 'string' },
    shiftCommentTypeId: { type: 'string' },
    shiftId: { type: 'string' },
  },
};

const dailySafetyTopicsFeedQuery = print(gql`
  query borerShiftDailySafetyTopicCommentFeed(
    $borerEquipmentId: ID!
    $lastUpdateAt: Float!
    $limit: Int!
  ) {
    borerShiftDailySafetyTopicCommentFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      isDeleted
      updatedAt
      version
      comment
      shiftCommentTypeId
      shiftId
    }
  }
`);

export const dailySafetyTopicsPullQueryBuilder = generatePullQuery(
  dailySafetyTopicsFeedQuery,
  PullQueryContext.Borer,
);

export default {};
