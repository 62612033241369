import type { Resource } from '@devexpress/dx-react-scheduler';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useMemo } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

import { translate } from '../siteLanguageTranslation/siteLanguageTranslation';

const APPT_GROUPING = [
  {
    resourceName: 'typeId',
  },
];
export const useAppointmentUtilities = () => {
  const { siteId } = useSiteFeatures();
  const { isRehabBorer } = useCurrentBorer();

  const APPT_RESOURCES: Resource[] = useMemo(() => {
    const types = [
      {
        text: !isRehabBorer ? 'Borer delays' : 'Rehab delays',
        id: 1,
      },
      {
        text: translate('Shift activities'),
        id: 2,
      },
    ];

    return [
      {
        fieldName: 'typeId',
        title: 'Types',
        instances: types,
      },
    ];
  }, [siteId, isRehabBorer]);

  return { APPT_RESOURCES, APPT_GROUPING };
};

export default useAppointmentUtilities;
