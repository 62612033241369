import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      backgroundColor: CustomPalette.elevation.dp4Solid,
      top: 0,
      zIndex: 1199,
      overflow: 'auto',
    },
    // Custom
    dialogTitleContainer: {
      margin: 0,
      padding: theme.spacing(1),
      backgroundColor: CustomPalette.elevation.dp4Solid,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1500,
    },
    dialogActionsContainer: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1500,
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginLeft: '35px',
      marginRight: '8px',
    },
    headerTitleContainer: {
      position: 'absolute',
      width: 'calc(100% - 16px)',
      height: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightButtonContainer: {
      position: 'absolute',
      top: '8px',
      right: 0,
      height: '48px',
    },
    leftHeaderButton: {
      position: 'absolute',
      top: '18px',
      left: '32px',
      height: '48px',
      zIndex: 2,
    },
    headerContainer: {
      margin: '0',
      height: '48px',
    },
    card: {
      padding: '16px',
      backgroundColor: CustomPalette.elevation.dp1,
      marginBottom: '16px',
    },
    buttonGroupContainer: {
      marginTop: '8px',
    },
    dialogContent: {
      padding: '16px',
      overflow: 'auto',
      margin: '61px 0px',
    },
    toggleButtonLabel: {
      textTransform: 'capitalize !important',
    },
  }),
);

export default useModalStyles;
