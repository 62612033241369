import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { BlockCollection, BlockDocument } from './queryBuilder';

const useBlocks = () => {
  const blocksCollection: BlockCollection = useRxCollection(RxdbCollectionName.BLOCKS);

  const [defaultBlock, setDefaultBlock] = useState<BlockDocument | undefined>();

  useEffect(() => {
    const getDefaultBlock = async () => {
      if (blocksCollection) {
        try {
          const blocks = await blocksCollection.find().exec();
          // Only one block exists for Cory/Vanscoy
          if (blocks.length) {
            setDefaultBlock(blocks[0]);
          } else {
            setDefaultBlock(undefined);
          }
        } catch (error) {
          console.log('🚀 ~ file: useBlocks.ts ~ line 23 ~ error', error);
          setDefaultBlock(undefined);
        }
      } else {
        setDefaultBlock(undefined);
      }
    };

    getDefaultBlock();
  }, [blocksCollection]);

  return {
    defaultBlock,
  };
};

export default useBlocks;
