import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette, MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
      marginTop: 20,
      width: 'auto',
    },
    iconSpacer: {
      marginRight: 8,
    },
    listbox: {
      '& li': {
        padding: '6px 6px',
      },
    },
    divider: {
      color: MaterialPalette.text.primary,
      opacity: 0.9,
      fontSize: '14px',
      margin: '0px',
      padding: '0px',
    },
    spacer: {
      marginTop: 10,
    },
    buttonSpacer: {
      marginTop: 10,
      width: 'auto',
    },
  }),
);
