import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useOnlineStatus } from '../../utilities';
import { useBorerHeartbeat } from './useBorerHeartbeat';

const HEART_BEAT_SEND_INTERVAL_MINUTES = 15;

const BorerHeartbeat: React.FC = observer(() => {
  const { sendBorerHeartbeat } = useBorerHeartbeat();
  const location = useLocation();
  const isOnline = useOnlineStatus();

  const lastUserInteractionTime = React.useRef<Dayjs>(dayjs());

  const sendBorerHeartbeatIfValid = async () => {
    if (location.pathname === '/login' || location.pathname === '/logout') return;
    try {
      sendBorerHeartbeat({ lastUserInteractionTime: lastUserInteractionTime.current });
    } catch (e) {
      console.log('🚀 ~ file: BorerHeartbeat.tsx:24 ~ sendBorerHeartbeatIfValid ~ e:', e);
    }
  };

  useEffect(() => {
    sendBorerHeartbeatIfValid();
    // Send heartbeat every 15 minutes
    const interval = setInterval(() => {
      sendBorerHeartbeatIfValid();
    }, HEART_BEAT_SEND_INTERVAL_MINUTES * 60 * 1000);

    return () => clearInterval(interval);
  }, [isOnline]);

  // Track user interactions
  useEffect(() => {
    window.addEventListener(
      'touchstart',
      () => {
        // eslint-disable-next-line no-console
        lastUserInteractionTime.current = dayjs();
      },
      { passive: true },
    );
  }, []);

  return <></>;
});

export { BorerHeartbeat };
