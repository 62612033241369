import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, MaterialPalette, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';

import {
  JSONComment,
  usePanelDrawingComment,
} from '../../rxdb/PanelDrawingComment/usePanelDrawingComment';
import EditPanelDrawingCommentPanel from '../EditPanelDrawingCommentPanel';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: '8px !important',
    },
    card: {
      padding: '16px',
      margin: '8px 0px 0px 0px !important',
    },
    markerArea: {
      marginTop: '16px',
    },
    activeButton: {
      backgroundColor: MaterialPalette.primary.main,
      borderRadius: '20px',
    },
    commentArea: {
      marginTop: '24px',
    },
  }),
);

interface Props {
  panelDrawingId: string;
  canEdit: boolean;
  onOpen?: () => void;
}

const PanelDrawingCommentsCard: React.FC<Props> = ({ panelDrawingId, canEdit, onOpen }: Props) => {
  const classes = useStyles();
  const { currentPanelComments } = usePanelDrawingComment(panelDrawingId);

  const [open, setOpen] = useState(false);

  const onAddComment = async () => {
    if (onOpen) {
      onOpen();
    }
    setOpen(true);
  };

  const onCloseCommentPanel = useCallback(() => setOpen(false), []);
  const onOpenCommentPanel = useCallback(() => setOpen(true), []);

  return (
    <>
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h3">Legend</Typography>
          </Grid>
          <Grid item container xs={6} justify="flex-end" spacing={0}>
            <Grid item>
              {canEdit && (
                <Button
                  variant="outlined"
                  color="primary"
                  startAdornment={<Icons.PlusFeather color="primary" />}
                  noMinHeight
                  onClick={onAddComment}
                  id="add-legend-comment-button"
                >
                  {i18n.t('Add to legend')}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.commentArea}>
            {currentPanelComments?.map(commentDoc => {
              try {
                const parsedComment: JSONComment = JSON.parse(commentDoc.comment);

                return (
                  <Grid item container xs={6} key={commentDoc.id}>
                    <Icons.XFeather style={{ stroke: parsedComment.colourHex }} />
                    <Typography variant="body1">= {parsedComment.commentText}</Typography>
                  </Grid>
                );
              } catch (err) {
                return null;
              }
            })}
          </Grid>
        </Grid>
      </Card>
      {panelDrawingId && (
        <EditPanelDrawingCommentPanel
          open={open}
          onClose={onCloseCommentPanel}
          onCancel={onCloseCommentPanel}
          onOpen={onOpenCommentPanel}
          panelDrawingId={panelDrawingId}
          existingComments={currentPanelComments}
        />
      )}
    </>
  );
};

export default observer(PanelDrawingCommentsCard);
