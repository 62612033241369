import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette, ExpansionPanel, Icons } from '@nutrien/cxp-components';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useRxData } from 'rxdb-hooks';

import { GroundControlType } from '../../../rxdb/GroundControlTypes/queryBuilder';
import { RxdbCollectionName } from '../../../rxdb/rxdbCollectionName';
import { HazardPanelTypes } from '../../../utilities';
import { sortAscendingByDisplayOrder } from '../../../utilities/sortHelper';
import GroundControlTypeComponent from '../GroundControlTypeComponent/GroundControlTypeComponent';

interface Props {
  title?: string;
  currentSelectedGroundControls: any[];
  onGroundControlSelected: (
    groundControlId: string,
    quantityValue: string,
    optionValuePairs: any[],
  ) => void;
  onGroundControlUpdated: (
    groundControlId: string,
    quantityValue?: string,
    optionId?: string,
    optionValueId?: string,
    optionValue?: string,
  ) => void;
  updateQuantityErrors: (val: boolean) => void;
  validationIndex: number;
  setValidationIndex: Dispatch<SetStateAction<number>>;
  expanded: boolean;
  onExpanded: (isExpanded: boolean, expandedPanelId: HazardPanelTypes) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    panelContent: {
      padding: '0px 8px',
    },
    groundControlType: {
      width: '100%',
    },
  }),
);

const GroundControlExpansionPanel = ({
  title = 'Ground control',
  onGroundControlSelected,
  onGroundControlUpdated,
  currentSelectedGroundControls,
  updateQuantityErrors,
  setValidationIndex,
  validationIndex,
  expanded,
  onExpanded,
}: Props) => {
  const classes = useStyles();

  const groundControlTypesQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: groundControls }: { result: GroundControlType[] } = useRxData(
    RxdbCollectionName.GROUND_CONTROL_TYPES,
    groundControlTypesQueryConstructor,
  );
  const [errorObject, setErrorObject] = useState<Record<string, boolean>>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleGroundControlUpdated = (
    groundControlId: string,
    quantityValue?: string,
    quantityError?: boolean,
    optionId?: string,
    optionValueId?: string,
    optionValue?: string,
  ) => {
    setValidationIndex(10);
    onGroundControlUpdated(
      groundControlId,
      quantityError ? '' : quantityValue,
      optionId,
      optionValueId,
      optionValue,
    );
  };

  const setValidationError = (index: number, value: boolean) => {
    setErrorObject(prev => ({ ...prev, [index]: value }));
  };

  useEffect(() => {
    updateQuantityErrors(!!Object.values(errorObject).find((x: boolean) => x));
  }, [errorObject]);

  const isGroundControlSelected = (groundControlId: string) => {
    return currentSelectedGroundControls.findIndex(x => x.typeId === groundControlId) !== -1;
  };

  const getGroundControlValues = (groundControlId: string) => {
    const groundControlValues = currentSelectedGroundControls.find(
      x => x.typeId === groundControlId,
    );
    return groundControlValues?.optionValues || [];
  };

  const getQuantityValue = (groundControlId: string) => {
    const groundControlValues = currentSelectedGroundControls.find(
      x => x.typeId === groundControlId,
    );
    return groundControlValues?.quantity || '';
  };

  const hasErrors = useMemo(() => {
    return Object.values(errorObject).find(error => error) != undefined;
  }, [errorObject]);

  const handlePanelChange = (event: React.ChangeEvent<unknown>, newExpandedState: boolean) => {
    if (newExpandedState) {
      onExpanded(true, HazardPanelTypes.GROUND_CONTROLS);
      setValidationIndex(prev => (prev > 9 ? prev : 9));
    } else if (!hasErrors) {
      onExpanded(false, HazardPanelTypes.GROUND_CONTROLS);
      setValidationIndex(prev => (prev > 9 ? prev : 9));
    }
  };

  return (
    <ExpansionPanel
      className={classes.panel}
      title={title}
      key="hazard-add-ground-control"
      data-cy="hazard-add-comment-ground-control"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded || hasErrors || isEditing}
      onChange={handlePanelChange}
      expansionPanelSummaryProps={{
        expandIcon: expanded ? (
          <Icons.ChevronDownFeather color="primary" key="hazard-add-ground-control-chevron-down" />
        ) : (
          <>
            {currentSelectedGroundControls.length > 0 && (
              <Icons.ChevronDownFeather
                color="primary"
                key="hazard-add-ground-control-chevron-down"
              />
            )}
            {currentSelectedGroundControls.length === 0 && (
              <Icons.PlusFeather color="primary" key="hazard-add-ground-control-chevron-plus" />
            )}
          </>
        ),
        IconButtonProps: {
          color: 'primary',
          style: {
            border: expanded
              ? '0px'
              : currentSelectedGroundControls.length > 0
              ? '0px'
              : `1px solid green`,
            borderRadius: '4px',
            height: '44px',
          },
        },
      }}
      panelContent={
        <>
          {groundControls && groundControls.length > 0 && (
            <Grid container>
              {groundControls.sort(sortAscendingByDisplayOrder).map((groundControl, index) => {
                return (
                  <div
                    id={`ground-control-${groundControl.id}`}
                    key={`ground-control-${groundControl.id}`}
                    className={classes.groundControlType}
                  >
                    <GroundControlTypeComponent
                      key={`ground-control-${groundControl.id}`}
                      groundControl={groundControl}
                      isSelected={isGroundControlSelected(groundControl.id)}
                      quantity={getQuantityValue(groundControl.id)}
                      selectedValues={getGroundControlValues(groundControl.id)}
                      onGroundControlSelected={onGroundControlSelected}
                      onGroundControlUpdated={handleGroundControlUpdated}
                      index={index}
                      setValidationError={setValidationError}
                      validationIndex={validationIndex}
                      setValidationIndex={setValidationIndex}
                      onGroundControlFocus={() => {
                        setIsEditing(true);
                      }}
                      onGroundControlBlur={() => {
                        setIsEditing(false);
                      }}
                    />
                  </div>
                );
              })}
            </Grid>
          )}
        </>
      }
    />
  );
};

export default GroundControlExpansionPanel;
