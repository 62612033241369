import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface Location extends BaseEntity {
  readonly startMeters: number | null;
  readonly endMeters?: number | null;
  readonly panel: string;
  readonly room?: string | null;
  readonly surveyPoint?: string | null;
  readonly sequence?: string | null; // only chevron (layer 4)
  readonly pass?: string | null; // optional on all types
}
export type LocationCollection = RxCollection<Location> | null;
export type LocationDocument = RxDocument<Location>;

export const locationSchema: RxJsonSchema<Location> = {
  type: 'object',
  version: 0,
  description: 'describes a location object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    startMeters: { type: ['number', 'null'] },
    endMeters: { type: ['number', 'null'] },
    panel: {
      ref: 'panels',
      type: ['string', 'null'],
    },
    room: {
      ref: 'rooms',
      type: ['string', 'null'],
    },
    surveyPoint: {
      ref: 'survey_points',
      type: ['string', 'null'],
    },
    sequence: {
      ref: 'sequences',
      type: ['string', 'null'],
    },
    pass: {
      ref: 'passes',
      type: ['string', 'null'],
    },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const locationFeedQuery = print(gql`
  query locationFeedQuery($siteId: ID!, $limit: Int!, $lastUpdateAt: Float!) {
    locationFeed(limit: $limit, siteId: $siteId, lastUpdateAt: $lastUpdateAt) {
      id
      startMeters
      endMeters
      panel
      pass
      room
      sequence
      surveyPoint
      updatedAt
      isDeleted
      version
    }
  }
`);

export const locationsPullQueryBuilder = generatePullQuery(
  locationFeedQuery,
  PullQueryContext.Site,
  defaultDoc90Day,
);

const SetLocationMutation = print(gql`
  mutation SetLocation($input: SetLocationInput) {
    setLocation(input: $input)
  }
`);

type SetLocationInput = {
  id: string;
  createdOn: string | null;
  modifiedOn: string | null;
  panelId: string;
  roomId: string | null;
  surveyPointId: string | null;
  passId: string | null;
  sequenceId: string | null;
  startMeters: number | null;
  endMeters: number | null;
  version: number;
};

const mapLocationDocToInput = (doc: LocationDocument): SetLocationInput => {
  return {
    id: doc.id,
    panelId: doc.panel,
    passId: doc.pass || null,
    roomId: doc.room || null,
    sequenceId: doc.sequence || null,
    surveyPointId: doc.surveyPoint || null,
    startMeters: doc.startMeters,
    endMeters: doc.endMeters || null,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    modifiedOn: dayjs(doc.updatedAt).toISOString(),
    version: doc.version,
  };
};

export const locationPushQueryBuilder = generatePushQuery(
  SetLocationMutation,
  (doc: LocationDocument) => ({
    input: mapLocationDocToInput(doc),
  }),
);
