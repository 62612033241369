import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  i18n,
  MaterialPalette,
  TextField,
  Typography,
} from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { ChangeEvent, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { Employee } from '@/rxdb/Employees/queryBuilder';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import useLastInspectionResult from '@/rxdb/InspectionResults/useLastInspectionResult';
import { InspectionType } from '@/utilities/constants';
import { SignatureContext, SignatureType } from '@/utilities/enums';
import { formatInLocalTimezone, getTimeSince } from '@/utilities/hooks/useDateFormatters';
import { useWindowDimensions } from '@/utilities/hooks/useWindowDimensions';
import { sortByFirstName } from '@/utilities/sortHelper';

import useSafeWorkPlanDocument from '../pages/Signatures/SafeWorkPlan/SafeWorkPlanDocumentCard/useSafeWorkPlanDocument';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      padding: '16px !important',
      '& h6': {
        color: `${theme.palette.text.disabled} !important`,
      },
      '& > div:nth-child(3)': {
        '& > div': {
          height: 'calc(100% - 50px)',
        },
      },
    },
    dialogActions: {
      padding: '8px',
    },
    dialogContent: {
      padding: '16px 0',
    },
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
      color: `${theme.palette.text.disabled} !important`,
    },
    subTitle: {},
    subCard: {
      margin: '0 !important',
      marginBottom: '10px !important',
      padding: '16px',
      color: `${theme.palette.text.disabled} !important`,
      boxShadow: 'none !important',
    },
    subCardNoColor: {
      margin: '0 !important',
      marginBottom: '10px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    saveButton: {
      paddingRight: '10px !important',
      '& > div': {
        alignItems: 'flex-end',
      },
    },
    clearButton: {
      maxWidth: '100px !important',
      marginBottom: '10px !important',
    },
    signatureClearContainer: {
      '& > div': {
        justifyContent: 'flex-end',
        flexDirection: 'row',
      },
    },
    popper: {
      zIndex: 999999,
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  onCancel: (showStatusNotification?: boolean) => void;
  onSave: (
    selectedEmployee: Employee,
    customName: string,
    reviewedCuttingPermits: boolean,
    signatureData: string,
    reviewedSafeWorkPlan: boolean | null,
    reasonForVisitValue: string | null,
  ) => Promise<string | number | { signatureId: string; borerShiftSignatureId: string }>;
  onUpdate: (
    selectedEmployee?: Employee,
    customName: string,
    nameNotInList: boolean,
    reviewedCuttingPermits: boolean,
    reviewedSafeWorkPlan: boolean,
    reasonForVisitValue: string,
  ) => void;
  type: SignatureType | undefined;
  selectedEmployee?: Employee;
  selectedCustomName: string;
  nameNotInList: boolean;
  reviewedCuttingPermits: boolean;
  disableEnforceFocus?: boolean;
  disableActionButton?: boolean;
  employees: Employee[];
  backButtonText?: string;
  cardText?: string;
  darkBackground?: boolean;
  safeWorkPlanChecked?: boolean;
  reasonForVisit?: string;
  validateSafeWorkPlan?: boolean;
  context?: SignatureContext;
}

const AddSignatureModal: React.FC<Props> = ({
  open,
  onCancel,
  onSave,
  type,
  selectedEmployee,
  selectedCustomName,
  nameNotInList,
  reviewedCuttingPermits,
  onUpdate,
  disableEnforceFocus = false,
  employees,
  backButtonText = 'Cancel',
  cardText = i18n.t('Add your signature to verify that you have reviewed the Area check summary'),
  darkBackground = false,
  safeWorkPlanChecked = false,
  reasonForVisit = '',
  validateSafeWorkPlan = false, // Signatures added via Area check / equipment
  context,
}: Props) => {
  const classes = useStyles();
  const defaultTitleText = i18n.t('Signature');
  const defaultSaveButtonText = i18n.t('Save');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [names, setNames] = useState<Employee[]>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const { safeWorkPlanDocumentAvailable } = useSafeWorkPlanDocument();

  const { width } = useWindowDimensions();

  // Most Recent Area Check
  const { isCory } = useSiteFeatures();
  const { miningMethod } = useCurrentBorer();
  const areaCheckType = isCory ? InspectionType.CORY_AREA_CHECK : InspectionType.AREA_CHECK;

  const { lastInspection: mostRecentAreaCheck } = useLastInspectionResult(
    areaCheckType,
    miningMethod,
  );

  const signatureCanvasWidth = 520;
  const signatureCanvasHeight = 200;
  let signatureCanvas: SignatureCanvas;

  function resizeCanvas() {
    if (signatureCanvas) {
      signatureCanvas.getCanvas().width = signatureCanvasWidth;
      signatureCanvas.getCanvas().height = signatureCanvasHeight;
      signatureCanvas.getCanvas().style = { maxWidth: '100%' };
      signatureCanvas.clear(); // otherwise isEmpty() might return incorrect value
    }
  }

  useEffect(() => {
    resizeCanvas();
  }, [width]);

  useEffect(() => {
    if (employees) {
      setNames(employees?.sort(sortByFirstName));
    }
  }, [employees]);

  useEffect(() => {
    const isDisabled =
      isSaving || // disable if saving
      !signatureCanvas ||
      signatureCanvas?.isEmpty() || // disable if signature canvas undefined or empty
      (!selectedEmployee && selectedCustomName === '') ||
      (validateSafeWorkPlan && safeWorkPlanDocumentAvailable && !safeWorkPlanChecked); // disable if selectedEmp or visitor custom name blank

    setSaveButtonDisabled(isDisabled);
  }, [
    selectedEmployee,
    selectedCustomName,
    signatureCanvas,
    isDrawing,
    safeWorkPlanDocumentAvailable,
    validateSafeWorkPlan,
    safeWorkPlanChecked,
  ]);

  const clear = () => {
    if (signatureCanvas) {
      setIsDrawing(false);
      signatureCanvas.clear();
      // signatureCanvas.off();    //unbind event handlers
    }
  };

  const getEmployeeLabel = () => {
    if (type === SignatureType.SUPERVISOR && context === SignatureContext.AREA_CHECK) {
      return i18n.t("Supervisor's name");
    }

    switch (type) {
      case SignatureType.VISITOR:
        return i18n.t("Visitor's name");
      case SignatureType.SUPERVISOR:
        return i18n.t("Supervisor's name");
      case SignatureType.OPERATOR:
        return i18n.t("Operator's name");
      case SignatureType.MAINTENANCE:
        return i18n.t("Maintenance worker's name");
      case SignatureType.MINE_STAFF:
        return i18n.t("Mine staff's name");
      case SignatureType.CUTTING_PERMIT:
      case SignatureType.AREA_CHECK:
      case SignatureType.PRE_OP:
        return i18n.t("Reporter's name");
      default:
        return i18n.t("Visitor's name");
    }
  };

  const onEmployeeChanged = (event: any, value: any) => {
    onUpdate(
      value,
      selectedCustomName,
      nameNotInList,
      reviewedCuttingPermits,
      safeWorkPlanChecked,
      reasonForVisit,
    );
  };

  const onCustomNameChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onUpdate(
      selectedEmployee,
      event.target.value,
      nameNotInList,
      reviewedCuttingPermits,
      safeWorkPlanChecked,
      reasonForVisit,
    );
  };

  const onReasonForVisitChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onUpdate(
      selectedEmployee,
      selectedCustomName,
      nameNotInList,
      reviewedCuttingPermits,
      safeWorkPlanChecked,
      event.target.value,
    );
  };

  const onNameNotInListChanged = (evt: any) => {
    onUpdate(
      undefined,
      '',
      evt.target.checked,
      reviewedCuttingPermits,
      safeWorkPlanChecked,
      reasonForVisit,
    );
  };

  const onSafeWorkPlanChanged = (evt: any) => {
    onUpdate(
      selectedEmployee,
      selectedCustomName,
      nameNotInList,
      reviewedCuttingPermits,
      evt.target.checked,
      reasonForVisit,
    );
  };

  const onReviewedCuttingPermitsChanged = (evt: any) => {
    onUpdate(
      selectedEmployee,
      selectedCustomName,
      nameNotInList,
      evt.target.checked,
      safeWorkPlanChecked,
      reasonForVisit,
    );
  };

  const getSignatureDataUrl = () => {
    return signatureCanvas.toDataURL(); // default is PNG
  };

  const closeModal = (showStatusNotification = true) => {
    clear();
    onCancel(showStatusNotification);
  };

  const save = async () => {
    setIsSaving(true);
    await onSave(
      selectedEmployee,
      selectedCustomName,
      reviewedCuttingPermits,
      getSignatureDataUrl(),
      safeWorkPlanDocumentAvailable && validateSafeWorkPlan ? safeWorkPlanChecked : null,
      reasonForVisit,
    );
    setIsSaving(false);
    closeModal(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      titleText={defaultTitleText}
      backButton={false}
      className={classes.dialogRoot}
      disableEnforceFocus={disableEnforceFocus}
      disableBackdropClick
      disableEscapeKeyDown
      dialogActions={
        <Grid container spacing={2} className={classes.dialogActions}>
          <Grid item xs={2}>
            <Button
              onClick={closeModal}
              variant="outlined"
              color="primary"
              noMinHeight
              id="signature-cancel-button"
            >
              {backButtonText}
            </Button>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={2} className={classes.saveButton}>
            <Button
              onClick={save}
              variant="contained"
              color="primary"
              noMinHeight
              disabled={saveButtonDisabled}
              isLoading={isSaving}
              id="signature-save-button"
              data-testid="signature-save-button"
            >
              {defaultSaveButtonText}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="sm"
      maxHeight="900px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
      }}
      classes={{ container: classes.backdrop }}
      data-testid="add-signature"
    >
      <div className={classes.dialogContent}>
        <Card elevation={2} className={classes.subCard}>
          <Typography variant="body1">{cardText}</Typography>
        </Card>
        {type &&
          [
            SignatureType.MAINTENANCE,
            SignatureType.MINE_STAFF,
            SignatureType.OPERATOR,
            SignatureType.SUPERVISOR,
            SignatureType.VISITOR,
          ].includes(type) &&
          context !== SignatureContext.AREA_CHECK && (
            <Card elevation={2} className={classes.subCardNoColor}>
              <Typography variant="body1">
                Last area check completed:{' '}
                {mostRecentAreaCheck && formatInLocalTimezone(mostRecentAreaCheck?.createdOn)} (
                {getTimeSince(mostRecentAreaCheck?.createdOn)})
              </Typography>
            </Card>
          )}
        <Card elevation={2} className={classes.subCard}>
          {!nameNotInList && (
            <Grid container>
              <Grid item xs={6}>
                <AutoComplete
                  list={names}
                  label={getEmployeeLabel()}
                  required
                  getOptionLabel={(employee: Employee) => {
                    return employee.firstName ? `${employee.firstName} ${employee.lastName}` : '';
                  }}
                  onChange={onEmployeeChanged}
                  value={selectedEmployee}
                  showCaret
                  classes={{ popper: classes.popper }}
                  autoSelect={false}
                  data-testid="signature-employee-autocomplete"
                />
              </Grid>
            </Grid>
          )}
          {nameNotInList && (
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  label={i18n.t("Visitor's name")}
                  required
                  onChange={onCustomNameChanged}
                  value={selectedCustomName}
                />
              </Grid>
            </Grid>
          )}
          {type === SignatureType.VISITOR && (
            <Grid container>
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onNameNotInListChanged}
                      name="name-not-in-list-checkbox"
                      checked={nameNotInList}
                    />
                  }
                  label={i18n.t('Name not in the list')}
                />
              </Grid>
            </Grid>
          )}
          {type === SignatureType.SUPERVISOR && context !== SignatureContext.AREA_CHECK && (
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onReviewedCuttingPermitsChanged}
                      name="reviewed-cutting-permits-checkbox"
                      checked={reviewedCuttingPermits}
                    />
                  }
                  label={i18n.t('Reviewed cutting permits')}
                />
              </Grid>
            </Grid>
          )}
        </Card>
        <Card elevation={2} className={classes.subCard}>
          <Grid container>
            <Grid item container xs={8}>
              <Typography variant="h5">{i18n.t('Signature')}</Typography>
            </Grid>
            <Grid
              item
              container
              xs={4}
              justify="flex-end"
              className={classes.signatureClearContainer}
            >
              <Button
                className={classes.clearButton}
                onClick={() => {
                  signatureCanvas.clear();
                  setIsDrawing(!isDrawing);
                }}
                variant="outlined"
                color="primary"
                noMinHeight
                id="signature-clear-button"
              >
                {i18n.t('Clear')}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <SignatureCanvas
                ref={ref => {
                  signatureCanvas = ref;
                }}
                penColor={`${MaterialPalette.text.disabled}`}
                backgroundColor={darkBackground ? '#2d2d2d' : '#404040'}
                canvasProps={{
                  width: signatureCanvasWidth,
                  height: signatureCanvasHeight,
                  className: 'sigCanvas',
                  style: { maxWidth: '100%' },
                }}
                onBegin={() => {
                  setIsDrawing(!isDrawing);
                }}
                onEnd={() => {
                  setIsDrawing(!isDrawing);
                }}
              />
            </Grid>
          </Grid>
          {safeWorkPlanDocumentAvailable && validateSafeWorkPlan && (
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={onSafeWorkPlanChanged}
                      name="name-not-in-list-checkbox"
                      checked={safeWorkPlanChecked}
                    />
                  }
                  label={i18n.t('I have read and understand the Safe Work Plan document.')}
                />
              </Grid>
            </Grid>
          )}
        </Card>
        {type !== SignatureType.AREA_CHECK &&
          type !== SignatureType.PRE_OP &&
          !(type === SignatureType.SUPERVISOR && context === SignatureContext.AREA_CHECK) &&
          type !== SignatureType.CUTTING_PERMIT && (
            <Card elevation={2} className={classes.subCard}>
              <Grid container>
                <Grid item container xs={8}>
                  <Typography variant="h5">{i18n.t('Purpose of visit (optional)')}</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{ position: 'relative' }}>
                    <TextField
                      required
                      onChange={onReasonForVisitChanged}
                      value={reasonForVisit}
                      multiline
                      inputProps={{ style: { minHeight: 200 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
      </div>
    </Dialog>
  );
};

export default AddSignatureModal;
