import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import dayjs from 'dayjs';

export const getDurationByType = (delays: AppointmentModel[]) => {
  return delays.reduce((acc, delay) => {
    if (delay.title !== undefined) {
      const duration = dayjs(delay.endDate).diff(delay.startDate, 'minute');
      if (acc[delay.title]) {
        acc[delay.title] += duration;
      } else {
        acc[delay.title] = duration;
      }
    }
    return acc;
  }, {} as Record<string, number>);
};
