import { Grid } from '@material-ui/core';
import { AutoComplete, ExpansionPanel, i18n } from '@nutrien/cxp-components';
import React from 'react';

import { DeficiencyPanelTypes, DeficiencyStatus } from '../../utilities/enums';
import { useStyles } from './DeficiencyStatusExpansionPanel.styles';

interface Props {
  onExpanded: (expanded: boolean, expansionPanelId: DeficiencyPanelTypes) => void;
  expanded: boolean;
  onStatusSelected: (value: DeficiencyStatus) => void;
  selectedStatus: DeficiencyStatus;
  workOrderRequired: boolean;
  disableAllInputs: boolean;
}

const expansionPanelId = DeficiencyPanelTypes.STATUS;

const StatusOptions = [DeficiencyStatus.ActiveDeficiency, DeficiencyStatus.FullyAddressed];

const DeficiencyDetailsExpansionPanel: React.FC<Props> = ({
  onExpanded,
  expanded,
  onStatusSelected,
  selectedStatus,
  workOrderRequired,
  disableAllInputs,
}: Props) => {
  const classes = useStyles();

  const onExpandPanel = (event: React.ChangeEvent<unknown>, expand: boolean) => {
    onExpanded(expand, expansionPanelId);
  };

  const onStatusChange = (event: any, value: DeficiencyStatus) => {
    onStatusSelected(value);
  };

  const getStatus = (status: string) => status;

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Deficiency status')}
      key="deficiency-status-details"
      data-cy="deficiency-status-details"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={onExpandPanel}
      panelContent={
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              list={workOrderRequired ? [DeficiencyStatus.WOPendingReview] : StatusOptions}
              label={i18n.t('Status')}
              required
              getOptionLabel={getStatus}
              onChange={onStatusChange}
              value={selectedStatus}
              showCaret
              disabled={workOrderRequired || disableAllInputs}
              selectOnFocus={false}
              autoSelect={false}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default DeficiencyDetailsExpansionPanel;
