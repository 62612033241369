import { Grid } from '@material-ui/core';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import React from 'react';

import useEquipment from '@/rxdb/Equipment/useEquipment';

import useStyles from './AssignedEquipment.styles';

const AssignedEquipment: React.FC = () => {
  const classes = useStyles();
  const { assignedEquipmentList } = useEquipment();

  return (
    <Card elevation={1} className={classes.card}>
      <Grid container>
        <Grid item container xs={12}>
          <Grid item xs={10}>
            <Typography variant="h3">{i18n.t('Assigned Equipment')}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.container} item container xs={12}>
          <Grid item xs={3} md={2} className={classes.gridItem}>
            <Typography className={classes.header} variant="h6">
              Type
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} className={classes.gridItem}>
            <Typography className={classes.header} variant="h6">
              Name
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} className={classes.gridItem}>
            <Typography className={classes.header} variant="h6">
              Status
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Typography className={classes.header} variant="h6">
              Comments
            </Typography>
          </Grid>

          {assignedEquipmentList
            .sort((a, b) => {
              if (a.equipmentType?.description === b.equipmentType?.description) {
                return a?.longName?.localeCompare(b?.longName);
              }
              return a.equipmentType?.description.localeCompare(b.equipmentType?.description);
            })
            .map((equipment, index: number) => {
              return (
                <Grid
                  container
                  key={`${equipment.equipmentTypeId}-${index}`}
                  className={classes.item}
                  spacing={2}
                >
                  <Grid item xs={3} md={2} className={classes.gridItem}>
                    <Typography
                      className={`${classes.itemSize} ${classes.header} `}
                      fontWeight={600}
                    >
                      {equipment.equipmentType?.description || ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2} className={classes.gridItem}>
                    <Typography
                      className={`${classes.itemSize} ${classes.header}`}
                      fontWeight={600}
                    >
                      {equipment.longName || equipment.shortName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={1} className={classes.gridItem}>
                    <Typography
                      className={`${classes.itemSize} ${classes.header} ${classes.status} ${
                        equipment.equipmentStatus?.description?.toLowerCase() === 'up'
                          ? classes.up
                          : classes.down
                      }`}
                    >
                      {equipment.equipmentStatus?.description || ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.gridItem}>
                    <Typography className={`${classes.header} ${classes.comment}`} fontWeight={300}>
                      {equipment.comment}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Card>
  );
};

export default AssignedEquipment;
