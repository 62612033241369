import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity } from '../rxdbUtilityFunctions';
import {
  PanelDrawingComment,
  PanelDrawingCommentCollection,
  PanelDrawingCommentDocument,
} from './queryBuilder';

export interface JSONComment {
  commentText: string;
  colourHex: string;
}

export interface PartialPanelDrawingComment {
  id: string;
  panelDrawingId: string;
  comment: string;
}

const usePanelDrawingComment = (currentPanelDrawingId?: string) => {
  const panelDrawingCommentsCollection: PanelDrawingCommentCollection = useRxCollection(
    RxdbCollectionName.PANEL_DRAWING_COMMENTS,
  );

  const [panelDrawingCommentCollectionInitialized, setPanelDrawingCommentCollectionInitialized] =
    useState(false);
  const [currentPanelComments, setCurrentPanelComments] = useState<
    PartialPanelDrawingComment[] | undefined
  >([]);

  useEffect(() => {
    let sub: Subscription;
    if (panelDrawingCommentsCollection) setPanelDrawingCommentCollectionInitialized(true);

    const getCommentsForPanelDrawing = async () => {
      if (!panelDrawingCommentsCollection || !currentPanelDrawingId) {
        return undefined;
      }

      try {
        sub = panelDrawingCommentsCollection
          .find({
            selector: {
              panelDrawingId: currentPanelDrawingId,
            },
            sort: [
              {
                updatedAt: 'desc',
              },
            ],
          })
          .$.subscribe(panelDrawingComments => {
            if (panelDrawingComments.length) {
              const selectedInfo = panelDrawingComments.map(comment => ({
                id: comment.id,
                panelDrawingId: comment.panelDrawingId,
                comment: comment.comment,
              }));
              setCurrentPanelComments(selectedInfo);
            } else {
              setCurrentPanelComments([]);
            }
          });
      } catch (error) {
        console.log('🚀 ~ file: usePanelDrawings.ts ~ line 38 ~ usePanelDrawings ~ error', error);
      }
    };

    getCommentsForPanelDrawing();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
    };
  }, [panelDrawingCommentsCollection, currentPanelDrawingId]);

  const getCommentById = async (commentId: string): Promise<PanelDrawingCommentDocument | null> => {
    if (!panelDrawingCommentsCollection) {
      return null;
    }

    try {
      const result = await panelDrawingCommentsCollection
        .findOne({
          selector: {
            id: commentId,
          },
        })
        .exec();

      return result;
    } catch (error) {
      console.log('🚀 ~ file: usePanelDrawings.ts ~ line 38 ~ usePanelDrawings ~ error', error);
      throw error;
    }
  };

  const updatePanelDrawingComment = async (panelDrawingComment: PartialPanelDrawingComment) => {
    if (!panelDrawingCommentsCollection) return undefined;

    const existingDoc = await getCommentById(panelDrawingComment.id);

    // Existing existingCommentId we will just update the state
    if (existingDoc) {
      return existingDoc.update({
        $set: {
          comment: panelDrawingComment.comment,
          isDeleted: false,
        },
      });
    }
    // New comment we will create a new drawing
    const doc: PanelDrawingComment = {
      ...generateBaseEntity(),
      panelDrawingId: panelDrawingComment.panelDrawingId,
      comment: panelDrawingComment.comment,
    };

    return panelDrawingCommentsCollection.insert(doc);
  };

  return {
    panelDrawingCommentCollectionInitialized,
    updatePanelDrawingComment,
    getCommentById,
    currentPanelComments,
  };
};

export { usePanelDrawingComment };
export default usePanelDrawingComment;
