/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import postal from 'postal';
import type { RxCollection, RxDocument, RxError, RxTypeError } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';
import { ENABLE_DEBUGGER, UNKNOWN_TEXT } from '@/utilities/constants';

import { rootStore } from '../mobx-models/Root';
import { trackEventFn } from '../utilities/hooks/useEventTracking';
import { getBorerShortName } from '../utilities/utilityFunctions';
import { RxdbCollectionName } from './rxdbCollectionName';
import { refreshTokenOnSyncState } from './rxdbUtilityFunctions';
import { CheckpointType } from './types';
export const DEBUG = localStorage.getItem('DEBUG') === 'true';

export const checkForExpiredToken = (err: RxError | RxTypeError) => {
  const errors = err?.parameters?.errors;
  return errors && errors.find(error => error.message.includes('Token has expired.')) !== undefined;
};

const hasServiceOfflineErrors = (err: RxError | RxTypeError) => {
  // If you need to add more foreign key error handling it can be done here
  return err?.parameters?.errors?.[0]?.message.includes(
    'MinesightOperationsApiUnavailableException',
  );
};

const recordIdsFailedToSyncCount: Record<string, number> = {};

/** @type {*} The connections between the rxdb collections and their foreignKeys */
const documentRemovalRelationships: Record<
  string,
  {
    collectionName: string;
    foreignKey: string;
  }[]
> = {
  [RxdbCollectionName.SIGNATURES]: [
    {
      collectionName: RxdbCollectionName.BORER_SHIFT_SIGNATURE,
      foreignKey: 'signatureId',
    },
    {
      collectionName: RxdbCollectionName.INSPECTION_RESULTS,
      foreignKey: 'signatureId',
    },
    {
      collectionName: RxdbCollectionName.CUTTING_PERMIT_SUPERVISOR_COMMENT,
      foreignKey: 'signatureId',
    },
  ],
  [RxdbCollectionName.GROUND_HAZARDS]: [
    {
      collectionName: RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
      foreignKey: 'groundHazardId',
    },
    {
      collectionName: RxdbCollectionName.HAZARD_LOGS,
      foreignKey: 'groundHazard',
    },
    {
      collectionName: RxdbCollectionName.GROUND_CONTROL_SETS,
      foreignKey: 'groundHazardId',
    },
  ],
  [RxdbCollectionName.EQUIPMENT_DEFICIENCY]: [
    {
      collectionName: RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
      foreignKey: 'equipmentDeficiencyId',
    },
    {
      collectionName: RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
      foreignKey: 'equipmentDeficiencyId',
    },
  ],
  [RxdbCollectionName.BORER_SHIFT_CREW]: [
    {
      collectionName: RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
      foreignKey: 'borerShiftCrewId',
    },
  ],
  [RxdbCollectionName.BORER_ACTIVITY]: [
    {
      collectionName: RxdbCollectionName.BORER_SHIFT_ACTIVITY_EMPLOYEES,
      foreignKey: 'borerActivityId',
    },
  ],
};

/**
 * Removes a single document from rxdb, and tracks it in the event tracking system and sentry
 *
 * @param {RxDocument} document
 * @param {RxCollection<any>} collection
 * @param {string} errMessage
 * @param {string} errType
 */
const executeDocumentRemoval = async (
  document: RxDocument<any>,
  collection: RxCollection<any>,
  errMessage: string,
  errType: string,
) => {
  try {
    await trackEventFn(
      'RXDB_PUSH_FAILURE_DOC_REMOVAL',
      {
        collection: collection?.name || UNKNOWN_TEXT,
        documentId: document?.id || UNKNOWN_TEXT,
        document: JSON.stringify(document),
        errMessage,
        errType,
        documentRemoval: true,
      },
      DEBUG,
    );

    if (!document.deleted) await document.remove();
    await collection.storageInstance.cleanup(0);

    delete recordIdsFailedToSyncCount[document.id];
  } catch (error) {
    console.log('Error removing document...', error, document);
    captureInSentryWithDetails(error, {
      rxDBError: true,
      removingDocumentError: true,
      collectionName: collection?.name,
      documentRemoval: true,
      shiftString: rootStore.shiftPicker.shiftString,
    });
  }
};

/**
 * Handles the removal of documents that have failed to sync
 * Checks if the document has any relationships that need to be removed as well
 *
 * @param {string} documentId
 * @param {RxCollection<any>} collection
 * @param {string} errMessage
 * @param {string} errType
 */
const handleDocumentRemoval = async (
  documentId: string,
  collection: RxCollection<any>,
  errMessage: string,
  errType: string,
) => {
  const originalDoc: RxDocument = await collection
    .findOne({
      selector: {
        id: documentId,
      },
    })
    .exec();

  if (!originalDoc) {
    console.log('Document not found in collection...', documentId, collection);
    return;
  }

  if (DEBUG) console.log('Document to be removed...', originalDoc);

  let docsToRemove: Array<{ collection: RxCollection; document: RxDocument }> = [
    { collection, document: originalDoc },
  ];

  // if the document has no relationships, just remove it
  if (!documentRemovalRelationships.hasOwnProperty(collection.name)) {
    if (DEBUG) console.info('No relationships found for collection...', collection.name);

    await executeDocumentRemoval(originalDoc, collection, errMessage, errType);
    postal.publish({
      channel: 'db',
      topic: 'db.restart',
    });
    return;
  }

  // Check if the document has any relationships with docs that need to be removed
  const relationships = documentRemovalRelationships[collection.name];

  for (const relationship of relationships) {
    const { collectionName, foreignKey } = relationship;
    const relationshipCollection = collection.database.collections[collectionName];

    if (relationshipCollection) {
      const relatedDocs = await relationshipCollection
        .find({
          selector: {
            [foreignKey]: documentId,
          },
        })
        .exec();

      if (relatedDocs) {
        if (DEBUG)
          console.log(
            `Found ${relatedDocs.length} related docs in ${collectionName} to remove...`,
            relatedDocs,
          );

        docsToRemove = [
          ...docsToRemove,
          ...relatedDocs.map(doc => ({ collection: relationshipCollection, document: doc })),
        ];
      }
    }
  }

  if (DEBUG) console.info('Documents to be removed...', docsToRemove);

  // remove docs in reverse order so that the original doc is removed last, ie remove the children linked to the original doc first
  for (const { collection: col, document: doc } of docsToRemove.reverse()) {
    if (DEBUG) console.log('Removing document...', doc);
    await executeDocumentRemoval(doc, col, errMessage, errType);

    if (DEBUG) console.log('Document removed...');
  }

  postal.publish({
    channel: 'db',
    topic: 'db.restart',
  });
};

const recordFailedToSyncRecord = (recordId: string) => {
  if (!recordIdsFailedToSyncCount[recordId]) {
    recordIdsFailedToSyncCount[recordId] = 1;
  }
  recordIdsFailedToSyncCount[recordId] += 1;
};

const recordIdHasFailedToSyncMoreThanXTimes = (recordId: string, xTimes = 10) => {
  return recordIdsFailedToSyncCount[recordId] >= xTimes;
};

/**
 * For a given record return the number of times it has failed to sync
 *
 * @param {string} recordId
 */
const errorReportedToSentry = (recordId: string) => recordIdsFailedToSyncCount[recordId] > 0;

const captureRxdbExceptionInSentry = (
  err: RxError | RxTypeError,
  collectionState: RxGraphQLReplicationState<any, CheckpointType>,
  additionalFields: { [key: string]: string | boolean | undefined } = {},
) => {
  const documentsData = err?.parameters?.pushRows?.[0]?.newDocumentState;
  const documentId = documentsData?.id || UNKNOWN_TEXT;
  const shortName = getBorerShortName();
  const hasExpiredToken = checkForExpiredToken(err);
  const { errors } = err?.parameters || {};
  const hasNotSelectedBorer = err?.parameters?.errors?.[0]?.message?.includes(
    "Variable 'borerEquipmentId' has coerced Null",
  );

  Sentry.captureException(err, {
    contexts: {
      documentData: {
        documentData: JSON.stringify(documentsData),
        error: JSON.stringify(errors),
      },
    },
    extra: { documentData: JSON.stringify(documentsData) },
    tags: {
      rxDBError: true,
      shortName,
      hasExpiredToken,
      hasNotSelectedBorer,
      collectionName: collectionState?.collection?.name,
      shiftString: rootStore.shiftPicker.shiftString,
      siteName: rootStore.user.getSiteName(),
      documentId,
      documentRemoval: false,
      ...additionalFields,
    },
  });

  recordFailedToSyncRecord(documentId);
};

//  ----------- Pull handling ------------
const captureRxdbPullExceptionInSentry = (
  err: RxError | RxTypeError,
  collectionState: RxGraphQLReplicationState<any, CheckpointType>,
  additionalFields: { [key: string]: string | boolean | undefined } = {},
) => {
  const shortName = getBorerShortName();
  const hasExpiredToken = checkForExpiredToken(err);
  const hasNotSelectedBorer = err?.parameters?.errors?.[0]?.message?.includes(
    "Variable 'borerEquipmentId' has coerced Null",
  );

  const { checkpoint, errors } = err?.parameters || {};

  Sentry.captureException(err, {
    contexts: {
      documentData: {
        error: JSON.stringify(errors),
      },
    },
    tags: {
      rxDBError: true,
      shortName,
      hasExpiredToken,
      hasNotSelectedBorer,
      siteName: rootStore.user.getSiteName(),
      collectionName: collectionState?.collection?.name,
      shiftString: rootStore.shiftPicker.shiftString,
      lastPulledDocumentId: checkpoint?.id,
      lastPulledUpdatedAt: checkpoint?.updatedAt,
      ...additionalFields,
    },
  });
};

const collectionsFailedToPullCount: Record<string, number> = {}; // key here is a composite of the collection name and the last pulled document id
const incrementCollectionFailedToPullCount = (
  collectionName: string,
  lastPulledDocumentId: string,
) => {
  const key = `${collectionName}-${lastPulledDocumentId}`;
  if (!collectionsFailedToPullCount[key]) {
    collectionsFailedToPullCount[key] = 1;
  }
  collectionsFailedToPullCount[key] += 1;
};

const collectionHasFailedToPullMoreThanXTimes = (
  collectionName: string,
  lastPulledDocumentId: string,
  xTimes = 10,
) => {
  const key = `${collectionName}-${lastPulledDocumentId}`;
  return collectionsFailedToPullCount[key] >= xTimes;
};

const handlePullError = (
  err: RxError | RxTypeError,
  collectionState: RxGraphQLReplicationState<any, CheckpointType>,
) => {
  const { checkpoint } = err?.parameters || {};

  if (!checkpoint) {
    return;
  }

  const collectionName = collectionState?.collection?.name;
  const lastPulledDocumentId = checkpoint?.id;

  if (collectionHasFailedToPullMoreThanXTimes(collectionName, lastPulledDocumentId, 5)) {
    captureRxdbPullExceptionInSentry(err, collectionState, {
      replicationPullError: true,
    });
  } else {
    incrementCollectionFailedToPullCount(collectionName, lastPulledDocumentId);
  }
};

/**
 * Handle the errors that occur during replication push/pull
 *
 * @param {RxGraphQLReplicationState<any>} collectionState
 */
export const handleReplicationErrors = (
  collectionState: RxGraphQLReplicationState<any, CheckpointType>,
) => {
  collectionState.error$.subscribe(async err => {
    // Get the borers name from local storage

    if (err) {
      const hasExpiredToken = checkForExpiredToken(err);

      // if token has expired then refresh it, dont report error
      if (hasExpiredToken) {
        await refreshTokenOnSyncState(collectionState, 6 * 60 * 60, DEBUG);
        return;
      }

      const { message, parameters } = err;
      const { direction, errors } = parameters || {};
      const errorMessages = errors?.map(error => error.message);

      if (errorMessages?.includes('Failed to fetch')) {
        // Network failed dont report error
        if (DEBUG) console.log('Network failed, offline or poor connection, dont report error');
        return;
      }

      if (hasServiceOfflineErrors(err)) {
        // Service is offline
        return;
      }

      // Generic error related to network failure
      if (errorMessages?.includes('Load failed')) {
        return;
      }

      // if a borer is not selected then dont report error
      // Check if a borer is selected
      const hasNotSelectedBorer = err?.parameters?.errors?.[0]?.message?.includes(
        "Variable 'borerEquipmentId' has coerced Null",
      );
      if (hasNotSelectedBorer) {
        return;
      }

      // Log error for debugging
      console.log('🚀 ~ file: handleReplicationErrors.ts:361 ~ err:', err);

      if (direction === 'pull') {
        console.error(err);
        handlePullError(err, collectionState);
        return;
      } else if (direction === 'push') {
        const documentId = parameters?.pushRows?.[0].newDocumentState.id as string;
        // This is handled gracefully in handleOperatorStateFeedEvents.ts
        if (collectionState.collection.name === RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED) {
          return;
        }

        // Don't remove documents if not on latest version
        if (rootStore?.appVersion?.hasNewUpdate) {
          captureRxdbExceptionInSentry(err, collectionState, {
            hasNewUpdateAvailable: true,
            replicationPushError: true,
          });
          return;
        }

        // If the document has failed to sync more than X OR 10 times then remove it
        const removalThresholdByCollection = {
          [RxdbCollectionName.HAZARD_LOGS]: 30,
          [RxdbCollectionName.INSPECTION_RESULTS]: 20,
          [RxdbCollectionName.GROUND_HAZARDS]: 30,
          [RxdbCollectionName.GROUND_CONTROL_SETS]: 30,
          [RxdbCollectionName.BORER_SHIFT_ADVANCE]: 30,
          [RxdbCollectionName.BORER_SHIFT_PRODUCTION]: 30,
          [RxdbCollectionName.BORER_SHIFT_SIGNATURE]: 30,
          [RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS]: 30,
        };

        const removalThreshold =
          removalThresholdByCollection[collectionState.collection?.name] || 10;

        if (recordIdHasFailedToSyncMoreThanXTimes(documentId, removalThreshold)) {
          captureRxdbExceptionInSentry(err, collectionState, {
            replicationPushError: true,
            documentRemoval: true,
          });

          if (ENABLE_DEBUGGER) debugger;

          await handleDocumentRemoval(documentId, collectionState.collection, message, direction);
          return;
        }

        if (recordIdHasFailedToSyncMoreThanXTimes(documentId, 1)) console.error(err);

        // If we have not reported the error, and its happened at least once already, report to sentry
        if (
          !errorReportedToSentry(documentId) &&
          recordIdHasFailedToSyncMoreThanXTimes(documentId, 1)
        ) {
          captureRxdbExceptionInSentry(err, collectionState, {
            replicationPushError: true,
          });
          recordFailedToSyncRecord(documentId);
          return;
        } else {
          //increment the number of times this document has failed to sync
          recordFailedToSyncRecord(documentId);
          return;
        }
      } else {
        // Error on unknown type, report it
        captureRxdbExceptionInSentry(err, collectionState, {
          replicationUnknownError: true,
        });
        return;
      }
    } else {
      // General error, like local DB connection issue
      console.error('rxDB Error - General Error', JSON.stringify(err));
      console.dir(err);
      captureRxdbExceptionInSentry(err, collectionState, {
        replicationUnknownError: true,
      });
    }
  });
};
