import { ApolloQueryResult } from '@apollo/client';

import { client, gql } from './Client';

const getSiteQuery = gql`
  query getSiteQuery($siteId: ID!) {
    getSite(id: $siteId) {
      id
      name
      isDeleted
      version
      distanceUnit
      distanceUnitAbbreviation
      editableAfterShiftEndMinutes
    }
  }
`;

export interface GetSiteResult {
  getSite: {
    id: string;
    name: string;
    isDeleted: boolean;
    version: number;
    distanceUnit: null | string;
    distanceUnitAbbreviation: null | string;
    editableAfterShiftEndMinutes: number;
  };
}

const getSite = (siteId: string): Promise<ApolloQueryResult<GetSiteResult>> =>
  client.query({
    query: getSiteQuery,
    variables: { siteId },
    fetchPolicy: 'network-only',
  });

export { getSite };
