import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      width: '30%',
    },
    marginRight: {
      marginRight: '8px',
    },
    commentMargin: {
      marginBottom: `${theme.spacing(4)}px!important`,
    },
    marginPrevShiftBottom: {
      marginBottom: '130px!important',
    },
  }),
);

export default useStyles;
