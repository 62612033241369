import { HazardSeverity } from '../../utilities/enums';
import { SnackbarBackground } from './types';

export const isHazardSeverity = (modelDescription: string | undefined, hazards: HazardSeverity[]) =>
  modelDescription
    ? hazards.some(hazard => hazard.toUpperCase() === modelDescription.toUpperCase())
    : false;

export const getSnackbarBackground = (modelDescription: string) => {
  if (
    isHazardSeverity(modelDescription, [
      HazardSeverity.SAFE_TO_CUT,
      HazardSeverity.GOOD_TO_CUT,
      HazardSeverity.CONDITION_OKAY,
      HazardSeverity.CONDITIONS_OKAY,
      HazardSeverity.R_CATEGORY_1,
      HazardSeverity.ROOM_OKAY,
    ])
  )
    return SnackbarBackground.SAFE_TO_CUT;

  if (
    isHazardSeverity(modelDescription, [
      HazardSeverity.ADDRESS_AND_PROCEED,
      HazardSeverity.CAUTION,
      HazardSeverity.A_CATEGORY_1,
      HazardSeverity.R_CATEGORY_2,
      HazardSeverity.ROOM_REQ_SUP_ASSES,
    ])
  )
    return SnackbarBackground.ADDRESS_AND_PROCEED;

  if (
    isHazardSeverity(modelDescription, [
      HazardSeverity.STOP_AND_ADDRESS,
      HazardSeverity.A_CATEGORY_2,
    ])
  )
    return SnackbarBackground.STOP_AND_ADDRESS;

  if (
    isHazardSeverity(modelDescription, [
      HazardSeverity.IMMEDIATE_WORK_STOPPAGE,
      HazardSeverity.CORRECTIVE_ACTION_REQUIRED,
      HazardSeverity.A_CATEGORY_3,
      HazardSeverity.R_CATEGORY_3,
      HazardSeverity.ROOM_REQ_ENG_ASSES,
    ])
  )
    return SnackbarBackground.IMMEDIATE_WORK_STOPPAGE;
};
