import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    categoryTitle: {
      maxWidth: '100%',
      marginRight: '30px',
      paddingLeft: '3px',
    },
    locationDetails: {
      minWidth: '300px',
      paddingTop: '10px',
    },
    buttonGroupContainer: {
      marginTop: '8px',
    },
    commentsContainer: {
      padding: '15px 15px 10px 5px',
    },
    createdDate: {
      padding: '10px 0 0 35px',
    },
    dropdown: {
      width: '30%',
    },
  }),
);

export default useStyles;
