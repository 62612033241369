import { Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import { Dispatch, FC, SetStateAction } from 'react';

import { useMst } from '@/mobx-models/Root';
import { ExtendedBorerShiftCrewDocument } from '@/rxdb/BorerShiftCrew/queryBuilder';
import { useDateFormatters } from '@/utilities';
import { SHIFT_WRITE_PERMISSION } from '@/utilities/constants';
import { TIME_FORMAT } from '@/utilities/hooks/useDateFormatters';
import { useViewingShiftOpenForEdit } from '@/utilities/hooks/useViewingShiftOpenForEdit';

import { useStyles } from './CrewCard.styles';

interface Props {
  index: number;
  assignCrewToEdit: (crewIndex: number | undefined, crewOrder: number) => void;
  crew: ExtendedBorerShiftCrewDocument;
  setModifyCrewSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  hideAddCrewButton: boolean;
}

const CrewCard: FC<Props> = ({
  index,
  assignCrewToEdit,
  crew,
  setModifyCrewSidePanelOpen,
  hideAddCrewButton,
}: Props) => {
  const classes = useStyles();
  const { formatDateInTZ } = useDateFormatters();
  const { user } = useMst();

  const viewingShiftOpenForEdit = useViewingShiftOpenForEdit();

  const onEditCrew = () => {
    assignCrewToEdit(index, index + 1);
    setModifyCrewSidePanelOpen(true);
  };

  const onAddCrew = () => {
    assignCrewToEdit(undefined, 2);
    setModifyCrewSidePanelOpen(true);
  };

  // As of MDP-7282, initial roles are missing an employee, so don't display
  const filledCrewMembers = crew?.borerShiftCrewMemberInput?.filter(
    crewMember => crewMember.employee?.id && crewMember.borerShiftCrewMemberRole,
  );

  return (
    <Grid item className={classes.flexItem} xs>
      <Card elevation={2} className={classes.subCard} divClass={classes.flexItem}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography
              variant="h5"
              className={classes.type}
              data-testid={`operating-crew-${index + 1}`}
            >
              {i18n.t('Operating crew ')}
              {crew.version !== 0 ? index + 1 : ''}
            </Typography>
          </Grid>
          <Grid item xs={8} container alignItems="flex-end">
            {user.hasPermissionTo(SHIFT_WRITE_PERMISSION) && viewingShiftOpenForEdit && (
              <>
                <Grid container direction="row" justify="flex-end">
                  <Grid item>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      noMinHeight
                      startAdornment={<Icons.EditFeather className={classes.iconSpacer} />}
                      onClick={onEditCrew}
                      data-cy="edit-crew-button"
                      id="edit-crew-button"
                    >
                      {i18n.t('Edit operating crew')}
                    </Button>
                  </Grid>
                  {!hideAddCrewButton && index === 0 && (
                    <Grid item className={classes.spacer}>
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        noMinHeight
                        startAdornment={<Icons.PlusFeather className={classes.iconSpacer} />}
                        onClick={crew.version === 0 ? onEditCrew : onAddCrew}
                        data-cy="add-another-crew-button"
                        data-testid="add-another-crew-button"
                        id="add-another-crew-button"
                      >
                        {crew.version === 0
                          ? i18n.t('Add operating crew')
                          : i18n.t('Add another operating crew')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {crew.version !== 0 && (
          <Grid container className={classes.crewCardContent}>
            <Grid item className={classes.crewTime}>
              <Typography variant="caption">
                {`${formatDateInTZ(crew?.start, TIME_FORMAT, false)} -
                ${formatDateInTZ(crew?.end, TIME_FORMAT, false)}`}
              </Typography>
            </Grid>
            <Grid item>
              {filledCrewMembers?.map(borerShiftCrewMember => {
                const employee = borerShiftCrewMember.employee;
                return (
                  <Grid
                    container
                    key={employee?.reactKey}
                    alignItems="center"
                    data-testid="crew-member-entry"
                  >
                    <Grid item className={classes.operatorLabel}>
                      <Typography variant="subtitle2">
                        {borerShiftCrewMember.borerShiftCrewMemberRole?.description || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.operatorValue}>
                      <Typography variant="body1">
                        {`${employee?.firstName} ${employee?.lastName}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              {!filledCrewMembers?.length && (
                <Typography variant="body1">{i18n.t('No employees assigned')}</Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

export default observer(CrewCard);
