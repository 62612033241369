import { createStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      '& li': {
        padding: '6px 6px',
      },
    },
    divider: {
      borderBottom: `solid 1px ${theme.palette.text.disabled}`,
      opacity: 0.9,
      fontSize: '14px',
      margin: '10px',
      padding: '0px',
    },
    trashContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      paddingTop: 10,
    },
    errorIcon: {
      '& svg': {
        stroke: `${MaterialPalette.error.main} !important`,
      },
    },
    spacer: {
      marginTop: 10,
    },
  }),
);
