import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { yupResolver } from '@hookform/resolvers/yup';
import { i18n } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import GenericSidePanel from '@/components/GenericSidePanel';
import { useAdvancesForShift } from '@/rxdb/Advance/useAdvancesForShift';
import { applyCuttingTypeIdToAllFutureStates } from '@/rxdb/BorerOperatorChangeFeed/applyCuttingTypeIdToAllFutureStates';
import { saveBorerOperatorChange } from '@/rxdb/BorerOperatorChangeFeed/borerOperatorChangeQueries';
import {
  BorerOperatorChangeState,
  TempBorerOperatorChangeState,
} from '@/rxdb/BorerOperatorChangeFeed/queryBuilder';
import { useBorerOperatorState } from '@/rxdb/BorerOperatorStateFeed/useBorerOperatorState';
import { getStateTypeById, useBorerStateType } from '@/rxdb/BorerStateTypeFeed/useBorerStateType';
import { useCuttingMethod } from '@/rxdb/CuttingMethod/useCuttingMethod';
import { useCuttingType } from '@/rxdb/CuttingType/useCuttingType';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { useNotification } from '@/utilities';
import { correctDateForShift } from '@/utilities/correctDateForShift';
import { formatAsAWSDateTime } from '@/utilities/hooks/useDateFormatters';
import { useShiftHours } from '@/utilities/hooks/useShiftHours';
import { useViewingShiftOpenForEdit } from '@/utilities/hooks/useViewingShiftOpenForEdit';

import DelayEditCard from '../DelayEditCard/DelayEditCard';
import { scrollToCard } from './scrollToCard';
import { useDelaySchema } from './useDelaySchema';

dayjs.extend(utc);

interface Props {
  selectedAppointment: AppointmentModel | null;
  initialValues?: TempBorerOperatorChangeState[];
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  isOnlineWhenDrawerOpened: boolean;
  lastSyncTime?: number;
  editingOngoingAppointment: boolean;
  shiftEndDateUnix: number | null;
  allowCodingAsRunning: boolean;
}

export const fieldArrayName = 'delays';

const DelaysEditDrawer: React.FC<Props> = ({
  selectedAppointment,
  open,
  onClose,
  onOpen,
  onCancel,
  initialValues,
  isOnlineWhenDrawerOpened,
  lastSyncTime,
  editingOngoingAppointment,
  shiftEndDateUnix,
  allowCodingAsRunning = false,
}: Props) => {
  const { removeStates } = useBorerOperatorState();
  const { errorNotification, successNotification } = useNotification();
  const { cuttingTypes, normalCuttingType } = useCuttingType();
  const { cuttingMethods, normalCuttingMethod } = useCuttingMethod();
  const { shiftStartDate, shiftEndDate, shiftType } = useShiftHours();
  const { isRehabBorer } = useCurrentBorer();
  const DelaySchema = useDelaySchema();
  const userViewingShiftOpenForEdit = useViewingShiftOpenForEdit();

  // Store online value from when drawer opened to prevent unexpected behaviour
  const [isOnline, setIsOnline] = useState(isOnlineWhenDrawerOpened);
  const [isSaving, setIsSaving] = useState(false);
  const [applyCuttingTypeToFutureEventsIndex, setApplyCuttingTypeToFutureEventsIndex] = useState<
    null | number
  >(null);

  const [undo, setUndo] = useState(false);

  // should be read only if the shift being viewed is not open for edit
  const readOnly = !userViewingShiftOpenForEdit;

  const { augmentedStateTypesWithCategories, allStateTypesById, runningStateTypes } =
    useBorerStateType(false, isRehabBorer);
  const { advancesForShift, advancesForShiftById } = useAdvancesForShift();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      delays: initialValues,
    },
    mode: 'onChange',
    delayError: 100,
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(DelaySchema),
  });

  const { fields, insert } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const resetFieldsWhenStateTypeChanges = useCallback(
    index => {
      // Reset cutting type and method when state type changes from running to non-running or vice versa
      setValue(`delays.${index}.cuttingTypeId`, null);
      setValue(`delays.${index}.cuttingMethodId`, null);
    },
    [setValue],
  );

  // When one of the times changes make sure to update the other delays time
  const onTimeChanged = useCallback(
    async (
      fieldName: string,
      intIndex: number,
      index: string,
      delayDetails: { delays: TempBorerOperatorChangeState[] },
    ) => {
      // Assign start/end time to the previous/next delay
      const delays = delayDetails.delays;
      if (!delays) return;

      // if start time changed, update the end time of the previous delay
      if (fieldName === 'startTime') {
        const stateType = await getStateTypeById(delays[intIndex].borerStateTypeId);

        if (
          stateType?.isRunning === true &&
          !allowCodingAsRunning &&
          Boolean(lastSyncTime) &&
          delays[intIndex].startTime?.unix() < Math.floor(lastSyncTime / 1000)
        ) {
          // Prevent crash when changing time of running block to before lastSyncTime by removing borerStateTypeId
          setValue(`${fieldArrayName}.${intIndex}.borerStateTypeId`, '');
        }
        const previousDelay = delays[intIndex - 1];
        if (previousDelay) {
          const correctedDate = correctDateForShift(
            delays[intIndex]?.startTime?.clone(),
            shiftStartDate,
            shiftEndDate,
            shiftType,
          );
          setValue(`${fieldArrayName}.${intIndex - 1}.endTime`, correctedDate, {
            shouldValidate: false,
          });
          setValue(`${fieldArrayName}.${intIndex}.startTime`, correctedDate, {
            shouldValidate: false,
          });
        }
      }

      // if end time changed, update the start time of the next delay
      if (fieldName === 'endTime') {
        const nextDelay = delays[intIndex + 1];

        if (nextDelay) {
          const correctedDate = correctDateForShift(
            delays[intIndex]?.endTime?.clone(),
            shiftStartDate,
            shiftEndDate,
            shiftType,
          );
          setValue(`${fieldArrayName}.${intIndex + 1}.startTime`, correctedDate, {
            shouldValidate: false,
          });
          setValue(`${fieldArrayName}.${intIndex}.endTime`, correctedDate, {
            shouldValidate: false,
          });
        }
      }
      setTimeout(() => {
        trigger('delays');
      }, 250);
    },

    [
      fields,
      setValue,
      lastSyncTime,
      shiftStartDate,
      shiftEndDate,
      shiftType,
      trigger,
      allowCodingAsRunning,
    ],
  );

  const onTypeChange = useCallback(
    async (
      fieldName: string,
      intIndex: number,
      index: string,
      delayDetails: { delays: TempBorerOperatorChangeState[] },
    ) => {
      const delays = delayDetails.delays;
      if (!delays) return;

      const newValueIsRunningType = runningStateTypes
        .map(stateType => stateType.id)
        .includes(delays[intIndex]?.borerStateTypeId as string);

      // if the type changed to a running one, update the cutting type to default to normal
      if (isRehabBorer && fieldName === 'borerStateTypeId' && newValueIsRunningType) {
        if (delayDetails.delays[intIndex].cuttingTypeId === null && normalCuttingType)
          setValue(`${fieldArrayName}.${intIndex}.cuttingTypeId`, normalCuttingType.id);
        if (delayDetails.delays[intIndex].cuttingMethodId === null && normalCuttingMethod)
          setValue(`${fieldArrayName}.${intIndex}.cuttingMethodId`, normalCuttingMethod.id);
      }

      if (!newValueIsRunningType) {
        resetFieldsWhenStateTypeChanges(index);
      }
    },
    [
      runningStateTypes,
      isRehabBorer,
      setValue,
      resetFieldsWhenStateTypeChanges,
      normalCuttingType,
      normalCuttingMethod,
    ],
  );

  useEffect(() => {
    const formChangedSubscription = watch((value, { name, type }) => {
      if (type === 'change') {
        if (!name) return;

        const [, index, fieldName] = name.split('.');
        const intIndex = parseInt(index, 10);

        if (fieldName === 'startTime' || fieldName === 'endTime')
          onTimeChanged(fieldName, intIndex, index, value);
        if (fieldName === 'borerStateTypeId') onTypeChange(fieldName, intIndex, index, value);
      }
    });
    return () => {
      formChangedSubscription.unsubscribe();
    };
  }, [
    initialValues,
    open,
    watch,
    onTimeChanged,
    resetFieldsWhenStateTypeChanges,
    runningStateTypes,
    getValues,
    onTypeChange,
  ]);

  useEffect(() => {
    if (open && !undo) {
      reset({
        delays: initialValues,
      });
      setApplyCuttingTypeToFutureEventsIndex(null);

      setIsOnline(isOnlineWhenDrawerOpened);

      // Scroll to selected state
      setTimeout(
        () =>
          scrollToCard(
            selectedAppointment?.isTempState
              ? selectedAppointment.originalId
              : selectedAppointment?.id,
          ),
        400,
      );
    } else if (open) {
      setUndo(false);
    }
  }, [initialValues, reset, open]);

  const handleErrorClose = (message: string) => {
    errorNotification(message);
    onClose();
    setIsSaving(false);
  };

  const onSave = async statesToSave => {
    setIsSaving(true);

    const borerOperatorStates = statesToSave.delays;
    if (!selectedAppointment) return handleErrorClose('No selected delay to edit.');
    if (borerOperatorStates.find(state => state.startTime === undefined))
      return handleErrorClose('Start time is required.');

    const formattedOperatorStates: BorerOperatorChangeState[] = await Promise.all(
      borerOperatorStates.map(async (state, index) => {
        let endTime: string | null = formatAsAWSDateTime(state.endTime || dayjs(), true);
        if (index === borerOperatorStates.length - 1 && editingOngoingAppointment)
          // This is a requirement as per https://nutrien.atlassian.net/browse/MDP-6362
          endTime = null;

        let startTime = formatAsAWSDateTime(state.startTime, true);
        if (index > 0) {
          // Fix cases where gaps were appearing in generated block.
          startTime = formatAsAWSDateTime(borerOperatorStates[index - 1].endTime, true);
        }

        return {
          startTime,
          endTime,
          borerStateTypeId: state.borerStateTypeId,
          borerShiftAdvanceId: state.borerShiftAdvanceId || null,
          comment: state.comment || '',
          originalId: uuidv4(),
          isGeneratedState: false,
          isTempState: true,
          cuttingTypeId: state.cuttingTypeId || null,
          cuttingMethodId: state.cuttingMethodId || null,
        };
      }),
    );

    try {
      await saveBorerOperatorChange(
        selectedAppointment?.borerOperatorStateId,
        formattedOperatorStates,
        selectedAppointment?.existingTempStateId,
      );

      // Hide states from UI (they will come back in feed with new ID)
      await removeStates(selectedAppointment?.borerOperatorStateId);
      successNotification('Delay saved.');
    } catch (error) {
      console.log('🚀 ~ file: DelaysEditDrawer.tsx ~ line 138 ~ error', error);
      return handleErrorClose('Error saving delay.');
    }

    if (applyCuttingTypeToFutureEventsIndex !== null) {
      try {
        const carryForwardState = borerOperatorStates[applyCuttingTypeToFutureEventsIndex];
        if (!carryForwardState) throw new Error('No carry forward state found');
        if (!shiftEndDateUnix) throw new Error('ShiftEndDateUnix is null');

        await applyCuttingTypeIdToAllFutureStates(
          carryForwardState.startTime.unix(),
          shiftEndDateUnix,
          carryForwardState.cuttingTypeId,
        );
        successNotification('Cutting type applied to future events.');
      } catch (error) {
        console.log('🚀 ~ file: DelaysEditDrawer.tsx ~ line 138 ~ error', error);
        return handleErrorClose('Error carrying cutting type forward.');
      }
    }

    onClose();
    setIsSaving(false);
  };

  const handleDelaySplit = useCallback(
    (delay: TempBorerOperatorChangeState, index: number) => {
      if (delay?.startTime && delay?.endTime) {
        const id = uuidv4();
        // update existing delay to be 5 minutes shorter
        setValue(`${fieldArrayName}.${index}.endTime`, delay.startTime.clone().add(5, 'minute'));
        // insert new delay 5 minutes after the existing delay
        const isRunning = allStateTypesById?.[delay?.borerStateTypeId]?.isRunning === true;
        insert(index + 1, {
          ...delay,
          startTime: delay.startTime.clone().add(5, 'minute'),
          endTime: delay.endTime.clone(),
          comment: '',
          originalId: id,
          borerStateTypeId: isRunning ? delay.borerStateTypeId : null,
        });
        setTimeout(() => scrollToCard(id), 300);
      }
    },
    [setValue, insert],
  );

  const handleDelayRemove = (delay: TempBorerOperatorChangeState, index: number) => {
    const temp = [...fields];
    if (index === fields.length - 1) {
      temp[index - 1].endTime = delay.endTime;
    } else if (index > 0 && index < fields.length - 1) {
      temp[index + 1].startTime = temp[index - 1].endTime;
    }

    temp.splice(index, 1);
    setValue(fieldArrayName, temp, {
      shouldValidate: true,
      shouldDirty: true,
    });
    trigger('delays');
  };

  const hasEdits =
    isDirty ||
    fields?.length !== initialValues?.length ||
    applyCuttingTypeToFutureEventsIndex !== null;

  if (!open) return null;
  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={!isRehabBorer ? i18n.t('Edit borer delay') : i18n.t('Edit rehab delay')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={hasEdits && isValid && !readOnly}
        isSaving={isSaving}
        onSave={handleSubmit(onSave)}
        onCancel={onCancel}
        discardNotificationText="Delay draft discarded"
        setUndo={setUndo}
      >
        {fields?.map((state, index) => {
          return (
            <DelayEditCard
              key={state.id}
              control={control}
              state={state}
              errors={errors.delays?.[index]}
              index={index}
              numberOfStates={fields.length}
              handleDelaySplit={handleDelaySplit}
              handleDelayRemove={handleDelayRemove}
              allStateTypes={augmentedStateTypesWithCategories}
              allStateTypesById={allStateTypesById}
              advancesForShift={advancesForShift}
              advancesForShiftById={advancesForShiftById}
              lastSyncTime={lastSyncTime}
              isOnline={isOnline}
              cuttingTypes={cuttingTypes}
              cuttingMethods={cuttingMethods}
              applyCuttingTypeToFutureEventsIndex={applyCuttingTypeToFutureEventsIndex}
              onApplyCuttingTypeToFutureEvents={(panelIndex: number | null) =>
                setApplyCuttingTypeToFutureEventsIndex(panelIndex)
              }
              allowCodingAsRunning={allowCodingAsRunning}
              readOnly={readOnly}
              isRehabBorer={isRehabBorer}
            />
          );
        })}
      </GenericSidePanel>
    </>
  );
};

export default observer(DelaysEditDrawer);
