import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import rxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface PanelLog extends BaseEntity {
  readonly panelId: string;
  readonly borerEquipmentId: string;
  readonly log: string;
  readonly reportedBySiteEmployeeId: string;
  readonly createdOn: string;
  readonly modifiedOn: string;
}

export type PanelLogDocument = RxDocument<PanelLog>;
export type PanelLogCollection = RxCollection<PanelLog> | null;

export const panelLogSchema: RxJsonSchema<PanelLog> = {
  type: 'object',
  version: 2,
  description: 'describes a panel log object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    panelId: { ref: rxdbCollectionName.PANELS, type: 'string', maxLength: 36 },
    borerEquipmentId: { type: 'string', maxLength: 36 },
    log: { type: 'string' },
    reportedBySiteEmployeeId: { ref: rxdbCollectionName.EMPLOYEES, type: 'string', maxLength: 36 },
    modifiedOn: { type: 'string' },
    isDeleted: { type: 'boolean' },
    createdOn: { type: 'string' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
  },
};

export const panelLogMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: PanelLogDocument) => {
    return {
      ...oldDoc,
      modifiedOn: dayjs().toISOString(),
      createdOn: dayjs().toISOString(),
      reportedBySiteEmployeeId: '00000000-0000-0000-0000-000000000000',
    };
  },
  2: (oldDoc: PanelLogDocument) => {
    return {
      ...oldDoc,
    };
  },
};

const panelLogFeedQuery = print(gql`
  query panelLogFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    panelLogFeed(borerEquipmentId: $borerEquipmentId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      panelId
      borerEquipmentId
      log
      createdOn
      modifiedOn
      reportedBySiteEmployeeId
      isDeleted
      version
      updatedAt
    }
  }
`);

export const panelLogsPullQueryBuilder = generatePullQuery(
  panelLogFeedQuery,
  PullQueryContext.Borer,
  defaultDoc90Day,
);

const setPanelLogMutation = print(gql`
  mutation setPanelLog($panelLog: SetPanelLogInput) {
    setPanelLog(input: $panelLog) {
      borerEquipmentId
      createdOn
      id
      isDeleted
      log
      modifiedOn
      panel {
        description
        id
      }
      siteEmployee {
        description
        id
      }
      siteId
      version
    }
  }
`);

type SetPanelLogInput = {
  id: string;
  borerEquipmentId: string;
  panelId: string;
  reportedBySiteEmployeeId: string;
  log: string;
  createdOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapPanelLogDocumentToInput = (doc: PanelLogDocument): SetPanelLogInput => {
  return {
    id: doc.id,
    borerEquipmentId: doc.borerEquipmentId,
    panelId: doc.panelId,
    reportedBySiteEmployeeId: doc.reportedBySiteEmployeeId,
    log: doc.log,
    createdOn: doc.createdOn,
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const panelLogPushQueryBuilder = generatePushQuery(
  setPanelLogMutation,
  (doc: PanelLogDocument) => ({
    panelLog: mapPanelLogDocumentToInput(doc),
  }),
);
