import './quill.snow.css';

import { Grid } from '@material-ui/core';
import { i18n, Typography } from '@nutrien/cxp-components';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';

import useStyles from './GenericRichTextInput.styles';

interface Props {
  title?: string | null;
  subtitle?: string | null;
  commentHTML: string;
  setCommentHTML: (value: string) => void;
}

const toolbarOptions = ['bold', 'italic', 'underline', { list: 'bullet' }];
const modules = {
  toolbar: toolbarOptions,
  clipboard: {
    matchVisual: false,
  },
};

const GenericRichTextInput: React.FC<Props> = ({
  title,
  subtitle,
  commentHTML,
  setCommentHTML,
}: Props) => {
  const classes = useStyles();

  const quillRef = useRef();

  return (
    <Grid container spacing={2}>
      {title && (
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
      )}
      <div className={classes.header}>
        {subtitle && (
          <Typography className={classes.label} variant="body">
            {subtitle}
          </Typography>
        )}
        <ReactQuill
          theme="snow"
          value={commentHTML}
          onChange={setCommentHTML}
          modules={modules}
          ref={quillRef}
          placeholder={i18n.t('Comment...')}
        />
      </div>
    </Grid>
  );
};

export default GenericRichTextInput;
