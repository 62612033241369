import { types } from 'mobx-state-tree';

import { MiningMethodAllCap } from '../../utilities';
import { ApiStatus } from '../../utilities/apiStatus';

export interface PanelType {
  id: string;
  description: string;
  miningMethod: string;
  cuttingType: string;
  rooms: RoomType[];
  surveyPoints: SurveyPointType[];
  sequences: SequenceType[];
  passes: PassType[];
  miningMethodType: MiningMethodAllCap;
}

export interface RoomType {
  id: string;
  description: string;
}

export interface SurveyPointType {
  id: string;
  description: string;
}

export interface SequenceType {
  id: string;
  description: string;
  cuttingType: string;
}

export interface PassType {
  id: string;
  description: string;
  cuttingType: string;
  miningMethod: string;
}

const Room = types.model({
  id: types.identifier,
  description: types.string,
});

const SurveyPoint = types.model({
  id: types.identifier,
  description: types.string,
});

const Sequence = types.model({
  id: types.identifier,
  description: types.string,
  cuttingType: types.string,
});

const Pass = types.model({
  id: types.identifier,
  description: types.string,
  cuttingType: types.string,
  miningMethod: types.string,
});

const Panel = types.model({
  id: types.identifier,
  description: types.string,
  miningMethod: types.string,
  cuttingType: types.string,
  rooms: types.array(Room),
  surveyPoints: types.array(SurveyPoint),
  sequences: types.array(Sequence),
  passes: types.array(Pass),
  miningMethodType: types.enumeration('MiningMethodAllCap', [
    MiningMethodAllCap.CHEVRON,
    MiningMethodAllCap.LONG_ROOM,
  ]),
});

export const Panels = types
  .model({
    panelsStatus: types.enumeration('STATUS', [
      ApiStatus.LOADING,
      ApiStatus.ERROR,
      ApiStatus.SUCCESS,
    ]),
    panelsMap: types.map(Panel),
    panels: types.array(Panel),
  })
  .views(self => ({
    getPanel: (panelId: string): PanelType => {
      return self.panelsMap.get(panelId);
    },
    getPanelName: (panelId: string) => {
      return self.panelsMap.get(panelId)?.description;
    },
    getPanelMiningMethodType: (panelId: string) => {
      return self.panelsMap.get(panelId)?.miningMethodType;
    },
    getPanelSurveyPoint: (panelId: string, surveyPointId: string) => {
      const surveyPoints = self.panelsMap.get(panelId)?.surveyPoints;
      if (!surveyPoints) return undefined;

      const filteredSurveyPoints = surveyPoints.filter(
        surveyPoint => surveyPoint.id === surveyPointId,
      );
      if (filteredSurveyPoints.length === 0) return undefined;

      return filteredSurveyPoints[0];
    },
    getPanelRoom: (panelId: string, roomId: string) => {
      const rooms = self.panelsMap.get(panelId)?.rooms;
      if (!rooms) return undefined;

      const filteredRooms = rooms.filter(room => room.id === roomId);
      if (filteredRooms.length === 0) return undefined;

      return filteredRooms[0];
    },
    getPanelSequence: (panelId: string, sequenceId: string): SequenceType | undefined => {
      const sequences = self.panelsMap.get(panelId)?.sequences;
      if (!sequences) return undefined;

      const filteredSequences = sequences.filter(sequence => sequence.id === sequenceId);
      if (filteredSequences.length === 0) return undefined;

      return filteredSequences[0];
    },
    getPanelPass: (panelId: string, passId: string) => {
      const passes = self.panelsMap.get(panelId)?.passes;
      if (!passes) return undefined;

      const filteredPasses = passes.filter(pass => pass.id === passId);

      if (filteredPasses.length === 0) return undefined;

      return filteredPasses[0];
    },
    getSequencesForPanel: (panelId: string) => {
      const panel = self.panelsMap.get(panelId);
      if (!panel) return [];
      const panelCuttingType = panel.cuttingType;

      const filteredSequences = panel.sequences.filter(
        sequence => sequence.cuttingType === panelCuttingType,
      );
      return filteredSequences;
    },
    getPassForPanel: (panelId: string) => {
      const panel = self.panelsMap.get(panelId);
      if (!panel) return [];
      const panelCuttingType = panel.cuttingType;

      const filteredPasses = panel.passes.filter(pass => pass.cuttingType === panelCuttingType);
      return filteredPasses;
    },
  }));
