import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Button, i18n, Icons } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import DOMPurify from 'isomorphic-dompurify';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '@/mobx-models/Root';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
} from '@/rxdb/BorerShiftSignature/queryBuilder';
import useBorerShiftSignatures from '@/rxdb/BorerShiftSignature/useBorerShiftSignatures';
import { Employee } from '@/rxdb/Employees/queryBuilder';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import {
  GroundHazard,
  GroundHazardCollection,
  GroundHazardDocument,
} from '@/rxdb/GroundHazard/queryBuilder';
import useGroundHazards from '@/rxdb/GroundHazard/useGroundHazards';
import { GroundHazardAttachment } from '@/rxdb/GroundHazardAttachment/queryBuilder';
import useGroundHazardAttachments from '@/rxdb/GroundHazardAttachment/useGroundHazardAttachments';
import {
  InspectionResult,
  InspectionResultCollection,
} from '@/rxdb/InspectionResults/queryBuilder';
import useInspections, {
  InspectionCategoryWithOptions,
  WfaiInspectionDetails,
} from '@/rxdb/Inspections/useInspections';
import usePanelDrawings from '@/rxdb/PanelDrawing/usePanelDrawings';
import RxdbCollectionName from '@/rxdb/rxdbCollectionName';
import { generateBaseEntity, generateBaseEntityWithCreatedOn } from '@/rxdb/rxdbUtilityFunctions';
import { Signature, SignatureCollection } from '@/rxdb/Signature/rxdbSignatureDefinition';
import { useNotification } from '@/utilities';
import { InspectionType, WFAI_CHECK_STORAGE_KEY } from '@/utilities/constants';
import { SignatureType } from '@/utilities/enums';
import useBorerWfai from '@/utilities/hooks/useBorerWfai';

import AddCommentSidePanel from '../AddCommentSidePanel';
import AddHazardsSidePanel from '../AddHazardsSidePanel';
import AddSignatureModal from '../AddSignatureModal';
import AddWorkFaceAreaInspectionCard from '../AddWorkFaceAreaInspectionCard';
import DiscardDraftModal from '../DiscardDraftModal';
import MissingPanelDrawingDialog from '../MissingPanelDrawingDialog';
import PanelDrawingEditModal from '../PanelDrawingEditModal';
import { ImageMetadata } from '../Thumbnail/Thumbnail';
import useStyles from './AddWorkFaceAreaInspection.styles';
import GCHAVerbage from './GCHAVerbage';

export interface AreaCheckInspectionCategory extends InspectionCategoryWithOptions {
  id: string;
  isOriginal: boolean;
  displayOrder: number;
}

export interface AreaCheck {
  id: string;
  displayOrder: number;
  categoryId: string;
  isOriginal: boolean;
  hazards: GroundHazardDocument[];
}

const UNDO_SNACKBAR_KEY = 'AreaCheckUndo';

const retrieveFromLocalStorage = (): WfaiInspectionDetails => {
  const result = localStorage.getItem(WFAI_CHECK_STORAGE_KEY);
  return result !== null ? JSON.parse(result) : undefined;
};

const clearLocalStorage = (force = false) => {
  if (window.location.href.indexOf('undo=true') === -1 || force) {
    localStorage.removeItem(WFAI_CHECK_STORAGE_KEY);
  }
};

const AddWorkFaceAreaInspection: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { errorNotification, successNotification, closeSnackbar } = useNotification();
  const { isCory, isRocanville } = useSiteFeatures();
  const { getInspectionFor, inspectionsInitialized } = useInspections();
  const { groundHazardAttachmentsInitialized, subscribeToGroundHazardAttachments } =
    useGroundHazardAttachments();
  const { shiftPicker } = useMst();
  const { activeGroundHazards } = useGroundHazards({
    endTimeUnix: shiftPicker.selectedShiftEndUTC.unix(),
  });
  const { currentPanelDrawing, panelDrawingsLoaded } = usePanelDrawings();

  const { borerInitialized, miningMethod } = useCurrentBorer();
  const { useWfai } = useBorerWfai();
  const { listEligibleSignees } = useBorerShiftSignatures();
  const [inspectionDetails, setInspectionDetails] = useState<WfaiInspectionDetails>({
    inspection: {},
    wfaiDetails: { signoffCompleted: false },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);

  const [forceRemediatedHazard, setForceRemediatedHazard] = useState(false);

  // signatures
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttinPermits] = useState<boolean>(false);
  const [eligibleEmployees, setEligibleEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  // hazards
  const [addHazardOpen, setAddHazardOpen] = useState<boolean>(false);
  const [hazard, setHazard] = useState<GroundHazard>();
  const [hazardConditionTypeDesc, setHazardConditionTypeDesc] = useState<string>('');
  const [existingAttachmentFileInfo, setExistingAttachmentFileInfo] = useState<ImageMetadata[]>([]);

  // comment
  const [addCommentOpen, setAddCommentOpen] = useState(false);

  // Panel Drawing
  const [panelDrawingModalOpen, setPanelDrawingModalOpen] = useState(false);

  // Wfai Comment
  const [wfaiCommentError, setWfaiCommentError] = useState('');

  // RXDB - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // collections
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection | null = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const inspectionResultCollection: InspectionResultCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  );

  const [miningMethodTimeout, setMiningMethodTimeout] = useState<ReturnType<typeof setTimeout>>();

  const inspectionType = useMemo(() => {
    const { type } = queryString.parse(location.search);
    if (type && typeof type === 'string' && GCHAVerbage[type]) {
      return type;
    }
    return '';
  }, [location]);

  const navigateToAreaCheck = () => {
    history.push('/area-check');
  };

  const setupBlank = async () => {
    let inspectionTypeToGet = InspectionType.AREA_CHECK;
    if (isCory) inspectionTypeToGet = InspectionType.CORY_AREA_CHECK;
    try {
      const inspection = await getInspectionFor(
        inspectionTypeToGet,
        miningMethod, // MiningMethod.LONG_ROOM,
        true,
      );

      if (!inspection) {
        console.error('Missing inspection in DB');
        throw new Error('No inspection found');
      }

      if (useWfai && inspection) {
        setInspectionDetails({
          ...inspection,
          wfaiDetails: {
            newGroundHazardIds: [],
            newRemediatedGroundHazardIds: [],
            signoffCompleted: false,
            newHazardStatus: '',
          },
        });
      }
    } catch (err) {
      errorNotification('Error: Bad data. Please contact an administrator');
      return navigateToAreaCheck();
    }
  };

  const setup = async () => {
    setLoading(true);
    clearTimeout(miningMethodTimeout);
    const { undo } = queryString.parse(location.search);

    try {
      const previousAreaCheckSession = retrieveFromLocalStorage();
      if (previousAreaCheckSession?.inspection?.id && undo) {
        setInspectionDetails(previousAreaCheckSession);
      } else {
        setupBlank();
      }

      const result = await listEligibleSignees(false);
      setEligibleEmployees(result);
    } catch (error) {
      console.log('🚀 ~ file: AddWorkFaceAreaInspection.tsx ~ line 248 ~ setup ~ error', error);
      setLoading(false);
    }
    setLoading(false);
  };

  const saveToLocalStorage = () => {
    localStorage.setItem(WFAI_CHECK_STORAGE_KEY, JSON.stringify(inspectionDetails));
  };

  useEffect(() => {
    if (inspectionDetails?.inspection?.id) saveToLocalStorage();
  }, [inspectionDetails]);

  const notifyAndCloseWhenNoMiningMethodFound = () => {
    setMiningMethodTimeout(
      setTimeout(() => {
        errorNotification(
          `Unable to add ${
            isRocanville ? 'Mining Face Inspection' : 'Work face area inspection'
          } - Ensure Borer is assigned to a location`,
        );
        onClose(true);
      }, 2500),
    );
  };

  const canSave = useMemo(() => {
    if (!inspectionDetails?.wfaiDetails?.signoffCompleted) return false;
    if (!inspectionDetails?.wfaiDetails?.newHazardStatus) return false;
    if (wfaiCommentError) return false;
    return true;
  }, [inspectionDetails, wfaiCommentError]);

  useEffect(() => {
    if (inspectionsInitialized && groundHazardCollection && shiftPicker && miningMethod) {
      setup();
    } else if (inspectionsInitialized && groundHazardCollection && shiftPicker && !miningMethod) {
      notifyAndCloseWhenNoMiningMethodFound();
    }
  }, [inspectionsInitialized, groundHazardCollection, shiftPicker, miningMethod, borerInitialized]);

  const hasEdits = useMemo(() => {
    return (
      inspectionDetails?.wfaiDetails?.newGroundHazardIds?.length ||
      inspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds?.length ||
      inspectionDetails?.wfaiDetails?.newHazardStatus ||
      inspectionDetails?.wfaiDetails?.signoffCompleted ||
      inspectionDetails?.wfaiDetails?.wfaiComment
    );
  }, [inspectionDetails]);

  const onClose = (forceClose = false) => {
    if (!hasEdits || forceClose) {
      clearLocalStorage(true);
      navigateToAreaCheck();
    } else if (hasEdits) {
      setDiscardOpen(true);
    }
  };

  const navigateToAddWorkFaceAreaInspection = () => {
    history.push(`/add-wfai?type=${inspectionType}&undo=true`);
  };

  const onSaveClicked = () => {
    setSignatureModalOpen(true);
  };

  const onHazardUpdated = async (gc: GroundHazardDocument) => {
    // Required to show 'new' indicator on remediated items
    if (gc.remediatedOn) {
      setInspectionDetails(prev => ({
        ...prev,
        wfaiDetails: {
          ...prev.wfaiDetails,
          newRemediatedGroundHazardIds: prev?.wfaiDetails?.newRemediatedGroundHazardIds
            ? [...prev.wfaiDetails.newRemediatedGroundHazardIds, gc.id]
            : [gc.id],
          // If marked as new and then remediated, remove from newGroundHazardIds array
          newGroundHazardIds: prev?.wfaiDetails?.newGroundHazardIds?.filter(ghId => ghId !== gc.id),
        },
      }));
    }
  };

  const onNewHazardCreated = async (gc: GroundHazardDocument) => {
    // Store in inspectionDetails
    try {
      setInspectionDetails(prev => {
        if (gc.remediatedOn) {
          return {
            ...prev,
            wfaiDetails: {
              ...prev.wfaiDetails,
              newRemediatedGroundHazardIds: prev?.wfaiDetails?.newRemediatedGroundHazardIds
                ? [...prev.wfaiDetails?.newRemediatedGroundHazardIds, gc.id]
                : [gc.id],
            },
          };
        }
        if (!gc.remediatedOn) {
          return {
            ...prev,
            wfaiDetails: {
              ...prev.wfaiDetails,
              newGroundHazardIds: prev?.wfaiDetails?.newGroundHazardIds
                ? [...prev.wfaiDetails?.newGroundHazardIds, gc.id]
                : [gc.id],
            },
          };
        }
      });
    } catch (error) {
      console.error(`ERROR => ${error}`);
    }
  };

  const onCancelHazardCreation = () => {
    // Reset button state when add hazard is discarded
    if (
      (inspectionDetails?.wfaiDetails?.newHazardStatus === 'New hazard present' &&
        !inspectionDetails?.wfaiDetails?.newGroundHazardIds?.length) ||
      (inspectionDetails?.wfaiDetails?.newHazardStatus === 'New hazard - remediated' &&
        !inspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds?.length)
    ) {
      setInspectionDetails(prev => ({
        ...prev,
        wfaiDetails: { ...prev.wfaiDetails, newHazardStatus: '' },
      }));
    }
  };

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttinPermits(false);
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    hasReviewedCuttingPermits: boolean,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttinPermits(hasReviewedCuttingPermits);
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    hasReviewedCuttingPermits: boolean,
    signatureData: string,
  ) => {
    if (!shiftPicker.currentBorerShiftId) {
      errorNotification("Can't save signature because no borer shift found was found");
      return;
    }

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntity(),
      signatureType: SignatureType.AREA_CHECK,
      siteEmployeeId: customName ? undefined : employee?.id,
      personnelName: customName,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: shiftPicker.currentBorerShiftId,
      signatureId: signature.id,
      reviewedCuttingPermits: hasReviewedCuttingPermits,
      purposeOfVisit: null,
      reviewedSafeWorkPlan: null,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  const onSaveSignatureAndWFAI = async (
    employee: Employee,
    customName: string,
    hasReviewedCuttingPermits: boolean,
    signatureData: string,
  ) => {
    setIsSaving(true);

    // Save the Signature
    let signature;
    try {
      signature = await onSaveSignature(employee, customName, false, signatureData);
    } catch (error) {
      console.log(
        '🚀 ~ file: AddWorkFaceAreaInspection.tsx ~ line 466 ~ onSaveSignatureAndWFAI ~ error',
        error,
      );
      return errorSaving();
    }

    if (!signature || !inspectionDetails || !shiftPicker.currentBorerShiftId) {
      return errorSaving();
    }

    const inspectionResultBlob = JSON.stringify({
      ...inspectionDetails,
      wfaiDetails: {
        ...inspectionDetails.wfaiDetails,
        activeGroundHazardIds: activeGroundHazards?.map(gh => gh.id) || [],
        verbage: isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType],
        signatureId: signature.signatureId,
        wfaiComment: DOMPurify.sanitize(inspectionDetails?.wfaiDetails?.wfaiComment || '', {
          ALLOWED_TAGS: [],
        }),
        inspectionType,
      },
    });

    const doc: InspectionResult = {
      ...generateBaseEntityWithCreatedOn(),
      inspectionDetail: inspectionResultBlob,
      signatureId: signature.signatureId,
      inspectionId: inspectionDetails.inspection.id,
      borerShiftId: shiftPicker.currentBorerShiftId,
    };

    // Save Inspection
    try {
      await inspectionResultCollection?.insert(doc);
    } catch (error) {
      console.log('🚀 ~ file: AddWorkFaceAreaInspection.tsx ~ line 507 ~ error', error);
      return errorSaving();
    }

    successNotification(
      (isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.success,
    );
    setPanelDrawingModalOpen(true);
  };

  const onAddAnotherHazardClicked = (forceRemediatedHazardInPanel: boolean) => {
    setForceRemediatedHazard(forceRemediatedHazardInPanel);
    setAddHazardOpen(true);
  };

  const errorSaving = () => {
    if (!shiftPicker.currentBorerShiftId) {
      errorNotification(
        i18n.t(`${isRocanville ? 'Mining Face Inspection' : 'Work face area inspection'}
         could not save. Missing borerShiftId. Please try again.`),
      );
    } else {
      errorNotification(
        (isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.errorSaving,
      );
    }
    setSignatureModalOpen(false);
    setIsSaving(false);
  };

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
  };

  const onDiscardDraft = () => {
    setDiscardOpen(false);
    history.push('/area-check');
    setTimeout(clearLocalStorage, 5000);
    errorNotification((isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.discard, {
      key: UNDO_SNACKBAR_KEY,
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            closeSnackbar(key);
            navigateToAddWorkFaceAreaInspection();
          }}
          id="undo-discard-area-check-button"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  const onCommentClicked = async (id: string, conditionTypeDesc: string) => {
    setForceRemediatedHazard(false);
    const gc = await groundHazardCollection?.findOne().where('id').eq(id).exec();
    if (gc) {
      setHazard(gc);
      setHazardConditionTypeDesc(conditionTypeDesc);
      setAddCommentOpen(true);
    }
  };

  const onPanelDrawingEditModalClose = useCallback(() => {
    setPanelDrawingModalOpen(false);
    navigateToAreaCheck();
  }, []);

  const onAddHazardClosed = () => {
    setAddHazardOpen(false);
  };

  const onGchaChecked = (checked: boolean) => {
    setInspectionDetails(prev => ({
      ...prev,
      wfaiDetails: { ...prev.wfaiDetails, signoffCompleted: checked },
    }));
  };

  const onSelectedHazardButtonChange = (value: string) => {
    setInspectionDetails(prev => ({
      ...prev,
      wfaiDetails: { ...prev.wfaiDetails, newHazardStatus: value },
    }));
  };

  const disableHazardButtons = useMemo(() => {
    if (
      inspectionDetails?.wfaiDetails?.newHazardStatus === 'New hazard present' ||
      inspectionDetails?.wfaiDetails?.newHazardStatus === 'New hazard - remediated'
    )
      return true;

    return false;
  }, [inspectionDetails?.wfaiDetails?.newHazardStatus]);

  const showAddHazardModalButton = useMemo(() => {
    if (
      inspectionDetails?.wfaiDetails?.newGroundHazardIds?.length ||
      inspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds?.length
    )
      return true;
    return false;
  }, [
    inspectionDetails?.wfaiDetails?.newGroundHazardIds?.length,
    inspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds?.length,
  ]);

  const getHazardImagesNames = async (attachments: GroundHazardAttachment[]) => {
    setExistingAttachmentFileInfo(attachments);
  };

  useEffect(() => {
    if (groundHazardAttachmentsInitialized && hazard) {
      const queryObj = {
        selector: {
          fileName: { $regex: `.*${hazard.id}*.` },
        },
      };
      subscribeToGroundHazardAttachments(getHazardImagesNames, queryObj);
    }
  }, [groundHazardAttachmentsInitialized, hazard]);

  const handleWfaiCommentUpdate = useCallback(
    (newWfaiCommentValue: string) => {
      setInspectionDetails(prev => ({
        ...prev,
        wfaiDetails: {
          ...prev.wfaiDetails,
          wfaiComment: newWfaiCommentValue.trim() ? newWfaiCommentValue : undefined,
        },
      }));
      if (newWfaiCommentValue.length > 2500) {
        setWfaiCommentError(i18n.t('Cannot exceed 2500 characters'));
      } else {
        setWfaiCommentError('');
      }
    },
    [setInspectionDetails, setWfaiCommentError],
  );

  const title = isRocanville
    ? 'Mining Face Inspection'
    : `Work face area inspection (${inspectionType})`;

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container className={classes.topBar}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="body2">{title}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                onClose(false);
              }}
              data-cy="closeDialogButton"
              id="close-dialog-button"
            >
              <Icons.XFeather strokeWidth={1} color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        {inspectionDetails && (
          <AddWorkFaceAreaInspectionCard
            onCommentClicked={onCommentClicked}
            inspectionDetails={inspectionDetails}
            onAddAnotherHazardClicked={onAddAnotherHazardClicked}
            onSelectedHazardButtonChange={onSelectedHazardButtonChange}
            onGchaChecked={onGchaChecked}
            inspectionType={inspectionType}
            disableHazardButtons={disableHazardButtons}
            wfaiComment={inspectionDetails?.wfaiDetails?.wfaiComment || ''}
            wfaiCommentError={wfaiCommentError}
            handleWfaiCommentUpdate={handleWfaiCommentUpdate}
          />
        )}
        {loading && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignContent="center"
            className={classes.progress}
          >
            <CircularProgress className={classes.spinner} />
          </Grid>
        )}
        <Grid container spacing={2} justify="space-between" className={classes.footerButtonActions}>
          <Grid item>
            <Button
              onClick={() => {
                onClose(false);
              }}
              variant="outlined"
              color="primary"
              noMinHeight
              id="cancel-area-check-button"
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Grid container item spacing={2}>
              {showAddHazardModalButton && (
                <Grid item>
                  <Button
                    onClick={() => onAddAnotherHazardClicked(false)}
                    variant="text"
                    color="primary"
                    noMinHeight
                    id="add-another-hazard-button"
                    startAdornment={
                      <Icons.PlusFeather color="primary" style={{ marginRight: 3 }} />
                    }
                  >
                    {i18n.t('Add another hazard')}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  onClick={onSaveClicked}
                  variant="contained"
                  color="primary"
                  noMinHeight
                  disabled={!canSave || isSaving}
                  isLoading={isSaving}
                  id="save-area-check-button"
                >
                  {i18n.t('Save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddSignatureModal
        open={signatureModalOpen}
        type={SignatureType.AREA_CHECK}
        onCancel={() => {
          setSignatureModalOpen(false);
          clearSignatureData();
        }}
        onSave={onSaveSignatureAndWFAI}
        onUpdate={onUpdateSignature}
        employees={eligibleEmployees}
        selectedEmployee={selectedEmployee}
        selectedCustomName={selectedCustomName}
        nameNotInList={nameNotInList}
        reviewedCuttingPermits={reviewedCuttingPermits}
        disableEnforceFocus
        cardText={(isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.signature}
        darkBackground
      />
      <AddHazardsSidePanel
        open={addHazardOpen}
        hazardConditionType={undefined}
        onClose={onAddHazardClosed}
        onOpen={() => {
          setAddHazardOpen(true);
        }}
        onNewHazardCreated={onNewHazardCreated}
        onCancel={onCancelHazardCreation}
        inspectionId={inspectionDetails?.inspection?.id}
        forceRemediatedHazard={forceRemediatedHazard}
      />
      {hazard && (
        <AddCommentSidePanel
          open={addCommentOpen}
          onClose={() => {
            setAddCommentOpen(false);
          }}
          onOpen={() => {
            setAddCommentOpen(true);
          }}
          conditionId={hazard?.id}
          groundHazard={hazard}
          onHazardUpdated={onHazardUpdated}
          attachmentPrefix={hazardConditionTypeDesc && `Borer_${hazardConditionTypeDesc}_`}
          existingAttachmentFileInfo={existingAttachmentFileInfo}
        />
      )}
      {discardOpen && (
        <DiscardDraftModal
          open={discardOpen}
          onCancel={onCancelDiscardDraft}
          onDiscard={onDiscardDraft}
          disableEnforceFocus
        />
      )}
      {panelDrawingModalOpen && miningMethod && (
        <PanelDrawingEditModal
          open={panelDrawingModalOpen}
          onClose={onPanelDrawingEditModalClose}
          miningMethod={miningMethod}
          saveEnabledByDefault={true}
        />
      )}
      {panelDrawingsLoaded && !currentPanelDrawing && (
        <MissingPanelDrawingDialog onClose={() => navigateToAreaCheck()} />
      )}
    </>
  );
};

export default observer(AddWorkFaceAreaInspection);
