import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerActivity extends BaseEntity {
  readonly activityTypeId: string;
  readonly borerShiftId: string;
  readonly comment: string;
  readonly start: string;
  readonly end: string;
  readonly isFaceChangeCrew: boolean;
  readonly typeName?: string;
  readonly createdOn?: string;
}
export type BorerActivityCollection = RxCollection<BorerActivity> | null;
export type BorerActivityDocument = RxDocument<BorerActivity>;

export const borerActivitySchema: RxJsonSchema<BorerActivity> = {
  type: 'object',
  version: 1,
  description: 'describes a BorerActivity object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    activityTypeId: { type: 'string' },
    borerShiftId: { type: 'string' },
    comment: { type: ['string', 'null'] },
    start: { type: ['string', 'null'] },
    end: { type: ['string', 'null'] },
    isFaceChangeCrew: { type: 'boolean' },
    createdOn: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const borerShiftActivityFeedQuery = print(gql`
  query borerShiftActivityFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    borerShiftActivityFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      borerShiftId
      activityTypeId
      comment
      end
      isDeleted
      start
      updatedAt
      version
    }
  }
`);

export const borerActivityPullQueryBuilder = generatePullQuery(
  borerShiftActivityFeedQuery,
  PullQueryContext.Borer,
);

export const borerShiftActivityMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerActivityDocument) => {
    const newDoc = { ...oldDoc };
    delete newDoc.borerShiftCrewId;

    return newDoc;
  },
};

const setBorerShiftActivityMutation = print(gql`
  mutation SetBorerShiftActivity($borerActivity: SetBorerShiftActivityInput) {
    setBorerShiftActivity(input: $borerActivity) {
      borerShiftId
      id
    }
  }
`);

type SetBorerShiftActivityInput = {
  id: string;
  borerShiftId: string;
  start: string;
  end: string;
  activityTypeId: string;
  comment: string | null;
  createdOn: string | null;
  modifiedOn: string;
  isDeleted: boolean;
  version: number;
};

const mapBorerActivityDocumentToInput = (
  doc: BorerActivityDocument,
): SetBorerShiftActivityInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    start: doc.start,
    end: doc.end,
    activityTypeId: doc.activityTypeId,
    comment: doc.comment,
    createdOn: doc.createdOn || null,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const borerActivityPushQueryBuilder = generatePushQuery(
  setBorerShiftActivityMutation,
  (doc: BorerActivityDocument) => ({
    borerActivity: mapBorerActivityDocumentToInput(doc),
  }),
);

export const borerActivityPushModifer = (doc: BorerActivityDocument) => {
  return doc;
};
