import { useCallback, useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';

import RxdbCollectionName from '../rxdbCollectionName';
import { BorerShiftCrewMemberRole } from './queryBuilder';

export const useBorerShiftCrewMemberRole = () => {
  const queryConstructor = useCallback(collection => collection.find(), []);

  const { result: borerShiftCrewMemberRoles } = useRxData<BorerShiftCrewMemberRole>(
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER_ROLE,
    queryConstructor,
  );

  const borerShiftCrewMemberRolesById: { [key: string]: BorerShiftCrewMemberRole } = useMemo(
    () => borerShiftCrewMemberRoles?.reduce((acc, role) => ({ ...acc, [role.id]: role }), {}),
    [borerShiftCrewMemberRoles],
  );

  return {
    borerShiftCrewMemberRoles: borerShiftCrewMemberRoles.sort(
      (a, b) => a.displayOrder - b.displayOrder,
    ),
    borerShiftCrewMemberRolesById,
  };
};
