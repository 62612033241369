import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { InspectionResultItem } from '@/rxdb/InspectionResults/useInspectionResults';

import ChecksThisShiftCard from '../ChecksThisShiftCard';

type Props = {
  shiftCardInspectionsList: InspectionResultItem[];
};

const AreaCheckChecksTab: React.FC<Props> = (props: Props) => {
  return (
    <Grid container data-cy="AreaCheckConditionsTab">
      <Grid item xs={12}>
        <ChecksThisShiftCard shiftCardInspectionsList={props.shiftCardInspectionsList} />
      </Grid>
    </Grid>
  );
};

export default observer(AreaCheckChecksTab);
