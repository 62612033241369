import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { AssignmentCollection } from '../Assignment/queryBuilder';
import { AssignmentRoleCollection } from '../AssignmentRole/queryBuilder';
import { EmployeeCollection } from '../Employees/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { AssignmentEmployeeCollection, AugmentedAssignmentEmployeeDocument } from './queryBuilder';

export const useAssignmentEmployee = (currentShiftId?: string) => {
  const assignmentEmployeeCollection: AssignmentEmployeeCollection = useRxCollection(
    RxdbCollectionName.ASSIGNMENT_EMPLOYEE,
  );
  const assignmentCollection: AssignmentCollection = useRxCollection(RxdbCollectionName.ASSIGNMENT);
  const employeesCollection: EmployeeCollection = useRxCollection(RxdbCollectionName.EMPLOYEES);
  const assignmentRoleCollection: AssignmentRoleCollection = useRxCollection(
    RxdbCollectionName.ASSIGNMENT_ROLE,
  );

  const [supervisorList, setSupervisorList] = useState<AugmentedAssignmentEmployeeDocument[]>([]);
  const [currentAssignmentId, setCurrentAssignmentId] = useState<string | undefined>();

  useEffect(() => {
    let sub: Subscription | undefined;
    const getCurrentAssignmentId = (shiftId?: string) => {
      if (!shiftId || !assignmentCollection) {
        setCurrentAssignmentId(undefined);
        return;
      }

      try {
        sub = assignmentCollection
          .find({
            selector: {
              shiftId,
            },
          })
          .$.subscribe(assignment => {
            const assignmentId = assignment[0]?.id;
            if (assignmentId) {
              setCurrentAssignmentId(assignmentId);
            } else {
              setCurrentAssignmentId(undefined);
            }
          });
      } catch (error) {
        setCurrentAssignmentId(undefined);
        console.log(
          '🚀 ~ file: useAssignmentEmployee.ts ~ line 18 ~ getAssignmentEmployee ~ error',
          error,
        );
        throw error;
      }
    };

    getCurrentAssignmentId(currentShiftId);

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, [currentShiftId, assignmentCollection]);

  useEffect(() => {
    let assignmentSub: Subscription | undefined;
    const getAssignmentEmployees = async (assignmentId?: string) => {
      if (
        !assignmentId ||
        !assignmentEmployeeCollection ||
        !employeesCollection ||
        !assignmentRoleCollection
      ) {
        setSupervisorList([]);
        return;
      }

      assignmentSub = assignmentEmployeeCollection
        .find({
          selector: {
            assignmentId,
          },
        })
        .$.subscribe(async assignmentEmployeesData => {
          if (assignmentEmployeesData) {
            const assignmentEmployees = await Promise.all(
              assignmentEmployeesData.map(async assignmentEmployee => {
                return {
                  ...assignmentEmployee,
                  employeeInformation: await assignmentEmployee.populate('siteEmployeeId'),
                  assignmentRole: await assignmentEmployee.populate('assignmentRoleId'),
                  comment: assignmentEmployee.comment,
                  id: assignmentEmployee.id,
                  displayOrder: assignmentEmployee.displayOrder,
                };
              }),
            );

            setSupervisorList(assignmentEmployees.sort((a, b) => a.displayOrder - b.displayOrder));
          } else {
            setSupervisorList([]);
          }
        });
    };

    const employeeSub = employeesCollection?.$.subscribe(() =>
      getAssignmentEmployees(currentAssignmentId),
    );
    getAssignmentEmployees(currentAssignmentId);

    return () => {
      if (assignmentSub) assignmentSub.unsubscribe();
      if (employeeSub) employeeSub.unsubscribe();
    };
  }, [currentAssignmentId, assignmentEmployeeCollection, employeesCollection]);

  return {
    supervisorList,
  };
};

export default useAssignmentEmployee;
