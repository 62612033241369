import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { MaterialPalette, Typography } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import React from 'react';

import { TIME_WITH_AM_PM_FORMAT } from '../../utilities/hooks/useDateFormatters';

const useStyles = makeStyles(() =>
  createStyles({
    alertContainer: {
      width: '100%',
      zIndex: `1201 !important`,
      position: 'fixed',
    },
    info: {
      height: 64,
      backgroundColor: '#CD4E5F',
      color: MaterialPalette.common.white,
      borderRadius: '0',
      display: 'flex',
      alignItems: 'center',
    },
    alertSpacer: {
      height: 64,
    },
  }),
);

interface Props {
  lastSyncTime?: number;
}

const DelaysOfflineBar: React.FC<Props> = ({ lastSyncTime }: Props) => {
  const classes = useStyles();

  return (
    <div data-cy="DelaysOfflineBar" className={classes.alertSpacer}>
      <Grid container className={classes.alertContainer}>
        <Grid item xs={12}>
          <Alert severity="info" icon={false} className={classes.info}>
            <Grid container item xs={12} alignItems="center" justify="space-between">
              <Typography variant="body2">
                Borer is currently offline. Last sync time:{' '}
                {!!lastSyncTime && dayjs(lastSyncTime).format(TIME_WITH_AM_PM_FORMAT)}
              </Typography>
            </Grid>
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default DelaysOfflineBar;
