import { useCallback } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { useCurrentBorer } from '../Equipment/useCurrentBorer';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity, getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import { PanelDrawing, PanelDrawingCollection, PanelDrawingDocument } from './queryBuilder';

const usePanelDrawings = () => {
  const { shiftPicker } = useMst();
  const { miningMethod } = useCurrentBorer();

  const panelDrawingCollection: PanelDrawingCollection = useRxCollection<PanelDrawingCollection>(
    RxdbCollectionName.PANEL_DRAWINGS,
  );

  const panelDrawingQueryConstructor = useCallback(
    (collection: RxCollection) => {
      return collection
        .find({
          selector: {
            borerShiftId: shiftPicker.currentBorerShiftId,
            miningMethod,
          },
        })
        .sort({ updatedAt: 'desc' });
    },
    [shiftPicker.currentBorerShiftId, miningMethod],
  );

  const { result: panelDrawingArray, isFetching } = useRxData<PanelDrawingDocument>(
    RxdbCollectionName.PANEL_DRAWINGS,
    panelDrawingQueryConstructor,
  );

  const createNewPanelDrawing = async () => {
    if (!miningMethod) throw new Error('No mining method. Borer must be assigned to a location.');
    if (panelDrawingCollection && shiftPicker.currentBorerShiftId && miningMethod) {
      const doc: PanelDrawing = {
        ...generateBaseEntity(),
        borerShiftId: shiftPicker.currentBorerShiftId,
        drawingData: '{}',
        miningMethod,
      };

      await panelDrawingCollection.insert(doc);
    }
  };

  const updatePanelDrawing = async (
    drawingState: string,
    existingPanelDrawing: PanelDrawingDocument,
  ) => {
    if (!panelDrawingCollection) return undefined;

    const state = JSON.parse(drawingState);
    // Ensure that panelDrawing is returned by feed
    const newState = { ...state, rand: Math.random() };
    const panelDrawingDoc = await panelDrawingCollection?.findOne(existingPanelDrawing.id).exec();
    const updatedAt = getUnixMillisecondTimestamp();

    await panelDrawingDoc?.incrementalModify(oldDoc => ({
      ...oldDoc,
      drawingData: JSON.stringify(newState),
      updatedAt,
    }));
    await new Promise(res => setTimeout(() => res(true), 300)); // Solves MDP-7487
  };

  return {
    updatePanelDrawing,
    panelDrawingCollection,
    currentPanelDrawing: panelDrawingArray[0] || null,
    recentPanelDrawing: panelDrawingArray[1] || null,
    panelDrawingsLoaded: !isFetching,
    createNewPanelDrawing,
  };
};

export { usePanelDrawings };
export default usePanelDrawings;
