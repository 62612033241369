import { cloneDeep } from '@apollo/client/utilities';
import { Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import { USER_TIMEZONE } from '../../utilities/hooks/useDateFormatters';
import CrewCard from '../CrewCard';
import ModifyCrewSidePanel from '../ModifyCrewSidePanel';

const ParentCrewCard: FC = () => {
  const { shiftPicker } = useMst();
  const [modifyCrewSidePanelOpen, setModifyCrewSidePanelOpen] = useState(false);
  const [crewNumber, setCrewNumber] = useState(1);
  const [crewToEdit, setCrewToEdit] = useState<BorerShiftCrewType | undefined | null>();

  const {
    firstBorerShiftCrewPopulated,
    secondBorerShiftCrewPopulated,
    borerShiftCrewCollection,
    borerShiftCrewMemberCollection,
  } = useBorerShiftCrew();

  const initialLoad =
    !borerShiftCrewCollection ||
    !borerShiftCrewMemberCollection ||
    !firstBorerShiftCrewPopulated ||
    !secondBorerShiftCrewPopulated ||
    !shiftPicker.currentBorerShiftId;

  const assignCrewToEdit = (crewIndex: number | undefined, crewOrder: number | undefined) => {
    const currentCrew =
      crewIndex === 0 ? firstBorerShiftCrewPopulated : secondBorerShiftCrewPopulated;
    if (crewIndex !== undefined && currentCrew?.version) {
      setCrewToEdit(
        cloneDeep({
          borerShiftCrewMemberInput: currentCrew.borerShiftCrewMemberInput,
          borerShiftId: shiftPicker.currentBorerShiftId || '',
          startDateTime: dayjs.tz(currentCrew?.start, USER_TIMEZONE),
          endDateTime: dayjs.tz(currentCrew?.end, USER_TIMEZONE),
          id: currentCrew?.id,
          version: currentCrew?.version,
          crewNumber: currentCrew?.crewNumber,
          index: crewIndex,
        }),
      );
      setCrewNumber(currentCrew?.crewNumber);
    } else {
      setCrewToEdit(undefined);
      setCrewNumber(crewOrder);
    }
  };

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const { search } = location;

    const { addCrew } = queryString.parse(search);

    if (addCrew && initialLoad === false) {
      assignCrewToEdit(0, undefined);
      setModifyCrewSidePanelOpen(true);
      history.push('/shift-start');
    }
  }, [location, initialLoad]);

  return (
    <>
      <Grid container alignItems="stretch" direction="row" spacing={1}>
        {firstBorerShiftCrewPopulated && !firstBorerShiftCrewPopulated?.hidden && (
          <CrewCard
            crew={firstBorerShiftCrewPopulated}
            index={0}
            assignCrewToEdit={assignCrewToEdit}
            setModifyCrewSidePanelOpen={setModifyCrewSidePanelOpen}
            hideAddCrewButton={true}
            key={firstBorerShiftCrewPopulated.id}
          />
        )}
        {secondBorerShiftCrewPopulated && !secondBorerShiftCrewPopulated?.hidden && (
          <CrewCard
            crew={secondBorerShiftCrewPopulated}
            index={1}
            assignCrewToEdit={assignCrewToEdit}
            setModifyCrewSidePanelOpen={setModifyCrewSidePanelOpen}
            hideAddCrewButton={true}
            key={secondBorerShiftCrewPopulated.id}
          />
        )}
      </Grid>
      <ModifyCrewSidePanel
        open={modifyCrewSidePanelOpen}
        setModifyCrewSidePanelOpen={setModifyCrewSidePanelOpen}
        crewToEdit={crewToEdit}
        crewNumber={crewNumber}
        triggerRefresh={() => {}}
      />
    </>
  );
};

export default observer(ParentCrewCard);
