import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { AreaCheckResult, CuttingPermitResult, PreOpResult } from '@/rxdb/InspectionResults/types';
import { useNotification } from '@/utilities/index';
import { jsonMapReviver } from '@/utilities/utilityFunctions';

import { useMst } from '../../mobx-models/Root';
import { InspectionResultDocument } from '../../rxdb/InspectionResults/queryBuilder';
import useInspectionResults, {
  InspectionResultItem,
} from '../../rxdb/InspectionResults/useInspectionResults';
import { InspectionDocument } from '../../rxdb/Inspections/queryBuilder';
import { InspectionOptionsWithDetails } from '../../rxdb/Inspections/useInspections';
import useLocations, { PopulatedLocation } from '../../rxdb/Locations/useLocations';
import useShifts from '../../rxdb/Shifts/useShifts';
import {
  EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION,
  GROUND_HAZARD_WRITE_PERMISSION,
  InspectionType,
} from '../../utilities/constants';
import { HazardSeverity, WorkFaceAreaInspectionType } from '../../utilities/enums';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import useViewingCurrentShift from '../../utilities/hooks/useViewingCurrentShift';
import { sortDescendingByInspectionResultCreatedOn } from '../../utilities/sortHelper';
import { AreaCheck } from '../AddAreaCheck/AddAreaCheck';
import AddHazardsSidePanel from '../AddHazardsSidePanel';
import AreaCheckCard from '../AreaCheckCard';
import AreaCheckReadOnlyModal from '../AreaCheckReadOnlyModal';
import CuttingPermitCard from '../CuttingPermitCard';
import CuttingPermitModal from '../CuttingPermitModal';
import { blankLocation } from '../CuttingPermitModal/CuttingPermitModal';
import HazardMenu from '../HazardMenu';
import AddPreOpCheck from '../pages/Equipment/AddPreOpCheck';
import PreOpCard from '../pages/Equipment/DeficienciesChecksTab/PreOpCheckCard/PreOpCard';
import WorkFaceAreaInspectionReadOnlyModal from '../WorkFaceAreaInspectionReadOnlyModal';
import WorkFaceAreaInspectionSummary from '../WorkFaceAreaInspectionSummary';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '16px',
    },
    cardRoot: {
      margin: '4px !important',
      padding: '16px',
    },
    title: {
      paddingLeft: '4px',
      paddingBottom: '16px',
    },
    inspectionCards: {
      margin: '10px 0 0 -5px',
    },
  }),
);

type Props = {
  shiftCardInspectionsList: InspectionResultItem[];
};

const ChecksThisShiftCard: React.FC<Props> = ({ shiftCardInspectionsList }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user, shiftPicker } = useMst();
  const { listPreOpChecksThisShift, inspectionsInitialized, inspectionResultsCollection } =
    useInspectionResults();
  const { isVanscoy } = useSiteFeatures();

  const { trackEvent } = useEventTracking();
  const viewingCurrentShift = useViewingCurrentShift();
  const { viewingMostRecentAvailableShift } = useShifts();
  const { getLocationById, populateLocation } = useLocations();
  const { errorNotification } = useNotification();
  const { isRehabBorer } = useCurrentBorer();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [inspectionsList, setInspectionsList] =
    useState<InspectionResultItem[]>(shiftCardInspectionsList);
  const [cuttingPermitOpen, setCuttingPermitOpen] = useState(false);
  const [areaCheckOpen, setAreaCheckOpen] = useState(false);
  const [selectedInspectionDetails, setSelectedInspectionDetails] = useState<InspectionDocument>();
  const [inspectionComment, setInspectionComment] = useState<string | null>('');
  const [inspectionSupervisorComment, setInspectionSupervisorComment] = useState<string | null>('');

  const [selectedInspectionOptions, setSelectedInspectionOptions] = useState<
    Record<string, InspectionOptionsWithDetails>
  >({});
  const [selectedInspectionResults, setSelectedInspectionResults] =
    useState<InspectionResultDocument>();
  const [selectedInspectionSeverity, setSelectedInspectionSeverity] = useState<HazardSeverity>();
  const [selectedAreaCheckHazards, setSelectedAreaCheckHazards] =
    useState<Record<string, string[]>>();
  const [selectedInspectionLocation, setSelectedInspectionLocation] =
    useState<PopulatedLocation | null>(blankLocation);
  const [selectedSignatureId, setSelectedSignatureId] = useState<string | null | undefined>();
  const [addHazardsOpen, setAddHazardsOpen] = useState(false);
  const [addCuttingPermitOpen, setAddCuttingPermitOpen] = useState(false);
  const [workFaceAreaInspectionOpen, setWorkFaceAreaInspectionOpen] = useState(false);
  const [selectedWorkFaceAreaInspectionId, setSelectedWorkFaceAreaInspectionId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [preOpChecks, setPreOpChecks] = useState<InspectionResultItem[]>([]);
  const [preOpResult, setPreOpResult] = useState<PreOpResult>();
  const [signatureId, setSignatureId] = useState<string | null>('');
  const [currentInspectionType, setCurrentInspectionType] = useState<InspectionType | undefined>();
  const [preOpOpen, setPreOpOpen] = useState(false);
  const hazardsMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    setInspectionsList(shiftCardInspectionsList);
  }, [shiftCardInspectionsList]);

  const onCuttingPermitSelected = async (inspectionResult: InspectionResultDocument) => {
    let inspectionDetail: CuttingPermitResult | string | null;

    try {
      inspectionDetail = JSON.parse(inspectionResult.inspectionDetail);
    } catch (error) {
      inspectionDetail = inspectionResult.inspectionDetail;
    }

    if (typeof inspectionDetail === 'string' || inspectionDetail === null) {
      // TODO: Handle Error
      return null;
    }

    const {
      cuttingPermitConfig,
      cuttingPermitSelections,
      cuttingPermitSeverity,
      comment,
      supervisorComment,
    } = inspectionDetail;

    // Generate selected options object for viewing cutting permit to work
    const selectedOptions = {};
    cuttingPermitConfig.categories.forEach(category => {
      const { id } = category.category;
      const selectedOptionId = cuttingPermitSelections[id].option.id;
      const option = category.options.filter(opt => opt.option.id === selectedOptionId);
      selectedOptions[id] = option[0];
    });

    let location: PopulatedLocation | null = blankLocation;
    if (inspectionResult.locationId) {
      const locationDoc = await getLocationById(inspectionResult.locationId);
      location = await populateLocation(locationDoc);
    }
    setSelectedInspectionLocation(location);
    setSelectedInspectionDetails(cuttingPermitConfig);
    setSelectedInspectionResults(inspectionResult);
    setSelectedInspectionOptions(selectedOptions);
    setSelectedInspectionSeverity(cuttingPermitSeverity);
    setInspectionComment(comment);
    setInspectionSupervisorComment(supervisorComment);
    setCuttingPermitOpen(true);
  };

  const onAreaCheckSelected = (inspectionResult: InspectionResultDocument) => {
    let inspectionDetail: AreaCheckResult | string | null;

    try {
      inspectionDetail = JSON.parse(inspectionResult.inspectionDetail);
    } catch (error) {
      inspectionDetail = inspectionResult.inspectionDetail;
    }

    if (typeof inspectionDetail === 'string' || inspectionDetail === null) {
      // TODO: Handle Error
      return null;
    }

    const { areaCheckConfig, areaCheckSelections, areaCheckHazards } = inspectionDetail;

    // Generate selected options object for viewing cutting permit to work
    const selectedOptions = {};
    areaCheckHazards.forEach((x: AreaCheck) => {
      selectedOptions[x.id] = areaCheckSelections[x.id].option;
    });

    setSelectedInspectionDetails(areaCheckConfig);
    setSelectedInspectionResults(inspectionResult);
    setSelectedInspectionOptions(selectedOptions);
    setSelectedAreaCheckHazards(areaCheckHazards);
    setSelectedSignatureId(inspectionResult.signatureId);
    setAreaCheckOpen(true);
  };

  // Render inspection cards
  const getInspectionCards = () => {
    if (!inspectionsList) return [];

    return inspectionsList
      .sort(sortDescendingByInspectionResultCreatedOn)
      .map((inspection: { inspectionDetails: any; inspectionResult: any }) => {
        const { inspectionDetails, inspectionResult } = inspection;
        const inspectionType = inspectionDetails?.inspectionType;

        if (
          inspectionType === InspectionType.CUTTING_PERMIT ||
          inspectionType === InspectionType.REHAB_CUTTING_PERMIT
        ) {
          return (
            <Grid item xs={12} key={`${inspectionResult.id}-${inspectionResult.createdOn}`}>
              <CuttingPermitCard
                inspectionDetails={inspectionDetails}
                inspectionResult={inspectionResult}
                onCuttingPermitSelected={onCuttingPermitSelected}
              />
            </Grid>
          );
        }
        if (
          inspectionType === InspectionType.AREA_CHECK ||
          inspectionType === InspectionType.CORY_AREA_CHECK ||
          inspectionType === InspectionType.CORY_LUNCH_AREA_CHECK ||
          inspectionType === InspectionType.CORY_REFUGE_CHECK ||
          inspectionType === InspectionType.CORY_MONTHLY_PANEL_INSPECTION ||
          inspectionType === InspectionType.VANSCOY_AREA_PREOP_CUTTING_CHECK ||
          inspectionType === InspectionType.VANSCOY_PANEL_INSPECTION ||
          inspectionType === InspectionType.AREA_CHECK_LUNCH_TABLE ||
          inspectionType === InspectionType.AREA_CHECK_EXTENSIBLE_DRIVE ||
          inspectionType === InspectionType.AREA_CHECK_MINING_FACE ||
          inspectionType === InspectionType.AREA_CHECK_BREAKTHROUGH_ROOM ||
          inspectionType === InspectionType.AREA_CHECK_REFUGE ||
          inspectionType === InspectionType.BIT_RACK_CHECK
        ) {
          const parsedInspectionDetail = JSON.parse(inspectionResult.inspectionDetail);

          if (parsedInspectionDetail?.wfaiDetails) {
            return (
              <Grid item xs={12} key={`${inspectionResult.id}-${inspectionResult.createdOn}`}>
                <WorkFaceAreaInspectionSummary
                  inspectionResult={inspectionResult}
                  onWorkFaceAreaInspectionSelected={onWorkFaceAreaInspectionSelected}
                />
              </Grid>
            );
          }

          return (
            <Grid item xs={12} key={`${inspectionResult.id}-${inspectionResult.createdOn}`}>
              <AreaCheckCard
                inspectionDetails={inspectionDetails}
                inspectionResult={inspectionResult}
                onAreaCheckSelected={onAreaCheckSelected}
              />
            </Grid>
          );
        }
        return <span key={`${uuidv4()}`} />;
      });
  };

  // area check view only
  const onOpenAreaCheckModal = () => {
    setAreaCheckOpen(true);
  };

  const onCloseAreaCheckModal = () => {
    setAreaCheckOpen(false);
  };

  const onCloseWorkFaceAreaInspectionModal = () => {
    setWorkFaceAreaInspectionOpen(false);
    setSelectedWorkFaceAreaInspectionId('');
  };

  const onWorkFaceAreaInspectionSelected = (id: string) => {
    setSelectedWorkFaceAreaInspectionId(id);
    setWorkFaceAreaInspectionOpen(true);
  };

  // read only cutting permit
  const onOpenCuttingPermitModal = () => {
    setCuttingPermitOpen(true);
  };

  const onCloseCuttingPermitModal = () => {
    setCuttingPermitOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClosePreOp = () => {
    setPreOpOpen(false);
    handleClose();
  };

  const onAddAreaCheck = (areaCheckType = InspectionType.AREA_CHECK) => {
    if (
      areaCheckType === InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING ||
      areaCheckType === InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING
    ) {
      setCurrentInspectionType(areaCheckType);
      setPreOpOpen(true);
      handleClose();
    } else {
      history.push(`/add-areacheck?type=${areaCheckType}`);
    }
  };

  const onAddWorkAreaInspection = (workFaceAreaInspectionType: WorkFaceAreaInspectionType) => {
    if (workFaceAreaInspectionType === WorkFaceAreaInspectionType.PRODUCTION) {
      history.push('/add-wfai?type=production');
    } else if (workFaceAreaInspectionType === WorkFaceAreaInspectionType.OTHER) {
      history.push('/add-wfai?type=other');
    }
  };
  const onAddCuttingPermit = () => {
    handleClose();
    setAddCuttingPermitOpen(true);
  };

  const onCloseAddCuttingPermitModal = () => {
    setAddCuttingPermitOpen(false);
    setAnchorEl(null);
  };

  const showAddHazardButton = useMemo(() => {
    return viewingCurrentShift || viewingMostRecentAvailableShift;
  }, [viewingCurrentShift, viewingMostRecentAvailableShift]);

  const getPreOpChecks = async () => {
    setIsLoading(true);
    try {
      setPreOpChecks(await listPreOpChecksThisShift());
    } catch (error) {
      console.log('🚀 ~ file: ChecksThisShiftCard.tsx ~ line 349  ~ getPreOpChecks ~ error', error);
      errorNotification('Unable to load checks this shift.');
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  useEffect(() => {
    let subscription: Subscription;
    if (inspectionsInitialized && inspectionResultsCollection) {
      getPreOpChecks();

      subscription = inspectionResultsCollection.$.subscribe(() => {
        getPreOpChecks();
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [inspectionsInitialized, shiftPicker.Date, shiftPicker.Type, shiftPicker.currentBorerShiftId]);

  // View PreOp check
  const viewPreOpCheck = (inspectionResult: InspectionResultDocument) => {
    let inspectionDetail: PreOpResult | string | null;

    try {
      inspectionDetail = JSON.parse(inspectionResult.inspectionDetail, jsonMapReviver);
    } catch (error) {
      inspectionDetail = inspectionResult.inspectionDetail;
    }

    if (typeof inspectionDetail === 'string' || inspectionDetail === null) return;
    setSignatureId(inspectionResult.signatureId);
    setPreOpResult(inspectionDetail);
    if (
      inspectionDetail?.preOpConfig?.inspection?.inspectionType &&
      Object.values(InspectionType).includes(
        inspectionDetail.preOpConfig.inspection.inspectionType as InspectionType,
      )
    ) {
      setCurrentInspectionType(
        inspectionDetail.preOpConfig.inspection.inspectionType as InspectionType,
      );
    }
    setPreOpOpen(true);
  };

  return (
    <>
      <Card>
        <Grid container data-cy="ChecksThisShiftCard" className={classes.container}>
          <Grid item container xs={12}>
            <Grid item xs={10} className={classes.title}>
              <Typography variant="h3">{i18n.t('Checks this shift')}</Typography>
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              {user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION) && showAddHazardButton && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    noMinHeight
                    startAdornment={<Icons.PlusFeather />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    data-cy="add-menu"
                    id="add-menu"
                  >
                    {i18n.t('Add...')}
                  </Button>
                  <HazardMenu
                    open={hazardsMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    openAddHazard={() => {
                      handleClose();
                      setAddHazardsOpen(true);
                      trackEvent(TrackingEventType.HAZARD_ADD_OPEN, {
                        location: 'CHECKS_THIS_SHIFT',
                      });
                    }}
                    onAddAreaCheck={onAddAreaCheck}
                    onAddWorkAreaInspection={onAddWorkAreaInspection}
                    onAddCuttingPermit={onAddCuttingPermit}
                    isCurrentShiftSelected={shiftPicker.isCurrentShiftSelected()}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} container className={classes.inspectionCards}>
            {!(isRehabBorer && isVanscoy) && getInspectionCards()}
            {isRehabBorer && isVanscoy && isLoading && <CircularProgress />}
            {isRehabBorer &&
              isVanscoy &&
              !isLoading &&
              preOpChecks.length > 0 &&
              preOpChecks.map(check => (
                <Grid item xs={12} key={check.inspectionResult.id}>
                  <PreOpCard
                    inspectionDetails={check.inspectionDetails}
                    inspectionResult={check.inspectionResult}
                    onSelected={viewPreOpCheck}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Card>
      {areaCheckOpen && (
        <AreaCheckReadOnlyModal
          open={areaCheckOpen}
          onClose={onCloseAreaCheckModal}
          onOpen={onOpenAreaCheckModal}
          inspectionDetailsSource={selectedInspectionDetails}
          selectedOptionsSource={selectedInspectionOptions}
          areaChecks={selectedAreaCheckHazards}
          signatureId={selectedSignatureId}
          createdOn={selectedInspectionResults?.createdOn}
        />
      )}
      {workFaceAreaInspectionOpen && (
        <WorkFaceAreaInspectionReadOnlyModal
          open={workFaceAreaInspectionOpen}
          onClose={onCloseWorkFaceAreaInspectionModal}
          inspectionId={selectedWorkFaceAreaInspectionId}
        />
      )}
      <AddHazardsSidePanel
        open={addHazardsOpen}
        onClose={() => {
          setAddHazardsOpen(false);
        }}
        onOpen={() => {
          setAddHazardsOpen(true);
        }}
      />
      {/* TODO: Refactor so it's not necessary to render it twice */}
      {/* Create Cutting Permit Modal */}
      <CuttingPermitModal
        open={addCuttingPermitOpen}
        onClose={onCloseAddCuttingPermitModal}
        onOpen={onAddCuttingPermit}
        inspectionDetailsSource={selectedInspectionDetails}
        selectedOptionsSource={selectedInspectionOptions}
        severityLevel={selectedInspectionSeverity}
        createdOn={selectedInspectionResults?.createdOn}
      />
      {/* Modify Cutting Permit Modal */}
      <CuttingPermitModal
        open={cuttingPermitOpen}
        onClose={onCloseCuttingPermitModal}
        onOpen={onOpenCuttingPermitModal}
        inspectionDetailsSource={selectedInspectionDetails}
        selectedOptionsSource={selectedInspectionOptions}
        severityLevel={selectedInspectionSeverity}
        createdOn={selectedInspectionResults?.createdOn}
        signatureId={selectedInspectionResults?.signatureId}
        inspectionComment={inspectionComment}
        inspectionLocation={selectedInspectionLocation}
        inspectionSupervisorComment={inspectionSupervisorComment}
        inspectionResultId={selectedInspectionResults?.id}
      />
      <AddPreOpCheck
        open={preOpOpen}
        onClose={onClosePreOp}
        onOpen={onAddAreaCheck}
        viewOnly={
          preOpResult !== undefined ||
          !user.hasPermissionTo(EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION)
        }
        inspectionDetailsSource={preOpResult?.preOpConfig}
        selectedOptionsSource={preOpResult?.preOpSelections}
        createdEquipmentDeficiencies={preOpResult?.preOpDeficiencies}
        signatureId={signatureId}
        inspectionType={currentInspectionType}
      />
    </>
  );
};

export default observer(ChecksThisShiftCard);
