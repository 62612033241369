import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerShiftActivityEmployee extends BaseEntity {
  readonly borerActivityId: string;
  readonly siteEmployeeId: string;
}
export type BorerShiftActivityEmployeeCollection = RxCollection<BorerShiftActivityEmployee> | null;
export type BorerShiftActivityEmployeeDocument = RxDocument<BorerShiftActivityEmployee>;

export const borerShiftActivityEmployeeSchema: RxJsonSchema<BorerShiftActivityEmployee> = {
  type: 'object',
  version: 1,
  description: 'describes a BorerShiftActivityEmployee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerActivityId: { type: 'string' },
    siteEmployeeId: { type: 'string', ref: RxdbCollectionName.EMPLOYEES },
  },
};

const borerShiftActivitySiteEmployeeFeedQuery = print(gql`
  query borerShiftActivitySiteEmployeeFeed(
    $lastUpdateAt: Float!
    $borerEquipmentId: ID!
    $limit: Int!
  ) {
    borerShiftActivitySiteEmployeeFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      siteEmployeeId
      borerActivityId
      updatedAt
      isDeleted
      version
    }
  }
`);

export const borerShiftActivityEmployeePullQueryBuilder = generatePullQuery(
  borerShiftActivitySiteEmployeeFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftActivityEmployeeMutation = print(gql`
  mutation setBorerShiftActivityEmployee($borerActivity: SetBorerShiftActivityEmployeeInput) {
    setBorerShiftActivityEmployee(input: $borerActivity)
  }
`);

type SetBorerShiftActivityEmployeeInput = {
  id: string;
  borerShiftActivityId: string;
  siteEmployeeId: string;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapBorerShiftActivityEmployeeDocToInput = (
  doc: BorerShiftActivityEmployeeDocument,
): SetBorerShiftActivityEmployeeInput => {
  return {
    id: doc.id,
    borerShiftActivityId: doc.borerActivityId,
    siteEmployeeId: doc.siteEmployeeId,
    isDeleted: doc.isDeleted,
    version: doc.version,
    createdOn: dayjs().toISOString(),
    modifiedOn: null,
  };
};

export const borerShiftActivityEmployeePushQueryBuilder = generatePushQuery(
  setBorerShiftActivityEmployeeMutation,
  (doc: BorerShiftActivityEmployeeDocument) => ({
    borerActivity: mapBorerShiftActivityEmployeeDocToInput(doc),
  }),
);

export const borerShiftActivityEmployeeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftActivityEmployeeDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
};

export default {};
