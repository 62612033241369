import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100vh',
      alignContent: 'start',
      zIndex: 16,
      backgroundColor: theme.palette.background.paper,
    },
    topBar: {
      height: '55px',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      '& > div:nth-child(2)': {
        paddingTop: '15px',
      },
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    footerButtonActions: {
      position: 'absolute',
      bottom: '00px',
      width: '100%',
      paddingLeft: '15px',
      justifyContent: 'space-between',
      height: '75px',
      borderTop: 'solid 1px rgba(0, 0, 0, 1)',
    },
    progress: {
      display: 'flex',
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
    },
  }),
);

export default useStyles;
