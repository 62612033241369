import { captureInSentryWithDetails } from './captureInSentryWithDetails';

export const removeIndexedDbPromise = async (databaseName: string, timeout = 5000) => {
  const timeoutPromise = new Promise(resolve =>
    setTimeout(async () => {
      await captureInSentryWithDetails('DB delete timed out', { databaseName });
      return resolve('DB not idle within 10 seconds');
    }, timeout),
  );

  const deletePromise = new Promise<void>(async (resolve, reject) => {
    const req = window.indexedDB.deleteDatabase(databaseName);
    req.onsuccess = async () => {
      console.log('DB deleted');
      resolve();
    };
    req.onerror = async () => {
      console.log('DB delete failed');
      await captureInSentryWithDetails('DB delete failed', { databaseName });
      reject('failed');
    };
    req.onblocked = async () => {
      console.log('DB delete blocked');
      await captureInSentryWithDetails('DB delete blocked', { databaseName });
      reject('blocked');
    };
  });

  return Promise.race([deletePromise, timeoutPromise]);
};
