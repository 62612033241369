import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface Prediction extends BaseEntity {
  readonly borerShiftId: string;
  readonly footage: number;
  readonly location: string;
  readonly endLocation: string;
  readonly endDistance: string | number;
}
export type PredictionCollection = RxCollection<Prediction> | null;
export type PredictionDocument = RxDocument<Prediction>;

export const predictionSchema: RxJsonSchema<Prediction> = {
  type: 'object',
  version: 3,
  description: 'describes a Prediction object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    footage: { type: 'number' },
    updatedAt: { type: 'number' },
    location: {
      ref: RxdbCollectionName.LOCATIONS,
      type: 'string',
    },
    endDistance: {
      type: ['number', 'null'],
    },
    endLocation: {
      ref: RxdbCollectionName.LOCATIONS,
      type: 'string',
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const borerShiftPredictionMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: PredictionDocument) => {
    return {
      ...oldDoc,
      startLocation: null,
      startFootage: null,
      endLocation: null,
      endFootage: null,
    };
  },
  2: (oldDoc: PredictionDocument) => {
    return {
      ...oldDoc,
      startLocation: null,
      startDistance: oldDoc.startFootage,
      endDistance: oldDoc.endFootage,
    };
  },
  3: (oldDoc: PredictionDocument) => {
    const newDoc = { ...oldDoc };
    delete newDoc.startDistance;
    delete newDoc.startLocation;
    return newDoc;
  },
};

const borerShiftPredictionFeedQuery = print(gql`
  query borerShiftPredictionFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftPredictionFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftId
      id
      isDeleted
      updatedAt
      version
      endLocationId
      endDistance
    }
  }
`);

export const predictionPullQueryBuilder = generatePullQuery(
  borerShiftPredictionFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftPredictionMutation = print(gql`
  mutation setBorerShiftPrediction($prediction: SetBorerShiftPredictionInput) {
    setBorerShiftPrediction(input: $prediction) {
      id
      borerEquipmentId
      borerShiftId
      siteId
    }
  }
`);

type SetBorerShiftPredictionInput = {
  id: string;
  borerShiftId: string;
  startLocationId: string | null;
  startDistance: number | null;
  endLocationId: string;
  endDistance: number;
  isDeleted: boolean;
  version: number;
};

const mapPredictionDocumentToInput = (doc: PredictionDocument): SetBorerShiftPredictionInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    startLocationId: doc.location,
    startDistance: doc.footage,
    endLocationId: doc.endLocation,
    endDistance: doc.endDistance,
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const predictionPushQueryBuilder = generatePushQuery(
  setBorerShiftPredictionMutation,
  (doc: PredictionDocument) => ({
    prediction: mapPredictionDocumentToInput(doc),
  }),
);

export const predictionPullModifier = (doc: any) => {
  const result = {
    ...doc,
    endLocation: doc.endLocationId,
    location: '',
  };

  delete result.startLocationId;
  delete result.endLocationId;
  return result;
};
