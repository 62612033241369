import dayjs from 'dayjs';

import { APP_BACKGROUND_KEY, SYNC_DURATION_KEY } from './constants';

export const getLastSyncDiff = () => {
  const lastSyncInStorage = localStorage.getItem(SYNC_DURATION_KEY);

  if (lastSyncInStorage) {
    const parsed = JSON.parse(lastSyncInStorage);
    const diff = dayjs().diff(dayjs(parsed), 'seconds');
    return diff;
  }
  return null;
};
export const saveSyncTimeToLocalStorage = () => {
  localStorage.setItem(SYNC_DURATION_KEY, JSON.stringify(Date.now()));
};

export const getLastBackgroundedTime = () => {
  const lastBackgroundTime = localStorage.getItem(APP_BACKGROUND_KEY);

  if (lastBackgroundTime) {
    const parsed = JSON.parse(lastBackgroundTime);
    const diff = dayjs().diff(dayjs(parsed), 'seconds');
    return diff;
  }
  return null;
};
export const saveBackgroundTimeToLocalStorage = () => {
  localStorage.setItem(APP_BACKGROUND_KEY, JSON.stringify(Date.now()));
};
