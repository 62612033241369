import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Dialog, i18n } from '@nutrien/cxp-components';
import React from 'react';

import Spinner from '../Spinner';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
    },
    message: {
      marginTop: '20px !important',
      textAlign: 'center',
    },
    spinner: {
      maxHeight: '50%',
    },
  }),
);

const AppLoadingDialog: React.FC = ({}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      titleText="Data loading"
      backButton={false}
      disableEnforceFocus
      maxWidth="xs"
      maxHeight="200px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <div className={classes.dialog}>
        <div className={classes.spinner}>
          <Spinner />
          <Typography variant="body1" className={classes.message}>
            {i18n.t('Please wait while we load the application.')}
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default AppLoadingDialog;
