import { useRxCollection, useRxData } from 'rxdb-hooks';

import { sortDescriptionWithNumberStrings } from '../../utilities/sortHelper';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SurveyPoint, SurveyPointDocument, SurveyPointsCollection } from './queryBuilder';

export const useSurveyPoints = (
  panelId?: string,
  roomId?: string,
  reverseSortSurveyPoints = false,
) => {
  const surveyPointsCollection: SurveyPointsCollection = useRxCollection(
    RxdbCollectionName.SURVEY_POINTS,
  );

  // Pulling survey points for a roomId if provided
  const { result: surveyPointsForRoom, isFetching: surveyPointsForRoomLoaded } = useRxData(
    RxdbCollectionName.SURVEY_POINTS,
    collection => collection.find().where('room').eq(roomId),
  );

  // Some sites only use one room per panel, so we need to pull the default room for the panelId
  const { result: defaultRoomForPanel } = useRxData(RxdbCollectionName.ROOMS, collection =>
    collection.findOne().where('panel').eq(panelId),
  );

  const { result, isFetching: surveyPointsForPanelLoaded } = useRxData<SurveyPoint>(
    RxdbCollectionName.SURVEY_POINTS,
    collection =>
      collection
        .find()
        .where('room')
        .eq(defaultRoomForPanel.length > 0 ? defaultRoomForPanel[0].id : 'GUIDTHATWILLNEVEREXIST'),
  );

  const surveyPointsForPanel = reverseSortSurveyPoints
    ? result.sort(sortDescriptionWithNumberStrings).reverse()
    : result.sort(sortDescriptionWithNumberStrings);

  // Pulling survey points for a room
  const getSurveyPointsForRoom = async (roomIdentifier: string) => {
    if (!surveyPointsCollection) return [];

    let surveyPoints: SurveyPointDocument[] = [];

    try {
      surveyPoints = await surveyPointsCollection.find().where('room').eq(roomIdentifier).exec();
    } catch (error) {
      console.error('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }

    return surveyPoints.sort((a, b) =>
      a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    );
  };

  return {
    getSurveyPointsForRoom,
    surveyPointsForPanel,
    surveyPointsForPanelLoaded,
    surveyPointsForRoom,
    surveyPointsForRoomLoaded,
  };
};

export default useSurveyPoints;
