import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, i18n, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  onCancel: null | (() => void);
  onDiscard: () => void;
  titleText?: string;
  continueEditingButtonText?: string;
  discardDraftButtonText?: string;
  areYouSureText?: string;
  cancelText?: string;
  maxHeight?: number;
  flipColors?: boolean;
  bothPrimaryColors?: boolean;
  hideAreYouSureText?: boolean;
  disableEnforceFocus?: boolean;
  disableActionButton?: boolean;
  hideCloseButton?: boolean;
}

const DiscardDraftModal: React.FC<Props> = ({
  open,
  onCancel,
  onDiscard,
  titleText,
  continueEditingButtonText,
  discardDraftButtonText,
  areYouSureText,
  cancelText,
  maxHeight,
  flipColors = false,
  disableEnforceFocus = false,
  disableActionButton = false,
  hideAreYouSureText,
  bothPrimaryColors,
  hideCloseButton = false,
}) => {
  const classes = useStyles();
  const defaultTitleText = i18n.t('Discard draft');
  const defaultContinueEditingText = i18n.t('Continue editing');
  const defaultDiscardDraftHazardText = i18n.t('Discard draft');
  const defaultAreYouSure = i18n.t('Are you sure?');
  const defaultCancelText = i18n.t('Canceling will lose any information you have added.');
  const [isDiscarding, setIsDiscarding] = useState(false);

  useEffect(() => {
    if (open) {
      setIsDiscarding(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={hideCloseButton ? undefined : onCancel}
      titleText={titleText || defaultTitleText}
      backButton={false}
      disableEnforceFocus={disableEnforceFocus}
      dialogActions={
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              onClick={onCancel}
              variant="outlined"
              color={bothPrimaryColors ? 'primary' : flipColors ? 'error' : 'primary'}
              noMinHeight
              disabled={isDiscarding}
              id="cancel-button"
            >
              {continueEditingButtonText || defaultContinueEditingText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setIsDiscarding(true);
                onDiscard();
              }}
              variant="contained"
              color={bothPrimaryColors ? 'primary' : flipColors ? 'primary' : 'error'}
              noMinHeight
              isLoading={isDiscarding}
              disabled={disableActionButton}
              id="discard-draft-button"
            >
              {discardDraftButtonText || defaultDiscardDraftHazardText}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight={maxHeight ? `${maxHeight}px` : '250px'}
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      classes={{ container: classes.backdrop }}
    >
      <Typography variant="h6" className={classes.title}>
        {!hideAreYouSureText && (areYouSureText || defaultAreYouSure)}
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        {cancelText || defaultCancelText}
      </Typography>
    </Dialog>
  );
};

export default DiscardDraftModal;
