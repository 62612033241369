import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: '8px !important',
    },
    card: {
      padding: '16px',
    },
    markerArea: {
      marginTop: '44px',
      zIndex: 100,
    },
    activeButton: {
      backgroundColor: MaterialPalette.primary.main,
      borderRadius: '20px',
    },
    bgImg: {
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      KhtmlUserSelect: 'none',
    },
  }),
);

export default useStyles;
