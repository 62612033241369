import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Icons,
  MaterialPalette,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

interface Props {
  selectedValueId?: string;
  option: any;
  optionClassName?: string;
  handleValueChanged: (optionId: string, valueId: string) => void;
  error?: boolean;
  errorText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      backgroundColor: MaterialPalette.background.paper2,
      '& h6': {
        fontSize: '20px',
        color: MaterialPalette.tertiary.main,
      },
    },
    checkboxLabel: {
      width: '100%',
    },
    optionsContainer: {
      paddingBottom: '20px',
      paddingLeft: '30px',
      '& button > span > span': {
        textTransform: 'none !important',
      },
      '& button[aria-pressed="true"]': {
        backgroundColor: `${MaterialPalette.primary.main} !important`,
        color: `${MaterialPalette.common.white} !important`,
      },
    },
    optionsLabel: {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 'bold !important',
    },
    optionsErrorLabel: {
      color: `${theme.palette.error.main} !important`,
      fontWeight: 'bold !important',
    },
    errorContainer: {
      marginTop: '4px',
    },
    errorAlert: {
      stroke: theme.palette.error.main,
      strokeWidth: 2,
      padding: '1px 3px 1px 0',
    },
  }),
);

const GroundControlToggleOption = ({
  option,
  selectedValueId,
  handleValueChanged,
  error = false,
  errorText = '',
}: Props) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>();

  useEffect(() => {
    const val = option.values.find(x => x.id === selectedValueId);
    if (val) {
      setValue(val.value);
    }
  }, [selectedValueId]);

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newVal: string) => {
    if (newVal.length > 0) {
      setValue(newVal);
      handleValueChanged(option.id, option.values.find(x => x.value === newVal).id);
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.optionsContainer}>
        <Typography
          variant="caption"
          className={error ? classes.optionsErrorLabel : classes.optionsLabel}
        >
          {option.description}
        </Typography>
        <ToggleButtonGroup value={value} onChange={handleToggleChange} aria-label="" exclusive>
          {option.values.map((val, index) => {
            return (
              <ToggleButton
                key={`${val.id}-${index}`}
                value={val.value}
                aria-label={`${val.id}-toggle`}
                disableRipple
              >
                <Typography variant="button">{val.value}</Typography>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {error && (
          <Grid container direction="row" alignItems="center" className={classes.errorContainer}>
            <Icons.AlertCircleFeather className={classes.errorAlert} fontSize="xs" />
            <Typography style={{ flex: 1 }} variant="caption" color="error">
              {errorText || 'Required'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default GroundControlToggleOption;
