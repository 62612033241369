import { i18n } from '@nutrien/cxp-components';

export const generateAssignmentString = (
  isAssigned: boolean,
  showEquipmentTypeInHeader: boolean,
  equipmentType?: string,
): string => {
  if (showEquipmentTypeInHeader && isAssigned) {
    return `${i18n.t('Assigned')}: ${equipmentType || ''}`;
  }
  if (showEquipmentTypeInHeader && !isAssigned) {
    return `${i18n.t('Not assigned')}: ${equipmentType || ''}`;
  }
  if (!showEquipmentTypeInHeader && isAssigned) {
    return `${i18n.t('Assigned')}:`;
  }
  if (!showEquipmentTypeInHeader && !isAssigned) {
    return `${i18n.t('Not assigned')}:`;
  }
  return '';
};
