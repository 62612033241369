import { Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import * as markerjs2 from 'markerjs2';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import { PanelDrawingDocument } from '../../rxdb/PanelDrawing/queryBuilder';
import {
  GROUND_HAZARD_WRITE_PERMISSION,
  MiningMethod,
  PANEL_DRAWING_COLOURS,
} from '../../utilities/constants';
import usePanelDrawingBackgrounds from '../../utilities/hooks/usePanelDrawingBackgrounds';
import useWindowSize from '../../utilities/hooks/useWindowSize';
import { sanitizeMarkerJsData } from '../../utilities/sanitizeMarkerJsData';
import PanelDrawingCommentsCard from '../PanelDrawingCommentsCard';
import PanelDrawingEditModal from '../PanelDrawingEditModal';
import useStyles from './PanelDrawingViewEditCard.styles';

const { MARKER_JS_2_KEY } = import.meta.env;

interface Props {
  panelDrawing: PanelDrawingDocument;
  editMode?: boolean;
  activeDrawing?: boolean;
}

if (MARKER_JS_2_KEY && !markerjs2.Activator.isLicensed) {
  markerjs2.Activator.addKey(MARKER_JS_2_KEY);
}

const PanelDrawingViewEditCard: React.FC<Props> = ({
  panelDrawing,
  editMode = true,
  activeDrawing = false,
}: Props) => {
  const classes = useStyles();
  const { user } = useMst();
  const size = useWindowSize();
  const { imageHeight, imageWidth, backgroundImage, imageWidthString, imageHeightString } =
    usePanelDrawingBackgrounds({
      size,
      miningMethod: panelDrawing.miningMethod as MiningMethod,
    });

  const imgRef = useRef<HTMLImageElement>(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [panelDrawingModalOpen, setPanelDrawingModalOpen] = useState(false);
  const [markerAreaElement, setMarkerAreaElementState] = useState<markerjs2.MarkerArea>();
  const markerAreaRef = React.useRef(markerAreaElement);

  const setMarkerAreaElement = (markerArea: markerjs2.MarkerArea) => {
    setMarkerAreaElementState(markerArea);
    markerAreaRef.current = markerArea;
  };
  const [renderedMarkers, setRenderedMarkers] = useState('');

  // Setup marker area
  const [setupComplete, setSetupComplete] = useState(false);
  const setupMarkerArea = async () => {
    if (imgRef.current !== null) {
      let parsedState;
      if (panelDrawing && panelDrawing.drawingData) {
        parsedState = JSON.parse(panelDrawing.drawingData);
        if (JSON.stringify(parsedState.markers) === renderedMarkers) {
          return;
        }
      }

      setSetupComplete(false);
      if (markerAreaElement) markerAreaElement.clear();
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);

      // Styles
      markerArea.uiStyleSettings.toolbarActiveButtonStyleColorsClassName = classes.activeButton;

      // Configure image to show in correct spot because of modal
      markerArea.settings.displayMode = 'inline';
      markerArea.uiStyleSettings.canvasBackgroundColor = '#292929';
      markerArea.uiStyleSettings.hideToolbar = true;
      markerArea.uiStyleSettings.hideToolbox = true;
      markerArea.settings.defaultColorSet = PANEL_DRAWING_COLOURS;

      markerArea.renderHeight = imageHeight;
      markerArea.renderWidth = imageWidth;

      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener(dataUrl => {
        if (imgRef.current) {
          imgRef.current.src = dataUrl;
        }
      });

      markerArea.addCloseEventListener(() => {
        setIsDrawing(false);
      });

      imgRef.current.src = backgroundImage?.src;

      setMarkerAreaElement(markerArea);

      if (parsedState.width) {
        markerArea.show();
        const sanitzedData = sanitizeMarkerJsData(parsedState);
        markerArea.restoreState(sanitzedData);
        setRenderedMarkers(JSON.stringify(parsedState.markers));
        await markerArea.startRenderAndClose();
      } else {
        setRenderedMarkers('');
        markerArea.clear();
      }
    }
    setSetupComplete(true);
  };
  const [refLoaded, setRefLoaded] = useState(false);

  useEffect(() => {
    if (backgroundImage?.src && refLoaded && !panelDrawingModalOpen) {
      setupMarkerArea();
    }
  }, [panelDrawing, refLoaded, panelDrawingModalOpen, backgroundImage]);

  const openPanelDrawingEditModal = useCallback(() => {
    setPanelDrawingModalOpen(true);
  }, []);

  const closePanelDrawingEditModal = useCallback(() => {
    setPanelDrawingModalOpen(false);
  }, []);

  const onImageRefLoad = () => setRefLoaded(true);

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={6}>
          {activeDrawing ? (
            <Typography variant="h3">{i18n.t('Active panel drawing')}</Typography>
          ) : (
            <Typography variant="h3">{i18n.t('Previous panel drawing')}</Typography>
          )}
        </Grid>
        {!isDrawing && editMode && user.hasPermissionTo(GROUND_HAZARD_WRITE_PERMISSION) && (
          <Grid item container xs={6} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                startAdornment={<Icons.Edit2Feather color="inherit" />}
                color="primary"
                noMinHeight
                onClick={openPanelDrawingEditModal}
                id="edit-panel-drawing-button"
              >
                Draw
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} className={classes.markerArea}>
          <div id="markerEditViewOverlay" className={classes.markerArea} />
          <img
            ref={imgRef}
            src={backgroundImage?.src}
            alt="sample"
            style={{
              width: imageWidthString,
              height: imageHeightString,
              visibility: setupComplete ? 'visible' : 'hidden',
            }}
            id="bgImageEditView"
            onLoad={onImageRefLoad}
          />
        </Grid>
        <Grid item xs={12}>
          <PanelDrawingCommentsCard panelDrawingId={panelDrawing?.id} canEdit={editMode} />
        </Grid>
        {panelDrawingModalOpen && (
          <PanelDrawingEditModal
            open={panelDrawingModalOpen}
            onClose={closePanelDrawingEditModal}
            miningMethod={panelDrawing?.miningMethod}
            saveEnabledByDefault={false}
          />
        )}
      </Grid>
    </Card>
  );
};

export default observer(PanelDrawingViewEditCard);
