import { Dialog } from '@nutrien/cxp-components';
import React from 'react';

import { useNotification } from '../../utilities';
import { useStyles } from './PDFPreviewModal.styles';

interface Props {
  url?: string; // URL from S3 OR base64 image string
  open: boolean;
  onClose: () => void;
  imageTitle: string;
  pdfWindow: WindowProxy | undefined;
}

const PDFPreviewModal: React.FC<Props> = ({ url, open, onClose, imageTitle, pdfWindow }: Props) => {
  const classes = useStyles();

  const { errorNotification } = useNotification();

  const openPDF = () => {
    try {
      if (!url) throw new Error('PDF data is missing');
      const isFileBlob = url.slice(0, 4) === 'data';

      if (!isFileBlob && pdfWindow) {
        // S3 File URL (hasn't been cached yet)
        pdfWindow.location.href = url;
        onClose(); // close preview panel since PDF is open in new window
        return;
      }

      const base64str = url.replace(/^data:application\/pdf;base64,/, '');

      // decode base64 string, remove space for IE compatibility
      const binary = atob(base64str.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // Convert binary string to Blob
      const blob = new Blob([view], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      // Open PDF document in new window within application
      if (pdfWindow) {
        pdfWindow.location.href = pdfUrl;
      } else {
        throw new Error('PDF window could not be opened');
      }
    } catch (error) {
      console.log('🚀 ~ file: PDFPreviewModal.tsx ~ line 57 ~ openPDF ~ error', error);

      errorNotification(`Error opening PDF file`);
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleText={imageTitle}
      backButton={false}
      noMinHeight
      fullScreen
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      classes={{ container: classes.backdrop }}
    >
      {open && openPDF()}
    </Dialog>
  );
};

export default PDFPreviewModal;
