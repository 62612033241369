import React from 'react';

import { InspectionResultItem } from '@/rxdb/InspectionResults/useInspectionResults';

import PreOpCheckCard from './PreOpCheckCard';
type Props = {
  equipmentIsLoading: boolean;
  equipmentPreOpChecks: InspectionResultItem[];
};

const DeficienciesChecksTab: React.FC<Props> = (props: Props) => {
  return (
    <>
      <PreOpCheckCard
        equipmentIsLoading={props.equipmentIsLoading}
        equipmentPreOpChecks={props.equipmentPreOpChecks}
      />
    </>
  );
};

export default DeficienciesChecksTab;
