import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';

export interface Assignment extends BaseEntity {
  readonly crewId: string;
  readonly shiftId: string;
}
export type AssignmentCollection = RxCollection<Assignment> | null;
export type AssignmentDocument = RxDocument<Assignment>;

export const assignmentSchema: RxJsonSchema<Assignment> = {
  type: 'object',
  version: 0,
  description: 'describes an assignment employee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    crewId: { type: 'string' },
    shiftId: { type: 'string', ref: RxdbCollectionName.SHIFT },
  },
};

const assignmentFeedQuery = print(gql`
  query assignmentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    assignmentFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      crewId
      id
      isDeleted
      shiftId
      updatedAt
      version
    }
  }
`);

export const assignmentPullQueryBuilder = generatePullQuery(
  assignmentFeedQuery,
  PullQueryContext.Borer,
);
