import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, i18n, Icons, TextField, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      padding: '8px',
    },
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
      color: `${theme.palette.text.disabled} !important`,
    },
    saveButton: {
      paddingRight: '10px !important',
      '& > div': {
        alignItems: 'flex-end',
      },
    },
  }),
);

interface Props {
  open: boolean;
  onCancel: () => void;
  onLogout: () => void;
  disableEnforceFocus?: boolean;
  logoutLoading: boolean;
}

const LogoutModal: React.FC<Props> = ({
  open,
  onCancel,
  onLogout,
  disableEnforceFocus,
  logoutLoading,
}: Props) => {
  const classes = useStyles();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>();

  useEffect(() => {
    setSaveButtonDisabled(confirmationText?.trim() !== 'LOGOUT');
  }, [confirmationText]);

  const handleClose = () => {
    setConfirmationText('');
    onCancel();
  };

  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      titleText={i18n.t('Log out')}
      backButton={false}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown
      disableBackdropClick
      dialogActions={
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={5}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              noMinHeight
              id="cancel-button"
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              onClick={onLogout}
              variant="contained"
              color="primary"
              noMinHeight
              disabled={saveButtonDisabled}
              id="logout-button"
              isLoading={logoutLoading}
            >
              {i18n.t('Log out')}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight="370px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Typography variant="h6" className={classes.title}>
        {i18n.t('Are you sure?')}
      </Typography>
      <Grid container>
        <Grid item xs={1}>
          <Icons.InfoFeather color="error" />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            {i18n.t(
              'Logging out will mean the application is unusable until the IT team can log in again.',
            )}
          </Typography>
          <br />
          <TextField
            label={i18n.t('Type LOGOUT to confirm logout')}
            onChange={event => {
              setConfirmationText(event.target.value);
            }}
            value={confirmationText}
            type="text"
            autoComplete="off"
            inputProps={{
              inputMode: 'text',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LogoutModal;
