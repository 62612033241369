import { Storage } from 'aws-amplify';
import imageCompression from 'browser-image-compression';
import { default as localForage } from 'localforage';
import path from 'path';

import {
  attachmentStore,
  defaultProtectionLevel,
} from '../../components/CacheUpdateHandler/CacheUpdateHandler';
import { getAttachmentWithRetry } from '../../components/CacheUpdateHandler/getAttachmentWithRetry';
import { fileToBase64 } from '../fileConverter';
import { useOnlineStatus } from './useOnlineStatus';

export const uploadQueueStore = localForage.createInstance({
  name: 'imageUploadQueue',
  storeName: 'imageUploadQueue',
});

interface UploadItem {
  uploadPath: string;
  protectionLevel: string;
  arrayBuffer: ArrayBuffer;
}

const maxImageSizeMB = 2;

const arrayBufferToBlob = (buffer: ArrayBuffer, type: string) => {
  return new Blob([buffer], { type });
};

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      resolve(reader.result);
    });
    reader.addEventListener('error', reject);
    reader.readAsArrayBuffer(blob);
  });
};

const uploadCachedImage = async (uploadPath: string, blob: Blob, protectionLevel: string) => {
  return Storage.put(uploadPath, blob, {
    contentType: 'image/jpeg',
    level: protectionLevel,
  });
};

export const addFileToUploadCache = async (
  uploadPath: string,
  blob: Blob,
  protectionLevel: string,
) => {
  const arrayBuffer = await blobToArrayBuffer(blob);

  uploadQueueStore.setItem(uploadPath, {
    protectionLevel,
    uploadPath,
    arrayBuffer,
  });

  const base64String = await fileToBase64(blob);
  attachmentStore.setItem(uploadPath, base64String);
};

const removeItemFromUploadCache = (key: string) => {
  return uploadQueueStore.removeItem(key);
};

const uploadOneFileFromCache = async (key: string) => {
  const value: UploadItem | null = await uploadQueueStore.getItem(key);
  if (value === null) return;

  // Remove item from store
  removeItemFromUploadCache(key);

  const { uploadPath, protectionLevel, arrayBuffer } = value;
  const blob = arrayBufferToBlob(arrayBuffer, 'image/jpeg');

  try {
    await uploadCachedImage(uploadPath, blob, protectionLevel);
  } catch (error) {
    console.log('🚀 ~  Upload file from cache ~ error', error);

    addFileToUploadCache(uploadPath, blob, protectionLevel);
  }
};

const uploadFilesInCache = async () => {
  const uploadKeys = await uploadQueueStore.keys();
  await Promise.all(uploadKeys.map(key => uploadOneFileFromCache(key)));
  await Promise.all(uploadKeys.map(key => getAttachmentWithRetry({ fileName: key })));
};

export const getImageInUploadQueue = async (fileKey: string): Promise<Blob | undefined> => {
  const value: UploadItem | null = await uploadQueueStore.getItem(fileKey);
  if (value === null) return;

  const { arrayBuffer } = value;
  const blob = arrayBufferToBlob(arrayBuffer, 'image/jpeg');

  return blob;
};

export const useFileStorage = () => {
  const online = useOnlineStatus();

  const uploadImage = async (
    folderName: string,
    file: File,
    // This addPrefix can be removed once MDP-4653 is removed.
    protectionLevel = defaultProtectionLevel,
  ) => {
    // Extract file name and extension
    let { ext } = path.parse(file.name);
    const { name } = path.parse(file.name);
    let contentType;
    ext = ext.toLowerCase();

    switch (ext) {
      case '.pdf':
        contentType = 'application/pdf';
        break;
      case '.png':
        contentType = 'image/png';
        break;
      case '.jpg':
      case '.jpeg':
        contentType = 'image/jpeg';
        break;
      default:
        throw new Error(`Unknown content type ${ext}`);
    }

    // Compress File
    const options = {
      maxSizeMB: maxImageSizeMB,
      useWebWorker: true,
    };

    let compressedFile = file;
    if (contentType === 'image/jpeg' || contentType === 'image/png') {
      try {
        compressedFile = await imageCompression(file, options);
      } catch (err) {
        console.log('Compression error', err);
      }
    }

    // Convert to blob
    const blob = new Blob([compressedFile], { type: contentType });
    const uploadPath = `${folderName}/${name}${ext}`;

    // Add file to cache
    await addFileToUploadCache(uploadPath, blob, protectionLevel);

    // If offline return key
    if (!online) return { key: uploadPath };

    // If connected, upload
    try {
      const response = await Storage.put(uploadPath, blob, {
        contentType,
        level: protectionLevel,
      });

      // Remove image if upload was a success
      removeItemFromUploadCache(uploadPath);

      return response;
    } catch (error) {
      console.log('🚀 ~ file: useFileStorage.tsx ~ line 95 ~ useFileStorage ~ error', error);
      return { key: uploadPath };
    }
  };

  return {
    uploadImage,
    uploadFilesInCache,
    getImageInUploadQueue,
  };
};

export default useFileStorage;
