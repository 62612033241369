import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette, ExpansionPanel, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { HazardPanelTypes } from '../../utilities';
import AspectRatio from '../AspectRatio';
import Thumbnail from '../Thumbnail';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    checkboxes: {
      paddingTop: '25px',
    },
    container: {
      width: '100%',
    },
    checkboxLabel: {
      width: '100%',
    },
    photoCardAdd: {
      width: '100%',
      height: '100%',
      border: `1px dashed ${CustomPalette.elevation.dp8}`,
      borderRadius: '4px',
      backgroundColor: CustomPalette.elevation.dp1,
    },
    photoCardAddText: {
      marginTop: '10px !important',
    },
  }),
);

interface Props {
  expansionPanelId: HazardPanelTypes;
  onExpanded: (expanded: boolean, expansionPanelId: HazardPanelTypes) => void;
  expanded: boolean;
  selectedImages: File[];
  onFilesSelected: (selectedImages: File[]) => void;
  setValidationIndex: Dispatch<SetStateAction<number>>;
}

// This displays while adding / editing a hazard
const HazardPhotosPanel: React.FC<Props> = ({
  expansionPanelId,
  onExpanded,
  expanded,
  selectedImages,
  onFilesSelected,
  setValidationIndex,
}: Props) => {
  const classes = useStyles();

  const onFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files !== null) onFilesSelected([...selectedImages, ...files]);
      setValidationIndex(12);
    },
    [onFilesSelected, selectedImages],
  );

  const onOpenUpload = () => {
    document.getElementById('selectImage')?.click();
  };

  const onOpenUploadInput = useCallback(() => {
    document.getElementById('selectImagePanel')?.click();
  }, []);

  const getIcon = useCallback(() => {
    if (!expanded && selectedImages.length === 0)
      return (
        <div style={{ marginTop: '5px' }}>
          <input
            type="file"
            accept="image/jpeg"
            onChange={evt => onFileInput(evt)}
            multiple
            hidden
            id="selectImagePanel"
          />
          <Icons.PlusFeather color="primary" />
        </div>
      );
    return <Icons.ChevronDownFeather color="primary" />;
  }, [expanded, onFileInput, selectedImages]);

  const onDelete = (file: File) => {
    onFilesSelected(selectedImages.filter(image => image.name !== file.name));
    if (selectedImages.length === 1) {
      onExpanded(false, expansionPanelId);
    } else {
      onExpanded(true, expansionPanelId);
    }
  };

  const onExpansionPanelChange = useCallback(
    (event: React.ChangeEvent<unknown>, expandedValue: boolean) => {
      if (selectedImages.length > 0) {
        onExpanded(expandedValue, expansionPanelId);
      } else {
        onOpenUploadInput();
      }
    },
    [expansionPanelId, expanded, selectedImages],
  );

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Photos')}
      key="hazard-photos"
      data-cy="HazardPhotosPanel"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={onExpansionPanelChange}
      expansionPanelSummaryProps={{
        expandIcon: getIcon(),
        IconButtonProps: {
          color: 'primary',
          style: {
            border: expanded || selectedImages.length !== 0 ? '0px' : '1px solid green',
            borderRadius: '4px',
            height: '44px',
          },
        },
      }}
      panelContent={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/jpeg"
              multiple
              hidden
              id="selectImage"
              onChange={evt => onFileInput(evt)}
              onClick={event => {
                event.target.value = null;
              }}
            />
          </Grid>
          {selectedImages.map(file => (
            <Thumbnail
              file={file}
              onDelete={onDelete}
              key={`hazard-photos-thumbnail-${file.name}-${file.lastModified}`}
            />
          ))}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            onClick={onOpenUpload}
            xs={6}
            id="add-photo-card"
          >
            <div className={classes.photoCardAdd}>
              <AspectRatio ratio={1 / 1}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Icons.PlusCircleFeather color="primary" />
                  <Typography className={classes.photoCardAddText} variant="body1">
                    {i18n.t('Add Photo')}
                  </Typography>
                </div>
              </AspectRatio>
            </div>
          </Grid>
        </Grid>
      }
    />
  );
};

export default observer(HazardPhotosPanel);
