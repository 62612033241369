import { Grid } from '@material-ui/core';
import { Button, Dialog, i18n, Icons, TextField, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import RxdbManager from '@/rxdb/RxdbManager';
import useEventTracking, { TrackingEventType } from '@/utilities/hooks/useEventTracking';

import { useStyles } from './ResetAppDataModal.styles';

interface Props {
  open: boolean;
  onCancel: () => void;
}
const ResetAppDataModal: React.FC<Props> = ({ open, onCancel }: Props) => {
  const classes = useStyles();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [rebuildLoading, setRebuildLoading] = useState(false);

  const { trackEvent } = useEventTracking();

  useEffect(() => {
    setSaveButtonDisabled(confirmationText?.trim() !== 'RESET');
  }, [confirmationText]);

  const handleClose = () => {
    setConfirmationText('');
    onCancel();
  };

  const onRebuildConfirmed = async () => {
    try {
      setRebuildLoading(true);
      await trackEvent(TrackingEventType.RESET_APP_DATA_CLICKED, {});
      await RxdbManager.instance.stopReplication(true);
      await RxdbManager.instance?.waitForIdle();
      await RxdbManager.instance?.rebuildDb();
    } catch (e) {
      console.log('🚀 ~ onRebuildConfirmed ~ e:', e);
    }
    window.location.href = '/initializing?initialLoad=true';
  };

  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      titleText={i18n.t('Reset app data')}
      backButton={false}
      disableEnforceFocus={false}
      disableEscapeKeyDown
      disableBackdropClick
      dialogActions={
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={5}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              noMinHeight
              id="cancel-button"
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              onClick={onRebuildConfirmed}
              variant="contained"
              color="primary"
              noMinHeight
              disabled={saveButtonDisabled}
              id="rebuild-button"
              isLoading={rebuildLoading}
            >
              {i18n.t('Reset app data')}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight="430px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Typography variant="h6" className={classes.title}>
        {i18n.t('Are you sure?')}
      </Typography>
      <Grid container>
        <Grid item xs={1}>
          <Icons.InfoFeather color="error" />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1">
            {i18n.t(
              'Resetting app data will clear all existing data in the application and download it from scratch, similiar to a new login. Any data that has failed to save will be lost.',
            )}
          </Typography>
          <br />
          <TextField
            label={i18n.t('Type RESET to confirm rebuild')}
            onChange={event => {
              setConfirmationText(event.target.value);
            }}
            value={confirmationText}
            type="text"
            autoComplete="off"
            inputProps={{
              inputMode: 'text',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ResetAppDataModal;
