import React from 'react';

import CompleteWorkOrdersCard from './CompleteWorkOrdersCard';

const CompleteWOTab: React.FC = () => {
  return (
    <>
      <CompleteWorkOrdersCard />
    </>
  );
};

export default CompleteWOTab;
