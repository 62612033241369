export enum LiveInterval {
  SECONDS_5 = 5000,
  SECONDS_15 = 15000,
  SECONDS_30 = 30000,
  SECONDS_60 = 60000,
  MINUTES_5 = 300000,
  MINUTES_15 = 900000,
  MINUTES_30 = 1800000,
  HOURS_1 = 3600000,
}
