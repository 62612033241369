import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      display: 'flex',
      flexDirection: 'column',
    },
    panelSummary: {
      '&': {
        display: 'none !important',
      },
    },
    panelContent: {
      '&': {
        padding: '16px !important',
      },
    },
    topMargin: {
      marginTop: 20,
    },
    nightShiftScheduleContainer: {
      '& > div': {
        height: 'auto !important',
      },
      '& > div:first-child > div': {
        height: '852px',
        overflowY: 'hidden',
      },
    },
  }),
);

export default useStyles;
