import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    card: {
      padding: '16px',
    },
    tabCard: {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 24px 38px rgba(0, 0, 0, 0.035), 0px 9px 46px rgba(0, 0, 0, 0.03), 0px 11px 15px rgba(0, 0, 0, 0.05);',
      '& button > span': {
        textTransform: 'none !important',
      },
    },
  }),
);

export default useStyles;
