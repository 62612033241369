import { Grid } from '@material-ui/core';
import {
  AutoComplete,
  GroupedAutoComplete,
  i18n,
  IconButton,
  Icons,
} from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import {
  BorerShiftCrewMemberEmployee,
  BorerShiftCrewMemberWithRole,
} from '@/rxdb/BorerShiftCrew/queryBuilder';
import { BorerShiftCrewMemberRole } from '@/rxdb/BorerShiftCrewMemberRole/queryBuilder';
import { useBorerShiftCrewMemberRole } from '@/rxdb/BorerShiftCrewMemberRole/useBorerShiftCrewMemberRole';
import { EmployeeSummary } from '@/rxdb/Employees/useEmployees';

import {
  AugmentedEmployee,
  ValidationOperation,
} from '../EditCrewDetailsPanel/EditCrewDetailsPanel';
import { useStyles } from './CrewMember.styles';
import { duplicateEmployeeAndRole } from './duplicateEmployeeAndRole';

export enum CrewEmployeePanelOperation {
  AddEmployee,
  DeleteEmployee,
}

interface Props {
  crewMemberDetails: BorerShiftCrewMemberWithRole;
  crewMemberList: BorerShiftCrewMemberWithRole[];
  crewMemberIndex: number;
  setValidationError: (index: number, error: boolean | ValidationOperation) => void;
  onCrewEmployeeChange: (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => void;
  onCrewEmployeeRoleChange: (index: number, value: BorerShiftCrewMemberRole | null) => void;
  employeeList: AugmentedEmployee[];
  validationIndex: number;
}

const CrewMember: React.FC<Props> = ({
  crewMemberDetails,
  setValidationError,
  crewMemberIndex,
  onCrewEmployeeChange,
  onCrewEmployeeRoleChange,
  employeeList,
  crewMemberList,
  validationIndex,
}: Props) => {
  const classes = useStyles();
  const { borerShiftCrewMemberRoles, borerShiftCrewMemberRolesById } =
    useBorerShiftCrewMemberRole();

  const [errors, setErrors] = useState({
    name: '',
    role: '',
  });

  useEffect(() => {
    const copiedArray = crewMemberList.slice();
    copiedArray.splice(crewMemberIndex, 1);
    // Validation index is the order of the dropdowns.
    // The first two are for crew time, and each next set of 2
    // are for role and employee, respectively.
    if (validationIndex >= (crewMemberIndex + 1) * 2 + 1) {
      if (!crewMemberDetails?.borerShiftCrewMemberRole?.id) {
        setErrors(prev => ({ ...prev, role: 'Required' }));
      } else if (duplicateEmployeeAndRole(crewMemberDetails, crewMemberList)) {
        setErrors(prev => ({ ...prev, role: 'Duplicate employee-role combination' }));
      } else {
        setErrors(prev => ({ ...prev, role: '' }));
      }
    }
    if (validationIndex >= (crewMemberIndex + 1) * 2 + 2) {
      if (!crewMemberDetails?.employee?.id) {
        setErrors(prev => ({ ...prev, name: 'Required' }));
      } else {
        setErrors(prev => ({ ...prev, name: '' }));
      }
    }
  }, [crewMemberList, crewMemberDetails, crewMemberIndex, validationIndex]);

  useEffect(() => {
    setValidationError(crewMemberIndex, Boolean(errors.name || errors.role));
  }, [errors, errors.name, errors.role, crewMemberIndex]);

  const onUpdateSelectedOperator = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: BorerShiftCrewMemberEmployee,
  ) => {
    onCrewEmployeeChange(crewMemberIndex, value);
  };

  const onRemoveCrew = () => {
    setValidationError(crewMemberIndex, ValidationOperation.Remove);
    onCrewEmployeeChange(crewMemberIndex, CrewEmployeePanelOperation.DeleteEmployee);
  };

  const getEmployeeLabel = (employee: EmployeeSummary) => employee.fullName;

  const crewMemberRole = crewMemberDetails.borerShiftCrewMemberRole?.id
    ? borerShiftCrewMemberRolesById[crewMemberDetails.borerShiftCrewMemberRole.id]
    : undefined;

  return (
    <>
      <Grid container className={classes.spacer}>
        <Grid item xs={crewMemberIndex > 0 ? 10 : 12}>
          <AutoComplete
            label="Role"
            autoSelect={false}
            list={borerShiftCrewMemberRoles.filter(role => role.isActive)}
            getOptionLabel={(option: BorerShiftCrewMemberRole) => option.description}
            onChange={(event: React.SyntheticEvent<any>, value: BorerShiftCrewMemberRole) => {
              onCrewEmployeeRoleChange(crewMemberIndex, value);
            }}
            value={crewMemberRole}
            showCaret
            error={!!errors.role}
            errorText={errors.role}
          />
        </Grid>
        {crewMemberIndex > 0 && (
          <Grid item xs={2} className={classes.trashContainer}>
            <IconButton
              onClick={onRemoveCrew}
              className={classes.errorIcon}
              id="crew-member-remove"
              data-testid="crew-member-remove"
            >
              <Icons.Trash2Feather color="error" className={classes.errorIcon} />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <GroupedAutoComplete
            autoSelect={false}
            list={employeeList}
            label={i18n.t('User')}
            getOptionLabel={getEmployeeLabel}
            onChange={onUpdateSelectedOperator}
            value={crewMemberDetails.employee}
            showCaret
            error={!!errors.name}
            errorText={errors.name}
            groupByProperty="crewName"
            primaryProperty="fullName"
            secondaryProperty="crewName"
            tertiaryProperty="positionName"
          />
        </Grid>
        {crewMemberIndex < crewMemberList.length - 1 && (
          <Grid item xs={12} className={classes.divider} />
        )}
      </Grid>
    </>
  );
};

export default CrewMember;
