import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface EquipmentDeficiencyLog extends BaseEntity {
  readonly comment: string;
  readonly siteEmployeeId: string;
  readonly equipmentDeficiencyId: string;
  readonly createdOn: string;
}
export type EquipmentDeficiencyLogCollection = RxCollection<EquipmentDeficiencyLog> | null;
export type EquipmentDeficiencyLogDocument = RxDocument<EquipmentDeficiencyLog>;

export const equipmentDeficiencyLogSchema: RxJsonSchema<EquipmentDeficiencyLog> = {
  type: 'object',
  version: 1,
  description: 'describes a EquipmentDeficiencyLog object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    comment: { type: 'string' },
    siteEmployeeId: { type: 'string' },
    equipmentDeficiencyId: {
      ref: RxdbCollectionName.EQUIPMENT_DEFICIENCY,
      type: 'string',
    },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

const pequipmentDeficiencyLogFeedQuery = print(gql`
  query equipmentDeficiencyLogFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    equipmentDeficiencyLogFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      comment
      siteEmployeeId
      equipmentDeficiencyId
      isDeleted
      updatedAt
      version
      createdOn
    }
  }
`);

export const equipmentDeficiencyLogPullQueryBuilder = generatePullQuery(
  pequipmentDeficiencyLogFeedQuery,
  PullQueryContext.Borer,
);

const createEquipmentDeficiencyLogMutation = print(gql`
  mutation createEquipmentDeficiencyLog(
    $id: ID!
    $comment: String!
    $siteEmployeeId: ID!
    $equipmentDeficiencyId: ID!
    $createdOn: AWSDateTime
  ) {
    createEquipmentDeficiencyLog(
      input: {
        id: $id
        comment: $comment
        siteEmployeeId: $siteEmployeeId
        equipmentDeficiencyId: $equipmentDeficiencyId
        createdOn: $createdOn
      }
    ) {
      id
      isWorkOrderRequired
      siteId
    }
  }
`);

type CreateEquipmentDeficiencyLogInput = {
  id: string;
  equipmentDeficiencyId: string;
  siteEmployeeId: string;
  comment: string;
  createdOn: string | null;
};

const mapEquipmentDeficiencyLogDocToInput = (
  doc: EquipmentDeficiencyLogDocument,
): CreateEquipmentDeficiencyLogInput => {
  return {
    id: doc.id,
    equipmentDeficiencyId: doc.equipmentDeficiencyId,
    siteEmployeeId: doc.siteEmployeeId,
    comment: doc.comment,
    createdOn: doc.createdOn,
  };
};

export const equipmentDeficiencyLogPushQueryBuilder = generatePushQuery(
  createEquipmentDeficiencyLogMutation,
  (doc: EquipmentDeficiencyLogDocument) => mapEquipmentDeficiencyLogDocToInput(doc),
);

export const equipmentDeficiencyLogStrategies: MigrationStrategies = {
  1: (oldDoc: EquipmentDeficiencyLogDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
};
