import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument } from 'rxdb';

import { BaseEntity } from '@/models/BaseEntity';

import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Supply extends BaseEntity {
  borerEquipmentId: string;
  quantity: string;
  otherDescription: string | null;
  supplyItemId: string;
}
export type SupplyDocument = RxDocument<Supply>;
export type SupplyCollection = RxCollection<Supply> | null;

export interface EnrichedSupply extends Supply {
  supplyItemDescription: string;
  supplyItemUnit: string | null;
  isNew: boolean;
}

export const supplySchema = {
  type: 'object',
  version: 2,
  description: 'describes a Supply object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerEquipmentId: { type: 'string' },
    quantity: { type: ['number', 'string'] },
    otherDescription: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    supplyItemId: {
      ref: 'supplyItems',
      type: 'string',
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const supplyMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SupplyDocument) => {
    return {
      ...oldDoc,
      otherDescription: null,
    };
  },
  2: (oldDoc: SupplyDocument) => oldDoc,
};

const borerSupplyFeedQuery = print(gql`
  query borerSupplyFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerSupplyFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerEquipmentId
      id
      isDeleted
      quantity
      otherDescription
      supplyItemId
      updatedAt
      version
    }
  }
`);

export const supplyPullQueryBuilder = generatePullQuery(
  borerSupplyFeedQuery,
  PullQueryContext.Borer,
  defaultDocAllTime,
);

const setBorerSupplyMutation = print(gql`
  mutation setBorerSupply($input: SetBorerSupplyInput) {
    setBorerSupply(input: $input) {
      id
      equipmentId
      quantity
      supplyItemId
      version
    }
  }
`);

export const supplyPushQueryBuilder = generatePushQuery(
  setBorerSupplyMutation,
  (doc: SupplyDocument) => {
    return {
      input: {
        id: doc.id,
        equipmentId: doc.borerEquipmentId,
        supplyItemId: doc.supplyItemId,
        quantity: doc.quantity,
        createdOn: dayjs().toISOString(),
        modifiedOn: dayjs().toISOString(),
        isDeleted: doc.isDeleted,
        version: doc.version,
        otherDescription: doc.otherDescription,
      },
    };
  },
);

export default {};
