import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      marginBottom: '8px !important',
    },
    titleLine: {
      marginBottom: '2px',
    },
    secondaryText: {
      color: theme.palette.text.disabled,
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    commentsContainer: {
      margin: '0px',
    },
    commentItemContainer: {
      marginBottom: '24px',
    },
    textTitle: {
      float: 'right',
    },
  }),
);

export default useStyles;
