import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface BorerShiftTarget extends BaseEntity {
  readonly borerShiftId: string;
  readonly shiftDate: string;
  readonly shiftType: string;
  readonly tonnes: number;
  readonly totalDistance: number | null;
}

export type BorerShiftTargetDocument = RxDocument<BorerShiftTarget>;
export type BorerShiftTargetCollection = RxCollection<BorerShiftTarget> | null;

export const borerShiftTargetSchema: RxJsonSchema<BorerShiftTarget> = {
  type: 'object',
  version: 0,
  description: 'describes a Borer Shift Target object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string', ref: RxdbCollectionName.BORER_SHIFT },
    shiftDate: { type: 'string' },
    shiftType: { type: 'string' },
    tonnes: { type: 'number' },
    totalDistance: { type: ['number', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const borerShiftTargetFeedQuery = print(gql`
  query borerShiftTargetFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftTargetFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      borerShiftId
      id
      isDeleted
      shiftDate
      shiftType
      tonnes
      totalDistance
      updatedAt
      version
    }
  }
`);

export const borerShiftTargetsPullQueryBuilder = generatePullQuery(
  borerShiftTargetFeedQuery,
  PullQueryContext.Borer,
  defaultDoc90Day,
);
