import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Inspection extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly inspectionType: string;
  readonly miningMethod: string;
  readonly needsHazard: boolean;
}
export type InspectionCollection = RxCollection<Inspection> | null;
export type InspectionDocument = RxDocument<Inspection>;

export const inspectionSchema: RxJsonSchema<Inspection> = {
  type: 'object',
  version: 0,
  description: 'describes a Inspection object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    inspectionType: { type: 'string' },
    miningMethod: { type: 'string' },
    needsHazard: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

const inspectionFeedQuery = print(gql`
  query inspectionFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    inspectionFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      description
      inspectionType
      isDeleted
      miningMethod
      needsHazard
      updatedAt
      version
    }
  }
`);

export const inspectionPullQueryBuilder = generatePullQuery(
  inspectionFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
