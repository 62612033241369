import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface HazardSeverity extends BaseEntity {
  readonly description: string;
}
export type HazardSeveritiesCollection = RxCollection<HazardSeverity> | null;
export type HazardSeverityDocument = RxDocument<HazardSeverity>;

export const hazardSeveritySchema: RxJsonSchema<HazardSeverity> = {
  type: 'object',
  version: 0,
  description: 'describes a HazardSeverity object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

const hazardSeverityFeedQuery = print(gql`
  query hazardSeverityFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    hazardSeverityFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      description
      id
      isDeleted
      updatedAt
      version
    }
  }
`);

export const hazardSeverityPullQueryBuilder = generatePullQuery(
  hazardSeverityFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
