import { cloneDeep } from '@apollo/client/utilities';
import { Grid } from '@material-ui/core';
import { Button, Card, Dialog, i18n, Icons, Typography } from '@nutrien/cxp-components';
import equal from 'fast-deep-equal';
import * as markerjs2 from 'markerjs2';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useNotification from '@/utilities/hooks/useNotification';

import { useMst } from '../../mobx-models/Root';
import usePanelDrawings from '../../rxdb/PanelDrawing/usePanelDrawings';
import {
  PartialPanelDrawingComment,
  usePanelDrawingComment,
} from '../../rxdb/PanelDrawingComment/usePanelDrawingComment';
import { MiningMethod, PANEL_DRAWING_COLOURS } from '../../utilities/constants';
import usePanelDrawingBackgrounds from '../../utilities/hooks/usePanelDrawingBackgrounds';
import { useWindowSize } from '../../utilities/hooks/useWindowSize';
import { sanitizeMarkerJsData } from '../../utilities/sanitizeMarkerJsData';
import DiscardDraftModal from '../DiscardDraftModal';
import PanelDrawingCommentsCard from '../PanelDrawingCommentsCard';
import useStyles from './PanelDrawingEditModal.styles';

const { MARKER_JS_2_KEY } = import.meta.env;

interface Props {
  open: boolean;
  onClose: () => void;
  miningMethod: string;
  saveEnabledByDefault?: boolean;
}

if (MARKER_JS_2_KEY && !markerjs2.Activator.isLicensed) {
  markerjs2.Activator.addKey(MARKER_JS_2_KEY);
}

const PanelDrawingEditModal: React.FC<Props> = ({
  open,
  onClose,
  miningMethod,
  saveEnabledByDefault,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  const [setupComplete, setSetupComplete] = useState(false);
  const { createNewPanelDrawing, updatePanelDrawing, currentPanelDrawing, panelDrawingsLoaded } =
    usePanelDrawings();
  const { updatePanelDrawingComment } = usePanelDrawingComment();
  const size = useWindowSize();
  const { imageHeight, imageWidth, backgroundImage, imageWidthString, imageHeightString } =
    usePanelDrawingBackgrounds({
      size,
      miningMethod: miningMethod as MiningMethod,
    });

  const [newPanelConfirmationModalOpen, setNewPanelConfirmationModalOpen] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const isIntialRef = useRef<boolean>(true);
  const drafedMarkerAreaStateRef = useRef<markerjs2.MarkerAreaState>();
  const [markerAreaElement, setMarkerAreaElement] = useState<markerjs2.MarkerArea>();
  const [tempComments, setTempComments] = useState<PartialPanelDrawingComment[]>([]);
  const [isDiscarded, setIsDiscarded] = useState<boolean>(false);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [isSavingDrawing, setIsSavingDrawing] = useState<boolean>(false);
  // Used to control loading spinners
  const [drawingInitializing, setDrawingInitializing] = useState<boolean>(true);
  const [newDrawingLoading, setNewDrawingLoading] = useState<boolean>(false);
  // Used to prevent setup from running concurrently
  const [initializing, setInitializing] = useState<boolean>(false);
  const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(saveEnabledByDefault);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);

  const [renderedMarkers, setRenderedMarkers] = useState('');

  const { errorNotification } = useNotification();

  const setupMarkerArea = async () => {
    if (
      isDrawing ||
      imgRef.current === null ||
      initializing ||
      !currentPanelDrawing ||
      !panelDrawingsLoaded
    ) {
      return;
    }
    let parsedState;
    if (currentPanelDrawing?.drawingData) {
      parsedState = JSON.parse(currentPanelDrawing.drawingData);
      if (JSON.stringify(parsedState.markers) === renderedMarkers) {
        return;
      }
    }

    setInitializing(true);
    setSetupComplete(false);
    if (markerAreaElement) markerAreaElement.clear();
    // create a marker.js MarkerArea
    const markerArea = markerAreaElement ?? new markerjs2.MarkerArea(imgRef.current);

    // to keep track of edits
    let prevMarkerArea = cloneDeep(markerArea);

    // Styles
    markerArea.uiStyleSettings.toolbarActiveButtonStyleColorsClassName = classes.activeButton;

    // Configure image to show in correct spot because of modal
    const target = document.getElementById('markerOverlay');
    if (target) markerArea.targetRoot = target;

    markerArea.settings.defaultColorSet = PANEL_DRAWING_COLOURS;

    markerArea.renderHeight = imageHeight;
    markerArea.renderWidth = imageWidth;
    drafedMarkerAreaStateRef.current = markerArea?.getState();

    // attach an event handler to assign annotated image back to our image element
    markerArea.addEventListener('render', ({ dataUrl, state }) => {
      if (imgRef.current) {
        imgRef.current.src = dataUrl;
        setIsDiscarded(false);
        // if panel already contains drawing
        if (!isDrawing && isIntialRef.current) {
          prevMarkerArea = cloneDeep(markerArea);
        }
        // has edits
        if (!equal(prevMarkerArea?.getState(), markerArea?.getState())) setEnableSaveBtn(true);
      }
      drafedMarkerAreaStateRef.current = state;
      isIntialRef.current = false;
    });

    markerArea.addEventListener('close', () => {
      setIsDrawing(false);
      // to keep only drafted/saved items
      markerArea.restoreState(drafedMarkerAreaStateRef.current as markerjs2.MarkerAreaState);
    });

    markerArea.addEventListener('beforeclose', () => {
      // to set discarded flag on close event
      setIsDiscarded(true);
    });

    imgRef.current.src = backgroundImage?.src;

    setMarkerAreaElement(markerArea);

    if (parsedState.width) {
      markerArea.show();
      const sanitzedData = sanitizeMarkerJsData(parsedState);
      markerArea.restoreState(sanitzedData);
      setRenderedMarkers(JSON.stringify(parsedState.markers));
      await markerArea.startRenderAndClose();
    } else {
      setRenderedMarkers('');
      markerArea.clear();
    }

    setSetupComplete(true);
    setDrawingInitializing(false);
    setInitializing(false);
  };

  const [refLoaded, setRefLoaded] = useState(false);

  useEffect(() => {
    if (refLoaded && open) {
      setupMarkerArea();
    }
  }, [refLoaded, open]);

  useEffect(() => {
    // setup marker area with previous image once changes are discarded
    if (isDiscarded) setupMarkerArea();
  }, [isDiscarded]);

  const showMarkerArea = () => {
    setIsDiscarded(false);
    if (markerAreaElement && !isDrawing) {
      // launch marker.js
      markerAreaElement.show();
      setIsDrawing(true);
      isIntialRef.current = false;
    }
  };

  const savePanelDrawing = async (closeAfterSave = true) => {
    try {
      setIsDrawing(false);
      if (!markerAreaElement || !shiftPicker.currentBorerShiftId || !currentPanelDrawing) return; // TODO: Handle Error

      setIsSavingDrawing(true);

      markerAreaElement.close();
      const state = markerAreaElement.getState();
      await updatePanelDrawing(JSON.stringify(state), currentPanelDrawing);

      await Promise.all(
        tempComments.map(async comment => {
          await updatePanelDrawingComment(comment);
        }),
      );

      try {
        markerAreaElement.restoreState({
          height: imageHeight,
          width: imageWidth,
          markers: [],
        });
      } catch (err) {
        console.error('error restoring markerAreaElement');
      }

      if (imgRef?.current && backgroundImage?.src) {
        imgRef.current.src = backgroundImage?.src;
      }
      if (closeAfterSave) {
        onClose();
        setIsDiscarded(false);
      }
    } catch (e) {
      errorNotification('Error saving panel drawing');
      console.log('🚀 ~ file: PanelDrawingEditModal.tsx:208 ~ savePanelDrawing ~ e:', e);
    }
    setIsSavingDrawing(false);
  };

  const onStartNextDrawing = async () => {
    setNewPanelConfirmationModalOpen(true);
  };

  const onConfirmNewPanelDrawing = async () => {
    setDrawingInitializing(true);
    setNewDrawingLoading(true);
    await savePanelDrawing(false);
    await createNewPanelDrawing();
    setTimeout(() => {
      setNewPanelConfirmationModalOpen(false);
      setDrawingInitializing(false);
      setNewDrawingLoading(false);
    }, 1000);
  };

  const onCancelNewPanelDrawing = () => {
    setNewPanelConfirmationModalOpen(false);
  };

  const onOpenLegend = useCallback(() => {
    if (markerAreaElement) {
      markerAreaElement.startRenderAndClose();
    }
  }, [markerAreaElement]);

  const onImgRefLoad = () => setRefLoaded(true);

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
  };

  const openDiscardDraftModal = () => {
    if (enableSaveBtn) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  };

  const onDiscardDraft = () => {
    setDiscardOpen(false);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      <Dialog
        titleText="Update panel drawing"
        data-cy="PanelDrawingEditModal"
        open={open}
        onClose={isDrawing ? () => {} : openDiscardDraftModal}
        backButton={false}
        dialogContentClassName={classes.dialogContent}
        fullScreen
        fullWidth
        dialogActions={
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                noMinHeight
                onClick={() => savePanelDrawing()}
                isLoading={isSavingDrawing}
                disabled={isDrawing || !enableSaveBtn}
                id="save-draft-btn"
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        }
        BackdropProps={{
          onClick: event => {
            event.preventDefault();
          },
          onTouchStart: event => {
            event.preventDefault();
          },
          style: { touchAction: 'none' },
        }}
      >
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h3">Panel drawing</Typography>
            </Grid>
            {!isDrawing && (
              <Grid item container xs={6} justify="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    startAdornment={<Icons.Edit2Feather color="inherit" />}
                    color="primary"
                    noMinHeight
                    isLoading={drawingInitializing}
                    onClick={showMarkerArea}
                    id="start-drawing-btn"
                  >
                    Draw
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startAdornment={<Icons.CopyFeather color="primary" />}
                    noMinHeight
                    onClick={onStartNextDrawing}
                    isLoading={drawingInitializing}
                    id="start-new-drawing-btn"
                  >
                    Start next drawing
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.markerArea}>
              <img
                ref={imgRef}
                src={backgroundImage?.src}
                alt="sample"
                style={{
                  width: imageWidthString,
                  height: imageHeightString,
                  visibility: setupComplete && !isDrawing ? 'visible' : 'hidden',
                }}
                onLoad={onImgRefLoad}
                id="bgImage"
                className={classes.bgImg}
              />

              <div id="markerOverlay" className={classes.markerArea} />
            </Grid>
            <Grid item xs={12}>
              {currentPanelDrawing?.id && (
                <PanelDrawingCommentsCard
                  panelDrawingId={currentPanelDrawing?.id}
                  canEdit
                  tempComments={tempComments}
                  setTempComments={setTempComments}
                  onOpen={onOpenLegend}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Dialog>
      <Dialog
        titleText={i18n.t('Start next drawing')}
        open={newPanelConfirmationModalOpen}
        onClose={onCancelNewPanelDrawing}
        backButton={false}
        dialogContentClassName={classes.dialogContent}
        disableBackdropClick
        dialogActions={
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={4}>
              <Button
                onClick={onCancelNewPanelDrawing}
                variant="outlined"
                color="primary"
                noMinHeight
                disabled={newDrawingLoading}
                id="cancel-new-panel-btn"
              >
                {i18n.t('Cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={onConfirmNewPanelDrawing}
                variant="contained"
                color="primary"
                noMinHeight
                isLoading={newDrawingLoading}
                id="confirm-new-panel-btn"
              >
                {i18n.t('Start next drawing')}
              </Button>
            </Grid>
          </Grid>
        }
        maxWidth="xs"
        maxHeight="215px"
        BackdropProps={{
          onClick: event => {
            event.preventDefault();
          },
          onTouchStart: event => {
            event.preventDefault();
          },
          style: { touchAction: 'none' },
        }}
      >
        <Grid container>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={1}>
              <Icons.InfoFeather color="error" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">
                {i18n.t(
                  'This will save the current drawing. Once the next drawing is started, the current drawing will become read only.',
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <DiscardDraftModal
        open={discardOpen}
        onCancel={onCancelDiscardDraft}
        onDiscard={onDiscardDraft}
        disableEnforceFocus
      />
    </>
  );
};

export default observer(PanelDrawingEditModal);
