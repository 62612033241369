import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface SurveyPoint extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly miningPattern: string;
  readonly room: string;
}
export type SurveyPointsCollection = RxCollection<SurveyPoint> | null;
export type SurveyPointDocument = RxDocument<SurveyPoint>;

export const surveyPointsSchema: RxJsonSchema<SurveyPoint> = {
  type: 'object',
  version: 1,
  description: 'describes a SurveyPoint object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    miningPattern: {
      ref: RxdbCollectionName.MINING_PATTERN,
      type: ['string', 'null'],
    },
    room: { ref: 'rooms', type: 'string' },
    version: { type: 'number' },
  },
};

export const surveyPointMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SurveyPointDocument) => ({ ...oldDoc }),
};

const surveyPointFeedQuery = print(gql`
  query surveyPointFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    surveyPointFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      description
      isActive
      isDeleted
      miningPattern
      room
      updatedAt
      version
    }
  }
`);

export const surveyPointsPullQueryBuilder = generatePullQuery(
  surveyPointFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
