import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface Production extends BaseEntity {
  readonly borerShiftId: string;
  readonly locationId: string;
  readonly miningCutId: string | null;
  readonly scoopEquipmentId: string | null;
  readonly stamlerEquipmentId: string | null;
}
export type ProductionCollection = RxCollection<Production> | null;
export type ProductionDocument = RxDocument<Production>;

export const productionSchema: RxJsonSchema<Production> = {
  type: 'object',
  version: 1,
  description: 'describes a Production object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    locationId: {
      ref: 'locations',
      type: 'string',
    },
    miningCutId: {
      ref: RxdbCollectionName.MINING_CUTS,
      type: ['string', 'null'],
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    scoopEquipmentId: { ref: RxdbCollectionName.EQUIPMENT, type: ['string', 'null'] },
    stamlerEquipmentId: { ref: RxdbCollectionName.EQUIPMENT, type: ['string', 'null'] },
  },
  indexes: ['updatedAt'],
};

export const productionMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: ProductionDocument) => ({
    ...oldDoc,
    scoopEquipmentId: null,
    stamlerEquipmentId: null,
  }),
};

const borerShiftProductionFeedQuery = print(gql`
  query borerShiftProductionFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftProductionFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftId
      id
      locationId
      miningCutId
      isDeleted
      updatedAt
      version
      scoopEquipmentId
      stamlerEquipmentId
    }
  }
`);

export const productionPullQueryBuilder = generatePullQuery(
  borerShiftProductionFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftProductionMutation = print(gql`
  mutation setBorerShiftProduction($production: SetBorerShiftProductionInput) {
    setBorerShiftProduction(input: $production)
  }
`);

type SetBorerShiftProductionInput = {
  id: string;
  borerShiftId: string;
  locationId: string;
  miningCutId: string | null;
  stamlerEquipmentId: string | null;
  scoopEquipmentId: string | null;
  createdOn: string | null;
  modifiedOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapProductionDocumentToInput = (doc: ProductionDocument): SetBorerShiftProductionInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    locationId: doc.locationId,
    miningCutId: doc.miningCutId,
    stamlerEquipmentId: doc.stamlerEquipmentId,
    scoopEquipmentId: doc.scoopEquipmentId,
    createdOn: doc.createdOn,
    modifiedOn: null,
    isDeleted: doc.isDeleted,
    version: doc.version,
  };
};

export const productionPushQueryBuilder = generatePushQuery(
  setBorerShiftProductionMutation,
  (doc: ProductionDocument) => ({
    production: mapProductionDocumentToInput(doc),
  }),
);
