import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import usePanelDrawings from '../../rxdb/PanelDrawing/usePanelDrawings';
import ActiveConditionsCard from '../ActiveConditionsCard';
import SafeWorkPlanDocumentCard from '../pages/Signatures/SafeWorkPlan/SafeWorkPlanDocumentCard';
import PanelDrawingViewEditCard from '../PanelDrawingViewEditCard';
import RemediatedConditionsCard from '../RemediatedConditionsCard';
import SignaturesCard from '../SignaturesCard';

dayjs.extend(utc);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      touchAction: 'none',
    },
    container: {
      overflowY: 'scroll',
      height: 'calc(100vh - 160px)',
      zIndex: 1500,
    },
    backgroundCard: {
      backgroundColor: '#121212 !important',
    },
  }),
);

const Signatures: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const [shiftDateStart, setShiftDateStart] = useState<number>();
  const [shiftDateEnd, setShiftDateEnd] = useState<number>();

  useEffect(() => {
    const shiftStart = shiftPicker.selectedShiftDate.unix();
    const shiftEnd = shiftPicker.selectedShiftEndDate.unix();

    setShiftDateStart(shiftStart);
    setShiftDateEnd(shiftEnd);
  }, [
    shiftPicker.Date,
    shiftPicker.Type,
    shiftPicker.currentShiftId,
    shiftPicker.currentBorerShiftId,
  ]);

  const { currentPanelDrawing } = usePanelDrawings();

  return (
    <div
      className={classes.root}
      onTouchStart={e => {
        e.preventDefault();
      }}
    >
      <Card className={classes.backgroundCard}>
        <Grid container className={classes.container} alignContent="flex-start">
          <Grid item xs={12}>
            {shiftPicker.currentBorerShiftId !== null && (
              <SignaturesCard shiftDateStart={shiftDateStart} />
            )}
          </Grid>
          <Grid item xs={12}>
            <SafeWorkPlanDocumentCard />
          </Grid>
          <Grid item xs={12}>
            <ActiveConditionsCard
              showAddButton={false}
              conditionsAlwaysExpanded
              hideAddPhotoAction
              hideAddComment
            />
          </Grid>
          <Grid item xs={12}>
            {currentPanelDrawing && (
              <PanelDrawingViewEditCard
                panelDrawing={currentPanelDrawing}
                editMode={false}
                activeDrawing
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <RemediatedConditionsCard
              useShiftDate
              shiftDateStart={shiftDateStart}
              shiftDateEnd={shiftDateEnd}
              conditionsAlwaysExpanded
              hideAddPhotoAction
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default observer(Signatures);
