import { useEffect, useState } from 'react';
import type { RxDocument } from 'rxdb';
import { useRxCollection } from 'rxdb-hooks';

import { SupplyMapper } from '../../utilities/supplyMapper';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SupplyItemCollection, SupplyItemDocument } from '../SupplyItem/queryBuilder';
import { EnrichedSupply, SupplyCollection, SupplyDocument } from './queryBuilder';

export const useSupply = () => {
  const supplyCollection: SupplyCollection = useRxCollection(RxdbCollectionName.SUPPLIES);
  const supplyItemsCollection: SupplyItemCollection = useRxCollection(
    RxdbCollectionName.SUPPLY_ITEMS,
  );

  const [supplyInitialized, setSupplyInitialized] = useState(false);

  useEffect(() => {
    if (supplyItemsCollection && supplyItemsCollection) {
      setSupplyInitialized(true);
    }
  }, [supplyCollection, supplyItemsCollection]);

  const listSupplies = async (): Promise<EnrichedSupply[]> => {
    if (!supplyCollection || !supplyItemsCollection) {
      return [];
    }
    try {
      const enrichedSupplies: EnrichedSupply[] = [];
      const supplies = await supplyCollection.find().exec();
      await Promise.all(
        supplies.map(supply =>
          supplyItemsCollection
            .findOne({
              selector: {
                id: supply.supplyItemId,
                isActive: true,
              },
            })
            .exec()
            .then(supplyItem => {
              if (supplyItem) {
                enrichedSupplies.push(SupplyMapper.MapFromSupplyDocument(supply, supplyItem));
              }
            }),
        ),
      );
      return enrichedSupplies;
    } catch (error) {
      console.log('🚀 ~ file: useSupply.ts ~ line 50 ~ listSupplies ~ error', error);
      throw error;
    }
  };

  const listSupplyItems = async (): Promise<SupplyItemDocument[]> => {
    if (!supplyItemsCollection) return [];
    try {
      const supplyItems = await supplyItemsCollection.find().exec();
      return supplyItems.sort((a, b) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
      );
    } catch (error) {
      console.log('🚀 ~ file: useSupply.ts ~ line 66 ~ listSupplyItems ~ error', error);
      throw error;
    }
  };

  const updateSupplies = async (
    supplyItemsToEdit: EnrichedSupply[],
    supplyItemsToDelete: EnrichedSupply[],
  ) => {
    if (supplyCollection) {
      await Promise.all(
        supplyItemsToEdit.map(async enrichedSupply => {
          const doc: RxDocument<SupplyDocument> = {
            ...enrichedSupply,
          };
          delete doc.supplyItemDescription;
          delete doc.supplyItemUnit;
          delete doc.isNew;

          if (enrichedSupply.isNew) {
            return supplyCollection.insert(doc);
          }
          return supplyCollection?.upsert(doc);
        }),
      );

      await Promise.all(
        supplyItemsToDelete.map(async enrichedSupply => {
          const doc = await supplyCollection
            ?.findOne({
              selector: {
                id: enrichedSupply.id,
              },
            })
            .exec();

          await doc?.remove();
        }),
      );
    }
  };

  return {
    supplyInitialized,
    listSupplies,
    listSupplyItems,
    supplyCollection,
    supplyItemsCollection,
    updateSupplies,
  };
};

export default useSupply;
