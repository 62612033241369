import { cloneDeep } from '@apollo/client/utilities';
import { createStyles, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CustomPalette,
  i18n,
  Icons,
  MaterialPalette,
  Typography,
} from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  EquipmentWithType,
  queryEquipmentWithTypeDetails,
} from '@/rxdb/EquipmentType/useEquipmentListByTypeName';

import { useMst } from '../../../../mobx-models/Root';
import { Advance } from '../../../../rxdb/Advance/queryBuilder';
import { useAdvances } from '../../../../rxdb/Advance/useAdvances';
import { BlockDocument } from '../../../../rxdb/Blocks/queryBuilder';
import useBlocks from '../../../../rxdb/Blocks/useBlocks';
import { useCurrentBorer } from '../../../../rxdb/Equipment/useCurrentBorer';
import useLocations, { PopulatedLocation } from '../../../../rxdb/Locations/useLocations';
import { MiningCutSequencePassObj } from '../../../../rxdb/MiningCut/queryBuilder';
import useMiningCuts from '../../../../rxdb/MiningCut/useMiningCuts';
import { PanelDocument } from '../../../../rxdb/Panels/queryBuilder';
import { ProductionDocument } from '../../../../rxdb/Productions/queryBuilder';
import { ProductionLocationInfo, useProduction } from '../../../../rxdb/Productions/useProduction';
import { Room } from '../../../../rxdb/Rooms/queryBuilder';
import useRooms from '../../../../rxdb/Rooms/useRooms';
import { generateBaseEntityWithCreatedOn } from '../../../../rxdb/rxdbUtilityFunctions';
import { SurveyPointDocument } from '../../../../rxdb/SurveyPoints/queryBuilder';
import { useNotification } from '../../../../utilities';
import { FEET_PER_METERS } from '../../../../utilities/constants';
import { MiningMethodAllCap } from '../../../../utilities/enums';
import DiscardDraftModal from '../../../DiscardDraftModal';
import GenericSidePanel from '../../../GenericSidePanel';
import AdvancePanel from './AdvancePanel';
import AdvanceSubPanel from './AdvanceSubPanel';
import { AdvanceErrorObj, AdvanceFootageError } from './AdvanceSubPanel/AdvanceSubPanel';
import { endValidation, isValidDistance, startValidation } from './useValidation';

export interface AdvanceErrors {
  room: string;
  sequence: string;
  step: string;
  advanceFootage: string;
  advanceStartFootage: string;
  advanceEndFootage: string;
  scoop: string;
  numberOfBuckets: string;
}
export interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onCancel?: () => void;
  productionToEdit?: ProductionDocument;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      borderRadius: 4,
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      padding: '16px !important',
    },
    addAdvanceButton: {
      marginTop: '10px',
    },
    addAnotherAdvanceButton: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
    },
  }),
);

export enum ProductionPanelOperation {
  ResetAdvanceInformation,
  AddAdvance,
  DeleteAdvance,
}

const ModifyAdvanceSidePanel: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  onCancel,
  productionToEdit,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const {
    createProduction,
    updateProduction,
    mostRecentProductionLocationInformation,
    findExistingProduction,
    listAdvancesForProductionId,
  } = useProduction();
  const { isVanscoy, isLanigan, isCory, isAllan, isRocanville } = useSiteFeatures();
  const { createAdvance, updateAdvance, deleteAdvance } = useAdvances();
  const { createLocation } = useLocations();
  const { getMiningCutLabel } = useMiningCuts();
  const [loading, setLoading] = useState(true);
  const [deleteAdvanceModalOpen, setDeleteAdvanceModalOpen] = useState<boolean>(false);
  const [advanceToDelete, setAdvanceToDelete] = useState<Advance>();
  const { currentBlock, currentPanel, currentRoom, showAllanRehabFields, miningMethod } =
    useCurrentBorer();

  const { successNotification, errorNotification } = useNotification();
  const [validationIndex, setValidationIndex] = useState(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);

  // Errors for add advance
  const [errors, setErrors] = useState<AdvanceErrors>({
    room: '',
    sequence: '',
    step: '',
    advanceFootage: '',
    advanceStartFootage: '',
    advanceEndFootage: '',
    scoop: '',
    numberOfBuckets: '',
  });

  // For edit advance mode (multiple advances)
  const [advanceFootageErrors, setAdvanceFootageErrors] = useState<AdvanceErrorObj>({});

  const [advanceExpansionPanels, setAdvanceExpansionPanels] = useState<any>({});

  // Advance properties
  const [block, setBlock] = useState<BlockDocument>();
  const [panel, setPanel] = useState<PanelDocument>();
  const [originalPanel, setOriginalPanel] = useState<PanelDocument | undefined>();
  const [room, setRoom] = useState<Room>();
  const [originalRoom, setOriginalRoom] = useState<Room>();
  const [surveyPoint, setSurveyPoint] = useState<SurveyPointDocument>();
  const [originalSurveyPoint, setOriginalSurveyPoint] = useState<SurveyPointDocument>();
  const [selectedMiningCutSequencePass, setSelectedMiningCutSequencePass] =
    useState<MiningCutSequencePassObj>();
  const [originalMiningCutSequencePass, setOriginalMiningCutSequencePass] =
    useState<MiningCutSequencePassObj>();
  const [advanceFootage, setAdvanceFootage] = useState<string | number>();
  const [advanceStartFootage, setAdvanceStartFootage] = useState<string | number>('');
  const [originalAdvanceStartFootage, setOriginalAdvanceStartFootage] = useState<string | number>(
    '',
  );

  const [advanceEndFootage, setAdvanceEndFootage] = useState<string>('');
  const [advancesToEdit, setAdvancesToEdit] = useState<Advance[]>([]);
  const [originalAdvancesToEdit, setOriginalAdvancesToEdit] = useState<Advance[]>([]);
  const [advancesToDelete, setAdvancesToDelete] = useState<Advance[]>([]);
  const [selectedStamler, setSelectedStamler] = useState<EquipmentWithType>();
  const [selectedScoop, setSelectedScoop] = useState<EquipmentWithType>();
  const [numberOfBuckets, setNumberOfBuckets] = useState<string | number>('');

  const onSetUndo = useCallback((value: boolean) => {
    setUndo(value);
  }, []);

  // Pre Populate Room
  const setupEditFields = async () => {
    if (productionToEdit) {
      const [cutToEdit, locToEdit, stamlerToEdit, scoopToEdit] = await Promise.all([
        productionToEdit.populate('miningCutId'),
        productionToEdit.populate('locationId'),
        queryEquipmentWithTypeDetails(productionToEdit?.stamlerEquipmentId),
        queryEquipmentWithTypeDetails(productionToEdit?.scoopEquipmentId),
      ]);

      const [seqToEdit, passToEdit, pnlToEdit, roomToEdit, surveyPtToEdit] = await Promise.all([
        cutToEdit.populate('sequence'),
        cutToEdit.populate('pass'),
        locToEdit.populate('panel'),
        locToEdit.populate('room'),
        locToEdit.populate('surveyPoint'),
      ]);

      const blockToEdit = await pnlToEdit.populate('block');

      setBlock(blockToEdit);
      setPanel(pnlToEdit);
      setOriginalPanel(pnlToEdit);
      setRoom(roomToEdit);
      setOriginalRoom(roomToEdit);
      setSurveyPoint(surveyPtToEdit);
      setOriginalSurveyPoint(surveyPtToEdit);
      setSelectedMiningCutSequencePass({
        miningMethod: pnlToEdit.miningMethod,
        miningCut: cutToEdit,
        sequence: seqToEdit,
        pass: passToEdit,
      });
      setOriginalMiningCutSequencePass({
        miningMethod: pnlToEdit.miningMethod,
        miningCut: cutToEdit,
        sequence: seqToEdit,
        pass: passToEdit,
      });
      setSelectedStamler(stamlerToEdit);
      setSelectedScoop(scoopToEdit);
    }
  };
  // options

  const isChevron = useMemo(() => {
    // 1: Selected panel mining method
    if (panel?.miningMethod) {
      return panel?.miningMethod.toUpperCase() === MiningMethodAllCap.CHEVRON;
    }
    // 2: Edited production mining method
    if (productionToEdit?.miningMethod) {
      return panel?.miningMethod.toUpperCase() === MiningMethodAllCap.CHEVRON;
    }
    // 3: Previous production mining method
    if (originalPanel?.miningMethod) {
      return originalPanel?.miningMethod?.toUpperCase() === MiningMethodAllCap.CHEVRON;
    }
    // 4 & 5: Borer location mining method, fallback to assigned panel mining method
    return miningMethod?.toUpperCase() === MiningMethodAllCap.CHEVRON;
  }, [originalPanel, productionToEdit, panel, miningMethod]);

  const setupAdvances = async () => {
    if (productionToEdit?.id) {
      const advancesForProduction = await listAdvancesForProductionId(productionToEdit?.id);
      setAdvancesToEdit(advancesForProduction);
      setOriginalAdvancesToEdit(cloneDeep(advancesForProduction));
      setLoading(false);
    }
  };

  // Validate Sequence (Section for Vanscoy, SurveyPoint in BE)
  const onSurveyPointChanged = (newSurveyPoint: SurveyPointDocument, setValidation = true) => {
    if (setValidation) setValidationIndex(prev => (prev >= 2 ? prev : 2));
    setSurveyPoint(newSurveyPoint);
  };

  const validateSequenceChange = (value: any, setValidation = true) => {
    onSurveyPointChanged(value, setValidation);
    setSelectedMiningCutSequencePass(undefined);
    if (setValidation) setValidationIndex(prev => (prev >= 2 ? prev : 2));
  };

  const clearSubPanelFields = () => {
    setSurveyPoint(undefined);
  };

  const validateRoomChange = async (newRoom: PopulatedLocation, setValidation = true) => {
    if (newRoom && newRoom.block) {
      setBlock(newRoom.block);
    }

    if (newRoom && newRoom.panel) {
      if (setValidation) setValidationIndex(prev => (prev >= 1 ? prev : 1));
      setAdvanceStartFootage('');
      setAdvanceEndFootage('');
      setAdvanceFootage('');
      setPanel(newRoom.panel);
    }
    if (newRoom && newRoom.room) {
      setRoom(newRoom.room);
    }

    clearSubPanelFields();
    setSelectedMiningCutSequencePass(undefined);
  };

  // Pre Populate Room
  const prePopulateLocationFields = async () => {
    setLoading(true);

    const {
      surveyPoint: recentSurveyPoint,
      miningCutSequencePassObj: recentMiningCutSequencePassObj,
      room: recentRoom,
      panel: recentPanel,
      block: recentBlock,
      mostRecentFootage,
    } = mostRecentProductionLocationInformation as ProductionLocationInfo;

    setOriginalRoom(recentRoom || currentRoom);
    setOriginalPanel(recentPanel || currentPanel);

    await validateRoomChange(
      {
        block: recentBlock || currentBlock,
        panel: recentPanel || currentPanel,
        room: recentRoom || currentRoom,
      },
      false,
    );

    if (recentSurveyPoint) {
      validateSequenceChange(recentSurveyPoint, false);
      setOriginalSurveyPoint(recentSurveyPoint);
    }

    if (recentMiningCutSequencePassObj) {
      setOriginalMiningCutSequencePass(recentMiningCutSequencePassObj);
      setSelectedMiningCutSequencePass(recentMiningCutSequencePassObj);
    }

    if (mostRecentFootage || mostRecentFootage === 0) {
      setAdvanceStartFootage(mostRecentFootage);
      setAdvanceFootage(mostRecentFootage);
      setOriginalAdvanceStartFootage(mostRecentFootage);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setValidationIndex(0);
      setErrors({
        room: '',
        sequence: '',
        step: '',
        advanceFootage: '',
        advanceStartFootage: '',
        advanceEndFootage: '',
        numberOfBuckets: '',
        scoop: '',
      });
      setPanel(undefined);
      setIsSaving(false);
      setRoom(undefined);
      setAdvanceFootageErrors({});
      setSurveyPoint(undefined);
      setSelectedMiningCutSequencePass(undefined);
      setAdvanceFootage('');
      setAdvanceStartFootage('');
      setAdvanceEndFootage('');
      setSelectedStamler(undefined);
      setSelectedScoop(undefined);
      setNumberOfBuckets('');

      if (productionToEdit) {
        setupEditFields();
      } else {
        prePopulateLocationFields();
      }
      setupAdvances();
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  const validateMiningCutChange = async (value: MiningCutSequencePassObj) => {
    setValidationIndex(prev => (prev >= 5 ? prev : 5));
    setSelectedMiningCutSequencePass(value);

    // Pre-fill start distance for longroom
    const existingProduction = await findExistingProduction(room, surveyPoint, value);
    const advancesForProduction = await listAdvancesForProductionId(existingProduction?.id);
    const lastAdvance = advancesForProduction[advancesForProduction.length - 1];
    if (!isChevron && (lastAdvance?.endDistance || lastAdvance?.endDistance === 0)) {
      setAdvanceStartFootage(lastAdvance?.endDistance);
    }
  };

  const { defaultBlock } = useBlocks();
  const { defaultRoomForPanel } = useRooms(panel?.id);

  useEffect(() => {
    if (isCory || isAllan || (isVanscoy && isChevron)) {
      if (defaultRoomForPanel?.id) {
        setRoom(defaultRoomForPanel);
      }

      if (defaultBlock?.id) {
        setBlock(defaultBlock);
      }
    }
  }, [defaultRoomForPanel, defaultBlock, isCory, isVanscoy, isChevron, isAllan]);

  const onAddAnotherAdvance = () => {
    const newAdvances = [...advancesToEdit];
    const newAdvance = {
      ...generateBaseEntityWithCreatedOn(),
      distance: '',
      startDistance: '',
      endDistance: '',
      borerShiftProductionId: productionToEdit?.id || '',
    };
    if (
      !isChevron &&
      !isCory &&
      !isAllan &&
      !isNaN(newAdvances[newAdvances.length - 1]?.endDistance)
    ) {
      newAdvance.startDistance = newAdvances[newAdvances.length - 1]?.endDistance || 0;
    }
    newAdvances.push(newAdvance);

    const newExpandedValues = { ...advanceExpansionPanels };
    newExpandedValues[newAdvance.id] = true;
    setAdvanceExpansionPanels(newExpandedValues);
    setAdvancesToEdit(newAdvances);
  };

  const confirmDeleteAdvance = () => {
    if (advanceToDelete) {
      let newAdvances = [...advancesToEdit];
      newAdvances = newAdvances.filter(advance => advance.id !== advanceToDelete.id);

      const toDelete = [...advancesToDelete];
      toDelete.push(advanceToDelete);

      setAdvanceFootageErrors(prev => {
        const clone = { ...prev };
        delete clone[advanceToDelete.id];
        return clone;
      });

      const newExpandedValues = { ...advanceExpansionPanels };
      delete newExpandedValues[advanceToDelete.id];
      setAdvanceExpansionPanels(newExpandedValues);
      setAdvancesToEdit(newAdvances);
      setAdvanceToDelete(undefined);
      setDeleteAdvanceModalOpen(false);
      setAdvancesToDelete(toDelete);
    }
  };

  const onAdvanceFootageChanged = (value: string) => {
    setAdvanceFootage(value);
    setValidationIndex(prev => (prev >= 5 ? prev : 5));

    if (RegExp('^[0-9]+$').test(value)) {
      if (value.length > 7) {
        setErrors(prev => ({ ...prev, advanceFootage: 'Must be 7 digits or less' }));
      } else {
        setErrors(prev => ({ ...prev, advanceFootage: '' }));
      }
    } else {
      setErrors(prev => ({
        ...prev,
        advanceFootage: i18n.t('Invalid Distance.'),
      }));
    }
  };

  const validateAdvanceStartFootageChange = (value: string) => {
    setValidationIndex(prev => (prev >= 6 ? prev : 6));
    setAdvanceStartFootage(value);
  };

  const validateAdvanceEndFootageChange = (value: string) => {
    setValidationIndex(prev => (prev >= 7 ? prev : 7));
    setAdvanceEndFootage(value);
  };

  const validateStamlerChange = (value?: EquipmentWithType) => {
    setSelectedStamler(value);
    setValidationIndex(prev => (prev >= 8 ? prev : 8));
  };
  const validateScoopChange = (value?: EquipmentWithType) => {
    setSelectedScoop(value);
    setValidationIndex(prev => (prev >= 9 ? prev : 9));
  };
  const validateNumberOfBucketsChange = (value: string) => {
    setNumberOfBuckets(value);
    setValidationIndex(prev => (prev >= 10 ? prev : 10));
  };

  // Determine if pass is even or odd
  const isEvenPass = useMemo(() => {
    const passDesc =
      selectedMiningCutSequencePass?.sequencePassString ||
      getMiningCutLabel(selectedMiningCutSequencePass);
    const isHeadingPass = passDesc?.toLowerCase().includes('heading') || false;

    const selectedPass = passDesc?.replace(/\D/g, '') || '';
    const selectedPassNum = parseFloat(selectedPass);

    if (isNaN(selectedPassNum) || isHeadingPass) return false;

    if (selectedPassNum % 2 === 0) {
      return true;
    }
    return false;
  }, [selectedMiningCutSequencePass]);

  // Calculate Advance Footage when values are valid
  const calculatedAdvanceFootage = useMemo(() => {
    const advanceStartFootageNum = parseFloat(advanceStartFootage);
    const advanceEndFootageNum = parseFloat(advanceEndFootage);

    if (
      Number.isInteger(advanceStartFootageNum) &&
      advanceStartFootageNum >= 0 &&
      Number.isInteger(advanceEndFootageNum) &&
      advanceEndFootageNum >= 0
    ) {
      // Odd Pass
      if (isEvenPass === false && advanceEndFootageNum >= advanceStartFootageNum) {
        return advanceEndFootageNum - advanceStartFootageNum;
      }

      // Even Pass
      if (isEvenPass === true && advanceEndFootageNum <= advanceStartFootageNum) {
        return advanceStartFootageNum - advanceEndFootageNum;
      }
    }

    return null;
  }, [advanceStartFootage, advanceEndFootage, isEvenPass]);

  // Save/Create
  const onSave = async () => {
    setIsSaving(true);

    if (!productionToEdit) {
      try {
        if (!shiftPicker.currentBorerShiftId) {
          throw new Error('Missing current borer shift id');
        }
        // check if a production exists for the currently selected room, sequence, step combination
        const existingProduction = await findExistingProduction(
          room,
          surveyPoint,
          selectedMiningCutSequencePass,
          selectedScoop?.equipment?.id || null,
          selectedStamler?.equipment?.id || null,
          isLanigan || (isVanscoy && !isChevron) || isRocanville,
          showAllanRehabFields,
        );

        // convert types and handle nulls and strings
        const advanceDistanceNum = advanceFootage ? parseFloat(advanceFootage) : 0;
        const advanceStartFootageNum =
          advanceStartFootage !== '' ? parseFloat(advanceStartFootage) : null;
        const advanceEndFootageNum =
          advanceEndFootage !== '' ? parseFloat(advanceEndFootage) : null;

        // create new Advance
        const advance: Advance = {
          ...generateBaseEntityWithCreatedOn(),
          distance: advanceDistanceNum || calculatedAdvanceFootage || 0,
          startDistance: advanceStartFootageNum,
          endDistance: advanceEndFootageNum,
          borerShiftProductionId: shiftPicker.currentBorerShiftId,
          numberOfBuckets: Number(numberOfBuckets),
        };

        if (existingProduction) {
          await createAdvance(existingProduction?.id, advance);
          await updateProduction(
            existingProduction,
            existingProduction.locationId,
            existingProduction.miningCutId,
            selectedScoop?.equipment?.id || null,
            selectedStamler?.equipment?.id || null,
          );
        } else if (panel && selectedMiningCutSequencePass) {
          const loc = await createLocation(
            panel?.id,
            room?.id,
            surveyPoint?.id,
            selectedMiningCutSequencePass?.sequence?.id,
            selectedMiningCutSequencePass?.pass?.id,
            advanceDistanceNum / FEET_PER_METERS,
            advanceDistanceNum / FEET_PER_METERS,
          );
          const newProduction = await createProduction(
            loc.id,
            selectedMiningCutSequencePass,
            selectedScoop?.equipment?.id || null,
            selectedStamler?.equipment?.id || null,
          );
          if (newProduction) await createAdvance(newProduction.id, advance);
        }

        successNotification('Advance added');
        onClose();
      } catch (error) {
        setIsSaving(false);
        console.error(`ERROR saving advance: ${error}`);
        errorNotification(`Could not add advance.`);
      }
    } else {
      setIsSaving(true);
      const { locationId } = productionToEdit;
      try {
        if (!shiftPicker.currentBorerShiftId || !selectedMiningCutSequencePass) {
          throw new Error('Missing required information');
        }
        await updateProduction(
          productionToEdit,
          locationId,
          selectedMiningCutSequencePass?.miningCut?.id,
          selectedScoop?.equipment?.id || null,
          selectedStamler?.equipment?.id || null,
        );
        await Promise.all(advancesToEdit.map(advance => updateAdvance(advance)));
        await Promise.all(advancesToDelete.map(advance => deleteAdvance(advance.id)));
        successNotification('Advance updated');
        onClose();
      } catch (error) {
        setIsSaving(false);
        console.error(`ERROR updating advance: ${error}`);
        errorNotification(`Could not update advance.`);
      }
    }
  };

  useEffect(() => {
    setErrors(prev => ({
      ...prev,
      advanceStartFootage: startValidation({ startDistance: advanceStartFootage }),
      advanceEndFootage: endValidation(
        { startDistance: advanceStartFootage, endDistance: advanceEndFootage },
        isEvenPass,
        isRocanville,
      ),
    }));
  }, [isEvenPass, advanceStartFootage, advanceEndFootage, isChevron, isRocanville]);

  const hasEdits = useMemo(() => {
    if (
      originalRoom?.id !== room?.id ||
      originalPanel?.id !== panel?.id ||
      originalSurveyPoint?.id !== surveyPoint?.id ||
      originalMiningCutSequencePass?.pass?.id !== selectedMiningCutSequencePass?.pass?.id ||
      originalMiningCutSequencePass?.sequence?.id !== selectedMiningCutSequencePass?.sequence?.id ||
      (isChevron && advanceFootage !== originalAdvanceStartFootage) ||
      (!isChevron && advanceStartFootage !== originalAdvanceStartFootage) ||
      !!advanceEndFootage ||
      (showAllanRehabFields &&
        (productionToEdit?.scoopEquipmentId !== selectedScoop?.equipment?.id ||
          productionToEdit?.stamlerEquipmentId !== selectedStamler?.equipment?.id))
    )
      return true;

    if (productionToEdit) {
      if (originalAdvancesToEdit.length !== advancesToEdit.length) return true;
      if (
        (isChevron || isCory || isAllan) &&
        !showAllanRehabFields &&
        advancesToEdit.find(
          (advance, index) =>
            Number(advance.distance) !== Number(originalAdvancesToEdit[index].distance),
        )
      ) {
        return true;
      }
      if (
        !isChevron &&
        !isCory &&
        !showAllanRehabFields &&
        advancesToEdit.find(
          (advance, index) =>
            Number(advance.startDistance) !== Number(originalAdvancesToEdit[index].startDistance) ||
            Number(advance.endDistance) !== Number(originalAdvancesToEdit[index].endDistance),
        )
      ) {
        return true;
      }
      if (
        showAllanRehabFields &&
        advancesToEdit.find(
          (advance, index) =>
            Number(advance.numberOfBuckets) !==
            Number(originalAdvancesToEdit[index].numberOfBuckets),
        )
      ) {
        return true;
      }
    }

    return false;
  }, [
    originalRoom,
    originalSurveyPoint,
    room,
    surveyPoint,
    originalMiningCutSequencePass,
    originalMiningCutSequencePass?.pass,
    originalMiningCutSequencePass?.sequence,
    selectedMiningCutSequencePass,
    panel,
    originalPanel,
    advanceFootage,
    originalAdvanceStartFootage,
    advanceEndFootage,
    advanceStartFootage,
    advancesToEdit,
    originalAdvancesToEdit,
    productionToEdit,
    isChevron,
    isCory,
    isAllan,
    selectedScoop,
    selectedStamler,
    showAllanRehabFields,
  ]);

  const canSave = useMemo(() => {
    if (!hasEdits) return false;
    if (!room) return false;
    if (!panel) return false;
    if (!selectedMiningCutSequencePass) return false;
    if ((isChevron || isCory || isAllan) && !surveyPoint) return false;
    if (productionToEdit) {
      // 'Edit Advance' mode
      const errs: AdvanceFootageError[] = Object.values(advanceFootageErrors);
      if (
        (isChevron || isCory || isAllan) &&
        !showAllanRehabFields &&
        errs.find(errorObj => !!errorObj.footage)
      )
        return false;

      if (showAllanRehabFields && errs.find(errorObj => !!errorObj.numberOfBuckets)) return false;
      if (
        !isChevron &&
        !isCory &&
        !isAllan &&
        errs.find(errorObj => !!errorObj.startFootage || errorObj.endFootage)
      )
        return false;

      if (
        (isChevron || isCory || isAllan) &&
        !showAllanRehabFields &&
        advancesToEdit.filter(x => x.distance === '').length > 0
      )
        return false;
      if (
        !isChevron &&
        !isCory &&
        !isAllan &&
        advancesToEdit.filter(x => x.startDistance === '' || x.endDistance === '').length > 0
      )
        return false;
    } else {
      // 'Create Advance' mode
      if (
        (isChevron || isCory || isAllan) &&
        !showAllanRehabFields &&
        (advanceFootage === '' || !!errors.advanceFootage)
      )
        return false;

      // Vanscoy Longroom
      if (
        !isChevron &&
        !isCory &&
        !isAllan &&
        (errors.advanceStartFootage !== '' ||
          errors.advanceEndFootage !== '' ||
          !isValidDistance(advanceStartFootage) ||
          !isValidDistance(advanceEndFootage))
      )
        return false;
    }

    if (
      showAllanRehabFields &&
      (!!errors.numberOfBuckets ||
        (!productionToEdit && !numberOfBuckets && numberOfBuckets !== 0) ||
        !!errors.scoop ||
        !selectedScoop)
    ) {
      return false;
    }

    return true;
  }, [
    room,
    panel,
    surveyPoint,
    advanceFootage,
    errors,
    isChevron,
    selectedMiningCutSequencePass,
    hasEdits,
    productionToEdit,
    advancesToEdit,
    advanceFootageErrors,
    isCory,
    advanceStartFootage,
    advanceEndFootage,
    isAllan,
    showAllanRehabFields,
    numberOfBuckets,
    selectedScoop,
  ]);

  if (!open) return null;
  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={productionToEdit ? i18n.t('Edit advance') : i18n.t('Add advance')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        loading={loading}
        discardNotificationText="Advance draft discarded"
      >
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">{i18n.t('Location')}</Typography>
            </Grid>
            <AdvancePanel
              isChevron={isChevron}
              block={block}
              panel={panel}
              room={room}
              surveyPoint={surveyPoint}
              advanceFootage={advanceFootage}
              errors={errors}
              validateRoomChange={validateRoomChange}
              validateSequenceChange={validateSequenceChange}
              validateAdvanceStartFootageChange={validateAdvanceStartFootageChange}
              validateAdvanceEndFootageChange={validateAdvanceEndFootageChange}
              selectedMiningCutSequencePass={selectedMiningCutSequencePass}
              validateMiningCutChange={validateMiningCutChange}
              onAdvanceFootageChanged={onAdvanceFootageChanged}
              isEvenPass={isEvenPass}
              advanceStartFootage={advanceStartFootage}
              advanceEndFootage={advanceEndFootage}
              editMode={!!productionToEdit}
              validationIndex={validationIndex}
              setErrors={setErrors}
              numberOfBuckets={numberOfBuckets}
              selectedStamler={selectedStamler}
              selectedScoop={selectedScoop}
              validateStamlerChange={validateStamlerChange}
              validateScoopChange={validateScoopChange}
              validateNumberOfBucketsChange={validateNumberOfBucketsChange}
              showAllanRehabFields={showAllanRehabFields}
            />
          </Grid>
          {!loading &&
            productionToEdit &&
            advancesToEdit?.map((advance, index) => (
              <AdvanceSubPanel
                key={advance.id}
                advance={advance}
                index={index}
                advanceFootageErrors={advanceFootageErrors}
                setAdvanceFootageErrors={setAdvanceFootageErrors}
                advancesToEdit={advancesToEdit}
                isChevron={isChevron}
                setAdvancesToEdit={setAdvancesToEdit}
                setDeleteAdvanceModalOpen={setDeleteAdvanceModalOpen}
                setAdvanceToDelete={setAdvanceToDelete}
                isEvenPass={isEvenPass}
                showAllanRehabFields={showAllanRehabFields}
              />
            ))}
          {productionToEdit && (
            <Grid item xs={8} className={classes.addAdvanceButton}>
              <Button
                className={classes.addAnotherAdvanceButton}
                variant="outlined"
                color="primary"
                data-cy="add-another-advance"
                data-testid="add-another-advance"
                noMinHeight
                onClick={onAddAnotherAdvance}
                id="add-another-advance"
                startAdornment={<Icons.PlusFeather />}
              >
                {i18n.t('Add another advance')}
              </Button>
            </Grid>
          )}
        </DialogContent>
      </GenericSidePanel>
      <DiscardDraftModal
        maxHeight={275}
        open={deleteAdvanceModalOpen}
        onCancel={() => {
          setDeleteAdvanceModalOpen(false);
          setAdvanceToDelete(undefined);
        }}
        onDiscard={confirmDeleteAdvance}
        disableEnforceFocus
        titleText={i18n.t('Delete advance')}
        continueEditingButtonText={i18n.t('Cancel')}
        discardDraftButtonText={i18n.t('Delete')}
        cancelText={i18n.t('Deleting will lose any information related to this advance')}
      />
    </>
  );
};

export default observer(ModifyAdvanceSidePanel);
