const fileToBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
    reader.addEventListener('error', error => {
      console.log('🚀 ~ file: fileConverter.ts ~ line 21 ~ error', error);
      reject(error);
    });
  });
};

export { fileToBase64 };
