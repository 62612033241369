import type { Instance } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { v4 } from 'uuid';

import { isJestOrStorybook } from '../test-helpers/isJestOrStorybook';
import { ApiStatus } from '../utilities/apiStatus';
import { SHIFT_SESSION_KEY, USER_SITE_ID } from '../utilities/constants';
import { AppVersion } from './AppVersion';
import { Employees } from './Employees';
import Equipment, { SELECTED_BORER_ID_KEY } from './Equipment';
import { GroundControls } from './GroundControls';
import { Panels } from './Panels';
import { getCurrentShift, ShiftPicker } from './ShiftPicker';
import { User } from './User';

const currentAppVersion = import.meta.env.VITE_APP_VERSION || '';

export const RootModel = types.model({
  employees: Employees,
  panels: Panels,
  user: User,
  groundControls: GroundControls,
  shiftPicker: ShiftPicker,
  appVersion: AppVersion,
  equipment: Equipment,
});

const shiftDefaultsString = sessionStorage.getItem(SHIFT_SESSION_KEY);

const currentDefaultShift = getCurrentShift();
const parsedShiftDefaults =
  shiftDefaultsString !== null ? JSON.parse(shiftDefaultsString) : { ...currentDefaultShift };

const selectedBorerDetails = localStorage.getItem(SELECTED_BORER_ID_KEY);
const parsedBorerDetails = selectedBorerDetails !== null ? JSON.parse(selectedBorerDetails) : {};

const defferedAt = localStorage.getItem('updateDeferralTime');
const parsedDefferedAt = defferedAt !== null ? JSON.parse(defferedAt) : null;

export const rootStore = RootModel.create({
  hazards: {
    HazardTypesStatus: ApiStatus.LOADING,
    HazardConditionTypes: [],
    HazardConditionTypesMap: {},
  },
  employees: {
    EmployeesStatus: ApiStatus.LOADING,
    EmployeesMap: {},
    Employees: [],
  },
  panels: {
    panelsStatus: ApiStatus.LOADING,
    panelsMap: {},
    panels: [],
  },
  user: {
    siteId: USER_SITE_ID,
  },
  groundControls: {
    GroundControlOptionsFetchStatus: ApiStatus.NOT_CALLED,
  },
  shiftPicker: {
    ...parsedShiftDefaults,
  },
  appVersion: {
    currentAppVersion,
    hasNewUpdate: false,
    showUpdateModal: false,
    deferredAt: parsedDefferedAt,
  },
  equipment: {
    BorersFetchStatus: ApiStatus.NOT_CALLED,
    selectedBorerId: isJestOrStorybook() ? v4() : parsedBorerDetails?.id,
    BorersMap: parsedBorerDetails?.id ? { [parsedBorerDetails.id]: parsedBorerDetails } : {},
  },
});

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const { Provider } = RootStoreContext;
export const MSTProvider = RootStoreContext.Provider;

export const useMst = () => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
};
