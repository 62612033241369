import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useOnlineStatus } from '../../utilities';
import { RoomDocument } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { DocumentUploadCollection, DocumentUploadDocument, EnrichedDocument } from './queryBuilder';

export interface EnrichedDocumentByDocumentType {
  [key: string]: EnrichedDocument;
}

export const useGlobalDocs = (
  rooms?: (RoomDocument | undefined | null)[],
  miningMethods?: string[],
) => {
  const [globalDocs, setGlobalDocs] = useState<DocumentUploadDocument[]>([]);
  const [globalDocsLoaded, setGlobalDocsLoaded] = useState(false);
  const documentUploadCollection: DocumentUploadCollection = useRxCollection(
    RxdbCollectionName.DOCUMENT_UPLOAD,
  );

  const isOnline = useOnlineStatus();

  const augmentAndSortDocuments = async (documents: DocumentUploadDocument[]) => {
    await Promise.all(
      documents.map(async document => {
        const documentType = await document.populate('borerDocumentTypeId');
        // Documents with reference documentType won't have description / display order
        if ((!document.description || !document.displayOrder) && documentType) {
          // If stale or missing document decsription / displayOrder - add/update it
          if (document.documentTypeDescription !== documentType.description)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              documentTypeDescription: documentType.description,
            }));
          if (document.displayOrder !== documentType.displayOrder)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              displayOrder: documentType.displayOrder,
            }));
        }
        return document;
      }),
    );

    return documents
      .filter(doc => doc !== null)
      .sort(
        (a, b) =>
          (a?.displayOrder === undefined ? 1 : 0) - (b?.displayOrder === undefined ? 1 : 0) ||
          +(a?.displayOrder > b?.displayOrder) ||
          -(a?.displayOrder < b?.displayOrder),
      );
  };

  useEffect(() => {
    let sub: Subscription | undefined;
    let timeout: NodeJS.Timeout | undefined;

    const getDocuments = async () => {
      if (!documentUploadCollection) {
        setGlobalDocs([]);
        setGlobalDocsLoaded(true);
        return;
      }

      let miningMethodsToQuery: string[] = [];

      if (rooms?.length) {
        const roomMiningMethods = await Promise.all(
          rooms.map(async room => {
            if (room?.miningPattern) {
              const miningPattern = await room.populate('miningPattern');
              return miningPattern?.miningMethod;
            } else if (room?.panel) {
              const panel = await room.populate('panel');
              return panel.miningMethod;
            }
            return '';
          }),
        );

        miningMethodsToQuery = [...roomMiningMethods];
      }

      if (miningMethods) {
        miningMethodsToQuery = [...miningMethodsToQuery, ...miningMethods];
      }

      const filteredMiningMethodsToQuery = miningMethodsToQuery.filter(
        (mMethod, index) => !!mMethod && miningMethodsToQuery.indexOf(mMethod) === index,
      );

      if (!filteredMiningMethodsToQuery.length) {
        setGlobalDocs([]);
        setGlobalDocsLoaded(true);
        return;
      }

      try {
        const querySelector = {
          selector: {
            roomId: null,
            miningMethod: {
              $in: filteredMiningMethodsToQuery,
            },
          },
        };

        sub = documentUploadCollection?.find(querySelector).$.subscribe(async docs => {
          const augmentedDocs = await augmentAndSortDocuments(docs);
          if (augmentedDocs.length) {
            setGlobalDocs(augmentedDocs);
          } else {
            setGlobalDocs([]);
          }
          setGlobalDocsLoaded(true);
        });
      } catch (error) {
        setGlobalDocs([]);
        console.log(error);
      }
    };
    getDocuments();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
      if (timeout) clearTimeout(timeout);
    };
  }, [documentUploadCollection, rooms, miningMethods, isOnline]);

  return {
    globalDocs,
    globalDocsLoaded,
  };
};

export default useGlobalDocs;
