import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    checkboxes: {
      paddingTop: '25px',
    },
    container: {
      width: '100%',
    },
    checkboxLabel: {
      width: '100%',
    },
    photoCardAdd: {
      width: '100%',
      height: '100%',
      border: `1px dashed ${CustomPalette.elevation.dp8}`,
      borderRadius: '4px',
      backgroundColor: CustomPalette.elevation.dp1,
    },
    photoCardAddText: {
      marginTop: '10px !important',
    },
    photoDiv: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
