import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface GroundHazardAttachment extends BaseEntity {
  readonly fileName: string;
  readonly groundHazardId: string;
  readonly createdBy: string | null;
  readonly createdOn?: string;
}

export type GroundHazardAttachmentDocument = RxDocument<GroundHazardAttachment>;
export type GroundHazardAttachmentCollection = RxCollection<GroundHazardAttachment> | null;

export const groundHazardAttachmentSchema: RxJsonSchema<GroundHazardAttachment> = {
  type: 'object',
  version: 2,
  description: 'describes a GroundHazardAttachment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    groundHazardId: { type: 'string' },
    fileName: { type: 'string' },
    createdBy: { type: 'string' },
    createdOn: { type: 'string' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
  indexes: ['updatedAt'],
};

const groundHazardAttachmentFeedQuery = print(gql`
  query groundHazardAttachmentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    groundHazardAttachmentFeed(
      borerEquipmentId: $borerEquipmentId
      limit: $limit
      lastUpdateAt: $lastUpdateAt
    ) {
      id
      fileName
      updatedAt
      createdOn
      isDeleted
      version
    }
  }
`);

export const groundHazardAttachmentsPullQueryBuilder = generatePullQuery(
  groundHazardAttachmentFeedQuery,
  PullQueryContext.Borer,
  defaultDoc90Day,
);

const createGroundHazardAttachmentMutation = print(gql`
  mutation CreateGroundHazardAttachment(
    $groundHazardAttachment: CreateGroundHazardAttachmentInput
  ) {
    createGroundHazardAttachment(input: $groundHazardAttachment)
  }
`);

type CreateGroundHazardAttachmentInput = {
  id: string;
  fileName: string;
  groundHazardId: string;
  uploadedById: string;
  createdOn: string | null;
  updateLastViewed: boolean;
};

const mapGroundHazardAttachmentDocToInput = (
  doc: GroundHazardAttachmentDocument,
): CreateGroundHazardAttachmentInput => {
  return {
    id: doc.id,
    fileName: doc.fileName,
    groundHazardId: doc.groundHazardId,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    uploadedById: doc.createdBy,
    updateLastViewed: false,
  };
};

export const groundHazardAttachmentPushQueryBuilder = generatePushQuery(
  createGroundHazardAttachmentMutation,
  (doc: GroundHazardAttachmentDocument) => ({
    groundHazardAttachment: mapGroundHazardAttachmentDocToInput(doc),
  }),
);

export const groundHazardAttachmentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardAttachmentDocument) => ({
    ...oldDoc,
  }),
  2: (oldDoc: GroundHazardAttachmentDocument) => {
    // createdOn is added, but we'll allow it to be undefined
    // while the data synchronizes from the BE.
    return oldDoc;
  },
};
