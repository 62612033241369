import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      width: '30%',
    },
    marginRight: {
      marginRight: '8px',
    },
  }),
);
