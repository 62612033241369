import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

import { useMst } from '../../mobx-models/Root';
import { ShiftType } from '../enums';

export interface SessionShiftDetails {
  dayShiftStartHour: number;
  dayShiftStartMinute: number;
  dayShiftDurationMinutes: number;
  nightShiftStartHour: number;
  nightShiftStartMinute: number;
  nightShiftDurationMinutes: number;
  shiftType: ShiftType;
  shiftDate: string;
  shiftId: string;
  shiftStartDate: Dayjs;
  shiftEndDate: Dayjs;
}

export const useShiftHours = () => {
  const { shiftPicker } = useMst();

  const sessionShiftDetails: SessionShiftDetails = useMemo(() => {
    return {
      dayShiftStartHour: shiftPicker.dayShiftStartHour,
      dayShiftStartMinute: shiftPicker.dayShiftStartMinute,
      dayShiftDurationMinutes: 12 * 60,
      nightShiftStartHour: shiftPicker.nightShiftStartHour,
      nightShiftStartMinute: shiftPicker.nightShiftStartMinute,
      nightShiftDurationMinutes: 12 * 60,
      shiftType: shiftPicker.Type,
      shiftDate: shiftPicker.Date,
      shiftStartDate: dayjs.unix(shiftPicker.shiftStartDateUnix),
      shiftEndDate: dayjs.unix(shiftPicker.shiftEndDateUnix),
      shiftId: shiftPicker.currentShiftId,
    };
  }, [
    shiftPicker.dayShiftStartHour,
    shiftPicker.dayShiftStartMinute,
    shiftPicker.nightShiftStartHour,
    shiftPicker.nightShiftStartMinute,
    shiftPicker.Type,
    shiftPicker.Date,
    shiftPicker.shiftStartDateUnix,
    shiftPicker.shiftEndDateUnix,
    shiftPicker.currentShiftId,
  ]);

  return sessionShiftDetails;
};
