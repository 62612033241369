import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Sequence extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly miningMethod: string;
  readonly miningStyle: string;
}
export type SequenceCollection = RxCollection<Sequence> | null;
export type SequenceDocument = RxDocument<Sequence>;

export const sequencesSchema: RxJsonSchema<Sequence> = {
  type: 'object',
  version: 0,
  description: 'describes a Sequence object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    miningMethod: { type: 'string' },
    miningStyle: { type: 'string' },
    version: { type: 'number' },
  },
};

const sequenceFeedQuery = print(gql`
  query sequenceFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    sequenceFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      description
      id
      isActive
      isDeleted
      miningMethod
      miningStyle
      updatedAt
      version
    }
  }
`);

export const sequencesPullQueryBuilder = generatePullQuery(
  sequenceFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export default {};
