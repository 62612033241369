import { useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';

import { sortAlphabeticallyByMultipleProperties } from '@/utilities/sortHelper';

import { useBorerActivity } from '../BorerActivity/useBorerActivity';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { BorerActivityType } from './queryBuilder';

export interface BorerSortedActivityTypes extends BorerActivityType {
  categoryName: string;
  id: string;
}

export const useBorerActivityType = () => {
  const activityTypeQueryConstructor = collection => collection.find();

  const [sortedActivityTypes, setSortedActivityTypes] = useState<BorerSortedActivityTypes[]>([]);

  const { result: activityTypesForShift, isFetching: activityTypesFetching } =
    useRxData<BorerActivityType>(
      RxdbCollectionName.BORER_ACTIVITY_TYPE,
      activityTypeQueryConstructor,
    );

  const { augmentedActivitiesForShift } = useBorerActivity();

  useEffect(() => {
    const getAugmentedActivityTypes = async () => {
      const types = await Promise.all(
        activityTypesForShift
          .filter(
            item =>
              item.delayActivityTypeCategory &&
              (item.isActive ||
                augmentedActivitiesForShift.find(v => v.activityTypeId === item.id)),
          )
          .map(async item => {
            const cat = await item.populate('delayActivityTypeCategory');
            return {
              ...item,
              id: item.id,
              description: item.description,
              name: item.name,
              categoryName: cat?.description || 'No Category',
            };
          }),
      );
      setSortedActivityTypes(
        sortAlphabeticallyByMultipleProperties(types, ['categoryName', 'name']),
      );
    };

    getAugmentedActivityTypes();
  }, [activityTypesForShift, augmentedActivitiesForShift]);

  return {
    sortedActivityTypes,
    activityTypesFetching,
  };
};

export default useBorerActivityType;
