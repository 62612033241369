import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface EquipmentDeficiency extends BaseEntity {
  readonly description: string;
  readonly equipmentId: string;
  readonly isWorkOrderRequired: boolean;
  readonly borerEquipmentId: string;
  readonly siteId: string;
  readonly fullyAddressedDate: string | null;
  readonly approvalDate: string | null;
  readonly denialDate: string | null;
  readonly createdOn: string | null;
}
export type EquipmentDeficiencyCollection = RxCollection<EquipmentDeficiency> | null;
export type EquipmentDeficiencyDocument = RxDocument<EquipmentDeficiency>;

export const equipmentDeficiencySchema: RxJsonSchema<EquipmentDeficiency> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentDeficiency object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    equipmentId: {
      ref: RxdbCollectionName.EQUIPMENT,
      type: 'string',
    },
    isWorkOrderRequired: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    borerEquipmentId: { type: 'string' },
    siteId: { type: 'string' },
    fullyAddressedDate: { type: ['string', 'null'] },
    approvalDate: { type: ['string', 'null'] },
    denialDate: { type: ['string', 'null'] },
    createdOn: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

const equipmentDeficiencyFeedQuery = print(gql`
  query equipmentDeficiencyFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
    equipmentDeficiencyFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      id
      description
      isWorkOrderRequired
      isDeleted
      updatedAt
      version
      borerEquipmentId
      siteId
      fullyAddressedDate
      approvalDate
      denialDate
      createdOn
      equipmentId
    }
  }
`);

export const equipmentDeficiencyPullQueryBuilder = generatePullQuery(
  equipmentDeficiencyFeedQuery,
  PullQueryContext.Borer,
);

const setEquipmentDeficiencyMutation = print(gql`
  mutation setEquipmentDeficiency(
    $id: ID!
    $description: String!
    $borerEquipmentId: ID!
    $isWorkOrderRequired: Boolean!
    $siteId: ID!
    $fullyAddressedDate: AWSDateTime
    $createdOn: AWSDateTime
    $version: Int!
    $isDeleted: Boolean!
    $equipmentId: ID!
  ) {
    setEquipmentDeficiency(
      input: {
        id: $id
        description: $description
        borerEquipmentId: $borerEquipmentId
        isWorkOrderRequired: $isWorkOrderRequired
        siteId: $siteId
        fullyAddressedDate: $fullyAddressedDate
        createdOn: $createdOn
        version: $version
        isDeleted: $isDeleted
        equipmentId: $equipmentId
      }
    ) {
      id
      isWorkOrderRequired
      siteId
    }
  }
`);

type SetEquipmentDeficiencyInput = {
  id: string;
  siteId: string;
  borerEquipmentId: string;
  equipmentId: string;
  description: string;
  isWorkOrderRequired: boolean;
  fullyAddressedDate: string | null;
  createdOn: string | null;
  isDeleted: boolean;
  version: number;
};

const mapEquipmentDeficiencyDocToInput = (
  doc: EquipmentDeficiencyDocument,
): SetEquipmentDeficiencyInput => {
  return {
    id: doc.id,
    description: doc.description,
    borerEquipmentId: doc.borerEquipmentId,
    isWorkOrderRequired: doc.isWorkOrderRequired,
    siteId: doc.siteId,
    fullyAddressedDate: doc.fullyAddressedDate,
    createdOn: doc.createdOn,
    version: doc.version,
    isDeleted: doc.isDeleted,
    equipmentId: doc.equipmentId,
  };
};

export const equipmentDeficiencyPushQueryBuilder = generatePushQuery(
  setEquipmentDeficiencyMutation,
  (doc: EquipmentDeficiencyDocument) => mapEquipmentDeficiencyDocToInput(doc),
);
