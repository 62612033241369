import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { getBorerReshimAndRebuildDates, getBorerShiftInfo } from '../../graphql/borerShiftInfo';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerShiftInfo extends BaseEntity {
  borerShiftId: string;
  belt: number | null;
  breaks: string | null;
  cable: number | null;
  serviceStatusId: string | null;
  rotorBits: number;
  trimBits: number;
  cornerBits: number;
  meterHours: number | null;
  faceSalt: number | null;
  gprIsWorking: boolean | null;
  gprIsWorkingChangedOn: string | null;
  lastPreventativeMaintenanceOn: string | null;
  lastServicedAtPanelId: string | null;
  lastServicedOn: string | null;
  lastTrimChainChangedOn: string | null;
  leachAnomaly: string | null;
  oilLevel: number | null;
  onMound: boolean | null;
  oreGrade: number | null;
  parkedForMaintenance: boolean | null;
  phoneNumber: string | null;
  shearPins: number | null;
  topChainShearPinsReplaced: number | null;
  bottomChainShearPinsReplaced: number | null;
  oreLineHeight: number | null;
  breakthroughFaceFootage: string | null;
  cableSlackEnd: string | null;
  lastRebuildOn: string | null;
  lastReshimOn: string | null;
  lastBottomTrimChainChangedOn: string | null;
}
export type BorerShiftInfoCollection = RxCollection<BorerShiftInfo> | null;
export type BorerShiftInfoDocument = RxDocument<BorerShiftInfo>;

export const borerShiftInfoSchema: RxJsonSchema<BorerShiftInfo> = {
  type: 'object',
  version: 9,
  description: 'describes a borerShiftInfo object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerShiftId: { type: 'string', ref: RxdbCollectionName.BORER_SHIFT },
    belt: { type: ['number', 'null'] },
    breaks: { type: ['string', 'null'] },
    cable: { type: ['number', 'null'] },
    serviceStatusId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.SERVICE_STATUS,
    },
    rotorBits: { type: ['number'] },
    trimBits: { type: ['number'] },
    cornerBits: { type: ['number'] },
    meterHours: { type: ['number', 'null'] },
    faceSalt: { type: ['number', 'null'] },
    gprIsWorking: { type: ['boolean', 'null'] },
    gprIsWorkingChangedOn: { type: ['string', 'null'] },
    lastPreventativeMaintenanceOn: { type: ['string', 'null'] },
    lastServicedAtPanelId: { type: ['string', 'null'] },
    lastServicedOn: { type: ['string', 'null'] },
    lastTrimChainChangedOn: { type: ['string', 'null'] },
    leachAnomaly: { type: ['boolean', 'null'] },
    oilLevel: { type: ['number', 'null'] },
    onMound: { type: ['boolean', 'null'] },
    oreGrade: { type: ['number', 'null'] },
    parkedForMaintenance: { type: ['boolean', 'null'] },
    phoneNumber: { type: ['string', 'null'] },
    shearPins: { type: ['number', 'null'] },
    topChainShearPinsReplaced: { type: ['number', 'null'] },
    bottomChainShearPinsReplaced: { type: ['number', 'null'] },
    oreLineHeight: { type: ['number', 'null'] },
    breakthroughFaceFootage: { type: ['string', 'null'] },
    cableSlackEnd: { type: ['string', 'null'] },
    lastRebuildOn: { type: ['string', 'null'] },
    lastReshimOn: { type: ['string', 'null'] },
    lastBottomTrimChainChangedOn: { type: ['string', 'null'] },
  },
};

export const borerShiftInfoMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftInfoDocument) => {
    return {
      ...oldDoc,
      rotorBits: null,
      trimBits: null,
      cornerBits: null,
    };
  },
  2: (oldDoc: BorerShiftInfoDocument) => oldDoc,
  3: (oldDoc: BorerShiftInfoDocument) => oldDoc,
  4: (oldDoc: BorerShiftInfoDocument) => {
    return {
      ...oldDoc,
      meterHours: null,
    };
  },
  5: (oldDoc: BorerShiftInfoDocument) => {
    const { borerShiftId } = oldDoc;

    // For each document we call the get and migrate with the new require borer shift info values
    return getBorerShiftInfo(borerShiftId).then(response => {
      const {
        faceSalt,
        gprIsWorking,
        gprIsWorkingChangedOn,
        lastPreventativeMaintenanceOn,
        lastServicedOn,
        lastTrimChainChangedOn,
        leachAnomaly,
        oilLevel,
        onMound,
        oreGrade,
        parkedForMaintenance,
        phoneNumber,
        shearPins,
        lastServicedAtPanel,
      } = response.data.getBorerShiftInfo;

      return {
        ...oldDoc,
        faceSalt,
        gprIsWorking,
        gprIsWorkingChangedOn,
        lastPreventativeMaintenanceOn,
        lastServicedOn,
        lastTrimChainChangedOn,
        leachAnomaly,
        oilLevel,
        onMound,
        oreGrade,
        parkedForMaintenance,
        phoneNumber,
        shearPins,
        lastServicedAtPanelId: lastServicedAtPanel?.id || null,
      };
    });
  },
  6: (oldDoc: BorerShiftInfoDocument) => {
    return {
      ...oldDoc,
      topChainShearPinsReplaced: null,
      bottomChainShearPinsReplaced: null,
    };
  },
  7: (oldDoc: BorerShiftInfoDocument) => ({
    ...oldDoc,
    oreLineHeight: 0,
    breakthroughFaceFootage: null,
    cableSlackEnd: null,
  }),
  8: async (oldDoc: BorerShiftInfoDocument) => {
    const borerShiftId = oldDoc.borerShiftId;
    if (!borerShiftId) return oldDoc;

    // For each document we call the get and migrate with the new require borer shift info values

    try {
      const borerShiftInfo = await getBorerReshimAndRebuildDates(borerShiftId);
      const { lastRebuildOn, lastReshimOn } = borerShiftInfo.data.getBorerShiftInfo;

      return {
        ...oldDoc,
        lastRebuildOn,
        lastReshimOn,
      };
    } catch (e) {
      console.log('🚀 ~ file: borerShiftInfo migration v8 error: ts:173 ~ e:', e);
      return oldDoc;
    }
  },
  9: (oldDoc: BorerShiftInfoDocument) => ({
    ...oldDoc,
    lastBottomTrimChainChangedOn: null,
  }),
};

const borerShiftInfoFeedQuery = print(gql`
  query borerShiftInfoFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftInfoFeed(
      borerEquipmentId: $borerEquipmentId
      lastUpdateAt: $lastUpdateAt
      limit: $limit
    ) {
      belt
      borerShiftId
      breaks
      cable
      id
      isDeleted
      serviceStatusId
      version
      updatedAt
      rotorBits
      trimBits
      cornerBits
      meterHours
      faceSalt
      gprIsWorking
      gprIsWorkingChangedOn
      lastPreventativeMaintenanceOn
      lastServicedAtPanelId
      lastServicedOn
      lastTrimChainChangedOn
      leachAnomaly
      oilLevel
      onMound
      oreGrade
      parkedForMaintenance
      phoneNumber
      shearPins
      topChainShearPinsReplaced
      bottomChainShearPinsReplaced
      breakthroughFaceFootage
      oreLineHeight
      cableSlackEnd
      lastRebuildOn
      lastReshimOn
      lastBottomTrimChainChangedOn
    }
  }
`);

export const borerShiftInfoPullQueryBuilder = generatePullQuery(
  borerShiftInfoFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftInfoMutation = print(gql`
  mutation setBorerShiftInfo(
    $id: ID!
    $borerShiftId: ID!
    $belt: Int
    $breaks: BorerInfoBreaks!
    $cable: Int
    $cornerBits: Int!
    $rotorBits: Int!
    $trimBits: Int!
    $serviceStatusId: ID
    $meterHours: Float!
    $gprIsWorking: Boolean
    $oilLevel: Float
    $parkedForMaintenance: Boolean
    $lastServicedOn: AWSDateTime
    $lastServicedAtPanelId: ID
    $lastPreventativeMaintenanceOn: AWSDateTime
    $onMound: Boolean
    $leachAnomaly: Boolean
    $phoneNumber: String
    $faceSalt: Float
    $oreGrade: Float
    $shearPins: Int
    $lastTrimChainChangedOn: AWSDateTime
    $bottomChainShearPinsReplaced: Int
    $topChainShearPinsReplaced: Int
    $cableSlackEnd: String
    $oreLineHeight: Float
    $breakthroughFaceFootage: String
    $lastRebuildOn: AWSDateTime
    $lastReshimOn: AWSDateTime
    $lastBottomTrimChainChangedOn: AWSDateTime
  ) {
    setBorerShiftInfo(
      input: {
        id: $id
        borerShiftId: $borerShiftId
        belt: $belt
        breaks: $breaks
        cable: $cable
        cornerBits: $cornerBits
        rotorBits: $rotorBits
        trimBits: $trimBits
        serviceStatusId: $serviceStatusId
        meterHours: $meterHours
        gprIsWorking: $gprIsWorking
        oilLevel: $oilLevel
        parkedForMaintenance: $parkedForMaintenance
        lastServicedOn: $lastServicedOn
        lastServicedAtPanelId: $lastServicedAtPanelId
        lastPreventativeMaintenanceOn: $lastPreventativeMaintenanceOn
        onMound: $onMound
        leachAnomaly: $leachAnomaly
        phoneNumber: $phoneNumber
        faceSalt: $faceSalt
        oreGrade: $oreGrade
        shearPins: $shearPins
        lastTrimChainChangedOn: $lastTrimChainChangedOn
        bottomChainShearPinsReplaced: $bottomChainShearPinsReplaced
        topChainShearPinsReplaced: $topChainShearPinsReplaced
        cableSlackEnd: $cableSlackEnd
        oreLineHeight: $oreLineHeight
        breakthroughFaceFootage: $breakthroughFaceFootage
        lastRebuildOn: $lastRebuildOn
        lastReshimOn: $lastReshimOn
        lastBottomTrimChainChangedOn: $lastBottomTrimChainChangedOn
      }
    )
  }
`);

export const borerShiftInfoPushQueryBuilder = generatePushQuery(
  setBorerShiftInfoMutation,
  (doc: BorerShiftInfoDocument) => ({
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    belt: doc.belt,
    breaks: doc.breaks,
    cable: doc.cable,
    cornerBits: doc.cornerBits,
    rotorBits: doc.rotorBits,
    trimBits: doc.trimBits,
    serviceStatusId: doc.serviceStatusId,
    meterHours: doc.meterHours || 0,
    gprIsWorking: doc.gprIsWorking,
    oilLevel: doc.oilLevel,
    parkedForMaintenance: doc.parkedForMaintenance,
    lastServicedOn: doc.lastServicedOn,
    lastServicedAtPanelId: doc.lastServicedAtPanelId,
    lastPreventativeMaintenanceOn: doc.lastPreventativeMaintenanceOn,
    onMound: doc.onMound,
    leachAnomaly: doc.leachAnomaly,
    phoneNumber: doc.phoneNumber,
    faceSalt: doc.faceSalt,
    oreGrade: doc.oreGrade,
    shearPins: doc.shearPins,
    lastTrimChainChangedOn: doc.lastTrimChainChangedOn,
    bottomChainShearPinsReplaced: doc.bottomChainShearPinsReplaced,
    topChainShearPinsReplaced: doc.topChainShearPinsReplaced,
    cableSlackEnd: doc.cableSlackEnd,
    oreLineHeight: doc.oreLineHeight,
    breakthroughFaceFootage: doc.breakthroughFaceFootage,
    lastRebuildOn: doc.lastRebuildOn,
    lastReshimOn: doc.lastReshimOn,
    lastBottomTrimChainChangedOn: doc.lastBottomTrimChainChangedOn,
  }),
);
