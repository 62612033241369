import dayjs from 'dayjs';

export const currentDateIsWithin = (startDate: string, endDate: string | null): boolean => {
  const currentDate = dayjs();
  const startDateValue = dayjs(startDate);

  if (startDateValue.isAfter(currentDate)) return false;

  if (endDate === null) return true;

  const endDateValue = dayjs(endDate);
  if (endDateValue.isBefore(currentDate)) return false;

  return true;
};
