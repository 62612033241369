import { useEffect, useState } from 'react';

export const useWindowVisibility = (): boolean => {
  const [windowVisible, setWindowVisible] = useState(false);

  const windowVisibilityEventHandler = () => {
    if (document.visibilityState === 'visible') {
      setWindowVisible(true);
    } else {
      setWindowVisible(false);
    }
  };

  const pageshowEventHandler = () => {
    setWindowVisible(true);
  };

  useEffect(() => {
    windowVisibilityEventHandler();
    window.addEventListener('visibilitychange', windowVisibilityEventHandler, false);
    window.addEventListener('pageshow', pageshowEventHandler, false);

    return () => {
      window.removeEventListener('visibilitychange', windowVisibilityEventHandler, false);
      window.removeEventListener('pageshow', pageshowEventHandler, false);
    };
  }, []);

  return windowVisible;
};

export default useWindowVisibility;
