import { useCallback, useMemo } from 'react';
import { RxDocument } from 'rxdb';
import { useRxData } from 'rxdb-hooks';

import { rootStore } from '@/mobx-models/Root';
import {
  BORER_DESCRIPTION,
  HAULAGE_EQUIPMENT_IDS,
  STAMLER_EQUIPMENT_IDS,
} from '@/utilities/constants';
import { generateAssignmentString } from '@/utilities/generateAssignmentString';

import { EquipmentDocument } from '../Equipment/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import { EquipmentType } from './queryBuilder';

export interface EquipmentWithType {
  equipment: EquipmentDocument;
  type?: EquipmentType;
  name: string;
  typeName: string;
  assignmentString?: string;
  isBorer?: boolean;
}

export enum EquipmentTypeList {
  Stamler = 'Stamler',
  Haulage = 'Haulage',
}

export const useEquipmentListByTypeName = (
  equipmentType: keyof typeof EquipmentTypeList,
): { equipmentWithAssignmentStatus: EquipmentWithType[] } => {
  const filteredEquipmentTypeQuery = useCallback(
    collection => {
      let equipmentTypeIdArray: string[] = [];
      if (equipmentType === EquipmentTypeList.Stamler) {
        equipmentTypeIdArray = STAMLER_EQUIPMENT_IDS;
      } else if (equipmentType === EquipmentTypeList.Haulage) {
        equipmentTypeIdArray = HAULAGE_EQUIPMENT_IDS;
      }
      return collection.find({
        selector: {
          id: {
            $in: equipmentTypeIdArray,
          },
        },
      });
    },
    [equipmentType],
  );

  const { result: filteredEquipmentTypes } = useRxData<RxDocument<EquipmentType>>(
    RxdbCollectionName.EQUIPMENT_TYPE,
    filteredEquipmentTypeQuery,
  );

  const equipmentQuery = useCallback(
    collection =>
      collection.find({
        selector: {
          equipmentTypeId: {
            $in: filteredEquipmentTypes?.map(eType => eType.id),
          },
        },
      }),
    [filteredEquipmentTypes],
  );

  const { result: filteredEquipmentList } = useRxData<EquipmentDocument>(
    RxdbCollectionName.EQUIPMENT,
    equipmentQuery,
  );

  const equipmentWithAssignmentStatus: EquipmentWithType[] = useMemo(
    () =>
      filteredEquipmentList
        ?.map(equipment => {
          const type = filteredEquipmentTypes.find(
            eqType => eqType.id === equipment.equipmentTypeId,
          );
          return {
            equipment,
            type,
            typeName: type?.description || '',
            name: equipment.shortName || equipment.longName,
            longName: equipment.longName,
            isAssigned: equipment.assignedBorerEquipmentId === rootStore.equipment.selectedBorerId,
            id: equipment.id,
            assignmentString: generateAssignmentString(
              equipment.assignedBorerEquipmentId === rootStore.equipment.selectedBorerId,
              false,
              type?.description,
            ),
            isBorer: type?.description === BORER_DESCRIPTION,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [filteredEquipmentList, filteredEquipmentTypes],
  );

  return { equipmentWithAssignmentStatus };
};

export const queryEquipmentWithTypeDetails = async (
  id?: string | null,
): Promise<EquipmentWithType | undefined> => {
  const db = RxdbManager.instance?.db;
  const equipmentCollection = db?.collections[RxdbCollectionName.EQUIPMENT];
  if (!equipmentCollection || !id) return undefined;

  const equipment = await equipmentCollection
    ?.findOne({
      selector: { id },
    })
    .exec();

  const type = await equipment?.populate('equipmentTypeId');
  if (!equipment || !type) return undefined;

  return {
    equipment,
    type,
    typeName: type?.description || '',
    name: equipment.shortName || equipment.longName,
    assignmentString: generateAssignmentString(
      equipment.assignedBorerEquipmentId === rootStore.equipment.selectedBorerId,
      false,
      type?.description,
    ),
    isBorer: type?.description === 'Borer',
  };
};
