import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useFileStorage } from '../../utilities';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import {
  EquipmentDeficiencyAttachment,
  EquipmentDeficiencyAttachmentCollection,
} from './queryBuilder';

const DEBUG = false;

export const useEquipmentDeficiencyAttachments = () => {
  const { uploadImage } = useFileStorage();

  const equipmentDeficiencyAttachmentCollection: EquipmentDeficiencyAttachmentCollection =
    useRxCollection(RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT);

  const [deficiencyAttachmentsInitialized, setCollectionsInitialized] = useState(false);

  useEffect(() => {
    if (equipmentDeficiencyAttachmentCollection) setCollectionsInitialized(true);
  }, [equipmentDeficiencyAttachmentCollection]);

  // Create the equipment attachment doc
  const createEquipmentDeficiencyAttachment = (
    equipmentDeficiencyId: string,
    fileName: string,
    uploadedById: string,
  ) => {
    const doc: EquipmentDeficiencyAttachment = {
      ...generateBaseEntityWithCreatedOn(),
      equipmentDeficiencyId,
      fileName,
      uploadedById,
    };

    return equipmentDeficiencyAttachmentCollection?.insert(doc);
  };

  // Create Batch of Attachments
  const createEquipmentDeficiencyAttachments = async (
    equipmentDeficiencyId: string,
    files: File[],
    uploadedById: string,
  ) => {
    try {
      const fileResults = await Promise.all(
        files.map(file => uploadImage(equipmentDeficiencyId, file)),
      );

      const attachments = await Promise.all(
        fileResults.map(fileResult =>
          createEquipmentDeficiencyAttachment(equipmentDeficiencyId, fileResult.key, uploadedById),
        ),
      );

      return attachments.map(attachment => attachment.id);
    } catch (error) {
      if (DEBUG)
        console.error(
          '🚀 ~ file: useEquipmentDeficiencyAttachment.ts ~ line 71 ~ useEquipmentDeficiencyAttachments ~ error',
          error,
        );

      throw error;
    }
  };

  /**
   * Subscribe to newly inserted attachments
   *
   * @param {(item: RxChangeEventInsert<EquipmentDeficiencyAttachment>) => void} next
   * @return {*}
   */
  const subscribeToEquipmentDeficiencyAttachments = (
    handleAttachments: (attachments: EquipmentDeficiencyAttachment[]) => void,
  ) => {
    if (!equipmentDeficiencyAttachmentCollection) return;

    const query = equipmentDeficiencyAttachmentCollection.find();

    query.$.subscribe(handleAttachments);
  };

  /**
   * Get a ground hazard attachment document by its id
   *
   * @param {string} groundHazardAttachmentId
   * @return {*}
   */
  const getEquipmentDeficiencyAttachment = (equipmentDeficiencyAttachmentId: string) => {
    if (!equipmentDeficiencyAttachmentCollection) return undefined;

    return equipmentDeficiencyAttachmentCollection
      .findOne({
        selector: {
          id: equipmentDeficiencyAttachmentId,
        },
      })
      .exec();
  };

  return {
    createEquipmentDeficiencyAttachment,
    deficiencyAttachmentsInitialized,
    createEquipmentDeficiencyAttachments,
    subscribeToEquipmentDeficiencyAttachments,
    getEquipmentDeficiencyAttachment,
  };
};

export default useEquipmentDeficiencyAttachments;
