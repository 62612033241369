import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, GroupedAutoComplete, i18n } from '@nutrien/cxp-components';
import React, { useState } from 'react';

import { Borer } from '@/mobx-models/Equipment/Equipment';
import { useMst } from '@/mobx-models/Root';
import useWindowSize from '@/utilities/hooks/useWindowSize';

import { useOnlineStatus } from '../../utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: '20px',
    },
    firstAutoComplete: {
      marginTop: '8px',
    },
    card: {
      padding: '70px 40px',
      borderRadius: '10px',
      backgroundColor: `${theme.palette.background.paper}`,
    },
    logoCard: {
      margin: '80px auto',
      textAlign: 'center',
    },
    imageContainer: {
      marginBottom: '50px',
      textAlign: 'center',
    },
    paperContainer: {
      alignItems: 'flex-start !important',
    },
  }),
);

interface Props {
  open: boolean;
  listOfBorers: Borer[];
  assignBorer: (borer: Borer) => void;
}

const BorerSelector: React.FC<Props> = ({ open, listOfBorers, assignBorer }: Props) => {
  const classes = useStyles();
  const { equipment } = useMst();

  const [selectedBorer, setSelectedBorer] = useState<Borer>();
  const [assignedBorerClicked, setAssignedBorerClicked] = useState<boolean>(false);
  const [borerListOpen, setBorerListOpen] = useState<boolean>(false);
  const online = useOnlineStatus();
  const { portrait } = useWindowSize();

  const onBorerChanged = (value: Borer) => {
    setSelectedBorer(value);
  };

  const onAssignBorer = () => {
    if (selectedBorer && online) {
      setAssignedBorerClicked(true);
      assignBorer(selectedBorer);
    }
  };

  return (
    <>
      <Grid item xs={8} md={6} className={classes.logoCard}>
        <div>
          <img src="../../minesight_borer.svg" alt="MineSight Borer" />
        </div>
      </Grid>
      <Dialog
        open={open}
        titleText={i18n.t('iPad assignment')}
        backButton={false}
        disableEnforceFocus
        dialogActions={
          <Grid container spacing={2} justify="flex-end">
            <Grid item xs={4}>
              <Button
                onClick={onAssignBorer}
                variant="contained"
                color="primary"
                noMinHeight
                isLoading={assignedBorerClicked}
                disabled={!selectedBorer || !online}
                id="assign-button"
                data-testid="assign-ipad-button"
              >
                {i18n.t('Assign iPad')}
              </Button>
            </Grid>
          </Grid>
        }
        maxWidth="xs"
        maxHeight="280px"
        BackdropProps={{
          onClick: event => {
            event.preventDefault();
          },
          onTouchStart: event => {
            event.preventDefault();
          },
          style: { touchAction: 'none' },
        }}
        scroll="paper"
        style={{ marginTop: borerListOpen ? '0px' : '225px' }}
        classes={{
          scrollPaper: classes.paperContainer,
        }}
      >
        <Grid container>
          {!online && (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.text}>
                You appear to be offline. Please connect the iPad to WiFi, then select a borer.
              </Typography>
            </Grid>
          )}
          {online && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.text}>
                  This iPad is assigned to:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GroupedAutoComplete
                  list={listOfBorers}
                  open={borerListOpen}
                  required
                  getOptionLabel={(borer: Borer) => borer.name}
                  onChange={(event, value) => {
                    onBorerChanged(value);
                  }}
                  value={selectedBorer}
                  data-cy="Borer-Autocomplete"
                  className={classes.firstAutoComplete}
                  autoSelect={false}
                  noMinHeight
                  showCaret
                  loading={
                    (!listOfBorers || (listOfBorers && listOfBorers.length === 0)) &&
                    !equipment.errorLoadingBorers
                  }
                  data-testid="borer-selector-autocomplete"
                  groupByProperty="equipmentTypeLabel"
                  primaryProperty="name"
                  secondaryProperty="equipmentTypeLabel"
                  onOpen={() => setBorerListOpen(true)}
                  onClose={() => setBorerListOpen(false)}
                  ListboxProps={{ style: { maxHeight: portrait ? '40vh' : '300px' } }}
                  error={equipment.errorLoadingBorers}
                  errorText="Error loading borer list."
                />
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

export default BorerSelector;
