import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface BorerActivityType extends BaseEntity {
  readonly delayActivityTypeCategory: string;
  readonly description: string;
  readonly name: string;
  readonly isActive: boolean;
  readonly createdOn: string;
}
export type BorerActivityTypeCollection = RxCollection<BorerActivityType> | null;
export type BorerActivityTypeDocument = RxDocument<BorerActivityType>;

export const borerActivityTypeSchema: RxJsonSchema<BorerActivityType> = {
  type: 'object',
  version: 0,
  description: 'describes a BorerActivityType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    delayActivityTypeCategory: {
      ref: RxdbCollectionName.DELAY_ACTIVITY_TYPE_CATEGORY,
      type: ['string', 'null'],
    },
    description: { type: 'string' },
    name: { type: 'string' },
    createdOn: { type: 'string' },
    isActive: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const activityTypeFeedQuery = print(gql`
  query activityTypeFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
    activityTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      createdOn
      delayActivityTypeCategory
      description
      id
      isActive
      name
      updatedAt
      isDeleted
      version
    }
  }
`);

export const borerActivityTypePullQueryBuilder = generatePullQuery(
  activityTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
