/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';

const showRerenders =
  sessionStorage.getItem('WHY_DID_YOU_RERENDER') === 'true' ||
  sessionStorage.getItem('WDYR') === 'true';

if (showRerenders) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
