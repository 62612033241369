import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { MiningMethodAllCap } from '../../utilities';
import { InspectionType, MiningMethod } from '../../utilities/constants';
import { GroundHazardConditionTypeDocument } from '../HazardConditionType/queryBuilder';
import { HazardSeverityDocument } from '../HazardSeverities/queryBuilder';
import { InspectionCategoryDocument } from '../InspectionCategories/queryBuilder';
import { InspectionOptionsDocument } from '../InspectionOptions/queryBuilder';
import { InspectionSeverityDocument } from '../InspectionSeverityFeed/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import { InspectionDocument } from './queryBuilder';

export interface InspectionOptionsWithDetails {
  option: InspectionOptionsDocument;
  hazardCondition?: GroundHazardConditionTypeDocument;
  hazardSeverity?: HazardSeverityDocument;
  deficiencyId?: string;
}
export interface InspectionCategoryWithOptions {
  category: InspectionCategoryDocument;
  options: InspectionOptionsWithDetails[];
}

export interface WfaiDetails {
  // Only used to display 'new' chips on hazards
  newGroundHazardIds?: string[];
  newRemediatedGroundHazardIds?: string[];
  // All ative hazards at time of GCHA submission
  activeGroundHazardIds?: string[];
  signoffCompleted?: boolean;
  newHazardStatus?: string;
  verbage?: Record<string, string | string[]>;
  inspectionType?: string;
  signatureId?: string;
  wfaiComment?: string;
}
export interface InspectionDetails {
  inspection: InspectionDocument;
  categories: InspectionCategoryWithOptions[];
  severityModel: InspectionSeverityDocument[];
}
export interface WfaiInspectionDetails {
  inspection: InspectionDocument;
  wfaiDetails?: WfaiDetails;
}

const useInspections = () => {
  const inspectionsCollection = useRxCollection(RxdbCollectionName.INSPECTIONS);
  const inspectionCategoriesCollection = useRxCollection(RxdbCollectionName.INSPECTION_CATEGORIES);
  const inspectionOptionsCollection = useRxCollection(RxdbCollectionName.INSPECTION_OPTIONS);
  const hazardCategoryCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
  );
  const hazardSeverityCollection = useRxCollection(RxdbCollectionName.HAZARD_SEVERITIES);
  const inspectionSeverityCollection = useRxCollection(RxdbCollectionName.INSPECTION_SEVERITY);

  const [inspectionsInitialized, setInspectionsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      inspectionsCollection &&
      inspectionCategoriesCollection &&
      inspectionOptionsCollection &&
      hazardCategoryCollection &&
      hazardSeverityCollection &&
      inspectionSeverityCollection
    ) {
      setInspectionsInitialized(true);
    }
  }, [
    inspectionsCollection,
    inspectionCategoriesCollection,
    inspectionOptionsCollection,
    hazardCategoryCollection,
    hazardSeverityCollection,
    inspectionSeverityCollection,
  ]);

  const getInspectionFor = async (
    inspectionType: InspectionType,
    miningMethod?: MiningMethod | MiningMethodAllCap,
    onlyReturnBaseInspection = false,
  ) => {
    if (!inspectionsCollection) return;
    if (!inspectionCategoriesCollection) return;
    if (!inspectionOptionsCollection) return;
    if (!hazardCategoryCollection) return;
    if (!hazardSeverityCollection) return;
    if (!inspectionSeverityCollection) return;
    if (!miningMethod) return;

    // Pull inspection parent record
    let inspection: InspectionDocument | null;
    try {
      inspection = await inspectionsCollection
        .findOne({
          selector: {
            $and: [
              { inspectionType: { $eq: inspectionType } },
              { miningMethod: { $eq: miningMethod } },
            ],
          },
        })
        .exec();
    } catch (error) {
      console.error('🚀 ~ file: useInspections.tsx ~ line 17 ~ useInspections ~ error', error);
      throw new Error('Unable to get inspection for type (missing data in DB)');
    }
    if (!inspection) return;
    if (onlyReturnBaseInspection) return { inspection };

    // Pull inspections categories
    let categories: InspectionCategoryDocument[];
    try {
      categories = await inspectionCategoriesCollection
        .find({
          selector: {
            inspectionId: inspection.id,
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useInspections.tsx ~ line 55 ~ useInspections ~ error', error);
      throw new Error('Unable top get inspection for type');
    }

    // Pull inspections options
    let optionsByCategory: Array<Array<InspectionOptionsDocument>>;

    try {
      optionsByCategory = await Promise.all(
        categories.map(category =>
          inspectionOptionsCollection
            .find({
              selector: {
                inspectionCategoryId: category.id,
              },
            })
            .exec(),
        ),
      );
    } catch (error) {
      console.log('🚀 ~ file: useInspections.tsx ~ line 79 ~ useInspections ~ error', error);
      throw new Error('Unable top get inspection for type');
    }

    // hazard condition type ids
    const conditionTypeIds = optionsByCategory
      .map(optionSet => optionSet.map(option => option.hazardConditionTypeId))
      .flat()
      .filter((id, index, array): id is string => id !== null && array.indexOf(id) === index);

    // hazard condition types
    const conditionTypes = await hazardCategoryCollection.findByIds(conditionTypeIds).exec();

    // hazard severity types
    const hazardSeverityIds = Array.from(conditionTypes.values())
      .map((doc: GroundHazardConditionTypeDocument) => doc.severity)
      .filter((id, index, array): id is string => id !== null && array.indexOf(id) === index);

    // hazard severity types
    const severityTypes = await hazardSeverityCollection.findByIds(hazardSeverityIds).exec();

    // Get the inspection severity models
    const severityModelRanges = await inspectionSeverityCollection
      .find({
        selector: {
          inspectionId: inspection.id,
        },
      })
      .exec();

    const result: InspectionDetails = {
      inspection,
      categories: categories.map((category, index) => {
        return {
          category,
          options: optionsByCategory[index].map(option => {
            const hazardCondition: GroundHazardConditionTypeDocument | undefined =
              option.hazardConditionTypeId && conditionTypes.get(option.hazardConditionTypeId);
            const hazardSeverity =
              hazardCondition &&
              hazardCondition.severity &&
              severityTypes.get(hazardCondition.severity);

            return { option, hazardCondition, hazardSeverity };
          }),
        };
      }),
      severityModel: severityModelRanges,
    };

    return result;
  };

  return { getInspectionFor, inspectionsInitialized };
};

export default useInspections;
