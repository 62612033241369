import React from 'react';

import ActiveDeficienciesCard from './ActiveDeficienciesCard';
import ActiveWorkOrdersCard from './ActiveWorkOrdersCard';
import OtherDeficienciesCard from './OtherDeficienciesCard';

const DeficienciesTab: React.FC = () => {
  return (
    <>
      <ActiveDeficienciesCard />
      <OtherDeficienciesCard />
      <ActiveWorkOrdersCard />
    </>
  );
};

export default DeficienciesTab;
