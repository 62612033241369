import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface DelayActivityTypeCategory extends BaseEntity {
  readonly description: string;
}
export type DelayActivityTypeCategoryCollection = RxCollection<DelayActivityTypeCategory> | null;
export type DelayActivityTypeCategoryDocument = RxDocument<DelayActivityTypeCategory>;

export const delayActivityTypeCategorySchema: RxJsonSchema<DelayActivityTypeCategory> = {
  type: 'object',
  version: 0,
  description: 'describes a DelayActivityTypeCategory object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const delayActivityTypeCategoryFeedQuery = print(gql`
  query delayActivityTypeCategoryFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
    delayActivityTypeCategoryFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      version
      updatedAt
      isDeleted
      id
      description
    }
  }
`);

export const delayActivityTypeCategoryPullQueryBuilder = generatePullQuery(
  delayActivityTypeCategoryFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
