import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';
import { MigrationStrategies } from 'rxdb/dist/types/types';

import { gql } from '../../graphql/Client';
import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Panel extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly block: string;
  readonly miningMethod: string;
  readonly completedOn: string | null;
}
export type PanelCollection = RxCollection<Panel> | null;
export type PanelDocument = RxDocument<Panel>;

export const panelMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: PanelDocument) => {
    return {
      ...oldDoc,
      completedOn: null,
    };
  },
};

export const panelSchema: RxJsonSchema<Panel> = {
  type: 'object',
  version: 1,
  description: 'describes a Panel object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    block: {
      ref: RxdbCollectionName.BLOCKS,
      type: 'string',
    },
    miningMethod: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    completedOn: { type: ['string', 'null'] },
  },
};

const panelFeedQuery = print(gql`
  query panelFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    panelFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      description
      block
      miningMethod
      isDeleted
      updatedAt
      version
      completedOn
    }
  }
`);

export const panelsPullQueryBuilder = generatePullQuery(
  panelFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
