import { z } from 'zod';

export const integerGreaterThanZero = () =>
  // Convert string to number before validating
  z.preprocess(
    val => {
      // Check for empty strings
      if (val !== '' && String(val).trim() === '') {
        return NaN;
      }
      // If the value is empty, return null instead of 0
      return val === '' ? null : Number(val);
    },
    z
      .number({
        invalid_type_error: 'Must be a valid number',
      })
      .int("Can't contain decimals")
      .positive('Must be greater than zero')
      .nullable(),
  );

export const decimalGreaterThanZero = () =>
  // Convert string to number before validating
  z.preprocess(
    val => {
      // Check for empty strings
      if (val !== '' && String(val).trim() === '') {
        return NaN;
      }
      // If the value is empty, return null instead of 0
      return val === '' ? null : Number(val);
    },
    z
      .number({
        invalid_type_error: 'Must be a valid number',
      })
      .positive('Must be greater than zero')
      .nullable(),
  );

export const maxLengthString = (maxLength = 255) =>
  z
    .string()
    .max(maxLength, 'Your value is too long')
    .nonempty('Please enter a valid value')
    .optional()
    .or(z.literal(''));
