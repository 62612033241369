import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    checkboxContainer: {
      paddingTop: '7px',
    },
  }),
);

export { useStyles };
export default useStyles;
