import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import { useEmployees } from '../../rxdb/Employees/useEmployees';
import { SignatureDocument } from '../../rxdb/Signature/rxdbSignatureDefinition';
import { useSignatures } from '../../rxdb/Signature/useSignatures';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
      margin: '0px 8px 8px 8px !important',
    },
    innerCard: {
      padding: '16px',
      marginLeft: '0 !important',
      boxShadow: 'none !important',
    },
    signatureImage: {
      width: '30%',
    },
  }),
);

interface Props {
  signatureId: string;
}

const ChecksThisShiftSignatureCard: React.FC<Props> = ({ signatureId }: Props) => {
  const classes = useStyles();
  const { signaturesInitialized, getSignature } = useSignatures();
  const { getEmployee } = useEmployees();
  const [signature, setSignature] = useState<SignatureDocument>();
  const [signatureName, setSignatureName] = useState<string>('');

  // Configure
  const setup = async () => {
    try {
      const sig = await getSignature(signatureId);
      if (sig) {
        setSignature(sig);

        let empName;
        if (sig.siteEmployeeId) {
          const emp = await getEmployee(sig.siteEmployeeId);
          empName = emp ? `${emp.firstName} ${emp.lastName}` : sig.personnelName;
        }
        setSignatureName(empName || 'Uknown');
      }
    } catch (error) {
      console.log('🚀 ~ file: ChecksThisShiftSignatureCard.tsx ~ line 71 ~ setup ~ error', error);
    }
  };

  useEffect(() => {
    if (signaturesInitialized && signatureId) {
      setup();
    }
  }, [signaturesInitialized, signatureId]);

  return (
    <>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">
                {i18n.t("Reporter's name")}: {signatureName}
              </Typography>
              <img alt="signature" src={signature?.signature} className={classes.signatureImage} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default ChecksThisShiftSignatureCard;
