import { RxDatabase } from 'rxdb';
import { migrateStorage } from 'rxdb-premium/plugins/migrate-storage';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';

import { BorerDatabaseCollections } from '@/models/BorerDatabaseCollections';
import { isJestOrStorybook } from '@/test-helpers/isJestOrStorybook';
import { removeIndexedDbPromise } from '@/utilities/removeIndexedDbPromise';

import { rxdb15DatabaseName } from './dbInitializer';

const rxdb14DBName = 'borer_rxdb_14';

export const removeRxDB15fromStorage = async () => {
  if (isJestOrStorybook()) return;
  const indexDBsList: IDBDatabaseInfo[] = await window.indexedDB.databases();

  // do we have a rxdb 15 database?
  const rxdb15DBExists = indexDBsList.find(idb => idb.name === rxdb15DatabaseName);
  if (rxdb15DBExists) await removeIndexedDbPromise(rxdb15DatabaseName);
};

const handleRxDB14to15Migration = async (db: RxDatabase<BorerDatabaseCollections>) => {
  await migrateStorage(
    db,
    rxdb14DBName,
    getRxStorageIndexedDB(),
    () => {
      console.log('storage migration: batch processed');
    },
    console.log.bind(console),
  );

  await db?.requestIdlePromise();

  window.location.reload();
};

export const migrateDBsAsNeeded = async (db: RxDatabase<BorerDatabaseCollections>) => {
  const indexDBsList: IDBDatabaseInfo[] = await window.indexedDB.databases();

  // do we have a rxdb 14 database?
  const rxdb14DB = indexDBsList.find(idb => idb.name === rxdb14DBName);

  if (rxdb14DB) {
    console.log('RxDB 14 database found, migrating to v15...');
    await handleRxDB14to15Migration(db);
    console.log('RxDB 14 migration complete');
    await removeRxDB14fromStorage();
  } else {
    console.log('no RxDB 14 database found, continuing...');
  }
};
