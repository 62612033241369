import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      padding: '8px',
    },
    title: {
      marginTop: '16px !important',
      marginBottom: '24px !important',
      color: `${theme.palette.text.disabled} !important`,
    },
    saveButton: {
      paddingRight: '10px !important',
      '& > div': {
        alignItems: 'flex-end',
      },
    },
  }),
);
