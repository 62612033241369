import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { getSite } from '@/graphql/Site';
import { rootStore } from '@/mobx-models/Root';
import { BaseEntity } from '@/models/BaseEntity';
import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';

import { generatePullQuery } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

const DEBUG = localStorage.getItem('DEBUG') === 'true';

export interface Site extends BaseEntity {
  readonly name: string;
  readonly distanceUnit: null | string;
  readonly distanceUnitAbbreviation: null | string;
  readonly editableAfterShiftEndMinutes: number;
}

export const siteSchema: RxJsonSchema<Site> = {
  type: 'object',
  version: 3,
  description: 'describes a site object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    name: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    distanceUnit: { type: ['string', 'null'] },
    distanceUnitAbbreviation: { type: ['string', 'null'] },
    editableAfterShiftEndMinutes: { type: 'number' },
  },
};

export const fetchSiteUnitsAndPutInSessionStorage = async (siteId: string) => {
  // This ensures the useSiteFeatures hook works as expected
  const siteDetails = await getSite(siteId);
  sessionStorage.setItem(
    'siteUnits',
    JSON.stringify({
      distanceUnit: siteDetails?.data?.getSite?.distanceUnit,
      distanceUnitAbbreviation: siteDetails?.data?.getSite?.distanceUnitAbbreviation,
      editableAfterShiftEndMinutes: siteDetails?.data?.getSite?.editableAfterShiftEndMinutes,
    }),
  );

  return siteDetails?.data?.getSite;
};

export type SiteCollection = RxCollection<Site> | null;
export type SiteDocument = RxDocument<Site>;

export const siteMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SiteDocument) => {
    const { id } = oldDoc;

    // For each document we call the get and migrate with the new distanceUnit and distanceUnitAbbreviation
    return getSite(id).then(response => {
      const { distanceUnit, distanceUnitAbbreviation } = response.data.getSite;
      return { ...oldDoc, distanceUnit, distanceUnitAbbreviation };
    });
  },
  2: async (oldDoc: SiteDocument) => {
    const siteId = rootStore.user.siteId;

    if (siteId === oldDoc.id) {
      if (DEBUG) console.log('running migration FOR SITE ID:', siteId);
      try {
        await fetchSiteUnitsAndPutInSessionStorage(siteId);
      } catch (error) {
        console.error('🚀 ~ file: queryBuilder.ts:72 ~ error', error);
        captureInSentryWithDetails(error, { siteDocMigrationError: true });
      }
    }

    return oldDoc;
  },
  3: async (oldDoc: SiteDocument) => {
    const siteId = rootStore.user.siteId;

    if (siteId === oldDoc.id) {
      try {
        const siteDetails = await fetchSiteUnitsAndPutInSessionStorage(siteId);
        if (DEBUG) console.log('Site details during site migration', siteDetails);

        if (siteDetails) {
          return {
            ...oldDoc,
            editableAfterShiftEndMinutes: siteDetails?.editableAfterShiftEndMinutes || 0,
          };
        }
      } catch (error) {
        console.error('🚀 ~ file: queryBuilder.ts:72 ~ error', error);
        captureInSentryWithDetails(error, { siteDocMigrationError: true });
      }
    }
    return { ...oldDoc, editableAfterShiftEndMinutes: 0 };
  },
};

const siteFeedQuery = print(gql`
  query siteFeed($lastUpdateAt: Float!, $limit: Int!) {
    siteFeed(lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      isDeleted
      name
      updatedAt
      version
      distanceUnit
      distanceUnitAbbreviation
      editableAfterShiftEndMinutes
    }
  }
`);

export const sitePullQueryBuilder = generatePullQuery(siteFeedQuery, undefined, defaultDocAllTime);
