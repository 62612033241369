import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { formatDateToUnix } from '../../utilities/hooks/useDateFormatters';
import { HazardLog } from '../GroundHazardLog/queryBuilder';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDoc90Day } from '../Shared/defaultDoc';

export interface GroundHazard extends BaseEntity {
  readonly attachments?: (string | null)[];
  readonly conditionType: string;
  readonly startLocation?: string;
  readonly endLocation?: string;
  readonly groundControlSets?: string[];
  readonly hazardLogs?: (HazardLog | null)[];
  readonly remediatedOn: string | null;
  readonly remediatedOnUnix: number | null;
  readonly createdBy: string;
  readonly createdOn?: string;
  readonly createdOnUnix: number;
  readonly modifiedBy: string;
  readonly modifiedOn?: string;
  readonly borerEquipmentId: string | null;
  readonly inspectionId?: string | null;
  readonly startHazardLandmark: string | null;
  readonly endHazardLandmark: string | null;
}

export type GroundHazardDocument = RxDocument<GroundHazard>;
export type GroundHazardCollection = RxCollection<GroundHazard> | null;

export const groundHazardSchema: RxJsonSchema<GroundHazard> = {
  type: 'object',
  version: 4,
  description: 'describes a hazard object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    attachments: {
      type: 'array',
      ref: 'ground_hazard_attachments',
      items: {
        type: 'string',
      },
    },
    conditionType: {
      ref: 'ground_hazard_condition_types',
      type: 'string',
    },
    hazardLogs: {
      type: 'array',
      ref: 'hazard_logs',
      items: {
        type: 'string',
      },
    },
    groundControlSets: {
      type: ['array', 'null'],
      items: {
        type: ['string', 'null'],
      },
    },
    startLocation: {
      ref: 'locations',
      type: 'string',
    },
    endLocation: {
      ref: 'locations',
      type: ['string', 'null'],
    },
    createdBy: {
      ref: 'employees',
      type: 'string',
    },
    modifiedBy: {
      ref: 'employees',
      type: 'string',
    },
    remediatedOn: { type: ['string', 'null'] },
    remediatedOnUnix: { type: ['number', 'null'] },
    createdOn: { type: 'string' },
    createdOnUnix: { type: 'number' },
    modifiedOn: { type: 'string' },
    updatedAt: { type: 'number', multipleOf: 1 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerEquipmentId: { type: ['string', 'null'] },
    inspectionId: { type: ['string', 'null'] },
    startHazardLandmark: { type: ['string', 'null'] },
    endHazardLandmark: { type: ['string', 'null'] },
  },
};

export const groundHazardMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardDocument) => {
    return {
      ...oldDoc,
      inspectionId: null,
    };
  },
  2: (oldDoc: GroundHazardDocument) => oldDoc,
  3: (oldDoc: GroundHazardDocument) => ({
    ...oldDoc,
    startHazardLandmark: null,
    endHazardLandmark: null,
  }),
  4: (oldDoc: GroundHazardDocument) => {
    const newDoc = { ...oldDoc };
    delete newDoc.updateLastViewed;
    return newDoc;
  },
};

const groundHazardFeedQuery = print(gql`
  query groundHazardFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    groundHazardFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      attachments
      conditionType
      createdBy
      createdOn
      endLocation
      hazardLogs
      id
      isDeleted
      remediatedOn
      modifiedBy
      modifiedOn
      startLocation
      updatedAt
      version
      borerEquipmentId
      inspectionId
      startHazardLandmark
      endHazardLandmark
    }
  }
`);

export const groundHazardsPullQueryBuilder = generatePullQuery(
  groundHazardFeedQuery,
  PullQueryContext.Borer,
  defaultDoc90Day,
);

const setGroundHazardMutation = print(gql`
  mutation setGroundHazard($groundHazard: SetGroundHazardInput) {
    setGroundHazard(input: $groundHazard) {
      borerEquipmentId
      remediatedOn
      createdOn
      siteId
      conditionType {
        description
        displayName
        id
      }
      createdBy
      groundControls
      id
      modifiedOn
      hazardLogs {
        comment
        id
        updatedAt
        createdOn
      }
      startLocation {
        endMeters
        id
        startMeters
        surveyPoint {
          description
        }
        sequence {
          description
        }
        room {
          description
        }
        pass {
          description
        }
        panel {
          description
          miningMethod {
            name
            layer
            zone
          }
          block {
            description
          }
        }
      }
      id
      createdOn
      siteId
    }
  }
`);

type SetGroundHazardInput = {
  id: string;
  createdOn: string | null;
  conditionTypeId: string | null;
  inspectionId: string | null;
  startLocationId: string | null;
  endLocationId: string | null;
  borerEquipmentId: string | null;
  remediatedOn: string | null;
  startHazardLandmark: string | null;
  endHazardLandmark: string | null;
  version: number;
};

const mapGroundHazardDocToInput = (doc: GroundHazardDocument): SetGroundHazardInput => {
  return {
    id: doc.id,
    createdOn: doc.createdOn || null,
    conditionTypeId: doc.conditionType,
    inspectionId: doc.inspectionId || null,
    startLocationId: doc.startLocation || null,
    endLocationId: doc.endLocation || null,
    borerEquipmentId: doc.borerEquipmentId,
    remediatedOn: doc.remediatedOn,
    startHazardLandmark: doc.startHazardLandmark,
    endHazardLandmark: doc.endHazardLandmark,
    version: doc.version,
  };
};

export const groundHazardPushQueryBuilder = generatePushQuery(
  setGroundHazardMutation,
  (doc: GroundHazardDocument) => ({
    groundHazard: mapGroundHazardDocToInput(doc),
  }),
);

export const groundHazardPullModifier = (doc: any) => {
  const result = {
    ...doc,
    createdOnUnix: formatDateToUnix(doc.createdOn),
    remediatedOnUnix: doc.remediatedOn !== null ? formatDateToUnix(doc.remediatedOn) : null,
  };
  return result;
};
