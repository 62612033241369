import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@nutrien/cxp-components';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import useRxDB from '@/rxdb/useRxDB';

import useEventTracking, { TrackingEventType } from '../../../utilities/hooks/useEventTracking';
import { useBorerHeartbeat } from '../../BorerHeartbeat/useBorerHeartbeat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100vh',
    },
  }),
);

const clearCacheStorageAndLogout = async db => {
  // Clear image cache
  if ('serviceWorker' in navigator) {
    try {
      const cache = await caches.open(`s3-images`);
      const imageKeys = await cache.keys();
      await Promise.all(imageKeys.map(key => cache.delete(key)));
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx ~ line 56 ~ signOutUser ~ error', error);
    }
  }

  sessionStorage.clear();
  localStorage.clear();
  db?.stopReplication();
  db?.waitForIdle();
  window.location.replace('/login'); // dont use history in this case a trigger a page refresh
};

const Logout: React.FC = ({}) => {
  const classes = useStyles();
  const { db } = useRxDB();

  const { sendCachedEvents, trackEvent } = useEventTracking();
  const { sendBorerHeartbeat } = useBorerHeartbeat();

  const [statusText, setStatusText] = useState('Logging Out...');

  const signOutUser = async () => {
    // Stop all replication
    try {
      setStatusText('Stopping sync...');
      await db?.stopReplication();
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:59 ~ signOutUser ~ error:', error);
    }

    // Perform a single sync to make sure all data is pushed
    try {
      setStatusText('Performing a final sync...');
      await db?.runSingleReplication();
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:59 ~ signOutUser ~ error:', error);
    }

    // Send a final heartbeat to capture the logout time
    try {
      await sendBorerHeartbeat({ lastUserInteractionTime: dayjs(), logoutTime: dayjs() });
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:39 ~ signOutUser ~ error', error);
    }

    // Send any cached events
    try {
      await sendCachedEvents();
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:35 ~ signOutUser ~ error', error);
    }

    // Clean up the local db instance
    setStatusText('Cleaning up... 🧹');
    try {
      await db?.destroyDB();
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:90 ~ signOutUser ~ error:', error);
    }

    const iDbList = await window.indexedDB.databases();

    for (const iDb of iDbList) {
      try {
        await new Promise((resolve, reject) => {
          const req = window.indexedDB.deleteDatabase(iDb.name);

          setTimeout(() => {
            try {
              console.log('🚀 ~ file: Logout.tsx:92 req:', req.error); // Trying to access error on Safari throws error if in use
            } catch (e) {
              console.log('🚀 ~ file: Logout.tsx:95 ~ setTimeout ~ e:', iDb.name, e);
              resolve(true); // need to resolve, otherwise will skip other dbs
            }
          }, 5000);

          req.onsuccess = resolve;
          req.onerror = reject;
          req.onblocked = resolve;
        });
      } catch (e) {
        console.log('🚀 ~ file: Logout.tsx:104 ~ indexedDB.databases ~ e:', e);
        await trackEvent(TrackingEventType.ERROR_REMOVING_DATABASE_DURING_LOGOUT, {
          e,
          indexDBDatabaseName: iDb?.name,
        });
      }
    }

    // Revokes all existing refresh tokens
    const globalLogout = localStorage.getItem('globalLogout') === 'true';
    try {
      await Auth.signOut({
        global: globalLogout,
      });
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx ~ line 30 ~ signOutUser ~ error', error);
    }

    clearCacheStorageAndLogout(db);
  };

  useEffect(() => {
    if (db?.isInitialized) {
      signOutUser();
    } else {
      console.log('DB not initialized when trying to sign out');
      setStatusText('DB not available, trying to clear cache and logout...');
      setTimeout(() => {
        clearCacheStorageAndLogout(db);
      }, 5000);
    }
  }, [db?.isInitialized, db]);

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2">{statusText}</Typography>
      </Grid>
    </Grid>
  );
};

export default Logout;
