import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { AppointmentType } from '@/utilities/enums';

import { useMst } from '../../mobx-models/Root';
import { formatDateFromUnixInReginaTzForScheduler } from '../../utilities/hooks/useDateFormatters';
import { getTimeUntilNextMinute } from '../../utilities/utilityFunctions';
import { useAdvancesForShift } from '../Advance/useAdvancesForShift';
import useBorerOperatorState, {
  codeBlockType,
  PopulatedBorerOperatorState,
} from '../BorerOperatorStateFeed/useBorerOperatorState';
import { getStateTypeById } from '../BorerStateTypeFeed/useBorerStateType';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { BorerOperatorChangeCollection, BorerOperatorChangeDocument } from './queryBuilder';

export const useBorerOperatorChange = () => {
  const { advancesForShiftById } = useAdvancesForShift();
  const { shiftPicker } = useMst();

  const borerOperatorChangeCollection: BorerOperatorChangeCollection = useRxCollection(
    RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED,
  );

  const { getStateByBorerStateId } = useBorerOperatorState();

  const { result: tempStates, isFetching: tempStatesFetching } =
    useRxData<BorerOperatorChangeDocument>(
      RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED,
      collection =>
        collection.find({
          selector: {
            showInSchedulerView: true,
          },
        }),
    );

  const [tempAugmentedStates, setTempAugmentedStates] = useState<PopulatedBorerOperatorState[]>([]);

  useEffect(() => {
    const augmentTempStates = async () => {
      if (!tempStates.length) {
        setTempAugmentedStates([]);
      } else {
        const formattedRecords: PopulatedBorerOperatorState[] = [];

        await Promise.all(
          tempStates.map(async parent => {
            let count = 1;
            for (const state of parent.states) {
              const borerStateType = await getStateTypeById(state.borerStateTypeId);
              const parentState = await getStateByBorerStateId(parent.borerStateId);

              formattedRecords.push({
                borerOperatorState: parentState,
                borerOperatorStateId: parent.borerStateId,
                borerStateType,
                startDate: formatDateFromUnixInReginaTzForScheduler(dayjs(state.startTime).unix()),
                endDate: !state.endTime
                  ? formatDateFromUnixInReginaTzForScheduler(dayjs().unix())
                  : formatDateFromUnixInReginaTzForScheduler(dayjs(state.endTime).unix()),
                title: borerStateType?.description || '',
                typeId: AppointmentType.DELAY, // Delays are 1, activities are 2
                id: `${parent.id}-${count}`,
                comment: state.comment,
                blockType: codeBlockType(borerStateType, parent.failedSync),
                isRunning: borerStateType?.isRunning === true,
                isTempState: true,
                failedSync: parent.failedSync,
                originalId: state.originalId,
                updatedAt: parent.updatedAt,
                existingTempStateId: parent.id,
                advanceString: state?.borerShiftAdvanceId
                  ? advancesForShiftById[state.borerShiftAdvanceId]?.locationString || ''
                  : '',
                isGeneratedState: false,
                cuttingTypeId: state.cuttingTypeId,
                cuttingMethodId: state.cuttingMethodId,
                startTime: state.startTime || null,
                endTime: state.endTime || null,
              });
              count = count + 1;
            }
          }),
        );
        const filteredRecords = formattedRecords.filter(state => {
          const diff = dayjs(state.startDate).diff(dayjs.unix(shiftPicker.shiftStartDateUnix));
          return diff >= 0;
        });
        setTempAugmentedStates([...filteredRecords]);
      }
    };
    augmentTempStates();

    const interval = setInterval(() => {
      // Re-run on on the minute to make sure last appointment is accurate
      augmentTempStates();
    }, getTimeUntilNextMinute());

    return () => clearInterval(interval);
  }, [tempStates, advancesForShiftById]);

  // States for given borerStateId
  const getTempStatesForParentBorerStateId = useCallback(
    async (
      borerStateId: string,
    ): Promise<
      {
        startTimeUnix: number;
        endTimeUnix: number | null;
        comment: string;
        borerStateTypeId: string;
        borerShiftAdvanceId: string;
        id: string;
        parentStateId: string;
        cuttingTypeId: string | null;
        cuttingMethodId: string | null;
      }[]
    > => {
      const tmpStates = await borerOperatorChangeCollection
        ?.find({
          selector: {
            borerStateId,
            showInSchedulerView: true,
          },
        })
        .exec();

      if (!tmpStates?.[0]?.states) return [];

      const formattedStates = await Promise.all(
        tmpStates?.[0]?.states.map(async state => {
          const stateType = await getStateTypeById(state.borerStateTypeId);
          return {
            parentStateId: tmpStates.id,
            id: state.originalId,
            startTimeUnix: dayjs(state.startTime).unix(),
            endTimeUnix: state.endTime ? dayjs(state.endTime).unix() : null,
            comment: state.comment,
            borerStateTypeId: state.borerStateTypeId,
            borerShiftAdvanceId: state.borerShiftAdvanceId || '',
            originalId: state.originalId,
            isTempState: true,
            isRunning: stateType?.isRunning === true,
            cuttingTypeId: state.cuttingTypeId,
            cuttingMethodId: state.cuttingMethodId,
          };
        }),
      );

      return formattedStates;
    },

    [borerOperatorChangeCollection],
  );

  return {
    tempAugmentedStates: tempStates.length === 0 ? tempStates : tempAugmentedStates,
    tempStatesFetching,
    getTempStatesForParentBorerStateId,
  };
};

export default useBorerOperatorChange;
