import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { PassDocument, PassesCollection } from './queryBuilder';

export const usePasses = (miningMethodToQuery?: string) => {
  const passesCollection: PassesCollection = useRxCollection(RxdbCollectionName.PASSES);

  const [passesForMiningMethod, setPassesForMiningMethod] = useState<PassDocument[]>([]);

  const getPassesForMiningMethod = async (miningMethod: string): Promise<PassDocument[]> => {
    if (!passesCollection) return [];

    let passes: PassDocument[];

    try {
      passes = await passesCollection
        ?.find({
          selector: {
            $or: [{ miningMethod: { $eq: miningMethod } }, { miningMethod: { $eq: 'Universal' } }],
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }
    return passes;
  };

  useEffect(() => {
    const getPasses = async () => {
      if (miningMethodToQuery) {
        setPassesForMiningMethod(await getPassesForMiningMethod(miningMethodToQuery));
      }
    };
    getPasses();
  }, [miningMethodToQuery]);

  return { getPassesForMiningMethod, passesForMiningMethod };
};

export default usePasses;
