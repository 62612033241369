import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: '3px 16px 13px 16px',
      gap: '16px',
      lineHeight: '23px !important',
      fontWeight: 400,
    },
    card: {
      padding: '16px',
    },
    container: {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      marginTop: '16px',
    },
    item: {
      padding: '3px 8px',
    },
    itemSize: {
      fontSize: '14px !important',
    },
    status: {
      borderWidth: '1px',
      border: 'solid ',
      borderRadius: '40px',
      fontSize: '14px',
      overflow: 'hidden',
      fontWeight: 'normal',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      height: '32px',
    },
    gridItem: {
      padding: '5px',
    },
    comment: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    down: {
      backgroundColor: '#FFEBEE',
      color: '#121212',
      borderColor: '#F24965',
      width: '74px',
    },
    up: {
      backgroundColor: '#E1F5E4',
      width: '54px',
      color: '#121212',
      borderColor: '#2AAB3F',
    },
  }),
);

export default useStyles;
