import { useCallback, useEffect, useState } from 'react';
import { MangoQuery } from 'rxdb';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { BlockDocument } from '../Blocks/queryBuilder';
import { PopulatedLocation } from '../Locations/useLocations';
import { RoomCollection, RoomDocument } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Panel, PanelCollection, PanelDocument } from './queryBuilder';

export interface OptionTypeWithMultipleRooms {
  block: BlockDocument;
  panel: PanelDocument;
  rooms?: RoomDocument[];
}

export const usePanels = (
  panelMiningMethod?: string,
  borerMiningMethod?: string,
  activeOnly = false,
) => {
  const roomsCollection: RoomCollection = useRxCollection(RxdbCollectionName.ROOMS);

  const [augmentedPanelList, setAugmentedPanelList] = useState<PopulatedLocation[]>([]);
  // Lanigan
  const [roomList, setRoomList] = useState<PopulatedLocation[]>([]);
  const [roomListLoaded, setRoomListLoaded] = useState(false);
  // Cory / Vanscoy / Allan
  const [panelListLoaded, setPanelListLoaded] = useState(false);

  const [panelListPopulated, setPanelListPopulated] = useState<OptionTypeWithMultipleRooms[]>([]);

  const panelQueryConstructor = useCallback(
    (collection: PanelCollection) => {
      const query: MangoQuery<Panel> = {
        selector: {},
      };

      if (activeOnly)
        query.selector = {
          ...query.selector,
          completedOn: { $eq: null },
        };

      return collection?.find(query);
    },
    [activeOnly],
  );

  const { result: panelList }: { result: PanelDocument[] } = useRxData<Panel>(
    RxdbCollectionName.PANELS,
    panelQueryConstructor,
  );

  const getRoomsForPanel = async (panelId: string) =>
    roomsCollection?.find().where('panel').eq(panelId).exec();

  const getRoomOptions = async (): Promise<PopulatedLocation[]> => {
    const options: PopulatedLocation[] = [];

    await Promise.all(
      panelList.map(async panel => {
        const block: BlockDocument = await panel.populate('block');
        const rooms: undefined | Array<RoomDocument | undefined> = await getRoomsForPanel(panel.id);

        rooms?.forEach(room => {
          options.push({
            block,
            panel,
            room,
          });
        });
      }),
    );

    return options;
  };

  const getPopulatedPanels = async (): Promise<OptionTypeWithMultipleRooms[]> => {
    // Returns rooms as an array
    const options: OptionTypeWithMultipleRooms[] = [];

    await Promise.all(
      panelList.map(async panel => {
        const block: BlockDocument = await panel.populate('block');
        const rooms: undefined | RoomDocument[] = await getRoomsForPanel(panel.id);

        options.push({
          block,
          panel,
          rooms: rooms || [],
        });
      }),
    );

    return options;
  };

  useEffect(() => {
    (async () => {
      // Returns rooms as part of the panel object, along with panel and block
      setRoomListLoaded(false);
      setPanelListLoaded(false);
      setRoomList([]);
      const panelRoomList = await getRoomOptions();
      // Longroom

      setAugmentedPanelList(
        panelRoomList
          .sort(
            (a, b) =>
              (a?.panel?.description || '').localeCompare(b.panel?.description || '') ||
              (a?.room?.description || '').localeCompare(b.room?.description || ''),
          )
          .filter(panelRoom => {
            if (panelMiningMethod) return panelRoom.panel?.miningMethod === panelMiningMethod;
            return true;
          }),
      );

      setRoomList(
        panelRoomList
          ?.filter((newRoom: PopulatedLocation) => {
            return (
              (borerMiningMethod ? newRoom.panel?.miningMethod === borerMiningMethod : true) &&
              newRoom.room?.isActive &&
              !newRoom.panel?.completedOn
            );
          })
          .sort(
            (a, b) =>
              (a.block?.description || '')?.localeCompare(b.block?.description || '') ||
              (a.panel?.description || '').localeCompare(b.panel?.description || '') ||
              (a.room?.description || '').localeCompare(b.room?.description || ''),
          ),
      );
      setRoomListLoaded(true);

      const popPanels = await getPopulatedPanels();

      setPanelListPopulated(
        popPanels?.sort(
          (a, b) =>
            a.block?.description?.localeCompare(b.block?.description) ||
            a.panel?.description?.localeCompare(b.panel?.description),
        ),
      );

      setPanelListLoaded(true);
    })();
  }, [roomsCollection, panelMiningMethod, borerMiningMethod, panelList]);

  return {
    getRoomOptions,
    augmentedPanelList,
    panelListPopulated,
    roomList,
    roomListLoaded,
    panelListLoaded,
  };
};

export default usePanels;
