import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { Button, i18n, Icons } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../../models/models';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
} from '../../rxdb/BorerShiftSignature/queryBuilder';
import useBorerShiftSignatures from '../../rxdb/BorerShiftSignature/useBorerShiftSignatures';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import {
  GroundHazard,
  GroundHazardCollection,
  GroundHazardDocument,
} from '../../rxdb/GroundHazard/queryBuilder';
import { GroundHazardAttachment } from '../../rxdb/GroundHazardAttachment/queryBuilder';
import useGroundHazardAttachments from '../../rxdb/GroundHazardAttachment/useGroundHazardAttachments';
import { GroundHazardConditionType } from '../../rxdb/HazardConditionType/queryBuilder';
import { InspectionOptionsDocument } from '../../rxdb/InspectionOptions/queryBuilder';
import {
  InspectionResult,
  InspectionResultCollection,
} from '../../rxdb/InspectionResults/queryBuilder';
import useInspections, {
  InspectionCategoryWithOptions,
  InspectionDetails,
} from '../../rxdb/Inspections/useInspections';
import useLocations from '../../rxdb/Locations/useLocations';
import usePanelDrawings from '../../rxdb/PanelDrawing/usePanelDrawings';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import {
  generateBaseEntity,
  generateBaseEntityWithCreatedOn,
} from '../../rxdb/rxdbUtilityFunctions';
import { Signature, SignatureCollection } from '../../rxdb/Signature/rxdbSignatureDefinition';
import { useNotification } from '../../utilities';
import { AREA_CHECK_STORAGE_KEY, InspectionType } from '../../utilities/constants';
import { SignatureType } from '../../utilities/enums';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import { formatAsStartCase } from '../../utilities/utilityFunctions';
import AddAreaCheckCard from '../AddAreaCheckCard';
import AddCommentSidePanel from '../AddCommentSidePanel';
import AddHazardsSidePanel from '../AddHazardsSidePanel';
import AddSignatureModal from '../AddSignatureModal';
import DiscardDraftModal from '../DiscardDraftModal';
import MissingPanelDrawingDialog from '../MissingPanelDrawingDialog';
import PanelDrawingEditModal from '../PanelDrawingEditModal';
import { ImageMetadata } from '../Thumbnail/Thumbnail';
import useStyles from './AddAreaCheck.styles';

export interface AreaCheckInspectionCategory extends InspectionCategoryWithOptions {
  id: string;
  isOriginal: boolean;
  displayOrder: number;
}

export interface AreaCheck {
  id: string;
  displayOrder: number;
  categoryId: string;
  isOriginal: boolean;
  hazards: GroundHazardDocument[];
}

export interface AreaCheckLocalStorage {
  inspectionDetails: InspectionDetails;
  isDraft: boolean;
  selectedOptions: Record<string, unknown>;
  currentAreaChecks: AreaCheck[];
  inspectionCategories: AreaCheckInspectionCategory[];
}

const UNDO_SNACKBAR_KEY = 'AreaCheckUndo';

const AddAreaCheck: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { errorNotification, successNotification, closeSnackbar } = useNotification();
  const { isCory, isLanigan, isVanscoy } = useSiteFeatures();
  const triggerAddHazardInAreaCheck = isLanigan || isVanscoy;

  const { currentPanelDrawing, panelDrawingsLoaded } = usePanelDrawings();

  const { getInspectionFor, inspectionsInitialized } = useInspections();
  const { groundHazardAttachmentsInitialized, subscribeToGroundHazardAttachments } =
    useGroundHazardAttachments();
  const { shiftPicker } = useMst();
  const { miningMethod, borerInitialized } = useCurrentBorer();
  const { trackEvent } = useEventTracking();

  const { getLocations, locationsInitialized } = useLocations();
  const [locations, setLocations] = useState<LocationDocument[]>([]);
  const { listEligibleSignees } = useBorerShiftSignatures();
  const [inspectionDetails, setInspectionDetails] = useState<InspectionDetails>();

  // area check
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [currentInspectionCategories, setCurrentInspectionCategories] = useState<
    AreaCheckInspectionCategory[]
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, unknown>>({});

  const [currentAreaChecks, setCurrentAreaChecks] = useState<AreaCheck[]>([]);
  const [currentAreaCheckId, setCurrentAreaCheckId] = useState<string>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Dayjs>(dayjs());

  // signatures
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttinPermits] = useState<boolean>(false);
  const [eligibleEmployees, setEligibleEmployees] = useState<Employee[]>([]);

  // hazards
  const [addHazardOpen, setAddHazardOpen] = useState<boolean>(false);
  const [hazardCondition, setHazardCondition] = useState<GroundHazardConditionType>();
  const [hazard, setHazard] = useState<GroundHazard>();
  const [existingAttachmentFileInfo, setExistingAttachmentFileInfo] = useState<ImageMetadata[]>([]);

  // comment
  const [addCommentOpen, setAddCommentOpen] = useState(false);

  // Panel Drawing
  const [panelDrawingModalOpen, setPanelDrawingModalOpen] = useState(false);

  // RXDB - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // collections
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection | null = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const inspectionResultCollection: InspectionResultCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  );

  const [miningMethodTimeout, setMiningMethodTimeout] = useState<ReturnType<typeof setTimeout>>();

  const saveToLocalStorage = (
    areaChecks: AreaCheck[],
    options: Record<string, unknown>,
    categories: AreaCheckInspectionCategory[],
  ) => {
    localStorage.setItem(
      AREA_CHECK_STORAGE_KEY,
      JSON.stringify({
        isDraft: Object.keys(options).length > 0,
        currentAreaChecks: areaChecks,
        selectedOptions: options,
        inspectionDetails,
        inspectionCategories: categories,
      }),
    );
    setLastUpdatedAt(dayjs());
  };

  const areaCheckType = useMemo(() => {
    const { type } = queryString.parse(location.search);

    if (
      type &&
      typeof type === 'string' &&
      Object.values(InspectionType)
        .map(val => val?.toLowerCase())
        .includes(type.toLowerCase() as InspectionType)
    ) {
      return type as InspectionType;
    }
    return isCory ? InspectionType.CORY_AREA_CHECK : InspectionType.AREA_CHECK;
  }, [isCory]);

  const areaCheckName = useMemo(
    () => formatAsStartCase(inspectionDetails?.inspection?.description),
    [inspectionDetails],
  );

  const setupBlank = async () => {
    const inspection = await getInspectionFor(
      areaCheckType,
      miningMethod, // MiningMethod.LONG_ROOM
    );

    if (!inspection) {
      errorNotification('Error: Bad data');
      onClose(true);
    }

    setInspectionDetails(inspection); // save original inspection to state

    const areaChecks: AreaCheck[] = [];
    const inspectionCategories: AreaCheckInspectionCategory[] = [];
    for (let k = 0; k < inspection?.categories.length; k++) {
      if (inspection?.categories[k]) {
        const id = uuidv4();
        areaChecks.push({
          id, // match inspection category id
          categoryId: inspection?.categories[k].category.id,
          displayOrder: inspection?.categories[k].category.displayOrder * 100,
          hazards: [],
          isOriginal: true,
        });

        inspectionCategories.push({
          id, // match area check id
          category: inspection?.categories[k].category,
          isOriginal: true,
          options: inspection?.categories[k].options,
          displayOrder: inspection?.categories[k].category.displayOrder * 100, // set high so room for 'add another check' items
        });
      }
    }

    setCurrentInspectionCategories(inspectionCategories);
    setCurrentAreaChecks(areaChecks);
    saveToLocalStorage(areaChecks, {}, inspectionCategories);
  };

  const setupFromLocalStorage = (previousAreaCheckSession: AreaCheckLocalStorage) => {
    setCurrentAreaChecks(previousAreaCheckSession.currentAreaChecks);
    setSelectedOptions(previousAreaCheckSession.selectedOptions);
    setInspectionDetails(previousAreaCheckSession.inspectionDetails);
    setCurrentInspectionCategories(previousAreaCheckSession.inspectionCategories);
  };

  const retrieveFromLocalStorage = (): AreaCheckLocalStorage => {
    const result = localStorage.getItem(AREA_CHECK_STORAGE_KEY);
    return result !== null ? JSON.parse(result) : undefined;
  };

  const setup = async () => {
    clearTimeout(miningMethodTimeout);
    setLocations(await getLocations());

    try {
      const { undo } = queryString.parse(location.search);

      const previousAreaCheckSession = retrieveFromLocalStorage();

      if (undo && previousAreaCheckSession && previousAreaCheckSession.isDraft) {
        // setup from localstorage
        setupFromLocalStorage(previousAreaCheckSession);
      } else {
        // fresh, net-new area check
        setupBlank();
      }

      try {
        const result = await listEligibleSignees(false);
        setEligibleEmployees(result);
      } catch (error) {
        console.log('🚀 ~ file: AddAreaCheck.tsx ~ line 242 ~ setup ~ error', error);
      }
    } catch (error) {
      console.log('🚀 ~ file: AddAreaCheck.tsx ~ line 248 ~ setup ~ error', error);
    }
  };

  const clearLocalStorage = (force = false) => {
    if (window.location.href.indexOf('undo=true') === -1 || force) {
      localStorage.removeItem(AREA_CHECK_STORAGE_KEY);
    }
  };

  const navigateToAreaCheckSummary = () => {
    history.push('/area-check');
  };

  const onClose = (forceClose = false) => {
    if (!forceClose && Object.keys(selectedOptions).length > 0) {
      setDiscardOpen(true);
    } else {
      clearLocalStorage(true);
      navigateToAreaCheckSummary();
    }
  };

  const notifyAndCloseWhenNoMiningMethodFound = () => {
    setMiningMethodTimeout(
      setTimeout(() => {
        errorNotification(
          `Unable to add ${areaCheckName.toLowerCase()} - please check borer assignment`,
        );
        onClose(true);
      }, 2500),
    );
  };

  const validateCanSave = useCallback(() => {
    let validSave = true;

    currentAreaChecks
      .filter(x => x.hazards.length > 0 || x.isOriginal)
      .forEach(x => {
        if (!selectedOptions[x.id]) {
          validSave = false;
        }
      });

    setCanSave(validSave);
  }, [selectedOptions, currentAreaChecks]);

  useEffect(() => {
    validateCanSave();
  }, [selectedOptions, validateCanSave]);

  useEffect(() => {
    if (
      inspectionsInitialized &&
      groundHazardCollection &&
      shiftPicker &&
      miningMethod &&
      locationsInitialized
    ) {
      setup();
    } else if (
      inspectionsInitialized &&
      groundHazardCollection &&
      shiftPicker &&
      !miningMethod &&
      locationsInitialized
    ) {
      notifyAndCloseWhenNoMiningMethodFound();
    }
  }, [
    inspectionsInitialized,
    groundHazardCollection,
    shiftPicker,
    miningMethod,
    borerInitialized,
    locationsInitialized,
  ]);

  const getCategoryIdByOptionId = (optionId: string): string => {
    let id = '';
    if (inspectionDetails) {
      inspectionDetails.categories.forEach(c => {
        if (c.options.some(x => x.option.id === optionId)) {
          id = c.category.id;
        }
      });
    }
    return id;
  };

  const getCategoryById = (categoryId: string) => {
    return inspectionDetails?.categories.find(x => x.category.id === categoryId);
  };

  const getOptionById = (category: any, optionId: string) => {
    return category.options.find(o => o.option.id === optionId);
  };

  const handleToggleChange = (event: any, value: string) => {
    if (value === null) return;

    const temp = { ...selectedOptions };
    const inspectionCategoryId = getCategoryIdByOptionId(value);

    const category = getCategoryById(inspectionCategoryId);
    const option = getOptionById(category, value);
    const areaCheckId = event.currentTarget.parentElement.id.substring(0, 36);

    temp[areaCheckId] = option;

    setSelectedOptions(temp);
    saveToLocalStorage(currentAreaChecks, temp, currentInspectionCategories);

    if (option && option.hazardCondition) {
      // Adds the hazard attached to the option hazard condition
      // For Cory, we want to be able to add a generic hazard
      if (triggerAddHazardInAreaCheck) {
        setHazardCondition(option.hazardCondition);
      }
      setCurrentAreaCheckId(areaCheckId);
      setAddHazardOpen(true); // open add hazard modal
      trackEvent(TrackingEventType.HAZARD_ADD_OPEN, {
        location: 'NEW_AREA_CHECK',
      });
    }
  };

  const returnToAddAreaCheck = () => {
    history.push(`/add-areacheck?type=${areaCheckType}&undo=true`);
  };

  const onSaveClicked = () => {
    setSignatureModalOpen(true);
  };

  const onHazardUpdated = async (gc: GroundHazardDocument) => {
    const temp = currentAreaChecks;
    const existingCategoryIndex = temp.findIndex(x => x.id === currentAreaCheckId);

    if (existingCategoryIndex !== -1) {
      const existingCategory = temp.find(x => x.id === currentAreaCheckId);
      existingCategory.hazards = [gc];
      temp[existingCategoryIndex] = existingCategory;
    }

    setCurrentAreaChecks(temp);
    saveToLocalStorage(temp, selectedOptions, currentInspectionCategories);
    setLocations(await getLocations());
  };

  const onNewHazardCreated = async (gc: GroundHazardDocument) => {
    try {
      const temp = currentAreaChecks;
      const existingCategory = temp.find(x => x.id === currentAreaCheckId);
      if (existingCategory) {
        const tempHazards = [...existingCategory.hazards];
        tempHazards.push(gc);
        existingCategory.hazards = tempHazards;
      } else {
        temp.push({
          id: currentAreaCheckId,
          categoryId: currentAreaChecks.find(x => x.id === id)?.categoryId,
          hazards: [gc],
        });
      }
      setCurrentAreaChecks(temp);
      saveToLocalStorage(temp, selectedOptions, currentInspectionCategories);
      setLocations(await getLocations());
    } catch (error) {
      console.error(`ERROR => ${error}`);
    }
  };

  const onCancelHazardCreation = () => {
    const temp = selectedOptions;
    delete temp[currentAreaCheckId];
    saveToLocalStorage(currentAreaChecks, temp, currentInspectionCategories);
    setSelectedOptions(() => temp);
    validateCanSave();
  };

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttinPermits(false);
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    hasReviewedCuttingPermits: boolean,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttinPermits(hasReviewedCuttingPermits);
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    hasReviewedCuttingPermits: boolean,
    signatureData: string,
  ) => {
    if (!shiftPicker.currentBorerShiftId) {
      errorNotification("Can't save signature because no borer shift found was found");
      return;
    }

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntity(),
      signatureType: SignatureType.AREA_CHECK,
      siteEmployeeId: customName ? undefined : employee?.id,
      personnelName: customName,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: shiftPicker.currentBorerShiftId,
      signatureId: signature.id,
      reviewedCuttingPermits: hasReviewedCuttingPermits,
      purposeOfVisit: null,
      reviewedSafeWorkPlan: null,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  const errorSaving = () => {
    errorNotification(`${areaCheckName} could not save. Please try again.`);
    setSignatureModalOpen(false);
    setIsSaving(false);
  };

  const onSaveSignatureAndAreaCheck = async (
    employee: Employee,
    customName: string,
    hasReviewedCuttingPermits: boolean,
    signatureData: string,
  ) => {
    setIsSaving(true);

    // Save the Signature
    let signature;
    try {
      signature = await onSaveSignature(employee, customName, false, signatureData);
    } catch (error) {
      console.log(
        '🚀 ~ file: AddAreaCheck.tsx ~ line 466 ~ onSaveSignatureAndAreaCheck ~ error',
        error,
      );
      return errorSaving();
    }

    if (!signature || !inspectionDetails || !shiftPicker.currentBorerShiftId) {
      return errorSaving();
    }

    const areaCheckHazards = currentAreaChecks
      .filter(x => selectedOptions[x.id] !== undefined) // only if there's a selection
      .map(x => {
        // save associated hazard ids
        return {
          id: x.id,
          displayOrder: currentInspectionCategories.find(y => y.id === x.id)?.displayOrder,
          categoryId: x.categoryId,
          hazards: x.hazards.map(y => y.id),
        };
      });

    // Generate Area check inspection object
    const inspectionResult = JSON.stringify({
      areaCheckConfig: inspectionDetails,
      areaCheckSelections: selectedOptions,
      areaCheckHazards,
    });

    const doc: InspectionResult = {
      ...generateBaseEntityWithCreatedOn(),
      inspectionDetail: inspectionResult,
      signatureId: signature.signatureId,
      inspectionId: inspectionDetails.inspection.id,
      borerShiftId: shiftPicker.currentBorerShiftId,
    };

    // Save Inspection
    try {
      await inspectionResultCollection?.insert(doc);
    } catch (error) {
      console.log('🚀 ~ file: AddAreaCheck.tsx ~ line 507 ~ error', error);
      return errorSaving();
    }

    // Close this page
    successNotification(`${areaCheckName} added`);
    if (currentAreaChecks.filter(x => selectedOptions[x.id] !== undefined).length > 0) {
      setPanelDrawingModalOpen(true);
      clearLocalStorage(true);
    }
  };

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
  };

  const onDiscardDraft = () => {
    setDiscardOpen(false);
    history.push('/area-check');
    errorNotification(`${areaCheckName} draft discarded`, {
      key: UNDO_SNACKBAR_KEY,
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            closeSnackbar(key);
            returnToAddAreaCheck();
          }}
          id="undo-discard-area-check-button"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  const hasAssociatedHazards = (id: string): boolean => {
    const ac = currentAreaChecks.find(x => x.id === id);
    return (ac && ac.hazards.length > 0) || false;
  };

  const getAssociatedHazard = (id: string): GroundHazardDocument | undefined => {
    const ac = currentAreaChecks.find(x => x.id === id);
    return ac && ac?.hazards.length > 0 ? ac.hazards[0] : undefined;
  };

  const isLatestCategory = (categoryId: string, displayOrder: number) => {
    const displayOrders = currentInspectionCategories
      .filter(x => x.category.id === categoryId)
      .map(y => y.displayOrder);
    return displayOrder === Math.max(...displayOrders);
  };

  const getSelectedToggleValueFor = (
    category: AreaCheckInspectionCategory,
  ): InspectionOptionsDocument => {
    const val = selectedOptions[category.id]?.option || '';
    return val;
  };

  const setupHazardForComment = async (id: string) => {
    const ac = currentAreaChecks.find(x => x.id === id);
    const gc = await groundHazardCollection.findOne().where('id').eq(ac?.hazards[0].id).exec();
    setCurrentAreaCheckId(id);
    setHazard(gc);
  };

  const onDeleteCheck = (areaCheckId: string) => {
    const updatedAreaChecks = currentAreaChecks.filter(x => x.id !== areaCheckId);
    setCurrentAreaChecks(updatedAreaChecks);

    const updatedCategories = currentInspectionCategories.filter(x => x.id !== areaCheckId);
    setCurrentInspectionCategories(updatedCategories);

    const tempSelectedOptions = selectedOptions;
    delete selectedOptions[areaCheckId];
    setSelectedOptions(tempSelectedOptions);

    saveToLocalStorage(updatedAreaChecks, tempSelectedOptions, updatedCategories);
  };

  const onCommentClicked = (id: string) => {
    setupHazardForComment(id);
    setAddCommentOpen(true);
  };

  const onAddAnotherCheck = (category: AreaCheckInspectionCategory) => {
    const nextDisplayOrder =
      currentInspectionCategories
        .sort((a, b) => b.displayOrder - a.displayOrder)
        .find(x => {
          return x.category.id === category.category.id;
        })?.displayOrder || 0;

    const id = uuidv4();

    const newAreaCheck: AreaCheck = {
      id,
      categoryId: category.category.id,
      hazards: [],
      isOriginal: false,
    };

    const newInspectionCategory: AreaCheckInspectionCategory = {
      id,
      isOriginal: false,
      displayOrder: nextDisplayOrder + 1,
      category: category.category,
      options: category.options,
    };

    const tempAreaChecks = currentAreaChecks;
    tempAreaChecks.push(newAreaCheck);
    setCurrentAreaChecks(tempAreaChecks);
    setCurrentInspectionCategories(old => [...old, newInspectionCategory]);
    saveToLocalStorage(tempAreaChecks, selectedOptions, [
      ...currentInspectionCategories,
      newInspectionCategory,
    ]);
  };

  const onPanelDrawingEditModalClose = useCallback(() => {
    setPanelDrawingModalOpen(false);
    navigateToAreaCheckSummary();
  }, []);

  const getHazardImagesNames = async (attachments: GroundHazardAttachment[]) => {
    setExistingAttachmentFileInfo(attachments);
  };

  useEffect(() => {
    if (groundHazardAttachmentsInitialized && hazard) {
      const queryObj = {
        selector: {
          fileName: { $regex: `.*${hazard.id}*.` },
        },
      };
      subscribeToGroundHazardAttachments(getHazardImagesNames, queryObj);
    }
  }, [groundHazardAttachmentsInitialized, hazard]);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container className={classes.topBar}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="body2" data-testid="areaCheckName">
              {areaCheckName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                onClose(false);
              }}
              data-cy="closeDialogButton"
              id="close-dialog-button"
            >
              <Icons.XFeather strokeWidth={1} color="primary" />
            </IconButton>
          </Grid>
        </Grid>

        {inspectionDetails && locationsInitialized && currentAreaChecks && (
          <AddAreaCheckCard
            inspectionCategories={currentInspectionCategories}
            areaChecks={currentAreaChecks}
            lastUpdatedAt={lastUpdatedAt}
            locations={locations}
            isLatestCategory={isLatestCategory}
            hasAssociatedHazards={hasAssociatedHazards}
            getAssociatedHazard={getAssociatedHazard}
            onAddAnotherCheck={onAddAnotherCheck}
            onCommentClicked={onCommentClicked}
            onDeleteCheck={onDeleteCheck}
            getSelectedToggleValueFor={getSelectedToggleValueFor}
            handleToggleChange={handleToggleChange}
          />
        )}

        {!inspectionDetails && (
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignContent="center"
            className={classes.progress}
          >
            <CircularProgress className={classes.spinner} />
          </Grid>
        )}

        <Grid container spacing={2} justify="space-between" className={classes.footerButtonActions}>
          <Grid item>
            <Button
              onClick={() => {
                onClose(false);
              }}
              variant="outlined"
              color="primary"
              noMinHeight
              id="cancel-area-check-button"
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSaveClicked}
              variant="contained"
              color="primary"
              noMinHeight
              disabled={!canSave || isSaving}
              isLoading={isSaving}
              id="save-area-check-button"
              data-testid="area-save-btn"
            >
              {i18n.t('Save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AddSignatureModal
        open={signatureModalOpen}
        type={SignatureType.AREA_CHECK}
        onCancel={() => {
          setSignatureModalOpen(false);
          clearSignatureData();
        }}
        onSave={onSaveSignatureAndAreaCheck}
        onUpdate={onUpdateSignature}
        employees={eligibleEmployees}
        selectedEmployee={selectedEmployee}
        selectedCustomName={selectedCustomName}
        nameNotInList={nameNotInList}
        reviewedCuttingPermits={reviewedCuttingPermits}
        disableEnforceFocus
        cardText={`Add your signature to verify that you have reviewed the ${areaCheckName}`}
      />
      <AddHazardsSidePanel
        open={addHazardOpen}
        hazardConditionType={hazardCondition}
        onClose={() => {
          setAddHazardOpen(false);
        }}
        onOpen={() => {
          setAddHazardOpen(true);
        }}
        onNewHazardCreated={onNewHazardCreated}
        onCancel={onCancelHazardCreation}
        inspectionId={inspectionDetails?.inspection.id}
      />
      {hazard && (
        <AddCommentSidePanel
          open={addCommentOpen}
          onClose={() => {
            setAddCommentOpen(false);
          }}
          onOpen={() => {
            setAddCommentOpen(true);
          }}
          conditionId={hazard?.id}
          groundHazard={hazard}
          attachmentPrefix={`Borer_${selectedOptions[currentAreaCheckId]?.option?.description}_`}
          onHazardUpdated={onHazardUpdated}
          existingAttachmentFileInfo={existingAttachmentFileInfo}
        />
      )}
      {discardOpen && (
        <DiscardDraftModal
          open={discardOpen}
          onCancel={onCancelDiscardDraft}
          onDiscard={onDiscardDraft}
          disableEnforceFocus
        />
      )}
      {panelDrawingModalOpen && miningMethod && (
        <PanelDrawingEditModal
          open={panelDrawingModalOpen}
          onClose={onPanelDrawingEditModalClose}
          miningMethod={miningMethod}
          saveEnabledByDefault={true}
        />
      )}
      {panelDrawingsLoaded && inspectionDetails && !currentPanelDrawing && (
        <MissingPanelDrawingDialog onClose={navigateToAreaCheckSummary} />
      )}
    </>
  );
};

export default observer(AddAreaCheck);
