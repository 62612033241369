import { FetchResult } from '@apollo/client';

import { client, gql } from './Client';

const closeBorerShiftMutation = gql`
  mutation closeBorerShift($input: CloseBorerShiftInput) {
    closeBorerShift(input: $input) {
      siteId
    }
  }
`;

export interface CloseBorerShiftInput {
  id: string;
  closedOn: string;
}

const closeBorerShift = (
  closeBorerShiftInput: CloseBorerShiftInput,
): Promise<FetchResult<string>> =>
  client.mutate({
    mutation: closeBorerShiftMutation,
    variables: { input: closeBorerShiftInput },
  });

export { closeBorerShift };
