import type { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      backgroundSize: 'cover !important',
    },
    loading: {
      height: '100%',
    },
    photoCardAdd: {
      width: '100%',
      height: '100%',
      border: `1px dashed ${CustomPalette.elevation.dp8}`,
      borderRadius: '4px',
      backgroundColor: CustomPalette.elevation.dp1,
      color: theme.palette.text.secondary,
    },
    icon: {
      marginBottom: '3px',
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    thumbnailText: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default useStyles;
export { useStyles };
