import { useSnackbar } from '@nutrien/cxp-components';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  type SnackbarOptions = Parameters<typeof enqueueSnackbar>[1];

  return {
    enqueueSnackbar,
    closeSnackbar,
    defaultNotification: (notificationText: string, options?: SnackbarOptions) => {
      const hasAction = options?.action !== undefined;

      return enqueueSnackbar(notificationText, {
        variant: 'default',
        persist: false,
        preventDuplicate: true,
        autoHideDuration: notificationText.length * (hasAction ? 200 : 100),
        ...options,
      });
    },
    successNotification: (notificationText: string, options?: SnackbarOptions) => {
      const hasAction = options?.action !== undefined;

      return enqueueSnackbar(notificationText, {
        variant: 'success',
        persist: false,
        preventDuplicate: true,
        autoHideDuration: notificationText.length * (hasAction ? 200 : 100),
        ...options,
      });
    },
    warningNotification: (notificationText: string, options?: SnackbarOptions) => {
      const hasAction = options?.action !== undefined;

      return enqueueSnackbar(notificationText, {
        variant: 'warning',
        persist: false,
        preventDuplicate: true,
        autoHideDuration: notificationText.length * (hasAction ? 200 : 100),
        ...options,
      });
    },
    errorNotification: (notificationText: string, options?: SnackbarOptions) => {
      const hasAction = options?.action !== undefined;
      return enqueueSnackbar(notificationText, {
        variant: 'error',
        persist: false,
        preventDuplicate: true,
        autoHideDuration: notificationText.length * (hasAction ? 200 : 100),
        ...options,
      });
    },
  };
};

export default useNotification;
