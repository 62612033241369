import { authenticatedFetch } from '@nutrien/cxp-api-fetch-module';
import isPromise from 'is-promise';

const DEBUG = false;
/**
 * Calls the API through the orchestrator using the endpoint and request initializer
 *
 * @param {string} endpoint
 * @param {*} request
 * @returns
 */
export const callAPI = async (endpoint: string, requestInit: any) => {
  try {
    if (DEBUG) console.log(endpoint, requestInit);
    const response = await authenticatedFetch(endpoint, requestInit);
    if (DEBUG) console.log(endpoint, response);

    const data = await response.json();
    if (DEBUG) console.log(endpoint, data);
    if (data.success || data.successful || response.ok) {
      return data;
    }
    throw new Error(data.errorMessage);
  } catch (error) {
    if (DEBUG) console.log(endpoint, error);

    if (isPromise(error)) {
      const result: any = await error;
      if (DEBUG) console.log(endpoint, result);
      if ('errorMessage' in result) throw new Error(result.errorMessage);
    }

    throw error;
  }
};
