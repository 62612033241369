import { OVERRIDE_SITE_ID_KEY } from '@nutrien/minesight-utility-module';
import { flow, types } from 'mobx-state-tree';

import {
  DifferentSiteIdError,
  MissingSiteIdError,
  MultipleSiteIdError,
  NoUserPermissionsError,
} from '@/utilities/minesightErrors';

import { getUserProfileAPI } from '../../api/profileAPI';
import { Operation } from '../../utilities/apiStatus';
import {
  ALLAN_FIXED_SITE_ID,
  CORY_FIXED_SITE_ID,
  LANIGAN_FIXED_SITE_ID,
  OVERRIDE_SITE_ID,
  ROCANVILLE_FIXED_SITE_ID,
  USER_EMAIL_ID,
  USER_PERMISSION_KEY,
  VANSCOY_FIXED_SITE_ID,
} from '../../utilities/constants';

export interface PermissionType {
  permissionId: number;
  feature: string;
  attribute: string;
  operation: Operation;
}

export interface GroupType {
  securityGroupId: number;
  name: string;
  permissions: PermissionType[];
}

export interface ProfileType {
  userId: number;
  email: string;
  groups: GroupType[];
}

const Permission = types.model({
  permissionId: types.identifierNumber,
  feature: types.string,
  attribute: types.string,
  operation: types.enumeration('Operation', [Operation.READ, Operation.WRITE]),
});

const Group = types.model({
  securityGroupId: types.identifierNumber,
  name: types.string,
  permissions: types.array(Permission),
});

const Profile = types.model({
  userId: types.identifierNumber,
  email: types.string,
  groups: types.array(Group),
});

export const User = types
  .model({
    Profile: types.maybe(Profile),
    PermissionStrings: types.array(types.string),
    siteId: types.maybeNull(types.string),
  })
  .actions(self => ({
    getProfile: flow(function* () {
      const { user } = yield getUserProfileAPI();

      self.Profile = user;
      localStorage.setItem(USER_EMAIL_ID, user?.email);

      const PermissionStrings: string[] = [];

      if (!user.groups?.length) throw NoUserPermissionsError;

      self.Profile?.groups.forEach(group =>
        group.permissions.forEach(permissions => {
          PermissionStrings.push(
            `${permissions.feature}-${permissions.attribute}-${permissions.operation}`,
          );
        }),
      );

      self.PermissionStrings.replace(PermissionStrings);
      localStorage.setItem(USER_PERMISSION_KEY, JSON.stringify(PermissionStrings));

      if (user.siteIds?.length) {
        if (user.siteIds.length > 1) throw MultipleSiteIdError;
        const overrideSiteId = user.siteIds[0];
        if (!!self.siteId && self.siteId !== overrideSiteId) {
          // User configuration has changed and a new site is being assigned
          throw DifferentSiteIdError;
        }
        localStorage.setItem(OVERRIDE_SITE_ID, overrideSiteId);
        self.siteId = overrideSiteId;
      } else {
        throw MissingSiteIdError;
      }
    }),
    hasPermissionTo: (key: string): boolean => {
      let result = false;
      const userPermissions = localStorage.getItem(USER_PERMISSION_KEY);
      if (typeof userPermissions === 'string') {
        JSON.parse(userPermissions).forEach((permissionArray: string[]) => {
          if (permissionArray.includes(key)) result = true;
        });
      }
      return result;
    },
    getSiteName: () => {
      if (self.siteId === VANSCOY_FIXED_SITE_ID) {
        return 'Vanscoy';
      }
      if (self.siteId === CORY_FIXED_SITE_ID) {
        return 'Cory';
      }
      if (self.siteId === ROCANVILLE_FIXED_SITE_ID) {
        return 'Rocanville';
      }
      if (self.siteId === ALLAN_FIXED_SITE_ID) {
        return 'Allan';
      }
      if (self.siteId === LANIGAN_FIXED_SITE_ID) {
        return 'Lanigan';
      }
      return '';
    },
    refreshSiteId: () => {
      const siteId = localStorage.getItem(OVERRIDE_SITE_ID_KEY);
      if (siteId) self.siteId = siteId;
    },
  }));
