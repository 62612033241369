import { SvgIcon } from '@material-ui/core';
import { darkTheme } from '@nutrien/cxp-components';
import React from 'react';

const CustomAvatarIcon = () => (
  <SvgIcon viewBox="-2 -2 24 24" data-cy="svg-icon-AvatarIcon">
    <path
      d="M 2.9544076,2.9107568 C 4.8067208,1.0584644 7.3189567,0.01785703 9.9384873,0.01785703 c 1.9534587,0 3.8630947,0.57927448 5.4873377,1.66457257 1.624296,1.085293 2.890213,2.6278693 3.637796,4.432643 0.747584,1.8047842 0.943148,3.7907329 0.562052,5.7066594 -0.381095,1.915926 -1.3218,3.675848 -2.703121,5.05717 -1.381322,1.381321 -3.141244,2.322026 -5.05717,2.703121 -1.9159257,0.381096 -3.9018744,0.185532 -5.7066586,-0.562052 -1.8047737,-0.747583 -3.34735,-2.0135 -4.432643,-3.637796 -1.0852981,-1.624243 -1.66457258,-3.533879 -1.66457258,-5.4873385 0,-2.6195306 1.04060738,-5.1317665 2.89289978,-6.9840797 z"
      stroke={darkTheme.palette.text.secondary}
      strokeOpacity="0.95"
      fill="none"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M 7.6241553,5.1405266 C 8.0941436,4.6704916 8.6930005,4.350415 9.3448813,4.2207407 9.9968143,4.0910611 10.672555,4.1576167 11.286643,4.411985 c 0.614088,0.2543634 1.138972,0.6851349 1.508267,1.2377779 0.369295,0.552695 0.566367,1.2024443 0.566367,1.8671651 0,0.6646687 -0.197072,1.3144181 -0.566367,1.8671135 -0.369295,0.552643 -0.894179,0.9834355 -1.508267,1.2377935 -0.614088,0.254358 -1.2898287,0.320898 -1.9417617,0.16925 C 8.6930005,10.683384 8.0941436,10.363318 7.6241553,9.8933295 6.9939001,9.2630745 6.6397843,8.4082474 6.6397843,7.516928 c 0,-0.8913715 0.3541158,-1.7461461 0.984371,-2.3764014 z"
      stroke={darkTheme.palette.text.secondary}
      strokeOpacity="0.95"
      fill={darkTheme.palette.text.secondary}
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m 6.7691728,15.300612 c -0.1683246,0.08271 -0.3403919,0.172068 -0.5117316,0.26434 0.1654135,-0.09778 0.3362332,-0.186052 0.5117316,-0.26434 4.7424052,-2.326184 8.0308162,-0.604835 9.0092612,0.04133 -0.590956,-1.074772 -1.470163,-1.963336 -2.538592,-2.565676 -1.068429,-0.60234 -2.283766,-0.894594 -3.5092387,-0.843858 -1.2255253,0.05074 -2.4125304,0.442385 -3.42752,1.130966 -1.0150416,0.688634 -1.8177542,1.646753 -2.3178933,2.766699 1.5812265,1.577041 3.7182931,2.470336 5.9514263,2.487751 2.2331847,0.01736 4.3839227,-0.842507 5.9895037,-2.3947 -1.346076,-0.877232 -2.894422,-1.394993 -4.497352,-1.503952 -1.6029817,-0.109011 -3.2071594,0.194472 -4.6595952,0.881442 z"
      stroke={darkTheme.palette.text.secondary}
      strokeOpacity="0.95"
      fill={darkTheme.palette.text.secondary}
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CustomAvatarIcon;
