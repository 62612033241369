import { RxDocument } from 'rxdb';

import { rootStore } from '@/mobx-models/Root';

import { BorerOperatorChange } from '../BorerOperatorChangeFeed/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../rxdbUtilityFunctions';
import { BorerOperatorState } from './queryBuilder';

// Key is the borerStateId
type OperatorStateRecord = {
  [key: string]: { doc: RxDocument<BorerOperatorState, object>; isRunning: boolean }[];
};

export const getRunningBorerOperatorStatesAfterTimestamp = async (
  startTimestamp: number,
  endTimestamp: number,
): Promise<OperatorStateRecord> => {
  const db = RxdbManager.instance?.db;
  const borerOperatorStateCollection =
    db?.collections[RxdbCollectionName.BORER_OPERATOR_STATE_FEED];
  const docs = await borerOperatorStateCollection
    ?.find({
      selector: {
        $and: [
          {
            startTimeUnix: {
              $gt: startTimestamp,
            },
          },
          {
            startTimeUnix: {
              $lt: endTimestamp,
            },
          },
          {
            showInSchedulerView: true,
          },
        ],
      },
    })
    .exec();

  if (!docs) throw new Error('Docs are null');

  const docsByBorerStateId: { [key: string]: RxDocument<BorerOperatorState, object>[] } = {};
  docs.forEach(doc => {
    if (!docsByBorerStateId[doc.borerStateId]) {
      docsByBorerStateId[doc.borerStateId] = [];
    }
    docsByBorerStateId[doc.borerStateId].push(doc);
  });

  const statesToReturn: OperatorStateRecord = {};

  for await (const borerStateId of Object.keys(docsByBorerStateId)) {
    const hasAnyRunningStates = (
      await Promise.all(
        docsByBorerStateId[borerStateId].map(async state => {
          const borerStateType = await state.populate('borerStateTypeId');
          if (borerStateType.isRunning) return true;
          return false;
        }),
      )
    ).find(isRunning => isRunning);

    if (hasAnyRunningStates) {
      statesToReturn[borerStateId] = [];

      await Promise.all(
        docsByBorerStateId[borerStateId].map(async doc => {
          const borerStateType = await doc.populate('borerStateTypeId');
          statesToReturn[borerStateId].push({ doc, isRunning: borerStateType?.isRunning });
        }),
      );
    }
  }

  return statesToReturn;
};

// When 'apply to all' is clicked, we want to generate new change states with the new cuttingTypeId
export const applyCuttingTypeIdToBorerOperatorState = async (
  docs: OperatorStateRecord,
  newCuttingTypeId: string,
): Promise<void> => {
  const db = RxdbManager.instance?.db;
  const borerOperatorChangeCollection =
    db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];

  if (!rootStore.equipment.selectedBorerId) throw new Error('missing selectedBorerId');
  if (!borerOperatorChangeCollection) throw new Error('missing borerOperatorChangeCollection');

  // For each borerStateId, create a new change state
  for await (const borerStateId of Object.keys(docs)) {
    const changeDoc: BorerOperatorChange = {
      ...generateBaseEntityWithCreatedOn(),
      borerStateId,
      states: docs[borerStateId].map(record => {
        const doc = record.doc;
        return {
          borerShiftAdvanceId: doc.borerShiftAdvanceId,
          startTime: doc.startTime,
          endTime: doc.endTime,
          borerStateTypeId: doc.borerStateTypeId,
          cuttingTypeId: record.isRunning ? newCuttingTypeId : doc.cuttingTypeId,
          comment: doc.comment || '',
          originalId: doc.id,
          failedSync: false,
          isGeneratedState: false,
          cuttingMethodId: doc.cuttingMethodId,
        };
      }),
      borerEquipmentId: rootStore.equipment.selectedBorerId || '',
      showInSchedulerView: true,
      failedSync: false,
      isGeneratedState: false,
    };

    await borerOperatorChangeCollection?.upsert(changeDoc);
  }

  // Hide original state docs from view
  await Promise.all(
    Object.keys(docs).map(async borerStateId => {
      for await (const record of docs[borerStateId]) {
        await record.doc.update({
          $set: {
            showInSchedulerView: false,
            updatedAt: getUnixMillisecondTimestamp(),
          },
        });
      }
    }),
  );
};
