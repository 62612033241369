import * as yup from 'yup';

export interface DescriptionWithId {
  id: string;
  [key: string]: string;
}

export const RoomConditionCommentSchema = yup.object({
  panel: yup
    .object()
    .shape({
      id: yup.string(),
      description: yup.string(),
    })
    .required('Required')
    .nullable(),
  name: yup
    .object()
    .shape({
      id: yup.string(),
      fullName: yup.string(),
    })
    .required('Required')
    .nullable(),
  comment: yup.string().required('Required').nullable(),
});

export type RoomConditionCommentSchemaType = yup.TypeOf<typeof RoomConditionCommentSchema>;
