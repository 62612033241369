import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface CuttingMethod extends BaseEntity {
  readonly description: string;
  readonly displayOrder: number | null;
}
export type CuttingMethodCollection = RxCollection<CuttingMethod> | null;
export type CuttingMethodDocument = RxDocument<CuttingMethod>;

export const cuttingMethodSchema: RxJsonSchema<CuttingMethod> = {
  type: 'object',
  version: 0,
  description: 'describes a CuttingMethod object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    displayOrder: { type: ['number', 'null'] },
  },
};

export const cuttingMethodMigrationStrategy: MigrationStrategies = {};

const cuttingMethodFeedQuery = print(gql`
  query cuttingMethodFeed($limit: Int!, $lastUpdateAt: Float!, $siteId: ID!) {
    cuttingMethodFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      description
      displayOrder
      isDeleted
      id
      updatedAt
      version
    }
  }
`);

export const cuttingMethodPullQueryBuilder = generatePullQuery(
  cuttingMethodFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
