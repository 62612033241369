import { group, sort } from 'radash';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useOnlineStatus } from '../../utilities';
import { getSurveyPointInt } from '../../utilities/utilityFunctions';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useSurveyPoints from '../SurveyPoints/useSurveyPoints';
import { getDocsForSurveyPointWithCarryForwards } from './documentUtilities';
import { DocumentUploadCollection, DocumentUploadDocument, EnrichedDocument } from './queryBuilder';

export interface EnrichedDocumentByDocumentType {
  [key: string]: EnrichedDocument;
}

export const useDocsForLocation = (roomId?: string, surveyPointDesc?: string) => {
  const [docsForLocation, setDocsForLocation] = useState<DocumentUploadDocument[]>([]);
  const [docsForLocationLoaded, setDocsForLocationLoaded] = useState(false);
  const documentUploadCollection: DocumentUploadCollection = useRxCollection(
    RxdbCollectionName.DOCUMENT_UPLOAD,
  );
  const { surveyPointsForRoom } = useSurveyPoints(undefined, roomId);

  const isOnline = useOnlineStatus();

  const augmentAndSortDocuments = async (documents: DocumentUploadDocument[]) => {
    // Augment documents with documentTypeDescription and displayOrder
    await Promise.all(
      documents.map(async document => {
        const documentType = await document.populate('borerDocumentTypeId');
        // Documents with reference documentType won't have description / display order
        if ((!document.description || !document.displayOrder) && documentType) {
          // If stale or missing document decsription - add/update it
          if (document.documentTypeDescription !== documentType.description)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              documentTypeDescription: documentType.description,
            }));
          if (document.displayOrder !== documentType.displayOrder)
            document.incrementalModify(oldDoc => ({
              ...oldDoc,
              displayOrder: documentType.displayOrder,
            }));
        }
        return document;
      }),
    );

    return documents
      .filter(doc => doc !== null)
      .sort(
        (a, b) =>
          (a?.displayOrder === undefined ? 1 : 0) - (b?.displayOrder === undefined ? 1 : 0) ||
          +(a?.displayOrder > b?.displayOrder) ||
          -(a?.displayOrder < b?.displayOrder),
      );
  };

  useEffect(() => {
    let sub: Subscription | undefined;

    const getDocuments = async () => {
      if (!documentUploadCollection || !roomId) {
        setDocsForLocation([]);
        setDocsForLocationLoaded(true);
        return;
      }

      try {
        const querySelector = {
          selector: {
            roomId,
          },
        };

        sub = documentUploadCollection?.find(querySelector).$.subscribe(async docs => {
          // 1. All docs for given room
          const augmentedDocs = await augmentAndSortDocuments(docs);

          // If we know surveyPointDesc
          if (augmentedDocs.length && surveyPointDesc) {
            // convert docs to object with surveyPointId as key and surveyPointDesc as value

            const roomSurveyPointsToInt = {};
            surveyPointsForRoom.forEach(sp => {
              roomSurveyPointsToInt[sp.id] = getSurveyPointInt(sp.description);
            });

            // 2. Organize them by survey point and sort from largest to smallest
            const docsBySurveyPoint = group(augmentedDocs, doc => {
              return roomSurveyPointsToInt[doc.surveyPointId];
            });

            const docsForSurveyPoint = getDocsForSurveyPointWithCarryForwards(
              surveyPointDesc,
              docsBySurveyPoint,
              roomSurveyPointsToInt,
            );

            const sortedDocs = sort(docsForSurveyPoint, doc => doc.displayOrder ?? 0);
            setDocsForLocation(sortedDocs);
          } else if (docs.length) {
            // If we don't know surveyPointDesc
            setDocsForLocation(augmentedDocs);
          } else {
            setDocsForLocation([]);
          }
          setDocsForLocationLoaded(true);
        });
      } catch (error) {
        setDocsForLocation([]);
        console.log(error);
      }
    };
    getDocuments();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
    };
  }, [documentUploadCollection, roomId, surveyPointDesc, surveyPointsForRoom, isOnline]);

  return {
    docsForLocation,
    docsForLocationLoaded,
  };
};

export default useDocsForLocation;
