import { useFlags } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import postal from 'postal';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Borer } from '../../graphql/Equipment';
import { rootStore, useMst } from '../../mobx-models/Root';
import BorerSelector from '../BorerSelector';

const Home: React.FC = () => {
  const { rehabBorersVisible } = useFlags()?.flags;

  const { equipment } = useMst();
  const history = useHistory();

  const [borerSelectOpen, setBorerSelectOpen] = useState(equipment.selectedBorerId === undefined);

  const setup = useCallback(async () => {
    try {
      // Only call if we don't have the list of borers from previous login step (ie page refresh)
      if (!equipment.getBorerList(true, true).length) {
        await equipment.fetchBorerList();
      }
    } catch (error) {
      console.log('🚀 ~ file: Home.tsx ~ line 36 ~ setup ~ error', error);
    }

    if (equipment.selectedBorerId !== undefined) {
      history.push('/initializing?initialLoad=true');
    }
  }, [equipment]);

  useEffect(() => {
    setup();
  }, [setup]);

  const assignBorer = (borer: Borer) => {
    equipment.selectBorer(borer.id);
    setBorerSelectOpen(false);

    Sentry.setContext('siteMetadata', {
      borerName: borer.shortName,
      siteName: rootStore.user.getSiteName(),
      siteId: rootStore.user.siteId,
    });

    postal.publish({
      channel: 'borer',
      topic: 'borer.selected',
    });

    history.push('/initializing?initialLoad=true');
  };

  return (
    <>
      <BorerSelector
        open={borerSelectOpen}
        listOfBorers={equipment.getBorerList(true, rehabBorersVisible)}
        assignBorer={assignBorer}
      />
    </>
  );
};

export default observer(Home);
