import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '@/mobx-models/Root';

import useBorerShift from '../BorerShift/useBorerShift';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity } from '../rxdbUtilityFunctions';
import { ServiceStatus, ServiceStatusCollection } from '../ServiceStatus/queryBuilder';
import useShifts from '../Shifts/useShifts';
import { BorerShiftInfo, BorerShiftInfoCollection, BorerShiftInfoDocument } from './queryBuilder';

export const useBorerShiftInfo = (borerShiftId?: string | null) => {
  const { shiftPicker } = useMst();

  const borerShiftIdForQuery = useMemo(
    () => borerShiftId || shiftPicker.currentBorerShiftId,
    [borerShiftId, shiftPicker.currentBorerShiftId],
  );

  const borerShiftInfoQueryConstructor = useCallback(
    (collection: any) => {
      return collection.find({
        selector: {
          borerShiftId: borerShiftIdForQuery,
        },
      });
    },
    [borerShiftIdForQuery],
  );

  const { result: borerShiftInfoArray }: { result: BorerShiftInfoDocument[] } = useRxData(
    RxdbCollectionName.BORER_SHIFT_INFO,
    borerShiftInfoQueryConstructor,
  );

  const borerShiftInfoDoc = borerShiftInfoArray?.[0];

  const { getPreviousShift } = useShifts();
  const { getCurrentBorerShiftId } = useBorerShift();

  const borerShiftInfoCollection: BorerShiftInfoCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_INFO,
  );
  const borerStatusCollection: ServiceStatusCollection = useRxCollection(
    RxdbCollectionName.SERVICE_STATUS,
  );

  const [initialLoadComplete, setLoadComplete] = useState(false);
  // Key values for cards
  const [serviceStatus, setServiceStatus] = useState<string | null>(null);
  const [rotorBits, setRotorBits] = useState<number | null>(null);
  const [trimBits, setTrimBits] = useState<number | null>(null);
  const [cornerBits, setCornerBits] = useState<number | null>(null);
  const [shearPins, setShearPins] = useState<number | null>(null);
  const [belt, setBelt] = useState<number | null>(null);
  const [breaks, setBreaks] = useState<string | null>(null);
  const [cable, setCable] = useState<number | null>(null);
  const [startMeterHours, setStartMeterHours] = useState<number | null>(null);
  const [endMeterHours, setEndMeterHours] = useState<number | null>(null);
  const [serviceStatusId, setServiceStatusId] = useState<string | null>(null);
  const [serviceStatusList, setServiceStatusList] = useState<ServiceStatus[]>([]);
  const [bottomChainShearPinsReplaced, setBottomChainShearPinsReplaced] = useState<number | null>(
    null,
  );
  const [topChainShearPinsReplaced, setTopChainShearPinsReplaced] = useState<number | null>(null);
  const [cableSlackEnd, setCableSlackEnd] = useState<string | null>(null);
  const [oreLineHeight, setOreLineHeight] = useState<number | null>(null);
  const [breakthroughFaceFootage, setBreakthroughFaceFootage] = useState<string | null>(null);
  const [oreGrade, setOreGrade] = useState<number | null>(null);
  const [gprIsWorking, setGprIsWorking] = useState<boolean | null>(null);

  const getBorerShiftInfoByBorerShiftId = async (id: string) => {
    if (!borerShiftInfoCollection) throw new Error('BorerShiftInfoCollection not initialized');

    try {
      const borerShiftInfo = await borerShiftInfoCollection
        .findOne()
        .where('borerShiftId')
        .eq(id)
        .exec();
      return borerShiftInfo;
    } catch (error) {
      console.log(
        '🚀 ~ file: useBorerShiftInfo.ts ~ line 82 ~ getBorerShiftInfoByBorerShiftId ~ error',
        error,
      );
      throw error;
    }
  };

  const getStartMeterHours = async () => {
    const previousShift = await getPreviousShift();
    if (!previousShift) {
      setStartMeterHours(null);
      return;
    }
    const previousBorerShiftId = await getCurrentBorerShiftId(previousShift.id);
    if (!previousBorerShiftId) {
      setStartMeterHours(null);
      return;
    }
    const previousBorerShiftInfo = await getBorerShiftInfoByBorerShiftId(previousBorerShiftId);
    if (previousBorerShiftInfo) {
      setStartMeterHours(previousBorerShiftInfo.meterHours);
    } else {
      setStartMeterHours(null);
    }
  };

  /**
   * Takes borerShiftInfoDoc and stores value in state. Uses hook argument as borerShiftId, otherwise currentBorerShiftId
   *
   * @return {*}
   */
  const getBorerShiftInfo = async () => {
    try {
      setLoadComplete(false);

      const borerServiceStatus = await borerStatusCollection?.find().sort('updatedAt').exec();
      const serviceStatusInfo = await borerShiftInfoDoc?.populate('serviceStatusId');

      if (serviceStatusInfo) {
        setServiceStatus(serviceStatusInfo.description);
      } else {
        setServiceStatus(null);
      }

      if (borerServiceStatus) {
        setServiceStatusList(borerServiceStatus);
      }

      if (borerShiftInfoDoc) {
        setServiceStatusId(borerShiftInfoDoc?.serviceStatusId);
        setRotorBits(borerShiftInfoDoc?.rotorBits);
        setTrimBits(borerShiftInfoDoc?.trimBits);
        setCornerBits(borerShiftInfoDoc?.cornerBits);
        setBelt(borerShiftInfoDoc?.belt);
        setBreaks(borerShiftInfoDoc?.breaks);
        setCable(borerShiftInfoDoc?.cable);
        setBottomChainShearPinsReplaced(borerShiftInfoDoc?.bottomChainShearPinsReplaced);
        setTopChainShearPinsReplaced(borerShiftInfoDoc?.topChainShearPinsReplaced);
        setShearPins(borerShiftInfoDoc.shearPins);
        setCableSlackEnd(borerShiftInfoDoc.cableSlackEnd);
        setOreLineHeight(borerShiftInfoDoc.oreLineHeight);
        setBreakthroughFaceFootage(borerShiftInfoDoc.breakthroughFaceFootage);
        setOreGrade(borerShiftInfoDoc.oreGrade);
        setEndMeterHours(borerShiftInfoDoc?.meterHours);
        setGprIsWorking(borerShiftInfoDoc?.gprIsWorking);
      } else {
        setRotorBits(null);
        setTrimBits(null);
        setCornerBits(null);
        setBelt(null);
        setBreaks(null);
        setCable(null);
        setEndMeterHours(null);
        setShearPins(null);
        setBottomChainShearPinsReplaced(null);
        setTopChainShearPinsReplaced(null);
        setCableSlackEnd(null);
        setOreLineHeight(null);
        setBreakthroughFaceFootage(null);
        setOreGrade(null);
        setGprIsWorking(null);
      }
      await getStartMeterHours();
      setLoadComplete(true);
    } catch (error) {
      console.log('🚀 ~ file: useBorerShiftInfo.ts ~ line 33 ~ getBorerShiftInfo ~ error', error);
      throw error;
    }
  };

  useEffect(() => {
    getBorerShiftInfo();
  }, [borerShiftInfoDoc]);

  const saveMeterHours = (meterHours: number) => {
    if (borerShiftInfoDoc) {
      return borerShiftInfoDoc.update({
        $set: {
          meterHours: meterHours || null,
        },
      });
    } else {
      throw new Error('Missing borerShiftInfoDoc');
    }
  };

  const saveBorerShiftInfo = async (
    rotorBitsValue: number,
    trimBitsValue: number,
    cornerBitsValue: number,
    oreLineHeightValue: number | null,
    cableSlackEndValue: string | null,
    breakthroughFaceFootageValue: string | null,
    oreGradeValue: number | null,
    gprIsWorkingValue: boolean | null,
    beltValue?: number,
    cableValue?: number,
    shearPinsValue?: number,
    serviceBorerStatusId?: string,
    bottomChainShearPinsReplacedValue?: number,
    topChainShearPinsReplacedValue?: number,
  ) => {
    if (borerShiftInfoDoc) {
      return borerShiftInfoDoc.update({
        $set: {
          rotorBits: rotorBitsValue,
          trimBits: trimBitsValue,
          cornerBits: cornerBitsValue,
          belt: beltValue || beltValue === 0 ? beltValue : null,
          cable: cableValue || cableValue === 0 ? cableValue : null,
          meterHours: endMeterHours || null,
          shearPins: shearPinsValue !== undefined ? shearPinsValue : shearPins,
          serviceStatusId: serviceBorerStatusId || null,
          bottomChainShearPinsReplaced: bottomChainShearPinsReplacedValue || 0,
          topChainShearPinsReplaced: topChainShearPinsReplacedValue || 0,
          oreLineHeight: oreLineHeightValue || 0,
          cableSlackEnd: cableSlackEndValue || null,
          breakthroughFaceFootage: breakthroughFaceFootageValue || null,
          oreGrade: oreGradeValue || 0,
          gprIsWorking: gprIsWorkingValue,
        },
      });
    }

    if (!borerShiftIdForQuery) throw new Error('Missing borerShiftId');
    if (!borerShiftInfoCollection) throw new Error('Missing borerShiftInfoCollection');

    const newDoc: BorerShiftInfo = {
      ...generateBaseEntity(),
      borerShiftId: borerShiftIdForQuery,
      rotorBits: rotorBitsValue,
      trimBits: trimBitsValue,
      cornerBits: cornerBitsValue,
      belt: beltValue || null,
      cable: cableValue || null,
      breaks: 'Early',
      serviceStatusId: serviceStatusId || null,
      faceSalt: null,
      gprIsWorking: gprIsWorkingValue,
      gprIsWorkingChangedOn: null,
      lastPreventativeMaintenanceOn: null,
      lastServicedAtPanelId: null,
      lastServicedOn: null,
      lastTrimChainChangedOn: null,
      leachAnomaly: null,
      oilLevel: null,
      onMound: null,
      parkedForMaintenance: null,
      phoneNumber: null,
      shearPins: shearPinsValue || null,
      bottomChainShearPinsReplaced: bottomChainShearPinsReplacedValue || null,
      topChainShearPinsReplaced: topChainShearPinsReplacedValue || null,
      oreLineHeight: oreLineHeightValue || 0,
      cableSlackEnd: cableSlackEndValue || null,
      breakthroughFaceFootage: breakthroughFaceFootageValue || null,
      oreGrade: oreGradeValue || 0,
    };

    return borerShiftInfoCollection.insert(newDoc);
  };

  return {
    borerShiftInfoCollection,
    getBorerShiftInfo,
    rotorBits,
    trimBits,
    cornerBits,
    shearPins,
    belt,
    cable,
    breaks,
    serviceStatus,
    saveBorerShiftInfo,
    initialLoadComplete,
    startMeterHours,
    endMeterHours,
    saveMeterHours,
    serviceStatusList,
    serviceStatusId,
    bottomChainShearPinsReplaced,
    topChainShearPinsReplaced,
    cableSlackEnd,
    oreLineHeight,
    breakthroughFaceFootage,
    oreGrade,
    borerShiftInfoDoc,
    gprIsWorking,
  };
};

export default useBorerShiftInfo;
