import { useEffect, useState } from 'react';
// Define general type for useWindowSize hook, which includes width and height
export interface Size {
  width: number;
  height: number;
  portrait: boolean;
}

// Hook
export const useWindowSize = (): Size => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.outerWidth,
    height: window.outerHeight,
    portrait: window.outerHeight > window.outerWidth,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight,
        portrait: window.outerHeight > window.outerWidth,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
