import { print } from 'graphql';
import gql from 'graphql-tag';
import { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '@/models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '@/rxdb/queryBuilders/generatePullQuery';
import RxdbCollectionName from '@/rxdb/rxdbCollectionName';
import { defaultDocAllTime } from '@/rxdb/Shared/defaultDoc';

export interface BorerDocumentUpload extends BaseEntity {
  siteId: string;
  borerDocumentTypeId: string | null;
  borerEquipmentId: string;
  attachmentId: string;
  fileName: string;
  description: string | null;
  startDate: string;
  endDate: string;
  isActive: boolean;
  createdOn: string;

  // Local properties
  isCached: boolean;
  isLoading: boolean;
  retryCount: number;
  documentTypeDescription: string | null;
  displayOrder: number | null;
}
export type BorerDocumentUploadCollection = RxCollection<BorerDocumentUpload> | null;
export type BorerDocumentUploadDocument = RxDocument<BorerDocumentUpload>;

export const borerDocumentUploadSchema: RxJsonSchema<BorerDocumentUpload> = {
  type: 'object',
  version: 0,
  description: 'describes a borerDocumentUpload object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    siteId: { type: 'string' },
    borerDocumentTypeId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.DOCUMENT_TYPES,
    },
    borerEquipmentId: { type: 'string' },
    attachmentId: { type: 'string' },
    fileName: { type: 'string' },
    description: { type: ['string', 'null'] },
    startDate: { type: 'string' },
    endDate: { type: 'string' },
    isActive: { type: 'boolean' },
    createdOn: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    // Local properties
    isCached: { type: 'boolean' },
    isLoading: { type: 'boolean' },
    retryCount: { type: 'number' },
    documentTypeDescription: { type: ['string', 'null'] },
    displayOrder: { type: ['number', 'null'] },
  },
};

export const borerDocumentMigrationStrategies: MigrationStrategies = {
  // do nothing - brand new, not needed yet
};

const borerDocumentUploadFeedQuery = print(gql`
  query borerDocumentFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerDocumentFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      isDeleted
      updatedAt
      version
      attachmentId
      borerDocumentTypeId
      borerEquipmentId
      fileName
      description
      startDate
      endDate
      isActive
      createdOn
    }
  }
`);

export const borerDocumentUploadPullQueryBuilder = generatePullQuery(
  borerDocumentUploadFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export const borerDocumentUploadPullModifier = (doc: BorerDocumentUpload) => {
  const result = {
    ...doc,
    isCached: false,
    isLoading: false,
    retryCount: 0,
  };
  return result;
};
