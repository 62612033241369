import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useMst } from '@/mobx-models/Root';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { InspectionResultDocument } from '@/rxdb/InspectionResults/queryBuilder';
import { PreOpResult } from '@/rxdb/InspectionResults/types';
import { InspectionResultItem } from '@/rxdb/InspectionResults/useInspectionResults';
import { EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION, InspectionType } from '@/utilities/constants';
import { jsonMapReviver } from '@/utilities/utilityFunctions';

import AddDeficiencySidePanel from '../../AddDeficiencySidePanel';
import AddPreOpCheck from '../../AddPreOpCheck';
import DeficienciesMenu from '../../DeficienciesMenu';
import PreOpCard from './PreOpCard';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    cardContainer: {
      padding: '0px !important',
    },
    deficiencyCard: {
      padding: '16px',
      margin: '0px 8px 8px 8px !important',
    },
  }),
);
type Props = {
  equipmentIsLoading: boolean;
  equipmentPreOpChecks: InspectionResultItem[];
};

const PreOpCheckCard: React.FC<Props> = ({ equipmentIsLoading, equipmentPreOpChecks }) => {
  const classes = useStyles();
  const { shiftPicker, user } = useMst();
  const { isRehabBorer } = useCurrentBorer();
  const { isVanscoy } = useSiteFeatures();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [addDeficiencyOpen, setAddDeficiencyOpen] = useState(false);
  const [currentInspectionType, setCurrentInspectionType] = useState<InspectionType | undefined>();
  // List Pre-Op Checks
  const [preOpResult, setPreOpResult] = useState<PreOpResult>();
  const [signatureId, setSignatureId] = useState('');

  // Controls
  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);
  const onOpenAddDeficiency = () => {
    setAddDeficiencyOpen(true);
    onCloseMenu();
  };
  const onCloseAddDeficiency = () => setAddDeficiencyOpen(false);

  // Add Pre-Op Check
  const [preOpOpen, setPreOpOpen] = useState(false);

  const onOpenPreOp = (inspectionType = InspectionType.PRE_OP) => {
    setCurrentInspectionType(inspectionType);
    setPreOpOpen(true);
    onCloseMenu();
  };

  const onClosePreOp = () => {
    setPreOpOpen(false);
    setPreOpResult(undefined);
  };

  // View PreOp check
  const viewPreOpCheck = (inspectionResult: InspectionResultDocument) => {
    let inspectionDetail: PreOpResult | string | null;

    try {
      inspectionDetail = JSON.parse(inspectionResult.inspectionDetail, jsonMapReviver);
    } catch (error) {
      inspectionDetail = inspectionResult.inspectionDetail;
    }

    if (typeof inspectionDetail === 'string' || inspectionDetail === null) return;
    setSignatureId(inspectionResult.signatureId);
    setPreOpResult(inspectionDetail);
    if (
      inspectionDetail?.preOpConfig?.inspection?.inspectionType &&
      Object.values(InspectionType).includes(
        inspectionDetail.preOpConfig.inspection.inspectionType as InspectionType,
      )
    ) {
      setCurrentInspectionType(
        inspectionDetail.preOpConfig.inspection.inspectionType as InspectionType,
      );
    }
    setPreOpOpen(true);
  };

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <Typography variant="h3">{i18n.t('Checks this shift')}</Typography>
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              {shiftPicker.isCurrentShiftSelected() &&
                user.hasPermissionTo(EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION) && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      noMinHeight
                      startAdornment={<Icons.PlusFeather />}
                      onClick={onOpenMenu}
                      data-cy="add-preop-menu"
                      id="add-preop-menu"
                    >
                      {i18n.t('Add...')}
                    </Button>
                    <DeficienciesMenu
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={onCloseMenu}
                      openAddDeficiency={onOpenAddDeficiency}
                      onAddPreOpCheck={onOpenPreOp}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
          {!(isRehabBorer && isVanscoy) && (
            <Grid item container xs={12} className={classes.cardContainer} justify="center">
              {equipmentIsLoading && <CircularProgress />}
              {!equipmentIsLoading &&
                equipmentPreOpChecks.length > 0 &&
                equipmentPreOpChecks.map(check => (
                  <Grid item xs={12} key={check.inspectionResult.id}>
                    <PreOpCard
                      inspectionDetails={check.inspectionDetails}
                      inspectionResult={check.inspectionResult}
                      onSelected={viewPreOpCheck}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      </Card>
      {addDeficiencyOpen && (
        <AddDeficiencySidePanel
          open={addDeficiencyOpen}
          onClose={onCloseAddDeficiency}
          onOpen={onOpenAddDeficiency}
        />
      )}
      <AddPreOpCheck
        open={preOpOpen}
        onClose={onClosePreOp}
        onOpen={onOpenPreOp}
        viewOnly={
          preOpResult !== undefined ||
          !user.hasPermissionTo(EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION)
        }
        inspectionDetailsSource={preOpResult?.preOpConfig}
        selectedOptionsSource={preOpResult?.preOpSelections}
        createdEquipmentDeficiencies={preOpResult?.preOpDeficiencies}
        signatureId={signatureId}
        inspectionType={currentInspectionType}
      />
    </>
  );
};

export default observer(PreOpCheckCard);
