import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { useDateFormatters } from '../../utilities';
import { EquipmentDocument } from '../Equipment/queryBuilder';
import { EquipmentTypeDocument } from '../EquipmentType/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { WorkOrderCollection, WorkOrderDocument } from './queryBuilder';
import { sortActiveWorkOrders, sortCompletedWorkOrders } from './sortWorkOrders';

export interface WorkOrderWithChildren {
  workOrderDoc: WorkOrderDocument;
  equipmentDoc: EquipmentDocument;
  equipmentTypeDoc: EquipmentTypeDocument;
}

const useWorkOrders = () => {
  const { dateIsBetweenDates } = useDateFormatters();
  const { shiftPicker } = useMst();
  const workOrdersCollection: WorkOrderCollection = useRxCollection(RxdbCollectionName.WORK_ORDERS);

  // Initializer boolean
  const [workOrdersCollectionsInitialized, setCollectionsInitialized] = useState(false);

  useEffect(() => {
    if (workOrdersCollection) setCollectionsInitialized(true);
  }, [workOrdersCollection]);

  /**
   * List active work orders
   *
   * @return {*}  {Promise<WorkOrderWithChildren[]>}
   */
  const listActiveWorkOrders = async (): Promise<WorkOrderWithChildren[]> => {
    if (!workOrdersCollection) return [];

    let workOrderDocs: WorkOrderDocument[];
    try {
      workOrderDocs = await workOrdersCollection
        .find({
          selector: {
            $and: [{ completedDate: { $eq: null } }],
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useWorkOrders.ts ~ line 53 ~ listActiveWorkOrders ~ error', error);
      throw error;
    }

    const populatedRecords: WorkOrderWithChildren[] = await Promise.all(
      workOrderDocs.map(async (doc: WorkOrderDocument) => {
        const equipment = (await doc.populate('equipmentId')) as EquipmentDocument;
        const equipmentType = (await equipment.populate(
          'equipmentTypeId',
        )) as EquipmentTypeDocument;

        if (equipment.isActive) {
          return {
            workOrderDoc: doc,
            equipmentDoc: equipment,
            equipmentTypeDoc: equipmentType,
          } as WorkOrderWithChildren;
        }
      }),
    );

    return populatedRecords.filter(record => !!record).sort(sortActiveWorkOrders);
  };

  /**
   * List complete work orders
   *
   * @return {*}  {Promise<WorkOrderDocument[]>}
   */
  const listCompleteWorkOrders = async (): Promise<WorkOrderWithChildren[]> => {
    if (!workOrdersCollection) return [];

    const { selectedShiftEndUTC } = shiftPicker;

    let workOrderDocs: WorkOrderDocument[];
    try {
      workOrderDocs = await workOrdersCollection
        .find({
          selector: {
            $and: [{ completedDate: { $ne: null } }],
          },
        })
        .exec();
    } catch (error) {
      console.log('🚀 ~ file: useWorkOrders.ts ~ line 53 ~ listActiveWorkOrders ~ error', error);
      throw error;
    }

    // Filter out documents completed more that 14 days ago
    workOrderDocs = workOrderDocs.filter(doc => {
      const docDate = doc.completedDate;
      if (!docDate) return false;

      const docDateDayJs = dayjs.utc(docDate);
      return dateIsBetweenDates(
        docDateDayJs,
        selectedShiftEndUTC.subtract(14, 'days'),
        selectedShiftEndUTC,
      );
    });

    const populatedRecords: WorkOrderWithChildren[] = await Promise.all(
      workOrderDocs.map(async (doc: WorkOrderDocument) => {
        const equipment = (await doc.populate('equipmentId')) as EquipmentDocument;

        const equipmentType = (await equipment.populate(
          'equipmentTypeId',
        )) as EquipmentTypeDocument;

        return {
          workOrderDoc: doc,
          equipmentDoc: equipment,
          equipmentTypeDoc: equipmentType,
        } as WorkOrderWithChildren;
      }),
    );

    return populatedRecords.sort(sortCompletedWorkOrders);
  };

  return {
    workOrdersCollection,
    workOrdersCollectionsInitialized,
    listActiveWorkOrders,
    listCompleteWorkOrders,
  };
};

export { useWorkOrders };
export default useWorkOrders;
