import { CircularProgress, Grid } from '@material-ui/core';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useState } from 'react';

import { DocumentUploadDocument, EnrichedDocument } from '../../rxdb/Documents/queryBuilder';
import useGlobalDocs from '../../rxdb/Documents/useGlobalDocs';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import usePrediction from '../../rxdb/Prediction/usePrediction';
import { useNotification } from '../../utilities';
import { getAttachmentFromIndexDB } from '../CacheUpdateHandler/documentUtilities';
import DocumentThumbnail from '../DocumentThumbnail';
import ImagePreviewModal from '../ImagePreviewModal';
import PDFPreviewModal from '../PDFPreviewModal';
import { useStyles } from './BorerShiftDocumentsCard.styles';

const BorerShiftGlobalDocumentsCard: FC = () => {
  const classes = useStyles();

  const [pdfWindow, setPdfWindow] = useState<WindowProxy | undefined>();

  const { currentRoom } = useCurrentBorer();

  const { predictedEndLocation } = usePrediction();

  const globalDocRoomArray = useMemo(
    () => [predictedEndLocation?.room, currentRoom],
    [predictedEndLocation, currentRoom],
  );
  const { globalDocs, globalDocsLoaded } = useGlobalDocs(globalDocRoomArray);

  const { errorNotification } = useNotification();

  const [imagePreviewModalOpen, setImagePreviewModalOpen] = useState(false);
  const [PDFPreviewModalOpen, setPDFPreviewModalOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<EnrichedDocument | undefined>();
  const [selectedDocumentData, setSelectedDocumentData] = useState<string | undefined>();

  const selectDocument = async (document: DocumentUploadDocument, windowProxy?: WindowProxy) => {
    let url = null;

    if (document.isCached && document.fileName) {
      // If we have the doc cached, get it from the cache
      url = await getAttachmentFromIndexDB(document.fileName);
    }

    if (url) setSelectedDocumentData(url);
    else {
      errorNotification(
        'Error opening document. Please try again or check your network connection.',
      );
      return;
    }

    setImagePreviewModalOpen(true);
    setSelectedDocument(document);

    if (windowProxy) {
      setPdfWindow(windowProxy);
      setPDFPreviewModalOpen(true);
    } else {
      setImagePreviewModalOpen(true);
    }
  };

  const unselectDocument = () => {
    setImagePreviewModalOpen(false);
    setPDFPreviewModalOpen(false);
    setSelectedDocumentData(undefined);
    setPdfWindow(undefined);
  };

  const prepareToSelectDocument = (doc: DocumentUploadDocument) => {
    if (doc.fileName?.toLowerCase().includes('.pdf')) {
      const windowProxy = window.open();
      selectDocument(doc, windowProxy || undefined);
    } else {
      selectDocument(doc);
    }
  };

  return (
    <>
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography
              variant="h5"
              className={classes.type}
              data-testid="global-document-card-header"
            >
              {i18n.t('Global documents')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {globalDocsLoaded && (
            <>
              <Grid container className={classes.verticalSpacer}>
                {globalDocs?.map(document => {
                  return (
                    <DocumentThumbnail
                      document={document}
                      selectDocument={prepareToSelectDocument}
                      key={document.id}
                    />
                  );
                })}
                {globalDocs?.length === 0 && (
                  <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
                )}
              </Grid>
            </>
          )}
          {!globalDocsLoaded && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>

      <ImagePreviewModal
        open={imagePreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
      />
      <PDFPreviewModal
        open={PDFPreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
        pdfWindow={pdfWindow}
      />
    </>
  );
};

export default observer(BorerShiftGlobalDocumentsCard);
