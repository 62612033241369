import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    header: {
      marginTop: '0px',
      padding: '0px',
      width: '100%',
    },
    label: {
      color: '#2AAB3F',
      fontSize: '12px',
      fontWeight: 600,
    },
  }),
);

export default useStyles;
