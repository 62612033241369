import { useFlags } from '@nutrien/minesight-utility-module';
import postal from 'postal';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';

import { useMst } from '../mobx-models/Root';
import { useOnlineStatus } from '../utilities';
import useDebug from '../utilities/hooks/useDebug';
import { saveSyncTimeToLocalStorage } from '../utilities/syncHelpers';
import RxdbManager from './RxdbManager';

type IUseRxDB = {
  db?: RxdbManager;
};

const RxDBContext = createContext<null | IUseRxDB>(null);
export const RxDBProvider = RxDBContext.Provider;

new RxdbManager(localStorage.getItem('DEBUG') === 'true');

// Wrapper component for useRxDB hook
export const RxDBHandler = ({ children }: { children: React.ReactNode }) => {
  const { equipment } = useMst();
  const [borerSelected, setBorerSelected] = useState<boolean>(!!equipment.selectedBorerId);
  const rxdbManagerRef = useRef(RxdbManager.instance);
  const { msBorerDataSync } = useFlags().flags;

  const online = useOnlineStatus();
  const DEBUG = useDebug();
  const [dbInitialized, setDbInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (DEBUG) {
      console.table({
        allowSync: msBorerDataSync?.allowSync,
        borerSelected,
        online,
        dbInitialized,
      });
    }
    if (dbInitialized) {
      if (msBorerDataSync?.allowSync && borerSelected && online) {
        rxdbManagerRef.current.startReplication();
      } else {
        rxdbManagerRef.current.stopReplication();
      }
    }
  }, [msBorerDataSync?.allowSync, borerSelected, online, dbInitialized, DEBUG]);

  useEffect(() => {
    const postalSub = postal.subscribe({
      channel: 'borer',
      topic: 'borer.selected',
      callback: () => {
        setBorerSelected(true);
      },
    });

    return () => postalSub.unsubscribe();
  }, []);

  useEffect(() => {
    const postalSub = postal.subscribe({
      channel: 'db',
      topic: 'db.initialized',
      callback: () => {
        setDbInitialized(true);
      },
    });
    return () => postalSub.unsubscribe();
  }, []);

  useEffect(() => {
    const subArray: Subscription[] = [];
    const postalSub = postal.subscribe({
      channel: 'sync',
      topic: 'sync.online',
      callback: () => {
        RxdbManager.instance.syncStates?.forEach(state => {
          const sub = state.received$.subscribe(() => saveSyncTimeToLocalStorage());
          subArray.push(sub);
        });
      },
    });

    return () => {
      postalSub.unsubscribe();
      subArray.forEach(sub => sub.unsubscribe());
    };
  }, []);

  // Check every 60 seconds if all collections are syncing, and gracefully recover if not
  const checkForSyncRunning = useCallback(async () => {
    const nonRunningCollections =
      rxdbManagerRef.current?.syncStates?.filter(state => state.isStopped()) || [];
    return nonRunningCollections;
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const nonRunningCollections = await checkForSyncRunning();
      if (nonRunningCollections.length > 0 && online && msBorerDataSync?.allowSync) {
        rxdbManagerRef.current.startReplication();
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [checkForSyncRunning, dbInitialized, online, msBorerDataSync]);

  // Restart Sync - Only used in the case of bad doc being removed form the sync process
  // This fixes the 'Document not found in collection' error
  useEffect(() => {
    const postalSub = postal.subscribe({
      channel: 'db',
      topic: 'db.restart',
      callback: () => {
        rxdbManagerRef.current.stopReplication().then(() => {
          //pause for 5 seconds to allow replication to stop
          setTimeout(() => {
            rxdbManagerRef.current.startReplication();
          }, 5000);
        });
      },
    });

    return () => {
      postalSub.unsubscribe();
    };
  }, []);

  return (
    <RxDBProvider
      value={{
        db: rxdbManagerRef.current,
      }}
    >
      {children}
    </RxDBProvider>
  );
};

// Hook for useRxDB
export const useRxDB = () => {
  const rxDB = useContext(RxDBContext);
  if (rxDB === null) {
    throw new Error('rxDB cannot be null, please add a context provider');
  }

  const { db } = rxDB;

  return {
    db,
  };
};

export default useRxDB;
