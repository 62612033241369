import { createStyles, makeStyles } from '@material-ui/core';
import { MaterialPalette } from '@nutrien/cxp-components';

export const useStyles = makeStyles(() =>
  createStyles({
    cardTitle: {
      borderBottom: `1px solid ${MaterialPalette.grey[300]}`,
    },
  }),
);
