import { flow, types } from 'mobx-state-tree';

import { getAllImpersonatedUsersFromAPI } from '../../api/employeesAPI';
import { ApiStatus } from '../../utilities/apiStatus';

export interface ImpersonatedUserType {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
}

const ImpersonatedUser = types.model({
  userId: types.identifierNumber,
  email: types.string,
  firstName: types.string,
  lastName: types.string,
});

export const Employees = types
  .model({
    EmployeesStatus: types.enumeration('STATUS', [
      ApiStatus.LOADING,
      ApiStatus.ERROR,
      ApiStatus.SUCCESS,
    ]),
    EmployeesMap: types.map(ImpersonatedUser),
    Employees: types.array(ImpersonatedUser),
  })
  .views(self => ({
    getEmployeeName: (userId: string) => {
      const employee = self.EmployeesMap.get(userId);
      return `${employee?.firstName} ${employee?.lastName}`;
    },
  }))
  .actions(self => ({
    fetchAllEmployees: flow(function* fetchAllEmployees() {
      self.EmployeesStatus = ApiStatus.LOADING;

      try {
        const {
          canImpersonateUsers,
        }: {
          canImpersonateUsers: ImpersonatedUserType[];
        } = yield getAllImpersonatedUsersFromAPI(); // TODO: We need to determine which site we are searching against

        self.Employees.replace(canImpersonateUsers);
        canImpersonateUsers.forEach(employee => self.EmployeesMap.put(employee));

        self.EmployeesStatus = ApiStatus.SUCCESS;
      } catch (error) {
        console.error(
          '🚀 ~ file: Employees.ts ~ line 59 ~ fetchAllHazardTypes:flow ~ error',
          error,
        );
        self.EmployeesStatus = ApiStatus.ERROR;
      }
    }),
  }));
