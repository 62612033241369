import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface BorerShiftCrewMemberRole extends BaseEntity {
  createdOn: string;
  description: string;
  displayOrder: number;
  isActive: boolean;
}
export type BorerShiftCrewMemberRoleCollection = RxCollection<BorerShiftCrewMemberRole> | null;
export type BorerShiftCrewMemberRoleDocument = RxDocument<BorerShiftCrewMemberRole>;

export const BorerShiftCrewMemberRoleSchema: RxJsonSchema<BorerShiftCrewMemberRole> = {
  type: 'object',
  version: 1,
  description: 'describes an borer shift crew member role object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    createdOn: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    displayOrder: {
      type: 'number',
    },
    isActive: {
      type: 'boolean',
    },
  },
};

export const borerShiftCrewMemberRoleMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCrewMemberRoleDocument) => ({ ...oldDoc, isActive: true }),
};

const BorerShiftCrewMemberRoleFeedQuery = print(gql`
  query borerShiftCrewMemberRoleFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftCrewMemberRoleFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      createdOn
      description
      displayOrder
      id
      isDeleted
      updatedAt
      version
      isActive
    }
  }
`);

export const BorerShiftCrewMemberRolePullQueryBuilder = generatePullQuery(
  BorerShiftCrewMemberRoleFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
