import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface DocumentType extends BaseEntity {
  description: string;
  displayOrder: number;
  isRequired: boolean;
  miningMethod: string;
  siteId: string;
}
export type DocumentTypeCollection = RxCollection<DocumentType> | null;
export type DocumentTypeDocument = RxDocument<DocumentType>;

export const documentTypeSchema: RxJsonSchema<DocumentType> = {
  type: 'object',
  version: 0,
  description: 'describes a documentType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    miningMethod: { type: 'string' },
    siteId: { type: 'string' },
    displayOrder: { type: 'number' },
    isRequired: { type: 'boolean' },
  },
};

const borerDocumentTypesBySiteIdFeedQuery = print(gql`
  query borerDocumentTypesBySiteIdFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerDocumentTypesBySiteIdFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      description
      displayOrder
      id
      isDeleted
      isRequired
      miningMethod
      siteId
      updatedAt
      version
    }
  }
`);

export const documentTypePullQueryBuilder = generatePullQuery(
  borerDocumentTypesBySiteIdFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export default {};
