import dayjs from 'dayjs';

import { rootStore } from '@/mobx-models/Root';

import {
  BorerOperatorChange,
  BorerOperatorChangeState,
} from '../BorerOperatorChangeFeed/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../rxdbUtilityFunctions';

// Key is the borerStateId, value is the index of the state(s) that needs to be updated
type TempChangeStateRecord = { [key: string]: number[] };

export const getRunningOperatorChangeStatesAfterTimestamp = async (
  startTimestamp: number,
  endTimeStamp: number,
): Promise<TempChangeStateRecord> => {
  const db = RxdbManager.instance?.db;
  const borerChangeCollection = db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];
  const allVisibleChangestates = await borerChangeCollection
    ?.find({
      selector: {
        showInSchedulerView: true,
      },
    })
    .exec();
  if (!allVisibleChangestates) throw new Error('allVisibleChangestates is null');

  const tempStatesToChange: TempChangeStateRecord = {};
  for await (const changeState of allVisibleChangestates) {
    let index = 0;
    for await (const state of changeState.states) {
      if (
        dayjs(state.startTime).diff(dayjs.unix(startTimestamp)) > 0 &&
        dayjs(state.startTime).diff(dayjs.unix(endTimeStamp)) <= 0
      ) {
        const borerStateType = await db?.collections[RxdbCollectionName.BORER_STATE_TYPE]
          ?.findOne(state.borerStateTypeId)
          .exec();

        if (borerStateType?.isRunning) {
          if (tempStatesToChange[changeState.id]) {
            tempStatesToChange[changeState.id].push(index);
          } else {
            tempStatesToChange[changeState.id] = [index];
          }
        }
      }
      index += 1;
    }
  }

  return tempStatesToChange;
};

export const applyCuttingTypeIdToChangeState = async (
  tempStatesToUpdate: TempChangeStateRecord,
  newCuttingTypeId: string,
): Promise<void> => {
  const db = RxdbManager.instance?.db;
  const borerChangeCollection = db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];
  const operatorChangeStateIds = Object.keys(tempStatesToUpdate);

  await Promise.all(
    operatorChangeStateIds.map(async id => {
      const parentDoc = await borerChangeCollection
        ?.find({
          selector: {
            $and: [
              {
                showInSchedulerView: true,
              },
              { id },
            ],
          },
        })
        .exec();

      if (!parentDoc) throw new Error('parentDoc is null');
      if (parentDoc.length > 1) throw new Error('parentDoc should only return one change doc');
      const updatedStates = parentDoc[0].states.map((state, index) => ({
        ...state,
        cuttingTypeId: tempStatesToUpdate[id].includes(index)
          ? newCuttingTypeId
          : state.cuttingTypeId,
      }));

      await parentDoc[0].update({
        $set: {
          states: updatedStates,
          updatedAt: getUnixMillisecondTimestamp(),
        },
      });
    }),
  );
};

// Save Sub routine
export const saveBorerOperatorChange = async (
  borerStateId: string,
  states: BorerOperatorChangeState[],
  existingTempStateId?: string,
) => {
  const borerEquipmentId = rootStore.equipment.selectedBorerId;
  const db = RxdbManager.instance?.db;
  const borerOperatorChangeCollection =
    db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];
  if (!borerOperatorChangeCollection || !borerEquipmentId) {
    throw new Error('🚀 ~ Missing collection or borerEquipmentId');
  }
  if (!borerStateId) {
    throw new Error('🚀 ~borerOperatorChangeQueries.ts:118 Missing borerStateId');
  }
  try {
    if (existingTempStateId) {
      // Update the existing temp state
      const existingDoc = await borerOperatorChangeCollection
        .findOne({
          selector: {
            id: existingTempStateId,
          },
        })
        .exec();
      if (existingDoc) {
        await existingDoc.incrementalPatch({
          showInSchedulerView: false,
          updatedAt: getUnixMillisecondTimestamp(),
        });
      } else {
        console.error('Existing temp state provided, but doc not found');
      }
    }

    // Always make new doc with new ID
    // Otherwise, updates that are set with same ID will be ignored by BE
    const changeDoc: BorerOperatorChange = {
      ...generateBaseEntityWithCreatedOn(),
      borerStateId,
      states,
      borerEquipmentId,
      showInSchedulerView: true,
      failedSync: false,
      isGeneratedState: false,
    };

    return await borerOperatorChangeCollection.upsert(changeDoc);
  } catch (err) {
    console.log('🚀 ~ file: useBorerOperatorChange.tsx:140 ~ err', err);
    throw new Error('🚀 ~ Unable to save borer operator change');
  }
};

// Save Sub routine
export const markTempStatesAsHiddenForBorerStateId = async (borerStateId: string) => {
  const db = RxdbManager.instance?.db;
  const borerOperatorChangeCollection =
    db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];

  if (!borerStateId) {
    throw new Error('🚀 ~ Missing borerStateId within markTempStatesAsHiddenForBorerStateId');
  }
  try {
    const existingDocs = await borerOperatorChangeCollection
      ?.find({
        selector: {
          borerStateId,
        },
      })
      .exec();
    if (existingDocs) {
      await Promise.all(
        existingDocs.map(async doc => doc.modify(old => ({ ...old, showInSchedulerView: false }))),
      );
    }
  } catch (err) {
    console.log(
      '🚀 ~ file: borerOperatorChangeQueries.ts:183 ~ markTempStatesAsHiddenForBorerStateId ~ err:',
      err,
    );
  }
};
