import { Grid } from '@material-ui/core';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import Spinner from '@/components/Spinner';
import { ExtendedBorerShiftComment } from '@/rxdb/BorerShiftComment/queryBuilder';
import { useBorerShiftComment } from '@/rxdb/BorerShiftComment/useBorerShiftComment';
import { ABBREVIATED_DATE_FORMAT } from '@/utilities/hooks/useDateFormatters';
import { capitalizeFirstLetterOnly } from '@/utilities/utilityFunctions';

import useStyles from './AutomationCommentCard.styles';

const AutomationCommentCard: FC = () => {
  const classes = useStyles();
  const [labels, setLabels] = useState<string[]>([]);
  const [comments, setComments] = useState<Partial<ExtendedBorerShiftComment>[]>([]);

  const { borerShiftCommentsLoaded, automationComments } = useBorerShiftComment();

  useEffect(() => {
    if (automationComments) {
      const labelsArray = Array.from(automationComments.keys());
      const commentsArray = Array.from(automationComments.values());
      setLabels(labelsArray);
      setComments(commentsArray);
    }
  }, [automationComments]);

  return (
    <Card className={classes.cardRoot}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">{i18n.t('Automation')}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!borerShiftCommentsLoaded ? (
            <Spinner />
          ) : (
            comments.length > 0 && (
              <Grid container spacing={2} className={classes.commentRow}>
                <Grid item xs={12}>
                  <Typography className={classes.label} variant="h5">
                    {capitalizeFirstLetterOnly(labels[0])}
                  </Typography>
                  <Typography className={classes.label} variant="caption">
                    {`Automation News ${
                      comments[0]?.updatedAt
                        ? `- Updated ${dayjs(comments[0]?.updatedAt).format(
                            ABBREVIATED_DATE_FORMAT,
                          )}`
                        : ''
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.commentText} data-testid="automation-comment">
                    {comments[0].parsedComment || ''}
                  </span>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default observer(AutomationCommentCard);
