import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { useMst } from '../../mobx-models/Root';
import useAssignmentEmployee from '../../rxdb/AssignmentEmployee/useAssignmentEmployee';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
      minHeight: '44px',
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
  }),
);

const SupervisorsCard: FC = () => {
  const classes = useStyles();

  const { shiftPicker } = useMst();

  const { supervisorList } = useAssignmentEmployee(shiftPicker.currentShiftId);

  return (
    <Card elevation={2} className={classes.subCard}>
      <Grid container justify="space-between">
        <Grid item xs={4}>
          <Typography variant="h5" className={classes.type}>
            {i18n.t('Supervisors')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-start">
        {supervisorList && (
          <>
            {supervisorList.length > 0 ? (
              supervisorList.map(assignmentEmployee => (
                <Grid container className={classes.verticalSpacer} key={assignmentEmployee.id}>
                  <Grid item xs={4}>
                    <Typography variant="body1" color="textSecondary">
                      {assignmentEmployee?.assignmentRole?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {`${assignmentEmployee.employeeInformation?.firstName} ${assignmentEmployee.employeeInformation?.lastName}`}
                      {assignmentEmployee?.comment && assignmentEmployee?.comment.length > 0 ? (
                        <span className={classes.dot}>&#183;</span>
                      ) : null}
                      {assignmentEmployee.comment}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                {i18n.t('No roles have been assigned for the current shift')}
              </Typography>
            )}
          </>
        )}
        {!supervisorList && <CircularProgress className={classes.spinner} />}
      </Grid>
    </Card>
  );
};

export default observer(SupervisorsCard);
