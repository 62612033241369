import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { i18n } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import GenericSidePanel from '@/components/GenericSidePanel';
import { saveBorerOperatorChange } from '@/rxdb/BorerOperatorChangeFeed/borerOperatorChangeQueries';
import { BorerOperatorChangeState } from '@/rxdb/BorerOperatorChangeFeed/queryBuilder';
import { useBorerOperatorState } from '@/rxdb/BorerOperatorStateFeed/useBorerOperatorState';
import { BorerStateType } from '@/rxdb/BorerStateTypeFeed/queryBuilder';
import { useBorerStateType } from '@/rxdb/BorerStateTypeFeed/useBorerStateType';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { useNotification } from '@/utilities';

import { DelaysMultiEditFormCard } from './DelaysMultiEditFormCard';
import { DelaysMultiEditSummaryCard } from './DelaysMultiEditSummaryCard';

dayjs.extend(utc);

export interface DelayMultiEditInput {
  type: BorerStateType | null;
  comment: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
  onSaving: () => void;
  initialValues: DelayMultiEditInput;
  selectedDelays: AppointmentModel[];
  allDelays: AppointmentModel[];
  editingOngoingAppointment: boolean;
}

export const DelaysMultiEditDrawer = ({
  open,
  onClose,
  onOpen,
  onSuccess,
  onSaving,
  initialValues,
  selectedDelays,
  allDelays,
  editingOngoingAppointment,
}: Props): JSX.Element => {
  const { removeStates } = useBorerOperatorState();
  const { isRehabBorer } = useCurrentBorer();
  const { augmentedStateTypesWithCategories } = useBorerStateType(false, isRehabBorer);
  const { errorNotification, successNotification } = useNotification();

  const delayTypes = augmentedStateTypesWithCategories.filter(stateType => !stateType.isRunning);

  const [isSaving, setIsSaving] = useState(false);
  const [undo, setUndo] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup
        .object({
          type: yup.object().nullable().required('Required.'),
          comment: yup.string().nullable().optional(),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (open && !undo) {
      reset(initialValues);
    } else if (open) {
      setUndo(false);
    }
  }, [initialValues, reset, open]);

  useEffect(() => {
    onSaving(isSaving);
  }, [isSaving, onSaving]);

  const handleErrorClose = (message: string) => {
    errorNotification(message);
    onClose();
    setIsSaving(false);
  };

  const onSave = async data => {
    setIsSaving(true);

    const borerOperatorStates = allDelays.sort((a, b) => a.startTimeUnix - b.startTimeUnix);
    if (borerOperatorStates.find(state => state.startTime === undefined))
      return handleErrorClose('Start time is required.');

    const formattedOperatorStatesMap: Record<string, BorerOperatorChangeState[]> = {};

    await Promise.all(
      borerOperatorStates.map(async (state, index) => {
        let type = state.borerStateType?.id || state.borerStateTypeId;
        let comment = state.comment;
        let endTime = state.endTime;
        let startTime = state.startTime;
        if (selectedDelays.some(selectedDelay => selectedDelay.id === state.id)) {
          type = data.type.id;
          if (data.comment && state.comment) {
            comment = `${data.comment} - ${state.comment}`;
          } else if (!data.comment && state.comment) {
            comment = state.comment;
          } else {
            comment = data.comment || '';
          }
        }
        if (index === borerOperatorStates.length - 1 && editingOngoingAppointment) {
          // This is a requirement as per https://nutrien.atlassian.net/browse/MDP-6362
          endTime = null;
        }
        if (index > 0) {
          // Fix cases where gaps were appearing in generated block.
          startTime = borerOperatorStates[index - 1].endTime;
        }

        const formattedState = {
          startTime,
          endTime,
          borerStateTypeId: type,
          borerShiftAdvanceId: state.borerShiftAdvanceId || null,
          comment: comment || '',
          originalId: uuidv4(),
          isGeneratedState: false,
          isTempState: true,
          cuttingTypeId: state.cuttingTypeId || null,
          cuttingMethodId: state.cuttingMethodId || null,
          existingTempStateId: state.existingTempStateId || null,
        };

        const borerOperatorStateId = state.borerOperatorStateId;
        if (borerOperatorStateId) {
          if (!formattedOperatorStatesMap[borerOperatorStateId]) {
            formattedOperatorStatesMap[borerOperatorStateId] = [];
          }
          formattedOperatorStatesMap[borerOperatorStateId].push(formattedState);
        }
      }),
    );

    try {
      const uniqueBorerOperatorStateIds = [
        ...new Set(selectedDelays.map(delay => delay.borerOperatorStateId)),
      ];

      for (const borerOperatorStateId of uniqueBorerOperatorStateIds) {
        const states = formattedOperatorStatesMap[borerOperatorStateId];
        if (states) {
          await saveBorerOperatorChange(
            borerOperatorStateId,
            states,
            states[0].existingTempStateId,
          );

          // Hide states from UI (they will come back in feed with new ID)
          await removeStates(borerOperatorStateId);
        }
      }
      onSuccess();
      successNotification('Delay saved.');
    } catch (error) {
      console.error('🚀 ~ file: DelaysMultiEditDrawer.tsx ~ line 145 ~ error', error);
      return handleErrorClose('Error saving delay.');
    }

    onClose();
    setIsSaving(false);
  };

  return (
    <GenericSidePanel
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSubmit(onSave)}
      title={i18n.t('Edit Multiple Delays')}
      onOpen={onOpen}
      hasEdits={isDirty}
      canSave={isValid && isDirty}
      isSaving={isSaving}
      discardNotificationText="Multiple delays draft discarded"
      setUndo={setUndo}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DelaysMultiEditSummaryCard selectedDelays={selectedDelays} />
        </Grid>
        <Grid item xs={12}>
          <DelaysMultiEditFormCard control={control} delayTypes={delayTypes} errors={errors} />
        </Grid>
      </Grid>
    </GenericSidePanel>
  );
};
