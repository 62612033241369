import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import useEventTracking, { TrackingEventType } from '@/utilities/hooks/useEventTracking';

import { BlockDocument } from '../Blocks/queryBuilder';
import { PanelDocument } from '../Panels/queryBuilder';
import { PassDocument } from '../Passes/queryBuilder';
import { RoomDocument } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity, getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import { SequenceDocument } from '../Sequences/queryBuilder';
import { SurveyPointDocument } from '../SurveyPoints/queryBuilder';
import { LocationCollection, LocationDocument } from './queryBuilder';

export type LocationElementDocument =
  | PanelDocument
  | RoomDocument
  | PassDocument
  | SequenceDocument
  | BlockDocument;

const useLocations = () => {
  const locationsCollection: LocationCollection = useRxCollection(RxdbCollectionName.LOCATIONS);
  const [locationsInitialized, setLocationsInitialized] = useState(false);
  const { trackEvent } = useEventTracking();
  useEffect(() => {
    if (locationsCollection) setLocationsInitialized(true);
  }, [locationsCollection]);

  const getLocations = async (): Promise<LocationDocument[]> => {
    if (!locationsCollection) return [];

    let locations: LocationDocument[];

    try {
      locations = await locationsCollection.find().exec();
    } catch (error) {
      console.log('🚀 ~ file: useLocations.ts ~ line 23 ~ getLocations ~ error', error);
    }

    return locations;
  };

  const getLocationById = async (id: string): Promise<LocationDocument | null | undefined> => {
    try {
      const location = await locationsCollection
        ?.findOne({
          selector: {
            id,
          },
        })
        .exec();
      return location;
    } catch (error) {
      console.error('🚀 ~ file: useLocations.ts ~ line 46 ~ getLocationByID ~ error', error);
    }
  };

  const getLocationByBlockPanelRoom = async (
    block: any,
    panel: any,
    room: any,
  ): Promise<LocationDocument | undefined> => {
    try {
      const location: LocationDocument | undefined = await locationsCollection
        ?.findOne({
          selector: {
            $and: [{ block: { $eq: block } }, { panel: { $eq: panel } }, { room: { $eq: room } }],
          },
        })
        .exec();
      return location;
    } catch (error) {
      console.error(
        '🚀 ~ file: useLocations.ts ~ line 76 ~ getLocationByBlockPanelRoom ~ error',
        error,
      );
    }
  };

  const createLocation = async (
    panelId: string,
    roomId?: string,
    surveyPointId?: string,
    sequenceId?: string,
    passId?: string,
    startMeters?: number,
    endMeters?: number,
  ) => {
    const endLocation = {
      ...generateBaseEntity(),
      startMeters: startMeters || null,
      endMeters: endMeters || null,
      panel: panelId,
      room: roomId || null,
      surveyPoint: surveyPointId || null,
      sequence: sequenceId || null,
      pass: passId || null,
    };
    if (locationsCollection !== null) {
      await locationsCollection.insert(endLocation);
      trackEvent(TrackingEventType.CREATE_LOCATION, { endLocation });
    }
    return endLocation;
  };

  const updateLocation = async (
    locationDocument: LocationDocument,
    startMeters,
    panel,
    room,
    surveyPoint,
    sequence,
    pass,
  ) => {
    const location = {
      id: locationDocument.id,
      updatedAt: getUnixMillisecondTimestamp(),
      isDeleted: locationDocument.isDeleted || false,
      version: locationDocument.version,
      startMeters,
      endMeters: startMeters,
      panel,
      room,
      surveyPoint,
      sequence,
      pass,
    };
    if (locationsCollection !== null) {
      trackEvent(TrackingEventType.UPDATE_LOCATION, { location });
      return locationsCollection.upsert(location);
    }
  };

  const populateLocation = async (
    location: LocationDocument | undefined | null,
  ): Promise<PopulatedLocation | null> => {
    if (!location) return null;
    const [room, pass, sequence, surveyPoint, panel] = await Promise.all([
      location?.populate('room') as Promise<RoomDocument | null>,
      location?.populate('pass') as Promise<PassDocument | null>,
      location?.populate('sequence') as Promise<SequenceDocument | null>,
      location?.populate('surveyPoint') as Promise<SurveyPointDocument | null>,
      location?.populate('panel') as Promise<PanelDocument | null>,
    ]);

    const block: BlockDocument | null = await panel?.populate('block');

    return {
      room,
      panel,
      block,
      pass,
      sequence,
      surveyPoint,
      miningMethod: panel?.miningMethod || null,
    };
  };

  return {
    getLocations,
    getLocationById,
    getLocationByBlockPanelRoom,
    createLocation,
    updateLocation,
    locationsCollection,
    locationsInitialized,
    populateLocation,
  };
};

export interface PopulatedLocation {
  block: BlockDocument | null | undefined;
  panel: PanelDocument | null | undefined;
  room: RoomDocument | null | undefined;
  pass?: PassDocument | null | undefined;
  surveyPoint?: SurveyPointDocument | null | undefined;
  sequence?: SequenceDocument | null | undefined;
  miningMethod?: string | null | undefined;
}

export default useLocations;
