import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Block extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
}
export type BlockCollection = RxCollection<Block> | null;
export type BlockDocument = RxDocument<Block>;

export const blockSchema: RxJsonSchema<Block> = {
  type: 'object',
  version: 0,
  description: 'describes a Block object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

const blockFeedQuery = print(gql`
  query blockFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    blockFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
      id
      description
      isActive
      isDeleted
      updatedAt
      version
    }
  }
`);

export const blocksPullQueryBuilder = generatePullQuery(
  blockFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
