import type { PopoverProps, Theme } from '@material-ui/core';
import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { i18n, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useMemo } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

import { InspectionType } from '../../../../utilities/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderRadius: '8px !important',
    },
    div: { backgroundColor: theme.palette.background.default },
    container: {
      padding: '16px',
      backgroundColor: 'rgba(56,56,56,1)',
    },
    title: {
      paddingBottom: '24px',
      color: theme.palette.text.secondary,
    },
    link: {
      padding: '10px 0px',
    },
  }),
);

interface Props extends PopoverProps {
  openAddDeficiency: () => void;
  onAddPreOpCheck: (checkType: InspectionType) => void;
}

type EquipmentInspectionOptions = {
  name: string;
  type: InspectionType;
  showOnBorer: boolean;
  showOnRehab: boolean;
};

const laniganEquipmentInspectionOptions: EquipmentInspectionOptions[] = [
  {
    name: i18n.t('Pre-op check'),
    type: InspectionType.PRE_OP,
    showOnBorer: true,
    showOnRehab: true,
  },
];

const vanscoyEquipmentInspectionOptions: EquipmentInspectionOptions[] = [
  {
    name: i18n.t('Borer & flexiveyor pre-op check'),
    type: InspectionType.BORER_FLEXIVEYOR_PREOP_CHECK,
    showOnBorer: true,
    showOnRehab: false,
  },
  {
    name: i18n.t('Borer cutting check'),
    type: InspectionType.BORER_CUTTING_CHECK,
    showOnBorer: true,
    showOnRehab: false,
  },
  {
    name: i18n.t('Post-cutting check'),
    type: InspectionType.POST_CUTTING_CHECK,
    showOnBorer: true,
    showOnRehab: false,
  },
  {
    name: i18n.t('Safety checks before cutting'),
    type: InspectionType.VANSCOY_SAFETY_CHECK_BEFORE_CUTTING,
    showOnBorer: false,
    showOnRehab: false,
  },
  {
    name: i18n.t('Safety checks during cutting'),
    type: InspectionType.VANSCOY_SAFETY_CHECK_DURING_CUTTING,
    showOnBorer: false,
    showOnRehab: false,
  },
];

const coryEquipmentInspectionOptions: EquipmentInspectionOptions[] = [
  {
    name: i18n.t('Miner pre-op inspection'),
    type: InspectionType.MINER_PRE_OP,
    showOnBorer: true,
    showOnRehab: true,
  },
  {
    name: i18n.t('Miner running inspection'),
    type: InspectionType.MINER_RUNNING,
    showOnBorer: true,
    showOnRehab: true,
  },
  {
    name: i18n.t('Bridges pre-op inspection'),
    type: InspectionType.BRIDGES_PRE_OP,
    showOnBorer: true,
    showOnRehab: true,
  },
  {
    name: i18n.t('Bridges running inspection'),
    type: InspectionType.BRIDGES_RUNNING,
    showOnBorer: true,
    showOnRehab: true,
  },
  {
    name: i18n.t('TR equipment check'),
    type: InspectionType.CORY_EQUIPMENT_CHECK,
    showOnBorer: true,
    showOnRehab: true,
  },
];

const rocanvilleEquipmentInspectionOptions: EquipmentInspectionOptions[] = [
  {
    name: i18n.t('Equipment check'),
    type: InspectionType.EQUIPMENT_CHECK,
    showOnBorer: true,
    showOnRehab: true,
  },
];

const allanEquipmentInspectionOptions: EquipmentInspectionOptions[] = [
  {
    name: i18n.t('Borer inspection'),
    type: InspectionType.BORER_INSPECTION,
    showOnBorer: true,
    showOnRehab: false,
  },
  {
    name: i18n.t('Mineveyor inspection'),
    type: InspectionType.MINEVEYOR_INSPECTION,
    showOnBorer: true,
    showOnRehab: false,
  },
];

const DeficienciesMenu = ({
  open,
  anchorEl,
  onClose,
  openAddDeficiency,
  onAddPreOpCheck,
}: Props) => {
  const classes = useStyles();
  const { isVanscoy, isCory, isLanigan, isRocanville, isAllan } = useSiteFeatures();
  const { isRehabBorer } = useCurrentBorer();

  const filterForRehabAndBorerMachines = (
    equipmentInspectionOptionsList: EquipmentInspectionOptions[],
  ) => {
    return equipmentInspectionOptionsList.filter(e =>
      isRehabBorer ? e.showOnRehab : e.showOnBorer,
    );
  };

  const siteEquipmentInspectionOptions = useMemo(() => {
    switch (true) {
      case isLanigan:
        return filterForRehabAndBorerMachines(laniganEquipmentInspectionOptions);
      case isVanscoy:
        return filterForRehabAndBorerMachines(vanscoyEquipmentInspectionOptions);
      case isCory:
        return filterForRehabAndBorerMachines(coryEquipmentInspectionOptions);
      case isRocanville:
        return filterForRehabAndBorerMachines(rocanvilleEquipmentInspectionOptions);
      case isAllan:
        return filterForRehabAndBorerMachines(allanEquipmentInspectionOptions);
      default:
        return [];
    }
  }, [isLanigan, isCory, isVanscoy, isRocanville, isAllan, isRehabBorer]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.popover}
    >
      <div className={classes.div}>
        <Grid container className={classes.container} style={{ width: isVanscoy ? 425 : 375 }}>
          {!(isRehabBorer && isVanscoy) && (
            <Grid item container xs={isVanscoy || isCory ? 8 : 6}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h6">{i18n.t('Checklist')}</Typography>
              </Grid>
              {siteEquipmentInspectionOptions?.map(inspectionOption => (
                <Grid
                  item
                  xs={12}
                  className={classes.link}
                  onClick={() => onAddPreOpCheck(inspectionOption.type)}
                  id={`add-${inspectionOption.type}`}
                  key={inspectionOption.type}
                >
                  <Typography variant="body1">{inspectionOption.name}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item xs={isVanscoy || isCory ? 4 : 6}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">{i18n.t('Single entry')}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.link}
              onClick={openAddDeficiency}
              id="add-deficiency"
            >
              <Typography variant="body1">{i18n.t('Deficiency')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default DeficienciesMenu;
