import { hasPermissionFor, UserPermissionTypes } from '@nutrien/minesight-utility-module';

import { listBorerShiftCrewMemberRoles } from '@/graphql/BorerShiftCrewMemberRoles';
import { getEquipmentTypeForEquipment, listEquipment } from '@/graphql/Equipment';
import { EMPTY_GUID, PAGINATION_LIMIT_1000 } from '@/utilities/constants';

import { rootStore } from '../mobx-models/Root';
import {
  BstFromListBorerStateTypeQuery,
  listBorerStateTypes,
} from './BorerStateTypeFeed/queryBuilder';

// A class to help augment data during migrations by performing one API call

interface PartialEquipment {
  id: string;
  comment: string | null;
  status: {
    id: string;
  };
  tonnesPerScoop: number | null;
}

interface BorerShiftCrewMemberRole {
  description: string;
  id: string;
}

export default class MigrationHelper {
  private static _instance: MigrationHelper;

  borerStateTypes: BstFromListBorerStateTypeQuery[];

  partialEquipmentList: { [key: string]: PartialEquipment };

  borerShiftCrewMemberRoles: BorerShiftCrewMemberRole[];

  constructor() {
    this.borerStateTypes = [];
    this.partialEquipmentList = {};
    this.borerShiftCrewMemberRoles = [];
    MigrationHelper._instance = this;
  }

  public static get instance(): MigrationHelper {
    return MigrationHelper._instance;
  }

  public static get borerStateTypes(): BstFromListBorerStateTypeQuery[] {
    return this.borerStateTypes;
  }

  public static get getEquipmentDetails(): PartialEquipment | undefined {
    return this.getEquipmentDetails;
  }

  getEquipmentTypeId = async (siteId: string, equipmentId: string) => {
    try {
      const result = await getEquipmentTypeForEquipment(siteId, equipmentId);
      const equipment = result?.data?.getEquipmentById;

      if (equipment?.equipmentType?.id) return equipment.equipmentType.id;
      return null;
    } catch (err) {
      console.log('🚀 ~ file: MigrationHelper.ts:80 ~ etchAllEquipment= ~ err:', err);
      throw err;
    }
  };

  fetchBorerStateTypes = async () => {
    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');
      if (!rootStore.equipment.selectedBorerId)
        throw new Error('No selectedBorerId found in rootStore.equipment');

      // if we dont have these details, which will happen for any users that logged in before 1.37, we need to re-pull the borer list, without clearing it
      let selectedBorerEquipmentTypeId: string | undefined = rootStore.equipment.BorersMap.get(
        rootStore.equipment.selectedBorerId,
      )?.equipmentType?.id;

      if (!selectedBorerEquipmentTypeId) {
        // we need to call get equipment
        selectedBorerEquipmentTypeId = await this.getEquipmentTypeId(
          rootStore.user.siteId,
          rootStore.equipment.selectedBorerId,
        );
      }

      // if the above retry fails then we are hooped
      if (!selectedBorerEquipmentTypeId) {
        throw new Error('No equipmentTypeId, even after retry. Please re-login.');
      }

      const stateTypes = await listBorerStateTypes(
        rootStore.user.siteId,
        selectedBorerEquipmentTypeId,
      );
      this.borerStateTypes = stateTypes.data.listBorerStateTypes;
    } catch (error) {
      console.log('🚀 ~ file: MigrationHelper.ts:47 ~ error', error);
    }
  };

  timeUsageTypeIdForStateTypeId = async (
    stateTypeId: string,
  ): Promise<{ timeUsageTypeId: string | null; isPlanned: boolean | null }> => {
    if (!this.borerStateTypes.length) await this.fetchBorerStateTypes();
    return {
      timeUsageTypeId:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.timeUsageType?.id ||
        null,
      isPlanned:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.isPlanned || null,
    };
  };

  equipmentTypeIdForStateTypeId = async (
    stateTypeId: string,
  ): Promise<{ equipmentTypeId: string | null; siteId: string | null }> => {
    if (!this.borerStateTypes.length) await this.fetchBorerStateTypes();
    return {
      equipmentTypeId:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.equipmentType?.id ||
        null,
      siteId: this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.siteId || null,
    };
  };

  fetchAllEquipment = async () => {
    let lastResultId = EMPTY_GUID;
    let equipmentCount = PAGINATION_LIMIT_1000;

    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');

      while (equipmentCount === PAGINATION_LIMIT_1000) {
        const result = await listEquipment(
          rootStore.user.siteId,
          PAGINATION_LIMIT_1000,
          lastResultId,
          hasPermissionFor(UserPermissionTypes.EQUIPMENT_STATUS_INACTIVE_WRITE),
        );

        const equipment = result?.data?.listEquipment;
        equipment?.forEach(eq => {
          this.partialEquipmentList[eq.id] = eq;
        });

        if (!equipment) {
          equipmentCount = 0;
        } else {
          equipmentCount = equipment?.length;
          lastResultId = equipment[equipment.length - 1]?.id;
        }
      }
    } catch (err) {
      console.log('🚀 ~ file: MigrationHelper.ts:80 ~ etchAllEquipment= ~ err:', err);
    }
  };

  getEquipmentDetails = async (equipmentId: string): Promise<PartialEquipment | undefined> => {
    if (!this.partialEquipmentList[equipmentId]) await this.fetchAllEquipment();
    return this.partialEquipmentList[equipmentId];
  };

  fetchBorerShiftCrewMemberRoleIds = async () => {
    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');
      const result = await listBorerShiftCrewMemberRoles(rootStore.user.siteId);

      const roles = result?.data?.listBorerShiftCrewMemberRoles;

      roles?.forEach(role => {
        // Find the correct default roles for the borer shift crew
        if (role.description.match(/(Borer Operator|Lead Operator)/)) {
          this.borerShiftCrewMemberRoles.splice(0, 0, role);
        } else if (role.description.match(/^Operator/)) {
          this.borerShiftCrewMemberRoles.splice(1, 0, role);
        }
      });
    } catch (err) {
      console.log(
        '🚀 ~ file: MigrationHelper.ts:181 ~ fetchBorerShiftCrewMemberRoleIds= ~ err:',
        err,
      );
    }
  };

  getBorerShiftCrewMemberRoleIds = async () => {
    if (this.borerShiftCrewMemberRoles.length === 0) await this.fetchBorerShiftCrewMemberRoleIds();
    return this.borerShiftCrewMemberRoles;
  };
}

new MigrationHelper();
