import type { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    photoCardAdd: {
      width: '100%',
      height: '100%',
      border: `1px dashed ${CustomPalette.elevation.dp8}`,
      borderRadius: '4px',
      backgroundColor: CustomPalette.elevation.dp1,
    },
    photoCardAddText: {
      marginTop: '10px !important',
      color: theme.palette.text.secondary,
    },
  }),
);

export { useStyles };
export default useStyles;
